export const tabNames = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const tabs = [
  { title: 'Draft', field: tabNames.DRAFT },
  { title: 'Active', field: tabNames.ACTIVE },
  { title: 'Archived', field: tabNames.ARCHIVED },
];

export default tabs;
