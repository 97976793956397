import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CompanySectionPlaceholder from 'components/company/CompanySectionPlaceholder';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';

import CompanyDownload from './CompanyDownload/CompanyDownload';
import CompanyDownloadsEditModal from './CompanyDownloadsEditModal';

const NO_DOWNLOADS_DESCRIPTION =
  'Add your first attachment, this will allow journalists to use your assets in their press mentions';
const DOWNLOADS_DESCRIPTION =
  'Add an attachment, this will allow journalists to use your assets in their press mentions';

const CompanyDownloads = ({
  crud,
  showOwnerView = false,
  companyId,
  sectionRef,
}) => {
  const [, downloadsRaw, ops] = crud;
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const downloads = downloadsRaw.map((download) => ({
    ...download,
  }));
  const isEmpty = downloads.length < 1;

  // If there are no insights yet, hide this section for journalists
  if (!showOwnerView && isEmpty) {
    return null;
  }

  const onSubmit = (download) => {
    ops.createDoc({
      title: download.title || null,
      file: download.file || null,
      tags: download.tags || null,
    });
    setIsAddModalOpen(false);
  };

  return (
    <>
      <div data-test-id="company-downloads" id="downloads" ref={sectionRef}>
        <SectionHeaderWrapper>
          <SectionHeadline light testId="company-downloads-headline">
            Downloads
          </SectionHeadline>
        </SectionHeaderWrapper>
        <div className="w-full mt-4 mb-20">
          <ul
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4 gap-6 items-stretch"
            data-test-id="company-downloads-list"
          >
            {downloads.map((download) => (
              <CompanyDownload
                key={download.uid}
                download={download}
                showOwnerView={showOwnerView}
                ops={ops}
                companyId={companyId}
              />
            ))}
            {showOwnerView && (
              <CompanySectionPlaceholder
                title="Add a download"
                description={
                  isEmpty ? NO_DOWNLOADS_DESCRIPTION : DOWNLOADS_DESCRIPTION
                }
                onClick={() => setIsAddModalOpen(true)}
                testId="company-downloads-add"
              />
            )}
          </ul>
        </div>
      </div>
      {showOwnerView && isAddModalOpen && (
        <CompanyDownloadsEditModal
          open={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          onSubmit={onSubmit}
          uploadFolder={`companies/${companyId}/downloads`}
        />
      )}
    </>
  );
};

CompanyDownloads.propTypes = {
  crud: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanyDownloads;
