import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';

import OnboardingInputsContainer from 'components/OnboardingInputsContainer';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import SeparatorTextLine from 'components/form/SeparatorTextLine';
import InputFieldCheckboxesCards from 'components/form/inputFields/InputFieldCheckboxesCards/InputFieldCheckboxesCards';
import InputFieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import { PAGE_TITLE } from 'constants/meta';

import TitleSubtitle from '../TitleSubtitle';
import useOnboardingStore from '../stores/onboardingStores';

const optionsPrGoals = [
  {
    emoji: '📰',
    label: 'Get regular press coverage',
  },
  {
    emoji: '🗣',
    label: 'Share news about a current or upcoming announcement',
  },
  {
    emoji: '🛠',
    label: "Build up your company's brand name",
  },
  {
    emoji: '🎙',
    label: 'Grow your own voice with the industry',
  },
  {
    emoji: '🏆',
    label: 'Convince investors of your credibility',
  },
];

const StepOne = ({ availableSteps = [], onSubmit = noop, content = {} }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    getValues,
    register,
  } = useForm({ mode: 'onChange' });

  const user = useOnboardingStore((state) => state.user);
  const updateUser = useOnboardingStore((state) => state.updateUser);

  const onChange = () => {
    updateUser(getValues());
  };

  return (
    <form
      className="relative z-10 flex flex-col items-center mx-4"
      onSubmit={handleSubmit(onSubmit)}
      onChange={onChange}
      data-test-id="onboarding-prGoals-form"
    >
      <OnboardingInputsContainer>
        <TitleSubtitle
          title="What would you like to achieve by doing PR?"
          subtitle="Select as many as are relevant to you."
        />

        <Controller
          control={control}
          name="prGoals"
          defaultValue={user.prGoals}
          render={({ field: { value, onChange: onChangeCards } }) => (
            <>
              <InputFieldCheckboxesCards
                options={optionsPrGoals}
                value={value}
                onChange={(val) => {
                  onChangeCards(val);
                  onChange();
                }}
                testId="onboarding-prGoals"
              />

              <SeparatorTextLine text="OR" classNames="mt-0.5" />

              <InputFieldTextArea
                {...register('prGoalsOther')}
                placeholder={`Let us know what you’d like to achieve using ${PAGE_TITLE}`}
                defaultValue={user.prGoalsOther}
                data-test-id="onboarding-prGoals-other"
              />
            </>
          )}
        />

        {errors.prGoals && (
          <ErrorMessage>{errors.prGoals.message}</ErrorMessage>
        )}
      </OnboardingInputsContainer>

      <div className="mb-8 flex items-center">
        <Button
          size="base"
          submit
          disabled={!isValid || !availableSteps.includes('two')}
          data-test-id="onboarding-prGoals-submit"
        >
          {content.submitText}
        </Button>
      </div>
    </form>
  );
};

StepOne.propTypes = {
  availableSteps: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
};

export default StepOne;
