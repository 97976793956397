import { useStateMachine } from 'little-state-machine';

import useUserData from 'contexts/UserContext/hooks/useUserData';

const useIsAnalyticsDisabled = () => {
  const { state } = useStateMachine();
  const { claimsAdmin } = useUserData();

  return claimsAdmin || !state.cookiesEnabled;
};

export default useIsAnalyticsDisabled;
