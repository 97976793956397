import PropTypes from 'prop-types';
import React from 'react';

const ModalRightButtons = ({ children = null, testId = '' }) => (
  <div className="ml-auto flex gap-4" data-test-id={testId}>
    {children}
  </div>
);

ModalRightButtons.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

export default ModalRightButtons;
