import React, { useState } from 'react';

import {
  CalendarIcon,
  IdentificationIcon,
  MailIcon,
} from '@heroicons/react/solid';
import { addDoc, collection, deleteDoc, doc } from 'firebase/firestore';
import Helmet from 'react-helmet';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import CompanyThreadAssignModal from 'components/CompanyThreadAssignModal/CompanyThreadAssignModal';
import Button from 'components/buttons/Button';
import Tag from 'components/tags/Tag';
import defaultOptions from 'constants/defaultOptions';
import {
  INBOXES_COLLECTION,
  INBOXES_ORPHAN_MESSAGES_DOC,
  INBOXES_THREADS_COLLECTION,
  INBOXES_THREADS_MESSAGES_COLLECTION,
} from 'constants/firebasePaths';
import { messageStatus, orphanMessageStatus } from 'constants/messageStatus';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';
import getPageTitle from 'utils/getPageTitle';

const ADMIN_INBOX_EMAIL = process.env.REACT_APP_ADMIN_INBOX_EMAIL;

const AdminMessagesOrphansTab = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({});

  const { setDocument } = useFirestoreWrapper();
  const firestore = useFirestore();
  const ref = collection(
    firestore,
    INBOXES_COLLECTION,
    INBOXES_ORPHAN_MESSAGES_DOC,
    INBOXES_THREADS_MESSAGES_COLLECTION
  );

  const { data: orphanMessages } = useFirestoreCollectionData(
    ref,
    defaultOptions
  );

  const handleAssignUnassign = async (message = {}) => {
    const isAssigned = message.status === orphanMessageStatus.ASSIGNED;

    if (isAssigned) {
      const assignedPathAsArray = message.assignedPath.split('/');

      await Promise.all([
        deleteDoc(doc(firestore, ...assignedPathAsArray)),
        setDocument(
          doc(ref, message.uid),
          {
            status: orphanMessageStatus.UNASSIGNED,
          },
          { merge: true }
        ),
      ]);
    } else {
      setSelectedMessage(message);
      setIsModalOpen(true);
    }
  };

  const handleAssign = async (companyUid = '', threadUid = '') => {
    // We add the new message for the assigned thread
    const documentRef = await addDoc(
      collection(
        firestore,
        INBOXES_COLLECTION,
        companyUid,
        INBOXES_THREADS_COLLECTION,
        threadUid,
        INBOXES_THREADS_MESSAGES_COLLECTION
      ),
      {
        dateCreated: selectedMessage.dateCreated,
        nylasId: selectedMessage.nylasId || null,
        text: selectedMessage.text,
        isJournalist: true,
        read: false,
        status: messageStatus.UNAPPROVED,
      }
    );

    // We update the status of the orphan message,
    // and save the path to which it was assigned
    await setDocument(
      doc(ref, selectedMessage.uid),
      {
        status: orphanMessageStatus.ASSIGNED,
        assignedPath: documentRef.path,
      },
      { merge: true }
    );

    setIsModalOpen(false);
  };

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <Helmet>
        <title>{getPageTitle('Admin - Messages: Orphans')}</title>
      </Helmet>
      <table className="min-w-full divide-y divide-gray-200 table-auto">
        <thead className="bg-gray-50 text-xs font-medium text-gray-500 uppercase">
          <tr>
            <th scope="col" className="px-6 py-3 text-left tracking-wider">
              Orphans
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {orphanMessages.map((message) => {
            const isUnassigned =
              message.status === orphanMessageStatus.UNASSIGNED ||
              !message.status;
            const isAssigned = message.status === orphanMessageStatus.ASSIGNED;

            return (
              <tr key={message.uid} className="text-gray-700">
                <td className="p-4 w-full">
                  <div>
                    <div className="mb-2 w-fit">
                      <span className="flex gap-2 items-center">
                        <CalendarIcon className="h-4 w-4" />
                        <span className="text-xs">
                          {getDateFromTimestamp(message.dateCreated, {
                            hour: 'numeric',
                            minute: 'numeric',
                          })}
                        </span>
                        <Tag
                          bold
                          uppercase
                          bgColor={isUnassigned ? 'gray-100' : 'teal-100'}
                          textColor={isUnassigned ? 'gray-700' : 'teal-700'}
                        >
                          {isUnassigned ? 'Unassigned' : 'Assigned'}
                        </Tag>
                      </span>
                      <span className="flex gap-2 items-center">
                        <IdentificationIcon className="w-4 h-4" />
                        <span className="font-semibold">
                          {message.from.name}
                        </span>
                      </span>
                      <span className="flex gap-2 items-center">
                        <MailIcon className="w-4 h-4" />
                        <a
                          href={encodeURI(
                            `https://mail.google.com/mail/u/${ADMIN_INBOX_EMAIL}/?view=cm&fs=1&tf=1&to=${message.from.email}`
                          )}
                          className="text-sm underline-offset-4 decoration-teal-500 hover:underline"
                        >
                          {message.from.email}
                        </a>
                      </span>
                    </div>
                    <hr />
                    <p className="text-sm text-gray-500 whitespace-pre-line pt-2 max-w-prose">
                      {message.text}
                    </p>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex justify-end gap-2">
                    <a
                      href={`https://mail.google.com/mail/u/${ADMIN_INBOX_EMAIL}/#search/rfc822msgid:${message.replyToId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button type="secondary">Find in Gmail</Button>
                    </a>
                    <Button onClick={() => handleAssignUnassign(message)}>
                      {isAssigned ? 'Unassign' : 'Assign'}
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <CompanyThreadAssignModal
        onClose={() => {
          setSelectedMessage({});
          setIsModalOpen(false);
        }}
        isValid={(companyUid, threadUid) => (companyUid && threadUid) || false}
        isOpen={isModalOpen}
        onSubmit={handleAssign}
      />
    </div>
  );
};

export default AdminMessagesOrphansTab;
