const getDateFromTimestamp = (timestamp = '', config = {}) => {
  const date = new Date(timestamp);
  const formatedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    year: 'numeric',
    month: 'short',
    ...config,
  });
  const isToday = date.toDateString() === new Date().toDateString();

  return isToday ? 'Today' : formatedDate;
};

export default getDateFromTimestamp;
