/* This example requires Tailwind CSS v2.0+ */
import React from 'react';

import { generatePath, useParams } from 'react-router-dom';

import {
  ADMIN_MESSAGES_COMPANIES_INBOX_PATH,
  ADMIN_MESSAGES_COMPANIES_INBOX_THREAD_PATH,
  ADMIN_MESSAGES_COMPANIES_PATH,
  ADMIN_MESSAGES_PATH,
  ADMIN_MESSAGES_PATH_BASE,
} from 'constants/paths';

import AdminMessagesCompaniesBreadCrumb from './AdminMessagesCompaniesBreadCrumb';

const BASE_PATH = `${ADMIN_MESSAGES_PATH_BASE}/${ADMIN_MESSAGES_COMPANIES_PATH}`;

const AdminMessagesCompaniesBreadCrumbs = () => {
  const { inboxId, threadId } = useParams();

  const pages = [
    {
      name: 'Companies',
      pattern: `${BASE_PATH}/*`,
      path: generatePath(ADMIN_MESSAGES_PATH, {
        type: ADMIN_MESSAGES_COMPANIES_PATH,
      }),
    },
    {
      name: 'Threads',
      pattern: `${BASE_PATH}/${ADMIN_MESSAGES_COMPANIES_INBOX_PATH}/*`,
      path: generatePath(ADMIN_MESSAGES_PATH, {
        type: ADMIN_MESSAGES_COMPANIES_PATH,
        inboxId,
      }),
    },
    {
      name: 'Messages',
      pattern: `${BASE_PATH}/${ADMIN_MESSAGES_COMPANIES_INBOX_PATH}/${ADMIN_MESSAGES_COMPANIES_INBOX_THREAD_PATH}`,
      path: generatePath(ADMIN_MESSAGES_PATH, {
        type: ADMIN_MESSAGES_COMPANIES_PATH,
        inboxId,
        threadId,
      }),
    },
  ];

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {pages.map((page, index) => (
          <AdminMessagesCompaniesBreadCrumb
            key={page.name}
            {...page}
            isFirst={index === 0}
          />
        ))}
      </ol>
    </nav>
  );
};

export default AdminMessagesCompaniesBreadCrumbs;
