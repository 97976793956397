import PropTypes from 'prop-types';
import React from 'react';

const AdminCompaniesCompanyVCInfo = ({
  companyName = '',
  vcs = [],
  vcsOther = '',
}) => {
  const toLowerCaseAndRemoveSpaces = (string) =>
    string.toLowerCase().replaceAll(/\s/g, '');

  // hacky way of checking if the company is a vc for now
  // i.e BACKED vs Backed and Speed Invest vs Speedinvest
  const isVC = (vc) =>
    toLowerCaseAndRemoveSpaces(vc.name) ===
    toLowerCaseAndRemoveSpaces(companyName);

  return (
    <div>
      {vcs.map((vc) => (
        <p key={vc.id}>{isVC(vc) ? "We're a VC company" : vc.name}</p>
      ))}
      {vcsOther ? <p>{vcsOther}</p> : null}
    </div>
  );
};

AdminCompaniesCompanyVCInfo.propTypes = {
  companyName: PropTypes.string,
  vcs: PropTypes.array,
  vcsOther: PropTypes.string,
};

export default AdminCompaniesCompanyVCInfo;
