import React from 'react';

import { ArrowRightIcon } from '@heroicons/react/outline';
import { signOut } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAuth } from 'reactfire';

import Button from 'components/buttons/Button';
import HomeLogo from 'components/layout/Navbar/HomeLogo';
import { PAGE_TITLE } from 'constants/meta';
import { LOGIN_PATH, ONBOARDING_PATH } from 'constants/paths';
import useRedirectOnboarding from 'hooks/useRedirectOnboarding';
import { ReactComponent as BgDotsGrey } from 'static/bg/bg--texture-dots-gray.svg';
import getPageTitle from 'utils/getPageTitle';

import StepOne from './01-StepOne/StepOne';
import StepTwo from './02-StepTwo/StepTwo';
import StepThree from './03-StepThree/StepThree';
import StepFour from './04-StepFour/StepFour';
import Progress from './Progress';
import usePromoCode from './hooks/usePromoCode';
import useStepValidation from './hooks/useStepValidation';
import useStepsConfigurationInit from './hooks/useStepsConfiguration';
import useSubmitStepFour from './hooks/useSubmitStepFour';

const componentMap = {
  one: StepOne,
  two: StepTwo,
  three: StepThree,
  four: StepFour,
};

const OnboardingPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  usePromoCode();

  const [currentStep, steps] = useStepsConfigurationInit();
  const availableStepsNames = useStepValidation(steps);
  const submitStepFour = useSubmitStepFour();

  const [showOnboarding, showLoginButton] = useRedirectOnboarding();

  if (!showOnboarding) {
    return null;
  }

  const onSubmit = async () => {
    let nextStep;
    if (!currentStep.submit) {
      const currentStepIndex = availableStepsNames.findIndex(
        (name) => name === currentStep.name
      );

      const nextStepName = availableStepsNames[currentStepIndex + 1];
      if (nextStepName) {
        nextStep = generatePath(ONBOARDING_PATH, { step: nextStepName });
      }
    }

    if (currentStep.submit && availableStepsNames.includes('submit')) {
      nextStep = await submitStepFour();
    }

    if (nextStep) {
      navigate(nextStep);
    }
  };

  const StepComponent = componentMap[currentStep.component];

  return (
    <div className="relative bg-gray-50 min-h-screen">
      <Helmet>
        <title>
          {getPageTitle(`Create a press page - Step ${currentStep.name}`)}
        </title>
      </Helmet>
      <div className="absolute z-0 right-0 top-0">
        <BgDotsGrey />
      </div>
      <div className="absolute z-0 left-0 bottom-0">
        <BgDotsGrey />
      </div>

      <nav className="relative z-10 flex items-center mx-0 justify-center sm:mx-4 md:justify-between">
        <HomeLogo
          wrapperClassName="hidden md:block flex-grow basis-0 shrink-0"
          homeRoute={null}
        />

        {/* this should go into the Navbar component */}
        <div className="md:flex-grow md:basis-0 md:shrink-0 max-w-fit">
          <Progress
            steps={steps}
            currentStep={currentStep.number}
            availableSteps={availableStepsNames}
          />
        </div>

        {/* Signin button */}
        <div className="hidden flex-grow basis-0 shrink-0  md:flex justify-end items-end">
          {showLoginButton ? (
            <Button
              type="secondary"
              className="block"
              onClick={() => navigate(LOGIN_PATH)}
              data-test-id="onboarding-login"
            >
              <div className="flex justify-center items-center">
                <div className="mr-2">Log in to {PAGE_TITLE}</div>
                <ArrowRightIcon className="w-4" />
              </div>
            </Button>
          ) : (
            <Button
              type="secondary"
              onClick={() => {
                signOut(auth).then(() => {
                  // we cannot use `navigate` here because the /login route will
                  // use the old state of the UserContext and immediately redirect here
                  window.location = LOGIN_PATH;
                });
              }}
              data-test-id="onboarding-login"
            >
              Log out
            </Button>
          )}
        </div>
      </nav>

      <StepComponent
        availableSteps={availableStepsNames}
        onSubmit={onSubmit}
        content={currentStep.content}
      />
    </div>
  );
};

export default OnboardingPage;
