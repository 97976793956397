import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Stepper from 'components/Stepper';
import getContentType from 'constants/contentTypes';
import useWithAnalytics from 'hooks/useWithAnalytics';
import getCurrentObjectAndIndex from 'utils/getCurrentObjectAndIndex';

import CompanyContentElementFiles from '../CompanyContentElement/CompanyContentElementFiles';
import CompanyContentElementTable from '../CompanyContentElement/CompanyContentElementTable';
import CompanyContentElementOpEdArticleIdea from '../CompanyContentElementOpEd/CompanyContentElementOpEdArticleIdea';
import CompanyContentElementOpEdPastExamples from '../CompanyContentElementOpEd/CompanyContentElementOpEdPastExamples';
import { ClampContextProvider } from '../contexts/ClampContext';
import getContentTypeAnalyticsEvents from '../helpers/getContentTypeAnalyticsEvents';

import CompanyContentFooter from './CompanyContentFooter';

const CompanyContentViewModal = ({
  open = false,
  content = [],
  currentViewContentId = null,
  setCurrentViewContentId = noop,
  onClose = noop,
}) => {
  const [contentObj, currentIndex] = getCurrentObjectAndIndex(
    content,
    currentViewContentId
  );

  const onPrevious = useWithAnalytics({
    eventType: 'pressPage_view',
    eventProperties: getContentTypeAnalyticsEvents(content[currentIndex - 1]),
  })(() => setCurrentViewContentId(content[currentIndex - 1]?.uid));

  const onNext = useWithAnalytics({
    eventType: 'pressPage_view',
    eventProperties: getContentTypeAnalyticsEvents(content[currentIndex + 1]),
  })(() => setCurrentViewContentId(content[currentIndex + 1]?.uid));

  if (!contentObj) {
    return null;
  }

  const ContentTypeIcon = getContentType(contentObj).Icon;

  return (
    <Modal open={open}>
      <Modal.Close srMessage="Close Insight edit modal" onClose={onClose} />
      <Modal.Icon Icon={ContentTypeIcon} />
      <Modal.Title>{getContentType(contentObj).label}</Modal.Title>

      <Modal.Content className="-mx-6">
        {contentObj.type === 'opEd' ? (
          <>
            <CompanyContentElementOpEdArticleIdea content={contentObj} />
            <div className="w-full mt-8">
              <CompanyContentElementOpEdPastExamples
                pastExamples={contentObj.pastExamples}
                linkedInUrl={contentObj.linkedInUrl}
              />
            </div>

            <CompanyContentFooter content={contentObj}>
              <CompanyContentElementFiles content={contentObj} />
            </CompanyContentFooter>
          </>
        ) : (
          <ClampContextProvider value={{ clampValues: false }}>
            <CompanyContentElementTable
              contentType={contentObj.type}
              content={contentObj}
            />
          </ClampContextProvider>
        )}
      </Modal.Content>

      <Modal.TopBar>
        <Stepper
          currentStep={currentIndex}
          totalSteps={content.length}
          stepLabel="Content item"
          stepsLabel="Content items"
          onNext={onNext}
          onPrevious={onPrevious}
        />
      </Modal.TopBar>
    </Modal>
  );
};

CompanyContentViewModal.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.array,
  currentViewContentId: PropTypes.string,
  setCurrentViewContentId: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompanyContentViewModal;
