import React from 'react';

import { SearchIcon } from '@heroicons/react/outline';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

import useCompaniesStore from './hooks/useCompaniesStore';

const AdminCompaniesSearchField = () => {
  const search = useCompaniesStore((state) => state.search);

  return (
    <InputFieldText
      type="text"
      autoComplete="company"
      containerClassName="w-full"
      name="search"
      value={search}
      onChange={(e) => useCompaniesStore.setState({ search: e.target.value })}
    >
      <InputFieldText.Label>Search Companies</InputFieldText.Label>
      <InputFieldText.Prefix>
        <SearchIcon className="w-4 h-4" />
      </InputFieldText.Prefix>
    </InputFieldText>
  );
};

export default AdminCompaniesSearchField;
