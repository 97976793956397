import AmexIcon from 'static/cardBrands/AmexIcon';
import DinersClubIcon from 'static/cardBrands/DinersClubIcon';
import DiscoverIcon from 'static/cardBrands/DiscoverIcon';
import JCBIcon from 'static/cardBrands/JCBIcon';
import MastercardIcon from 'static/cardBrands/MastercardIcon';
import UnionpayIcon from 'static/cardBrands/UnionpayIcon';
import VisaIcon from 'static/cardBrands/VisaIcon';

const cardIcons = {
  amex: AmexIcon,
  discover: DiscoverIcon,
  jcb: JCBIcon,
  visa: VisaIcon,
  mastercard: MastercardIcon,
  unionpay: UnionpayIcon,
  diners_club: DinersClubIcon,
};

export default cardIcons;
