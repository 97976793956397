import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

const NavbarLink = ({
  path = '',
  name = '',
  testId = '',
  Badge = null,
  children = null,
}) => (
  <NavLink
    to={path}
    className={classNames(
      'group flex gap-x-2 rounded-md p-2 px-4 text-sm leading-6 font-semibold bg-teal-600 text-white mr-2 hover:bg-teal-700'
    )}
    data-test-id={testId}
  >
    <div className="relative">
      {children}
      {Badge && <div className="absolute -top-1 -right-1">{Badge}</div>}
    </div>
    <span
      className="text-sm font-medium max-w-[5rem] flex items-center"
      style={{ textAlign: 'left' }}
    >
      {name}
    </span>
  </NavLink>
);
NavbarLink.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  testId: PropTypes.string,
  children: PropTypes.node,
  Badge: PropTypes.node,
};

export default NavbarLink;
