import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import noop from 'lodash/noop';

import InputFieldCompaniesAutocomplete from 'components/form/inputFields/InputFieldCompaniesAutocomplete/InputFieldCompaniesAutocomplete';

const CompanyAutocompleteField = ({
  updateCallback = noop,
  targetedCompanies = [],
  companiesData = [],
  loading = false,
  name = '',
}) => {
  const suggestions = useMemo(() => {
    if (companiesData?.length) {
      const res = companiesData.map((company) => ({
        id: company.id,
        name: company.name,
      }));

      return res;
    }

    return [];
  }, [companiesData]);

  return (
    <div className="w-full">
      <div className="space-y-2">
        {!loading && (
          <InputFieldCompaniesAutocomplete
            name={name}
            customFieldName="target-companies"
            onChange={updateCallback}
            buttonClassName="w-full"
            value={targetedCompanies}
            placeholder="Type to search companies..."
            suggestions={suggestions}
          />
        )}
      </div>
    </div>
  );
};

CompanyAutocompleteField.propTypes = {
  targetedCompanies: PropTypes.array,
  companiesData: PropTypes.array,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  updateCallback: PropTypes.func,
};

export default CompanyAutocompleteField;
