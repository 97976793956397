// eslint-disable jsx-a11y/no-noninteractive-element-interactions

import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import ReactTags from 'react-tag-autocomplete';

import reactTagsClassnames from 'components/form/inputFields/InputFieldTagsAutocomplete/config/reactTagsClassnames';
import createInputFieldTagsAutocompleteTag from 'components/form/inputFields/InputFieldTagsAutocomplete/createInputFieldTagsAutocompleteTag';
import InputFieldTextLabel from 'components/form/inputFields/InputFieldText/InputFieldTextLabel';

const toTag = (j) => ({
  ...j,
  name: `${j.name} (${j.publication?.name || 'n/a'})`,
});

const JournalistAutocomplete = ({
  selectedJournalists = [],
  onChange = noop,
  suggestions = [],
  removeJournalist = noop,
  addJournalist = noop,
  testId = '',
}) => (
  <section className="flex-1">
    <div className="relative">
      <InputFieldTextLabel className="mb-2">Journalists</InputFieldTextLabel>
      <label
        className="focus-within:border-teal-500 relative px-2 block bg-white border border-gray-300 min-w-0 cursor-text rounded-md w-full"
        data-test-id={testId}
      >
        <ReactTags
          autoresize
          minQueryLength={1}
          classNames={reactTagsClassnames}
          tags={selectedJournalists.map(toTag)}
          suggestions={suggestions}
          maxSuggestionsLength={20}
          onInput={onChange}
          onDelete={(idx) => removeJournalist(selectedJournalists[idx])}
          onAddition={(j) => addJournalist(j)}
          tagComponent={createInputFieldTagsAutocompleteTag()}
          suggestionsTransform={() => suggestions.map(toTag)} // We override the default filter, as we do that in the backend already
          allowNew
          placeholderText=""
        />
      </label>
    </div>
  </section>
);

JournalistAutocomplete.propTypes = {
  onChange: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  selectedJournalists: PropTypes.array,
  removeJournalist: PropTypes.func,
  addJournalist: PropTypes.func,
  testId: PropTypes.string,
};

export default JournalistAutocomplete;
