const trimMessage = (
  message = {
    html: '',
    text: '',
  }
) => {
  // remove white space, empty p and <p>&nbsp*n</p> with 0 or more &nbsp from the beginning and end of the html
  const html = message.html
    .replace(/(\s|<p>&nbsp;*<\/p>)*$/, '')
    .replace(/^(\s|<p>&nbsp;*<\/p>)*/, '');

  // remove white space, empty lines, \n \r from the beginning and end of plain text
  const text = message.text
    .replace(/(\s|\n|\r)*$/, '')
    .replace(/^(\s|\n|\r)*/, '');

  return { html, text };
};

export default trimMessage;
