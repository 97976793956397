import PropTypes from 'prop-types';
import React from 'react';

import { PencilIcon, PlusIcon } from '@heroicons/react/outline';
import noop from 'lodash/noop';

import AdminMessagesThreadsThreadJournalistModal from './AdminMessagesThreadsJournalistModal';

const AdminMessagesThreadsThreadJournalist = ({
  journalist = {},
  setIsJournalistModalOpen = noop,
  isJournalistModalOpen = false,
}) => (
  <div className="flex gap-2 items-baseline flex-wrap">
    <span className="text-sm text-gray-500">
      ({journalist?.email || 'No email added'})
    </span>
    <button
      type="button"
      className="flex items-center gap-1 text-xs text-teal-600 hover:underline underline-offset-2 min-w-max"
      onClick={(e) => {
        e.stopPropagation();
        setIsJournalistModalOpen(true);
      }}
    >
      <span>{`${journalist?.email ? 'Edit' : 'Add'} email`}</span>
      {journalist?.email ? (
        <PencilIcon className="w-3 h-3" />
      ) : (
        <PlusIcon className="w-3 h-3" />
      )}
    </button>
    <AdminMessagesThreadsThreadJournalistModal
      journalist={journalist}
      onClose={() => setIsJournalistModalOpen(false)}
      open={isJournalistModalOpen}
    />
  </div>
);

AdminMessagesThreadsThreadJournalist.propTypes = {
  journalist: PropTypes.object,
  setIsJournalistModalOpen: PropTypes.func,
  isJournalistModalOpen: PropTypes.bool,
};
export default AdminMessagesThreadsThreadJournalist;
