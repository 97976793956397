import {
  CheckIcon,
  InformationCircleIcon,
  XIcon,
} from '@heroicons/react/outline';

const journalistResponseOptions = {
  positive: {
    Icon: CheckIcon,
    responseText: `Great, I'll use this and follow up if I have further questions.`,
    bgColor: 'bg-teal-100',
    textColor: 'text-teal-600',
    dotColor: 'bg-teal-500',
  },
  neutral: {
    Icon: InformationCircleIcon,
    responseText: `Interesting, but not for now.`,
    bgColor: 'bg-blue-100',
    textColor: 'text-blue-600',
    dotColor: 'bg-blue-500',
  },
  negative: {
    Icon: XIcon,
    responseText: `Thanks for sharing, this isn't for me.`,
    bgColor: 'bg-amber-100',
    textColor: 'text-amber-600',
    dotColor: 'bg-amber-500',
  },
};

export default journalistResponseOptions;
