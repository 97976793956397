import { OPPORTUNITIES_PATH } from 'constants/paths';

import OpportunitiesPage from './OpportunitiesPage';

const OpportunitiesRoute = {
  path: OPPORTUNITIES_PATH,
  authRequired: true,
  component: OpportunitiesPage,
};

export default OpportunitiesRoute;
