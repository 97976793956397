import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';

import {
  SETTINGS_BILLING_PATH,
  SETTINGS_COMPANY_PATH,
  SETTINGS_USER_PATH,
} from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useActiveSubscription from 'hooks/useActiveSubscription';

const getActiveClassFunction = (currentSection) => (section) =>
  currentSection === section
    ? 'text-teal-500 border-b-2 border-teal-500 pb-1'
    : 'text-gray-400 pb-2';

const SettingsAside = ({ currentSection }) => {
  const { hasBasicCompanyAccess } = useCompanyData();
  const hasActiveSubscription = useActiveSubscription();

  const showCompanyInfo = hasBasicCompanyAccess;

  const links = useMemo(
    () =>
      [
        {
          name: 'User',
          section: 'user',
          url: SETTINGS_USER_PATH,
          isVisible: true,
        },
        {
          name: 'Company',
          section: 'company',
          url: SETTINGS_COMPANY_PATH,
          isVisible: showCompanyInfo,
        },
        {
          name: 'Subscription & Billing',
          section: 'billing',
          url: SETTINGS_BILLING_PATH,
          isVisible: hasActiveSubscription,
        },
      ].filter((link) => link.isVisible),
    [showCompanyInfo, hasActiveSubscription]
  );

  const getActiveClass = useMemo(
    () => getActiveClassFunction(currentSection),
    [currentSection]
  );

  return (
    <aside className="bg-white shadow border gap-6 px-4 flex rounded-md w-max mb-6">
      {links.map(({ url, name, section }) => (
        <Link
          key={name}
          to={url}
          className={`${getActiveClass(section)} hover:text-teal-500 mt-2`}
        >
          {name}
        </Link>
      ))}
    </aside>
  );
};

SettingsAside.propTypes = {
  currentSection: PropTypes.string.isRequired,
};

export default SettingsAside;
