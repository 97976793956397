import React, { useState, useMemo } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { get } from 'lodash';

import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import SweetheartListJournalist from './SweetheartListJournalist/SweetheartListJournalist';
import SweetheartListJournalistDetailsModal from './SweetheartListJournalistDetailsModal/SweetheartListJournalistDetailsModal';

const SweetheartListJournalists = () => {
  const { claimsAdmin: isAdmin } = useUserData();
  const { journalists = [] } = useSweetheartListContext();

  const [journalistToShow, setJournalistToShow] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedBy, setSortedBy] = useState('');

  const getNormalizedValue = (obj, key) => {
    const value = get(obj, key);
    return typeof value === 'string' ? value.toLowerCase() : value;
  };

  const sortedJournalists = useMemo(
    () =>
      [...journalists].sort((a, b) => {
        const valueA = getNormalizedValue(a, sortedBy);
        const valueB = getNormalizedValue(b, sortedBy);

        if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
        if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
        return 0;
      }),
    [journalists, sortedBy, sortOrder]
  );

  const handleSort = (column) => {
    if (sortedBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedBy(column);
      setSortOrder('asc');
    }
  };

  return (
    <div className="w-full overflow-x-auto shadow border-b border-gray-200 sm:rounded-lg">
      <table
        className="min-w-full divide-y divide-gray-200"
        data-test-id="sweetheartList-table"
      >
        <thead className="bg-gray-50 text-xs uppercase text-gray-500">
          <tr className="text-left">
            <th
              scope="col"
              className={`px-3 py-3.5 text-left font-medium text-gray-500 cursor-pointer ${
                sortedBy === 'name' ? 'bg-gray-100' : ''
              } `}
              role="button"
              onClick={() => handleSort('name')}
            >
              <div className="group inline-flex items-center">
                Journalist
                <span className="ml-2 flex-none rounded bg-gray-100 group-hover:bg-gray-200 h-fit">
                  {sortedBy === 'name' && sortOrder === 'asc' ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </div>
            </th>
            <th
              scope="col"
              className={`px-3 py-3.5 text-left font-medium text-gray-500 cursor-pointer ${
                sortedBy === 'jobTitle' ? 'bg-gray-100' : ''
              } `}
              role="button"
              onClick={() => handleSort('jobTitle')}
            >
              <div className="group inline-flex items-center">
                Job Title
                <span className="ml-2 flex-none rounded bg-gray-100 group-hover:bg-gray-200 h-fit">
                  {sortedBy === 'jobTitle' && sortOrder === 'asc' ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </div>
            </th>

            <th
              scope="col"
              className={`px-3 py-3.5 text-left font-medium text-gray-500 cursor-pointer ${
                sortedBy === 'publication.name' ? 'bg-gray-100' : ''
              } `}
              role="button"
              onClick={() => handleSort('publication.name')}
            >
              <div className="group inline-flex items-center">
                Publication
                <span className="ml-2 flex-none rounded bg-gray-100 group-hover:bg-gray-200 h-fit">
                  {sortedBy === 'publication.name' && sortOrder === 'asc' ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </div>
            </th>

            <th
              scope="col"
              className={`px-3 py-3.5 font-medium text-gray-500 cursor-pointer text-center ${
                sortedBy === 'publication.tier' ? 'bg-gray-100' : ''
              } `}
              role="button"
              onClick={() => handleSort('publication.tier')}
            >
              <div className="group inline-flex items-center">
                Tier
                <span className="ml-2 flex-none rounded bg-gray-100 group-hover:bg-gray-200 h-fit">
                  {sortedBy === 'publication.tier' && sortOrder === 'asc' ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </div>
            </th>
            <th
              scope="col"
              className={`px-3 py-3.5 font-medium text-gray-500 cursor-pointer text-center ${
                sortedBy === 'publication.domainAuthority' ? 'bg-gray-100' : ''
              } `}
              role="button"
              onClick={() => handleSort('publication.domainAuthority')}
            >
              <div className="group inline-flex items-center">
                Domain Authority
                <span className="ml-2 flex-none rounded bg-gray-100 group-hover:bg-gray-200 h-fit">
                  {sortedBy === 'publication.domainAuthority' &&
                  sortOrder === 'asc' ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </div>
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-medium text-center text-gray-500"
            >
              Tags
            </th>

            <th
              scope="col"
              className="px-4 py-3 font-medium text-center text-gray-500 min-w-[210px]"
            >
              Reach out
            </th>

            {isAdmin && (
              <th
                scope="col"
                className="px-4 py-3 font-medium text-center text-gray-500"
              >
                Delete
              </th>
            )}
          </tr>
        </thead>
        <tbody className="text-sm text-gray-900">
          {sortedJournalists.map((journalist) => (
            <SweetheartListJournalist
              key={journalist.id}
              journalist={journalist}
              onClick={() => setJournalistToShow(journalist)}
            />
          ))}
        </tbody>
      </table>
      {journalistToShow && isAdmin ? (
        <SweetheartListJournalistDetailsModal
          journalist={journalistToShow}
          onClose={() => setJournalistToShow(null)}
        />
      ) : null}
    </div>
  );
};

export default SweetheartListJournalists;
