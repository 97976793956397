import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import classNames from 'classnames';

import CopyToClipboardButton from 'components/buttons/CopyToClipboardButton';
import getContentType from 'constants/contentTypes';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import { ClampContext } from '../contexts/ClampContext';
import getContentTypeAnalyticsEvents from '../helpers/getContentTypeAnalyticsEvents';
import getContentTypeCopyText from '../helpers/getContentTypeCopyText';

const CompanyContentFooter = ({ content = {}, children = null }) => {
  const { clampValues } = useContext(ClampContext);
  const { companyData } = useCompanyData();
  return (
    <div
      className={classNames({
        'mt-auto w-full flex justify-between items-center p-6': true,
        'pt-6': !clampValues,
        'pt-4': clampValues,
      })}
    >
      <div>{children}</div>
      <CopyToClipboardButton
        copyText={getContentTypeCopyText(content, companyData)}
        moduleName={`${getContentType(content).label} Content`}
        eventType="pressPage_view"
        eventProperties={getContentTypeAnalyticsEvents(
          content,
          'content_view_copyToClipboard'
        )}
      />
    </div>
  );
};

CompanyContentFooter.propTypes = {
  children: PropTypes.node,
  content: PropTypes.object,
};

export default CompanyContentFooter;
