const SEARCH_URL = process.env.REACT_APP_SEARCH_URL;

const getSearchSuggestions = async ({
  searchQuery = '',
  idToken = '',
  controller = new AbortController(),
}) => {
  const response = await fetch(
    `${SEARCH_URL}/search/v2/opportunities/suggestion`,
    {
      signal: controller.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        query: searchQuery,
      }),
    }
  );

  const data = await response.json();
  const documents = data.results?.documents || [];

  return documents.map((d) => ({
    id: d.suggestion,
    name: d.suggestion,
  }));
};

export default getSearchSuggestions;
