import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';

import { useLocation } from 'react-router-dom';

import Footer from './Footer';
import Navbar from './Navbar/Navbar';

/**
 * Parent wrapper for the whole app.
 * Renders the navbar next to the children and a footer below
 */
const CoreLayout = ({ children = null }) => {
  const { pathname } = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="min-h-screen flex flex-col">
        <Navbar />
        {children}
      </div>
      <Footer />
    </>
  );
};

CoreLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CoreLayout;
