import React from 'react';

import classNames from 'classnames';

import EmptyState from 'components/EmptyState';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

const CompanyNotFound = () => {
  const { companyData } = useCompanyData();
  const slug = companyData?.slug;

  return (
    <div
      className={classNames({ 'sm:ml-28': slug })}
      data-test-id="company-notFound-container"
    >
      <EmptyState
        helmetTitle="Company not found"
        testId="company-notFound"
        title="Oops, sorry!"
        subtitle=" Looks like this company doesn't exist."
      />
    </div>
  );
};

export default CompanyNotFound;
