import React, { useEffect } from 'react';

import 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth, useFirebaseApp } from 'reactfire';

import functionsConfig from 'constants/functionsConfig';
import useHandleLogout from 'hooks/useHandleLogout';

const DeleteAccountPage = () => {
  const auth = useAuth();
  const handleLogout = useHandleLogout('accountDeleted=true');

  const app = useFirebaseApp();
  const functions = getFunctions(app, functionsConfig.DEFAULT_REGION);
  const deleteUserData = httpsCallable(functions, 'auth-onDeleteUserData');

  useEffect(() => {
    (async () => {
      try {
        await deleteUserData();
        await auth?.currentUser?.delete();
        handleLogout();
      } catch (error) {
        // TODO: send to logging service
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white">
      <p className="p-4">Deleting account... This may take a few seconds.</p>
    </div>
  );
};

export default DeleteAccountPage;
