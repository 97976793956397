import { collection, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';

// get a company ref by its slug
const useCompanyDocBySlug = (slug = '') => {
  const firestore = useFirestore();

  const col = collection(firestore, COMPANIES_COLLECTION);
  const q = slug ? query(col, where('slug', '==', slug)) : col;

  // get ref from collection
  const companyCollection = useFirestoreCollection(q, defaultOptions);
  return companyCollection?.data?.docs?.[0] ?? null;
};

export default useCompanyDocBySlug;
