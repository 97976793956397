import { SIGNUP_AGENCY_PATH as path } from 'constants/paths';

import SignupAgencyPage from './SignupAgencyPage';

const SignupAgencyRoute = {
  path,
  component: SignupAgencyPage,
};

export default SignupAgencyRoute;
