import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { INBOX_PATH } from 'constants/paths';

const InboxNavbarTabsItem = ({ tab = {} }) => {
  const navigate = useNavigate();
  const { tab: activeTab } = useParams();
  const isActive = tab.field === activeTab;
  const path = generatePath(INBOX_PATH, { tab: tab.field });

  return (
    <a
      key={tab.field}
      className={classNames(
        'block pl-2 xl:py-3 xl:pb-4 xl:px-4 cursor-pointer w-auto space-x-1 text-sm hover:text-teal-500',
        {
          'border-none xl:border-solid border-b-2 border-teal-500 font-medium text-teal-500':
            isActive,
        }
      )}
      onClick={() => {
        if (isActive) {
          return;
        }

        window.scrollTo(0, 0);
        navigate(path);
      }}
      data-test-id={`tab-${tab.field}`}
    >
      {tab.title}
    </a>
  );
};

InboxNavbarTabsItem.propTypes = {
  tab: PropTypes.shape({
    field: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default InboxNavbarTabsItem;
