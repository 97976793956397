import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { doc } from 'firebase/firestore';
import { generatePath } from 'react-router-dom';
import { useFirestore } from 'reactfire';

import Label from 'components/form/Label';
import {
  INBOXES_COLLECTION,
  INBOXES_THREADS_COLLECTION,
  INBOXES_THREADS_MESSAGES_COLLECTION,
} from 'constants/firebasePaths';
import { ADMIN_MESSAGES_PATH } from 'constants/paths';

import AdminCreateCustomThreadResultsResult from './AdminCreateCustomThreadResultsResult';

const generateMessageRefPath = ({ companyId, threadId, messageId }) => [
  INBOXES_COLLECTION,
  companyId,
  INBOXES_THREADS_COLLECTION,
  threadId,
  INBOXES_THREADS_MESSAGES_COLLECTION,
  messageId,
];

const AdminCreateCustomThreadResults = ({ results = null }) => {
  const firestore = useFirestore();
  const enrichedResults = useMemo(
    () =>
      results.map((res) => ({
        ...res,
        path: generatePath(ADMIN_MESSAGES_PATH, {
          type: 'companies',
          inboxId: res.companyId,
          threadId: res.threadId,
        }),
        messageRef: doc(firestore, ...generateMessageRefPath(res)),
      })),
    [results, firestore]
  );

  return (
    <div className="space-y-2 col-span-3">
      <Label>Results</Label>

      <div className=" bg-white p-2 rounded-md border flex flex-col gap-2 text-gray-700">
        <div className="gap-2 grid lg:grid-cols-2 xl:grid-cols-3">
          {enrichedResults.map((res) => (
            <AdminCreateCustomThreadResultsResult
              result={res}
              key={res.threadId}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

AdminCreateCustomThreadResults.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object),
};

export default AdminCreateCustomThreadResults;
