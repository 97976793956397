import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';

import Notifications from './Notifications';

export const NotificationsContext = React.createContext({
  showSuccess: () => {},
  showError: () => {},
  showWarning: () => {},
});

export const useNotifications = () => useContext(NotificationsContext);

const MAX_NOTIFICATIONS = 3;

const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState(new Map());

  const setNotification = (key, notification) => {
    setNotifications((previousNotifications) => {
      const newNotifications = new Map(previousNotifications);

      if (notification) {
        if (newNotifications.size >= MAX_NOTIFICATIONS) {
          const firstNotificationKey = [...newNotifications.keys()][0];
          newNotifications.delete(firstNotificationKey);
        }

        newNotifications.set(key, {
          ...newNotifications.get(key),
          ...notification,
        });
      } else {
        newNotifications.delete(key);
      }

      return newNotifications;
    });
  };

  const deleteNotification = (key) => {
    setNotification(key, null);
  };

  const addNotification = (notification) => {
    const notificationWithKey = {
      ...notification,
      key: new Date().getTime() + Math.random(),
    };

    setNotification(notificationWithKey.key, notificationWithKey);
  };

  const contextValue = useMemo(
    () => ({
      showSuccess: (notification) =>
        addNotification({ ...notification, type: 'success' }),
      showError: (notification) =>
        addNotification({ ...notification, type: 'error' }),
      showWarning: (notification) =>
        addNotification({ ...notification, type: 'warning' }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
      <Notifications
        deleteNotification={deleteNotification}
        notifications={notifications}
      />
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NotificationsProvider;
