import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';

import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldCurrency from 'components/form/inputFields/InputFieldCurrency/InputFieldCurrency';

const ContentElementFundraiseNewValuation = ({ index = 0 }) => {
  const name = 'valuation';
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, `${name}.amount`, null);
  const currency = watch(`${name}.currency`);

  return (
    <div className="w-full">
      <div className="space-y-2">
        <div className="flex gap-1 items-center ">
          <Label htmlFor={`${name}${index}`}>
            New valuation of the company
          </Label>
        </div>
        <InputFieldCurrency
          control={control}
          inputRules={{
            valueAsNumber: true,
          }}
          id={`${name}${index}`}
          currencyDefaultName={currency}
          nameAmount={`${name}.amount`}
          nameCurrency={`${name}.currency`}
          testId="company-content-fundraise-inputValuation"
        />
        <small className="text-xs text-gray-500">
          Leave as 0 to show as undisclosed
        </small>
      </div>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </div>
  );
};

ContentElementFundraiseNewValuation.propTypes = {
  index: PropTypes.number,
};

export default ContentElementFundraiseNewValuation;
