import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { ThumbDownIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import Button from 'components/buttons/Button';
import { OpportunitiesContext } from 'contexts/OpportunitiesContext/OpportunitiesContext';

const OpportunitiesListResponseModalLeftActions = ({
  onClose = noop,
  opportunity = {},
}) => {
  const { thread } = opportunity;
  const { companiesOpportunitiesOps } = useContext(OpportunitiesContext);

  if (thread?.exists) {
    return null;
  }
  return (
    <Button
      type="secondary"
      className="space-x-1"
      onClick={() => {
        companiesOpportunitiesOps.decline(opportunity);
        onClose();
      }}
    >
      <ThumbDownIcon className="w-4" /> <span>Decline</span>
    </Button>
  );
};

OpportunitiesListResponseModalLeftActions.propTypes = {
  onClose: PropTypes.func.isRequired,
  opportunity: PropTypes.object.isRequired,
};

export default OpportunitiesListResponseModalLeftActions;
