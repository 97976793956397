import PropTypes from 'prop-types';
import React from 'react';

import { useParams } from 'react-router-dom';

import { ContentTypes } from 'constants/contentTypes';
import useCompanyDocBySlug from 'hooks/useCompanyDocBySlug';
import formatTimestamp from 'utils/date/formatTimestamp';

import CompanyContentElementTableRow from './CompanyContentElementTableRow';

const CompanyContentElementTableFundraise = ({ content = {} }) => {
  const { capitalRaised, fundingRound, valuation, leadInvestors, embargoDate } =
    content;
  const { id } = useParams();

  // since we cannot retrieve this from the `content` we need to look for it in the `companyData`
  const companyData = useCompanyDocBySlug(id, true)?.data() || {};

  const valuationProps =
    valuation?.amount === 0
      ? {
          content: 'undisclosed',
        }
      : {
          content: valuation?.amount,
          currency: valuation?.currency,
          type: 'currency',
        };

  return (
    <>
      <CompanyContentElementTableRow
        label="Company"
        contentType={ContentTypes.FUNDRAISE}
        content={companyData?.name || 'N/A'}
      />
      <CompanyContentElementTableRow
        label="Funding Amount"
        type="currency"
        contentType={ContentTypes.FUNDRAISE}
        content={capitalRaised.amount}
        currency={capitalRaised.currency}
      />
      <CompanyContentElementTableRow
        label="Round"
        content={fundingRound}
        contentType={ContentTypes.FUNDRAISE}
      />
      <CompanyContentElementTableRow
        label="Valuation"
        {...valuationProps}
        contentType={ContentTypes.FUNDRAISE}
      />
      <CompanyContentElementTableRow
        label="Lead Investor"
        type="links"
        content={leadInvestors}
      />
      <CompanyContentElementTableRow
        label="Embargo Date"
        contentType={ContentTypes.FUNDRAISE}
        content={formatTimestamp(embargoDate)}
      />
    </>
  );
};

CompanyContentElementTableFundraise.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CompanyContentElementTableFundraise;
