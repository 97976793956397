import React from 'react';

import { useLocation } from 'react-router-dom';

import { AGENCY_DASHBOARD_PATH } from 'constants/paths';

import AgencyWrapperBreadcrumb from './AgencyWrapperBreadcrumb';

const AgencyWrapperBreadcrumbs = () => {
  const { state } = useLocation();

  const pages = [
    {
      name: 'Companies',
      pattern: AGENCY_DASHBOARD_PATH,
    },
    {
      name: state?.name || 'Company',
      pattern: `${AGENCY_DASHBOARD_PATH}/:companyId`,
    },
  ];

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {pages.map((page, index) => (
          <AgencyWrapperBreadcrumb
            key={page?.name}
            {...page}
            isFirst={index === 0}
          />
        ))}
      </ol>
    </nav>
  );
};

export default AgencyWrapperBreadcrumbs;
