import successFeeStatusConfigs from 'constants/successFeeStatusConfigs';

const getStatusConfig = (status = '') => {
  const statusConfig = successFeeStatusConfigs.find((config) =>
    config.condition(status)
  );

  return {
    ...statusConfig,
    name: statusConfig?.name || status,
  };
};

export default getStatusConfig;
