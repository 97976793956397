import {
  UserAddIcon,
  CurrencyPoundIcon,
  TrendingUpIcon,
  NewspaperIcon,
  SpeakerphoneIcon,
  BeakerIcon,
} from '@heroicons/react/solid';

import { ReactComponent as MergeIcon } from 'static/icon-merge.svg';

export const ContentTypes = {
  OPED: 'opEd',
  HIRE: 'hire',
  MA: 'm&a',
  FUNDRAISE: 'fundraise',
  IPO: 'ipo',
  BRAND: 'brand',
  PRODUCT: 'product-service',
  PARTNERSHIP: 'partnership',
};

export const contentTypeTags = [
  {
    id: 8000,
    name: ContentTypes.OPED,
    Icon: NewspaperIcon,
    label: 'Thought Leadership',
    description:
      'Share your expertise with an idea for a thought leadership article',
  },
  {
    id: 8001,
    name: ContentTypes.HIRE,
    Icon: UserAddIcon,
    label: 'Hire',
    description:
      'Announce a significant hire  (i.e. C-suite level or leadership)',
  },
  {
    id: 8002,
    name: ContentTypes.MA,
    Icon: MergeIcon,
    label: 'Merger & Acquisition',
    description:
      "Share the details of your company's merger or acquisition with journalists",
  },
  {
    id: 8003,
    name: ContentTypes.FUNDRAISE,
    Icon: CurrencyPoundIcon,
    label: 'Funding Announcement',
    description: "Announce your company's latest fundraise",
  },
  {
    id: 8004,
    name: ContentTypes.IPO,
    Icon: TrendingUpIcon,
    label: 'Planned IPO',
    description: 'Share the details of your IPO with journalists',
  },
  {
    id: 8005,
    name: ContentTypes.BRAND,
    Icon: SpeakerphoneIcon,
    label: 'Brand Announcement',
    description: 'Share an announcement about your company with the press',
  },
  {
    id: 8006,
    name: ContentTypes.PRODUCT,
    Icon: BeakerIcon,
    label: 'Product/Service Announcement',
    description: 'Share news of your latest product or service',
  },
  {
    id: 8007,
    name: ContentTypes.PARTNERSHIP,
    Icon: BeakerIcon,
    label: 'Partnership Announcement',
    description: 'Share news of your recent partnerships',
  },
];

const getContentType = (content) =>
  contentTypeTags.find((tag) => tag.name === content.type) || null;

export default getContentType;
