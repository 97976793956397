const stripePortalConfigNames = {
  SUBSCRIPTION_UPDATE: 'subscriptionUpdate',
  SUBSCRIPTION_CANCEL: 'subscriptionCancel',
  BILLING_UPDATE: 'billingUpdate',
  PAYMENT_UPDATE: 'paymentUpdate',
  INVOICE_HISTORY: 'invoiceHistory',
  SUBSCRIPTION_REACTIVATION: 'subscriptionReactivation',
};

export default stripePortalConfigNames;
