import { useEffect, useState } from 'react';

const SEARCH_URL = process.env.REACT_APP_SEARCH_URL;

// retrive suggestions from the search eindpoint for a given query
const useCompanySuggestionsBySearch = (searchQuery = '', idToken = '') => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const loadSuggestions = async () => {
      try {
        const response = await fetch(`${SEARCH_URL}/search/suggestion`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            query: searchQuery,
          }),
        });
        const data = await response.json();
        setSuggestions(data.results?.documents || []);
      } catch (error) {
        // Don't do anything on error for now
      }
    };
    loadSuggestions();
  }, [searchQuery, idToken]);

  return suggestions;
};

export default useCompanySuggestionsBySearch;
