import PropTypes from 'prop-types';
import React from 'react';

import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/solid';

import Tag from 'components/tags/Tag';

const tagProps = {
  className: '!my-0 !mr-0',
  uppercase: true,
  bold: true,
};

const AdminMessagesMessageIncomingOutgoing = ({ outgoing = false }) => {
  if (outgoing) {
    return (
      <Tag {...tagProps} bgColor="blue-200" textColor="blue-700">
        <ArrowRightIcon className="w-4 h-4 mr-1" />
        outgoing
      </Tag>
    );
  }

  return (
    <Tag {...tagProps} bgColor="amber-200" textColor="amber-700">
      <ArrowLeftIcon className="w-4 h-4 mr-1" />
      incoming
    </Tag>
  );
};

AdminMessagesMessageIncomingOutgoing.propTypes = {
  outgoing: PropTypes.bool,
};

export default AdminMessagesMessageIncomingOutgoing;
