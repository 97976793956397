import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Tag from 'components/tags/Tag';
import successFeeStatuses from 'constants/successFeeStatuses';
import getStatusConfig from 'utils/getStatusConfig';

const SuccessFeesStatus = ({
  status = '',
  error = '',
  stripeDashboardLink = '',
}) => {
  if (!status) {
    return null;
  }

  const { name, textColor, bgColor } = getStatusConfig(status);

  if (successFeeStatuses.ERROR === status) {
    return (
      <div className="flex flex-col">
        <Tag
          uppercase
          bold
          textColor={textColor}
          bgColor={bgColor}
          className="bg-opacity-40 w-fit"
          data-tooltip-content={error}
          data-tooltip-id="tooltip-successFees-status"
        >
          {name}
        </Tag>
        <Link
          className="lowercase text-sm text-red-700 hover:underline underline-offset-2"
          onClick={(e) => e.stopPropagation()}
          to={stripeDashboardLink}
          target="_blank"
        >
          (click to fix)
        </Link>
        <ReactTooltip
          id="tooltip-successFees-status"
          className="max-w-xs whitespace-pre-wrap text-sm"
        />
      </div>
    );
  }

  return (
    <Tag
      uppercase
      bold
      textColor={textColor}
      bgColor={bgColor}
      className="bg-opacity-40"
    >
      {name}
    </Tag>
  );
};

SuccessFeesStatus.propTypes = {
  error: PropTypes.string,
  status: PropTypes.oneOf(['', ...Object.values(successFeeStatuses)]),
  stripeDashboardLink: PropTypes.string,
};

export default SuccessFeesStatus;
