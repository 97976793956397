import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import { useParams } from 'react-router-dom';

import useUserName from 'components/layout/Navbar/hooks/useUserName';
import { tabNames } from 'constants/inboxTabs';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import responseTemplates from 'routes/Inbox/InboxMessages/InboxMessage/InboxMessageResponseForm/helpers/responseTemplates';

import getSignature from './helpers/getSignature';

const getDefaultDraft = ({
  opportunity = {},
  companyData = {},
  userName = '',
  tab = tabNames.DRAFT,
}) =>
  tab === tabNames.DRAFT
    ? {
        template: 'Blank Draft',
        html:
          responseTemplates['Blank Draft']({
            companyData,
            opportunity,
          }) + getSignature(userName, companyData.name),
        attachments: [],
      }
    : {
        html: getSignature(userName, companyData.name),
        attachments: [],
      };

const InboxMessageResponseFormDraftProvider = ({
  children = null,
  thread = {},
  threadRef = {},
  setDocument = noop,
}) => {
  const userName = useUserName();
  const { companyData } = useCompanyData();
  const { tab } = useParams();
  const { opportunity, draft: draftData } = thread;
  const defaultDraft = getDefaultDraft({
    opportunity,
    companyData,
    userName,
    tab,
  });

  const isInitialLoad = useRef(false);
  const [initialTemplate] = useState(draftData?.html || defaultDraft.html);
  const [draft, setDraft] = useState(draftData || defaultDraft);

  useEffect(() => {
    if (!draftData?.html && draftData?.html !== '') {
      setDraft(defaultDraft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftData?.html, defaultDraft?.html]);

  const debouncedSaveDraftInFirebase = useMemo(
    () =>
      debounce((newDraft) => {
        setDocument(
          threadRef,
          {
            draft: {
              ...newDraft,
              lastEdited: new Date(),
            },
          },
          {
            merge: true,
          }
        );
      }, 1000),
    [setDocument, threadRef]
  );

  const saveDraft = useCallback(
    (newDraft = {}, cb = noop) => {
      setDraft((currentDraft) => {
        const mergedDraft = { ...currentDraft, ...newDraft };
        if (!isInitialLoad.current) {
          debouncedSaveDraftInFirebase(mergedDraft);
          cb();
        }
        isInitialLoad.current = false;
        return mergedDraft;
      });
    },
    [debouncedSaveDraftInFirebase]
  );

  return children({
    initialTemplate,
    draft,
    saveDraft,
  });
};

export default InboxMessageResponseFormDraftProvider;
