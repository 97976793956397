const dotsMenuThemes = {
  light: {
    dotsClassName: 'text-white hover:text-gray-200',
    focusClassName: 'focus:bg-gray-100',
  },
  medium: {
    dotsClassName: 'text-gray-200 hover:text-gray-400',
    focusClassName: 'focus:bg-gray-300',
  },
  dark: {
    dotsClassName: 'text-gray-400 hover:text-gray-600',
    focusClassName: 'focus:bg-gray-500',
  },
};

export default dotsMenuThemes;
