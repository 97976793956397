import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { InboxIcon } from '@heroicons/react/outline';
import { generatePath } from 'react-router-dom';

import { tabNames } from 'constants/inboxTabs';
import { INBOX_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useImpersonation from 'contexts/ImpersonationContext/hooks/useImpersonation';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import getCompanyRoute from 'utils/getCompanyRoute';

import AccountLink from './AccountLink';
import AccountMenu from './AccountMenu';
import Aside from './Aside/Aside';
import useUnreadMessagesCount from './Aside/hooks/useUnreadMessagesCount';
import HomeLogo from './HomeLogo';
import MobileNav from './MobileNav/MobileNav';
import NavbarInboxUnreadIcon from './NavbarInboxUnreadIcon';
import NavbarLink from './NavbarLink';
import useRouteMatchNav from './hooks/useRouteMatchNav';

/**
 * Renders the top navbar with the following components:
 * - logo on the left
 * - account menu or account link on the right, hamburger icon on mobile
 * - mobile menu as an overlay on mobile
 * - aside with nav options (press page, opportunities,...) for logged-in users on the left
 */
const Navbar = ({ children = null }) => {
  const { isAnonymous, uid, claimsAdmin, claimsJournalist } = useUserData();
  const { companyData, hasBasicCompanyAccess, isAgencyOfCompany } =
    useCompanyData();
  const { impersonatedCompanySlug } = useImpersonation();

  const unreadMessagesCount = useUnreadMessagesCount(companyData?.uid);

  const slug = companyData?.slug;

  const [homeRoute, setHomeRoute] = useState('/');
  // Get the company slug for the home link
  useEffect(() => {
    if (slug) {
      setHomeRoute(getCompanyRoute(slug));
    }
  }, [setHomeRoute, slug]);

  // nav-specific logic
  const [isOnboardingRoute, showTopNav] = useRouteMatchNav();
  const isPrivilegedUser = hasBasicCompanyAccess || claimsJournalist;

  // if we're on an onboarding route, don't render any navigation
  if (isOnboardingRoute) {
    return null;
  }

  const revealNav = showTopNav && hasBasicCompanyAccess;

  const showAgencyInbox = isAgencyOfCompany && impersonatedCompanySlug;

  // only show logged in user if NOT anonymous and has UID
  const authExists = !isAnonymous && uid;

  return (
    <nav className="sticky top-0 w-full z-20 bg-teal-500" data-test-id="navbar">
      <div className="relative flex justify-between px-2 sm:px-4 h-16">
        <HomeLogo
          homeRoute={isPrivilegedUser && homeRoute}
          testId="homeLogo"
          className="ml-2 sm:ml-0"
          white
        />
        {children}
        {authExists ? (
          <div className="flex items-center sm:ml-6 gap-x-2">
            {(revealNav || claimsAdmin || showAgencyInbox) && (
              <NavbarLink
                path={generatePath(INBOX_PATH, { tab: tabNames.ACTIVE })}
                name="Inbox"
                Badge={
                  <NavbarInboxUnreadIcon
                    unreadMessagesCount={unreadMessagesCount}
                  />
                }
                testId="navbar-inbox"
              >
                <InboxIcon className="h-6 w-6 p-px white" />
              </NavbarLink>
            )}
            <AccountMenu />
            {(revealNav || claimsAdmin) && (
              <MobileNav
                slug={slug}
                unreadMessagesCount={unreadMessagesCount}
              />
            )}
          </div>
        ) : (
          <AccountLink />
        )}
        {revealNav && <Aside slug={slug} />}
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  children: PropTypes.node,
};

export default Navbar;
