import PropTypes from 'prop-types';
import React from 'react';

import Card from 'components/Card';

import BillingSettingsSubscriptionFeatures from './BillingSettingsSubscriptionFeatures';
import BillingSettingsSubscriptionStatus from './BillingSettingsSubscriptionStatus/BillingSettingsSubscriptionStatus';

const BillingSettingsSubscription = ({ subscriptionData = {} }) => {
  const {
    price,
    name,
    currencySymbol,
    createdDate,
    includedFeatures,
    willCancel,
    willCancelAtDate,
  } = subscriptionData;

  return (
    <Card title="Subscription Plan" className="grow">
      <div className="w-full max-w-5xl">
        <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3">
          <div className="col-span-2 sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Package name</dt>
            <dd className="mt-1 text-sm text-gray-900">{name}</dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Price</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {currencySymbol}
              {price} /mo
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Subscription date
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{createdDate}</dd>
          </div>
          <BillingSettingsSubscriptionStatus
            subscriptionName={name}
            willCancel={willCancel}
            willCancelAtDate={willCancelAtDate}
          />
          <BillingSettingsSubscriptionFeatures
            includedFeatures={includedFeatures}
          />
        </dl>
      </div>
    </Card>
  );
};

BillingSettingsSubscription.propTypes = {
  subscriptionData: PropTypes.object,
};

export default BillingSettingsSubscription;
