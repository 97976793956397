import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { InformationCircleIcon } from '@heroicons/react/solid';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  verifyBeforeUpdateEmail,
  updatePassword,
} from 'firebase/auth';
import get from 'lodash/get';
import { Link } from 'react-router-dom';

import Callout from 'components/Callout/Callout';
import Card from 'components/Card';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

import UpdateEmailModal from './UpdateEmailModal';
import UpdatePasswordModal from './UpdatePasswordModal';

const AccountForm = ({ user }) => {
  const { showSuccess, showError } = useNotifications();

  const { credential } = EmailAuthProvider;

  const [updateEmailModalOpen, setUpdateEmailModalOpen] = useState(false);
  const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = useState(false);

  const isGoogleAccount =
    get(user, 'providerData.0.providerId') === 'google.com';

  const onSubmitEmail = async ({ password, newEmail }) => {
    try {
      const credentials = credential(user.email, password);

      await reauthenticateWithCredential(user, credentials);
      await verifyBeforeUpdateEmail(user, newEmail);

      setUpdateEmailModalOpen(false);
      showSuccess({
        title: 'Email address updated',
        message: 'Please check your inbox',
      });
    } catch (e) {
      setUpdateEmailModalOpen(false);
      showError({
        title: 'Something went wrong',
        message: 'Please try again!',
      });
    }
  };

  const onSubmitPassword = async ({ password, newPassword }) => {
    try {
      const credentials = credential(user.email, password);
      await reauthenticateWithCredential(user, credentials);
      await updatePassword(user, newPassword);

      setUpdatePasswordModalOpen(false);
      showSuccess({ message: 'Your password has been updated' });
    } catch (e) {
      setUpdatePasswordModalOpen(false);
      showError({
        title: 'Something went wrong',
        message: 'Please try again!',
      });
    }
  };

  return (
    <>
      <Card title="Account data">
        <div className="w-full">
          <InputFieldText name="email" defaultValue={user.email} disabled>
            <InputFieldText.Label>Email</InputFieldText.Label>
          </InputFieldText>

          {isGoogleAccount ? (
            <Callout
              type="info"
              Icon={InformationCircleIcon}
              wrapperClassName="w-fit"
            >
              You&apos;ve logged in using Google. You can&apos;t change the
              password or email address. If you want to unlink your account from
              Google, please get in touch with{' '}
              <a
                href="mailto:support@mvpr.io"
                className="underline font-semibold"
              >
                support@mvpr.io
              </a>
            </Callout>
          ) : (
            <div className="mt-2">
              <Button
                href="#"
                className="mr-2"
                type="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  setUpdateEmailModalOpen(true);
                }}
              >
                Change email
              </Button>
              <Link
                to="#"
                className="font-medium text-sm text-gray-700 hover:text-gray-500 py-2 px-3"
                onClick={(e) => {
                  e.preventDefault();
                  setUpdatePasswordModalOpen(true);
                }}
              >
                Reset password
              </Link>
            </div>
          )}
        </div>
      </Card>

      <UpdateEmailModal
        open={updateEmailModalOpen}
        onClose={() => setUpdateEmailModalOpen(false)}
        onSubmit={onSubmitEmail}
      />

      <UpdatePasswordModal
        open={updatePasswordModalOpen}
        onClose={() => setUpdatePasswordModalOpen(false)}
        onSubmit={onSubmitPassword}
      />
    </>
  );
};

AccountForm.propTypes = {
  user: PropTypes.object.isRequired,
};

export default AccountForm;
