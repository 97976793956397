const maxFileSize = {
  maxFilesize: 1024 * 1024 * 10, // 10MB
  maxFilesizeErrorText: 'Please provide an image that is less than 10MB.',
};

const presetImage = {
  ...maxFileSize,
  name: 'image',
  storageRef: 'images',
  accept: {
    'image/png': [],
    'image/jpg': [],
    'image/jpeg': [],
    'image/gif': [],
    'image/webp': [],
  },
  acceptText: 'PNG, JPG, GIF or WEBP up to 10MB',
  acceptErrorText:
    'Please provide a file in one of the following formats: PNG, JPG, GIF or WEBP',
};

const presetPdf = {
  ...maxFileSize,
  name: 'pdf',
  accept: { 'application/pdf': [] },
  acceptText: 'PDF up to 10MB',
  acceptErrorText: 'Please provide a file in PDF format.',
};

const presetDoc = {
  ...maxFileSize,
  name: 'doc',
  accept: {
    'application/msword': [],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      [],
  },
  acceptText: 'DOC or DOCX up to 10MB',
  acceptErrorText: 'Please provide a file in DOC or DOCX format.',
};

const presetFile = {
  ...maxFileSize,
  name: 'file',
  storageRef: 'files',
  accept: {
    ...presetImage.accept,
    ...presetDoc.accept,
    ...presetPdf.accept,
    'image/svg+xml': [],
  },
  acceptText: 'PNG, JPG, GIF, PDF, WEBP or DOCX up to 10MB',
  acceptErrorText:
    'Please provide a file in one of the following formats: PNG, JPG, GIF, WEBP, PDF or DOCX',
};

const presetFileWithZip = {
  ...maxFileSize,
  name: 'fileWithZip',
  accept: { ...presetFile.accept, 'application/zip': [] },
  acceptText: 'PNG, JPG, GIF, PDF, DOCX or ZIP up to 10MB',
  acceptErrorText:
    'Please provide a file in one of the following formats: PNG, JPG, GIF, PDF, DOCX or ZIP',
};

const uploadPresets = {
  image: presetImage,
  file: presetFile,
  fileWithZip: presetFileWithZip,
  pdf: presetPdf,
  doc: presetDoc,
};

export default uploadPresets;
