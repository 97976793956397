import React from 'react';

import { doc } from 'firebase/firestore';
import reactDom from 'react-dom';
import { useFirestore, useFirestoreDocData } from 'reactfire';

import {
  CLOUD_FUNCTIONS_COLLECTION,
  CLOUD_FUNCTIONS_COLLECTION_MESSAGES_DOC,
} from 'constants/firebasePaths';

import AdminMessagesTimerClock from './AdminMessagesTimerClock';

const AdminMessagesTimer = () => {
  const firestore = useFirestore();

  const messagesRef = doc(
    firestore,
    CLOUD_FUNCTIONS_COLLECTION,
    CLOUD_FUNCTIONS_COLLECTION_MESSAGES_DOC
  );

  const {
    data: { startedAt, isRunning, interval },
  } = useFirestoreDocData(messagesRef);

  return (
    <>
      <AdminMessagesTimerClock
        startedAt={startedAt}
        isRunning={isRunning}
        interval={interval}
      />
      {isRunning
        ? reactDom.createPortal(
            <div className="top-0 fixed flex justify-center items-center w-full h-full bg-white/80">
              <p>Sending the messages...</p>
            </div>,
            document.body
          )
        : null}
    </>
  );
};

export default AdminMessagesTimer;
