import getDateFromTimestamp from './getDateFromTimestamp';
import getTimeFromTimestamp from './getTimeFromTimestamp';

// formats a timestamp, optionally with the time appended to the date
// eslint-disable-next-line max-params
const formatTimestamp = (timestamp = '', withTime = false) => {
  const date = getDateFromTimestamp(timestamp);
  const time = getTimeFromTimestamp(timestamp);

  if (!withTime) {
    return date;
  }

  return `${date} - ${time}`;
};

export default formatTimestamp;
