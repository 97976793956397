import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';
import { Link } from 'react-router-dom';

import { PAGE_TITLE } from 'constants/meta';
import { ReactComponent as Icon } from 'static/mvpr-icon.svg';
import { ReactComponent as Logo } from 'static/mvpr-logo.svg';

// Displays the logo linking back to the home route
const HomeLogo = ({
  homeRoute = '/',
  className = '',
  wrapperClassName = '',
  testId = '',
  white = false,
  iconOnly = false,
  onClick = null,
}) => {
  const Component = homeRoute ? Link : 'div';
  const altText = `${PAGE_TITLE} logo`;
  return (
    <h1
      className={classNames(
        'flex items-center',
        {
          'text-teal-500': !white,
          'text-white': white,
        },
        wrapperClassName
      )}
    >
      {iconOnly ? (
        <Icon
          className={classNames('w-10/12 m-auto', className)}
          alt={altText}
        />
      ) : (
        <Component
          to={homeRoute}
          data-test-id={testId}
          onClick={onClick || noop}
        >
          <Logo
            className={classNames('h-auto w-24 -mb-2', className)}
            alt={altText}
          />
        </Component>
      )}
      <span className="sr-only">{PAGE_TITLE}</span>
    </h1>
  );
};

HomeLogo.propTypes = {
  homeRoute: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  testId: PropTypes.string,
  white: PropTypes.bool,
  iconOnly: PropTypes.bool,
  onClick: PropTypes.func,
};

export default HomeLogo;
