import { SWEETHEART_PATH } from 'constants/paths';

import SweetheartListPage from './SweetheartListPage';

const SweetheartListRoute = {
  path: SWEETHEART_PATH,
  authRequired: true,
  component: SweetheartListPage,
};

export default SweetheartListRoute;
