import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { EyeIcon } from '@heroicons/react/outline';
import { ArchiveIcon, StarIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';
import OpportunityMeta from 'components/opportunities/opportunity/OpportunityMeta';
import OpportunityTimeLeft from 'components/opportunities/opportunity/OpportunityTimeLeft';
import capitalizeFirstLetter from 'components/opportunities/opportunity/helpers/capitalizeFirstLetter';
import Tag from 'components/tags/Tag';
import useGetSuccessFees from 'hooks/useGetSuccessFees';

import SuccessFeesStatus from '../../../../../components/successFees/SuccessFeesStatus';

import AdminMessagesThreadsThreadJournalist from './AdminMessagesThreadsThreadJournalist';

const AdminMessagesThreadsThread = ({
  opportunity = {},
  threadRef = {},
  journalist = {},
  isArchived = false,
  handleShowQuery = noop,
  reload = noop,
}) => {
  const [isJournalistModalOpen, setIsJournalistModalOpen] = useState(false);
  const { deadlineUTC, enquirySummary, custom, subject } = opportunity;
  const { invoice } = useGetSuccessFees(threadRef);
  if (!Object.keys(opportunity).length) {
    return null;
  }

  const expired = deadlineUTC?.toDate() < new Date();

  return (
    <td className="p-4 w-full">
      <div className="flex flex-none flex-col justify-center">
        <div className="flex flex-wrap">
          {opportunity.custom && (
            <Tag bold bgColor="teal-200" textColor="teal-600">
              <StarIcon className="w-4 mr-1" />
              Custom Opportunity
            </Tag>
          )}
          <OpportunityTimeLeft
            deadlineUTC={opportunity.deadlineUTC}
            expired={expired}
            wrapperClassName="my-1 mr-2"
            standalone
          />
          {isArchived && (
            <Tag uppercase bold textColor="amber-700" bgColor="amber-200">
              <ArchiveIcon className="w-4 h-4 mr-1" />
              archived
            </Tag>
          )}
          <SuccessFeesStatus
            status={invoice?.status}
            error={invoice?.error}
            stripeDashboardLink={invoice?.dashboardLink}
          />
        </div>

        <div className="flex items-baseline gap-2 mt-2 flex-wrap">
          <h3 className="font-semibold">
            {subject || capitalizeFirstLetter(enquirySummary)}
          </h3>
          <button
            type="button"
            onClick={handleShowQuery}
            className="text-xs flex gap-1 text-teal-600 hover:underline underline-offset-2 min-w-max"
          >
            <span>View Query</span>
            <EyeIcon className="w-4 h-4" />
          </button>
        </div>

        <div className="mt-2 flex items-center gap-4 flex-wrap">
          <div className="flex gap-2 items-center">
            <PublicationImage
              custom={custom}
              url={journalist?.publication?.image}
              name={journalist?.publication?.name}
            />
            <div className="flex items-center flex-wrap gap-x-2 gap-y-0">
              <OpportunityMeta
                text={journalist?.publication?.name}
                className="max-w-[10rem]"
              />
              <DomainAuthorityPill
                domainAuthority={journalist?.publication?.domainAuthority}
              />
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <JournalistImage url={journalist?.image} />
            <div className="flex items-baseline flex-wrap gap-x-2 gap-y-0">
              <OpportunityMeta text={journalist?.name || ''} />
              <AdminMessagesThreadsThreadJournalist
                journalist={journalist}
                isJournalistModalOpen={isJournalistModalOpen}
                setIsJournalistModalOpen={async (v) => {
                  await reload();
                  setIsJournalistModalOpen(v);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </td>
  );
};

AdminMessagesThreadsThread.propTypes = {
  opportunity: PropTypes.object,
  journalist: PropTypes.object,
  isArchived: PropTypes.bool,
  threadRef: PropTypes.object,
  handleShowQuery: PropTypes.func,
  reload: PropTypes.func,
};

export default AdminMessagesThreadsThread;
