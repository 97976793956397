import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import { actions } from '../journalistReducer';

import JournalistFiltersCheckboxes from './JournalistFiltersCheckboxes';
import JournalistFiltersFundingAmount from './JournalistFiltersFundingAmount';
import JournalistFiltersTopic from './JournalistFiltersTopic';

const JournalistFilters = ({
  className = '',
  companySearchFilters = {},
  journalistState = {},
  fundingLevels = [],
  dispatch = noop,
}) => {
  const {
    selectedTopics,
    selectedStages,
    selectedContentTypes,
    minFundingIndex,
    maxFundingIndex,
  } = journalistState;

  const { filters, filterNames, contentTypeNames } = companySearchFilters;

  const topics = filters?.topics[0].data.map((t) => ({
    name: t.value,
    ...t,
  }));

  const stages = filters?.fundraise_funding_rounds[0].data;
  const contentTypes = filters?.content_types[0].data;
  const fundingCounts = filters?.fundraise_capital_raised_amounts[0].data;

  return (
    <form className={className}>
      <JournalistFiltersTopic
        name={filterNames?.topics}
        topics={topics}
        selectedTopics={selectedTopics}
        onChange={(newTopics) =>
          dispatch({ type: actions.SET_TOPICS, payload: newTopics })
        }
      />
      <JournalistFiltersCheckboxes
        name={filterNames?.fundraise_funding_rounds}
        options={stages}
        selectedOptions={selectedStages}
        onChange={(stage) =>
          dispatch({ type: actions.TOGGLE_STAGE, payload: stage })
        }
      />
      <JournalistFiltersFundingAmount
        name={filterNames?.fundraise_capital_raised_amounts}
        minFundingIndex={minFundingIndex}
        maxFundingIndex={maxFundingIndex}
        fundingLevels={fundingLevels}
        fundingCounts={fundingCounts}
        onChange={(values) =>
          dispatch({
            type: actions.SET_FUNDING_INDEX_RANGE,
            payload: values,
          })
        }
      />
      <JournalistFiltersCheckboxes
        name={filterNames?.content_types}
        options={contentTypes}
        optionNameMapping={contentTypeNames}
        selectedOptions={selectedContentTypes}
        onChange={(contentType) =>
          dispatch({ type: actions.TOGGLE_CONTENT_TYPE, payload: contentType })
        }
      />
    </form>
  );
};

JournalistFilters.propTypes = {
  className: PropTypes.string,
  companySearchFilters: PropTypes.shape({
    filters: PropTypes.object,
    filterNames: PropTypes.object,
  }),
  journalistState: PropTypes.object,
  fundingLevels: JournalistFiltersFundingAmount.propTypes.fundingLevels,
  dispatch: PropTypes.func,
};

export default JournalistFilters;
