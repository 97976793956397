import isString from 'lodash/isString';

// filters an array of options and only returns those whose `id` starts with `custom-`
const filterCustomOptions = (options = []) =>
  options
    ? options.filter(
        (option) => isString(option?.id) && option?.id.startsWith('custom-')
      )
    : [];

export default filterCustomOptions;
