import PropTypes from 'prop-types';
import React from 'react';

import { NewspaperIcon } from '@heroicons/react/solid';
import { useForm } from 'react-hook-form';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Tooltip from 'components/Tooltip/Tooltip';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import TextCounter from 'components/form/TextCounter';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import { REGEX_URL } from 'constants/regex';
import useOpenGraphApi from 'hooks/useOpenGraphApi';
import {
  getDateRange,
  getDateRangeDefault,
  todaysDateObj,
} from 'utils/date/dateRange';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

import CompanyArticleEditModalPublishedDate from './CompanyArticleEditModalPublishedDate';

const CompanyArticlesEditModal = ({
  open,
  article = null,
  uploadFolder,
  onClose,
  onSubmit,
}) => {
  const defaultDate = article?.date
    ? getDateObjFromTimestamp(article.date)
    : todaysDateObj;
  const defaultValues = article || {};

  const formName = 'articleForm';
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      image: null,
      title: '',
      publication: '',
      author: '',
      ...defaultValues,
      date: defaultDate || {
        day: getDateRangeDefault('days'),
        month: getDateRangeDefault('months'),
        year: getDateRangeDefault('years'),
      },
    },
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    trigger,
    formState: { isValid, errors },
  } = form;

  const [isLoading, setIsLoading] = React.useState(false);
  const [overridePreview, setOverride] = React.useState(false);

  const title = watch('title');
  const url = watch('url');

  useOpenGraphApi({
    handleSuccess: (data) => {
      setValue('title', data.title || '');
      setValue('description', data.description || '');
      setValue('publication', data.site_name || '');
      setValue('author', data.articleAuthor || '');

      let date = new Date();
      if (data.articlePublishedTime) {
        date = new Date(data.articlePublishedTime);
      }

      setValue('date.day', date.getDate());
      setValue('date.month', getDateRange('months')[date.getMonth()]);
      setValue('date.year', date.getFullYear());

      if (data.image) {
        const newImage = {
          path: data.image,
          type: 'image', // we don't know the type, so we assume it's an image so it can be previewed
        };
        setOverride(newImage);
        setValue('image', newImage);
      }

      trigger();
    },
    handleError: () => {
      setError('url', { message: 'Could not fetch data from URL' });
    },
    url,
    open,
    setIsLoading,
    validate: () => trigger('url'),
  });

  return (
    <Modal open={open}>
      <Modal.Close srMessage="Close Article Edit modal" onClose={onClose} />
      <Modal.Icon Icon={NewspaperIcon} />
      <Modal.Title>{article ? 'Edit' : 'Add a'} news article</Modal.Title>
      {!article ? (
        <Modal.Description>
          Recent news articles show that your company is being talked about.
        </Modal.Description>
      ) : null}
      <Modal.Content>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={formName}
          className="space-y-3"
        >
          <InputFieldText
            maxLength={2000}
            {...register('url', {
              required: {
                value: true,
                message: 'Please type in a URL',
              },
              pattern: {
                value: REGEX_URL,
                message: 'Please type in a valid URL',
              },
            })}
            errors={errors}
            placeholder="https://"
            testId="company-articles-editModal-urlInput"
          >
            {isLoading ? (
              <InputFieldText.Overlay className="flex items-center justify-end">
                <div className="w-6 h-6 bg-white relative mr-2">
                  <Spinner />
                </div>
              </InputFieldText.Overlay>
            ) : null}

            <InputFieldText.Label className="flex items-center mt-5">
              Link to the news article*
              <Tooltip
                id="tooltip-articleEdit-link"
                copy="Don't worry if you don't have any recently published articles! You can add links to articles you've written on your company's blog, LinkedIn articles, Medium or other sites."
              />
            </InputFieldText.Label>
          </InputFieldText>

          <InputFieldUpload
            name="image"
            folder={uploadFolder}
            form={form}
            overridePreview={overridePreview}
            alt="Preview image for the article"
          >
            <InputFieldUpload.Label>Image</InputFieldUpload.Label>
          </InputFieldUpload>

          <InputFieldText
            {...register('title', {
              required: 'Please put in a title',
              maxLength: {
                value: 140,
                message: 'Please keep the title below 140 characters',
              },
            })}
            errors={errors}
            placeholder="Type here the title of the article..."
            testId="company-articles-editModal-titleInput"
          >
            <InputFieldText.Label className="flex w-full justify-between items-baseline mt-3">
              Title*
              <TextCounter str={title} maxLength={140} />
            </InputFieldText.Label>
          </InputFieldText>

          <fieldset className="w-full flex flex-col items-start">
            <Label htmlFor="date">When did it get published?</Label>
            <CompanyArticleEditModalPublishedDate
              control={control}
              watch={watch}
              setValue={setValue}
            />
          </fieldset>

          {errors.date && <ErrorMessage>{errors.date.message}</ErrorMessage>}

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            <InputFieldText
              {...register('author', {
                maxLength: {
                  value: 50,
                  message: 'Please keep the author below 50 characters',
                },
              })}
              errors={errors}
              placeholder="Who wrote the article..."
              testId="company-articles-editModal-authorInput"
            >
              <InputFieldText.Label>Author</InputFieldText.Label>
            </InputFieldText>

            <InputFieldText
              {...register('publication', {
                required: 'Please add a publication',
                maxLength: {
                  value: 50,
                  message: 'Please keep the publication below 50 characters',
                },
              })}
              errors={errors}
              placeholder="Where did it get published..."
              testId="company-articles-editModal-publicationInput"
            >
              <InputFieldText.Label>Publication*</InputFieldText.Label>
            </InputFieldText>
          </div>
        </form>

        <ReactTooltip
          id="tooltip-articleEdit-link"
          className="max-w-xs text-sm"
        />
      </Modal.Content>

      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-articles-editModal-cancel"
        >
          Cancel
        </Button>
        <Button
          submit
          form={formName}
          disabled={!isValid}
          eventType="pressPage_update"
          eventProperties={{ action: 'article_add' }}
          data-test-id="company-articles-editModal-submit"
        >
          {article ? 'Update' : 'Add'} article
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyArticlesEditModal.propTypes = {
  open: PropTypes.bool,
  article: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.object,
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
    author: PropTypes.string,
    publication: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadFolder: PropTypes.string.isRequired,
};

export default CompanyArticlesEditModal;
