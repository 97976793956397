import PropTypes from 'prop-types';
import React from 'react';

import Tag from 'components/tags/Tag';
import { messageStatus } from 'constants/messageStatus';

const bgColors = {
  [messageStatus.UNAPPROVED]: 'red-200',
  [messageStatus.APPROVED]: 'green-200',
  [messageStatus.DELIVERED]: 'gray-200',
};

const textColors = {
  [messageStatus.UNAPPROVED]: 'red-700',
  [messageStatus.APPROVED]: 'green-700',
  [messageStatus.DELIVERED]: 'gray-700',
};

const MessageStatus = ({ status = messageStatus.UNAPPROVED }) => (
  <Tag
    uppercase
    bold
    bgColor={bgColors[status]}
    textColor={textColors[status]}
    className="!my-0 !mr-0"
  >
    {status}
  </Tag>
);

MessageStatus.propTypes = {
  status: PropTypes.string,
};

export default MessageStatus;
