import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  ArchiveIcon,
  EyeIcon,
  EyeOffIcon,
  IdentificationIcon,
} from '@heroicons/react/outline';
import { MailIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

import Button from 'components/buttons/Button';
import CompanyImage from 'components/company/CompanyImage';
import activeSubscriptionStatus from 'constants/activeSubscriptionStatus';
import useImpersonation from 'contexts/ImpersonationContext/hooks/useImpersonation';
import useActiveSubscription from 'hooks/useActiveSubscription';
import useActiveSubscriptionWithTierFormatted from 'hooks/useActiveSubscriptionWithTierFormatted';
import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';
import getCompanyRoute from 'utils/getCompanyRoute';

import useCompaniesStore from '../hooks/useCompaniesStore';
import useCompanyStats from '../hooks/useCompanyStats';
import useSetCompanyArchived from '../hooks/useSetCompanyArchived';
import useSetCompanyInternal from '../hooks/useSetCompanyInternal';

import AdminCompaniesCompanyStats from './AdminCompaniesCompanyStats';
import AdminCompaniesCompanyTier from './AdminCompaniesCompanyTier';
import AdminCompaniesCompanyTopics from './AdminCompaniesCompanyTopics';
import AdminCompaniesCompanyUpdateEmailModal from './AdminCompaniesCompanyUpdateEmailModal';
import AdminCompaniesCompanyVCInfo from './AdminCompaniesCompanyVCInfo';
import getSubscriptionProduct from './helpers/getSubscriptionProduct';

const AdminCompaniesCompany = ({ company = {} }) => {
  const isVisible = useCompaniesStore((state) =>
    state.filteredCompanies.has(company.uid)
  );
  // impersonate
  const { impersonatedCompanySlug, setImpersonatedCompanySlug } =
    useImpersonation();

  // set/unset internal
  const setCompanyInternal = useSetCompanyInternal(company?.uid);
  const setCompanyArchived = useSetCompanyArchived(company?.uid);

  const [isUpdateEmailModalOpen, setIsUpdateEmailModalOpen] = useState(false);

  const subscription = useActiveSubscription(company.owner);
  const product = getSubscriptionProduct(subscription);
  const stats = useCompanyStats(company?.uid);

  useEffect(() => {
    const { removeCompanyTier, setCompanyTier } = useCompaniesStore.getState();

    if (activeSubscriptionStatus.includes(subscription?.status)) {
      setCompanyTier({
        id: company.uid,
        product: product?.id,
        name: product?.name,
        status: subscription?.status,
      });
    } else {
      removeCompanyTier(company.uid);
    }
  }, [company.uid, product?.id, product?.name, subscription?.status]);

  const subscriptionData = useActiveSubscriptionWithTierFormatted(
    company?.owner
  );

  if (!isVisible) {
    return null;
  }

  const isImpersonating = company.slug === impersonatedCompanySlug;
  const today = new Date();

  return (
    <tr>
      <td className="px-4 py-2">
        <div className="flex items-center">
          <CompanyImage
            image={company.logo}
            alt={`${company.name} logo`}
            className="w-10 h-10"
          />
          <div className="ml-4">
            <Link
              to={getCompanyRoute(company.slug)}
              className="text-sm font-medium text-gray-900 flex gap-2 items-center"
            >
              <span className="align-middle max-w-[200px] inline-block truncate">
                {company.name}
              </span>
              {company.internal && (
                <EyeOffIcon
                  data-tooltip-content="This company is internal, and not visible on the journalist dashboard"
                  data-tooltip-id="tooltip-admin-companies"
                  className="w-4 h-4 text-gray-400"
                />
              )}
            </Link>
          </div>
        </div>
      </td>
      <AdminCompaniesCompanyStats stats={stats} />
      <td className="px-4 py-2 max-w-sm">
        {company.topics?.length > 0 && (
          <AdminCompaniesCompanyTopics topics={company.topics} />
        )}
      </td>

      <AdminCompaniesCompanyTier
        tier={product?.name || 'No subscription'}
        today={today}
        status={subscription?.status}
        willCancel={subscriptionData?.willCancel}
        cancelDate={subscriptionData?.willCancelAtDate}
      />

      <td className="px-4 py-2 max-w-sm w-fit">
        {subscriptionData?.createdDate ?? (
          <span className="block text-center">-</span>
        )}
      </td>

      <td className="px-4 py-2 max-w-sm w-fit">
        {getDateFromTimestamp(company?.signupDate?.toDate())}
      </td>

      <td className="px-4 py-2 max-w-sm w-fit">
        {getDateFromTimestamp(company?.lastSeenDate?.toDate())}
      </td>

      <td className="px-4 py-2">
        {company.vcs?.length > 0 && (
          <AdminCompaniesCompanyVCInfo
            companyName={company.name}
            vcs={company.vcs}
            vcsOther={company.vcsOther}
          />
        )}
      </td>

      <td className="px-4 py-2">
        <div className="flex gap-2">
          <Button
            onClick={() =>
              isImpersonating
                ? setImpersonatedCompanySlug(null)
                : setImpersonatedCompanySlug(company.slug)
            }
            type={isImpersonating ? 'secondary' : 'primary'}
            data-tooltip-content={
              isImpersonating ? 'Stop impersonating' : 'Impersonate'
            }
            data-tooltip-id="tooltip-admin-companies"
          >
            <IdentificationIcon className="w-4 h-4" />
          </Button>
          <Button
            type="secondary"
            onClick={() => setCompanyInternal(!company?.internal)}
            data-tooltip-content={
              company.internal ? 'Unset internal' : 'Set internal'
            }
            data-tooltip-id="tooltip-admin-companies"
          >
            {company.internal ? (
              <EyeIcon className="w-4 h-4" />
            ) : (
              <EyeOffIcon className="w-4 h-4" />
            )}
          </Button>
          <Button
            type="secondary"
            onClick={() => setIsUpdateEmailModalOpen(true)}
            data-tooltip-content="Update Email Address"
            data-tooltip-id="tooltip-admin-companies"
          >
            <MailIcon className="w-4 h-4" />
          </Button>
          <Button
            type={company.archived ? 'secondary' : 'danger'}
            onClick={() => setCompanyArchived(!company?.archived)}
            data-tooltip-content={
              company.archived ? 'Unarchive company' : 'Archive company'
            }
            data-tooltip-id="tooltip-admin-companies"
          >
            <ArchiveIcon className="w-4 h-4" />
          </Button>
        </div>
      </td>
      <AdminCompaniesCompanyUpdateEmailModal
        company={company}
        open={isUpdateEmailModalOpen}
        onClose={() => setIsUpdateEmailModalOpen(false)}
      />
    </tr>
  );
};

AdminCompaniesCompany.propTypes = {
  company: PropTypes.object.isRequired,
};

export default AdminCompaniesCompany;
