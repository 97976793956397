import React from 'react';

import AdminSuccessFeesAdd from './AdminSuccessFeesAdd';
import AdminSuccessFeesTable from './AdminSuccessFeesTable/AdminSuccessFeesTable';

const AdminSuccessFees = () => (
  <div className="flex flex-row flex-wrap">
    <div className="flex flex-col gap-4 w-full">
      <AdminSuccessFeesAdd />
      <AdminSuccessFeesTable />
    </div>
  </div>
);

export default AdminSuccessFees;
