import PropTypes from 'prop-types';
import React from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import SocialLogin from 'components/SocialLogin';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { PASSWORD_RESET } from 'constants/paths';
import { REGEX_EMAIL } from 'constants/regex';
import { useTracking } from 'modules/segment/TrackingProvider';

const LoginForm = ({ signInWithEmail, signInWithGoogle }) => {
  const {
    register,
    handleSubmit,

    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: 'onChange',
    nativeValidation: false,
  });
  const trackingService = useTracking();

  const onSubmit = async (formData, event) => {
    const res = await signInWithEmail(formData, event);
    if (!res) {
      return;
    }

    trackingService.track('login', {
      auth_method: 'email',
      auth_form: 'page',
    });

    trackingService.track('Login', {
      type: 'member',
      email: formData.email,
    });
  };

  const onSignInWithGoogle = async () => {
    const res = await signInWithGoogle();
    if (!res) {
      return;
    }

    trackingService.track('login', {
      auth_method: 'google',
      auth_form: 'page',
    });
  };

  return (
    <div className="mt-16 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <InputFieldText
            type="email"
            {...register('email', {
              pattern: {
                value: REGEX_EMAIL,
                message: 'Please provide a valid email address',
              },
            })}
            autoComplete="email"
            required
            testId="loginForm-email"
            errors={errors}
          >
            <InputFieldText.Label>Email address</InputFieldText.Label>
          </InputFieldText>

          <InputFieldText
            {...register('password', {
              required: 'Please provide a password',
            })}
            type="password"
            autoComplete="current-password"
            required
            testId="loginForm-password"
            errors={errors}
          >
            <InputFieldText.Label>Password</InputFieldText.Label>
          </InputFieldText>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link
                to={PASSWORD_RESET}
                className="font-medium text-teal-500 hover:text-teal-500"
                data-test-id="loginForm-forgotPassword"
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <div>
            <Button
              size="base"
              submit
              full
              disabled={isSubmitting || !isValid}
              data-test-id="loginForm-submit"
            >
              {isSubmitting ? 'Loading' : 'Login'}
            </Button>
          </div>
        </form>

        <SocialLogin signInWithGoogle={onSignInWithGoogle} />
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  signInWithEmail: PropTypes.func.isRequired,
  signInWithGoogle: PropTypes.func.isRequired,
};

export default LoginForm;
