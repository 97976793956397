import PropTypes from 'prop-types';
import React from 'react';

import Tag from 'components/tags/Tag';

/**
 * Displays an opportunity's type
 * Prepends the topic with a blue tag
 */
const OpportunityType = ({ type = '', isResponded = false }) => (
  <div>
    {type && (
      <Tag
        bold
        bgColor="blue-500"
        textColor="white"
        data-intercom-id="opportunity-type"
      >
        {type}
      </Tag>
    )}
    {isResponded && (
      <Tag bold bgColor="teal-500" textColor="white">
        Responded
      </Tag>
    )}
  </div>
);

OpportunityType.propTypes = {
  type: PropTypes.string,
  isResponded: PropTypes.bool,
};

export default OpportunityType;
