import { useState } from 'react';

import { httpsCallable, getFunctions } from 'firebase/functions';
import { useFirebaseApp, useRemoteConfigValue } from 'reactfire';

import functionsConfig from 'constants/functionsConfig';

// redirects user to the Stripe portal, given that they're logged in
const useRedirectToStripePortal = (portalConfigId = '') => {
  // We need to set the region here explicitly:
  // https://github.com/FirebaseExtended/reactfire/issues/330#issuecomment-875708438
  const [isRedirecting, setIsRedirecting] = useState(false);
  const app = useFirebaseApp();
  const functions = getFunctions(app, functionsConfig.DEFAULT_REGION);

  if (!portalConfigId) {
    throw new Error(
      'useRedirectToStripePortal: portalConfigId is required. Please pass in a portalConfigId.'
    );
  }

  const { data: portalConfigIdsString } = useRemoteConfigValue(
    'portalConfigurations'
  );
  const portalConfigIDs = JSON.parse(portalConfigIdsString.asString());
  const configuration = portalConfigIDs[portalConfigId];

  // TODO: move this to a constants file
  const portalFunction = httpsCallable(
    functions,
    'ext-firestore-stripe-payments-createPortalLink'
  );

  if (!configuration) {
    throw new Error(
      `No portal configuration found for portalConfigId: ${portalConfigId}`
    );
  }

  const redirectToStripePortal = async (e) => {
    e.preventDefault();
    setIsRedirecting(true);
    let url;
    try {
      const { data } = await portalFunction({
        // TODO: turn this into a function
        returnUrl: `${window.location.origin}${window.location.pathname}`,
        locale: 'auto',
        configuration,
      });
      if (data) {
        url = data.url;
      }
    } catch (err) {
      // TODO-LOG: this should send an error to the logger (which we don't have yet)
      setIsRedirecting(false);
    }
    if (url) {
      window.location.assign(url);
    }
  };

  return { redirectToStripePortal, isRedirecting };
};

export default useRedirectToStripePortal;
