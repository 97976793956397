import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { VideoCameraIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import TextCounter from 'components/form/TextCounter';
import InputFieldTagsList from 'components/form/inputFields/InputFieldTagsList/InputFieldTagsList';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { REGEX_URL } from 'constants/regex';
import { tagsVideo } from 'constants/tags';
import useOpenGraphApi from 'hooks/useOpenGraphApi';

import CompanyVideoEditModalPreview from './CompanyVideoEditModalPreview';

const CompanyVideoEditModal = ({ open, video = null, onClose, onSubmit }) => {
  const formName = 'company-video-editModal';
  const defaultValues = video || {};
  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, errors },
    watch,
    setValue,
    trigger,
  } = useForm({ mode: 'onChange', defaultValues });

  const title = watch('title');
  const url = watch('url');

  const [isLoading, setIsLoading] = useState(false);
  useOpenGraphApi({
    url,
    open,
    setIsLoading,
    handleSuccess: (data) => {
      if (data.title) {
        setValue('title', data.title, { shouldValidate: true });
      }
    },
    validate: () => trigger('url'),
  });

  return (
    <Modal open={open}>
      <Modal.Close srMessage="Close snapshot edit modal" onClose={onClose} />
      <Modal.Icon Icon={VideoCameraIcon} />
      <Modal.Title>{video ? 'Edit' : 'Add a'} video</Modal.Title>
      {!video ? (
        <Modal.Description>
          Add a video to share your company’s story with journalists - something
          you’d be happy for the world to see.
        </Modal.Description>
      ) : null}
      <Modal.Content>
        <form id={formName} onSubmit={handleSubmit(onSubmit)}>
          <InputFieldText
            maxLength={2000}
            errors={errors}
            {...register('url', {
              required: 'Please type in a URL',
              pattern: {
                value: REGEX_URL,
                message: 'Please type in a valid URL',
              },
            })}
            placeholder="https://www.youtube.com/watch?v={videoId}"
            testId="company-videos-editModal-urlInput"
          >
            {isLoading ? (
              <InputFieldText.Overlay className="flex items-center justify-end">
                <div className="w-6 h-6 bg-white relative mr-2">
                  <Spinner />
                </div>
              </InputFieldText.Overlay>
            ) : null}
            <InputFieldText.Label>Video URL*</InputFieldText.Label>
          </InputFieldText>
          <p className="mt-2 text-xs text-gray-400">
            Supported platforms: Youtube, Vimeo. More coming soon...
          </p>

          <InputFieldText
            errors={errors}
            {...register('title', {
              required: 'Please put in a title',
              maxLength: {
                value: 140,
                message: 'Please keep the title below 140 characters',
              },
            })}
            placeholder="Title of the video"
            testId="company-content-partnership-editModal-inputTitle"
          >
            <InputFieldText.Label className="flex w-full justify-between items-baseline mt-3">
              Video title*
              <TextCounter str={title || defaultValues.title} maxLength={140} />
            </InputFieldText.Label>
          </InputFieldText>

          <div className="w-full mt-3 text-left text-sm font-medium">
            <Label htmlFor="url">Preview</Label>
          </div>

          <div className="w-full mt-3 h-64 overflow-hidden object-cover">
            <CompanyVideoEditModalPreview
              url={url}
              defaultUrl={defaultValues.url}
              error={errors?.url?.message}
              onDuration={(duration) => {
                setValue('duration', parseInt(duration, 10));
                trigger();
              }}
            />
          </div>

          <div className="w-full mt-3 text-left text-sm font-medium">
            <Label htmlFor="url">Tags</Label>
          </div>

          <div className="mt-2">
            <Controller
              name="tag"
              control={control}
              defaultValue={defaultValues.tag || tagsVideo[0]}
              render={({ field: { onChange, value } }) => (
                <InputFieldTagsList
                  tags={tagsVideo}
                  onChange={onChange}
                  value={value}
                  testId="company-videos-editModal-tags"
                />
              )}
            />
          </div>

          <input
            id="duration"
            type="hidden"
            {...register('duration', {
              required: true,
            })}
          />
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-videos-editModal-cancel"
        >
          Cancel
        </Button>
        <Button
          submit
          form={formName}
          disabled={!isValid}
          eventType="pressPage_update"
          eventProperties={{ action: 'video_add' }}
          data-test-id="company-videos-editModal-submit"
        >
          {video ? 'Update' : 'Add'} video
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyVideoEditModal.propTypes = {
  open: PropTypes.bool,
  video: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    tag: PropTypes.object,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyVideoEditModal;
