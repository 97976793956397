import PropTypes from 'prop-types';
import React from 'react';

import Label from 'components/form/Label';

const InputFieldTextLabel = ({
  children = null,
  className = '',
  htmlFor = '',
  testId = '',
  ...rest
}) => (
  <Label className={className} htmlFor={htmlFor} testId={testId} {...rest}>
    {children}
  </Label>
);

InputFieldTextLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
  testId: PropTypes.string,
};

export default InputFieldTextLabel;
