import React from 'react';

import InputFieldToggle from 'components/form/inputFields/InputFieldToggle';

import useCompaniesStore from './hooks/useCompaniesStore';

const AdminCompaniesArchivedToggle = () => {
  const showArchived = useCompaniesStore((state) => state.showArchived);
  return (
    <div className="grow shrink-0  mb-2">
      <InputFieldToggle
        name="showArchived"
        text="Show archived"
        value={showArchived}
        onChange={(v) => useCompaniesStore.setState({ showArchived: v })}
      />
    </div>
  );
};

export default AdminCompaniesArchivedToggle;
