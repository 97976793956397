import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ClockIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import { messageStatus } from 'constants/messageStatus';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import formatDate from 'utils/date/formatDate';

import InboxMessageResponseFormScheduleModal from '../InboxMessageResponseForm/InboxMessageResponseFormScheduleModal';

const InboxMessageHeaderSchedule = ({
  status = messageStatus.UNAPPROVED,
  sendAfter = null,
  messagePath = '',
}) => {
  const { showSuccess } = useNotifications();
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const firestore = useFirestore();
  const messageRef = doc(firestore, messagePath);
  const { updateDocument } = useFirestoreWrapper();

  const date = sendAfter.toDate();
  const formatedDate = formatDate(date);

  return (
    <div className="flex w-full items-center gap-2 rounded-md">
      <span
        className={classNames('flex gap-1 text-sm items-center ', {
          'text-gray-300': status === messageStatus.UNAPPROVED,
          'text-teal-500': status === messageStatus.APPROVED,
        })}
      >
        <ClockIcon className="h-4 w-4" />
        <span className="break-words min-width-0">
          Scheduled to be sent on {formatedDate}
        </span>
      </span>
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setShowScheduleModal(true);
        }}
        className="flex gap-1 text-xs bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md text-gray-500"
      >
        Edit
      </button>
      {showScheduleModal ? (
        <InboxMessageResponseFormScheduleModal
          onCancel={() => setShowScheduleModal(false)}
          date={date}
          onSchedule={async (newSendAfter) => {
            await updateDocument(messageRef, { sendAfter: newSendAfter });
            showSuccess({
              message: `Your response will be sent to the journalist on ${formatDate(
                newSendAfter
              )}`,
            });
            setShowScheduleModal(false);
          }}
        />
      ) : null}
    </div>
  );
};

InboxMessageHeaderSchedule.propTypes = {
  status: PropTypes.string,
  sendAfter: PropTypes.object,
  messagePath: PropTypes.string,
};
export default InboxMessageHeaderSchedule;
