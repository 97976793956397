import PropTypes from 'prop-types';
import React from 'react';

import { NavHashLink } from 'react-router-hash-link';
import { SwiperSlide } from 'swiper/react';

const CompanyNavbarLink = ({ to, section, isActive, onScroll }) => {
  const classes = isActive
    ? 'text-teal-500 border-b-2 border-teal-500'
    : 'hover:text-teal-500';

  return (
    <SwiperSlide key={section.hash} className="inline-flex">
      <NavHashLink
        smooth
        to={to}
        scroll={onScroll}
        className={`w-full pb-4 text-center whitespace-nowrap ${classes}`}
        data-test-id={`company-navbar-${section.hash.replace('#', '')}`}
      >
        <span className="mr-2 text-md">{section.emoji}</span>
        <span className="text-sm">{section.label}</span>
      </NavHashLink>
    </SwiperSlide>
  );
};

// We need to set this manually, or the Swiper component won't recognise this component as a slide
CompanyNavbarLink.displayName = 'SwiperSlide';

CompanyNavbarLink.propTypes = {
  to: PropTypes.object.isRequired,
  section: PropTypes.shape({
    hash: PropTypes.string,
    emoji: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool,
  onScroll: PropTypes.func.isRequired,
};

export default CompanyNavbarLink;
