import PropTypes from 'prop-types';
import React from 'react';

import getTagColors from 'utils/color/getTagColors';

import Tag from './Tag';

const TagList = ({ tags = [], className = '' }) => (
  <div className={className}>
    {tags.map(({ text, colorIndex, id, testId }, idx) => {
      const [bgColor, textColor] = getTagColors(
        colorIndex ?? idx,
        text?.length
      );
      return (
        <Tag
          key={id}
          bgColor={bgColor}
          textColor={textColor}
          data-test-id={testId}
        >
          {text}
        </Tag>
      );
    })}
  </div>
);

TagList.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      colorIndex: PropTypes.number,
      id: PropTypes.any,
      testId: PropTypes.string,
    })
  ),
  className: PropTypes.string,
};

export default TagList;
