import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

import { useTracking } from 'modules/segment/TrackingProvider';

// Versatile button component for most click interactions.
// TODO-ANALYTICS: Extract the `onClickWithAnalytics` function and move to a separate hook
const Button = ({
  children = null,
  className = '',
  type = 'primary',
  size = 'sm',
  full = false,
  submit = false,
  disabled = false,
  onClick = noop,
  eventType = '',
  eventProperties = {},
  ...rest
}) => {
  // analytics: track button click
  const tracking = useTracking();
  const onClickWithAnalytics = (e) => {
    if (disabled) {
      return;
    }
    if (eventType) {
      tracking.track(eventType, eventProperties);
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
      className={classNames(
        'inline-flex justify-center items-center py-2 px-3 border shadow-sm font-medium rounded-md whitespace-nowrap focus:outline-none focus:ring',
        {
          'bg-teal-500 hover:bg-teal-400 focus:border-teal-700':
            type === 'primary',
          'bg-white hover:bg-gray-50 focus:border-gray-100 text-teal-500':
            type === 'secondary',
          'bg-amber-500 hover:bg-amber-400 focus:border-amber-700':
            type === 'danger',
          'border-transparent text-white':
            type === 'primary' || type === 'danger' || type === 'extraDanger',
          'text-sm': size === 'sm',
          'text-base': size === 'base',
          'w-full': full,
          'cursor-not-allowed': disabled,
          'bg-[#d8f1ed] hover:bg-[#d7f3ef]': disabled && type === 'primary',
          'text-[#d8f1ed] hover:bg-gray-50': disabled && type === 'secondary',
          'bg-amber-200 hover:bg-amber-100': disabled && type === 'danger',
        },
        className
      )}
      onClick={(e) => onClickWithAnalytics(e)}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'danger']),
  size: PropTypes.oneOf(['base', 'sm']),
  full: PropTypes.bool,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  eventType: PropTypes.string,
  eventProperties: PropTypes.object,
};

export default Button;
