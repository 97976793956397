// don't show callout for messages before the last journalist response
const createShowCalloutArray = (messages = []) => {
  // eslint-disable-next-line max-params
  const journalistLastPosition = messages.reduce((acc, message, index) => {
    if (message.isJournalist) {
      return index;
    }
    return acc;
  }, 0);

  return messages.map((message, index) => {
    if (index < journalistLastPosition) {
      return false;
    }
    return !message.isJournalist && message.opened !== true;
  });
};

export default createShowCalloutArray;
