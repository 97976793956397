import { messageStatus } from 'constants/messageStatus';

const getUnapprovedMessagesCount = (inbox = {}) =>
  inbox?.threads
    ?.filter((thread) => thread?.data?.isArchived !== true)
    ?.reduce((acc, thread) => {
      const unapprovedMessages = thread?.messages?.filter(
        (message) =>
          !message.data.status ||
          message.data.status === messageStatus.UNAPPROVED
      );

      return acc + (unapprovedMessages?.length || 0);
    }, 0);

export default getUnapprovedMessagesCount;
