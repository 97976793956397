import React, { useMemo } from 'react';

import Helmet from 'react-helmet';
import { useParams, generatePath, useMatch } from 'react-router-dom';

import {
  ADMIN_MESSAGES_COMPANIES_INBOX_PATH,
  ADMIN_MESSAGES_COMPANIES_INBOX_THREAD_PATH,
  ADMIN_MESSAGES_COMPANIES_PATH,
  ADMIN_MESSAGES_PATH,
} from 'constants/paths';
import getPageTitle from 'utils/getPageTitle';

import useNestedInboxesWithCompanies from '../hooks/useNestedInboxesWithCompanies';

import AdminMessagesCompanies from './AdminMessagesCompanies/AdminMessagesCompanies';
import AdminMessagesCompaniesTabHeader from './AdminMessagesCompaniesTabHeader';
import AdminMessagesMessages from './AdminMessagesMessages/AdminMessagesMessages';
import AdminMessagesThreads from './AdminMessagesThreads/AdminMessagesThreads';
import useThreadsWithEmails from './hooks/useThreadsWithEmails';

const COMPANIES_PATH = generatePath(ADMIN_MESSAGES_PATH, {
  type: ADMIN_MESSAGES_COMPANIES_PATH,
});
const THREADS_PATH = `${COMPANIES_PATH}/${ADMIN_MESSAGES_COMPANIES_INBOX_PATH}`;
const MESSAGES_PATH = `${THREADS_PATH}/${ADMIN_MESSAGES_COMPANIES_INBOX_THREAD_PATH}`;

const AdminMessagesCompaniesTab = () => {
  const inboxes = useNestedInboxesWithCompanies();
  const { inboxId, threadId } = useParams();

  const showCompanies = useMatch(COMPANIES_PATH);
  const showThreads = useMatch(THREADS_PATH);
  const showMessages = useMatch(MESSAGES_PATH);

  const inbox = useMemo(
    () => inboxes?.find((i) => i.id === inboxId) || {},
    [inboxes, inboxId]
  );

  const { threadsWithEmails, reload } = useThreadsWithEmails(inbox?.threads);

  const thread = useMemo(
    () => threadsWithEmails?.find((t) => t.id === threadId) || {},
    [threadsWithEmails, threadId]
  );

  return (
    <div className="space-y-4">
      <Helmet>
        <title>{getPageTitle('Admin - Messages: Companies')}</title>
      </Helmet>

      {showMessages && (
        <AdminMessagesCompaniesTabHeader thread={thread} inbox={inbox} />
      )}
      {showCompanies && <AdminMessagesCompanies inboxes={inboxes} />}
      {showThreads && (
        <AdminMessagesThreads threads={threadsWithEmails} reload={reload} />
      )}
      {showMessages && (
        <AdminMessagesMessages
          messages={thread?.messages}
          companyName={inbox?.data?.name}
          journalist={thread?.data?.journalist}
          isArchived={thread?.data?.isArchived}
        />
      )}
    </div>
  );
};

export default AdminMessagesCompaniesTab;
