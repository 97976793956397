const getTimeLeftUntilInterval = (date = new Date(), interval = 0) => {
  const INTERVAL_TIME = interval * 60 * 1000;
  const dateWithInterval = new Date(date.getTime() + INTERVAL_TIME);
  const now = new Date();

  const diff = new Date(dateWithInterval - now);
  const diffSeconds = diff.getSeconds();
  const diffMinutes = diff.getMinutes();

  const seconds = `${diffSeconds < 10 ? '0' : ''}${diffSeconds}`;
  const minutes = `${diffMinutes < 10 ? '0' : ''}${diffMinutes}`;

  const timeLeft = [minutes, seconds].join(':');

  return {
    timeLeft: dateWithInterval > now ? timeLeft : null,
    diff,
  };
};

export default getTimeLeftUntilInterval;
