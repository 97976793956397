import { useCallback } from 'react';

import { deleteDoc, doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import {
  OPPORTUNITIES_NOTIFICATIONS,
  OPPORTUNITIES_NOTIFICATIONS_COMPANIES_TO_NOTIFY,
} from 'constants/firebasePaths';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';

const useToggleCompany = ({
  opportunityUid = '',
  companyUid = '',
  isSelected = false,
}) => {
  const { setDocument } = useFirestoreWrapper();
  const firestore = useFirestore();

  return useCallback(async () => {
    if (isSelected) {
      const companyDoc = doc(
        firestore,
        OPPORTUNITIES_NOTIFICATIONS,
        opportunityUid,
        OPPORTUNITIES_NOTIFICATIONS_COMPANIES_TO_NOTIFY,
        companyUid
      );
      return deleteDoc(companyDoc);
    }

    const opportunityDoc = doc(
      firestore,
      OPPORTUNITIES_NOTIFICATIONS,
      opportunityUid
    );
    await setDocument(opportunityDoc, {});

    const companyDoc = doc(
      opportunityDoc,
      OPPORTUNITIES_NOTIFICATIONS_COMPANIES_TO_NOTIFY,
      companyUid
    );
    return setDocument(companyDoc, {
      companyUid,
      dateCreated: new Date(),
      allowMultipleNotifications: true,
    });
  }, [companyUid, firestore, isSelected, opportunityUid, setDocument]);
};
export default useToggleCompany;
