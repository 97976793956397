import PropTypes from 'prop-types';
import React from 'react';

import ContentElementUploadFile from '../ContentElementUploadFile';

import ContentElementHireCompanyName from './ContentElementHireCompanyName';
import ContentElementHireLinkedinUrl from './ContentElementHireLinkedinUrl';
import ContentElementHireName from './ContentElementHireName';
import ContentElementHirePosition from './ContentElementHirePosition';

const ContentElementHire = ({ index = 0 }) => (
  <div className="w-full space-y-5">
    <ContentElementHireName index={index} />
    <ContentElementHireLinkedinUrl index={index} />
    <ContentElementHireCompanyName index={index} />
    <ContentElementHirePosition index={index} />
    <ContentElementUploadFile name="file" label="Add files" />
  </div>
);

ContentElementHire.propTypes = {
  index: PropTypes.number,
};
export default ContentElementHire;
