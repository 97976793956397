import PropTypes from 'prop-types';
import React from 'react';

import { UserIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import UniversalImage from 'components/UniversalImage/UniversalImage';

// Displays an rounded avatar image
const Avatar = ({
  image = '',
  className = 'w-8 h-8',
  alt = 'User profile photo thumbnail',
}) => (
  <div
    className={classNames(
      'flex-shrink-0 rounded-full overflow-hidden bg-gray-50 outline outline-1 outline-black/10',
      className
    )}
  >
    <UniversalImage
      image={image}
      alt={alt}
      placeholderImage={
        <UserIcon className="text-gray-200 transform translate-y-[10%]" />
      }
    />
  </div>
);

Avatar.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  alt: PropTypes.string,
};
export default Avatar;
