import PropTypes from 'prop-types';
import React from 'react';

import { AnimatePresence } from 'framer-motion';
import noop from 'lodash/noop';
import ReactDOM from 'react-dom';

import Notification from './Notification';

const Notifications = ({
  notifications = new Map(),
  deleteNotification = noop,
}) =>
  ReactDOM.createPortal(
    <div
      aria-live="assertive"
      className="z-[101] pointer-events-none fixed inset-0 flex px-4 py-6 sm:items-start sm:p-6"
    >
      <ul
        id="notifications"
        className="flex w-full flex-col items-center sm:items-end overflow-visible"
      >
        <AnimatePresence initial={false}>
          {Array.from(notifications.values()).map((notification, index) => (
            <Notification
              key={notification.key}
              notification={notification}
              deleteNotification={deleteNotification}
              isFirst={index === 0}
            />
          ))}
        </AnimatePresence>
      </ul>
    </div>,
    document.body
  );

Notifications.propTypes = {
  notifications: PropTypes.instanceOf(Map),
  deleteNotification: PropTypes.func,
};

export default Notifications;
