import PropTypes from 'prop-types';
import React from 'react';

import { PencilIcon, TrashIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';
import { Tooltip } from 'react-tooltip';

import Button from 'components/buttons/Button';
import JournalistImage from 'components/images/JournalistImage';
import Tag from 'components/tags/Tag';

const tooltipId = 'tooltip-admin-journalists';

const AdminJournalistsTable = ({
  journalists = [],
  onEdit = noop,
  onActivate = noop,
  onDeactivate = noop,
}) => (
  <div className="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg mt-4">
    <table className="min-w-full divide-y divide-gray-200 text-gray-500">
      <thead className="bg-gray-50 text-xs font-medium uppercase">
        <tr className="text-left tracking-wider">
          <th scope="col" className="px-4 py-2 whitespace-nowrap">
            Image
          </th>
          <th scope="col" className="px-4 py-2">
            Name
          </th>
          <th scope="col" className="px-4 py-2">
            Job Title
          </th>
          <th scope="col" className="px-4 py-2">
            Publication
          </th>
          <th scope="col" className="px-4 py-2">
            Email
          </th>
          <th scope="col" className="px-4 py-2">
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 text-sm">
        {journalists.map((journalist) => (
          <tr key={journalist.id}>
            <td className="px-4 py-2 whitespace-nowrap">
              <JournalistImage
                className="w-12 h-12"
                alt={journalist.name}
                url={journalist.image}
              />
            </td>
            <td className="px-4 py-2 font-semibold">
              {journalist.name}
              {journalist.isDeactivated && (
                <Tag className="ml-2" bgColor="red-100" textColor="red-700">
                  Deactivated
                </Tag>
              )}
            </td>
            <td className="px-4 py-2">{journalist.jobTitle || ''}</td>
            <td className="px-4 py-2">{journalist?.publication?.name}</td>
            <td className="px-4 py-2">{journalist.email || 'n/a'}</td>
            <td className="px-4 py-2">
              <div className="flex gap-2">
                <Button
                  type="primary"
                  data-tooltip-id={tooltipId}
                  data-tooltip-content="Edit"
                  onClick={() => onEdit(journalist.id)}
                >
                  <PencilIcon className="w-4 h-4" />
                </Button>
                <Button
                  type={journalist.isDeactivated ? 'secondary' : 'danger'}
                  data-tooltip-id={tooltipId}
                  onClick={() =>
                    journalist.isDeactivated
                      ? onActivate(journalist.id)
                      : onDeactivate(journalist.id)
                  }
                >
                  <TrashIcon className="w-4 h-4" />
                </Button>
              </div>
            </td>
          </tr>
        ))}
        {journalists.length === 0 && (
          <tr>
            <td colSpan={5} className="p-2 text-center">
              No journalists found
            </td>
          </tr>
        )}
      </tbody>
    </table>
    <Tooltip id={tooltipId} className="max-w-xs text-sm" />
  </div>
);

AdminJournalistsTable.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  journalists: PropTypes.array,
};

export default AdminJournalistsTable;
