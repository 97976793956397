import PropTypes from 'prop-types';
import { useEffect } from 'react';

import noop from 'lodash/noop';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import { useTracking } from 'modules/segment/TrackingProvider';

import useCheckPayment from './hooks/useCheckPayment';

// check payment to update signupStatus, log analytics event
const OnboardingWrapperPaymentStatusSuccess = ({
  onPaymentCheckComplete = noop,
}) => {
  useCheckPayment(onPaymentCheckComplete);

  const { companyData } = useCompanyData();
  const trackingService = useTracking();

  useEffect(() => {
    if (companyData?.slug) {
      trackingService.track('payment_update', {
        action: 'payment_succeeded',
        company_slug: companyData?.slug,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

OnboardingWrapperPaymentStatusSuccess.propTypes = {
  onPaymentCheckComplete: PropTypes.func,
};

export default OnboardingWrapperPaymentStatusSuccess;
