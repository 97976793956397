import PropTypes from 'prop-types';
import React from 'react';

// Styled input text area, should be used anywhere the user inputs multiple lines of text
const InputfieldTextArea = React.forwardRef(
  ({ name = '', placeholder = '', ...rest }, ref) => (
    <textarea
      ref={ref}
      id={name}
      name={name}
      rows="4"
      className="w-full mt-2 rounded-md shadow-sm bg-white text-sm border-gray-300 block focus:border-teal-500 focus:outline-none focus:ring-0 min-w-0 sm:text-sm"
      placeholder={placeholder}
      {...rest}
    />
  )
);

InputfieldTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

InputfieldTextArea.displayName = 'InputfieldTextArea';

export default InputfieldTextArea;
