import PropTypes from 'prop-types';
import React from 'react';

// Displas a teal-coloured video tag that can be used underneath a video title
const VideoTag = ({ tag = {}, testId = '' }) =>
  tag.id ? (
    <h4
      className="inline-block mt-2 px-3 py-1 text-xs sm:text-sm rounded-xl bg-teal-500 text-white"
      data-test-id={testId}
    >
      {tag.name}
    </h4>
  ) : null;

VideoTag.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  testId: PropTypes.string,
};

export default VideoTag;
