import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const CompanyContentElementOpEdArticleIdea = ({
  content = {},
  clampContent = false,
}) => {
  const { title, bulletPoints } = content;
  return (
    <div className="px-6 w-full">
      <h3
        className={classNames('font-medium text-xl pt-4', {
          'line-clamp-2': clampContent,
          'break-words': !clampContent,
        })}
        data-test-id={`company-content-opEd-title-${title}`}
      >
        {title}
      </h3>
      <ul
        className={classNames({
          'list-disc px-4': true,
          'pt-4': !clampContent,
        })}
      >
        {bulletPoints?.map((bulletPoint, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className="text-gray-600 mt-2" key={index}>
            <p
              className={classNames({
                'line-clamp-3': clampContent,
                'break-words': !clampContent,
              })}
            >
              {bulletPoint}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

CompanyContentElementOpEdArticleIdea.propTypes = {
  content: PropTypes.object,
  clampContent: PropTypes.bool,
};

export default CompanyContentElementOpEdArticleIdea;
