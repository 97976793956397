import PropTypes from 'prop-types';
import React from 'react';

import { ArchiveIcon, ArrowSmUpIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { doc } from 'firebase/firestore';
import { Link, generatePath, useParams } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import JournalistImage from 'components/images/JournalistImage';
import ThreadTag from 'components/tags/ThreadTag';
import { tabNames } from 'constants/inboxTabs';
import { INBOX_PATH } from 'constants/paths';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import { useTracking } from 'modules/segment/TrackingProvider';
import getLastJournalistResponse from 'routes/Inbox/helpers/getLastJournalistResponse';

import InboxThreadsThreadJournalistResponse from './InboxThreadsThreadJournalistResponse';

const InboxThreadsThread = ({
  currentThreadId = '',
  thread = {},
  isLast = false,
  isFirst = false,
  threadsRef = {},
  hasUnreadMessages = false,
  hasMessages = false,
}) => {
  const { uid, status, journalist, opportunity } = thread;
  const { subject, enquirySummary } = opportunity || {};
  const threadSubject = subject || enquirySummary || 'No subject';
  const isSelectedThread = currentThreadId === uid;
  const isArchived = status === tabNames.ARCHIVED;
  const { tab } = useParams();

  const trackingService = useTracking();

  const { showSuccess } = useNotifications();
  const { updateDocument } = useFirestoreWrapper();

  const threadRef = doc(threadsRef, uid);

  // journalist response
  const { journalistResponse } = thread;

  const lastJournalistResponse = getLastJournalistResponse(journalistResponse);
  const handleArchiveThread = async () => {
    const originStatus = hasMessages ? tabNames.ACTIVE : tabNames.DRAFT;
    const newStatus = isArchived ? originStatus : tabNames.ARCHIVED;

    await updateDocument(threadRef, {
      status: newStatus,
    });

    showSuccess({
      title: `Thread ${isArchived ? 'un' : ''}archived`,
      message: `Go to the ${newStatus} tab to view it.`,
      Icon: isArchived ? ArrowSmUpIcon : ArchiveIcon,
    });
  };

  return (
    <li
      data-test-id="inboxThread"
      data-intercom-id="inbox-thread"
      className="relative z-0 focus-within:z-[5]"
    >
      <Link
        to={generatePath(INBOX_PATH, {
          tab,
          threadId: uid,
        })}
        onClick={() =>
          trackingService.track('inbox_interaction', {
            action: 'thread_open',
            inbox_id: uid,
            inbox_hasUnreadMessages: hasUnreadMessages,
          })
        }
        className={classNames(
          'group block py-5 px-4 space-y-2 focus-within:outline-teal-500 border-b border-x',
          {
            'rounded-b-md': isLast,
            'rounded-t-md border-t': isFirst,
            'bg-white hover:bg-gray-50': !isSelectedThread && hasUnreadMessages,
            'bg-gray-50 hover:bg-gray-100':
              !isSelectedThread && !hasUnreadMessages,
            'bg-gray-100 ': isSelectedThread && hasUnreadMessages,
            'bg-gray-100': isSelectedThread && !hasUnreadMessages,
          }
        )}
        data-test-id={`inboxThread-${journalist?.name}`}
      >
        <ThreadTag
          threadIntent={thread?.intent}
          testId={`inboxThread-${thread?.uid}-tag`}
        />
        <div className="flex items-center justify-between space-x-3">
          <div className="min-w-0 flex-shrink-0">
            <JournalistImage url={journalist?.image} name={journalist?.name} />
          </div>
          <div className="min-w-0 flex-1">
            <div className="block focus:outline-none text-sm">
              <div
                data-test-id="inboxThread-journalistName"
                className="flex items-center"
              >
                <p className="truncate">
                  <span
                    className={classNames('text-gray-700', {
                      'font-medium': !hasUnreadMessages,
                      'font-bold': hasUnreadMessages,
                    })}
                  >
                    {journalist?.name || 'Unknown'}
                  </span>
                  <span
                    className={classNames('text-gray-700', {
                      'font-bold': hasUnreadMessages,
                    })}
                    data-test-id="inboxThread-publicationName"
                    data-tooltip-content={journalist?.publication?.name}
                    data-tooltip-id="tooltip-inbox-thread"
                  >
                    , {journalist?.publication?.name || 'Unknown'}
                  </span>
                </p>

                {lastJournalistResponse ? (
                  <InboxThreadsThreadJournalistResponse
                    journalistResponse={lastJournalistResponse}
                  />
                ) : null}
              </div>
              <p
                data-tooltip-content={threadSubject}
                data-tooltip-id="tooltip-inbox-thread"
                className={classNames('truncate', {
                  'text-gray-400': !hasUnreadMessages && !isSelectedThread,
                  'text-gray-500': !hasUnreadMessages && isSelectedThread,
                  'text-gray-700 font-bold': hasUnreadMessages,
                })}
              >
                {threadSubject}
              </p>
            </div>
          </div>
          <div className="group-hover:block hidden">
            <DotsMenu
              theme="dark"
              options={[
                {
                  icon: isArchived ? (
                    <ArrowSmUpIcon className="w-4 h-4" />
                  ) : (
                    <ArchiveIcon className="w-4 h-4" />
                  ),
                  label: isArchived ? 'Unarchive' : 'Archive',
                  onClick: handleArchiveThread,
                },
              ]}
            />
          </div>
          {/*
            Todo:
            - add time/date
         */}
        </div>
      </Link>
    </li>
  );
};

InboxThreadsThread.propTypes = {
  threadsRef: PropTypes.object,
  currentThreadId: PropTypes.string,
  thread: PropTypes.object,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  hasUnreadMessages: PropTypes.bool,
  hasMessages: PropTypes.bool,
};

export default InboxThreadsThread;
