import orderByName from 'utils/data/orderByName';
import topicFilter from 'utils/data/topicFilter';

import intents from './intents';

// IMPORTANT: when changing any of these, make sure to also update the tagsTopics at `functions/emailClassify/helpers/mapClassificationsToTopics.js`
export const tagsTopics = [
  // core categories from the old list
  { id: 1003, name: 'Fintech', core: true },
  { id: 1005, name: 'Environment', core: true },
  { id: 1012, name: 'Cybersecurity', core: true },
  { id: 1015, name: 'Human Resources', core: true },
  { id: 1017, name: 'Education', core: true },
  { id: 1027, name: 'Sports', core: true },
  { id: 1023, name: 'Data Science', core: true },
  { id: 1029, name: 'Blockchain', core: true },
  { id: 1036, name: 'Digital Transformation', core: true },
  { id: 1038, name: 'Engineering', core: true },

  // new core categories (that's why they're starting at 1100)
  { id: 1100, name: 'Entertainment', core: true },
  { id: 1101, name: 'Food', core: true },
  { id: 1102, name: 'Health', core: true },
  { id: 1103, name: 'Insurance', core: true },
  { id: 1104, name: 'Logistics', core: true },
  { id: 1105, name: 'Tourism', core: true },
  { id: 1106, name: 'Startups', core: true },
  { id: 1107, name: 'eCommerce', core: true },
  { id: 1108, name: 'Venture Capital', core: true },
  { id: 1109, name: 'Insurtech', core: true },
  { id: 1110, name: 'Personal Finance', core: true },
  { id: 1199, name: 'None', core: true },

  // old categories that we're mapping to the new core categories
  { id: 1000, name: 'Healthcare', mapsTo: 1102 },
  { id: 1001, name: 'Medtech', mapsTo: 1102 },
  { id: 1004, name: 'Wellbeing', mapsTo: 1102 },
  { id: 1006, name: 'Climate', mapsTo: 1005 },
  { id: 1007, name: 'Sustainability', mapsTo: 1005 },
  { id: 1010, name: 'Diversity & Inclusion', mapsTo: 1015 },
  { id: 1011, name: 'Design', mapsTo: 1036 },
  { id: 1014, name: 'Management', mapsTo: 1015 },
  { id: 1016, name: 'Remote Work', mapsTo: 1015 },
  { id: 1018, name: 'Asset Management', mapsTo: 1003 },
  { id: 1020, name: 'Delivery', mapsTo: 1104 },
  { id: 1021, name: 'Artificial Intelligence', mapsTo: 1023 },
  { id: 1022, name: 'Machine Learning', mapsTo: 1023 },
  { id: 1024, name: 'Big Data', mapsTo: 1023 },
  { id: 1026, name: 'Productivity', mapsTo: 1015 },
  { id: 1028, name: 'Cryptocurrency', mapsTo: 1029 },
  { id: 1030, name: 'Automotive', mapsTo: 1038 }, // not in list
  { id: 1031, name: 'Autonomous Cars', mapsTo: 1038 }, // not in list
  { id: 1032, name: 'Augmented Reality', mapsTo: 1036 },
  { id: 1033, name: 'Fitness', mapsTo: 1102 }, // not in list
  { id: 1034, name: 'Mental Health', mapsTo: 1102 },
  { id: 1035, name: 'Travel', mapsTo: 1105 },
  { id: 1037, name: 'Cloud Computing', mapsTo: 1036 },
  { id: 1039, name: 'Community Management', mapsTo: 1015 },

  // no topics are mapping to these
  { id: 1002, name: 'Biotechnology', uncategorised: true },
  { id: 1008, name: 'Government', uncategorised: true },
  { id: 1009, name: 'Regulation', uncategorised: true },
  { id: 1013, name: 'Data Privacy', uncategorised: true },
  { id: 1019, name: 'Banking', uncategorised: true },
  { id: 1025, name: 'Fundraising', uncategorised: true },
];

export const coreTopics = orderByName(tagsTopics.filter((topic) => topic.core));

export const coreTopicsForUser = coreTopics.filter(topicFilter.isNotNone);

export const tagsAudiences = [
  { id: 2000, name: 'Other startups' },
  { id: 2001, name: 'SMEs' },
  { id: 2002, name: 'Business Decision Makers' },
  { id: 2003, name: 'CIOs' },
  { id: 2004, name: 'CFOs' },
  { id: 2005, name: 'CEOs' },
  { id: 2006, name: 'CMOs' },
  { id: 2007, name: 'Venture Capital Investors' },
  { id: 2008, name: 'HNWs' },
  { id: 2009, name: 'Fund Managers' },
  { id: 2010, name: 'Health Care Professionals' },
  { id: 2011, name: 'Young Professionals' },
  { id: 2012, name: 'Students' },
  { id: 2013, name: 'Parents' },
  { id: 2014, name: 'Business Leaders' },
  { id: 2015, name: 'Regulators' },
  { id: 2016, name: 'Consumers' },
  { id: 2017, name: 'Developers' },
];

export const tagsVcs = [
  { id: 10001, name: 'Backed', slug: 'backed' },
  {
    id: 10002,
    name: 'Founders Factory',
    slug: 'founders_factory',
  },
  {
    id: 10003,
    name: 'Hambro Perks',
    slug: 'hambro_perks',
  },
  {
    id: 10004,
    name: 'Octopus Ventures',
    slug: 'octopus_ventures',
  },
  {
    id: 10005,
    name: 'Playfair Capital',
    slug: 'playfair_capital',
  },
  {
    id: 10006,
    name: 'Target Global',
    slug: 'target_global',
  },
  {
    id: 10007,
    name: 'Speed Invest',
    slug: 'speed_invest',
  },
];

export const tagsDownloads = [
  { id: 3000, name: 'Product' },
  { id: 3001, name: 'Case Study' },
  { id: 3002, name: 'Headshot' },
  { id: 3003, name: 'Sales Deck' },
  { id: 3004, name: 'Investor Deck' },
  { id: 3005, name: 'Stock Image' },
  { id: 3006, name: 'Logo' },
  { id: 3007, name: 'Infographic' },
];

// export const tagsComments = [
//   { id: 4000, name: 'Trends' },
//   { id: 4001, name: 'Product' },
//   { id: 4002, name: 'Lists' },
//   { id: 4003, name: 'Data privacy' },
//   { id: 4004, name: 'Management' },
//   { id: 4005, name: 'Startups' },
// ];

// export const tagsKeywords = [
//   { id: 5000, name: 'Product' },
//   { id: 5001, name: 'Software' },
//   { id: 5002, name: 'Marketplace' },
//   { id: 5003, name: 'Mobile application' },
//   { id: 5004, name: 'SaaS' },
//   { id: 5005, name: 'B2B' },
//   { id: 5006, name: 'B2C' },
//   { id: 5007, name: 'B2B2C' },
//   { id: 5008, name: 'Hardware' },
//   { id: 5009, name: 'Telecommunications' },
//   { id: 5010, name: 'Energy' },
//   { id: 5011, name: 'Consumer Goods' },
//   { id: 5012, name: 'Clothing and Apparel' },
//   { id: 5013, name: 'Natural Resources' },
//   { id: 5014, name: 'Agriculture' },
//   { id: 5015, name: 'Food' },
//   { id: 5016, name: 'Audio' },
//   { id: 5017, name: 'Commerce' },
//   { id: 5018, name: 'e-Commerce' },
//   { id: 5019, name: 'Data & Analytics' },
//   { id: 5020, name: 'Gaming' },
// ];

export const tagsInsights = [
  { id: 6000, name: 'Problem' },
  { id: 6001, name: 'Traction' },
  { id: 6002, name: 'Funding' },
  { id: 6003, name: 'Company information' },
  { id: 6004, name: 'Competitors' },
  { id: 6005, name: 'Product information' },
  { id: 6006, name: 'Differentiator' },
  { id: 6007, name: 'Business model' },
  { id: 6008, name: 'Market size' },
];

export const tagsVideo = [
  {
    id: 7000,
    name: 'Interview',
    tip: `Interviews should provide journalists with a 30-60s elevator pitch.`,
  },
  {
    id: 7001,
    name: 'Behind-the-scenes',
    tip: `Behind-the-scenes videos give journalists a better idea of how your company works and should be 30 - 60s long.`,
  },
  {
    id: 7002,
    name: 'Product video',
    tip: `Product videos give journalists a 30-60s demo of your product or service.`,
  },
  {
    id: 7003,
    name: 'Event presentation',
    tip: `Event presentations show Journalists and producers that your spokespeople have the ability to speak in front of live audiences at events or presentations.`,
  },
  {
    id: 7004,
    name: 'Product demo',
    tip: `Similar to Product Videos, Product Demos give journalists a more detailed demo of your product or service. These can be up to 3 minutes.`,
  },
  {
    id: 7005,
    name: 'Testimonial',
    tip: `30-60s testimonials from your clients highlights your credibility.`,
  },
  {
    id: 7006,
    name: 'Team intro',
    tip: `Show off your team to journalists or highlight specific team members and their efforts.`,
  },
  {
    id: 7007,
    name: 'Explainer',
    tip: `Explainers exist to help you communicate the key industry challenges that your product or service overcomes. Break these down into 30 second snippets.`,
  },
];

export const tagsIntent = [
  { id: 8000, slug: intents.opportunities.OPPORTUNITY, name: 'Opportunity' },
  {
    id: 8001,
    slug: intents.opportunities.CUSTOM_OPPORTUNITY,
    name: 'Custom Opportunity',
  },
  { id: 8002, slug: intents.customThread.CUSTOM_THREAD, name: 'Outreach' },

  { id: 8005, slug: intents.sweetheartList.OP_ED, name: 'Thought Leadership' },
  { id: 8006, slug: intents.sweetheartList.OUTREACH, name: 'Cold Outreach' },
  {
    id: 8015, // Starts at 8015 to avoid conflicts with the removed press_release intents
    slug: intents.sweetheartList.PRESS_RELEASE,
    name: 'Press Release',
  },

  // To be deprecated soon
  {
    id: 8003,
    slug: intents.sweetheartList.COMMENT_QUOTE,
    name: 'Comment / Quote',
  },
  {
    id: 8004,
    slug: intents.sweetheartList.EXPERT_OPINION,
    name: 'Request for Experts',
  },
  {
    id: 8007,
    slug: intents.sweetheartList.WARM_INTRO,
    name: 'Warm Introduction',
  },
];
