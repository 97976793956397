import pressPageSection from 'constants/pressPageSections';

const companyNavbarBreakpoints = {
  320: {
    slidesPerView: pressPageSection.length - 6,
  },
  414: {
    slidesPerView: pressPageSection.length - 5,
  },
  520: {
    slidesPerView: pressPageSection.length - 4,
  },
  640: {
    slidesPerView: pressPageSection.length - 4,
  },
  768: {
    slidesPerView: pressPageSection.length - 6,
  },
  900: {
    slidesPerView: pressPageSection.length - 5,
  },
  1024: {
    slidesPerView: pressPageSection.length - 4,
  },
  1280: {
    slidesPerView: pressPageSection.length - 2,
  },
  1536: {
    slidesPerView: pressPageSection.length,
  },
};

export default companyNavbarBreakpoints;
