import PropTypes from 'prop-types';
import React from 'react';

const InputFieldUploadHint = ({ children = null }) => (
  <p className="text-xs text-gray-400">{children}</p>
);

InputFieldUploadHint.propTypes = {
  children: PropTypes.node,
};

export default InputFieldUploadHint;
