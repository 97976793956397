import PropTypes from 'prop-types';
import React from 'react';

import { LinkIcon } from '@heroicons/react/outline';

import SuccessFeesModalDefinition from 'components/successFees/SuccessFeesModal/SuccessFeesModalDefinition';

import SuccessFeesStatus from '../SuccessFeesStatus';

import SuccessFeesModalInfoOpportunity from './SuccessFeesModalInfoOpportunity';

const SuccessFeesModalInfo = ({
  status = null,
  link = null,
  dashboardLink = null,
  threadId = '',
  companyId = '',
  successFeeId = '',
  error = '',
}) => (
  <div className="flex flex-col gap-2 mt-4">
    <SuccessFeesModalInfoOpportunity
      threadId={threadId}
      companyId={companyId}
      successFeeId={successFeeId}
    />
    <SuccessFeesModalDefinition label="Status:">
      {status ? (
        <SuccessFeesStatus
          status={status}
          error={error}
          stripeDashboardLink={dashboardLink}
        />
      ) : (
        'n/a'
      )}
    </SuccessFeesModalDefinition>
    <SuccessFeesModalDefinition label="Invoice Link:">
      {link ? (
        <div className="text-teal-600 flex items-center gap-1">
          <a href={link} target="_blank" rel="noreferrer">
            Receipt Link
          </a>
          <LinkIcon className="h-4 w-4" />
        </div>
      ) : (
        'No invoice link available'
      )}
    </SuccessFeesModalDefinition>
  </div>
);

SuccessFeesModalInfo.propTypes = {
  error: PropTypes.string,
  status: PropTypes.string,
  link: PropTypes.string,
  dashboardLink: PropTypes.string,
  threadId: PropTypes.string,
  companyId: PropTypes.string,
  successFeeId: PropTypes.string,
};

export default SuccessFeesModalInfo;
