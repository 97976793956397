/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import SweetheartListProvider from 'contexts/SweetheartListContext/SweetheartListContext';
import { useTracking } from 'modules/segment/TrackingProvider';

import SweetheartList from './SweetheartList';
import SweetheartListPageBeingGenerated from './SweetheartListViewStates/SweetheartListPageBeingGenerated';

const SweetheartListPage = () => {
  const company = useCompanyData();
  const trackingService = useTracking();

  useEffect(() => {
    trackingService.page('SweetheartList', {});
  }, [trackingService]);

  if (company.companyData) {
    return (
      <SweetheartListProvider uid={company.companyData.uid}>
        <SweetheartList company={company.companyData} />
      </SweetheartListProvider>
    );
  }

  return <SweetheartListPageBeingGenerated />;
};

export default SweetheartListPage;
