import noop from 'lodash/noop';

const onArchive =
  ({
    threadRef = {},
    isArchived = false,
    showSuccess = noop,
    showError = noop,
    setDocument = noop,
  }) =>
  async (e) => {
    e.stopPropagation();
    try {
      const state = isArchived ? 'unarchived' : 'archived';
      await setDocument(
        threadRef,
        { isArchived: !isArchived },
        { merge: true }
      );
      showSuccess({ message: `Thread has been ${state}` });
    } catch (error) {
      const action = isArchived ? 'unarchive' : 'archive';
      showError({
        title: 'Whoops, something went wrong',
        message: `We couldn't ${action} the thread`,
      });
    }
  };

export default onArchive;
