import React, { useState } from 'react';

import { LightningBoltIcon, PlusIcon } from '@heroicons/react/solid';
import { collection, deleteDoc, doc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import OpportunityQuery from 'components/opportunities/opportunity/OpportunityQuery';
import OpportunityType from 'components/opportunities/opportunity/OpportunityType';
import defaultOptions from 'constants/defaultOptions';
import { CUSTOM_OPPORTUNITIES_COLLECTION } from 'constants/firebasePaths';
import intents from 'constants/intents';
import useAPIRequest from 'hooks/useAPIRequest';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';
import getPageTitle from 'utils/getPageTitle';

import AdminWrapper from '../AdminWrapper';

import AddOpportunityModal from './AdminAddOpportunityModal';

const AdminCreateOpportunity = () => {
  const { showError } = useNotifications();
  const firestore = useFirestore();
  const { fetchData: upsertOpportunity } = useAPIRequest({
    endpoint: '/opportunities',
    method: 'PUT',
    service: 'MOD',
  });
  const opportunitiesCollection = collection(
    firestore,
    CUSTOM_OPPORTUNITIES_COLLECTION
  );
  const opportunities = useFirestoreCollectionData(
    opportunitiesCollection,
    defaultOptions
  );
  const { data: companiesData } = useAPIRequest({
    endpoint: '/company/list',
    service: 'UCD',
  });

  const [showAddOpportunityModal, setShowAddOpportunityModal] = useState(false);
  const [opportunityToEdit, setOpportunityToEdit] = useState(null);

  const hydrateOpportunityWithTargetedCompanies = (defaultOpportunity) => {
    if (defaultOpportunity.targetedCompanies?.length) {
      const hydratedTargetedCompanies =
        defaultOpportunity.targetedCompanies.map((targetedCompany) => {
          const result = companiesData.find(
            (company) => company.id === targetedCompany
          );

          return {
            id: result.id,
            name: result.name,
          };
        });

      return {
        ...defaultOpportunity,
        targetedCompanies: hydratedTargetedCompanies,
      };
    }

    return defaultOpportunity;
  };

  const onSubmit = async (data) => {
    // create date in UTC time
    const dateUtc = createTimestampFromDateObj(data.deadline, true);

    // Add offset (London/UK)
    const deadlineUTC = data.evergreen ? null : dateUtc;

    const { html, text } = data.description;

    const opportunity = {
      deadlineUTC,
      subject: data.subject,
      domainAuthority: data.domainAuthority,
      journalist: data.journalist,
      mediaOutlet: data.mediaOutlet,
      mediaOutletWebsite: data.mediaOutletWebsite,
      enquirySummary: data.enquirySummary,
      query: text.split('\n'),
      description: text,
      descriptionHtml: html,
      type: data.type,
      exclusive: data.exclusive ?? false,
      targetedCompanies: (data.targetedCompanies ?? []).map(
        (targetedCompany) => targetedCompany.id
      ),
      intent: intents.opportunities.CUSTOM_OPPORTUNITY,
      custom: true,
    };

    try {
      const opportunityPayload = { ...opportunity };
      if (opportunityToEdit?.uid) {
        opportunityPayload.uid = opportunityToEdit.uid;
      }

      await upsertOpportunity({
        body: opportunityPayload,
      });

      setShowAddOpportunityModal(false);
      setOpportunityToEdit(null);
    } catch (error) {
      showError({ message: 'There was an error saving opportunity' });
    }
  };

  return (
    <AdminWrapper currentIndex={3} title="Custom opportunities">
      <Helmet>
        <title>{getPageTitle('Admin - Custom opportunities')}</title>
      </Helmet>
      <div className="flex justify-between items-start">
        <p className="mt-2">
          Below you can find a list of <strong>custom opportunities</strong>
        </p>

        <Button size="base" onClick={() => setShowAddOpportunityModal(true)}>
          <PlusIcon className="w-4 mr-2" />
          Add opportunity
        </Button>
      </div>
      <div className="flex flex-col mt-8">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 text-xs font-medium text-gray-500 uppercase">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      Enquiry summary / Query
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      Media outlet
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      <LightningBoltIcon className="w-4" />
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      Journalist
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      Deadline (UTC)
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left tracking-wider"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {opportunities.data.map((opportunity) => (
                    <tr key={opportunity.uid}>
                      <td className="p-4 align-top">
                        <OpportunityType type={opportunity.type} />
                        <p className="text-sm text-gray-700 font-medium">
                          {opportunity.enquirySummary}
                        </p>

                        <OpportunityQuery
                          opportunityId={opportunity.uid}
                          query={opportunity.query}
                          collapsed
                        />
                      </td>

                      <td className="p-4 align-top">
                        <p className="text-sm text-gray-900">
                          <a
                            className="underline"
                            rel="noreferrer"
                            href={opportunity.mediaOutletWebsite}
                            target="_blank"
                          >
                            {opportunity.mediaOutlet}
                          </a>
                        </p>
                      </td>

                      <td className="p-4 align-top">
                        <p className="text-sm text-gray-900">
                          <DomainAuthorityPill
                            domainAuthority={opportunity.domainAuthority}
                          />
                        </p>
                      </td>
                      <td className="p-4 align-top">
                        <p className="text-sm text-gray-90">
                          {opportunity.journalist}
                        </p>
                      </td>
                      <td className="p-4 align-top">
                        <p className="text-sm text-gray-900">
                          {opportunity.deadlineUTC
                            ?.toDate()
                            .toLocaleString('en-GB', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              timeZone: 'UTC',
                            }) ?? 'Ongoing'}
                        </p>
                      </td>
                      <td>
                        <div className="p-4 space-y-2 flex flex-col">
                          <Button
                            type="secondary"
                            onClick={() =>
                              setOpportunityToEdit(
                                hydrateOpportunityWithTargetedCompanies(
                                  opportunity
                                )
                              )
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            type="secondary"
                            onClick={async () => {
                              await deleteDoc(
                                doc(opportunitiesCollection, opportunity.uid)
                              );
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {showAddOpportunityModal || !!opportunityToEdit ? (
        <AddOpportunityModal
          open={showAddOpportunityModal || !!opportunityToEdit}
          onClose={() => {
            setShowAddOpportunityModal(false);
            setOpportunityToEdit(null);
          }}
          onSubmit={onSubmit}
          companiesData={companiesData}
          opportunity={opportunityToEdit || undefined}
        />
      ) : null}
    </AdminWrapper>
  );
};

export default AdminCreateOpportunity;
