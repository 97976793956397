import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { TrashIcon } from '@heroicons/react/solid';
import { getDownloadURL, ref } from 'firebase/storage';
import { useStorage } from 'reactfire';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import { useTracking } from 'modules/segment/TrackingProvider';
import downloadFile from 'utils/file/downloadFile';

import CompanyDownloadsDeleteModal from '../CompanyDownloadsDeleteModal';
import CompanyDownloadsEditModal from '../CompanyDownloadsEditModal';

import CompanyDownloadPreview from './CompanyDownloadPreview';
import CompanyDownloadTags from './CompanyDownloadTags';
import CompanyDownloadWrapper from './CompanyDownloadWrapper';

const CompanyDownload = ({
  download = null,
  showOwnerView = false,
  companyId = '',
  ops = {},
}) => {
  const storage = useStorage();

  const [downloadUrl, setDownloadUrl] = useState('');
  useEffect(() => {
    (async () => {
      const url = await getDownloadURL(ref(storage, download?.file?.path));
      setDownloadUrl(url);
    })();
  }, [download?.file?.path, storage]);

  const trackingService = useTracking();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onSubmit = (data = {}) => {
    const { title, file, founder, tags } = data;
    ops.updateDoc(download.uid, {
      title,
      file,
      founder: founder?.name ? { name: founder.name } : null,
      users: null,
      tags,
    });
    setIsEditModalOpen(false);
  };

  const onDelete = () => {
    ops.deleteDoc(download.uid);
    setIsDeleteModalOpen(false);
  };

  const onClick = async (e) => {
    e.preventDefault();
    trackingService.track('pressPage_view', {
      action: 'download_open',
      download_title: download.title,
      download_url: downloadUrl,
    });

    const url = await getDownloadURL(ref(storage, download.file.path));
    downloadFile(url, download.title);
  };

  return (
    <>
      <CompanyDownloadWrapper
        url={downloadUrl}
        showOwnerView={showOwnerView}
        onClick={onClick}
      >
        <CompanyDownloadPreview download={download} />

        {showOwnerView && (
          <div className="absolute top-4 right-4">
            <DotsMenu
              options={[
                {
                  label: `Edit download`,
                  onClick: () => setIsEditModalOpen(true),
                  testId: `company-download-${download.title}-menu-edit`,
                },
                {
                  label: `Delete download`,
                  icon: <TrashIcon className="w-4 h-4" />,
                  onClick: () => setIsDeleteModalOpen(true),
                  testId: `company-download-${download.title}-menu-delete`,
                },
              ]}
              testId={`company-download-${download.title}-menu`}
            />
          </div>
        )}

        <div className="w-full bg-white p-4 rounded-b-lg">
          <CompanyDownloadTags
            tags={download.tags}
            testId={`company-download-${download.title}-tags`}
          />
          <p
            className="mt-1 text-lg font-medium text-gray-700 line-clamp-2"
            data-test-id={`company-download-${download.title}-title`}
          >
            {download.title}
          </p>
        </div>
      </CompanyDownloadWrapper>

      {showOwnerView && isEditModalOpen ? (
        <CompanyDownloadsEditModal
          open={isEditModalOpen}
          download={download}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onSubmit}
          uploadFolder={`companies/${companyId}/downloads`}
        />
      ) : null}
      {showOwnerView && (
        <CompanyDownloadsDeleteModal
          open={isDeleteModalOpen}
          title={download.title}
          onDelete={onDelete}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}
    </>
  );
};

CompanyDownload.propTypes = {
  download: PropTypes.object.isRequired,
  ops: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
};

export default CompanyDownload;
