import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';

const ModalTitle = ({ children = null, testId = '' }) => (
  <Dialog.Title
    as="h3"
    data-test-id={testId}
    className="text-center text-lg leading-6 font-medium text-gray-900"
  >
    {children}
  </Dialog.Title>
);

ModalTitle.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

export default ModalTitle;
