import PropTypes from 'prop-types';
import React from 'react';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';

import OpportunityMeta from './OpportunityMeta';
import capitalizeFirstLetter from './helpers/capitalizeFirstLetter';

const OpportunityBody = ({
  custom = false,
  enquirySummary = '',
  journalistData = {},
}) => (
  <>
    <div className="flex justify-between mt-1">
      <h3 className="text-2xl font-semibold">
        {capitalizeFirstLetter(enquirySummary)}
      </h3>
    </div>
    <div
      className="mt-3 flex items-center space-x-2"
      data-intercom-id="opportunity-publicationJournalist"
    >
      <PublicationImage
        custom={custom}
        url={journalistData?.publication?.image}
        name={journalistData?.publication?.name}
      />
      <OpportunityMeta text={journalistData?.publication?.name} />

      <DomainAuthorityPill
        domainAuthority={journalistData?.publication?.domainAuthority}
      />
      <div className="w-1" />
      <JournalistImage url={journalistData.image} name={journalistData?.name} />
      <OpportunityMeta text={journalistData.name} />
    </div>
  </>
);

OpportunityBody.propTypes = {
  custom: PropTypes.bool,
  enquirySummary: PropTypes.string,
  journalistData: PropTypes.object,
};

export default OpportunityBody;
