import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { PlusIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import noop from 'lodash/noop';

import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import useUserName from 'components/layout/Navbar/hooks/useUserName';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';

import InboxMessageResponseFormEditorNewTemplateModal from './InboxMessageResponseFormEditorNewTemplateModal';
import getSignature from './helpers/getSignature';
import responseTemplates from './helpers/responseTemplates';

const createTemplateOptions = (companyTemplates = []) => [
  ...companyTemplates.map((t) => ({
    value: t.name,
    label: t.name,
  })),
  ...Object.keys(responseTemplates).map((key) => ({
    value: key,
    label: key,
  })),
  {
    value: 'create_new_template',
    label: (isActive) => (
      <span className="flex gap-2">
        <PlusIcon
          className={classNames('w-5 h-5', {
            'text-teal-500': !isActive,
            'text-white': isActive,
          })}
        />
        Create new...
      </span>
    ),
  },
];

const getNewInitialTemplate = ({
  templateName = '',
  companyTemplates = [],
  companyData = {},
  opportunity = {},
  userName = '',
}) => {
  let newInitialTemplate;
  const companyTemplate = companyTemplates.find((t) => t.name === templateName);

  if (companyTemplate) {
    newInitialTemplate = companyTemplate.text;
  } else {
    newInitialTemplate =
      responseTemplates[templateName]({
        company: companyData,
        opportunity,
      }) + getSignature(userName, companyData.name);
  }

  return newInitialTemplate;
};

const InboxMessageResponseFormEditorTemplateDropdown = ({
  template = '',
  opportunity = {},
  setInitialTemplate = noop,
  saveDraft = noop,
  addSavedDraftNotification = noop,
}) => {
  const userName = useUserName();
  const { companyData } = useCompanyData();
  const { data: companyTemplates, fetchData: getTemplates } = useAPIRequest({
    endpoint: `/companies/${companyData.uid}/templates`,
    method: 'GET',
    service: 'MOD',
    initialData: [],
  });

  const [isNewTemplateModalOpen, setIsNewTemplateModalOpen] = useState(false);

  return (
    <div className="flex gap-2 items-baseline flex-wrap">
      <Label htmlFor="template">Choose a template</Label>
      <InputFieldDropdown
        name="template"
        testId="inboxMessage-responseFormDropdown"
        intercomId="inbox-messages-templates"
        options={createTemplateOptions(companyTemplates)}
        value={template}
        onChange={(newTemplateName) => {
          if (newTemplateName === 'create_new_template') {
            setIsNewTemplateModalOpen(true);
            return;
          }

          const newInitialTemplate = getNewInitialTemplate({
            templateName: newTemplateName,
            companyTemplates,
            companyData,
            opportunity,
            userName,
          });

          setInitialTemplate(newInitialTemplate);
          saveDraft(
            {
              template: newTemplateName,
              html: newInitialTemplate,
            },
            addSavedDraftNotification
          );
        }}
        wrapperClassName="w-fit"
        buttonClassName="w-60"
      />
      {isNewTemplateModalOpen ? (
        <InboxMessageResponseFormEditorNewTemplateModal
          onCancel={() => {
            setIsNewTemplateModalOpen(false);
            getTemplates();
          }}
        />
      ) : null}
    </div>
  );
};

InboxMessageResponseFormEditorTemplateDropdown.propTypes = {
  template: PropTypes.string,
  opportunity: PropTypes.object,
  setInitialTemplate: PropTypes.func,
  saveDraft: PropTypes.func,
  addSavedDraftNotification: PropTypes.func,
};

export default InboxMessageResponseFormEditorTemplateDropdown;
