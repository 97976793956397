import { useMemo } from 'react';

import createGetCompoundComponentName from 'utils/components/createGetCompoundComponentName';

// Finds all valid compound components in the children array and returns an
// object with this structure: { [compoundComponentName]: child }
const useCompoundComponents = (children = [], compoundComponents = {}) => {
  const getChildType = useMemo(
    () => createGetCompoundComponentName(compoundComponents),
    [compoundComponents]
  );

  return useMemo(() => {
    if (!Array.isArray(children)) {
      const type = getChildType(children);
      return { [type]: children };
    }

    return children.reduce((acc, child) => {
      const type = getChildType(child);
      return { ...acc, [type]: child };
    }, {});
  }, [children, getChildType]);
};

export default useCompoundComponents;
