import PropTypes from 'prop-types';
import React from 'react';

import OpportunitiesFilter from 'components/opportunities/OpportunitiesFilter/OpportunitiesFilter';
import { filterOptions } from 'constants/opportunitiesOptions';

import useCreateSetState from '../hooks/useCreateSetState';
import useQueryParams from '../hooks/useQueryParams';

const OpportunitiesControlsOrganizeFilter = ({ fromModal = false }) => {
  const { filter } = useQueryParams();
  const setFilter = useCreateSetState('filter');

  return (
    <OpportunitiesFilter
      filter={filter.map((f) => filterOptions.find((o) => o.index === f))}
      setFilter={(f) => setFilter(f.map((o) => o.index))}
      {...(fromModal
        ? {
            buttonClassName: 'w-full',
            wrapperClassName: 'block',
          }
        : {})}
    />
  );
};

OpportunitiesControlsOrganizeFilter.propTypes = {
  fromModal: PropTypes.bool,
};

export default OpportunitiesControlsOrganizeFilter;
