import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';

import createAnalyticsService from './analyticsServiceFactory';
import TrackingService from './trackingService';

export const TrackingContext = createContext(null);

export const TrackingProvider = ({ children, firebaseAnalytics }) => {
  const trackingService = useMemo(() => {
    const service = new TrackingService();

    const googleAnalyticsAdapter = firebaseAnalytics
      ? createAnalyticsService({
          serviceName: 'FirebaseAnalytics',
          analytics: firebaseAnalytics,
        })
      : null;

    const segmentAdapter = createAnalyticsService({
      serviceName: 'Segment',
      key: process.env.REACT_APP_SEGMENT_API_KEY,
    });

    service.setAdapters({
      Segment: segmentAdapter,
      GoogleAnalytics: googleAnalyticsAdapter,
    });

    service.setDefaultAdapter(googleAnalyticsAdapter);

    return service;
  }, [firebaseAnalytics]);

  return (
    <TrackingContext.Provider value={trackingService}>
      {children}
    </TrackingContext.Provider>
  );
};

TrackingProvider.propTypes = {
  children: PropTypes.node,
  firebaseAnalytics: PropTypes.object,
};

export const useTracking = () => useContext(TrackingContext);
