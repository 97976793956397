import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { SearchIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { debounce } from 'lodash';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import JournalistImage from 'components/images/JournalistImage';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';
import useJournalistsBySearch from 'hooks/useJournalistsBySearch';

import SweetheartListAddJournalistModalNoResultFound from './SweetheartListAddJournalistModalNoResultFound';

const getButtonLabel = (isAlreadyInList = false, isDeactivated = false) => {
  if (isAlreadyInList) {
    return 'Added';
  }

  if (isDeactivated) {
    return 'Deactivated';
  }

  return 'Add';
};

const SweetheartListAddJournalistModal = ({ onAbort = noop }) => {
  const { journalists, insertJournalist } = useSweetheartListContext();
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [clickedJournalists, setClickedJournalists] = useState([]);

  const { loading, journalists: suggestions } =
    useJournalistsBySearch(debouncedInputValue);

  const isJournalistInSHL = (journalist) =>
    journalists.some((j) => j.id === journalist.id) ||
    clickedJournalists.includes(journalist.id);

  const onChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddJournalist = (journalistId) => () => {
    insertJournalist(journalistId);
    setClickedJournalists((prevIds) => [...prevIds, journalistId]);
  };

  useEffect(() => {
    const handleDebouncedSearch = debounce(() => {
      setDebouncedInputValue(inputValue);
    }, 500);

    if (inputValue.length >= 3 || inputValue === '') {
      handleDebouncedSearch();
    }

    // Cleanup the debounce on unmount
    return () => handleDebouncedSearch.cancel();
  }, [inputValue]);

  return (
    <Modal open widthClass="max-w-3xl">
      <Modal.Close srMessage="Close find journalist Modal" onClose={onAbort} />
      <Modal.Title>Add journalist to Sweetheart List</Modal.Title>
      <Modal.Content>
        <div className="mb-4">
          <InputFieldText
            type="text"
            containerClassName="w-full"
            name="search"
            placeholder="Search by journalist"
            value={inputValue}
            onChange={onChange}
          >
            <InputFieldText.Prefix>
              <SearchIcon className="w-4 h-4" />
            </InputFieldText.Prefix>
          </InputFieldText>
        </div>
        <ul
          className={classNames(
            'h-[calc(100vh-370px)] overflow-auto flex flex-col',
            {
              'opacity-20': loading,
            }
          )}
        >
          {suggestions.length > 0 &&
            suggestions.map((j) => {
              const isAlreadyInList = isJournalistInSHL(j);
              const isDisabled = isAlreadyInList || j.isDeactivated;

              return (
                <li
                  key={j.id}
                  className={classNames(
                    'grid grid-cols-[auto,1fr,1fr,0.7fr] items-center gap-2',
                    {
                      'odd:bg-gray-200 opacity-50 pointer-events-none':
                        isDisabled,
                      'odd:bg-gray-50': !isDisabled,
                    }
                  )}
                >
                  <JournalistImage
                    url={j.image}
                    className="h-6 w-6 ml-1"
                    name={j.name}
                  />
                  <div className="flex flex-col justify-center">
                    <b>{j.name}</b>
                  </div>
                  <div className="flex flex-col justify-center">
                    <span>{j?.publication?.name || 'n/a'}</span>
                  </div>
                  <Button
                    disabled={isDisabled}
                    type={isDisabled ? 'secondary' : 'primary'}
                    onClick={handleAddJournalist(j.id)}
                    className="my-2"
                  >
                    {getButtonLabel(isAlreadyInList, j.isDeactivated)}
                  </Button>
                </li>
              );
            })}
        </ul>
        {loading && <Spinner />}
        {!suggestions.length && !loading && (
          <SweetheartListAddJournalistModalNoResultFound />
        )}
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" className="w-fit" onClick={onAbort}>
          Cancel
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

SweetheartListAddJournalistModal.propTypes = {
  onAbort: PropTypes.func,
};

export default SweetheartListAddJournalistModal;
