import React, { useEffect } from 'react';

import { signOut, signInWithEmailAndPassword } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAuth } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import PageWrapper from 'components/PageWrapper';
import Spinner from 'components/Spinner';
import { COMPANY_PATH, SIGNUP_MEMBER_PATH } from 'constants/paths';
import useAPIRequest from 'hooks/useAPIRequest';
import useQuery from 'hooks/useQuery';
import { useTracking } from 'modules/segment/TrackingProvider';
import getPageTitle from 'utils/getPageTitle';

import SignupMemberForm from './SignupMemberForm';
import useInviteByToken from './hooks/useInviteByToken';

const SignupMemberPage = () => {
  const { showSuccess, showError } = useNotifications();
  const navigate = useNavigate();
  const trackingService = useTracking();

  const query = useQuery();
  const auth = useAuth();
  const token = query.get('token');
  const { invite, loading: isInviteLoading } = useInviteByToken(token);
  const { fetchData: claimToken } = useAPIRequest({
    method: 'POST',
    service: 'UCD',
  });

  const logout = async () => {
    await signOut(auth);
    navigate(SIGNUP_MEMBER_PATH);
  };

  const onSubmit = async ({ email, password, firstName, lastName }) => {
    try {
      await claimToken({
        endpoint: `/invites/${token}/claim`,
        body: {
          email,
          password,
          firstName,
          lastName,
        },
      });

      await signInWithEmailAndPassword(auth, email, password);

      navigate(generatePath(COMPANY_PATH, { id: invite?.company?.slug }));

      trackingService.track('Invite_Claimed', {
        token,
        company: invite.company.name,
        firstName,
        lastName,
        email,
      });

      showSuccess({
        message: 'Signup successful!',
      });
    } catch (err) {
      if (err?.code === 'auth/email-already-in-use') {
        showError({
          message: 'Email already exists in the system.',
        });
      } else {
        await logout();
        showError({
          message: 'Signup rejected by the system. Please try again.',
        });
      }
    }
  };

  useEffect(() => {
    trackingService.page('MemberSignup');
  }, [trackingService]);

  useEffect(() => {
    if (token && invite && invite.status === 'pending') {
      trackingService.track('SignedUp', {
        type: 'member',
        token,
        email: invite.email,
      });
    }
  }, [invite, token, trackingService]);

  if (isInviteLoading) {
    return <Spinner />;
  }

  if (invite.status !== 'pending') {
    return (
      <div className="absolute inset-0 mt-24 flex justify-center items-center text-center">
        <p>This invite has already been claimed.</p>
      </div>
    );
  }

  return (
    <PageWrapper className="p-0">
      <Helmet>
        <title>{getPageTitle('Signup')}</title>
      </Helmet>
      <SignupMemberForm
        onSubmit={onSubmit}
        email={invite.email}
        companyName={invite?.company?.name}
      />
    </PageWrapper>
  );
};

export default SignupMemberPage;
