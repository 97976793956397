import PropTypes from 'prop-types';
import React from 'react';

import { IdentificationIcon, LightningBoltIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import noop from 'lodash/noop';

import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import useAPIRequest from 'hooks/useAPIRequest';

const CompanyThreadAssignModalThreads = ({
  company = {},
  selectedThread = {},
  setSelectedThread = noop,
}) => {
  const { data: threads, loading } = useAPIRequest({
    service: 'MOD',
    endpoint: `/companies/${company.uid}/threads`,
    method: 'GET',
    initialData: [],
  });

  const activeOrArchivedThreads = threads.filter(
    (t) => t.status === 'active' || t.status === 'archived'
  );
  return (
    <div className="text-gray-700 text-left space-y-2 relative">
      {loading && (
        <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center">
          <Spinner />
        </div>
      )}
      <hr className="my-4" />
      <span className="font-semibold">
        Threads:
        {company.name ? (
          <span className="inline-flex gap-2 items-center bg-teal-50 px-2 py-0.5 rounded-md text-teal-700">
            <LightningBoltIcon className="h-4 w-4" />
            {company.name}
          </span>
        ) : null}
      </span>
      {!activeOrArchivedThreads.length && !loading ? (
        <p className="italic text-gray-500 text-sm text-center pt-2">
          No threads found for <b>{company.name}</b> company.
        </p>
      ) : null}
      <div className="flex flex-col gap-2">
        {activeOrArchivedThreads.map((thread) => {
          const isSelected = thread.id === selectedThread.id;
          const { subject, journalist } = thread;

          return (
            <div
              key={thread.uid}
              className={classNames(
                'flex justify-between max-w-full items-center gap-2 border border-gray-300 rounded-md p-2',
                {
                  'border-teal-500': isSelected,
                }
              )}
            >
              <div className="flex flex-col gap-2">
                <p className="font-semibold text-sm">{subject}</p>
                {journalist?.name ? (
                  <p className="text-sm flex gap-2">
                    <IdentificationIcon className="w-4 h-4" />
                    <span>{journalist.name}</span>
                    {journalist?.publication?.name && (
                      <span className="text-teal-500">
                        {journalist.publication.name}
                      </span>
                    )}
                  </p>
                ) : null}
              </div>
              <Button
                className="shrink-0 h-fit"
                type={isSelected ? 'primary' : 'secondary'}
                onClick={() => {
                  if (!isSelected) {
                    setSelectedThread(thread);
                  } else {
                    setSelectedThread({});
                  }
                }}
              >
                {isSelected ? 'Selected' : 'Select'}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

CompanyThreadAssignModalThreads.propTypes = {
  company: PropTypes.object,
  selectedThread: PropTypes.object,
  setSelectedThread: PropTypes.func,
};

export default CompanyThreadAssignModalThreads;
