import { useCallback, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import getPrettifiedQueryString from '../helpers/getPrettifiedQueryString';
import removeDefaultQueries from '../helpers/removeDefaultQueries';

const useCreateSetState = (name = '') => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const currentValue = currentSearchParams.get(name);

  return useCallback(
    (value) => {
      if (typeof value === 'function') {
        currentSearchParams.set(name, value(currentValue));
      } else {
        currentSearchParams.set(name, value);
      }

      const queryString = getPrettifiedQueryString(
        removeDefaultQueries(`?${currentSearchParams.toString()}`)
      );

      navigate(`${location.pathname}?${queryString}`);
    },
    [currentSearchParams, currentValue, location.pathname, name, navigate]
  );
};

export default useCreateSetState;
