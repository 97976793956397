import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import noop from 'lodash/noop';

import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import vcs from 'constants/vcs.json';
import useFormArray from 'hooks/useFormArray';

const validate = (value = []) => {
  if (value.length === 0) {
    return 'Please add at least one lead investor';
  }
  return null;
};

const ContentElementFundraiseLeadInvestors = ({
  index = 0,
  updateCallback = noop,
}) => {
  const name = 'leadInvestors';
  const {
    formApi: { formState, trigger, getValues },
    update,
  } = useFormArray({
    name,
    validate,
    updateCallback,
  });
  const error = get(formState.errors, name, null);

  return (
    <div className="w-full">
      <Label className="mb-2" htmlFor={`${name}${index}`}>
        Investors leading the round*
      </Label>
      <InputFieldTagsAutocomplete
        onChange={(value) => {
          update(value);
          trigger(name);
        }}
        customFieldName={name}
        id={`${name}${index}`}
        buttonClassName="w-full"
        placeholder="i.e. Backed VC, Angel Investor..."
        suggestions={vcs}
        value={getValues(name) || []}
        testId="company-content-fundraise-inputLeadInvestors"
      />
      {error && (
        <ErrorMessage testId="company-content-fundraise-inputLeadInvestors-error">
          {error.message}
        </ErrorMessage>
      )}
    </div>
  );
};

ContentElementFundraiseLeadInvestors.propTypes = {
  index: PropTypes.number,
  updateCallback: PropTypes.func,
};

export default ContentElementFundraiseLeadInvestors;
