import React from 'react';

import Label from 'components/form/Label';
import InputFieldTagsDropdown from 'components/form/inputFields/InputFieldTagsDropdown/InputFieldTagsDropdown';
import { coreTopicsForUser } from 'constants/tags';

import useCompaniesStore from './hooks/useCompaniesStore';

const AdminCompaniesTopicFilter = () => {
  const topics = useCompaniesStore((state) => state.topics);
  const companiesTopics = useCompaniesStore((state) => state.companiesTopics);

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="topics">Topics</Label>
      <div className="w-full">
        <InputFieldTagsDropdown
          tags={companiesTopics}
          tagsIndex={coreTopicsForUser}
          buttonClassName="w-[calc(20ch+3rem)]"
          placeholder="Select topics to filter"
          onChange={(v) => useCompaniesStore.setState({ topics: v })}
          value={topics}
        />
      </div>
    </div>
  );
};

export default AdminCompaniesTopicFilter;
