import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

import Spinner from 'components/Spinner';

import CompanyThreadAssignModalCompanySearchResult from './CompanyThreadAssignModalCompanySearchResult';

const CompanyThreadAssignModalCompanySearchResults = ({
  isFetching = false,
  companies = [],
  selectedCompany = noop,
  onCompanyClick = noop,
}) => (
  <div className="flex flex-col gap-2 relative">
    {isFetching && <Spinner />}
    {selectedCompany.uid && (
      <CompanyThreadAssignModalCompanySearchResult
        company={selectedCompany}
        selectedCompany={selectedCompany}
        onCompanyClick={onCompanyClick}
      />
    )}
    <div
      className={classNames('flex flex-col gap-2 relative', {
        'opacity-50': isFetching,
      })}
    >
      {companies?.map((c) => (
        <CompanyThreadAssignModalCompanySearchResult
          key={c.uid}
          company={c}
          selectedCompany={selectedCompany}
          onCompanyClick={onCompanyClick}
        />
      ))}
    </div>
  </div>
);

CompanyThreadAssignModalCompanySearchResults.propTypes = {
  companies: PropTypes.array,
  selectedCompany: PropTypes.object,
  onCompanyClick: PropTypes.func,
  isFetching: PropTypes.bool,
};

export default CompanyThreadAssignModalCompanySearchResults;
