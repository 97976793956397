import eventServiceMap from './eventServiceMap';

export default class TrackingService {
  constructor() {
    this.adapters = {};
  }

  setAdapters(adapters) {
    this.adapters = adapters;
  }

  setDefaultAdapter(adapter) {
    this.defaultAdapter = adapter;
  }

  page(pageName, properties = {}) {
    Object.values(this.adapters).forEach((adapter) => {
      adapter.page?.(pageName, properties);
    });
  }

  track(eventName, eventData) {
    const adapterTouse = eventServiceMap[eventName];

    const adapter = this.adapters[adapterTouse] || this.defaultAdapter;

    if (adapter) {
      adapter.track(eventName, eventData);
    }
  }

  identify(userId, params) {
    Object.values(this.adapters).forEach((adapter) => {
      adapter.identify?.(userId, params);
    });
  }

  setUserProperties(userId, userProperties) {
    Object.values(this.adapters).forEach((adapter) => {
      adapter.setUserProperties?.(userId, userProperties);
    });
  }

  setAnalyticsCollectionEnabled(isEnabled) {
    Object.values(this.adapters).forEach((adapter) => {
      adapter.setAnalyticsCollectionEnabled?.(isEnabled);
    });
  }
}
