import ContentElementBrand from 'components/company/ContentElement/ContentElementBrand/ContentElementBrand';
import ContentElementFundraise from 'components/company/ContentElement/ContentElementFundraise/ContentElementFundraise';
import ContentElementHire from 'components/company/ContentElement/ContentElementHire/ContentElementHire';
import ContentElementIPO from 'components/company/ContentElement/ContentElementIPO/ContentElementIPO';
import ContentElementMA from 'components/company/ContentElement/ContentElementMA/ContentElementMA';
import ContentElementOpEd from 'components/company/ContentElement/ContentElementOpEd/ContentElementOpEd';
import ContentElementPartnership from 'components/company/ContentElement/ContentElementPartnership/ContentElementPartnership';
import ContentElementProductService from 'components/company/ContentElement/ContentElementProductService/ContentElementProductService';
import { todaysDateObj } from 'utils/date/dateRange';

import fundingRoundTypes from './fundingRoundTypes';

const contentOnboardingDefaults = {
  opEd: {
    component: ContentElementOpEd,
    defaultValues: {
      type: 'opEd',
      file: null,
      pastExamples: Array(2).fill(''),
      title: '',
      bulletPoints: Array(3).fill(''),
      linkedInUrl: '',
    },
  },
  fundraise: {
    component: ContentElementFundraise,
    defaultValues: {
      type: 'fundraise',
      file: null,
      capitalRaised: {
        currency: 'USD',
        amount: 0,
      },
      fundingRound: fundingRoundTypes[0],
      valuation: {
        currency: 'USD',
        amount: 0,
      },
      leadInvestors: [],
      embargoDate: todaysDateObj,
    },
  },
  'm&a': {
    component: ContentElementMA,
    defaultValues: {
      type: 'm&a',
      file: null,
      companyAcquiredCountry: '',
      companyAcquiredName: '',
      companyAcquiringName: '',
      dealSize: {
        amount: 0,
        currency: 'USD',
      },
      other: '',
      sector: [],
    },
  },
  ipo: {
    component: ContentElementIPO,
    defaultValues: {
      type: 'ipo',
      file: null,
      embargoDate: todaysDateObj,
      companyName: '',
      stockExchanges: [],
      other: '',
    },
  },
  hire: {
    component: ContentElementHire,
    defaultValues: {
      type: 'hire',
      file: null,
      name: '',
      linkedInUrl: '',
      companyName: '',
      position: '',
    },
  },
  brand: {
    component: ContentElementBrand,
    defaultValues: {
      type: 'brand',
      headline: '',
      description: '',
      other: '',
      embargoDate: todaysDateObj,
      file: null,
    },
  },
  'product-service': {
    component: ContentElementProductService,
    defaultValues: {
      type: 'product-service',
      headline: '',
      description: '',
      embargoDate: todaysDateObj,
      name: '',
      link: '',
      file: null,
      other: '',
    },
  },
  partnership: {
    component: ContentElementPartnership,
    defaultValues: {
      type: 'partnership',
      headline: '',
      companies: [],
      embargoDate: todaysDateObj,
      file: null,
    },
  },
};

export default contentOnboardingDefaults;
