import sortBy from 'lodash/sortBy';

// sorts opportunities by one of the available options and given sort direction
const sortOpportunities = (
  // eslint-disable-next-line default-param-last
  opportunities = [],
  { sort = null, sortDir = 1 }
) => {
  if (sort === null) {
    return opportunities.reverse();
  }

  return sortBy(opportunities, (o) => {
    if (sort?.name === 'domainAuthority') {
      return parseInt(o?.domainAuthority, 10) * -sortDir;
    }
    if (sort?.name === 'timeLeft') {
      return parseInt(o?.deadlineUTC?.seconds, 10) * sortDir;
    }
    return parseInt(o?.date?.seconds, 10) * sortDir;
  });
};

export default sortOpportunities;
