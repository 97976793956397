import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { sanitizeUrl } from '@braintree/sanitize-url';
import { TrashIcon } from '@heroicons/react/solid';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import UrlDomain from 'components/company/UrlDomain';
import { useTracking } from 'modules/segment/TrackingProvider';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';
import formatTimestamp from 'utils/date/formatTimestamp';

import CompanySnapshotDeleteModal from './CompanySnapshotDeleteModal';
import CompanySnapshotEditModal from './CompanySnapshotEditModal/CompanySnapshotEditModal';

const CompanySnapshot = ({
  snapshot = {},
  showOwnerView = false,
  ops = {},
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const trackingService = useTracking();

  const onClick = () =>
    trackingService.track('pressPage_view', {
      action: 'milestone_open',
      milestone_title: snapshot.title,
      milestone_url: snapshot.url || null,
    });

  const onSubmit = (data = {}) => {
    const { date, emoji, url } = data;
    const timestamp = createTimestampFromDateObj(date);
    const sanitizedUrl = url ? sanitizeUrl(url) : null;
    ops.updateDoc(snapshot.uid, {
      ...data,
      url: sanitizedUrl,
      emoji,
      date: timestamp,
    });
    setIsEditModalOpen(false);
  };

  const onDelete = () => {
    ops.deleteDoc(snapshot.uid);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <li className="relative z-0 flex flex-col p-4 border rounded-lg text-sm border-gray-200 bg-white">
        <div className="w-16 h-16 flex justify-center items-center border border-gray-200 rounded-full">
          <span
            className="text-3xl"
            data-test-id={`company-snapshot-${snapshot.title}-emoji`}
          >
            {snapshot?.emoji || '💫'}
          </span>
        </div>
        <h3
          className="mt-3 text-lg font-medium leading-normal"
          data-test-id={`company-snapshot-${snapshot.title}-title`}
        >
          {snapshot.title}
        </h3>
        <p
          className="mt-2 text-sm text-gray-400"
          data-test-id={`company-snapshot-${snapshot.title}-date`}
        >
          {formatTimestamp(snapshot.date)}
        </p>

        <UrlDomain
          url={snapshot.url}
          onClick={onClick}
          testId={`company-snapshot-${snapshot.title}-url`}
        />

        {showOwnerView && (
          <div className="absolute top-2 right-2">
            <DotsMenu
              theme="medium"
              options={[
                {
                  label: 'Edit milestone',
                  onClick: () => setIsEditModalOpen(true),
                  testId: `company-snapshot-${snapshot.title}-menu-edit`,
                },
                {
                  label: 'Delete milestone',
                  icon: <TrashIcon className="w-4 h-4" />,
                  onClick: () => setIsDeleteModalOpen(true),
                  testId: `company-snapshot-${snapshot.title}-menu-delete`,
                },
              ]}
              testId={`company-snapshot-${snapshot.title}-menu`}
            />
          </div>
        )}
      </li>

      {showOwnerView && isEditModalOpen ? (
        <CompanySnapshotEditModal
          open={isEditModalOpen}
          snapshot={snapshot}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onSubmit}
        />
      ) : null}

      {showOwnerView && (
        <CompanySnapshotDeleteModal
          open={isDeleteModalOpen}
          title={snapshot.title}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

CompanySnapshot.propTypes = {
  snapshot: PropTypes.shape({
    emoji: PropTypes.string,
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  ops: PropTypes.shape({
    deleteDoc: PropTypes.func,
    updateDoc: PropTypes.func,
  }).isRequired,
};

export default CompanySnapshot;
