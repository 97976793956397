import PropTypes from 'prop-types';
import React, { useState } from 'react';

import noop from 'lodash/noop';

import Button from 'components/buttons/Button';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import InboxMessageResponseFormScheduleModal from './InboxMessageResponseFormScheduleModal';

const InboxMessageResponseFormScheduleButton = ({ onSchedule = noop }) => {
  const { claimsAdmin: isAdmin } = useUserData();
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      {showScheduleModal && (
        <InboxMessageResponseFormScheduleModal
          onSchedule={onSchedule}
          onCancel={() => setShowScheduleModal(false)}
        />
      )}
      <Button
        data-test-id="inboxMessage-responseFormSendPreviewButton"
        type="secondary"
        onClick={() => setShowScheduleModal(true)}
        className="ml-auto h-fit"
      >
        Schedule...
      </Button>
    </>
  );
};

InboxMessageResponseFormScheduleButton.propTypes = {
  onSchedule: PropTypes.func,
};

export default InboxMessageResponseFormScheduleButton;
