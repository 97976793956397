import React, { useState } from 'react';

import { useMatch } from 'react-router-dom';

import Button from 'components/buttons/Button';
import { ADMIN_PATH } from 'constants/paths';
import useEnvironment from 'contexts/EnvironmentContext/hooks/useEnvironment';

import AdminUnclutterProdEnvironmentModal from './AdminUnclutterProdEnvironmentModal';

const AdminUnclutterProdEnvironment = () => {
  const { isProduction } = useEnvironment();
  const isAdminPath = useMatch(ADMIN_PATH);
  const [isOpen, setIsOpen] = useState(false);

  if (!isProduction || !isAdminPath) {
    return null;
  }

  return (
    <>
      <Button type="danger" onClick={() => setIsOpen(true)}>
        Unclutter Production Environment
      </Button>
      <AdminUnclutterProdEnvironmentModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default AdminUnclutterProdEnvironment;
