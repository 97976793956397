import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

import Button from 'components/buttons/Button';
import OpportunityBody from 'components/opportunities/opportunity/OpportunityBody';
import OpportunityQuery from 'components/opportunities/opportunity/OpportunityQuery';

import AdminOpportunityDeadline from './AdminOpportunityDeadline';

const AdminOpportunityWrapper = ({
  opportunity = {},
  Tags = null,
  Companies = null,
  onToggleDelete = noop,
}) => {
  const { deadlineUTC, query, deleted } = opportunity;

  return (
    <tr className="p-4 even:bg-gray-50">
      <td
        className={classNames('p-4 align-top', {
          'line-through opacity-50': deleted,
        })}
      >
        <div className="flex flex-col justify-start items-start max-w-prose">
          <div className="space-x-2 flex items-center justify-start w-full">
            <AdminOpportunityDeadline deadlineUTC={deadlineUTC} />
            {/* Any additional tags go here */}
            {Tags}
          </div>
          <OpportunityBody {...opportunity} />
          <OpportunityQuery
            opportunityId={opportunity.uid}
            query={query}
            collapsed
            canCollapse
          />
        </div>
      </td>
      <td
        className={classNames('p-4 min-w-[300px] align-top', {
          'opacity-50': deleted,
        })}
      >
        {Companies}
      </td>
      <td className="p-4">
        <Button onClick={onToggleDelete}>
          {deleted ? 'Recover' : 'Delete'}
        </Button>
      </td>
    </tr>
  );
};

AdminOpportunityWrapper.propTypes = {
  opportunity: PropTypes.object,
  Tags: PropTypes.node,
  Companies: PropTypes.node,
  onToggleDelete: PropTypes.func,
};

export default AdminOpportunityWrapper;
