import PropTypes from 'prop-types';
import React from 'react';

import { useFormContext } from 'react-hook-form';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const ContentElementHeadline = ({ index = 0, placeholder = '' }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const name = 'headline';

  return (
    <div className="w-full">
      <InputFieldText
        id={`${name}${index}`}
        {...register(name)}
        errors={errors}
        placeholder={placeholder}
        testId="company-content-inputHeadline"
      >
        <InputFieldText.Label>Headline</InputFieldText.Label>
      </InputFieldText>
    </div>
  );
};

ContentElementHeadline.propTypes = {
  index: PropTypes.number,
  placeholder: PropTypes.string,
};

export default ContentElementHeadline;
