import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const CompanyInsightQuestion = ({
  question,
  hasAnswer = false,
  testId = '',
}) => (
  <h3
    className={classNames(
      'mt-2 text-xl font-medium leading-normal line-clamp-2',
      {
        'text-gray-700': hasAnswer,
        'text-gray-300': !hasAnswer,
      }
    )}
    data-test-id={testId}
  >
    {question}
  </h3>
);

CompanyInsightQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  hasAnswer: PropTypes.bool,
  testId: PropTypes.string,
};

export default CompanyInsightQuestion;
