import { ADMIN_CREATE_CUSTOM_THREAD_PATH as path } from 'constants/paths';

import AdminCreateCustomThreadPage from './AdminCreateCustomThreadPage';

const AdminCreateCustomThreadRoute = {
  path,
  authRequired: true,
  claimsRequired: { admin: true },
  component: AdminCreateCustomThreadPage,
};

export default AdminCreateCustomThreadRoute;
