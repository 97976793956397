import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { sanitizeUrl } from '@braintree/sanitize-url';
import { TrashIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import UrlDomain from 'components/company/UrlDomain';
import { useTracking } from 'modules/segment/TrackingProvider';
import DefaultArticleImage from 'static/company-article.jpg';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';
import formatTimestamp from 'utils/date/formatTimestamp';

import CompanyArticleDeleteModal from './CompanyArticleDeleteModal';
import CompanyArticleEditModal from './CompanyArticleEditModal';

const CompanyArticle = ({
  article = {},
  showOwnerView = false,
  ops = {},
  companyId = '',
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onSubmit = (data = {}) => {
    const timestamp = createTimestampFromDateObj(data.date);
    const sanitizedUrl = data.url ? sanitizeUrl(data.url) : null;
    ops.updateDoc(article.uid, { ...data, url: sanitizedUrl, date: timestamp });
    setIsEditModalOpen(false);
  };

  const onDelete = () => {
    ops.deleteDoc(article.uid);
    setIsDeleteModalOpen(false);
  };

  const trackingService = useTracking();

  const onClick = () => {
    trackingService.track('pressPage_view', {
      action: 'article_open',
      article_title: article.title,
      article_url: article.url || null,
    });
  };

  return (
    <>
      <li className="relative z-0 flex flex-col border rounded-lg text-sm border-gray-200 bg-white">
        <div className="flex flex-col h-full overflow-hidden">
          <Link to={article.url} onClick={onClick} target="_blank">
            <div className="relative bg-black flex-none h-20 object-center overflow-hidden rounded-t">
              <UniversalImage
                alt={`Preview image for article ${article.title}`}
                image={article.image}
                className="opacity-90"
                testId={`company-article-${article.title}-image`}
                placeholderImage={DefaultArticleImage}
              />
            </div>
          </Link>
          <div className="flex-shrink px-6 py-4">
            <Link to={article.url} onClick={onClick} target="_blank">
              <h4
                className="!inline text-lg font-medium overflow-hidden leading-normal line-clamp-3 hover:underline"
                data-test-id={`company-article-${article.title}-title`}
              >
                {article.title}
              </h4>
            </Link>
            <p className="mt-2 text-sm text-gray-400">
              <span data-test-id={`company-article-${article.title}-author`}>
                {article.author ? `${article.author.substr(0, 16)} • ` : null}
              </span>
              <span data-test-id={`company-article-${article.title}-date`}>
                {formatTimestamp(article.date)}
              </span>
            </p>
            <UrlDomain url={article.url} />
          </div>
        </div>

        {showOwnerView && (
          <div className="absolute top-2 right-2">
            <DotsMenu
              theme="light"
              options={[
                {
                  label: 'Edit article',
                  onClick: () => setIsEditModalOpen(true),
                  testId: `company-article-${article.title}-menu-edit`,
                },
                {
                  label: 'Delete article',
                  icon: <TrashIcon className="w-4 h-4" />,
                  onClick: () => setIsDeleteModalOpen(true),
                  testId: `company-article-${article.title}-menu-delete`,
                },
              ]}
              testId={`company-article-${article.title}-menu`}
            />
          </div>
        )}
      </li>

      {showOwnerView && isEditModalOpen ? (
        <CompanyArticleEditModal
          open={isEditModalOpen}
          article={article}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onSubmit}
          uploadFolder={`companies/${companyId}/articles`}
        />
      ) : null}

      <CompanyArticleDeleteModal
        open={isDeleteModalOpen}
        title={article.title}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={onDelete}
      />
    </>
  );
};

CompanyArticle.propTypes = {
  article: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.object,
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
    author: PropTypes.string,
    publication: PropTypes.string,
  }),
  showOwnerView: PropTypes.bool.isRequired,
  ops: PropTypes.shape({
    deleteDoc: PropTypes.func,
    updateDoc: PropTypes.func,
  }).isRequired,
  companyId: PropTypes.string.isRequired,
};

export default CompanyArticle;
