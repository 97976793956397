const punctuation = ['.', ',', ':', '!', '?'];

const stripPunctuation = (text) => {
  if (!text) {
    return text;
  }
  let strippedText = text;
  punctuation.forEach((character) => {
    if (strippedText.endsWith(character)) {
      strippedText = strippedText.substring(0, text.length - 1);
    }
  });
  return strippedText;
};

export default stripPunctuation;
