import { collection } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import { CUSTOMERS_COLLECTION } from 'constants/firebasePaths';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import isActiveSubscription from './helpers/isActiveSubscription';

const useActiveSubscription = (ownerId = '') => {
  const { uid } = useUserData();

  const firestore = useFirestore();
  const subscriptionsRef = collection(
    firestore,
    CUSTOMERS_COLLECTION,
    ownerId || uid,
    'subscriptions'
  );

  const { data } = useFirestoreCollectionData(subscriptionsRef, {
    initialData: null,
  });

  if (!data?.length) {
    return null;
  }

  const activeSubscription = data.find(isActiveSubscription);

  return activeSubscription;
};

export default useActiveSubscription;
