import { useEffect } from 'react';

import { doc, updateDoc } from 'firebase/firestore';
import noop from 'lodash/noop';
import { useFirestore } from 'reactfire';

import { COMPANIES_COLLECTION } from 'constants/firebasePaths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useActiveSubscription from 'hooks/useActiveSubscription';

// verifies that a payment has indeed be collected from a user and sets the company's `signupStatus` to 2 as a result
const useCheckPayment = (onPaymentCheckComplete = noop) => {
  const { uid } = useUserData();
  const { companyData } = useCompanyData();

  const firestore = useFirestore();
  const hasActiveSubscription = useActiveSubscription();

  useEffect(() => {
    (async () => {
      if (!hasActiveSubscription) {
        return;
      }

      const companiesDoc = doc(
        firestore,
        COMPANIES_COLLECTION,
        companyData.uid
      );
      await updateDoc(companiesDoc, { signupStatus: 2 });

      onPaymentCheckComplete();
    })();
  }, [
    companyData.slug,
    companyData.uid,
    firestore,
    hasActiveSubscription,
    onPaymentCheckComplete,
    uid,
  ]);
};

export default useCheckPayment;
