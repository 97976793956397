import FirebaseAnalyticsAdapter from './FirebaseAnalyticsAdapter';
import SegmentAdapter from './segmentAdapter';

export default function createAnalyticsService({
  serviceName,
  analytics,
  key,
}) {
  switch (serviceName) {
    case 'Segment':
      return new SegmentAdapter(key);
    case 'FirebaseAnalytics':
      return new FirebaseAnalyticsAdapter(analytics);
    default:
      throw new Error(`Unknown analytics service: ${serviceName}`);
  }
}
