import PropTypes from 'prop-types';
import React from 'react';

import { ContentTypes } from 'constants/contentTypes';
import formatTimestamp from 'utils/date/formatTimestamp';

import CompanyContentElementTableRow from './CompanyContentElementTableRow';

const CompanyContentElementTableIPO = ({ content = {} }) => {
  const { companyName, embargoDate, stockExchanges, other } = content;

  return (
    <>
      <CompanyContentElementTableRow
        label="Company Name"
        contentType={ContentTypes.IPO}
        content={companyName}
      />
      <CompanyContentElementTableRow
        label="Date"
        contentType={ContentTypes.IPO}
        content={formatTimestamp(embargoDate, true)}
      />
      <CompanyContentElementTableRow
        label="Stock Exchanges"
        contentType={ContentTypes.IPO}
        content={stockExchanges}
        type="list"
      />
      {other ? (
        <CompanyContentElementTableRow
          label="More info"
          content={other}
          contentType={ContentTypes.IPO}
        />
      ) : null}
    </>
  );
};

CompanyContentElementTableIPO.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CompanyContentElementTableIPO;
