import PropTypes from 'prop-types';
import React from 'react';

import { TrashIcon } from '@heroicons/react/solid';

import Button from 'components/buttons/Button';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

const SweetheartListJournalistDelete = ({ journalistId = '' }) => {
  const { deleteJournalist } = useSweetheartListContext();

  const handleDeleteJournalist = async (e) => {
    e.stopPropagation();
    await deleteJournalist(journalistId);
  };

  return (
    <Button type="danger" onClick={handleDeleteJournalist}>
      <TrashIcon className="w-4 h-4" />
    </Button>
  );
};

SweetheartListJournalistDelete.propTypes = {
  journalistId: PropTypes.string,
};

export default SweetheartListJournalistDelete;
