import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

// Label for form fields, usually used in conjunction with components in `components/inputFields`
// TODO: can maybe be combined with the `Error` component since input fields usually have both, a label and an error message
// sc825 - https://app.shortcut.com/mvpr/story/825/refactor-modal-and-form-components
const Label = ({ htmlFor = '', children = null, className = '' }) => (
  <label
    htmlFor={htmlFor}
    className={classNames(
      'block text-sm font-medium text-gray-700 cursor-pointer',
      className
    )}
  >
    {children}
  </label>
);

Label.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Label;
