import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

const useGetInboxMessagesError = ({ thread = {}, messagesByDate = [] }) => {
  const { messageId, threadId } = useParams();

  const noValidMessageInThread = useMemo(
    () =>
      threadId &&
      messageId &&
      !messagesByDate.filter((message) => message.uid === messageId).length,
    [threadId, messageId, messagesByDate]
  );

  if (threadId && !thread?.uid) {
    // If we have a threadId but no thread, we show the no thread message
    return "Whoops, the thread you're searching for doesn't seem to exist.";
  }

  if (noValidMessageInThread) {
    // If we have a threadId and messageId,
    // but the messageId doesn't match the message in the thread,
    // we show the not found message
    return "Whoops, seems like the message you were looking for doesn't exist in this thread!";
  }

  return null;
};

export default useGetInboxMessagesError;
