export const USERS_COLLECTION = 'users';
export const COMPANIES_COLLECTION = 'companies';
export const COMPANIES_STATS_COLLECTION = 'stats';
export const COMPANIES_ACHIEVEMENTS_COLLECTION = 'achievements';
export const COMPANIES_VIDEOS_COLLECTION = 'videos';
export const COMPANIES_ARTICLES_COLLECTION = 'articles';
export const COMPANIES_INSIGHTS_COLLECTION = 'insights';
export const COMPANIES_COMMENTS_COLLECTION = 'comments';
export const COMPANIES_CONTENT_COLLECTION = 'content';
export const COMPANIES_DOWNLOADS_COLLECTION = 'downloads';
export const COMPANIES_FOUNDERS_COLLECTION = 'founders';
export const COMPANIES_SEARCH_COLLECTION = 'companies_search';
export const COMPANIES_OPPORTUNITIES_COLLECTION = 'companies_opportunities';
export const COMPANIES_OPPORTUNITIES_DECLINED_COLLECTION = 'declined';
export const RESPONSE_SOURCE_EMAILS_COLLECTION = 'response_source_emails';
export const CUSTOM_OPPORTUNITIES_COLLECTION = 'opportunities';
export const PARSED_OPPORTUNITIES_COLLECTION = 'opportunities_parsed';
export const CLASSIFIED_OPPORTUNITIES_COLLECTION = 'opportunities_classified';

export const OPPORTUNITIES_NOTIFICATIONS = 'opportunities_notifications';
export const OPPORTUNITIES_NOTIFICATIONS_COMPANIES_NOTIFIED =
  'companiesNotified';
export const OPPORTUNITIES_NOTIFICATIONS_COMPANIES_TO_NOTIFY =
  'companiesToNotify';
export const OPPORTUNITIES_NOTIFICATIONS_COMPANIES_VISITED = 'companiesVisited';

export const COMPANIES_SWEETHEARTS_COLLECTION = 'companies_sweethearts';

export const INBOXES_COLLECTION = 'inboxes';
export const INBOXES_THREADS_COLLECTION = 'threads';
export const INBOXES_THREADS_MESSAGES_COLLECTION = 'messages';
export const INBOXES_ORPHAN_MESSAGES_DOC = 'orphanMessages';

export const PRESS_RELEASES_COLLECTION = 'press_releases';

export const CLOUD_FUNCTIONS_COLLECTION = 'cloud_functions';
export const CLOUD_FUNCTIONS_COLLECTION_MESSAGES_DOC =
  'onApprovedMessagesSendToJournalists';
// stripe
export const CUSTOMERS_COLLECTION = 'customers';
export const CUSTOMERS_PAYMENTS_COLLECTION = 'payments';
export const CUSTOMERS_SUBSCRIPTIONS_COLLECTION = 'subscriptions';
export const CHECKOUT_SESSION_COLLECTION = 'checkout_sessions';

export const PRODUCTS_COLLECTION = 'products';
export const PRODUCTS_PRICES_COLLECTION = 'prices';

export const SUCCESS_FEES_COLLECTION = 'success_fees';

export const INVOICES_COLLECTION = 'invoices';
