import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { sanitizeUrl } from '@braintree/sanitize-url';
import sortBy from 'lodash/sortBy';

import CompanySectionPlaceholder from 'components/company/CompanySectionPlaceholder';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';
import trackIntercomEvent from 'modules/intercom/trackIntercomEvent';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';

import CompanySnapshot from './CompanySnapshot';
import CompanySnapshotEditModal from './CompanySnapshotEditModal/CompanySnapshotEditModal';

const NO_SNAPSHOTS_DESCRIPTION =
  "Add your first milestone, show journalists the story of your company's growth";
const SNAPSHOTS_DESCRIPTION =
  "Add a milestone, show journalists the story of your company's growth";

const CompanySnapshots = ({ crud, showOwnerView, sectionRef }) => {
  const [, snapshots, ops] = crud;

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const closeModal = () => setIsAddModalOpen(false);

  const snapshotsSorted = sortBy(snapshots, 'date').reverse();
  const isEmpty = snapshots.length < 1;

  const onSubmit = (data = {}) => {
    trackIntercomEvent('pressPage-update');
    trackIntercomEvent('pressPage-update-milestones');
    const { date, emoji, title, url } = data;
    const sanitizedUrl = url ? sanitizeUrl(url) : null;
    ops.createDoc({
      date: createTimestampFromDateObj(date),
      title,
      url: sanitizedUrl || null,
      emoji,
    });
    closeModal();
  };

  // If there are no snapshots yet, hide this section for journalists
  if (!showOwnerView && isEmpty) {
    return null;
  }

  return (
    <div data-test-id="company-snapshots" id="milestones" ref={sectionRef}>
      <SectionHeaderWrapper>
        <SectionHeadline light testId="company-snapshots-headline">
          Milestones
        </SectionHeadline>
      </SectionHeaderWrapper>
      <div className="w-full mt-4">
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-5 gap-6">
          {snapshotsSorted.map((snapshot) => (
            <CompanySnapshot
              key={snapshot.uid}
              snapshot={snapshot}
              ops={ops}
              showOwnerView={showOwnerView}
            />
          ))}
          {showOwnerView && (
            <CompanySectionPlaceholder
              title="Add a milestone"
              description={
                isEmpty ? NO_SNAPSHOTS_DESCRIPTION : SNAPSHOTS_DESCRIPTION
              }
              onClick={() => setIsAddModalOpen(true)}
              testId="company-snapshots-add"
            />
          )}
        </ul>
      </div>

      {showOwnerView && isAddModalOpen ? (
        <CompanySnapshotEditModal
          open={isAddModalOpen}
          onClose={() => closeModal()}
          onSubmit={onSubmit}
        />
      ) : null}
    </div>
  );
};

CompanySnapshots.propTypes = {
  crud: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanySnapshots;
