import useAPIRequest from 'hooks/useAPIRequest';

const useInviteByToken = (token) => {
  const { data: invite, loading } = useAPIRequest({
    endpoint: `/invites/${token}`,
    service: 'UCD',
  });

  return { loading, invite };
};

export default useInviteByToken;
