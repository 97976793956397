import PropTypes from 'prop-types';
import React from 'react';

import CompanyImage from 'components/company/CompanyImage';

const AdminMessagesCompaniesCompany = ({ name = '', logo = {} }) => (
  <div className="flex items-center gap-2 text-left">
    <CompanyImage image={logo} alt={`${name} logo`} />
    {name}
  </div>
);

AdminMessagesCompaniesCompany.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.object,
};

export default AdminMessagesCompaniesCompany;
