import PropTypes from 'prop-types';
import React from 'react';

import { OfficeBuildingIcon } from '@heroicons/react/solid';

const CompanyImageV2 = ({
  url = '',
  size = 12,
  alt = 'Logo of company',
  children = null,
}) => {
  const className = `max-h-${size} w-${size} rounded-lg self-center`;

  return (
    <div
      className={`h-${size} w-${size} text-gray-200 ring-1 ring-gray-900/10 rounded-lg bg-white flex relative`}
    >
      {url ? (
        <img alt={alt} src={url} className={className} />
      ) : (
        <OfficeBuildingIcon className={className} />
      )}
      {children}
    </div>
  );
};

CompanyImageV2.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.number,
  children: PropTypes.node,
};
export default CompanyImageV2;
