import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const InboxMessagesWrapper = ({
  children = null,
  className = '',
  testId = '',
}) => (
  <div
    className={classNames(
      'rounded-md border shadow-sm bg-white max-w-4xl flex',
      className
    )}
    data-test-id={testId}
  >
    {children}
  </div>
);

InboxMessagesWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  testId: PropTypes.string,
};

export default InboxMessagesWrapper;
