import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCredential,
  signInWithEmailAndPassword,
  linkWithPopup,
} from 'firebase/auth';
import { useAuth } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import useUserData from 'contexts/UserContext/hooks/useUserData';

// sign in a user with email/password or via Google
// when signup is true, don't try to log in credentials on error
const useSignIn = (signup = false) => {
  const auth = useAuth();
  const { showError } = useNotifications();
  const userContext = useUserData();

  const signInWithEmail = async (formValues, e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const res = await signInWithEmailAndPassword(
        auth,
        formValues.email,
        formValues.password
      );
      return res;
    } catch (err) {
      let message;
      if (err.code === 'auth/user-not-found') {
        message =
          'There is no user record corresponding to this identifier. The user may have been deleted.';
      }
      showError({
        title: 'There was an error with the credentials',
        message: message || err.message,
      });
      userContext.setIsAuthenticating(false);
    }
    return false;
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    let res = null;
    try {
      if (userContext.isAnonymous) {
        res = await linkWithPopup(auth.currentUser, provider);
      } else {
        res = await signInWithPopup(auth, provider);
      }
    } catch (err) {
      // user already exists, override session with logged in user
      if (err.code === 'auth/credential-already-in-use') {
        if (signup) {
          showError({
            title: 'Seems like the user already exists',
            message: err.message,
          });
        } else {
          const credential = GoogleAuthProvider.credentialFromError(err);
          res = await signInWithCredential(auth, credential);
        }
      }
    }

    return res;
  };

  return {
    signInWithEmail,
    signInWithGoogle,
  };
};

export default useSignIn;
