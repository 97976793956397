// filters opportunities by tier using the filter's domainAuthority
const filterOpportunities = (opportunities = [], filter = []) =>
  opportunities.filter((o) => {
    let match = false;
    filter.forEach((f) => {
      if (o.domainAuthority <= f.max && o.domainAuthority >= f.min) {
        match = true;
      }
    });
    return match;
  });

export default filterOpportunities;
