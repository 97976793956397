import xor from 'lodash/xor';

export const initialState = {
  showSuggestions: false,
  selectedTopics: [],
  selectedStages: [],
  selectedContentTypes: [],
  minFundingIndex: 0,
  maxFundingIndex: 1,
  currentPage: 1,
  searchQuery: '',
  searchString: '',
  mobileFiltersOpen: false,
};

export const actions = {
  SHOW_SUGGESTIONS: 'SHOW_SUGGESTIONS',
  HIDE_SUGGESTIONS: 'HIDE_SUGGESTIONS',
  SET_TOPICS: 'SET_TOPICS',
  TOGGLE_STAGE: 'TOGGLE_STAGE',
  TOGGLE_CONTENT_TYPE: 'TOGGLE_CONTENT_TYPE',
  SET_MIN_FUNDING_INDEX: 'SET_MIN_FUNDING_INDEX',
  SET_MAX_FUNDING_INDEX: 'SET_MAX_FUNDING_INDEX',
  SET_FUNDING_INDEX_RANGE: 'SET_FUNDING_INDEX_RANGE',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_SEARCH_STRING: 'SET_SEARCH_STRING',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  SHOW_MOBILE_FILTERS: 'SHOW_MOBILE_FILTERS',
  HIDE_MOBILE_FILTERS: 'HIDE_MOBILE_FILTERS',
};

export const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actions.SHOW_SUGGESTIONS:
      return {
        ...state,
        showSuggestions: true,
      };

    case actions.HIDE_SUGGESTIONS:
      return {
        ...state,
        showSuggestions: false,
      };

    case actions.TOGGLE_STAGE:
      return {
        ...state,
        currentPage: 1,
        selectedStages: xor(state.selectedStages, [action.payload]),
      };

    case actions.TOGGLE_CONTENT_TYPE:
      return {
        ...state,
        currentPage: 1,
        selectedContentTypes: xor(state.selectedContentTypes, [action.payload]),
      };

    case actions.SET_TOPICS:
      return {
        ...state,
        currentPage: 1,
        selectedTopics: action.payload,
      };

    case actions.SET_MIN_FUNDING_INDEX:
      return {
        ...state,
        currentPage: 1,
        minFundingIndex: action.payload,
      };

    case actions.SET_MAX_FUNDING_INDEX:
      return {
        ...state,
        currentPage: 1,
        maxFundingIndex: action.payload,
      };

    case actions.SET_FUNDING_INDEX_RANGE:
      // eslint-disable-next-line no-case-declarations
      const [minFundingIndex, maxFundingIndex] = action.payload;
      return {
        ...state,
        currentPage: 1,
        maxFundingIndex,
        minFundingIndex,
      };

    case actions.SET_SEARCH_STRING:
      return {
        ...state,
        currentPage: 1,
        showSuggestions: true,
        searchString: action.payload,
      };

    case actions.SET_SEARCH_QUERY:
      return {
        ...state,
        currentPage: 1,
        showSuggestions: false,
        searchQuery: action.payload,
      };

    case actions.SHOW_MOBILE_FILTERS:
      return {
        ...state,
        mobileFiltersOpen: true,
      };

    case actions.HIDE_MOBILE_FILTERS:
      return {
        ...state,
        mobileFiltersOpen: false,
      };

    case actions.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    default:
      return state;
  }
};
