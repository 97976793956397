import { ADMIN_COMPANIES_PATH as path } from 'constants/paths';

import Companies from './AdminCompaniesPage';

const AdminCompaniesRoute = {
  path,
  authRequired: true,
  claimsRequired: { admin: true },
  component: Companies,
};

export default AdminCompaniesRoute;
