// Creates a function that returns the name of a compound component,
// or throws an error if the child is not a compound component.

const createGetCompoundComponentName =
  (compoundComponents = {}) =>
  (child = {}) => {
    if (!child) {
      return null;
    }
    let compoundComponentName;

    Object.entries(compoundComponents).some(([componentName, Component]) => {
      if (child.type === Component) {
        compoundComponentName = componentName;
        return true;
      }
      return false;
    });

    if (compoundComponentName) {
      return compoundComponentName;
    }

    throw new Error(
      'Invalid child type, please use one of the InputFieldText components'
    );
  };

export default createGetCompoundComponentName;
