import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import fundingRoundTypes from 'constants/fundingRoundTypes';

const ContentElementFundraiseFundingRound = () => {
  const name = 'fundingRound';
  const { control } = useFormContext();

  return (
    <div className="w-full space-y-2">
      <Controller
        render={({ field: { onChange, value } }) => (
          <InputFieldDropdown
            value={value || ''}
            label="Stage of funding round*"
            onChange={onChange}
            options={fundingRoundTypes}
            buttonClassName="min-w-full "
            optionsClassName="min-w-full"
            testId="company-content-fundraise-inputFundingRound"
          />
        )}
        control={control}
        name={name}
      />
    </div>
  );
};

export default ContentElementFundraiseFundingRound;
