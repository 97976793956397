import React, { useEffect, useState } from 'react';

import { doc, getDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { COMPANIES_COLLECTION } from 'constants/firebasePaths';

const useSuccessFeesWithCompanies = (successFees = []) => {
  const firestore = useFirestore();
  const uniqueCompanyIds = React.useMemo(() => {
    const companyIds = successFees.map((successFee) => successFee.companyId);
    return [...new Set(companyIds)];
  }, [successFees]);

  const [companies, setCompanies] = useState({});

  useEffect(() => {
    const getCompanies = async () => {
      const companiesObject = {};
      const companiesData = await Promise.all(
        uniqueCompanyIds.map(async (companyId) => {
          const companyRef = doc(firestore, COMPANIES_COLLECTION, companyId);
          const companyDoc = await getDoc(companyRef);

          return { data: companyDoc.data(), id: companyDoc.id };
        })
      );

      companiesData.forEach((company) => {
        companiesObject[company.id] = company.data;
      });
      setCompanies(companiesObject);
    };
    getCompanies();
  }, [firestore, uniqueCompanyIds]);

  return successFees.map((successFee) => {
    const company = companies[successFee.companyId];
    return { ...successFee, company };
  });
};

export default useSuccessFeesWithCompanies;
