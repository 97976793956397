import { useEffect, useMemo, useRef } from 'react';

import { isEqual } from 'lodash';
import noop from 'lodash/noop';

import {
  generateAnyRanges,
  generateFutureRanges,
  generatePastRanges,
  getNumDaysInMonth,
  isPresentDay,
  isPresentMonth,
  isPresentYear,
  updateInvalidSelections,
} from './helpers/dateUtils';

const DEFAULT_NUM_FUTURE_YEARS = 3;
const DEFAULT_NUM_PAST_YEARS = 3;

/**
 * @param {Object} params
 * @param {Object} params.config
 * @param {Number} params.config.numPastYears
 * @param {Number} params.config.numFutureYears
 * @param {"any"|"future"|"past"} params.config.type="any"
 * @param {Object} params.selection
 * @param {Number} params.selection.selectedDay
 * @param {String} params.selection.selectedMonth
 * @param {Number} params.selection.selectedYear
 * @param {String} params.selection.selectedHour
 * @param {Function} params.updateSelections
 * @returns  {Object} validOptionRanges
 * @returns  {Array<Number>} validOptionRanges.days
 * @returns  {Array<Number>} validOptionRanges.years
 * @returns  {Array<String>} validOptionRanges.months
 * @returns  {Array<String>} validOptionRanges.hours
 */
const useDateTimeSelector = ({
  config = {},
  selection = {},
  updateSelections = noop,
}) => {
  const previousSelection = useRef(selection);
  const {
    numFutureYears = DEFAULT_NUM_FUTURE_YEARS,
    numPastYears = DEFAULT_NUM_PAST_YEARS,
    type = 'any',
  } = config;
  const { selectedMonth, selectedYear, selectedDay } = selection;

  const validOptionRanges = useMemo(() => {
    const rangeConfig = {
      isPresentDayBool: isPresentDay(selectedDay),
      isPresentMonthBool: isPresentMonth(selectedMonth),
      isPresentYearBool: isPresentYear(selectedYear),
      numDaysInMonth: getNumDaysInMonth(selectedYear, selectedMonth),
      numFutureYears,
      numPastYears,
    };

    if (type === 'past') {
      return generatePastRanges(rangeConfig);
    }

    if (type === 'future') {
      return generateFutureRanges(rangeConfig);
    }

    return generateAnyRanges(rangeConfig);
  }, [
    numFutureYears,
    numPastYears,
    selectedDay,
    selectedMonth,
    selectedYear,
    type,
  ]);

  useEffect(() => {
    // We don't want to update the selection on the first render,
    // as it may contain an invalid date depending on the config
    // eg: if the config is set to only allow future dates,
    // and the initial date is set to a past date, this will cause an infinite loop.
    if (isEqual(previousSelection.current, selection)) {
      return;
    }

    previousSelection.current = selection;
    updateInvalidSelections({
      selection,
      updateSelections,
      validOptionRanges,
    });
  }, [selection, updateSelections, validOptionRanges]);

  return validOptionRanges;
};

export default useDateTimeSelector;
