import { useEffect, useRef } from 'react';

const MARGIN = 20;

const useScrollOnActive = (isActive = false) => {
  const animationRef = useRef(null);
  const parentRef = useRef(null);
  const childRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      if (parentRef.current) {
        const { scrollWidth, offsetWidth } = parentRef.current;

        // if the scroll width is wider than the total width then we start scrolling
        if (scrollWidth > offsetWidth) {
          animationRef.current = childRef.current.animate(
            [
              { transform: 'translateX(0px)' },
              {
                transform: `translateX(${
                  offsetWidth - scrollWidth - MARGIN
                }px)`,
                offset: 0.5,
              },
              {
                transform: `translateX(${
                  offsetWidth - scrollWidth - MARGIN
                }px)`,
                offset: 0.9,
              },
            ],
            {
              delay: 500,
              duration: 2000,
              endDelay: 1000,
            }
          );
        }
      }
    } else if (animationRef.current && !isActive) {
      // else if we are not active and we have an animation running then we stop it
      animationRef.current.cancel();
    }
  }, [isActive, parentRef, childRef]);

  return [parentRef, childRef];
};

export default useScrollOnActive;
