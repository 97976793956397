import { ADMIN_JOURNALISTS_PATH as path } from 'constants/paths';

import AdminJournalistsPage from './AdminJournalistsPage';

const AdminJournalistsRoute = {
  path,
  authRequired: true,
  claimsRequired: { admin: true },
  component: AdminJournalistsPage,
};

export default AdminJournalistsRoute;
