import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const OnboardingInputsContainer = ({ children = null, className = '' }) => (
  <div
    className={classNames(
      'flex flex-col items-center max-w-lg mt-12 mb-8 w-full',
      className
    )}
  >
    {children}
  </div>
);

OnboardingInputsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default OnboardingInputsContainer;
