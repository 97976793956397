import React, { Suspense } from 'react';

import { useParams, Navigate } from 'react-router-dom';

import { tabNames, tabs } from 'constants/inboxTabs';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import Inbox from './Inbox';
import InboxAutoImpersonate from './InboxAutoImpersonate';

const InboxPage = () => {
  const company = useCompanyData();
  const { claimsAdmin: isAdmin } = useUserData();
  const { tab } = useParams();

  if (!tab || !tabs.find((t) => t.field === tab)) {
    return <Navigate to={`/inbox/${tabNames.ACTIVE}`} replace />;
  }

  if (isAdmin) {
    return (
      <Suspense>
        <InboxAutoImpersonate>
          <Inbox companyUid={company.companyData.uid} />
        </InboxAutoImpersonate>
      </Suspense>
    );
  }

  return (
    <Suspense>
      <Inbox companyUid={company.companyData.uid} />;
    </Suspense>
  );
};

export default InboxPage;
