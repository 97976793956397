import PropTypes from 'prop-types';
import React from 'react';

import { LogoutIcon } from '@heroicons/react/outline';
import noop from 'lodash/noop';
import { generatePath, Link } from 'react-router-dom';

import { DEFAULT_SETTINGS_PATH, SETTINGS_PATH } from 'constants/paths';
import useHandleLogout from 'hooks/useHandleLogout';

import UserAvatar from '../UserAvatar';
import useUserName from '../hooks/useUserName';

// Link to the settings and signout link on mobile showing up at the bottom of the mobile nav overlay
const MobileNavAccountLink = ({ toggleMobileMenu = noop }) => {
  const handleLogout = useHandleLogout();
  const userName = useUserName();

  return (
    <li className="mt-auto flex justify-between border-t-2 border-teal-600 px-4 py-2">
      <Link
        to={generatePath(SETTINGS_PATH, { '*': DEFAULT_SETTINGS_PATH })}
        className="flex items-center w-auto"
        onClick={() => toggleMobileMenu(false)}
      >
        <UserAvatar />
        <span className="flex flex-col pl-4">
          <p className="text-base">{userName}</p>
          <p className="text-sm">View Settings</p>
        </span>
      </Link>
      <div className="flex items-center">
        <Link
          to="#"
          className="text-sm font-medium border shadow-sm flex items-center px-2 py-1 rounded-md focus:outline-none focus:ring focus:border-gray-100"
          role="menuitem"
          onClick={handleLogout}
        >
          <LogoutIcon className="w-6 h-6 pr-2" />
          Sign out
        </Link>
      </div>
    </li>
  );
};

MobileNavAccountLink.propTypes = {
  toggleMobileMenu: PropTypes.func.isRequired,
};
export default MobileNavAccountLink;
