import React from 'react';

import SwiperCore, { Navigation } from 'swiper';

import 'swiper/swiper-bundle.min.css';
import InboxNavbarTabs from './InboxNavbarTabs';

SwiperCore.use([Navigation]);

const InboxNavbar = () => (
  <div className="sticky top-16 z-10 -mx-6 -mt-8 mb-8">
    <div className="w-full flex bg-white shadow-sm text-gray-300 border-b border-gray-200">
      <InboxNavbarTabs />
    </div>
  </div>
);

export default InboxNavbar;
