import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

// Opportunity meta text (used for journalists and publications)
const OpportunityMeta = ({ text = '', className }) => (
  <h4
    className={classNames(
      'font-medium text-sm text-gray-700 whitespace-nowrap truncate',
      className
    )}
  >
    {text}
  </h4>
);

OpportunityMeta.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default OpportunityMeta;
