import { useEffect, useMemo } from 'react';

import noop from 'lodash/noop';
import {
  useNavigate,
  matchPath,
  useLocation,
  generatePath,
} from 'react-router-dom';

import { ONBOARDING_PATH } from 'constants/paths';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import useOnboardingStore from '../stores/onboardingStores';

// iterates over the steps and returns an array of all the next steps (+1)
// of steps that pass the validate predicate
const getAvailableStepsNames = (state = {}, steps = []) => {
  const availableStepsNames = [steps[0].name];

  steps.forEach((step, index) => {
    if (step?.validate?.(state) && availableStepsNames.includes(step.name)) {
      if (steps[index + 1]) {
        availableStepsNames.push(steps[index + 1].name);
      }
      // for the submit step, push the "submit" string
      if (step.submit) {
        availableStepsNames.push('submit');
      }
    }
  });
  return availableStepsNames;
};

// Custom hook that returns available steps for a given form,
// and redirects to the last valid step if the user tries to go to an invalid step
const useStepValidation = (steps = []) => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = useOnboardingStore(({ company, user }) => ({
    company,
    user,
  }));

  const { uid: hasUserData } = useUserData();

  // if user is available (anonymous user), return steps, otherwise use first step
  const availableSteps = useMemo(() => {
    if (hasUserData) {
      return getAvailableStepsNames(state, steps);
    }
    return [steps[0].name];
  }, [steps, state, hasUserData]);

  useEffect(() => {
    const isOnboarding = matchPath(
      { path: ONBOARDING_PATH, end: true },
      location.pathname
    );

    // don't do URL replacement if not in onboardng
    if (!isOnboarding) {
      return noop;
    }

    // If the user tries to go to a step that is not available we redirect him to the last available step
    if (!availableSteps.includes(isOnboarding.params.step)) {
      const previousStep = availableSteps[availableSteps.length - 1];

      navigate(generatePath(ONBOARDING_PATH, { step: previousStep }), {
        replace: true,
      });
    }
    return noop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return availableSteps;
};

export default useStepValidation;
