import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import findIndex from 'lodash/findIndex';

import Tag from 'components/tags/Tag';
import getTagColors from 'utils/color/getTagColors';
import topicFilter from 'utils/data/topicFilter';

/**
 * Child element for InputFieldSelect button with multiple options
 * Can be prepended with an additional element, such as an image
 */
const InputFieldTagsDropdownButton = ({
  tag = {},
  tagsIndex = [],
  prependButtonFn = null,
  testId = '',
}) => {
  const tagIndex = findIndex(tagsIndex, (t) => t.id === tag.id);
  let [bgColor, textColor] = getTagColors(tagIndex, tag.name?.length);

  if (topicFilter.isNone(tag)) {
    bgColor = 'black';
    textColor = 'white';
  }

  return (
    <Tag
      bgColor={bgColor}
      textColor={textColor}
      className={classNames({
        'inline-flex items-center': prependButtonFn,
      })}
      data-test-id={`${testId}-value-${tag.id}`}
    >
      {prependButtonFn && prependButtonFn(tag)}
      {tag.name}
    </Tag>
  );
};

InputFieldTagsDropdownButton.propTypes = {
  tag: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  tagsIndex: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  prependButtonFn: PropTypes.func,
  testId: PropTypes.string,
};

export default InputFieldTagsDropdownButton;
