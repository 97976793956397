import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { collection, orderBy, limit, query, where } from 'firebase/firestore';
import noop from 'lodash/noop';
import { useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { INBOXES_THREADS_MESSAGES_COLLECTION } from 'constants/firebasePaths';
import { messageStatus } from 'constants/messageStatus';

const InboxThreadsThreadOrder = ({
  threadDateCreated = '',
  threadId = '',
  threadsRef = {},
  setOrder = noop,
}) => {
  const messagesRef = collection(
    threadsRef,
    threadId,
    INBOXES_THREADS_MESSAGES_COLLECTION
  );
  const messagesQuery = query(
    messagesRef,
    orderBy('dateCreated', 'desc'),
    limit(1)
  );
  const unreadMessagesQuery = query(
    messagesRef,
    where('isJournalist', '==', true),
    where('status', '==', messageStatus.DELIVERED),
    where('read', '==', false),
    limit(1)
  );
  const { data: messagesData } = useFirestoreCollectionData(
    messagesQuery,
    defaultOptions
  );
  const { data: unreadMessagesData } = useFirestoreCollectionData(
    unreadMessagesQuery,
    defaultOptions
  );
  const mostRecentMessageDate = messagesData[0]?.dateCreated || null;
  const threadHasUnreadMessages = unreadMessagesData.length > 0;

  useEffect(() => {
    setOrder((prev) => {
      const newMap = new Map(prev);
      newMap.set(threadId, {
        mostRecentMessageDate,
        threadHasUnreadMessages,
        threadDateCreated,
      });
      return newMap;
    });
  }, [
    mostRecentMessageDate,
    setOrder,
    threadDateCreated,
    threadHasUnreadMessages,
    threadId,
  ]);

  return null;
};

InboxThreadsThreadOrder.propTypes = {
  threadId: PropTypes.string.isRequired,
  threadsRef: PropTypes.object.isRequired,
  setOrder: PropTypes.func.isRequired,
  threadDateCreated: PropTypes.string,
};

export default InboxThreadsThreadOrder;
