import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import CloseButton from 'components/buttons/CloseButton';
import CompanyImage from 'components/company/CompanyImage';
import TagList from 'components/tags/TagList';

const ContentElementPartnershipCompaniesCompany = ({
  company = {},
  onRemove = noop,
}) => (
  <tr className="py-2 even:bg-gray-50">
    <td className="px-4 py-2 w-36">
      <p className="w-24 truncate text-xs whitespace-nowrap">{company.name}</p>
    </td>
    <td className="px-4 py-2">
      <div className="flex flex-wrap justify-start items-center -mt-2">
        {!company.sectors?.length && (
          <p className="text-xs text-gray-500 mt-2">No sectors defined</p>
        )}
        <TagList
          className="mt-3"
          tags={(company.sectors || []).map((sector) => ({
            text: sector.name,
            id: sector.name,
          }))}
        />
      </div>
    </td>
    <td className="px-4 py-2 w-8">
      <CompanyImage image={company.logo} />
    </td>
    <td className="px-4 py-2 w-5">
      <div className="flex justify-center items-center">
        <CloseButton className="bg-transparent" onClick={onRemove} />
      </div>
    </td>
  </tr>
);

ContentElementPartnershipCompaniesCompany.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sectors: PropTypes.array,
    logo: PropTypes.object,
  }),
  onRemove: PropTypes.func.isRequired,
};

export default ContentElementPartnershipCompaniesCompany;
