import React from 'react';

import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';

import ErrorMessage from 'components/form/ErrorMessage';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import countryOptions from 'constants/countries';

const ContentElementMAAcquiredCountry = () => {
  const name = 'companyAcquiredCountry';
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name, null);

  return (
    <div className="w-full space-y-2">
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <InputFieldDropdown
            label="Country of the acquired company"
            name={name}
            options={countryOptions}
            onChange={onChange}
            value={value || 'Please select a country...'}
            buttonClassName="w-full"
            optionsClassName="overflow-x-hidden"
            testId="company-content-m&a-inputCompanyAcquiredCountry"
          />
        )}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </div>
  );
};

export default ContentElementMAAcquiredCountry;
