import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'components/Modal';
import Stepper from 'components/Stepper';
import OpportunityDetails from 'components/opportunities/OpportunityDetails';
import useLogOpportunityInteraction from 'hooks/useLogOpportunityInteraction';
import useQueryParams from 'routes/Opportunities/hooks/useQueryParams';

import useCreateSetState from '../../hooks/useCreateSetState';

import OpportunitiesListResponseModalCallout from './OpportunitiesListResponseModalCallout';
import OpportunitiesListResponseModalLeftActions from './OpportunitiesListResponseModalLeftActions';
import OpportunitiesListResponseModalRightActions from './OpportunitiesListResponseModalRightActions';
import OpportunitiesListResponseModalTrackVisit from './OpportunitiesListResponseModalTrackVisit';

// eslint-disable-next-line no-unused-vars
const OpportunitiesListResponseModal = ({
  open = false,
  opportunity = {},
  currentCount = 0,
  totalCount = 0,
  nextId = '',
  prevId = '',
}) => {
  const setId = useCreateSetState('id');
  const { showDeclined } = useQueryParams();
  const logOpportunityInteraction = useLogOpportunityInteraction();

  const onClose = () => {
    logOpportunityInteraction('dismiss', opportunity);
    setId('');
  };

  if (!Object.keys(opportunity).length) {
    return null;
  }

  const expired = opportunity.deadlineUTC?.toDate?.() < new Date() ?? false;

  return (
    <Modal
      open={open}
      testId="opportunity-responseModal"
      widthClass="max-w-xl lg:max-w-2xl xl:max-w-3xl"
    >
      <Modal.Close
        srMessage="Close Opportunity Response modal"
        testId="opportunity-responseModal-close"
        onClose={onClose}
      />
      <Modal.TopBar>
        <Stepper
          currentStep={currentCount}
          testId="opportunity-responseModal"
          totalSteps={totalCount}
          stepLabel="Opportunity"
          stepsLabel="Opportunities"
          onPrevious={() => {
            setId(prevId);
          }}
          onNext={() => {
            setId(nextId);
          }}
        />
      </Modal.TopBar>
      <Modal.Content>
        <OpportunityDetails opportunity={opportunity} />

        {!expired && (
          <OpportunitiesListResponseModalCallout
            journalist={opportunity.journalist}
          />
        )}

        <OpportunitiesListResponseModalTrackVisit uid={opportunity.uid} />
      </Modal.Content>
      {!showDeclined ? (
        <Modal.LeftButtons>
          <OpportunitiesListResponseModalLeftActions
            opportunity={opportunity}
            onClose={onClose}
          />
        </Modal.LeftButtons>
      ) : null}
      <Modal.RightButtons>
        <OpportunitiesListResponseModalRightActions
          opportunity={opportunity}
          onClose={onClose}
        />
      </Modal.RightButtons>
    </Modal>
  );
};

OpportunitiesListResponseModal.propTypes = {
  open: PropTypes.bool,
  opportunity: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    mediaOutlet: PropTypes.string.isRequired,
    mediaOutletWebsite: PropTypes.string,
    journalist: PropTypes.string.isRequired,
    deadlineUTC: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    enquirySummary: PropTypes.string,
    query: PropTypes.array.isRequired,
    topics: PropTypes.array,
    custom: PropTypes.bool,
    domainAuthority: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    publicationImage: PropTypes.object,
    journalistImage: PropTypes.object,
    response: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  currentCount: PropTypes.number,
  totalCount: PropTypes.number,
  nextId: PropTypes.string,
  prevId: PropTypes.string,
};

export default OpportunitiesListResponseModal;
