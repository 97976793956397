import PropTypes from 'prop-types';
import React from 'react';

import { StarIcon } from '@heroicons/react/solid';
import { doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Tag from 'components/tags/Tag';
import { CUSTOM_OPPORTUNITIES_COLLECTION } from 'constants/firebasePaths';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';

import AdminOpportunityCompanies from './AdminOpportunityCompanies';
import AdminOpportunityWrapper from './AdminOpportunityWrapper';

const AdminOpportunityCustom = ({ opportunity = {} }) => {
  const { companies, targetedCompanies } = opportunity;
  const firestore = useFirestore();
  const { updateDocument } = useFirestoreWrapper();
  const { showSuccess, showError } = useNotifications();

  const ref = doc(firestore, CUSTOM_OPPORTUNITIES_COLLECTION, opportunity.uid);

  const onToggleDelete = async () => {
    const action = opportunity.deleted ? 'recover' : 'delet';
    try {
      await updateDocument(ref, { deleted: !opportunity.deleted });
      showSuccess({ message: `Opportunity ${action}ed successfully.` });
    } catch (err) {
      showError({ message: `Error ${action}ing opportunity: ${err.message}` });
    }
  };

  return (
    <AdminOpportunityWrapper
      onToggleDelete={onToggleDelete}
      opportunity={opportunity}
      Tags={
        <Tag textColor="teal-600" bgColor="teal-200" bold>
          <StarIcon className="w-4 mr-1" />
          Custom Opportunity
        </Tag>
      }
      Companies={
        <AdminOpportunityCompanies
          companies={companies}
          targetedCompanies={targetedCompanies}
          opportunityUid={opportunity.uid}
        />
      }
    />
  );
};

AdminOpportunityCustom.propTypes = {
  opportunity: PropTypes.object,
};

export default AdminOpportunityCustom;
