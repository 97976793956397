import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { RefreshIcon } from '@heroicons/react/solid';
import { getFunctions, httpsCallable } from 'firebase/functions';
import noop from 'lodash/noop';
import { useFirebaseApp } from 'reactfire';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import functionsConfig from 'constants/functionsConfig';

import AdminResetModalCollections from '../components/AdminResetModalCollections';

const AdminUnclutterProdEnvironmentModal = ({
  open = false,
  onClose = noop,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const app = useFirebaseApp();
  const functions = getFunctions(app, functionsConfig.DEFAULT_REGION);

  const resetDevEnvironment = useCallback(async () => {
    setIsLoading(true);
    await httpsCallable(functions, 'firestore-onResetProdEnvironment-default', {
      timeout: 300000,
    })();
    setIsLoading(false);
    onClose();
  }, [functions, onClose]);

  return (
    <Modal open={open} onClose={isLoading ? noop : onClose}>
      {isLoading ? (
        <Modal.Overlay className="bg-white/70 z-20">
          <Spinner />
        </Modal.Overlay>
      ) : null}
      <Modal.Close
        srMessage="Close Unclutter Production modal"
        onClose={onClose}
      />
      <Modal.Icon Icon={RefreshIcon} warning />
      <Modal.Title>Unclutter Production Environment</Modal.Title>
      <Modal.Description>
        Are you sure you want to unclutter the Production environment?
        <div className="text-left mt-4">
          <p>
            <b>This will trigger the following actions:</b>
          </p>
          <ul className="list-disc pl-4 mt-4 space-y-2">
            <li className="leading-normal">
              Delete any anonymous users from{' '}
              <a
                href="https://console.firebase.google.com/project/mvpr-io-production/authentication/users"
                target="_blank"
                rel="noreferrer"
                className="text-teal-500"
              >
                Auth
              </a>{' '}
              which are older than a month
            </li>

            <li className="leading-normal">
              Delete any dummy accounts which end in{' '}
              <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                ...@mvpr.io
              </code>{' '}
              and are not on the{' '}
              <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                userEmailAllowList
              </code>{' '}
              defined in{' '}
              <a
                href="https://console.firebase.google.com/project/mvpr-io-production/config"
                target="_blank"
                rel="noreferrer"
                className="text-teal-500"
              >
                Remote Config
              </a>
              . This will also remove any entries associated with this user from
              the following collections (similar to when a user deletes an
              account):
              <AdminResetModalCollections />
            </li>

            <li className="leading-normal">
              Add test accounts and all of its corresponding fixtures and files,
              including dummy date for:
              <ul className="pl-4 list-disc mt-2 space-y-1">
                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    opportunities
                  </code>{' '}
                  (a few with far-future deadlines)
                </li>
                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    companies
                  </code>{' '}
                  (subscribed and unsubscribed, see{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="text-teal-500"
                    href="https://www.notion.so/mvpr/Test-accounts-f424994bd373414fa6682f79c699f44e?pvs=4"
                  >
                    test accounts
                  </a>
                  )
                </li>
                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    inbox
                  </code>{' '}
                  (with two examples threads)
                </li>
                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    sweetheart list
                  </code>{' '}
                  (for the test account #1)
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </Modal.Description>
      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-content-addModal-cancel"
        >
          Cancel
        </Button>
        <Button onClick={resetDevEnvironment}>Yes, unclutter it!</Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminUnclutterProdEnvironmentModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AdminUnclutterProdEnvironmentModal;
