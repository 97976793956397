// eslint-disable jsx-a11y/no-noninteractive-element-interactions

import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import ReactTags from 'react-tag-autocomplete';

import InputFieldTagsAutocompleteInstructions from './InputFieldTagsAutocompleteInstructions';
import reactTagsClassnames from './config/reactTagsClassnames';
import createInputFieldTagsAutocompleteTag from './createInputFieldTagsAutocompleteTag';

// Renders a autocomplete field with tags and a suggestions flyout
const InputFieldTagsAutocompleteSuggestions = ({
  terms = [],
  onChange = noop,
  suggestions = [],
  removeTerm = noop,
  addTerm = noop,
  testId = '',
}) => (
  <section className="flex-1 2xl:max-w-[50vw]">
    <div className="relative">
      <label
        className="focus-within:border-teal-500 relative px-2 block bg-white border border-gray-300 min-w-0 cursor-text rounded-md w-full"
        data-test-id={testId}
      >
        <ReactTags
          autoresize
          minQueryLength={1}
          classNames={reactTagsClassnames}
          tags={terms.map((t) => ({ name: t }))}
          suggestions={suggestions}
          onInput={onChange}
          onDelete={(idx) => removeTerm(terms[idx])}
          onAddition={({ name }) => addTerm(name)}
          tagComponent={createInputFieldTagsAutocompleteTag()}
          suggestionsTransform={() => suggestions} // We override the default filter, as we do that in the backend already
          allowNew
          placeholderText=""
        />
      </label>
    </div>
    <InputFieldTagsAutocompleteInstructions
      tagWord="search term"
      className="hidden md:block mt-2"
    />
  </section>
);

InputFieldTagsAutocompleteSuggestions.propTypes = {
  onChange: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  terms: PropTypes.array,
  removeTerm: PropTypes.func,
  addTerm: PropTypes.func,
  testId: PropTypes.string,
};

export default InputFieldTagsAutocompleteSuggestions;
