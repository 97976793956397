import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

/**
 * Aside link displaying the icon ( and whether it's selected or not) and the name on hover
 * The pointer-events-none makes sure that the hoverable link name doesn't interfere with the current page
 */
const AsideLink = ({
  path = '',
  label = '',
  children = null,
  testId = '',
  Badge = null,
}) => (
  <NavLink
    to={path}
    className={classNames(
      'group relative text-gray-900 hover:text-gray-900 group rounded-md flex items-center justify-center text-sm font-medium'
    )}
    data-test-id={testId}
  >
    {({ isActive }) => (
      <>
        {Badge && (
          <div className="absolute top-[4px] left-[calc(100%-46px)]">
            {Badge}
          </div>
        )}
        <div
          className={classNames(
            {
              'bg-gray-100': isActive,
              'group-hover:bg-gray-100': !isActive,
            },
            'flex w-full flex-col items-center text-teal-500 p-2 rounded-sm transition-colors duration-300 ease-in-out'
          )}
        >
          {children}
          <p
            className={classNames('text-xs font-medium w-[5rem] text-center', {
              'text-gray-700': isActive,
              'text-gray-400 group-hover:text-gray-500': !isActive,
            })}
          >
            {label}
          </p>
        </div>
      </>
    )}
  </NavLink>
);
AsideLink.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.element,
  testId: PropTypes.string,
  Badge: PropTypes.node,
};

export default AsideLink;
