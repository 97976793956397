import React from 'react';

import Button from 'components/buttons/Button';
import useHandleLogout from 'hooks/useHandleLogout';

// Renders an error for logged in users that are *not* journalists
const JournalistClaimsError = () => {
  const logout = useHandleLogout();
  return (
    <div className="min-h-screen pt-24 bg-gray-50 flex flex-col items-center py-12 sm:px-6 lg:px-8">
      <div className="max-w-prose">
        <p className="font-semibold">Oops.</p>
        <p className="mt-4 text-md">
          You don&apos;t seem to have signed up for a journalist account yet. If
          you recently signed up as a journalist, please log out and log in
          again to refresh your ID token.
        </p>
        <div className="mt-4 w-24">
          <Button type="secondary" onClick={logout}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JournalistClaimsError;
