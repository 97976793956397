import PropTypes from 'prop-types';
import React from 'react';

import { messageStatus } from 'constants/messageStatus';

import AdminMessagesThreadsMessagesCountPill from './AdminMessagesThreadsMessagesCountPill';

const AdminMessagesThreadsMessages = ({ messages = [] }) => {
  const approvedMessagesCount = messages.filter(
    (message) =>
      message.data.status === messageStatus.APPROVED ||
      message.data.status === messageStatus.DELIVERED
  ).length;
  const unapprovedMessagesCount = messages.filter(
    (message) =>
      !message.data.status || message.data.status === messageStatus.UNAPPROVED
  ).length;

  return (
    <td className="text-center">
      <div className="flex items-center justify-center gap-2 text-sm">
        <AdminMessagesThreadsMessagesCountPill count={approvedMessagesCount} />
        <AdminMessagesThreadsMessagesCountPill
          count={unapprovedMessagesCount}
          activeClassNames="bg-red-100 text-red-600"
        />
      </div>
    </td>
  );
};

AdminMessagesThreadsMessages.propTypes = {
  messages: PropTypes.array,
};
export default AdminMessagesThreadsMessages;
