import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { REGEX_EMAIL } from 'constants/regex';
import useUpdateJournalist from 'hooks/useUpdateJournalist';

const AdminMessagesThreadsThreadJournalistModal = ({
  open = true,
  onClose = noop,
  journalist = {},
}) => {
  const formName = 'journalistEmailForm';
  const notifications = useNotifications();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      journalistEmail: journalist.email,
    },
  });
  const updateJournalist = useUpdateJournalist();

  const handleSave = async (data) => {
    try {
      await updateJournalist({
        name: journalist.name,
        publicationName: journalist?.publication?.name,
        email: data.journalistEmail,
      });

      // optimistic UI update by reference
      // TODO: replace with journalist reload once this is feasible
      // eslint-disable-next-line no-param-reassign
      journalist.email = data.journalistEmail;

      onClose();
      notifications.showSuccess({
        message: `${journalist.name}'s email updated`,
      });
    } catch (error) {
      notifications.showError({ message: 'Error updating journalist email' });
    }
  };

  useEffect(() => {
    reset({
      journalistEmail: journalist.email,
    });
  }, [journalist.email, reset]);

  return (
    <Modal open={open}>
      <Modal.Title>
        {journalist.email ? 'Edit' : 'Add'} Journalist Email
      </Modal.Title>
      <Modal.Content>
        <form onSubmit={handleSubmit(handleSave)} id={formName}>
          <InputFieldText
            id="journalistEmail"
            {...register('journalistEmail', {
              required: {
                value: true,
                message: 'Please enter a journalist email',
              },
              pattern: {
                value: REGEX_EMAIL,
                message: 'Please enter a valid email',
              },
            })}
            defaultValue={journalist.email || ''}
            placeholder="Journalist Email"
            errors={errors}
          >
            <InputFieldText.Label>Email</InputFieldText.Label>
          </InputFieldText>
        </form>
      </Modal.Content>

      <Modal.RightButtons>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button submit form={formName}>
          Save
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminMessagesThreadsThreadJournalistModal.propTypes = {
  journalist: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default AdminMessagesThreadsThreadJournalistModal;
