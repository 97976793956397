import { uniqBy } from 'lodash';
import isEqual from 'lodash/isEqual';
import createStore from 'zustand';

import { coreTopicsForUser } from 'constants/tags';
import isActiveSubscription from 'hooks/helpers/isActiveSubscription';

const mapTopicToName = (topic) =>
  topic?.name?.toLowerCase().trim() || topic?.toLowerCase().trim();
const useCompaniesStore = createStore((set) => ({
  companies: [],
  companiesTiers: new Map(),
  companiesTopics: [],
  filteredCompanies: new Set(),

  showArchived: false,
  search: '',
  topics: [],
  activeTier: 'all',
  tiers: [],

  setCompanyTier: ({ id, ...rest }) => {
    set((state) => ({
      companiesTiers: new Map(state.companiesTiers.set(id, rest)),
    }));
  },

  removeCompanyTier: (id) => {
    set((state) => {
      const companiesTiers = new Map(state.companiesTiers);
      companiesTiers.delete(id);
      return { companiesTiers };
    });
  },
}));

// Filter companies based on search, topics and tier
useCompaniesStore.subscribe((state, prevState) => {
  if (!state.companies.length) {
    return;
  }

  const stateTopicsNames = state.topics.map(mapTopicToName);
  const prevStateTopicsNames = prevState.topics.map(mapTopicToName);

  const stateCompaniesIds = state.companies.map((c) => c.uid);
  const prevStateCompaniesIds = prevState.companies.map((c) => c.uid);

  const prevStateArchived = prevState.companies
    .filter((c) => c.archived)
    .map((c) => c.uid);
  const stateArchived = state.companies
    .filter((c) => c.archived)
    .map((c) => c.uid);

  const prevStateTiers = prevState.tiers.map((t) => t.value);
  const stateTiers = state.tiers.map((t) => t.value);

  if (
    state.search === prevState.search &&
    state.activeTier === prevState.activeTier &&
    state.showArchived === prevState.showArchived &&
    isEqual(prevStateTopicsNames, stateTopicsNames) &&
    isEqual(prevStateCompaniesIds, stateCompaniesIds) &&
    isEqual(prevStateArchived, stateArchived) &&
    isEqual(prevStateTiers, stateTiers)
  ) {
    return;
  }

  let filteredCompanies = state.companies;

  // Filter by company name
  if (state.search) {
    filteredCompanies = filteredCompanies.filter((company) =>
      company.name.toLowerCase().includes(state.search.toLowerCase())
    );
  }

  // Filter by company archived status
  if (state.showArchived) {
    filteredCompanies = filteredCompanies.filter((company) => company.archived);
  } else {
    filteredCompanies = filteredCompanies.filter(
      (company) => !company.archived
    );
  }

  // Filter by company subscription tier
  if (state.activeTier === 'all') {
    filteredCompanies = filteredCompanies.filter((company) =>
      state.companiesTiers.get(company.uid)
    );
  } else if (state.activeTier === 'none') {
    filteredCompanies = filteredCompanies.filter(
      (company) => !state.companiesTiers.get(company.uid)
    );
  } else {
    filteredCompanies = filteredCompanies.filter(
      (company) =>
        state.companiesTiers.get(company.uid)?.product === state.activeTier
    );
  }

  // Filter by company topics
  if (state.topics.length) {
    filteredCompanies = filteredCompanies
      .filter((company) => company.topics?.length)
      .filter((company) => {
        const companyTopicsNames = company.topics.map(mapTopicToName);
        return stateTopicsNames.every((t) => companyTopicsNames.includes(t));
      });
  }

  filteredCompanies = new Set(filteredCompanies.map((c) => c.uid));

  // Don't update state if filteredCompaniesIds is the same as prevState.filteredCompanies
  if (isEqual(prevState.filteredCompanies, filteredCompanies)) {
    return;
  }

  useCompaniesStore.setState({
    filteredCompanies,
  });
});

// We merge core topics with company topics
useCompaniesStore.subscribe((state, prevState) => {
  if (isEqual(state.companies, prevState.companies)) {
    return;
  }
  const companiesTopics = state.companies
    .flatMap((c) => c.topics)
    .filter((t) => t?.name);

  const uniqueTopics = uniqBy(
    coreTopicsForUser.concat(companiesTopics),
    mapTopicToName
  );

  useCompaniesStore.setState({
    companiesTopics: uniqueTopics,
  });
});

// We create tiers from active company subscriptions
useCompaniesStore.subscribe((state, prevState) => {
  const tiers = uniqBy(
    Array.from(state.companiesTiers.values()).filter(isActiveSubscription),
    'product'
  ).map((t) => ({ label: t.name, value: t.product }));

  if (isEqual(prevState.tiers, tiers)) {
    return;
  }

  const largestTierNameLength = tiers.reduce(
    (acc, tier) => Math.max(acc, tier.label.length),
    0
  );

  useCompaniesStore.setState({
    tiers,
    largestTierNameLength,
  });
});

export default useCompaniesStore;
