import { useCallback } from 'react';

import noop from 'lodash/noop';

import { useTracking } from 'modules/segment/TrackingProvider';

const useWithAnalytics = ({ eventType = '', eventProperties = {} }) => {
  const trackingService = useTracking();

  return useCallback(
    (callback = noop) =>
      (...args) => {
        if (eventType) {
          trackingService.track(eventType, eventProperties);
        }

        callback(...args);
      },
    [eventType, trackingService, eventProperties]
  );
};

export default useWithAnalytics;
