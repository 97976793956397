import { PASSWORD_RESET as path } from 'constants/paths';

import ResetPasswordPage from './ResetPasswordPage';

const ResetPasswordRoute = {
  path,
  component: ResetPasswordPage,
};

export default ResetPasswordRoute;
