import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

// Displays the headline for a section on the company's press page
const SectionHeadline = ({ children = null, light = false, testId = '' }) => {
  const themeTextColor = {
    'text-gray-400': light,
  };

  return (
    <div className="flex items-center" data-test-id={testId}>
      <h2
        className={classNames(
          'text-sm uppercase font-medium tracking-wide',
          themeTextColor
        )}
      >
        {children}
      </h2>
    </div>
  );
};

SectionHeadline.propTypes = {
  children: PropTypes.string.isRequired,
  light: PropTypes.bool,
  testId: PropTypes.string,
};

export default SectionHeadline;
