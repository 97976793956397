import { SIGNUP_JOURNALIST_PATH as path } from 'constants/paths';

import SignupJournalistPage from './SignupJournalistPage';

const SignupJournalistRoute = {
  path,
  component: SignupJournalistPage,
};

export default SignupJournalistRoute;
