import { useEffect, useState } from 'react';

const SEARCH_URL = process.env.REACT_APP_SEARCH_URL;

// retrive all possible filters for the company search
const useCompanySearchFilters = (idToken = '') => {
  const [filters, setFilters] = useState([]);
  const [filterNames, setFilterNames] = useState({});
  const [contentTypeNames, setContentTypeNames] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await fetch(`${SEARCH_URL}/search/`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        const data = await response.json();
        const { facets, facetNameMapping, contentTypeNameMapping } = data;
        setFilters(facets);
        setFilterNames(facetNameMapping);
        setContentTypeNames(contentTypeNameMapping);
        setError(false);
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    })();
  }, [idToken]);

  return {
    loading,
    error,
    filterNames,
    contentTypeNames,
    filters,
  };
};

export default useCompanySearchFilters;
