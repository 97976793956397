import PropTypes from 'prop-types';
import React from 'react';

import CompanyImage from 'components/company/CompanyImage';

const AdminSuccessFeesTableRowCompany = ({ logo = {}, name = '' }) => (
  <div className="flex items-center gap-2">
    <div className="relative w-8 h-8 flex items-center justify-center">
      <CompanyImage image={logo} alt={`${name} logo`} />
    </div>
    {name}
  </div>
);

AdminSuccessFeesTableRowCompany.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.object,
};

export default AdminSuccessFeesTableRowCompany;
