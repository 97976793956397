import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { useFirestore } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import successFeeStatuses from 'constants/successFeeStatuses';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import getStatusConfig from 'utils/getStatusConfig';

import createOnSaveInvoiceDraft from './helpers/createOnSaveInvoiceDraft';
import createOnSendReceipt from './helpers/createOnSendReceipt';

const SuccessFeesModalButtons = ({
  form = {},
  draft = {},
  onClose = noop,
  companyId = '',
  threadId = '',
  successFees = {},
}) => {
  const { setDocument } = useFirestoreWrapper();
  const { showSuccess, showError } = useNotifications();
  const firestore = useFirestore();

  const { getValues, formState } = form;
  const { isValid } = formState;

  const { date, title, link } = getValues();

  const onSaveInvoiceDraft = createOnSaveInvoiceDraft({
    date,
    link,
    title,
    draft,
    companyId,
    threadId,
    firestore,
    successFees,
    onClose,
    showError,
    showSuccess,
    setDocument,
  });

  const onSendReceipt = createOnSendReceipt({
    isValid,
    title,
    link,
    draft,
    companyId,
    successFeesUid: successFees?.uid,
    firestore,
    onSaveInvoiceDraft,
    setDocument,
  });

  const status = successFees?.invoice?.status;
  const canSendInvoice = status === successFeeStatuses.DRAFTED && isValid;
  const { canBeModified, sendInvoiceButtonText } = getStatusConfig(status);

  return (
    <>
      <Button
        type="secondary"
        disabled={!isValid || !canBeModified}
        onClick={form.handleSubmit(onSaveInvoiceDraft)}
      >
        Save Invoice Draft
      </Button>
      <Button disabled={!canSendInvoice} onClick={onSendReceipt}>
        {sendInvoiceButtonText}
      </Button>
    </>
  );
};

SuccessFeesModalButtons.propTypes = {
  form: PropTypes.object,
  draft: PropTypes.object,
  onClose: PropTypes.func,
  companyId: PropTypes.string,
  threadId: PropTypes.string,
  successFees: PropTypes.object,
};

export default SuccessFeesModalButtons;
