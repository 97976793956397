import PropTypes from 'prop-types';
import React from 'react';

import { useParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { tabNames } from 'constants/inboxTabs';

import InboxThreadsThread from './InboxThreadsThread/InboxThreadsThread';
import InboxThreadsThreadOrder from './InboxThreadsThreadOrder';
import useOrderedThreads from './hooks/useOrderedThreads';

const InboxThreads = ({
  threads = [],
  currentThreadId = '',
  threadsRef = {},
}) => {
  const { tab } = useParams();
  const isDraftTab = tab === tabNames.DRAFT;
  const { order, orderedThreads, setOrder } = useOrderedThreads(
    threads,
    isDraftTab
  );

  return (
    <ul
      data-test-id="inbox-threadsList"
      data-intercom-id="inbox-threads"
      className="shadow-sm rounded-md"
    >
      {orderedThreads.map((thread, index) => {
        const threadOrder = order.get(thread.uid);

        if (!threadOrder) {
          return null;
        }

        const { threadHasUnreadMessages, mostRecentMessageDate } = threadOrder;

        return (
          <InboxThreadsThread
            key={thread.uid}
            currentThreadId={currentThreadId}
            threadsRef={threadsRef}
            thread={thread}
            hasUnreadMessages={threadHasUnreadMessages}
            hasMessages={mostRecentMessageDate !== null}
            isFirst={index === 0}
            isLast={index + 1 === threads.length}
          />
        );
      })}
      {threads.map((thread) => (
        <InboxThreadsThreadOrder
          key={thread.uid}
          threadDateCreated={thread.dateCreated}
          threadId={thread.uid}
          threadsRef={threadsRef}
          setOrder={setOrder}
        />
      ))}
      <ReactTooltip id="tooltip-inbox-thread" className="text-sm z-30" />
    </ul>
  );
};

InboxThreads.propTypes = {
  threads: PropTypes.array.isRequired,
  currentThreadId: PropTypes.string,
  threadsRef: PropTypes.object,
};

export default InboxThreads;
