import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/lodash';

import IconImage from 'components/icons/IconImage';

const DROPZONE_TITLE = 'Drop the file here ...';
const DROPZONE_DESCRIPTION = 'Drag and drop a file here, or click to select.';

/**
 * Dropzone component that allows dropping images onto it,
 * as well as selecting an image from hard drive by clicking anywhere in the box
 */
const InputFieldUploadDropzone = ({
  name = '',
  acceptText = '',
  getInputProps = noop,
  isDragActive = false,
}) => (
  <div className="flex flex-col w-full justify-center gap-1 sm:p-8 text-center cursor-pointer">
    <IconImage />

    <input
      id={name}
      name={name}
      type="file"
      className="sr-only"
      {...getInputProps()}
    />

    <p className="text-sm text-gray-500">
      {isDragActive ? DROPZONE_TITLE : DROPZONE_DESCRIPTION}
    </p>

    <p className="text-xs text-gray-500">{acceptText}</p>
  </div>
);

InputFieldUploadDropzone.propTypes = {
  name: PropTypes.string.isRequired,
  acceptText: PropTypes.string.isRequired,
  getInputProps: PropTypes.func.isRequired,
  isDragActive: PropTypes.bool.isRequired,
};

export default InputFieldUploadDropzone;
