import PropTypes from 'prop-types';
import React from 'react';

import JournalistResultsCompany from './JournalistResultsCompany';

// Renders the results in the companies search
const JournalistResults = ({
  results = [],
  loading = true,
  totalResults = 0,
}) => {
  if (loading) {
    return null;
  }

  if (results.length === 0) {
    return (
      <div className="bg-gray-50 items-center col-span-12">
        <div className="flex flex-1 flex-col justify-between items-start p-6 rounded-lg text-sm border-gray-200 bg-white border hover:border-teal-500 hover:shadow-md">
          <p className="text-md">There are no results for your search.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="col-span-12">
      <div className="text-right py-2 text-sm text-gray-500 hidden lg:block">
        {totalResults} {totalResults === 1 ? 'result' : 'results'}
      </div>
      <ul>
        {results.map((company) => (
          <JournalistResultsCompany
            key={company?.id}
            slug={company?.slug}
            name={company?.name}
            description={company?.description}
            founders={company?.founders}
            vcs={company?.vcs || []}
          />
        ))}
      </ul>
    </div>
  );
};

JournalistResults.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      founder: PropTypes.object,
    })
  ),
  loading: PropTypes.bool,
  totalResults: PropTypes.number,
};

export default JournalistResults;
