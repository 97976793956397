import PropTypes from 'prop-types';
import React from 'react';

import { QuestionMarkCircleIcon, TrashIcon } from '@heroicons/react/solid';
import { generatePath, useNavigate } from 'react-router-dom';

import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import {
  ADMIN_MESSAGES_COMPANIES_PATH,
  ADMIN_MESSAGES_PATH,
} from 'constants/paths';
import useAPIRequest from 'hooks/useAPIRequest';

import SuccessFeesModalDefinition from './SuccessFeesModalDefinition';
import SuccessFeesModalInfoOpportunityAssignButton from './SuccessFeesModalInfoOpportunityAssignButton';

const SuccessFeesModalInfoOpportunity = ({
  companyId = '',
  threadId = '',
  successFeeId = '',
}) => {
  const navigate = useNavigate();
  const {
    data: threadData,
    status,
    loading,
  } = useAPIRequest({
    endpoint: `/companies/${companyId}/threads/${threadId}`,
    method: 'GET',
    service: 'MOD',
  });

  const { subject, journalist } = threadData;

  return (
    <div className="relative group">
      {loading ? (
        <>
          <div className="absolute pointer-events-none inset-0 bg-white/90" />
          <Spinner />
        </>
      ) : null}

      {threadId && status === 404 ? (
        <div className="bg-white/90 text-red-500 inset-0 absolute flex-col flex justify-center items-center">
          <TrashIcon className="h-6 w-6 " />
          <p>Assigned thread seems to have been deleted.</p>
          <SuccessFeesModalInfoOpportunityAssignButton
            companyId={companyId}
            successFeeId={successFeeId}
          />
        </div>
      ) : null}

      {!threadId ? (
        <div className="bg-white/90 text-amber-500 inset-0 absolute flex-col flex justify-center items-center">
          <QuestionMarkCircleIcon className="h-6 w-6 " />
          <p>No thread assigned to success fee.</p>
          <SuccessFeesModalInfoOpportunityAssignButton
            companyId={companyId}
            successFeeId={successFeeId}
          />
        </div>
      ) : null}

      {threadId && companyId ? (
        <div className="group-hover:flex hidden absolute inset-0 bg-white/90 items-center justify-center">
          <Button
            onClick={() => {
              navigate(
                generatePath(ADMIN_MESSAGES_PATH, {
                  inboxId: companyId,
                  threadId,
                  type: ADMIN_MESSAGES_COMPANIES_PATH,
                })
              );
            }}
          >
            Go to thread
          </Button>
        </div>
      ) : null}

      <SuccessFeesModalDefinition label="Media Opportunity:">
        {subject || 'n/a'}
      </SuccessFeesModalDefinition>
      <SuccessFeesModalDefinition label="Journalist:">
        {journalist?.name || 'n/a'}
      </SuccessFeesModalDefinition>
    </div>
  );
};

SuccessFeesModalInfoOpportunity.propTypes = {
  companyId: PropTypes.string,
  threadId: PropTypes.string,
  successFeeId: PropTypes.string,
};
export default SuccessFeesModalInfoOpportunity;
