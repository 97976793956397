import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';

import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldCurrency from 'components/form/inputFields/InputFieldCurrency/InputFieldCurrency';

const ContentElementMADealSize = ({ index = 0 }) => {
  const name = `dealSize`;
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const currency = watch(`${name}.currency`);
  const error = get(errors, `${name}.amount`, null);

  return (
    <div className="w-full">
      <div className="space-y-2">
        <Label htmlFor={`${name}${index}`}>Size of the deal*</Label>
        <InputFieldCurrency
          control={control}
          inputRules={{
            valueAsNumber: true,
            required: {
              value: true,
              message: 'Please provide the size of the deal',
            },
            min: {
              value: 1,
              message: 'Please provide a valid value',
            },
          }}
          id={`${name}${index}`}
          testId="company-content-m&a-inputDealSize"
          currencyDefaultName={currency}
          nameAmount={`${name}.amount`}
          nameCurrency={`${name}.currency`}
        />
      </div>
      {error && (
        <ErrorMessage testId="company-content-m&a-inputDealSize-error">
          {error.message}
        </ErrorMessage>
      )}
    </div>
  );
};

ContentElementMADealSize.propTypes = {
  index: PropTypes.number,
};

export default ContentElementMADealSize;
