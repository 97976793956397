import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const CompanyInsightAnswer = ({ answer = '', clamp = true, testId = '' }) => (
  <p
    className={classNames(
      'flex-1 mt-2 text-sm text-gray-400 leading-normal break-words',
      {
        'line-clamp-5': clamp,
      }
    )}
    data-test-id={testId}
  >
    {answer}
  </p>
);

CompanyInsightAnswer.propTypes = {
  answer: PropTypes.string,
  clamp: PropTypes.bool,
  testId: PropTypes.string,
};

export default CompanyInsightAnswer;
