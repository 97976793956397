import PropTypes from 'prop-types';
import React, { useState } from 'react';

import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import { REGEX_URL } from 'constants/regex';
import useAPIRequest from 'hooks/useAPIRequest';

const UPLOAD_FOLDER = 'publicationImages';

const AdminPublicationsEditPublicationModal = ({
  open = false,
  onClose = noop,
  onSubmit = noop,
  currentPublication = null,
}) => {
  const { showError } = useNotifications();
  const { fetchData: getPrefillData } = useAPIRequest({
    method: 'POST',
    endpoint: '/publications/prefill',
    service: 'JPD',
  });

  const [prefillLoading, setPrefillLoading] = useState(false);

  const form = useForm({
    mode: 'onChange',
    defaultValues: currentPublication
      ? {
          ...currentPublication,
          image: currentPublication.image
            ? {
                path: currentPublication.image,
                type: 'image',
              }
            : null,
        }
      : {
          name: '',
          image: null,
        },
  });
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = form;

  return (
    <Modal open={open}>
      <Modal.Title>Edit publication</Modal.Title>
      <Modal.Content>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="publicationForm"
          className="flex flex-col gap-2"
        >
          <InputFieldText
            {...register('name', {
              required: 'Name is required',
            })}
            errors={errors}
            disabled={!!currentPublication}
          >
            <InputFieldText.Label>Name</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText
            {...register('website', {
              pattern: {
                value: REGEX_URL,
                message: 'Please type in a valid URL',
              },
            })}
            errors={errors}
          >
            <InputFieldText.Label>Website</InputFieldText.Label>
          </InputFieldText>
          <Button
            onClick={async () => {
              try {
                setPrefillLoading(true);
                const formData = form.getValues();
                const data = await getPrefillData({
                  body: {
                    url: formData.website,
                  },
                });
                if (data.image) {
                  form.setValue('image', {
                    path: data.image,
                    type: 'image',
                  });
                }
                if (data.domainAuthority) {
                  form.setValue('domainAuthority', data.domainAuthority);
                }
                if (data.name && !formData.name) {
                  form.setValue('name', data.name);
                }
              } catch (error) {
                showError({
                  message: `Something went wrong`,
                });
              } finally {
                setPrefillLoading(false);
              }
            }}
          >
            {prefillLoading ? 'Loading...' : 'Prefill from website'}
          </Button>
          <InputFieldText
            {...register('domainAuthority')}
            errors={errors}
            type="number"
            step={1}
            min={0}
            max={100}
          >
            <InputFieldText.Label>Domain authority</InputFieldText.Label>
          </InputFieldText>
          <InputFieldUpload name="image" folder={UPLOAD_FOLDER} form={form}>
            <InputFieldUpload.Label>Image</InputFieldUpload.Label>
          </InputFieldUpload>
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button disabled={!isValid} submit form="publicationForm">
          Update publication
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminPublicationsEditPublicationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentPublication: PropTypes.object,
};

export default AdminPublicationsEditPublicationModal;
