const intents = {
  opportunities: {
    OPPORTUNITY: 'opportunity',
    CUSTOM_OPPORTUNITY: 'customOpportunity',
  },
  customThread: {
    CUSTOM_THREAD: 'customThread', // Deprecated
  },
  sweetheartList: {
    OP_ED: 'opEd',
    OUTREACH: 'outreach',
    PRESS_RELEASE: 'pressRelease',

    // Deprecated
    WARM_INTRO: 'warmIntro',
    COMMENT_QUOTE: 'commentQuote',
    EXPERT_OPINION: 'expertOpinion',
  },
};

export const legacyIntents = [
  'warmIntro',
  'commentQuote',
  'expertOpinion',
  'customThread',
];

export default intents;
