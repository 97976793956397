import { collection } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import {
  COMPANIES_OPPORTUNITIES_COLLECTION,
  COMPANIES_OPPORTUNITIES_DECLINED_COLLECTION,
} from 'constants/firebasePaths';

const useCompaniesOpportunitiesCollections = (uid) => {
  const firestore = useFirestore();

  const declinedCollection = collection(
    firestore,
    COMPANIES_OPPORTUNITIES_COLLECTION,
    uid,
    COMPANIES_OPPORTUNITIES_DECLINED_COLLECTION
  );

  return { declinedCollection };
};

export default useCompaniesOpportunitiesCollections;
