import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import ContentElementEmbargoDate from '../ContentElementEmbargoDate';
import ContentElementOther from '../ContentElementOther';
import ContentElementUploadFile from '../ContentElementUploadFile';

import ContentElementIPOCompanyName from './ContentElementIPOCompanyName';
import ContentElementIPOStockExchanges from './ContentElementIPOStockExchanges';

const ContentElementIPO = ({ index = 0, updateCallback = noop }) => (
  <div className="w-full space-y-5">
    <ContentElementIPOCompanyName index={index} />
    <ContentElementEmbargoDate index={index} />
    <ContentElementIPOStockExchanges
      index={index}
      updateCallback={updateCallback}
    />
    <ContentElementOther
      index={index}
      placeholder="Type any other relevant information about the IPO..."
    />
    <ContentElementUploadFile name="file" label="Add files" />
  </div>
);

ContentElementIPO.propTypes = {
  index: PropTypes.number,
  updateCallback: PropTypes.func,
};

export default ContentElementIPO;
