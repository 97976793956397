import PropTypes from 'prop-types';
import React from 'react';

// Vertical separator line separate form fields with an "AND" or "OR"
const SeparatorTextLine = ({ text = 'AND', classNames = '' }) => (
  <div
    className={`flex items-center justify-between min-w-full mt-2 mb-2 ${classNames}`}
  >
    <hr className="border-gray-400 block w-5/12" />
    <p className="text-gray-400">{text}</p>
    <hr className="border-gray-400 block w-5/12" />
  </div>
);

SeparatorTextLine.propTypes = {
  text: PropTypes.string,
  classNames: PropTypes.string,
};

export default SeparatorTextLine;
