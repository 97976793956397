import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import getContentType from 'constants/contentTypes';
import { useTracking } from 'modules/segment/TrackingProvider';

import getContentTypeAnalyticsEvents from '../helpers/getContentTypeAnalyticsEvents';

const CompanyContentDeleteModal = ({
  open = false,
  onClose = noop,
  content = {},
  ops = {},
}) => {
  const { label, Icon } = getContentType(content);
  const lowerCaseLabel = label.toLowerCase();

  const trackingService = useTracking();

  const deleteContent = async () => {
    trackingService.track(
      'pressPage_update',
      getContentTypeAnalyticsEvents(content, 'content_delete')
    );
    await ops.deleteDoc(content.uid);
    onClose();
  };
  return (
    <Modal open={open}>
      <Modal.Close srMessage="Close content delete modal" onClose={onClose} />
      <Modal.Icon Icon={Icon} />
      <Modal.Title>Delete {lowerCaseLabel}</Modal.Title>
      <Modal.Description>
        Are you sure you want to delete this <b>{lowerCaseLabel}</b>?
      </Modal.Description>
      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-content-addModal-cancel"
        >
          Cancel
        </Button>

        <Button
          onClick={deleteContent}
          eventType="pressPage_update"
          eventProperties={{ action: 'content_update' }}
          data-test-id="company-content-deleteModal-submit"
        >
          Delete {lowerCaseLabel}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyContentDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  ops: PropTypes.object.isRequired,
};

export default CompanyContentDeleteModal;
