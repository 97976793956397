// eslint-disable jsx-a11y/no-noninteractive-element-interactions

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

import Button from 'components/buttons/Button';

// Renders the search bar with a suggestions flyout
const SearchBar = ({
  value = '',
  onChange = noop,
  suggestions = [],
  onSelect = noop,
  showSuggestions = false,
  onBlur = noop,
  onFocus = noop,
}) => {
  const [highlightedIndex, setHighlitedIndex] = useState(-1);
  const onKeyDown = (event) => {
    // KeyCode 13 = Enter Key
    if (event.keyCode === 13) {
      onSelect(suggestions[highlightedIndex] || value);
    }
    // KeyCode 38 = Up
    if (event.keyCode === 38) {
      setHighlitedIndex((prev) => Math.max(prev - 1, -1));
    }
    // KeyCode 40 = Down
    if (event.keyCode === 40) {
      setHighlitedIndex((prev) => Math.min(prev + 1, suggestions.length - 1));
    }
  };

  return (
    <section className="flex space-x-3">
      <div className="flex-1 relative">
        <input
          type="text"
          autoComplete="off"
          value={value}
          className="mt-2 px-3 py-2 flex-1 block bg-white border-gray-300 focus:border-teal-500 focus:outline-none focus:ring-0 min-w-0 text-sm rounded-md shadow-sm w-full"
          name="press-page-search-bar"
          placeholder="Search..."
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={onKeyDown}
          onBlur={() => {
            onBlur();
            setHighlitedIndex(-1);
          }}
          onFocus={onFocus}
        />
        {showSuggestions && suggestions.length > 0 && (
          <ul className="absolute w-full z-50 py-2 border rounded-lg text-sm border-gray-200 bg-white">
            {suggestions.map((suggestion, index) => (
              <li key={suggestion}>
                <a
                  className={classNames(
                    'block w-full px-2 py-1 cursor-pointer hover:bg-gray-100',
                    { 'bg-gray-200': highlightedIndex === index }
                  )}
                  onClick={() => {
                    onChange(suggestion);
                    onSelect(suggestion);
                  }}
                >
                  {suggestion}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
      <Button className="my-2 w-40 text-center" onClick={() => onSelect(value)}>
        Search
      </Button>
    </section>
  );
};

SearchBar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  showSuggestions: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default SearchBar;
