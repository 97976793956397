import { COMPANIES_JOURNALIST_PATH as path } from 'constants/paths';

import JournalistPage from './JournalistPage';

const JournalistRoute = {
  path,
  authRequired: true,
  component: JournalistPage,
};

export default JournalistRoute;
