import PropTypes from 'prop-types';
import React from 'react';

import { Menu } from '@headlessui/react';
import noop from 'lodash/noop';

// Sort option displaying the option label
const OpportunitiesSortOption = ({ option = {}, onClick = noop }) => (
  <Menu.Item
    key={option.name}
    data-test-id={`opportunities-sortBy-${option.name}`}
    onClick={onClick}
  >
    <div className="flex items-center space-x-2 px-4 pr-2 py-2 text-sm text-gray-900 focus:outline-none hover:bg-gray-50 cursor-pointer">
      <option.Icon className="w-4" />
      <div className="w-32">{option.label}</div>
    </div>
  </Menu.Item>
);

OpportunitiesSortOption.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    Icon: PropTypes.elementType,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default OpportunitiesSortOption;
