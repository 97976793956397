import { ADMIN_OPPORTUNITIES_PATH as path } from 'constants/paths';

import OpportunitiesPage from './AdminOpportunitiesPage';

const AdminOpportunitiesRoute = {
  path,
  authRequired: true,
  claimsRequired: { admin: true },
  component: OpportunitiesPage,
};

export default AdminOpportunitiesRoute;
