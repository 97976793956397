import create from 'zustand';

import { defaultCompany, defaultUser } from 'constants/defaultData';

const localStorage = JSON.parse(sessionStorage.getItem('__ZUSTAND__') ?? '{}');

const useOnboardingStore = create((set) => ({
  company: localStorage?.company || defaultCompany,
  user: localStorage?.user || defaultUser,
  updateCompany: (payload) =>
    set((state) => ({ company: { ...state.company, ...payload } })),
  updateUser: (payload) =>
    set((state) => ({ user: { ...state.user, ...payload } })),
  resetCompanyAndUser: () =>
    set(() => ({
      company: defaultCompany,
      user: defaultUser,
    })),
}));

useOnboardingStore.subscribe(({ company, user }) => {
  sessionStorage.setItem('__ZUSTAND__', JSON.stringify({ company, user }));
});

export default useOnboardingStore;
