import PropTypes from 'prop-types';
import React from 'react';

import { RefreshIcon } from '@heroicons/react/solid';
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
} from 'firebase/firestore';
import noop from 'lodash/noop';
import { useParams } from 'react-router-dom';
import { useFirestore } from 'reactfire';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import {
  INBOXES_COLLECTION,
  INBOXES_THREADS_COLLECTION,
  INBOXES_THREADS_MESSAGES_COLLECTION,
} from 'constants/firebasePaths';
import { messageStatus } from 'constants/messageStatus';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';

const AdminMessagesResetThreadModal = ({ onClose = noop }) => {
  const { inboxId, threadId } = useParams();
  const { setDocument } = useFirestoreWrapper();
  const firestore = useFirestore();
  const threadRef = doc(
    firestore,
    INBOXES_COLLECTION,
    inboxId,
    INBOXES_THREADS_COLLECTION,
    threadId
  );

  const messagesRef = collection(
    firestore,
    threadRef.path,
    INBOXES_THREADS_MESSAGES_COLLECTION
  );
  const { showError, showSuccess } = useNotifications();
  return (
    <Modal open>
      <Modal.Title>Reset thread</Modal.Title>
      <Modal.Icon Icon={RefreshIcon} />
      <Modal.Description>
        <p>Are you sure you want to reset this thread?</p>
        <ul className="text-left mt-2">
          <li>- The bounced messages will be deleted</li>
          <li>- The delivered messages will be marked as unapproved</li>
          <li>
            - The previous thread in gmail will not continue, and a new one will
            be created
          </li>
        </ul>
      </Modal.Description>
      <Modal.RightButtons>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="danger"
          onClick={async () => {
            const messages = await getDocs(messagesRef);

            if (messages.docs.length > 2) {
              showError({
                title: "Can't reset thread",
                message:
                  'This thread has more than 2 messages, please reach out to dev team',
              });
              return;
            }

            setDocument(
              threadRef,
              {
                nylasThreadId: deleteField(),
              },
              { merge: true }
            );

            const bouncedMessages = messages.docs.filter(
              (message) => message.data().isBounced
            );
            const deliveredMessages = messages.docs.filter(
              (message) => message.data().status === messageStatus.DELIVERED
            );

            if (bouncedMessages.length > 0) {
              bouncedMessages.forEach((message) => {
                deleteDoc(message.ref);
              });
            }

            if (deliveredMessages.length > 0) {
              deliveredMessages.forEach((message) => {
                setDocument(
                  message.ref,
                  {
                    status: messageStatus.UNAPPROVED,
                    dateSent: deleteField(),
                    internal: deleteField(),
                    internalEmail: deleteField(),
                    nylasId: deleteField(),
                    opened: deleteField(),
                    openedDate: deleteField(),
                  },
                  { merge: true }
                );
              });
            }

            showSuccess({
              title: 'Thread reset',
              message: 'The thread was reset successfully',
            });
            onClose();
          }}
        >
          Reset
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminMessagesResetThreadModal.propTypes = {
  onClose: PropTypes.func,
};

export default AdminMessagesResetThreadModal;
