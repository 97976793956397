import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';

import OnboardingInputsContainer from 'components/OnboardingInputsContainer';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import InputFieldRadioStackedCards from 'components/form/inputFields/InputFieldRadioStackedCards/InputFieldRadioStackedCards';

import TitleSubtitle from '../TitleSubtitle';
import useOnboardingStore from '../stores/onboardingStores';

const optionsPrKnowledge = [
  'Almost nothing',
  'I know we need it, but not sure how it works',
  'I have worked in or with PR teams before',
  'I know about marketing more broadly',
  "I'm a PR professional",
];

const StepTwo = ({ availableSteps = [], onSubmit = noop, content = {} }) => {
  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    getValues,
  } = useForm({ mode: 'onChange' });

  const updateUser = useOnboardingStore((state) => state.updateUser);
  const prKnowledge = useOnboardingStore((state) => state.user.prKnowledge);

  const onChange = () => {
    updateUser(getValues());
  };

  return (
    <form
      className="relative z-10 flex flex-col items-center mx-4"
      onSubmit={handleSubmit(onSubmit)}
      onChange={onChange}
      data-test-id="onboarding-prKnowledge-form"
    >
      <OnboardingInputsContainer>
        <TitleSubtitle
          title="How much do you know about PR already?"
          subtitle="This helps us know how much help to give."
        />
        <Controller
          name="prKnowledge"
          control={control}
          defaultValue={prKnowledge}
          rules={{
            validate: (value) => value && value.length,
          }}
          render={({ field }) => (
            <InputFieldRadioStackedCards
              name="prKnowledge"
              options={optionsPrKnowledge}
              selected={field.value}
              onChange={field.onChange}
              testId="onboarding-prKnowledge"
            />
          )}
        />

        {errors.prKnowledge && (
          <ErrorMessage>{errors.prKnowledge.message}</ErrorMessage>
        )}
      </OnboardingInputsContainer>

      <div className="mb-8 flex items-center">
        <Button
          size="base"
          submit
          disabled={!isValid || !availableSteps.includes('three')}
          data-test-id="onboarding-prKnowledge-submit"
        >
          {content.submitText}
        </Button>
      </div>
    </form>
  );
};

StepTwo.propTypes = {
  availableSteps: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
};

export default StepTwo;
