import { ADMIN_CREATE_OPPORTUNITY_PATH as path } from 'constants/paths';

import AdminCreateOpportunity from './AdminCreateOpportunityPage';

const AdminCreateOpportunityRoute = {
  path,
  authRequired: true,
  claimsRequired: { admin: true },
  component: AdminCreateOpportunity,
};

export default AdminCreateOpportunityRoute;
