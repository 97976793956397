// ellipsize a string, return if not a string or first letter is uppercase
const ellipsize = (str = '') => {
  if (!str || typeof str !== 'string') {
    return str;
  }
  if (str[0] === str[0].toUpperCase()) {
    return str;
  }

  return `...${str}`;
};

export default ellipsize;
