import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  linkWithCredential,
} from 'firebase/auth';
import { useAuth } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import useUserData from 'contexts/UserContext/hooks/useUserData';

// sign up a user, converting anonymous users into authenticated ones
const useSignup = () => {
  const auth = useAuth();

  const { showError } = useNotifications();
  const userContext = useUserData();

  return async (formValues, e) => {
    e.preventDefault();
    e.stopPropagation();
    let res = null;

    // if anonymous user, link account
    if (userContext.isAnonymous) {
      try {
        const credentials = EmailAuthProvider.credential(
          formValues.email,
          formValues.password
        );
        res = await linkWithCredential(auth.currentUser, credentials);
      } catch (err) {
        showError({
          title: 'There was an issue creating the account',
          message: err.message,
        });
        userContext.setIsAuthenticating(false);
      }
    }

    // if NO anonymous user, create account
    else {
      try {
        res = await createUserWithEmailAndPassword(
          auth,
          formValues.email,
          formValues.password
        );
      } catch (err) {
        showError({
          title: 'There was an issue creating the account',
          message: err.message,
        });
        userContext.setIsAuthenticating(false);
      }
    }

    const uid = res?.user?.uid;

    userContext.setIsAuthenticating(false);
    if (!uid) {
      return false;
    }

    return res;
  };
};

export default useSignup;
