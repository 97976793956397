import PropTypes from 'prop-types';
import React, { useState } from 'react';

import classNames from 'classnames';
import truncate from 'lodash/truncate';

import ellipsize from 'utils/data/ellipsize';

const COLLAPSED_LENGTH = 120;

// Displays an opportunity's query text, can be shortened to 255 chars if collapsed
const OpportunityQuery = ({
  opportunityId = '',
  query = [],
  collapsed = false,
  canCollapse = false,
  testId = '',
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const collapsedText = ellipsize(
    truncate(query.join(' '), { length: COLLAPSED_LENGTH })
  );
  const needsCollapse = query.join().length > COLLAPSED_LENGTH && canCollapse;

  const className = 'mt-2 text-sm leading-relaxed';

  if (isCollapsed) {
    return (
      <p className={className} data-test-id="opportunity-query">
        <span className="mr-2">{collapsedText}</span>
        {needsCollapse && (
          <button
            type="button"
            onClick={() => setIsCollapsed(false)}
            className="text-teal-500"
          >
            Show more
          </button>
        )}
      </p>
    );
  }
  return (
    <div className={className} data-test-id={`${testId}-body`}>
      {query.map((paragraph, index) => {
        // need to construct a key from an index so that the react reconciler removes "empty" paragraphs again
        // eslint-disable-next-line react/no-array-index-key
        const key = `${opportunityId}-${paragraph}-${index}`;
        return (
          <p key={key} className={classNames({ 'mt-2': index !== 0 })}>
            <span className="mr-2">{paragraph}</span>
            {index === query.length - 1 && needsCollapse && (
              <button
                type="button"
                onClick={() => setIsCollapsed(true)}
                className="text-teal-500"
              >
                Show less
              </button>
            )}
          </p>
        );
      })}
    </div>
  );
};

OpportunityQuery.propTypes = {
  opportunityId: PropTypes.string,
  query: PropTypes.array,
  collapsed: PropTypes.bool,
  canCollapse: PropTypes.bool,
  testId: PropTypes.string,
};

export default OpportunityQuery;
