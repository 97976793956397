import PropTypes from 'prop-types';
import React from 'react';

import AgencyWrapperBreadcrumbs from './AgencyWrapperBreadcrumbs';

const AgencyWrapper = ({ children, title }) => (
  <main className="p-6">
    <div className="flex flex-col gap-2 mb-4">
      <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
      <AgencyWrapperBreadcrumbs />
    </div>
    <div>{children}</div>
  </main>
);

AgencyWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default AgencyWrapper;
