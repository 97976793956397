import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import IconCheckmark from 'components/icons/IconCheckmark';

import useScrollOnActive from './useScrollOnActive';

// Child element for InputFieldSelect dropdown list with multiple options
const InputFieldDropdownOption = ({
  option = '',
  isSelected = false,
  isActive = false,
  disabled = false,
  hasCheckbox = true,
}) => {
  const [parentRef, childRef] = useScrollOnActive(isActive);

  return (
    <div
      title={typeof option === 'function' ? undefined : option}
      className={classNames(
        'cursor-default select-none relative py-2 px-4 sm:pr-4',
        {
          'sm:pl-8': hasCheckbox,
          'text-white bg-teal-500': isActive,
          'text-gray-900': !isActive && !disabled,
          'text-gray-300': disabled,
        }
      )}
    >
      <div ref={parentRef} className="overflow-hidden">
        <span
          ref={childRef}
          className={classNames('translate-x-0 block text-left truncate', {
            'font-semibold': isSelected,
            'font-normal': !isSelected,
            'w-max whitespace-nowrap': isActive,
          })}
        >
          {typeof option === 'function' ? option(isActive) : option}
        </span>

        {isSelected && <IconCheckmark active={isActive} />}
      </div>
    </div>
  );
};

InputFieldDropdownOption.propTypes = {
  option: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
  ]).isRequired,
  isSelected: PropTypes.bool,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
};

export default InputFieldDropdownOption;
