// Returns the file type based on the MIME type: DOCS, PDF, JPG PNG, etc...
const getFileType = (file) => {
  if (!file || !file.file) {
    return '';
  }

  const parts = file.file.type.split('/');
  if (!parts || !parts[1]) {
    return '';
  }

  if (parts[1] === 'svg+xml') {
    return 'SVG';
  }

  if (
    parts[1] === 'vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    return 'DOCX';
  }

  return parts[1].toUpperCase();
};

export default getFileType;
