import PropTypes from 'prop-types';
import React from 'react';

import { useParams } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { tabNames } from 'constants/inboxTabs';

import createShowCalloutArray from '../helpers/createShowCalloutArray';
import getLastJournalistResponse from '../helpers/getLastJournalistResponse';
import useGetInboxMessagesError from '../hooks/useGetInboxMessagesError';
import useInboxMessagesByDate from '../hooks/useInboxMessagesByDate';
import useRedirectToLastMessageInThread from '../hooks/useRedirectToLastMessageInThread';

import InboxMessage from './InboxMessage/InboxMessage';
import InboxMessageJournalistResponse from './InboxMessage/InboxMessageJournalistResponse';
import InboxMessageResponseForm from './InboxMessage/InboxMessageResponseForm/InboxMessageResponseForm';
import InboxMessagesThreadDetail from './InboxMessageThreadDetail';
import InboxMessagesError from './InboxMessagesError';
import InboxMessagesInvoiceStatus from './InboxMessagesInvoiceStatus';
import InboxMessagesWrapper from './InboxMessagesWrapper';

const InboxMessages = ({ threadRef = {}, journalist = {} }) => {
  const { tab } = useParams();

  const { data: thread } = useFirestoreDocData(threadRef, defaultOptions);
  const { journalistResponse } = thread || {};
  const lastJournalistResponse = getLastJournalistResponse(journalistResponse);

  const messagesByDate = useInboxMessagesByDate(threadRef);
  const showCalloutArray = createShowCalloutArray(messagesByDate);

  useRedirectToLastMessageInThread(messagesByDate);

  const error = useGetInboxMessagesError({
    thread,
    messagesByDate,
  });

  if (error) {
    return <InboxMessagesError text={error} />;
  }

  const shouldRenderResponseForm = [tabNames.DRAFT, tabNames.ACTIVE].includes(
    tab
  );

  const wrapperClasses = 'flex-col rounded-t-none';

  return (
    <>
      <InboxMessagesThreadDetail thread={thread} journalist={journalist} />
      <InboxMessagesWrapper
        className={wrapperClasses}
        testId="inboxMessage-wrapper"
      >
        {lastJournalistResponse ? (
          <InboxMessageJournalistResponse
            journalistResponse={lastJournalistResponse}
            journalistName={journalist?.name}
          />
        ) : null}

        <InboxMessagesInvoiceStatus threadRef={threadRef} />

        {tab !== tabNames.DRAFT &&
          messagesByDate?.map((message, index) => (
            <InboxMessage
              key={message.uid}
              message={message}
              thread={thread}
              journalist={journalist}
              threadRef={threadRef}
              showCallout={showCalloutArray[index]}
              isLast={messagesByDate.length - 1 === index}
              isFirst={index === 0}
            />
          ))}

        {messagesByDate.length === 0 && (
          <div className="flex flex-col items-center justify-center p-4">
            <div className="text-gray-500 text-sm">No messages</div>
          </div>
        )}

        {shouldRenderResponseForm && (
          <InboxMessageResponseForm
            thread={thread}
            threadRef={threadRef}
            key={messagesByDate[messagesByDate.length - 1]?.uid}
          />
        )}
      </InboxMessagesWrapper>
    </>
  );
};

InboxMessages.propTypes = {
  threadRef: PropTypes.object,
  journalist: PropTypes.object,
};

export default InboxMessages;
