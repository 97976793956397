import PropTypes from 'prop-types';
import React from 'react';

import HtmlContainer from 'components/HtmlContainer';
// Displays an opportunity's description markup,
const OpportunityDescription = ({ descriptionHtml, testId = '' }) => (
  <div className="mt-2 text-sm leading-relaxed" data-test-id={`${testId}-body`}>
    <HtmlContainer htmlString={descriptionHtml} />
  </div>
);

OpportunityDescription.propTypes = {
  descriptionHtml: PropTypes.string,
  testId: PropTypes.string,
};

export default OpportunityDescription;
