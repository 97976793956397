import PropTypes from 'prop-types';
import React from 'react';

import { PlayIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

// A flexible play icon that can be shown as an overlay on top of a video
const VideoPlayIcon = ({ className = '' }) => (
  <div className="absolute z-10 inset-0 flex justify-center items-center">
    <div
      className={classNames(
        'flex justify-center items-center border-2 border-white bg-teal-500 hover:bg-teal-700 rounded-full cursor-pointer',
        className
      )}
    >
      <PlayIcon className="w-1/2 h-1/2 text-white" />
    </div>
  </div>
);

VideoPlayIcon.propTypes = {
  className: PropTypes.string,
};

export default VideoPlayIcon;
