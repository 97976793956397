// returns the localised currency value, e.g. `1.000,00`
const getLocalisedCurrencyValue = (value = 0, currencyName = '') => {
  if (!value) {
    return '0';
  }
  const formattedValue = new Intl.NumberFormat(navigator.language, {
    currencyName,
  }).format(value);
  return formattedValue;
};

export default getLocalisedCurrencyValue;
