import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const InboxMessageHeaderName = ({
  journalist = '',
  publication = '',
  isJournalist = false,
  isExpanded = false,
  isRead = true,
}) => {
  if (isJournalist) {
    return (
      <div>
        <p
          data-test-id="inboxMessage-fromJournalist"
          className={classNames('shrink-0 text-gray-700', {
            'font-normal': isRead,
            'font-bold': !isRead,
          })}
        >
          {journalist || 'Unknown'}
        </p>
        {isExpanded && (
          <p className="text-gray-400 text-sm">
            Writer{publication && `, ${publication}`}
          </p>
        )}
      </div>
    );
  }

  return (
    <p data-test-id="inboxMessage-fromUser" className="shrink-0 text-gray-700">
      You
    </p>
  );
};

InboxMessageHeaderName.propTypes = {
  journalist: PropTypes.string,
  publication: PropTypes.string,
  isJournalist: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isRead: PropTypes.bool,
};

export default InboxMessageHeaderName;
