import PropTypes from 'prop-types';
import React, { Suspense, useState } from 'react';

import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

import CompanyThreadAssignModalCompanySearch from './CompanyThreadAssignModalCompanySearch';
import CompanyThreadAssignModalThreads from './CompanyThreadAssignModalThreads';

const CompanyThreadAssignModal = ({
  isOpen = false,
  onClose = noop,
  onSubmit = noop,
  submitButtonText = 'Assign',
  title = 'Assign Orphan Message',
  isValid = () => false,
}) => {
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedThread, setSelectedThread] = useState({});

  return (
    <Modal open={isOpen}>
      <Modal.Title>{title}</Modal.Title>
      <Modal.Content>
        <CompanyThreadAssignModalCompanySearch
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
        <Suspense>
          {selectedCompany?.uid ? (
            <CompanyThreadAssignModalThreads
              company={selectedCompany}
              selectedThread={selectedThread}
              setSelectedThread={setSelectedThread}
            />
          ) : null}
        </Suspense>
      </Modal.Content>

      <Modal.RightButtons>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit(selectedCompany.uid, selectedThread.id)}
          disabled={!isValid(selectedCompany.uid, selectedThread.id)}
        >
          {submitButtonText}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyThreadAssignModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  submitButtonText: PropTypes.string,
  title: PropTypes.string,
  isValid: PropTypes.func,
};

export default CompanyThreadAssignModal;
