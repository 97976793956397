import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import noop from 'lodash/noop';

import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import { coreTopicsForUser } from 'constants/tags';
import useFormArray from 'hooks/useFormArray';

const validate = (value = []) => {
  if (value.length === 0) {
    return 'Please add at least one sector';
  }
  return null;
};

const ContentElementMASector = ({ index = 0, updateCallback = noop }) => {
  const name = 'sector';
  const {
    update,
    formApi: { formState, trigger, getValues },
  } = useFormArray({
    name,
    validate,
    updateCallback,
  });

  const error = get(formState.errors, name, null);

  return (
    <div className="w-full">
      <div className="space-y-2">
        <Label htmlFor={`${name}${index}`}>Sector*</Label>
        <InputFieldTagsAutocomplete
          onChange={(value) => {
            update(value);
            trigger(name);
          }}
          customFieldName={`${name}${index}`}
          buttonClassName="w-full"
          placeholder="i.e. Fintech, Digital Transformation..."
          suggestions={coreTopicsForUser.filter((topic) => topic.name)}
          value={getValues(name) || []}
          testId="company-content-m&a-inputSector"
        />
      </div>
      {error && (
        <ErrorMessage testId="company-content-m&a-inputSector-error">
          {error.message}
        </ErrorMessage>
      )}
    </div>
  );
};

ContentElementMASector.propTypes = {
  index: PropTypes.number,
  updateCallback: PropTypes.func,
};

export default ContentElementMASector;
