import PropTypes from 'prop-types';
import React from 'react';

import findIndex from 'lodash/findIndex';

import Tag from 'components/tags/Tag';
import { tagsInsights } from 'constants/tags';
import getTagColors from 'utils/color/getTagColors';

const CompanyInsightTag = ({ tag = {}, testId = '' }) => {
  const tagIndex = findIndex(tagsInsights, (t) => t.id === tag.id);

  const [bgColor, textColor] = getTagColors(tagIndex, tag.name?.length);

  return (
    <Tag
      bgColor={tag.name ? bgColor : 'gray-100'}
      textColor={tag.name ? textColor : 'gray-500'}
      data-test-id={testId}
    >
      {tag.name || 'Untagged'}
    </Tag>
  );
};

CompanyInsightTag.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  testId: PropTypes.string,
};

export default CompanyInsightTag;
