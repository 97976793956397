import successFeeStatuses from './successFeeStatuses';

const { DRAFT, DRAFTED, INVOICED, OPEN, PAID, UNSUCCESSFUL, ERROR } =
  successFeeStatuses;

const successFeeStatusConfigs = [
  {
    name: 'Drafted',
    condition: (status) => status === DRAFTED,
    textColor: 'gray-600',
    bgColor: 'gray-200',
    buttonText: 'Edit/send invoice',
    canBeMarkedUnsuccessful: true,
    canBeModified: true,
    sendInvoiceButtonText: 'Send invoice',
  },
  {
    name: 'Pending',
    condition: (status) => [INVOICED, DRAFT, OPEN].includes(status),
    textColor: 'yellow-600',
    bgColor: 'yellow-200',
    buttonText: 'View success fees',
    canBeMarkedUnsuccessful: false,
    canBeModified: false,
    sendInvoiceButtonText: 'Send invoice',
  },
  {
    name: 'Paid',
    condition: (status) => status === PAID,
    textColor: 'green-600',
    bgColor: 'green-200',
    buttonText: 'View success fees',
    canBeMarkedUnsuccessful: false,
    canBeModified: false,
    sendInvoiceButtonText: 'Invoice sent',
  },
  {
    name: 'Unsuccessful',
    condition: (status) => status === UNSUCCESSFUL,
    textColor: 'red-600',
    bgColor: 'red-200',
    buttonText: 'View success fees',
    canBeMarkedUnsuccessful: false,
    canBeModified: true,
    sendInvoiceButtonText: 'Send invoice',
  },
  {
    name: 'Error',
    condition: (status) => status === ERROR,
    textColor: 'red-600',
    bgColor: 'red-200',
    color: 'bg-red-200 text-red-600',
    buttonText: 'Add success fees',
    canBeMarkedUnsuccessful: true,
    canBeModified: true,
    sendInvoiceButtonText: 'Send invoice',
  },
  {
    name: null,
    condition: () => true,
    textColor: 'gray-600',
    bgColor: 'gray-200',
    buttonText: 'Add success fees',
    canBeMarkedUnsuccessful: true,
    canBeModified: true,
    sendInvoiceButtonText: 'Send invoice',
  },
];

export default successFeeStatusConfigs;
