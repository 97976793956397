import React from 'react';

import { SparklesIcon, TrashIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import useCreateSetState from '../hooks/useCreateSetState';
import useQueryParams from '../hooks/useQueryParams';

const smallScreenClasses =
  'flex justify-center sticky order-last bottom-0 p-4 bg-white w-[calc(100%+3rem)] -mb-6 -ml-6 z-10';
const largeScreenClasses =
  'lg:bg-transparent lg:w-full lg:pointer-events-none lg:p-0 lg:order-none lg:ml-0 lg:justify-end lg:-mb-[68px] lg:top-[88px] lg:mt-6 ';

const OpportunitiesListShowDeclined = () => {
  const { showDeclined } = useQueryParams();

  const setShowDeclined = useCreateSetState('showDeclined');

  return (
    <div className={classNames(smallScreenClasses, largeScreenClasses)}>
      <button
        type="button"
        data-test-id="opportunities-showDeclined"
        onClick={() => setShowDeclined(!showDeclined)}
        title={`Show ${showDeclined ? 'upcoming' : 'declined'} opportunities`}
        className={classNames(
          'rounded-md pointer-events-auto w-fit py-2 px-3 text-sm flex gap-1 items-center',
          {
            'text-gray-500 lg:bg-gray-100 lg:hover:bg-gray-50': !showDeclined,
            'text-teal-500 lg:bg-teal-100 lg:hover:bg-teal-50': showDeclined,
          }
        )}
      >
        {showDeclined ? (
          <SparklesIcon className="w-4 h-4" aria-hidden="true" />
        ) : (
          <TrashIcon className="w-4 h-4" aria-hidden="true" />
        )}
        <span className="inline lg:hidden xl:inline">
          Show {showDeclined ? 'upcoming' : 'declined'}
        </span>
      </button>
    </div>
  );
};

export default OpportunitiesListShowDeclined;
