import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { Controller } from 'react-hook-form';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import useDateTimeSelector from 'hooks/useDateTimeSelector';

const AdminAddOpportunityModalDeadline = ({
  control = {},
  isEvergreen = false,
  watch = {},
  setValue = {},
}) => {
  const dayName = 'deadline.day';
  const monthName = 'deadline.month';
  const yearName = 'deadline.year';
  const hourName = 'deadline.hour';

  const optionRanges = useDateTimeSelector({
    config: {
      type: 'future',
    },
    selection: {
      selectedHour: watch(hourName),
      selectedDay: watch(dayName),
      selectedMonth: watch(monthName),
      selectedYear: watch(yearName),
    },
    updateSelections: ({ day, month, year, hour }) => {
      setValue(dayName, day);
      setValue(monthName, month);
      setValue(yearName, year);
      setValue(hourName, hour);
    },
  });

  return (
    <div
      className={classNames('flex space-x-2', {
        'opacity-25 pointer-events-none': isEvergreen,
      })}
    >
      <Controller
        name={dayName}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputFieldDropdown
            options={optionRanges.days}
            onChange={onChange}
            value={value}
            buttonClassName="w-[calc(2ch+4rem)]"
          />
        )}
      />
      <Controller
        name={monthName}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputFieldDropdown
            options={optionRanges.months}
            onChange={onChange}
            value={value}
            buttonClassName="w-[calc(9ch+4rem)]"
          />
        )}
      />
      <Controller
        name={yearName}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputFieldDropdown
            options={optionRanges.years}
            onChange={onChange}
            value={value}
            buttonClassName="w-[calc(4ch+4rem)]"
          />
        )}
      />
      <Controller
        name={hourName}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputFieldDropdown
            options={optionRanges.hours}
            onChange={onChange}
            value={value}
            buttonClassName="w-[calc(5ch+4rem)]"
          />
        )}
      />
    </div>
  );
};

AdminAddOpportunityModalDeadline.propTypes = {
  control: PropTypes.object,
  isEvergreen: PropTypes.bool,
  watch: PropTypes.func,
  setValue: PropTypes.func,
};
export default AdminAddOpportunityModalDeadline;
