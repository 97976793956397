/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Listbox, Portal } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import noop from 'lodash/lodash';
import { usePopper } from 'react-popper';

import InputFieldDropdownOption from './InputFieldDropdownOption';

const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
};

// Dropdown select field to choose one option from a list
const InputFieldDropdown = ({
  sameWidthOption = true,
  optionWithCheckbox = true,
  options = [],
  onChange = noop,
  value = '',
  wrapperClassName = '',
  buttonClassName = 'w-24',
  optionClassName = '',
  optionsClassName = '',
  testId = '',
  intercomId = '',
  name = '',
  label = '',
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      sameWidthOption ? sameWidth : {},
    ],
  });

  return (
    <Listbox
      as="div"
      value={value}
      onChange={onChange}
      className={wrapperClassName}
    >
      {({ open }) => {
        const buttonLabel =
          options.find((option) => option?.value === value)?.label || value;
        const buttonChildren = (
          <span className="block truncate" data-test-id={`${testId}-value`}>
            {buttonLabel || <>&nbsp;</>}
          </span>
        );

        return (
          <div className="relative flex flex-col">
            {label && (
              <Listbox.Label className="block text-sm font-medium text-gray-700 cursor-pointer mb-2">
                {label}
              </Listbox.Label>
            )}
            <span className="block w-full rounded-md">
              <Listbox.Button
                id={name}
                className={classNames(
                  'focus:border-teal-500 flex gap-2 items-center cursor-default relative rounded-md border border-gray-300 bg-white text-left focus:outline-none text-sm sm:leading-5',
                  'pl-3 pr-9 py-2 shadow-sm',
                  buttonClassName
                )}
                onClick={(e) => e.stopPropagation()}
                ref={setReferenceElement}
                data-test-id={testId}
                data-intercom-id={intercomId}
              >
                {buttonChildren}
                <ChevronDownIcon className="w-4 h-4 text-gray-400 absolute right-3" />
              </Listbox.Button>
            </span>

            {open ? (
              <Portal ref={document.body}>
                <Listbox.Options
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                  static
                  className={classNames(
                    'bg-white max-h-60 rounded-md py-1 text-sm leading-6 overflow-auto focus:outline-none sm:leading-5 z-10 border-gray-300 border shadow-sm',
                    optionsClassName
                  )}
                >
                  {options.map((option) => {
                    const {
                      value: optionValue = option,
                      label: optionLabel = option,
                      disabled: optionDisabled = false,
                    } = typeof option === 'string' ? {} : option;

                    return (
                      <Listbox.Option
                        key={optionValue}
                        value={optionValue}
                        className={optionClassName}
                        disabled={optionDisabled}
                        data-test-id={`${testId}-${optionLabel}`}
                        data-intercom-id={`${intercomId}-${optionLabel}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {({ selected, active }) => (
                          <InputFieldDropdownOption
                            hasCheckbox={optionWithCheckbox}
                            option={optionLabel}
                            isSelected={selected}
                            isActive={active}
                            disabled={optionDisabled}
                          />
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Portal>
            ) : null}
          </div>
        );
      }}
    </Listbox>
  );
};

InputFieldDropdown.propTypes = {
  sameWidthOption: PropTypes.bool,
  optionWithCheckbox: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  wrapperClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  optionsClassName: PropTypes.string,
  testId: PropTypes.string,
  intercomId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default InputFieldDropdown;
