import { useEffect, useState } from 'react';

import { collection, getDocs } from 'firebase/firestore';
import debounce from 'lodash/debounce';
import { useFirestore } from 'reactfire';

import { COMPANIES_COLLECTION } from 'constants/firebasePaths';

const PAGE_SIZE = 5;
const paginate = (array = [], pageSize = 5) => {
  const pagesCount = Math.ceil(array.length / pageSize);
  const pages = [];
  for (let i = 0; i < pagesCount; i += 1) {
    pages.push(array.slice(i * pageSize, (i + 1) * pageSize));
  }
  return pages;
};

const getFilteredCompanies = async (firestore = {}, companyQuery = '') => {
  const data = (
    await getDocs(collection(firestore, COMPANIES_COLLECTION))
  ).docs.map((doc) => ({ ...doc.data(), uid: doc.id }));
  const filteredCompanies = data.filter((c) =>
    c?.name?.toLowerCase().includes(companyQuery?.toLowerCase())
  );

  return filteredCompanies;
};

const usePagination = (companyQuery = '') => {
  const firestore = useFirestore();
  const [pages, setPages] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const debouncedSearch = debounce(async () => {
      if (companyQuery) {
        // get filtered companies
        setIsFetching(true);
        const filteredCompanies = await getFilteredCompanies(
          firestore,
          companyQuery
        );
        // paginate the results
        const newPages = paginate(filteredCompanies, PAGE_SIZE);

        setCurrentPageNumber(0);
        setPages(newPages);
        setIsFetching(false);
      }
    }, 500);

    if (!companyQuery) {
      setCurrentPageNumber(0);
      setPages([]);
    }

    debouncedSearch();
    return () => debouncedSearch.cancel();
  }, [companyQuery, firestore]);

  return {
    nextPage: () => {
      if (currentPageNumber < pages.length - 1) {
        setCurrentPageNumber(currentPageNumber + 1);
      }
    },
    previousPage: () => {
      if (currentPageNumber > 0) {
        setCurrentPageNumber(currentPageNumber - 1);
      }
    },
    isFetching,
    isLast: currentPageNumber === pages.length - 1,
    isFirst: currentPageNumber === 0,
    currentPage: pages[currentPageNumber] || [],
  };
};

export default usePagination;
