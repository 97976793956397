import PropTypes from 'prop-types';
import React from 'react';

const ModalLeftButtons = ({ children = null, testId = '' }) => (
  <div className="flex gap-4" data-test-id={testId}>
    {children}
  </div>
);

ModalLeftButtons.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

export default ModalLeftButtons;
