import PropTypes from 'prop-types';
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import Tooltip from 'components/Tooltip/Tooltip';
import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import useDateTimeSelector from 'hooks/useDateTimeSelector';

const ContentElementEmbargoDate = ({ index = 0 }) => {
  const name = 'embargoDate';
  const dayName = `${name}.day`;
  const monthName = `${name}.month`;
  const yearName = `${name}.year`;
  const hourName = `${name}.hour`;

  const { control, watch, setValue } = useFormContext();

  const validOptionRanges = useDateTimeSelector({
    config: {
      type: 'future',
    },
    selection: {
      selectedHour: watch(hourName),
      selectedDay: watch(dayName),
      selectedMonth: watch(monthName),
      selectedYear: watch(yearName),
    },
    updateSelections: ({ day, month, year, hour }) => {
      setValue(dayName, day);
      setValue(monthName, month);
      setValue(yearName, year);
      setValue(hourName, hour);
    },
  });

  return (
    <div className="flex gap-2 flex-wrap">
      <div className="space-y-2 flex-col flex grow">
        <Label htmlFor={`${name}.day${index}`} className="flex items-center">
          Embargo Date*
          <Tooltip
            wrapperClassName="!inline-block"
            id="tooltip-contentEdit-general"
            copy="The embargo date tells a journalist the date and time after which they are allowed to publish the information you have shared with them in your press release. This allows you to share information with journalists ahead of time."
          />
        </Label>
        <div className="flex flex-wrap gap-2">
          <Controller
            id={`${dayName}${index}`}
            name={dayName}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputFieldDropdown
                options={validOptionRanges.days}
                onChange={onChange}
                value={value || ''}
                wrapperClassName="w-[calc(2ch+4rem)] grow"
                buttonClassName="w-full"
                testId="company-content-inputEmbargo-day"
              />
            )}
          />
          <Controller
            id={`${monthName}${index}`}
            name={monthName}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputFieldDropdown
                options={validOptionRanges.months}
                onChange={onChange}
                value={value || ''}
                wrapperClassName="w-[calc(9ch+4rem)] grow"
                buttonClassName="w-full"
                testId="company-content-inputEmbargo-month"
              />
            )}
          />
          <Controller
            id={`${yearName}${index}`}
            name={yearName}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputFieldDropdown
                options={validOptionRanges.years}
                onChange={onChange}
                value={value || ''}
                wrapperClassName="w-[calc(4ch+4rem)] grow"
                buttonClassName="w-full"
                testId="company-content-inputEmbargo-year"
              />
            )}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 w-[calc(4ch+4rem)] grow">
        <Controller
          id={`${hourName}${index}`}
          name={hourName}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputFieldDropdown
              options={validOptionRanges.hours}
              onChange={onChange}
              value={value || ''}
              buttonClassName="w-full"
              label="Hour*"
              testId="company-content-inputEmbargo-hour"
            />
          )}
        />
      </div>
    </div>
  );
};

ContentElementEmbargoDate.propTypes = {
  index: PropTypes.number,
};

export default ContentElementEmbargoDate;
