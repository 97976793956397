import PropTypes from 'prop-types';
import React from 'react';

import { useFormContext } from 'react-hook-form';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const ContentElementMAAcquirer = ({ index = 0 }) => {
  const name = 'companyAcquiringName';
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputFieldText
      id={`${name}${index}`}
      {...register(name, {
        required: {
          value: true,
          message: 'Please provide the name of the acquiring company',
        },
      })}
      testId="company-content-m&a-inputCompanyAcquiringCompany"
      errors={errors}
      placeholder="Type the name of the acquiring company..."
    >
      <InputFieldText.Label>Name of the acquirer company*</InputFieldText.Label>
    </InputFieldText>
  );
};

ContentElementMAAcquirer.propTypes = {
  index: PropTypes.number,
};

export default ContentElementMAAcquirer;
