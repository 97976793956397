import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import Avatar from 'components/Avatar';
import { ReactComponent as IconLinkedIn } from 'static/icon-linkedin.svg';

// Renders the clickable founders section in search results
const JournalistFounder = ({ founder = {} }) => (
  <button
    type="button"
    className={classNames('flex space-x-2 p-2', {
      'hover:bg-gray-100': founder.linkedInHandle,
    })}
    onClick={(e) => {
      if (founder.linkedInHandle) {
        e.stopPropagation();
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        window.open(
          `https://linkedin.com/in/${founder.linkedInHandle}`,
          '__blank'
        );
      }
    }}
  >
    <Avatar className="w-14 h-14" image={founder.image} alt={founder.name} />
    <div className="flex flex-col justify-center items-start text-xs max-w-full truncate text-left">
      <h4 className="font-medium text-gray-700 truncate w-full">
        {founder.name}
      </h4>
      <p className="text-gray-400">{founder.position}</p>
      {founder.linkedInHandle && (
        <IconLinkedIn className="mt-1 text-blue-500" />
      )}
    </div>
  </button>
);

JournalistFounder.propTypes = {
  founder: PropTypes.shape({
    uid: PropTypes.string,
    image: PropTypes.object,
    name: PropTypes.string,
    position: PropTypes.string,
    linkedInHandle: PropTypes.string,
  }),
};

export default JournalistFounder;
