import { PropTypes } from 'prop-types';
import { useEffect } from 'react';

import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import {
  OPPORTUNITIES_NOTIFICATIONS,
  OPPORTUNITIES_NOTIFICATIONS_COMPANIES_VISITED,
} from 'constants/firebasePaths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

const OpportunitiesListResponseModalTrackVisit = ({ uid = '' }) => {
  const firestore = useFirestore();
  const { companyData } = useCompanyData();

  const ref = doc(
    firestore,
    OPPORTUNITIES_NOTIFICATIONS,
    uid,
    OPPORTUNITIES_NOTIFICATIONS_COMPANIES_VISITED,
    companyData.uid
  );

  useEffect(() => {
    (async () => {
      const visited = await getDoc(ref);
      if (visited.exists()) {
        return;
      }

      await setDoc(ref, {
        dateCreated: new Date(),
        companyId: companyData.uid,
      });
    })();
  }, [companyData.uid, ref, uid]);

  return null;
};

OpportunitiesListResponseModalTrackVisit.propTypes = {
  uid: PropTypes.string,
};

export default OpportunitiesListResponseModalTrackVisit;
