export const title = {
  display: false,
};

export const tooltip = (getLabel) => ({
  enabled: true,
  callbacks: {
    label: getLabel,
  },
});

// eslint-disable-next-line max-params
export const sortLabels = (a, b, chart) => {
  const aValue = chart.datasets[0].data[a.datasetIndex];
  const bValue = chart.datasets[0].data[b.datasetIndex];

  if (a.text.split(' ')[0] === 'Other') return 1;
  if (b.text.split(' ')[0] === 'Other') return -1;

  return bValue - aValue;
};

export const legend = {
  display: true,
  position: 'right',
  align: 'center',
  fullSize: true,
  labels: {
    generateLabels: (chart) => {
      const { datasets, labels } = chart.data;
      const { backgroundColor, data } = datasets[0];

      return labels.map((label, i) => {
        let truncatedLabel = label;
        if (label.length > 25) {
          truncatedLabel = `${label.substring(0, 22)}...`;
        }

        return {
          text: `${truncatedLabel} (${data[i]})`,
          fillStyle: backgroundColor[i],
          strokeStyle: backgroundColor[i],
          datasetIndex: i,
          fontColor: '#374151',
        };
      });
    },
    sort: sortLabels,
  },
};
