import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import {
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { useFirestore } from 'reactfire';

import {
  COMPANIES_COLLECTION,
  INBOXES_THREADS_COLLECTION,
} from 'constants/firebasePaths';
import useImpersonation from 'contexts/ImpersonationContext/hooks/useImpersonation';

const InboxAutoImpersonate = ({ children = null }) => {
  const { setImpersonatedCompanySlug } = useImpersonation();
  const { threadId } = useParams();
  const firestore = useFirestore();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!threadId) {
      setIsLoaded(true);
      return;
    }

    (async () => {
      const threads = await getDocs(
        query(
          collectionGroup(firestore, INBOXES_THREADS_COLLECTION),
          where('uid', '==', threadId)
        )
      );

      if (!threads.docs.length) {
        setIsLoaded(true);
        return;
      }

      const companyId = threads.docs[0].ref.parent.parent.id;
      const companyRef = doc(firestore, COMPANIES_COLLECTION, companyId);
      const companyDoc = await getDoc(companyRef);
      const data = companyDoc.data();

      if (!data) {
        setIsLoaded(true);
        return;
      }

      setImpersonatedCompanySlug(data.slug);
      setIsLoaded(true);
    })();
  }, [firestore, isLoaded, setImpersonatedCompanySlug, threadId]);

  if (!isLoaded) {
    return null;
  }

  return children;
};

InboxAutoImpersonate.propTypes = {
  children: PropTypes.node,
};

export default InboxAutoImpersonate;
