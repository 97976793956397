import getCurrencySymbol from './getCurrencySymbol';
import getLocalisedCurrencyValue from './getLocalisedCurrencyValue';

// gets the formatted and localised value with symbol, e.g. `€1.000,00`
const getFormattedCurrencyValue = (value = 0, currencyName = '') => {
  const symbol = getCurrencySymbol(currencyName);
  return `${symbol}${getLocalisedCurrencyValue(value, currencyName)}`;
};

export default getFormattedCurrencyValue;
