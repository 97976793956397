import React from 'react';

import useActiveSubscriptionWithTierFormatted from 'hooks/useActiveSubscriptionWithTierFormatted';

import BillingSettingsDangerZone from './BillingSettingsDangerZone/BillingSettingsDangerZone';
import BillingSettingsInvoices from './BillingSettingsInvoices/BillingSettingsInvoices';
import BillingSettingsPayment from './BillingSettingsPayment/BillingSettingsPayment';
import BillingSettingsSubscription from './BillingSettingsSubscription/BillingSettingsSubscription';
import BillingSettingsSuccessFees from './BillingSettingsSuccessFees/BillingSettingsSuccessFees';

const BillingSettings = () => {
  const subscriptionData = useActiveSubscriptionWithTierFormatted();
  if (!subscriptionData) {
    return null;
  }
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-6 lg:flex-row">
        <BillingSettingsSubscription subscriptionData={subscriptionData} />
        <BillingSettingsPayment />
      </div>
      <BillingSettingsInvoices subscriptionData={subscriptionData} />
      <BillingSettingsSuccessFees />
      {!subscriptionData?.willCancel && (
        <BillingSettingsDangerZone subscriptionData={subscriptionData} />
      )}
    </div>
  );
};

export default BillingSettings;
