import { useContext, useEffect, useState } from 'react';

import noop from 'lodash/noop';

import { OpportunitiesContext } from 'contexts/OpportunitiesContext/OpportunitiesContext';

import getSearchSuggestions from '../helpers/getSearchSuggestions';

// retrive suggestions from the search eindpoint for a given query
const useOpportunitiesSearchSuggestions = (searchQuery = '') => {
  const { idToken } = useContext(OpportunitiesContext);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!searchQuery || !idToken) {
      setIsLoading(false);
      setSuggestions([]);
      return noop;
    }

    const controller = new AbortController();

    (async () => {
      setIsLoading(true);

      try {
        setSuggestions(
          await getSearchSuggestions({
            searchQuery,
            idToken,
            controller,
          })
        );
        setIsLoading(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          setIsLoading(false);
        }
      }
    })();

    return () => controller.abort();
  }, [searchQuery, idToken]);

  return [suggestions, isLoading];
};

export default useOpportunitiesSearchSuggestions;
