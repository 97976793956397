import { useEffect, useState } from 'react';

import { collection } from 'firebase/firestore';
import { useAuth, useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_SEARCH_COLLECTION } from 'constants/firebasePaths';

const SEARCH_URL = process.env.REACT_APP_SEARCH_URL;

// retrieve all the opportunities and the companies that can find them in their search
const useReverseOpportunitySearch = () => {
  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const firestore = useFirestore();
  const auth = useAuth();

  // collect all the companies
  const companiesSearchCollection = collection(
    firestore,
    COMPANIES_SEARCH_COLLECTION
  );

  const { data: companiesSearchData } = useFirestoreCollectionData(
    companiesSearchCollection,
    defaultOptions
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      const jwt = await auth.currentUser?.getIdToken();
      try {
        const response = await fetch(
          `${SEARCH_URL}/search/v2/opportunities/reverse`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
            method: 'POST',
            body: JSON.stringify({ companies: companiesSearchData }),
          }
        );

        const data = await response.json();
        setResult(data);

        setError(false);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [auth]);

  return {
    error,
    loading,
    result,
  };
};

export default useReverseOpportunitySearch;
