import PropTypes from 'prop-types';
import React, { createRef, useMemo } from 'react';

import noop from 'lodash/noop';

import useUserData from 'contexts/UserContext/hooks/useUserData';
import filterContentForPublic from 'utils/data/filterContentForPublic';

const CompanySectionRefs = ({
  showOwnerView = false,
  cruds = {},
  children = noop,
}) => {
  const { claimsAdmin: isAdmin, claimsJournalist: isJournalist } =
    useUserData();
  const showAllContent = isAdmin || isJournalist || showOwnerView;

  const sectionRefs = useMemo(() => {
    const asArray = [];
    const byKey = {};

    // Find non empty sections
    let keys = Object.keys(cruds);
    const isNotEmpty = (key) => cruds[key]?.[1]?.length > 0;

    // Check if there are public quotes
    const hasPublicQuotes = cruds.quotes[1].some((quote) => !quote.private);
    const removeQuotes = (key) => key !== 'quotes';

    // Filter content for public - remove content with future embargo dates
    const contentFilteredForPublic = cruds.content[1].filter(
      filterContentForPublic
    );
    const hasContentForPublic = contentFilteredForPublic.length > 0;
    const removeContent = (key) => key !== 'content';

    if (!showOwnerView) {
      keys = hasPublicQuotes
        ? keys.filter(isNotEmpty)
        : keys.filter(isNotEmpty).filter(removeQuotes);
    }

    if (!showAllContent) {
      keys = hasContentForPublic ? keys : keys.filter(removeContent);
    }

    keys.forEach((key) => {
      const ref = createRef();
      asArray.push(ref);
      byKey[key] = ref;
    });

    return { asArray, byKey };
  }, [cruds, showAllContent, showOwnerView]);
  return (
    <div className="w-full md:w-[calc(100%-20rem)] flex flex-col">
      {children(sectionRefs.asArray, sectionRefs.byKey)}
    </div>
  );
};

CompanySectionRefs.propTypes = {
  showOwnerView: PropTypes.bool.isRequired,
  cruds: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
};

export default CompanySectionRefs;
