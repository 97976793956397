import { ADMIN_PUBLICATIONS_PATH as path } from 'constants/paths';

import AdminPublicationsPage from './AdminPublicationsPage';

const AdminPublicationsRoute = {
  path,
  authRequired: true,
  claimsRequired: { admin: true },
  component: AdminPublicationsPage,
};

export default AdminPublicationsRoute;
