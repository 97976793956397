import PropTypes from 'prop-types';
import React from 'react';

// Displays a (clamped) video title that can be rendered on top of a video
const VideoTitle = ({ title }) => (
  <h3
    className="text-white font-semibold text-sm sm:text-lg md:text-xl w-3/4 line-clamp-2"
    data-test-id={`company-video-${title}-title`}
  >
    {title}
  </h3>
);

VideoTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default VideoTitle;
