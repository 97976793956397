import { useEffect } from 'react';

import useQuery from 'hooks/useQuery';

import useOnboardingStore from '../stores/onboardingStores';

// Sets state's promoCode to the promoCode search param and return it from state
const usePromoCode = () => {
  const query = useQuery();
  const updateCompany = useOnboardingStore((state) => state.updateCompany);
  const promoCodeState = useOnboardingStore((state) => state.company.promoCode);

  useEffect(() => {
    const promoCode = query.get('promoCode');
    if (promoCode) {
      updateCompany({ promoCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return promoCodeState;
};

export default usePromoCode;
