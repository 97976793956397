import PropTypes from 'prop-types';
import React from 'react';

import {
  HomeIcon,
  NewspaperIcon,
  SpeakerphoneIcon,
} from '@heroicons/react/outline';
import { generatePath } from 'react-router-dom';

import {
  AGENCY_DASHBOARD_PATH,
  COMPANY_PATH,
  OPPORTUNITIES_PATH,
  SWEETHEART_PATH,
} from 'constants/paths';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import { ReactComponent as SweetheartListIcon } from 'static/icon-sweetheart-list.svg';

import AsideLink from './AsideLink';

/**
 * Aside navigation with options for logged in users
 * Highlights the current option as selected
 */
const Aside = ({ slug = null }) => {
  const { claimsAgencyId } = useUserData();

  return (
    <aside
      className="hidden sm:flex fixed top-0 mt-16 left-0 min-h-screen"
      aria-label="Sidebar"
    >
      <div className="bg-white p-2 space-y-2 border-r border-gray-300">
        {claimsAgencyId && (
          <AsideLink
            path={generatePath(AGENCY_DASHBOARD_PATH, {
              agencyId: claimsAgencyId,
            })}
            label="Dashboard"
            testId="aside-pressPage"
          >
            <HomeIcon className="h-6 w-6" />
          </AsideLink>
        )}
        <AsideLink
          path={generatePath(COMPANY_PATH, { id: slug })}
          label="Press Page"
          testId="aside-pressPage"
        >
          <NewspaperIcon className="h-6 w-6" />
        </AsideLink>

        <AsideLink
          path={OPPORTUNITIES_PATH}
          label="Media Opportunities"
          testId="aside-opportunities"
        >
          <SpeakerphoneIcon className="h-6 w-6 p-px" />
        </AsideLink>

        <AsideLink
          path={generatePath(SWEETHEART_PATH)}
          label="Sweetheart List"
          testId="aside-sweetheart"
        >
          <SweetheartListIcon className="h-6 w-6 p-px" />
        </AsideLink>
      </div>
    </aside>
  );
};

Aside.propTypes = {
  slug: PropTypes.string,
};

export default Aside;
