import React, { useState } from 'react';

import Button from 'components/buttons/Button';

import SweetheartListAddJournalistModal from '../SweetheartListJournalist/SweetheartListAddJournalistModal/SweetheartListAddJournalistModal';

const SweetheartListHeaderAdminAddJournalist = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button type="primary" onClick={() => setModalOpen(true)}>
        Add Journalist
      </Button>

      {isModalOpen ? (
        <SweetheartListAddJournalistModal onAbort={() => setModalOpen(false)} />
      ) : null}
    </>
  );
};

export default SweetheartListHeaderAdminAddJournalist;
