import PropTypes from 'prop-types';
import React from 'react';

import Avatar from '../../Avatar';

import usePhotoUrl from './hooks/usePhotoUrl';

// Displays an rounded avatar image
const UserAvatar = ({
  image = '',
  alt = 'User profile photo thumbnail',
  ...props
}) => {
  const userPhotoUrl = usePhotoUrl();

  return (
    <Avatar alt={alt} image={image || { path: userPhotoUrl }} {...props} />
  );
};

UserAvatar.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  alt: PropTypes.string,
};
export default UserAvatar;
