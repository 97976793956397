import PropTypes from 'prop-types';
import React from 'react';

import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';

const JournalistFiltersTopic = ({
  name = '',
  selectedTopics = [],
  topics = [],
  onChange = noop,
}) => (
  <Disclosure defaultOpen as="div" className="border-b border-gray-200 py-6">
    {({ open }) => (
      <>
        <h3 className="-my-3 flow-root">
          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
            <span className="font-medium text-gray-900">{name}</span>
            <span className="ml-6 flex items-center">
              {open ? (
                <MinusIcon className="h-5 w-5" aria-hidden="true" />
              ) : (
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
              )}
            </span>
          </Disclosure.Button>
        </h3>
        <Disclosure.Panel className="pt-6">
          <div className="space-y-4">
            <InputFieldTagsAutocomplete
              customFieldName="topic"
              suggestions={topics}
              value={selectedTopics}
              placeholder="Add topic"
              onChange={(newTopics) =>
                onChange(
                  newTopics.filter((t) =>
                    topics.map((c) => c.name).includes(t.name)
                  )
                )
              }
            />
          </div>
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

JournalistFiltersTopic.propTypes = {
  name: PropTypes.string,
  selectedTopics: InputFieldTagsAutocomplete.propTypes.value,
  topics: InputFieldTagsAutocomplete.propTypes.suggestions,
  onChange: PropTypes.func,
};

export default JournalistFiltersTopic;
