import { useCallback, useEffect, useState } from 'react';

import { useAuth } from 'reactfire';

const UCD_URL = process.env.REACT_APP_UCD_URL;
const JPD_URL = process.env.REACT_APP_JPD_URL;
const MOD_URL = process.env.REACT_APP_MOD_URL;

const getBaseURL = (service = '') => {
  switch (service) {
    case 'MOD':
      return MOD_URL;
    case 'JPD':
      return JPD_URL;
    case 'UCD':
      return UCD_URL;
    default:
      throw new Error("Service must be one of 'MOD', 'JPD', or 'UCD'");
  }
};

/**
 * @param {Object} params
 * @param {("GET"|"POST"|"PUT"|"DELETE")} params.method
 * @param {Object} params.body
 * @param {string} params.endpoint
 * @param {("MOD"|"JPD"|"UCD")} params.service
 * @param {any} params.initialData
 */
const useAPIRequest = (params = {}) => {
  const {
    method: initialMethod = 'GET',
    body: initialBody,
    endpoint: initialEndpoint,
    autoFetch = true,
    initialData = {},
    service,
  } = params;

  const BASE_URL = getBaseURL(service);

  const auth = useAuth();
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(
    async (fetchParams = {}) => {
      const {
        endpoint = initialEndpoint,
        body = initialBody,
        method = initialMethod,
      } = fetchParams;

      setLoading(true);
      setError(false);
      setStatus(null);

      const jwt = await auth.currentUser?.getIdToken();
      try {
        const response = await fetch(`${BASE_URL}${endpoint}`, {
          method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
          body: body ? JSON.stringify(body) : null,
        });

        const responseData = await response.json();

        if (!response?.ok) {
          setStatus(response.status);
          throw new Error(responseData?.msg || 'API call failed');
        }

        setData(responseData);
        setLoading(false);

        return responseData;
      } catch (err) {
        setError(true);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [auth.currentUser, initialBody, initialMethod, initialEndpoint, BASE_URL]
  );

  useEffect(() => {
    (async () => {
      if (autoFetch && initialMethod === 'GET') {
        try {
          await fetchData();
        } catch (_) {
          // swallow it and expect the components to use the error state
        }
      }
    })();
  }, [fetchData, autoFetch, initialMethod]);

  return { loading, error, status, data, fetchData };
};

export default useAPIRequest;
