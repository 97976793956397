import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

// Visual component realizing a "Card" layout
const Card = ({
  title = '',
  subtitle = '',
  type = 'default',
  Action = null,
  children = null,
  className = '',
}) => (
  <div className={className}>
    <section
      className={classNames(
        'flex flex-col bg-white rounded-md shadow h-full divide-y'
      )}
    >
      <div className="flex flex-row justify-between items-center p-5">
        <div>
          <h4
            className={classNames('text-lg font-semibold', {
              'text-gray-900': type === 'default',
              'text-red-500': type === 'danger',
              'text-teal-500': type === 'primary',
            })}
          >
            {title}
          </h4>
          {subtitle && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
        </div>
        {Action}
      </div>
      <div className="flex flex-1 p-5">{children}</div>
    </section>
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.oneOf(['default', 'primary', 'danger']),
  Action: PropTypes.node,
  className: PropTypes.string,
};

export default Card;
