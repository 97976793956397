import { useEffect, useRef, useState } from 'react';

import { doc, arrayUnion } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import {
  INBOXES_COLLECTION,
  INBOXES_THREADS_COLLECTION,
} from 'constants/firebasePaths';

import useFirestoreWrapper from './useFirestoreWrapper';

const useSaveJournalistResponse = (companyUid = '', query = {}) => {
  const firestore = useFirestore();
  const { updateDocument } = useFirestoreWrapper();
  const [modalNameToShow, setModalNameToShow] = useState(null);
  const threadId = query.get('thread');
  const journalistResponse = query.get('journalistResponse');
  const hasSavedJournalistResponse = useRef(false);

  useEffect(() => {
    (async () => {
      if (!threadId || !journalistResponse) {
        setModalNameToShow(null);
        return;
      }

      if (!hasSavedJournalistResponse.current) {
        const threadDoc = doc(
          firestore,
          INBOXES_COLLECTION,
          companyUid,
          INBOXES_THREADS_COLLECTION,
          threadId
        );

        await updateDocument(threadDoc, {
          journalistResponse: arrayUnion({
            value: journalistResponse,
            createdAt: new Date(),
          }),
        });

        hasSavedJournalistResponse.current = true;
      }

      setModalNameToShow(journalistResponse);
    })();
  }, [threadId, journalistResponse, firestore, companyUid, updateDocument]);

  return modalNameToShow;
};

export default useSaveJournalistResponse;
