import React, { useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import Card from 'components/Card';
import Button from 'components/buttons/Button';
import { SETTINGS_BILLING_PATH } from 'constants/paths';
import useActiveSubscriptionWithTierFormatted from 'hooks/useActiveSubscriptionWithTierFormatted';

import UserSettingsDangerZoneConfirmationModal from './UserSettingsDangerZoneConfirmationModal';
import UserSettingsDangerZoneErrorModal from './UserSettingsDangerZoneErrorModal';

const UserSettingsDangerZone = () => {
  const subscriptionData = useActiveSubscriptionWithTierFormatted();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const deletionIsBlocked = subscriptionData && !subscriptionData.willCancel;

  const onConfirm = () =>
    navigate({
      pathname: '/deleteAccount',
      search: '?skipRoot=true',
    });

  return (
    <Card type="danger" title="Danger Zone">
      <div className="w-full flex flex-col gap-5">
        <div className="flex flex-col text-sm text-gray-500 gap-2">
          {deletionIsBlocked && (
            <p className="text-gray-900">
              If you would like to delete your account please{' '}
              <Link
                className="text-teal-500 hover:underline"
                to={`../${SETTINGS_BILLING_PATH}`}
              >
                cancel your subscription
              </Link>{' '}
              first.
            </p>
          )}
          {!deletionIsBlocked && (
            <p className="text-gray-900">
              Deleting your account will permanently remove all data associated
              with your account including your company profile.
            </p>
          )}
        </div>
        <Button
          disabled={deletionIsBlocked}
          type="secondary"
          className="w-fit"
          onClick={() => setShowConfirmationModal(true)}
        >
          Delete Account
        </Button>
      </div>

      <UserSettingsDangerZoneConfirmationModal
        open={showConfirmationModal}
        onConfirm={onConfirm}
        onAbort={() => setShowConfirmationModal(false)}
      />

      <UserSettingsDangerZoneErrorModal
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
    </Card>
  );
};

UserSettingsDangerZone.propTypes = {};

export default UserSettingsDangerZone;
