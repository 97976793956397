import React, { useState } from 'react';

import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { collection } from 'firebase/firestore';
import orderBy from 'lodash/orderBy';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { SUCCESS_FEES_COLLECTION } from 'constants/firebasePaths';

import useSuccessFeesWithCompanies from '../hooks/useSuccessFeesWithCompanies';

import AdminSuccessFeesTableRow from './AdminSuccessFeesTableRow/AdminSuccessFeesTableRow';

const AdminSuccessFeesTable = () => {
  const [orderKey, setOrderKey] = useState('date');
  const [orderDirection, setOrderDirection] = useState('asc');
  const firestore = useFirestore();
  const { data: successFees } = useFirestoreCollectionData(
    collection(firestore, SUCCESS_FEES_COLLECTION),
    defaultOptions
  );

  const successFeesWithCompany = useSuccessFeesWithCompanies(successFees);

  // representing dates as their negative number of seconds so that they have the same ordering semantic as letters
  const getSortableProperty = (successFee) =>
    orderKey === 'company'
      ? successFee?.company?.name?.toLowerCase()
      : -(successFee?.date?.seconds || -1);
  const orderedSuccessFeesWithCompany = orderBy(
    successFeesWithCompany,
    getSortableProperty,
    orderDirection
  );

  const changeOrder = (key) => {
    if (key === orderKey) {
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderKey(key);
    }
  };

  const Chevron = orderDirection === 'asc' ? ChevronDownIcon : ChevronUpIcon;

  return (
    <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200 text-gray-500">
        <thead className="bg-gray-50 text-xs font-medium uppercase">
          <tr className="text-left tracking-wider">
            <th
              scope="col"
              className="px-4 py-2 flex flex-row align-center cursor-pointer"
              onClick={() => changeOrder('company')}
            >
              Company
              {orderKey === 'company' && <Chevron className="ml-1 h-4 w-4" />}
            </th>
            <th scope="col" className="px-4 py-2 max-w-sm">
              Article title
            </th>
            <th scope="col" className="px-4 py-2">
              Product/Tier
            </th>
            <th
              scope="col"
              className="px-4 py-2 flex flex-row align-center cursor-pointer"
              onClick={() => changeOrder('date')}
            >
              Date
              {orderKey === 'date' && <Chevron className="ml-1 h-4 w-4" />}
            </th>
            <th scope="col" className="px-4 py-2">
              Status
            </th>
            <th scope="col" className="px-4 py-2">
              Receipt
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 text-sm">
          {orderedSuccessFeesWithCompany?.map((successFee) => {
            const { companyId, uid } = successFee;
            if (!companyId || !uid) {
              return null;
            }

            return (
              <AdminSuccessFeesTableRow key={uid} successFee={successFee} />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminSuccessFeesTable;
