import PropTypes from 'prop-types';
import React from 'react';

import findIndex from 'lodash/findIndex';
import noop from 'lodash/lodash';

import Tag from 'components/tags/Tag';
import getTagColors from 'utils/color/getTagColors';

// Creates an input field tag where the tag classes are based on the tagsIndex array (if available) or tags array
const createInputFieldTagsAutocompleteTag = (tags = [], tagsIndex = null) => {
  const InputFieldTagsAutocompleteTag = ({
    tag = {},
    removeButtonText = '',
    onDelete: onClick = noop,
  }) => {
    const [bgColor, textColor] = getTagColors(
      findIndex(tagsIndex || tags, (t) => t.id === tag.id),
      tag.name?.length
    );
    return (
      <Tag bgColor={bgColor} textColor={textColor}>
        {tag.name}{' '}
        <span
          className="ml-1 cursor-pointer hover:opacity-50"
          title={removeButtonText}
          onClick={onClick}
        >
          &#x2715;
        </span>
      </Tag>
    );
  };
  InputFieldTagsAutocompleteTag.propTypes = {
    tag: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
    removeButtonText: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
  };

  return InputFieldTagsAutocompleteTag;
};

export default createInputFieldTagsAutocompleteTag;
