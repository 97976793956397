import { collection } from 'firebase/firestore';

import {
  COMPANIES_ACHIEVEMENTS_COLLECTION,
  COMPANIES_ARTICLES_COLLECTION,
  COMPANIES_COMMENTS_COLLECTION,
  COMPANIES_CONTENT_COLLECTION,
  COMPANIES_DOWNLOADS_COLLECTION,
  COMPANIES_FOUNDERS_COLLECTION,
  COMPANIES_INSIGHTS_COLLECTION,
  COMPANIES_VIDEOS_COLLECTION,
} from 'constants/firebasePaths';
import useCrud from 'hooks/useCrud';

const useCompanyCruds = (docRef = {}) => {
  const cruds = {
    achievements: useCrud(
      collection(docRef, COMPANIES_ACHIEVEMENTS_COLLECTION)
    ),
    videos: useCrud(collection(docRef, COMPANIES_VIDEOS_COLLECTION)),
    articles: useCrud(collection(docRef, COMPANIES_ARTICLES_COLLECTION)),
    insights: useCrud(collection(docRef, COMPANIES_INSIGHTS_COLLECTION)),
    quotes: useCrud(collection(docRef, COMPANIES_COMMENTS_COLLECTION)),
    content: useCrud(collection(docRef, COMPANIES_CONTENT_COLLECTION)),
    downloads: useCrud(collection(docRef, COMPANIES_DOWNLOADS_COLLECTION)),
    founders: useCrud(collection(docRef, COMPANIES_FOUNDERS_COLLECTION)),
  };

  let loading = false;

  // if any of the collections is loading, return null
  Object.keys(cruds).forEach((key) => {
    if (cruds[key].status === 'loading') {
      loading = true;
    }
  });

  return loading ? null : cruds;
};

export default useCompanyCruds;
