import PropTypes from 'prop-types';
import React from 'react';

import OpportunitiesSort from 'components/opportunities/OpportunitiesSort/OpportunitiesSort';
import { sortOptions } from 'constants/opportunitiesOptions';
import { useTracking } from 'modules/segment/TrackingProvider';

import useCreateSetState from '../hooks/useCreateSetState';
import useQueryParams from '../hooks/useQueryParams';

const OpportunitiesControlsOrganizeSort = ({ fromModal = false }) => {
  const trackingService = useTracking();

  const { sort, sortDir, showDeclined } = useQueryParams();
  const tab = showDeclined ? 'declined' : 'upcoming';

  const setSort = useCreateSetState('sort');
  const setSortDir = useCreateSetState('sortDir');

  const logSortChange = (sortBy, sortDirection) =>
    trackingService.track('opportunity_interaction', {
      action: 'sort',
      opportunities_tab: tab,
      opportunities_sortBy: sortBy,
      opportunities_sortDirection: sortDirection < 0 ? 'asc' : 'desc',
    });

  const setSortLog = (sortBy) => {
    setSort(sortBy);
    logSortChange(sortBy, sortDir);
  };

  const setSortDirLog = (sortDirection) => {
    setSortDir(sortDirection);
    logSortChange(sort, sortDirection);
  };

  return (
    <OpportunitiesSort
      sort={sortOptions.find((option) => option.name === sort) || null}
      setSort={(sortOption) => setSortLog(sortOption?.name || null)}
      sortDir={sortDir}
      setSortDir={setSortDirLog}
      {...(fromModal
        ? { buttonClassName: 'w-56', wrapperClassName: 'flex' }
        : {})}
    />
  );
};

OpportunitiesControlsOrganizeSort.propTypes = {
  fromModal: PropTypes.bool,
};

export default OpportunitiesControlsOrganizeSort;
