import React from 'react';

import { Helmet } from 'react-helmet';

import getPageTitle from 'utils/getPageTitle';

import AdminWrapper from '../AdminWrapper';

import AdminSuccessFees from './AdminSuccessFees';

const AdminSuccessFeesPage = () => (
  <AdminWrapper title="Success Fees" currentIndex={8}>
    <Helmet>
      <title>{getPageTitle('Admin - Success Fees')}</title>
    </Helmet>
    <AdminSuccessFees />
  </AdminWrapper>
);

export default AdminSuccessFeesPage;
