import React from 'react';

import OpportunitiesControlsOrganize from './OpportunitiesControlsOrganize';
import OpportunitiesControlsSearch from './OpportunitiesControlsSearch';

const OpportunitiesControls = () => (
  <div className="sticky z-[9] -mx-6">
    <div className="flex px-4 py-2 w-full bg-white shadow-sm border-b border-gray-200 space-x-3 items-start justify-between">
      <OpportunitiesControlsSearch />
      <OpportunitiesControlsOrganize />
    </div>
  </div>
);

export default OpportunitiesControls;
