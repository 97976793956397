import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import onboardingSteps from 'constants/onboardingSteps';

// returns the steps configuration and current step
const useStepsConfiguration = () => {
  const { step: stepName } = useParams();

  const currentStep = useMemo(
    () =>
      onboardingSteps.find((step) => step.name === stepName) ||
      onboardingSteps[0],
    [stepName]
  );

  return [currentStep, onboardingSteps];
};

export default useStepsConfiguration;
