import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { DEFAULT_ONBOARDING_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

// redirect hook for opportunities page, redirecting the user to the correct path on load
const useRedirectOpportunities = () => {
  const { uid } = useUserData();
  const { companyData } = useCompanyData();
  const showOpportunities = uid && companyData;
  const navigate = useNavigate();

  useEffect(() => {
    if (!companyData) {
      navigate(DEFAULT_ONBOARDING_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showOpportunities;
};

export default useRedirectOpportunities;
