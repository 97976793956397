const successFeeStatuses = {
  DRAFTED: 'drafted',
  INVOICED: 'invoiced',
  DRAFT: 'draft',
  OPEN: 'open',
  PAID: 'paid',
  UNSUCCESSFUL: 'unsuccessful',
  ERROR: 'error',
};

export default successFeeStatuses;
