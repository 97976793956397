import getJournalistFirstName from './getJournalistFirstName';
import stripPunctuation from './stripPunctuation';

const responseTemplates = {
  'Thought Leadership': ({ company = {}, opportunity = {} }) =>
    `<p>Hi ${getJournalistFirstName(opportunity)},</p>
<p>I saw that you're open to taking thought leadership pieces and wondered if we'd be a good fit for ${
      opportunity?.mediaOutlet ||
      '<span class="highlight">Publication Name</span>'
    }.</p>
<p>For context, ${
      stripPunctuation(company?.description) ||
      '<span class="highlight">Company name, is about</span>'
    }. You can find out more about what we do in detail here too in our <a href="https://app.mvpr.io/company/${
      company?.slug
    }">media kit</a>.</p>
<p>I have set out the article we'd propose on behalf of our Founder <span class="highlight">Insert founder name</span> here below:</p>
<p class="highlight">Insert article headline</p>
<ul>
<li><span class="highlight">Insert article subheader 1</span></li>
<li><span class="highlight">Insert article subheader 2</span></li>
<li><span class="highlight">Insert article subheader 3</span></li>
<li><span class="highlight">Insert article subheader 4</span></li>
</ul>
<p>Let me know if this would be of interest,</p>`,

  'Comment / Quote': ({ company = {}, opportunity = {} }) =>
    `<p>Hi ${getJournalistFirstName(opportunity)},</p>
<p>I saw your request for comment on ${
      stripPunctuation(opportunity?.enquirySummary) ||
      '<span class="highlight">Media opportunity title</span>'
    } and was keen to share the thoughts of our <span class="highlight">Insert team role</span>, <span class="highlight">Insert team member name</span>.</p>
<p>For context <span class="highlight">Insert team member name</span> is <span class="highlight">Insert why the team member is a credible source for this publication's readership</span>.</p>
<p>I have included their comment here below - you can also find their headshot here should you need it in our <a href="https://app.mvpr.io/company/${
      company?.slug
    }">media kit</a>.</p>
<p class="highlight">Insert quote / Comment which directly answers or relates to the request(s)</p>`,

  'Request for Experts': ({
    opportunity = {},
  }) => `<p>Hi ${getJournalistFirstName(opportunity)},</p>
<p>I saw your request for experts for ${
    stripPunctuation(opportunity?.enquirySummary) ||
    '<span class="highlight">Media opportunity title</span>'
  } and was keen to propose our <span class="highlight">Insert team role</span>, <span class="highlight">Insert team member name</span>.</p>
<p>For context <span class="highlight">Insert team member name</span> is <span class="highlight">Insert why the team member is a credible expert and what makes them relevant for this opportunity</span>.</p>
<p>Let me know if you're keen to speak with them, happy to arrange that interview.</p>`,

  'Warm Introduction': ({ company = {}, opportunity = {} }) =>
    `<p>Hi ${getJournalistFirstName(opportunity)},</p>
<p>I saw a recent article you wrote on <span class="highlight">Insert article title</span> and wanted to introduce ${
      company?.name || '<span class="highlight">Company name</span>'
    } to you as we're also in this space.</p>
<p>For context on us, ${
      stripPunctuation(company?.description) ||
      '<span class="highlight">Company name, is about</span>'
    }.</p>
<p>If you'd be open to a background conversation with one of our founders, I'd love to make that possible.</p>
<p>Keen to hear what you think - but understand if we're not relevant for you yet.</p>`,

  'Blank Draft': () =>
    '<span class="highlight">Your message to the journalist</span>',
};

export default responseTemplates;
