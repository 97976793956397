import PropTypes from 'prop-types';
import React from 'react';

import { Bar } from 'react-chartjs-2';

import capitalizeKeys from './utils/capitalizeKeys';
import { title, tooltip } from './utils/options';

const getLabel = (context) => context.parsed.x || 0;
const options = {
  maintainAspectRatio: false,
  indexAxis: 'x',
  plugins: {
    title,
    tooltip: tooltip(getLabel),
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 1,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 1,
      },
    },
  },
};

const SweetheartListJournalistDetailsModalKeywords = ({ keywords = {} }) => {
  const filteredKeywords = Object.fromEntries(
    Object.entries(keywords)
      .filter(([key, value]) => key.toLowerCase() !== 'other' && value > 0)
      .sort((a, b) => b[1] - a[1])
  );

  const capitalizedKeywords = capitalizeKeys(filteredKeywords);
  return (
    <Bar
      className="text-teal-500"
      options={options}
      data={{
        labels: Object.keys(capitalizedKeywords),
        datasets: [
          {
            data: Object.values(capitalizedKeywords),
            backgroundColor: '#14b8a6',
          },
        ],
      }}
    />
  );
};

SweetheartListJournalistDetailsModalKeywords.propTypes = {
  keywords: PropTypes.object,
};

export default SweetheartListJournalistDetailsModalKeywords;
