import PropTypes from 'prop-types';
import React from 'react';

import {
  LocationMarkerIcon,
  CursorClickIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

const CompanyMetaPlace = ({
  location = null,
  website = null,
  foundedDate = {},
}) => (
  <div className="mt-2 space-y-2 px-6">
    {location && (
      <p
        className="flex items-center space-x-2 text-gray-400"
        data-test-id="company-header-location"
      >
        <LocationMarkerIcon className="w-4 text-gray-400 flex-none" />
        <span className="text-sm line-clamp-1">{location}</span>
      </p>
    )}

    <p
      className="flex items-center space-x-2 text-gray-400"
      data-test-id="company-header-foundedDate"
    >
      <OfficeBuildingIcon className="w-4 text-gray-400 flex-none" />
      <span className="text-sm line-clamp-1">
        {foundedDate.month} {foundedDate.year}
      </span>
    </p>

    <p
      className="flex items-center space-x-2 text-teal-500"
      data-test-id="company-header-url"
    >
      <CursorClickIcon className="w-4 text-gray-400 flex-none" />
      {website ? (
        <Link
          to={`https://${website}`}
          target="_blank"
          className="underline hover:no-underline text-sm line-clamp-1"
          data-test-id="company-header-url"
        >
          {website}
        </Link>
      ) : (
        <span className="underline hover:no-underline text-sm line-clamp-1">
          No website provided yet
        </span>
      )}
    </p>
  </div>
);

CompanyMetaPlace.propTypes = {
  location: PropTypes.string,
  website: PropTypes.string,
  foundedDate: PropTypes.shape({
    month: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default CompanyMetaPlace;
