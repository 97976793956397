import useJournalistsByIds from 'hooks/useJournalistsByIds';
import usePublicationsByIds from 'hooks/usePublicationsByIds';
import getJournalistId from 'utils/getJournalistId';
import getPublicationId from 'utils/getPublicationId';

const useThreadsWithEmails = (threads = []) => {
  const journalistIds = threads.map((thread) =>
    getJournalistId(
      thread?.data?.opportunity?.journalist,
      thread?.data?.opportunity?.mediaOutlet
    )
  );

  const publicationIds = threads.map((thread) =>
    getPublicationId(thread?.data?.opportunity?.mediaOutlet)
  );

  const { journalists, reload } = useJournalistsByIds(journalistIds);

  const { publications } = usePublicationsByIds(publicationIds);

  const threadsWithEmails = threads.map((thread) => {
    const newThread = { ...thread };
    const journalistName = thread?.data?.opportunity?.journalist;
    const publicationName = thread?.data?.opportunity?.mediaOutlet;
    const journalistId = getJournalistId(journalistName, publicationName);
    const publicationId = getPublicationId(publicationName);

    let journalist = journalists.find((j) => j.id === journalistId);

    if (!journalist) {
      const publication = publications.find((p) => p.id === publicationId);
      journalist = {
        name: journalistName,
        publication: publication || { name: publicationName },
      };
    }

    newThread.data.journalist = journalist;
    return newThread;
  });

  return { threadsWithEmails, reload };
};

export default useThreadsWithEmails;
