import * as React from 'react';

const DiscoverIcon = (props) => (
  <svg
    width={780}
    height={500}
    viewBox="0 0 780 500"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd">
      <path d="M54.992 30h670.016C738.811 30 750 41.191 750 55.005v219.919s-75.602 51.078-163.193 86.377C447.689 425.61 246.167 471 232.37 471H54.991C41.19 471 30 459.809 30 445.995V55.005C30 41.198 41.196 30 54.992 30zM0 55.005v390.99C0 476.375 24.618 501 54.992 501h670.016C755.374 501 780 476.37 780 445.995V55.005C780 24.625 755.382 0 725.008 0H54.992C24.626 0 0 24.63 0 55.005z" />
      <path d="M67.415 164.4v99.142h29.992c15.947 0 27.465-3.543 37.573-11.445 12.014-9.36 19.117-23.467 19.117-38.057 0-29.26-23.22-49.64-56.532-49.64h-30.15zm54.146 74.48c-6.454 5.484-14.837 7.878-28.11 7.878H87.94V181.2h5.511c13.273 0 21.326 2.236 28.11 8.016 7.104 5.956 11.377 15.183 11.377 24.682 0 9.515-4.273 19.025-11.377 24.981zm41.987 24.662h20.54V164.4h-20.54v99.142zm70.766-61.337c-12.325-4.293-15.946-7.128-15.946-12.482 0-6.244 6.45-10.99 15.303-10.99 6.153 0 11.212 2.383 16.563 8.029l10.748-13.249c-8.833-7.274-19.398-10.993-30.944-10.993-18.632 0-32.844 12.184-32.844 28.41 0 13.658 6.617 20.648 25.909 27.186 8.04 2.67 12.133 4.45 14.2 5.646 4.103 2.524 6.158 6.094 6.158 10.257 0 8.032-6.785 13.985-15.948 13.985-9.798 0-17.688-4.612-22.42-13.222l-13.268 12.03c9.464 13.077 20.828 18.872 36.458 18.872 21.345 0 36.32-13.36 36.32-32.553 0-15.75-6.924-22.882-30.289-30.926m36.769 11.567c0 29.14 24.303 51.736 55.58 51.736 8.846 0 16.417-1.636 25.758-5.776v-22.764c-8.214 7.737-15.489 10.86-24.801 10.86-20.688 0-35.37-14.123-35.37-34.197 0-19.037 15.15-34.051 34.414-34.051 9.8 0 17.213 3.29 25.757 11.154v-22.752c-9.02-4.305-16.432-6.09-25.269-6.09-31.12 0-56.07 23.054-56.07 51.88m244.2 17.068-28.084-66.592h-22.437l44.687 101.68h11.054l45.492-101.68h-22.265l-28.446 66.593m60 32.701h58.251v-16.784h-37.726v-26.762h36.335v-16.792h-36.335V181.2h37.726v-16.8h-58.251v99.142m139.58-69.872c0-18.56-13.576-29.27-37.266-29.27h-30.456v99.142h20.517v-39.827h2.677l28.43 39.827h25.26l-33.149-41.765c15.472-2.964 23.987-12.927 23.987-28.106zm-41.204 16.371h-6.001v-30.025h6.326c12.791 0 19.744 5.048 19.744 14.697 0 9.966-6.953 15.328-20.07 15.328zm-314.55 3.914v-.034c0-29.129 25.081-52.708 56.021-52.708s56.022 23.579 56.022 52.708v.034c0 29.13-25.082 52.743-56.022 52.743s-56.022-23.614-56.022-52.743" />
    </g>
  </svg>
);

export default DiscoverIcon;
