const getIconType = (type) => {
  switch (type) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
      return 'DOC';
    case 'application/pdf':
      return 'PDF';
    case 'image/jpeg':
    case 'image/jpg':
      return 'JPG';
    case 'image/png':
      return 'PNG';
    case 'image/gif':
      return 'GIF';
    case 'image/webp':
      return 'WEBP';
    default:
      return 'NONE';
  }
};
export default getIconType;
