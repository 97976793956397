import PropTypes from 'prop-types';
import React from 'react';

import { EyeOffIcon } from '@heroicons/react/solid';
import { doc, query, where } from 'firebase/firestore';
import { useFirestoreCollectionData } from 'reactfire';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import Video from 'components/company/Video/Video';
import VideoDuration from 'components/company/Video/VideoDuration';
import defaultOptions from 'constants/defaultOptions';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';

const CompanyMetaVideo = ({ videosRef = {}, showOwnerView = false }) => {
  const q = query(videosRef, where('featured', '==', true));
  const { status, data } = useFirestoreCollectionData(q, defaultOptions);
  const { updateDocument } = useFirestoreWrapper();

  const onRemove = async (uid) =>
    updateDocument(doc(videosRef, uid), { featured: false });

  if (status === 'loading') {
    return null;
  }

  if (!data || !data[0] || !data[0].url) {
    return null;
  }

  const video = data[0];

  return (
    <div className="w-full mt-6 px-6">
      <div className="relative rounded-xl overflow-hidden">
        <Video
          video={video}
          className="aspect-w-16 aspect-h-9"
          playIconSizeClassName="w-10 h-10"
          testId="company-meta-video"
        >
          {(isPlaying) =>
            !isPlaying && (
              <>
                <h3 className="text-white font-semibold text-sm absolute top-4 left-4 w-3/4 line-clamp-1">
                  {video.title}
                </h3>

                <VideoDuration title={video.title} duration={video.duration} />
                <div className="absolute top-2.5 right-2 z-10">
                  {showOwnerView && (
                    <DotsMenu
                      options={[
                        {
                          label: 'Remove from featured',
                          onClick: () => onRemove(video.uid),
                          testId: 'company-meta-video-menu-unfeature',
                          icon: <EyeOffIcon className="w-4 h-4" />,
                        },
                      ]}
                      testId="company-meta-video-menu"
                    />
                  )}
                </div>
              </>
            )
          }
        </Video>
      </div>
    </div>
  );
};

CompanyMetaVideo.propTypes = {
  videosRef: PropTypes.shape({
    id: PropTypes.string,
    where: PropTypes.func,
    doc: PropTypes.func,
  }).isRequired,
  showOwnerView: PropTypes.bool,
};

export default CompanyMetaVideo;
