import React from 'react';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import SweetheartListHeaderAdmin from './SweetheartListHeaderAdmin';

const SweetheartListHeader = () => {
  const { claimsAdmin: isAdmin, claimsAgencyId: isAgency } = useUserData();
  const { companyData } = useCompanyData();

  return (
    <div className="mb-2 flex flex-wrap items-center justify-between">
      <div className="">
        <h2
          className="text-lg font-semibold text-gray-700"
          data-test-id="sweetheartList-title"
        >
          Sweetheart List
        </h2>
        {(isAdmin || isAgency) && (
          <p className="text-xs italic text-gray-500">for {companyData.name}</p>
        )}
      </div>
      {(isAdmin || isAgency) && <SweetheartListHeaderAdmin />}
    </div>
  );
};

export default SweetheartListHeader;
