const sizes = {
  xs: {
    iconSizeClassName: 'w-4 h-4',
    textSizeClassName: 'text-xs',
  },
  sm: {
    iconSizeClassName: 'w-6 h-6',
    textSizeClassName: 'text-sm',
  },
};

const getSizeClassNames = (size) => sizes[size];

export { getSizeClassNames, sizes };
