import getFormattedCurrencyValue from 'utils/data/currency/getFormattedCurrencyValue';
import formatTimestamp from 'utils/date/formatTimestamp';

import getSectorNames from './getSectorNames';
import getStockExchangesNames from './getStockExchangesNames';

const getContentTypeAnalyticsEvents = (
  content = {},
  action = 'content_view'
) => {
  const { type } = content;

  switch (type) {
    case 'opEd':
      return {
        action,
        content_type: type,
        content_title: content?.title,
        content_body: content?.bulletPoints?.join(', '),
        content_url: `https://www.linkedin.com/in/${content?.linkedInUrl}`,
      };

    case 'hire':
      return {
        action,
        content_type: type,
        content_title: content?.companyName,
        content_name: content?.name,
        content_body: content?.position,
        content_url: `https://www.linkedin.com/in/${content?.linkedInUrl}`,
      };

    case 'm&a': {
      return {
        action,
        content_type: type,
        content_title: content?.companyAcquiredName,
        content_name: content?.companyAcquiringName,
        content_body: `${getFormattedCurrencyValue(
          content?.dealSize?.amount,
          content?.dealSize?.currency
        )}, ${getSectorNames(content?.sector)}, ${
          content?.companyAcquiredCountry
        }`,
      };
    }

    case 'fundraise':
      return {
        action,
        content_type: type,
        content_name: content?.fundingRound,
        content_title: `${content?.leadInvestors
          ?.map((l) => l.name)
          .join(', ')}`,
        content_body: `${getFormattedCurrencyValue(
          content?.capitalRaised?.amount,
          content?.capitalRaised?.currency
        )}, ${getFormattedCurrencyValue(
          content?.valuation?.amount,
          content?.valuation?.currency
        )}`,
      };

    case 'ipo':
      return {
        action,
        content_type: type,
        content_title: content?.companyName,
        content_name: getStockExchangesNames(content?.stockExchanges).join(),
        content_body: `${formatTimestamp(content?.embargoDate, true)} ${
          content?.other
        }`,
      };

    case 'brand':
      return {
        action,
        content_type: type,
        content_title: content?.headline,
        content_body: content?.description,
      };

    case 'product-service':
      return {
        action,
        content_type: type,
        title: content?.headline,
        content_body: content?.description,
        content_name: content?.name,
        content_url: content?.link,
      };

    case 'partnership':
      return {
        action,
        content_type: type,
        content_title: content?.headline,
        content_body: content?.companies.map((c) => c.name).join(', '),
      };

    default:
      return {};
  }
};

export default getContentTypeAnalyticsEvents;
