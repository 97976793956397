import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import InputFieldMultiUpload from 'components/form/inputFields/InputFieldMultiUpload';
import InputFieldTextEditor from 'components/form/inputFields/InputFieldTextEditor';
import trimMessage from 'routes/Inbox/InboxMessages/InboxMessage/InboxMessageResponseForm/helpers/trimMessage';

const AdminMessagesMessageTextEditModal = ({
  open = false,
  message = {
    text: '',
    html: '',
    attachments: [],
  },
  onClose = noop,
  onSubmit = noop,
}) => {
  const formName = 'editMessageText';
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({ mode: 'onChange' });

  return (
    <Modal open={open} widthClass="max-w-2xl">
      <Modal.Close
        srMessage="Close Message text edit Modal"
        onClose={onClose}
      />
      <Modal.Title>Edit text</Modal.Title>
      <Modal.Description>Edit the message&apos;s text</Modal.Description>
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)} id={formName}>
          <Controller
            render={({ field: { onChange } }) => (
              <InputFieldTextEditor
                onChange={(html, text) => onChange({ html, text })}
                initialValue={message.html || message.text}
                placeholder="The message text..."
                height={500}
              />
            )}
            rules={{
              validate: (value) => {
                if (!value?.text?.length) {
                  return 'Please put in a text';
                }
                return true;
              },
              setValueAs: trimMessage,
            }}
            defaultValue={message}
            name="message"
            control={control}
          />
          <Controller
            name="attachments"
            control={control}
            defaultValue={message?.attachments || []}
            render={({ field: { onChange, value } }) => (
              <InputFieldMultiUpload onChange={onChange} value={value} />
            )}
          />

          {errors.text && <ErrorMessage>{errors.text.message}</ErrorMessage>}
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button form={formName} submit disabled={!isValid}>
          Update
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminMessagesMessageTextEditModal.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AdminMessagesMessageTextEditModal;
