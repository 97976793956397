import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
} from 'firebase/analytics';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import {
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
} from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { useStateMachine } from 'little-state-machine';
import {
  AuthProvider,
  FirestoreProvider,
  FunctionsProvider,
  RemoteConfigProvider,
  StorageProvider,
  useInitRemoteConfig,
} from 'reactfire';

import {
  AUTH_EMULATOR_HOST,
  FIRESTORE_EMULATOR_PORT,
  FUNCTIONS_EMULATOR_PORT,
  STORAGE_EMULATOR_PORT,
  LOCALHOST,
} from 'constants/emulatorConfig';
import functionsConfig from 'constants/functionsConfig';
import useEnvironment from 'contexts/EnvironmentContext/hooks/useEnvironment';
import { TrackingProvider } from 'modules/segment/TrackingProvider';

/**
 * Preloads all of the Firebase modules we're using.
 * Makes them talk to the emulator if `isEmulated` is true
 */
const PreloadFirebase = ({ children = null }) => {
  const { isEmulated, firebaseApp: app } = useEnvironment();
  const [preloadComplete, setPreloadComplete] = useState(!isEmulated);

  const { state } = useStateMachine();

  let firestore;

  if (isEmulated) {
    firestore = initializeFirestore(app, {
      experimentalForceLongPolling: true,
    });
  } else {
    firestore = getFirestore(app);
  }

  const auth = getAuth(app);
  const storage = getStorage(app);
  const functions = getFunctions(app, functionsConfig.DEFAULT_REGION);
  const analytics = getAnalytics(app);
  const remoteConfig = getRemoteConfig(app);

  if (isEmulated && !preloadComplete && !auth.emulatorConfig) {
    connectAuthEmulator(auth, AUTH_EMULATOR_HOST);
    connectFirestoreEmulator(firestore, LOCALHOST, FIRESTORE_EMULATOR_PORT);
    connectStorageEmulator(storage, LOCALHOST, STORAGE_EMULATOR_PORT);
    connectFunctionsEmulator(functions, LOCALHOST, FUNCTIONS_EMULATOR_PORT);
    setPreloadComplete(true);
  }

  const { status, data: remoteConfigInstance } = useInitRemoteConfig(
    async (firebaseApp) => {
      const config = getRemoteConfig(firebaseApp);
      config.settings = {
        minimumFetchIntervalMillis: 10000,
        fetchTimeoutMillis: 10000,
      };

      await fetchAndActivate(remoteConfig);
      return remoteConfig;
    }
  );

  if (status === 'loading') {
    return null;
  }

  setAnalyticsCollectionEnabled(analytics, !!state.cookiesEnabled);

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestore}>
        <StorageProvider sdk={storage}>
          <FunctionsProvider sdk={functions}>
            <RemoteConfigProvider sdk={remoteConfigInstance}>
              <TrackingProvider firebaseAnalytics={analytics}>
                {children}
              </TrackingProvider>
            </RemoteConfigProvider>
          </FunctionsProvider>
        </StorageProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
};

PreloadFirebase.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PreloadFirebase;
