import PropTypes from 'prop-types';
import React from 'react';

const ModalContent = ({ children = null, testId = '', className = '' }) => (
  <div data-test-id={testId} className={className}>
    {children}
  </div>
);

ModalContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

export default ModalContent;
