import PropTypes from 'prop-types';
import React from 'react';

import { UserIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

const CompanyMetaDescriptionDeleteModal = ({
  open = false,
  onClose = noop,
  onDelete = noop,
}) => (
  <Modal open={open}>
    <Modal.Icon Icon={UserIcon} />
    <Modal.Title>Delete description</Modal.Title>
    <Modal.Description>
      Are you sure you want to delete the description?
    </Modal.Description>
    <Modal.RightButtons>
      <Button type="secondary" onClick={onClose}>
        Cancel
      </Button>

      <Button
        onClick={onDelete}
        data-test-id="company-meta-description-deleteModal-delete"
        eventType="pressPage_update"
        eventProperties={{ action: 'description_delete' }}
      >
        Delete description
      </Button>
    </Modal.RightButtons>
  </Modal>
);

CompanyMetaDescriptionDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CompanyMetaDescriptionDeleteModal;
