import React from 'react';

import InputFieldToggle from 'components/form/inputFields/InputFieldToggle';

import { actions } from '../contexts/filtersReducer';
import useAdminMessagesContext from '../contexts/useAdminMessagesContext';

const AdminThreadsFiltersArchivedToggle = () => {
  const {
    filters: { showArchivedThreads },
    dispatch,
  } = useAdminMessagesContext();

  return (
    <InputFieldToggle
      text="Show Archived"
      value={showArchivedThreads}
      onChange={() => dispatch({ type: actions.TOGGLE_SHOW_ARCHIVED_THREADS })}
    />
  );
};

export default AdminThreadsFiltersArchivedToggle;
