import React from 'react';

import { XIcon } from '@heroicons/react/outline';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import useImpersonation from 'contexts/ImpersonationContext/hooks/useImpersonation';
import useCompanies from 'hooks/useCompanies';

const ImpersonateDropdown = () => {
  const companies = useCompanies();
  const { impersonatedCompanySlug, setImpersonatedCompanySlug } =
    useImpersonation();

  return (
    <div className="flex gap-2 items-center">
      <InputFieldDropdown
        options={[
          {
            label: 'Select company',
            value: null,
          },
          ...companies.map((company) => ({
            label: company.name,
            value: company.slug,
          })),
        ]}
        onChange={setImpersonatedCompanySlug}
        value={impersonatedCompanySlug}
        buttonClassName="w-48"
      />
      <button
        type="button"
        className="text-teal-600 hover:text-teal-800 "
        onClick={() => setImpersonatedCompanySlug(null)}
      >
        <XIcon className="w-5 h-5" />
      </button>
    </div>
  );
};

export default ImpersonateDropdown;
