import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import without from 'lodash/without';

import InputFieldCheckboxesCardsCard from './InputFieldCheckboxesCardsCard';

// Renders checkbox-like cards. The user can select multiple items.
const InputFieldCheckboxesCards = ({
  options = [],
  value = [],
  onChange = noop,
  testId = '',
}) => (
  <div className="w-full flex flex-col cursor-pointer">
    {options.map((option) => (
      <InputFieldCheckboxesCardsCard
        key={typeof option === 'object' ? option.emoji : option}
        option={option}
        checked={value.includes(option.label)}
        onClick={
          () =>
            value.includes(option.label)
              ? onChange(without(value, option.label)) // remove `option` from `value`
              : onChange([...value, option.label]) // add `option` to `value`
        }
        testId={testId}
      />
    ))}
  </div>
);

InputFieldCheckboxesCards.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

export default InputFieldCheckboxesCards;
