import PropTypes from 'prop-types';
import React from 'react';

import {
  HomeIcon,
  AdjustmentsIcon,
  OfficeBuildingIcon,
  StarIcon,
  NewspaperIcon,
  UsersIcon,
  MailIcon,
  CurrencyPoundIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { generatePath, Link } from 'react-router-dom';

import {
  ADMIN_COMPANIES_PATH,
  ADMIN_CREATE_OPPORTUNITY_PATH,
  ADMIN_OPPORTUNITIES_PATH,
  ADMIN_PATH,
  ADMIN_PUBLICATIONS_PATH,
  ADMIN_JOURNALISTS_PATH,
  ADMIN_SUCCESS_FEES_PATH,
  ADMIN_MESSAGES_PATH,
  ADMIN_MESSAGES_COMPANIES_PATH,
  ADMIN_CREATE_CUSTOM_THREAD_PATH,
} from 'constants/paths';

import AdminResetDevEnvironment from './AdminResetDevEnvironment/AdminResetDevEnvironment';
import AdminUnclutterProdEnvironment from './AdminUnclutterProdEnvironment/AdminUnclutterProdEnvironment';

const navigation = [
  { name: 'Dashboard', to: ADMIN_PATH, icon: HomeIcon },
  {
    name: 'Companies list',
    to: ADMIN_COMPANIES_PATH,
    icon: OfficeBuildingIcon,
  },
  {
    name: 'Opportunities',
    to: ADMIN_OPPORTUNITIES_PATH,
    icon: AdjustmentsIcon,
  },
  {
    name: 'Custom opportunities',
    to: ADMIN_CREATE_OPPORTUNITY_PATH,
    icon: StarIcon,
  },
  {
    name: 'Publications',
    to: ADMIN_PUBLICATIONS_PATH,
    icon: NewspaperIcon,
  },
  {
    name: 'Journalists',
    to: ADMIN_JOURNALISTS_PATH,
    icon: UsersIcon,
  },
  {
    name: 'Messages',
    to: generatePath(ADMIN_MESSAGES_PATH, {
      type: ADMIN_MESSAGES_COMPANIES_PATH,
    }),
    icon: MailIcon,
  },
  {
    name: 'Success fees',
    to: `${ADMIN_SUCCESS_FEES_PATH}`,
    icon: CurrencyPoundIcon,
  },
  {
    name: 'Create custom thread',
    to: ADMIN_CREATE_CUSTOM_THREAD_PATH,
    icon: NewspaperIcon,
  },
];

const AdminWrapper = ({ children, title, currentIndex }) => (
  <>
    <div className="hidden w-32 md:flex md:fixed md:inset-y-0 md:flex-col">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex overflow-y-auto flex-col mt-16 min-h-screen bg-white border-r border-gray-200">
        <nav className="flex flex-col items-center text-center mt-5 px-2 pb-4 space-y-1 max-w-fit">
          {navigation.map((item, index) => (
            <Link
              key={item.name}
              to={item.to}
              className={classNames(
                {
                  'bg-gray-100 text-gray-900': index === currentIndex,
                  'text-gray-600 hover:bg-gray-50 hover:text-gray-900':
                    index !== currentIndex,
                },
                'group flex flex-col items-center px-2 py-2 text-xs font-medium rounded-md max-w-fit'
              )}
            >
              <item.icon
                className={classNames(
                  {
                    'text-gray-500': index === currentIndex,
                    'text-gray-400 group-hover:text-gray-500':
                      index !== currentIndex,
                  },
                  'shrink-0 h-6 w-6'
                )}
                aria-hidden="true"
              />
              {item.name}
            </Link>
          ))}
        </nav>
      </div>
    </div>

    <main className="p-6 md:ml-32">
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
        <AdminResetDevEnvironment />
        <AdminUnclutterProdEnvironment />
      </div>
      <div>{children}</div>
    </main>
  </>
);

AdminWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  currentIndex: PropTypes.number,
};

export default AdminWrapper;
