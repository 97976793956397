import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

const InputFieldCheckboxesCardsCard = ({
  option = '',
  checked = false,
  onClick = noop,
  testId = '',
}) => {
  const optionIsObject = typeof option === 'object';
  const optionLabel = optionIsObject ? option.label : option;

  return (
    <button
      type="button"
      key={optionLabel}
      className={classNames(
        'text-left border rounded-md px-4 py-2 mb-4 focus:outline-none focus:ring',
        {
          'bg-teal-50 hover:bg-teal-50 border-teal-500 text-teal-600': checked,
          'bg-white hover:bg-gray-50 text-gray-800': !checked,
        }
      )}
      onClick={onClick}
      data-test-id={`${testId}-${optionLabel}`}
    >
      <div className="flex items-center">
        {optionIsObject && <span className="text-xl mr-3">{option.emoji}</span>}
        <span>{optionLabel}</span>
      </div>
    </button>
  );
};

InputFieldCheckboxesCardsCard.propTypes = {
  option: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

export default InputFieldCheckboxesCardsCard;
