import React from 'react';

import classNames from 'classnames';
import { generatePath, NavLink, useNavigate } from 'react-router-dom';

import {
  ADMIN_MESSAGES_COMPANIES_PATH,
  ADMIN_MESSAGES_ORPHANS_PATH,
  ADMIN_MESSAGES_PATH,
} from 'constants/paths';

const tabs = [
  {
    name: 'Companies',
    href: generatePath(ADMIN_MESSAGES_PATH, {
      type: ADMIN_MESSAGES_COMPANIES_PATH,
    }),
    current: true,
  },
  {
    name: 'Orphans',
    href: generatePath(ADMIN_MESSAGES_PATH, {
      type: ADMIN_MESSAGES_ORPHANS_PATH,
    }),
    current: false,
  },
];
const AdminMessagesTabs = () => {
  const navigate = useNavigate();

  return (
    <div className="w-fit ml-auto">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={tabs[0].name}
          onChange={(e) => navigate(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <NavLink
              key={tab.name}
              to={tab.href}
              className={(isActive) =>
                classNames('px-3 py-2 font-medium text-sm rounded-md', {
                  'bg-gray-200 text-gray-800': isActive,
                  'text-gray-600 hover:text-gray-800': !isActive,
                })
              }
            >
              {tab.name}
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default AdminMessagesTabs;
