import { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { OPPORTUNITIES_PATH } from 'constants/paths';

import getPrettifiedQueryString from '../helpers/getPrettifiedQueryString';
import removeDefaultQueries from '../helpers/removeDefaultQueries';

const useNavigateWithQuery = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (query = {}) => {
      const cleanSearchParams = removeDefaultQueries(location.search);
      const keys = Object.keys(query);

      keys.forEach((key) => {
        if (query[key]) {
          cleanSearchParams.set(key, query[key]);
        } else {
          cleanSearchParams.delete(key);
        }
      });

      const path = `${OPPORTUNITIES_PATH}?${getPrettifiedQueryString(
        cleanSearchParams
      )}`;

      navigate(path);
    },
    [location.search, navigate]
  );
};

export default useNavigateWithQuery;
