import PropTypes from 'prop-types';
import React from 'react';

import { OfficeBuildingIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import { coreTopics } from 'constants/tags';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

const ContentElementPartnershipCompaniesEditModal = ({
  open = false,
  onClose = noop,
  onSubmit = noop,
  company = null,
}) => {
  const { companyData } = useCompanyData();
  const formName = `${
    company ? `${company}-edit` : 'new-company'
  }-partnership-form`;

  const form = useForm({ mode: 'onChange' });
  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = form;

  const defaultValues = {
    name: '',
    sectors: [],
    logo: null,
  };

  return (
    <Modal open={open} widthClass="max-w-md">
      <Modal.Close
        srMessage="Close Partnership company edit modal"
        onClose={onClose}
      />
      <Modal.Icon Icon={OfficeBuildingIcon} />
      <Modal.Content>
        <form
          id={formName}
          onSubmit={(e) => {
            e.stopPropagation();
            return handleSubmit(onSubmit)(e);
          }}
          className="space-y-3"
        >
          <InputFieldText
            defaultValue={defaultValues.name}
            {...register('name', {
              required: 'Please put in a name',
            })}
            errors={errors}
            placeholder="Name of the company"
            testId="company-content-partnership-editModal-inputTitle"
          >
            <InputFieldText.Label>Company title*</InputFieldText.Label>
          </InputFieldText>

          <div className="w-full text-left text-sm font-medium">
            <Label htmlFor="url">Sectors</Label>

            <div className="mt-2">
              <Controller
                name="sectors"
                control={control}
                defaultValue={[]}
                render={({ field: { onChange, value } }) => (
                  <InputFieldTagsAutocomplete
                    customFieldName="sectors"
                    suggestions={coreTopics}
                    onChange={onChange}
                    value={value}
                    testId="company-content-partnership-editModal-inputSectors"
                  />
                )}
              />
            </div>
          </div>

          <InputFieldUpload
            name="logo"
            folder={`companies/${companyData.uid}/content`}
            form={form}
            containPreview
          >
            <InputFieldUpload.Label>Logo</InputFieldUpload.Label>
          </InputFieldUpload>
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          submit
          disabled={!isValid}
          form={formName}
          data-test-id="company-content-partnership-editModal-addCompany"
        >
          {company ? 'Update' : 'Add'} company
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

ContentElementPartnershipCompaniesEditModal.propTypes = {
  open: PropTypes.bool,
  company: PropTypes.shape({
    name: PropTypes.string,
    sectors: PropTypes.array,
    logo: PropTypes.object,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ContentElementPartnershipCompaniesEditModal;
