import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CompanySectionPlaceholder from 'components/company/CompanySectionPlaceholder';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';

import CompanyInsight from './CompanyInsight/CompanyInsight';
import CompanyInsightEditModal from './CompanyInsightEditModal';
import CompanyInsightViewModal from './CompanyInsightViewModal';
import useInsights from './hooks/useInsights';

const NO_INSIGHTS_DESCRIPTION =
  'Add your first insight, help journalists understand where your company fits into the startup ecosystem';
const INSIGHTS_DESCRIPTION =
  'Add an insight, help journalists understand where your company fits into the startup ecosystem';

const CompanyInsights = ({ crud, founders, showOwnerView, sectionRef }) => {
  const [, insights, userInsights, ops] = useInsights(crud);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [currentViewInsightId, setCurrentViewInsightId] = useState(null);

  const isEmpty = userInsights.length < 1;

  // If there are no insights yet, hide this section for journalists
  if (!showOwnerView && isEmpty) {
    return null;
  }

  const onSubmit = (data = {}) => {
    const { question, answer, tag, founder } = data;
    ops.createDoc({
      question,
      answer,
      tag,
      founder: founder?.name ? { name: founder.name } : null,
    });
    setIsAddModalOpen(false);
  };

  const onView = (uid) => {
    setCurrentViewInsightId(uid);
    setIsViewModalOpen(true);
  };

  return (
    <>
      <div data-test-id="company-insights" id="insights" ref={sectionRef}>
        <SectionHeaderWrapper>
          <SectionHeadline light testId="company-insights-headline">
            Insights
          </SectionHeadline>
        </SectionHeaderWrapper>
        <div className="w-full mt-4">
          {showOwnerView || userInsights.length ? (
            <ul
              className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-6"
              data-test-id="company-insights-list"
            >
              {insights.map((insight, index) => (
                <CompanyInsight
                  key={insight.question}
                  insight={insight}
                  showOwnerView={showOwnerView}
                  ops={ops}
                  founders={founders}
                  onView={onView}
                  index={index}
                />
              ))}
              {showOwnerView && (
                <CompanySectionPlaceholder
                  key="add-insight"
                  title="Add an insight"
                  description={
                    isEmpty ? NO_INSIGHTS_DESCRIPTION : INSIGHTS_DESCRIPTION
                  }
                  onClick={() => {
                    setCurrentViewInsightId(null);
                    setIsAddModalOpen(true);
                  }}
                  testId="company-insight-addCustom"
                />
              )}
            </ul>
          ) : null}
        </div>
      </div>

      {showOwnerView && isAddModalOpen ? (
        <CompanyInsightEditModal
          open={isAddModalOpen}
          founders={founders}
          onClose={() => setIsAddModalOpen(false)}
          onSubmit={onSubmit}
        />
      ) : null}

      <CompanyInsightViewModal
        open={isViewModalOpen}
        insights={userInsights}
        founders={founders}
        currentInsightId={currentViewInsightId}
        setCurrentInsightId={setCurrentViewInsightId}
        onClose={() => setIsViewModalOpen(false)}
      />
    </>
  );
};

CompanyInsights.propTypes = {
  crud: PropTypes.array.isRequired,
  founders: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanyInsights;
