import PropTypes from 'prop-types';
import React from 'react';

import { PhotographIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

const CompanyHeaderDeleteModal = ({
  open = false,
  onClose = noop,
  onDelete = noop,
}) => (
  <Modal open={open}>
    <Modal.Icon Icon={PhotographIcon} />

    <Modal.Title>Delete header image</Modal.Title>
    <Modal.Description>
      Are you sure you want to delete the header image?
    </Modal.Description>
    <Modal.RightButtons>
      <Button type="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button
        onClick={onDelete}
        data-test-id="company-header-deleteModal-delete"
      >
        Delete image
      </Button>
    </Modal.RightButtons>
  </Modal>
);

CompanyHeaderDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CompanyHeaderDeleteModal;
