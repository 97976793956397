import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import noop from 'lodash/noop';
import { FormProvider, useForm } from 'react-hook-form';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import contentOnboardingDefaults from 'constants/contentOnboardingDefaults';
import getContentType from 'constants/contentTypes';
import { useTracking } from 'modules/segment/TrackingProvider';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

import getContentTypeAnalyticsEvents from '../helpers/getContentTypeAnalyticsEvents';

const CompanyContentEditModal = ({
  open = false,
  content = {},
  modalType = 'edit',
  onClose = noop,
  ops = {},
}) => {
  const trackingService = useTracking();

  const form = useForm({ mode: 'all' });
  const { handleSubmit, formState } = form;

  const Component = useMemo(
    () => contentOnboardingDefaults?.[content?.type].component || 'div',
    [content?.type]
  );

  const { label, Icon, description } = getContentType(content);
  const lowerCaseLabel = label.toLowerCase();
  const formName = `companyContentEditForm-${lowerCaseLabel}`;

  const onSubmit = async (data = {}) => {
    // We add the type to the data object.
    const fullData = { ...data, type: content.type };

    // We convert the date to a timestamp.
    if (fullData.embargoDate) {
      fullData.embargoDate = createTimestampFromDateObj(fullData.embargoDate);
    }

    // We clean falsy values from arrays.
    Object.keys(fullData).forEach((key) => {
      if (Array.isArray(fullData[key])) {
        fullData[key] = fullData[key].filter(Boolean);
      }
    });

    // If the modal type is add, we add the content.
    if (modalType === 'add') {
      trackingService.track(
        'pressPage_update',
        getContentTypeAnalyticsEvents(fullData, 'content_add')
      );
      await ops.createDoc(fullData);
      onClose();
      return;
    }

    // If the modal type is edit, we update the content.
    await ops.updateDoc(content.uid, fullData);
    onClose();
  };

  useEffect(() => {
    const contentCloned = cloneDeep(content);
    const contentDefaultsCloned = cloneDeep(
      contentOnboardingDefaults?.[content?.type].defaultValues
    );

    if (typeof contentCloned.embargoDate === 'string') {
      contentCloned.embargoDate = getDateObjFromTimestamp(
        contentCloned.embargoDate
      );
    }

    if (typeof contentCloned.file === 'undefined') {
      contentCloned.file = null;
    }

    const newDefaultContent = merge(contentDefaultsCloned, contentCloned);
    form.reset(newDefaultContent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <Modal open={open}>
      <Modal.Close srMessage="Close content edit modal" onClose={onClose} />
      <Modal.Icon Icon={Icon} />
      <Modal.Icon Icon={Icon} />
      <Modal.Title>{label}</Modal.Title>
      <Modal.Description>{description}</Modal.Description>
      <Modal.Content>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} id={formName}>
            <Component />
          </form>
        </FormProvider>

        <ReactTooltip
          id="tooltip-contentEdit-general"
          className="max-w-xs text-sm"
        />

        <ReactTooltip
          id="tooltip-contentEdit-general-delay"
          className="max-w-xs text-sm"
          delayHide={1500}
        />
      </Modal.Content>
      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-content-editModal-cancel"
        >
          Cancel
        </Button>
        <Button
          submit
          form={formName}
          disabled={!formState.isValid}
          data-test-id="company-content-editModal-submit"
        >
          {modalType === 'edit' ? 'Update' : 'Add'} {lowerCaseLabel}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyContentEditModal.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.object,
  setIsEditModalOpen: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  ops: PropTypes.object,
  modalType: PropTypes.oneOf(['edit', 'add']),
};

export default CompanyContentEditModal;
