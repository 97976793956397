import PropTypes from 'prop-types';
import React from 'react';

import { UserIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

// Displays the journalist's image as a rounded avatar
const JournalistImage = ({ url = '', name = '', className = 'w-8 h-8' }) => (
  <div
    className={classNames(
      'flex-shrink-0 rounded-full overflow-hidden bg-gray-50 outline outline-1 outline-black/10',
      className
    )}
  >
    {url ? (
      <img
        className="w-full h-full object-center object-contain"
        src={url}
        alt={name}
      />
    ) : (
      <UserIcon className="text-gray-200 transform translate-y-[10%]" />
    )}
  </div>
);

JournalistImage.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
};

export default JournalistImage;
