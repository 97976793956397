import sortBy from 'lodash/sortBy';

import defaultInsights from '../constants/defaultInsights';

const useInsights = (crud) => {
  const [status, insights, ops] = crud;

  // filter out default insights that have been answered and concat custom ones
  // (which contain previously default ones)
  const allInsights = defaultInsights
    .filter(
      (defaultInsight) =>
        !insights.some((insight) => insight.default === defaultInsight.default)
    )
    .concat(insights);

  // sort insights
  const userInsightsSorted = sortBy(insights, ['default', 'dateCreated']);
  const allInsightsSorted = sortBy(allInsights, ['default', 'dateCreated']);

  return [status, allInsightsSorted, userInsightsSorted, ops];
};

export default useInsights;
