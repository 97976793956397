import useQuery from 'hooks/useQuery';

/*
  URL patterns

- NAMED PARAMETER: id (tab)
  /opportunities
  /opportunities/declined

- QUERY PARAMETERS

  - sort

    /opportunities (default, equals 'sort=none')
    /opportunities?sort=none
    /opportunities?sort=timeLeft
    /opportunities?sort=domainAuthority
    
  - sortDir
    
    /opportunities?sort=timeLeft (default, equals sortDir=1)
    /opportunities?sort=timeLeft&sortDir=0
    /opportunities?sort=timeLeft&sortDir=1

  - filter

    /opportunities?sort=timeLeft (default, equals no filter)
    /opportunities?sort=timeLeft&filter=1
    /opportunities?sort=timeLeft&filter=1,2 (multiple)

*/
export const getAllQueries = (query) => {
  const allQueriesArray = Array.from(query.entries());

  return allQueriesArray.reduce((acc, curr) => {
    const [key, value] = curr;
    return { ...acc, [key]: value };
  }, {});
};

const parseFilterQuery = (filterQuery) => {
  if (!filterQuery) return [];

  return filterQuery
    .split(',')
    .map((s) => Number.parseInt(s, 10))
    .filter((n) => !Number.isNaN(n));
};

const addDefaultQueries = (queries) => {
  const { sort, sortDir, filter, showDeclined } = queries;
  const sortDirNumber = Number.parseInt(sortDir, 10);

  const defaultQueries = {
    sort: sort === 'null' ? null : sort,
    showDeclined: showDeclined === 'true',
    sortDir: Number.isNaN(sortDirNumber) ? 1 : sortDirNumber,
    filter: parseFilterQuery(filter),
  };

  return { ...queries, ...defaultQueries };
};

// The useQueryParams function returns an object containing the
// current URL's query parameters (with default values added) and route parameters.
const useQueryParams = () => {
  const query = addDefaultQueries(getAllQueries(useQuery()));

  return query;
};

export default useQueryParams;
