import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { TrashIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import CopyToClipboardButton from 'components/buttons/CopyToClipboardButton';
import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import Founder from 'components/company/Founder';
import { useTracking } from 'modules/segment/TrackingProvider';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';

import CompanyQuoteDeleteModal from '../CompanyQuoteDeleteModal';
import CompanyQuoteEditModal from '../CompanyQuoteEditModal';

import CompanyQuoteTags from './CompanyQuoteTags';
import CompanyQuoteWrapper from './CompanyQuoteWrapper';

const CompanyQuote = ({
  quote = {},
  showOwnerView = false,
  ops = {},
  founders = [],
  onView = noop,
  index = 0,
}) => {
  const trackingService = useTracking();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onSubmit = (data = {}) => {
    const { title, body, tags, founder } = data;
    const date = createTimestampFromDateObj(data.date);
    ops.updateDoc(quote.uid, {
      title,
      body,
      tags,
      founder: founder?.name ? { name: founder.name } : null,
      users: null,
      private: data.private,
      date,
    });
    setIsEditModalOpen(false);
  };

  const onDelete = () => {
    ops.deleteDoc(quote.uid);
    setIsDeleteModalOpen(false);
  };

  const onClick = (e) => {
    e.preventDefault();
    trackingService.track('pressPage_view', {
      action: 'quote_view',
      quote_title: quote?.title,
      quote_body: quote?.body,
    });
    onView(quote.uid);
  };

  return (
    <>
      <CompanyQuoteWrapper
        showOwnerView={showOwnerView}
        onClick={onClick}
        testId={`company-quote-${index + 1}`}
      >
        <CompanyQuoteTags
          tags={quote.tags}
          testId={`company-quote-${quote.title}-tags`}
          isPrivate={quote.private}
        />

        {showOwnerView && (
          <div className="absolute top-4 right-4">
            <DotsMenu
              theme="medium"
              options={[
                {
                  label: `Edit quote`,
                  onClick: () => setIsEditModalOpen(true),
                  testId: `company-quote-${quote.title}-menu-edit`,
                },
                {
                  label: `Delete quote`,
                  icon: <TrashIcon className="w-4 h-4" />,
                  onClick: () => setIsDeleteModalOpen(true),
                  testId: `company-quote-${quote.title}-menu-delete`,
                },
              ]}
              testId={`company-quote-${quote.title}-menu`}
            />
          </div>
        )}

        <div>
          <h3
            className={`text-xl font-medium leading-normal line-clamp-2 ${
              !quote.tags?.length ? 'pr-8' : ''
            }`}
            data-test-id={`company-quote-${quote.title}-title`}
          >
            {quote.title}
          </h3>

          <p
            className="flex-1 mt-1 text-sm text-gray-400 leading-normal line-clamp-5"
            data-test-id={`company-quote-${quote.title}-body`}
          >
            {quote.body}
          </p>
        </div>

        <div className="w-full flex flex-row justify-between items-center pt-4">
          <Founder
            founder={founders.find((f) => f.name === quote?.founder?.name)}
            widthClassName="w-3/4 flex-shrink"
            testId={`company-quote-${quote.title}-founder`}
          />
          <CopyToClipboardButton
            copyText={quote.body}
            moduleName="quote"
            eventType="pressPage_view"
            eventProperties={{
              action: 'quote_view_copyToClipboard',
              quoteCopied_title: quote.title,
              quoteCopied_body: quote.body,
            }}
          />
        </div>
      </CompanyQuoteWrapper>

      {showOwnerView && isEditModalOpen ? (
        <CompanyQuoteEditModal
          open={isEditModalOpen}
          quote={quote}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onSubmit}
          founders={founders}
        />
      ) : null}

      {showOwnerView && (
        <CompanyQuoteDeleteModal
          open={isDeleteModalOpen}
          title={quote.title}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

CompanyQuote.propTypes = {
  quote: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
  founders: PropTypes.array,
  showOwnerView: PropTypes.bool,
  ops: PropTypes.object,
  onView: PropTypes.func.isRequired,
  index: PropTypes.number,
};

export default CompanyQuote;
