import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { NewspaperIcon, SpeakerphoneIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { generatePath } from 'react-router-dom';

import {
  COMPANY_PATH,
  OPPORTUNITIES_PATH,
  SWEETHEART_PATH,
} from 'constants/paths';
import { ReactComponent as SweetheartListIcon } from 'static/icon-sweetheart-list.svg';

import HomeLogo from '../HomeLogo';

import MobileNavAccountLink from './MobileNavAccountLink';
import MobileNavLink from './MobileNavLink';
import MobileNavToggle from './MobileNavToggle';

/**
 * Navigation overlay for mobile users, gets toggled via toggle icon
 * Highlights the current option as selected
 */
const MobileNav = ({ slug = '' }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = (y) => {
    setIsMobileMenuOpen(y);
    const root = document.getElementsByTagName('html')[0];
    root.classList.toggle('fixed', y);
    root.classList.toggle('overflow-y-scroll', y);
    root.classList.toggle('w-full', y);
    const iubenda = document.getElementsByClassName('iubenda-tp-btn')[0];
    if (iubenda) {
      if (y) iubenda.setAttribute('style', 'display: none !important');
      if (!y) iubenda.removeAttribute('style');
    }
  };
  const companyPath = generatePath(COMPANY_PATH, { id: slug });

  return (
    <div
      className={classNames(
        'my-2 px-2 pl-4 sm:hidden flex items-center justify-end border-l-2 border-teal-600 text-white min-h-full',
        { 'z-10': isMobileMenuOpen, 'z-0': !isMobileMenuOpen }
      )}
    >
      <MobileNavToggle
        isMobileMenuOpen={isMobileMenuOpen}
        toggleMobileMenu={toggleMobileMenu}
      />

      <div
        className={classNames(
          'fixed overscroll-none top-0 h-screen min-w-full bg-black',
          {
            'left-0 bg-opacity-70 overflow-hidden': isMobileMenuOpen,
            '-left-full bg-opacity-0': !isMobileMenuOpen,
          }
        )}
      >
        <div
          className={classNames(
            'absolute top-0 min-h-full w-10/12 flex flex-col bg-teal-500 transition-all ease-in-out duration-500',
            { 'left-0': isMobileMenuOpen, '-left-full': !isMobileMenuOpen }
          )}
        >
          <div className="ml-4 mt-4">
            <HomeLogo
              homeRoute={companyPath}
              white
              onClick={() => toggleMobileMenu(false)}
            />
          </div>
          <ul className="flex-grow flex flex-col pt-4 text-lg">
            <MobileNavLink
              path={companyPath}
              label="Press Page"
              Icon={NewspaperIcon}
              onClick={() => toggleMobileMenu(false)}
            />

            <MobileNavLink
              path={OPPORTUNITIES_PATH}
              label="Media Opportunities"
              Icon={SpeakerphoneIcon}
              onClick={() => toggleMobileMenu(false)}
            />

            <MobileNavLink
              path={generatePath(SWEETHEART_PATH)}
              label="Sweetheart List"
              Icon={SweetheartListIcon}
              onClick={() => toggleMobileMenu(false)}
            />

            <MobileNavAccountLink toggleMobileMenu={toggleMobileMenu} />
          </ul>
        </div>
      </div>
    </div>
  );
};

MobileNav.propTypes = {
  slug: PropTypes.string,
};

export default MobileNav;
