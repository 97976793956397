import { useAuth } from 'reactfire';

// return the photo URL of the currently logged-in user
const usePhotoUrl = () => {
  const auth = useAuth();

  if (auth.currentUser && auth.currentUser.photoURL) {
    return auth.currentUser.photoURL;
  }

  return null;
};

export default usePhotoUrl;
