import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { SearchIcon } from '@heroicons/react/outline';
import noop from 'lodash/noop';

import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

import CompanyThreadAssignModalCompanySearchResults from './CompanyThreadAssignModalCompanySearchResults';
import usePagination from './hooks/usePagination';

const CompanyThreadAssignModalCompanySearch = ({
  selectedCompany = {},
  setSelectedCompany = noop,
}) => {
  const [companyQuery, setCompanyQuery] = useState('');
  const pagination = usePagination(companyQuery);
  const currentPage = pagination.currentPage?.filter(
    (c) => c.uid !== selectedCompany.uid
  );

  return (
    <div className="space-y-2">
      <p className="text-sm text-gray-500">Search and select a company</p>
      <div className="relative">
        <InputFieldText
          name="companyQuery"
          onChange={(e) => setCompanyQuery(e.target.value)}
          placeholder="Search for a company"
        >
          <InputFieldText.Overlay className="flex justify-end items-center pr-2">
            <SearchIcon className="w-4 h-4 text-gray-400 right-2" />
          </InputFieldText.Overlay>
        </InputFieldText>
      </div>
      <CompanyThreadAssignModalCompanySearchResults
        companies={currentPage}
        isFetching={pagination.isFetching}
        onCompanyClick={(c) => {
          if (selectedCompany.uid !== c.uid) {
            setSelectedCompany(c);
          } else {
            setSelectedCompany({});
          }
        }}
        selectedCompany={selectedCompany}
      />
      <div className="space-x-2">
        <Button
          type="secondary"
          onClick={pagination.previousPage}
          disabled={!currentPage.length || pagination.isFirst}
        >
          Previous
        </Button>
        <Button
          type="secondary"
          onClick={pagination.nextPage}
          disabled={!currentPage.length || pagination.isLast}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

CompanyThreadAssignModalCompanySearch.propTypes = {
  selectedCompany: PropTypes.object,
  setSelectedCompany: PropTypes.func,
};

export default CompanyThreadAssignModalCompanySearch;
