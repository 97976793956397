const getCurrentObjectAndIndex = (array = [], uid = '') => {
  const index = array.findIndex((item) => item.uid === uid);

  if (index === -1) {
    return [null, null];
  }

  const currentObject = array[index];

  return [currentObject, index];
};

export default getCurrentObjectAndIndex;
