import React from 'react';

import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';

import useCompaniesStore from './hooks/useCompaniesStore';

const AdminCompaniesTiers = () => {
  const activeTier = useCompaniesStore((state) => state.activeTier);
  const tiers = useCompaniesStore((state) => state.tiers);
  const largestTierNameLength = useCompaniesStore(
    (state) => state.largestTierNameLength
  );

  const tierOptions = [
    {
      label: 'All tiers',
      value: 'all',
    },
    ...tiers,
    {
      label: 'No tiers',
      value: 'none',
    },
  ];

  return (
    <div
      className="flex flex-col gap-2"
      style={{
        '--dropdown-width': `${largestTierNameLength}ch`,
      }}
    >
      <Label htmlFor="tier">Tier</Label>
      <InputFieldDropdown
        name="tier"
        options={tierOptions}
        value={activeTier}
        onChange={(v) => useCompaniesStore.setState({ activeTier: v })}
        buttonClassName="w-[calc(var(--dropdown-width)+3rem)]"
      />
    </div>
  );
};

export default AdminCompaniesTiers;
