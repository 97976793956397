import { useCallback } from 'react';

import useCompanies from 'hooks/useCompanies';

// verify's if a company's slug already exists
const useVerifySlug = (setError) => {
  const companies = useCompanies();
  const verifySlug = useCallback(
    (newSlug, newName) => {
      // check if company name or slug exists already
      if (
        companies &&
        companies.some(
          (company) => company.slug === newSlug || company.name === newName
        )
      ) {
        setTimeout(() => {
          if (setError)
            setError('name', {
              message: 'Sorry! The company name or slug exists already.',
            });
        }, 0);

        return false;
      }

      return true;
    },
    [companies, setError]
  );

  return verifySlug;
};

export default useVerifySlug;
