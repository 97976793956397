import { COMPANY_PATH as path } from 'constants/paths';

import CompanyPage from './CompanyPage';

const CompanyRoute = {
  path,
  component: CompanyPage,
};

export default CompanyRoute;
