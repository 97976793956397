import { ONBOARDING_PATH as path } from 'constants/paths';

import OnboardingPage from './OnboardingPage';

const OnboardingRoute = {
  path,
  component: OnboardingPage,
};

export default OnboardingRoute;
