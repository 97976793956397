const fundingRoundTypes = [
  'Angel',
  'Pre-seed',
  'Seed',
  'Series A',
  'Series B',
  'Series C',
  'Series D',
  'Series E',
];

export default fundingRoundTypes;
