import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

/**
 * Toggle switch that switches a value on/off.
 * Needs a `text` that shows up next to the toggle switch.
 */
const InputFieldToggle = ({
  id = 'toggle',
  value = false,
  onChange = noop,
  text = '',
  testId = '',
  disabled = false,
}) => (
  <div className="flex items-center">
    <button
      type="button"
      role="switch"
      id={id}
      className={classNames(
        { 'bg-teal-500': value, 'bg-gray-200': !value },
        'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2',
        { 'focus:ring-teal-500': value, 'focus:ring-gray-200': !value },
        {
          'cursor-not-allowed bg-gray-100': disabled,
        }
      )}
      aria-checked={value}
      onClick={() => onChange(!value)}
      data-test-id={testId}
      disabled={disabled}
    >
      <span aria-hidden="true" className="sr-only">
        {value ? 'On' : 'Off'}
      </span>
      <span
        aria-hidden="true"
        className={classNames(
          { 'translate-x-5': value, 'translate-x-0': !value },
          'relative pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
        )}
      />
    </button>
    <label
      htmlFor={id}
      className={classNames('ml-3 text-sm', {
        'text-gray-500 cursor-pointer': !disabled,
        'text-gray-300 cursor-not-allowed': disabled,
      })}
    >
      {text}
    </label>
  </div>
);

InputFieldToggle.propTypes = {
  id: PropTypes.string,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InputFieldToggle;
