import PropTypes from 'prop-types';
import React from 'react';

import { ContentTypes } from 'constants/contentTypes';
import formatTimestamp from 'utils/date/formatTimestamp';

import CompanyContentElementTableRow from './CompanyContentElementTableRow';

const CompanyContentElementTableFundraise = ({ content = {} }) => {
  const { headline, description, other, embargoDate } = content;

  return (
    <>
      <CompanyContentElementTableRow
        label="Headline"
        content={headline}
        contentType={ContentTypes.BRAND}
      />
      <CompanyContentElementTableRow
        label="Description"
        contentType={ContentTypes.BRAND}
        content={description}
      />

      {other ? (
        <CompanyContentElementTableRow
          label="More info"
          content={other}
          contentType={ContentTypes.BRAND}
        />
      ) : null}

      <CompanyContentElementTableRow
        label="Embargo Date"
        contentType={ContentTypes.BRAND}
        content={formatTimestamp(embargoDate)}
      />
    </>
  );
};

CompanyContentElementTableFundraise.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CompanyContentElementTableFundraise;
