const registerCompoundComponents = (Component, compoundComponents) => {
  const LocalComponent = Component;

  Object.entries(compoundComponents).forEach(
    ([compoundComponentName, compoundComponent]) => {
      LocalComponent[compoundComponentName] = compoundComponent;
    }
  );
};

export default registerCompoundComponents;
