import { useEffect } from 'react';

import { generatePath, useMatch, useNavigate } from 'react-router-dom';

import {
  DEFAULT_ONBOARDING_PATH,
  SETTINGS_BILLING_PATH,
  SETTINGS_PATH,
  SETTINGS_USER_PATH,
} from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import useActiveSubscription from './useActiveSubscription';

// redirect hook for settings page, redirecting the user to the correct path on load
const useRedirectSettings = () => {
  const { uid, claimsJournalist } = useUserData();
  const { companyData } = useCompanyData();
  const showSettings = (uid && companyData) || claimsJournalist;
  const isBillingPage = useMatch(
    generatePath(SETTINGS_PATH, { '*': SETTINGS_BILLING_PATH })
  );
  const subscription = useActiveSubscription();
  const navigate = useNavigate();

  useEffect(() => {
    if (!companyData && !claimsJournalist) {
      navigate(DEFAULT_ONBOARDING_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isBillingPage && !subscription) {
      navigate(`/${generatePath(SETTINGS_PATH, { '*': SETTINGS_USER_PATH })}`);
    }
  }, [navigate, isBillingPage, subscription]);

  return showSettings;
};

export default useRedirectSettings;
