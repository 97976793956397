import PropTypes from 'prop-types';
import React from 'react';

import ReactDOMServer from 'react-dom/server';
import { useFormContext } from 'react-hook-form';

import Tooltip from 'components/Tooltip/Tooltip';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { PAGE_TITLE } from 'constants/meta';

const BULLETPOINT_PLACEHOLDER = 'Bullet point about the article...';
const TITLE_PLACEHOLDER = 'Title of the article...';

const ContentElementOpEdArticleIdea = ({ index = 0 }) => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext();
  const [title, bulletPoints] = watch(['title', 'bulletPoints']);

  const firstEmptyBulletPointIndex = bulletPoints?.findIndex((v) => !v);

  return (
    <div className="w-full flex flex-col space-y-2">
      <Label htmlFor="articleIdeas" className="flex items-center">
        Article Synopsis*
        <Tooltip
          wrapperClassName="!inline-block"
          dataHTML
          id="tooltip-contentEdit-general-delay"
          timeout={1500}
          html={ReactDOMServer.renderToString(
            <p className="pointer-events-auto">
              Summarize your thought leadership article idea into a title and
              bullet points that you can share with journalists. If you need an
              example, you can take a look at{' '}
              <a
                className="underline"
                href="https://app.mvpr.io/company/mvpr#content"
                target="_blank"
                rel="noreferrer"
              >
                {PAGE_TITLE}&apos;s Content for an Opinion Article here
              </a>
            </p>
          )}
        />
      </Label>
      <div className="w-full shadow-sm flex flex-col bg-gray-50 border border-gray-200 p-2 rounded-md group space-y-3">
        <InputFieldText
          id={`title${index}`}
          {...register('title', {
            required: {
              value: true,
              message: 'Title is required',
            },
          })}
          testId="company-content-opEd-inputTitle"
          errors={errors}
          defaultValue={title || ''}
          placeholder={TITLE_PLACEHOLDER}
        >
          <InputFieldText.Label>Article Title*</InputFieldText.Label>
        </InputFieldText>

        <div className="gap-2 w-full items-start flex flex-col">
          <Label
            htmlFor={`bulletPoints[${
              firstEmptyBulletPointIndex > 0 ? firstEmptyBulletPointIndex : 0
            }]${index}`}
          >
            Bulletpoints
          </Label>
          <ul className="w-full space-y-2">
            {bulletPoints?.map((bulletPoint, bulletPointIndex) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={bulletPointIndex}
                className="pl-4"
              >
                <InputFieldText
                  id={`bulletPoints[${bulletPointIndex}]${index}`}
                  {...register(`bulletPoints.${bulletPointIndex}`)}
                  defaultValue={bulletPoint}
                  testId={`company-content-opEd-inputBulletPoints-${bulletPointIndex}`}
                  placeholder={BULLETPOINT_PLACEHOLDER}
                >
                  <InputFieldText.Overlay className="text-gray-300 -ml-4 flex items-center">
                    •
                  </InputFieldText.Overlay>
                </InputFieldText>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

ContentElementOpEdArticleIdea.propTypes = {
  index: PropTypes.number,
};

export default ContentElementOpEdArticleIdea;
