import React from 'react';

import { Helmet } from 'react-helmet';

import useCompanies from 'hooks/useCompanies';
import getPageTitle from 'utils/getPageTitle';

import AdminWrapper from '../AdminWrapper';

import AdminCompanies from './AdminCompanies';

const AdminCompaniesPage = () => {
  const companies = useCompanies();

  return (
    <AdminWrapper title="Companies" currentIndex={1}>
      <Helmet>
        <title>{getPageTitle('Admin - Companies')}</title>
      </Helmet>
      <AdminCompanies companies={companies} />
    </AdminWrapper>
  );
};

export default AdminCompaniesPage;
