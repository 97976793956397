import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import getPageTitle from 'utils/getPageTitle';

import AgencyDashboardCompaniesCompanyMessages from './AgencyDashboardCompaniesCompanyMessages';

const AgencyDashboardCompaniesCompany = () => {
  const { state } = useLocation();
  const [companyName, setCompanyName] = useState(state?.name);

  return (
    <>
      <Helmet>
        <title>{getPageTitle(`Dashboard - ${companyName}`)}</title>
      </Helmet>

      <AgencyDashboardCompaniesCompanyMessages
        companyName={companyName}
        setCompanyName={setCompanyName}
      />
    </>
  );
};

export default AgencyDashboardCompaniesCompany;
