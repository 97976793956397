import { useCallback, useEffect } from 'react';

import noop from 'lodash/noop';
import { useFormContext } from 'react-hook-form';

const useFormArray = ({
  name = '',
  validate = () => true,
  updateCallback = noop,
}) =>
  // eslint-disable-next-line max-params
  {
    const formApi = useFormContext();
    const { watch, setValue } = formApi;
    const fields = watch(name);

    // We need to register the input field so that the formApi can
    // keep track of the input field's value. We declare it here, so it validates correctly.
    formApi.register(name, {
      validate,
    });

    useEffect(
      () => () => formApi.unregister(name),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const setForm = useCallback(
      (value) => {
        updateCallback(value, name);
        setValue(name, value);
      },
      [name, setValue, updateCallback]
    );

    const add = useCallback(
      (item = {}) => {
        setForm([...fields, item]);
      },
      [fields, setForm]
    );

    const remove = useCallback(
      (index) => {
        setForm(fields.filter((_, i) => i !== index));
      },
      [fields, setForm]
    );

    const update = useCallback(
      (newFields) => {
        setForm(newFields);
      },
      [setForm]
    );

    return { fields, add, remove, update, formApi };
  };

export default useFormArray;
