import PropTypes from 'prop-types';
import React from 'react';

import TrashIcon from '@heroicons/react/solid/TrashIcon';
import noop from 'lodash/noop';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import getContentType from 'constants/contentTypes';

import CompanyContentTitle from '../components/CompanyContentTitle';
import CompanyContentWrapper from '../components/CompanyContentWrapper';
import { ClampContextProvider } from '../contexts/ClampContext';

import CompanyContentElementTable from './CompanyContentElementTable';

const CompanyContentElement = ({
  content = {},
  showOwnerView = false,
  onView = noop,
  onEdit = noop,
  onDelete = noop,
}) => {
  const { type } = content;
  const { label } = getContentType(content);
  return (
    <CompanyContentWrapper
      onView={onView}
      showOwnerView={showOwnerView}
      content={content}
    >
      {showOwnerView && (
        <div className="absolute top-4 right-4 z-10">
          <DotsMenu
            theme="medium"
            options={[
              {
                label: `Edit ${label.toLowerCase()}`,
                onClick: () => onEdit(content.uid),
                testId: `company-content-${type}-menu-edit`,
              },
              {
                label: `Delete ${label.toLowerCase()}`,
                icon: <TrashIcon className="w-4 h-4" />,
                onClick: () => onDelete(content.uid),
                testId: `company-quote-${type}-menu-delete`,
              },
            ]}
            testId={`company-quote-${type}-menu`}
          />
        </div>
      )}
      <div className="w-full h-full flex flex-col">
        <CompanyContentTitle content={content} />
        <ClampContextProvider value={{ clampValues: true }}>
          <CompanyContentElementTable content={content} />
        </ClampContextProvider>
      </div>
    </CompanyContentWrapper>
  );
};

CompanyContentElement.propTypes = {
  content: PropTypes.object.isRequired,
  showOwnerView: PropTypes.bool,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CompanyContentElement;
