import uploadPresets from 'constants/uploadPresets';

const isImageFileType = (fileType) => {
  const { accept } = uploadPresets.image;

  return Object.keys(accept).some(
    (imageFileType) => imageFileType === fileType
  );
};

const isImageFile = (file = {}) => {
  if (file.type) {
    // We use this type for images which
    // we are not sure of their type from OpenGraph
    if (file.type === 'image') {
      return true;
    }

    return isImageFileType(file.type);
  }

  if (file.path || file.name) {
    // If the file is local, we can use the name
    // If the file is remote, we can use the path or name
    const fileName = file.isLocal ? file.name : file.path || file.name;

    const fileNameClean = fileName.split(/[?#]/)[0];

    return isImageFileType(`image/${fileNameClean.split('.').pop()}`);
  }

  return false;
};

export default isImageFile;
