import { ADMIN_PATH as path } from 'constants/paths';

import AdminPage from './AdminPage';

const AdminRoute = {
  path,
  authRequired: true,
  claimsRequired: { admin: true },
  component: AdminPage,
};

export default AdminRoute;
