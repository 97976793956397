import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import InputFieldRadioStackedCardsCard from './InputFieldRadioStackedCardsCard';

// Renders radio-like cards. The user can select one item.
const InputFieldRadioStackedCards = ({
  name = '',
  options = [],
  selected = null,
  testId = '',
  onChange = noop,
}) => (
  <fieldset className="w-full rounded-md flex flex-col space-y-4 bg-gray-50">
    {options.map((option, index) => (
      <InputFieldRadioStackedCardsCard
        key={option}
        name={name}
        option={option}
        index={index}
        onSelect={onChange}
        isSelected={selected === option}
        isFirst={index === 0}
        isLast={index === options.length - 1}
        testId={testId}
      />
    ))}
  </fieldset>
);

InputFieldRadioStackedCards.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

InputFieldRadioStackedCards.displayName = 'InputFieldRadioStackedCards';

export default InputFieldRadioStackedCards;
