import React from 'react';

import { ExclamationIcon } from '@heroicons/react/outline';

const SweetheartListAddJournalistModalNoResultFound = () => (
  <div className="flex flex-col items-center justify-center h-full py-12">
    <ExclamationIcon className="w-16 h-16 mb-4 text-gray-500" />
    <h2 className="text-xl font-semibold mb-2">Oops! No Matches Yet.</h2>
    <p className="text-gray-500 text-center">
      We couldn&apos;t find any matching results. Please try again with
      different criteria.
    </p>
  </div>
);

export default SweetheartListAddJournalistModalNoResultFound;
