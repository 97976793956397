// get the bg/text color className for a domain authority value
const getDomainAuthorityClassNames = (domainAuthority = 0) => {
  if (domainAuthority >= 80) {
    return 'bg-gray-300 text-gray-600';
  }
  if (domainAuthority >= 60) {
    return 'bg-gray-200 text-gray-500';
  }
  if (domainAuthority >= 40) {
    return 'bg-gray-100 text-gray-500';
  }
  return 'bg-white text-gray-400  border';
};

export default getDomainAuthorityClassNames;
