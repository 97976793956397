import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import getCompanyRoute from 'utils/getCompanyRoute';

import JournalistFounder from '../JournalistFounder';

const JournalistResultsCompany = ({
  slug = '',
  name = '',
  description = '',
  founders = [],
  vcs = [],
}) => (
  <Link to={getCompanyRoute(slug)} key={slug}>
    <li className="my-2 flex flex-1 flex-col justify-between items-start p-6 pb-4 rounded-lg text-sm border-gray-200 bg-white border hover:border-teal-500 hover:shadow-md">
      {vcs.length > 0 && (
        <div className="absolute self-end text-sm">
          <span className="font-semibold">VCs: </span>
          {vcs.join(', ')}
        </div>
      )}
      <h3 className="text-md font-bold leading-normal line-clamp-2 ">{name}</h3>
      <p className="flex-1 mt-1 text-sm text-gray-400 leading-normal line-clamp-5">
        {description}
      </p>
      <div className="w-full flex flex-row flex-wrap items-center pt-4">
        {founders.map((founder) => (
          <div key={founder.name} className="mr-4 mb-2">
            <JournalistFounder
              founder={{
                image: founder.image_path
                  ? {
                      path: founder?.image_path,
                      name: founder?.image_name,
                      type: founder?.image_type,
                      size: founder?.image_size,
                    }
                  : null,
                name: founder.name,
                position: founder.position,
                linkedInHandle: founder.linkedin_url,
              }}
            />
          </div>
        ))}
      </div>
    </li>
  </Link>
);

JournalistResultsCompany.propTypes = {
  slug: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  founders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      position: PropTypes.string,
      linkedinHandle: PropTypes.string,
    })
  ),
  vcs: PropTypes.arrayOf(PropTypes.string),
};

export default JournalistResultsCompany;
