import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const InputFieldTextPrefix = ({ children = null, className = '' }) => (
  <span
    className={classNames(
      'inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm',
      className
    )}
  >
    {children}
  </span>
);

InputFieldTextPrefix.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default InputFieldTextPrefix;
