import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { sanitizeUrl } from '@braintree/sanitize-url';
import sortBy from 'lodash/sortBy';

import CompanySectionPlaceholder from 'components/company/CompanySectionPlaceholder';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';

import CompanyVideo from './CompanyVideo';
import CompanyVideoEditModal from './CompanyVideoEditModal/CompanyVideoEditModal';

const NO_VIDEOS_DESCRIPTION =
  'Add your first video that shares what you and your company is working to achieve';
const VIDEOS_DESCRIPTION =
  'Add a video that shares what you and your company is working to achieve';

const CompanyVideos = ({ crud, showOwnerView = false, sectionRef }) => {
  const [status, videosRaw, ops] = crud;

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const closeModal = () => setIsAddModalOpen(false);

  const videos = videosRaw.filter(
    (video) => showOwnerView || (!showOwnerView && !video.featured)
  );
  const videosSorted = sortBy(videos, '!featured');
  const isEmpty = videos.length < 1;

  if (status === 'loading') {
    return null;
  }

  // If there are no videos yet, hide this section for journalists
  if (!showOwnerView && isEmpty) {
    return null;
  }

  const onSubmit = (data = {}) => {
    const { title, url, duration, tag } = data;
    const sanitizedUrl = sanitizeUrl(url);
    ops.createDoc({
      title,
      url: sanitizedUrl,
      duration: duration || 0,
      tag: tag || null,
    });
    closeModal();
  };

  return (
    <div data-test-id="company-videos" id="videos" ref={sectionRef}>
      <SectionHeaderWrapper>
        <SectionHeadline light testId="company-videos-headline">
          Videos
        </SectionHeadline>
      </SectionHeaderWrapper>
      <div className="w-full mt-4">
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-8 gap-6">
          {videosSorted.map((video) => (
            <CompanyVideo
              key={video.uid}
              video={video}
              ops={ops}
              showOwnerView={showOwnerView}
            />
          ))}
          {showOwnerView && (
            <CompanySectionPlaceholder
              title="Add a video"
              description={isEmpty ? NO_VIDEOS_DESCRIPTION : VIDEOS_DESCRIPTION}
              onClick={() => setIsAddModalOpen(true)}
              className="col-span-2"
              testId="company-videos-add"
            />
          )}
        </ul>
      </div>

      {showOwnerView && isAddModalOpen ? (
        <CompanyVideoEditModal
          open={isAddModalOpen}
          onClose={() => closeModal()}
          onSubmit={onSubmit}
        />
      ) : null}
    </div>
  );
};

CompanyVideos.propTypes = {
  crud: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanyVideos;
