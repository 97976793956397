import { k, M } from 'constants/math';

const getTickLabel = (level = 0) => {
  if (level === 0) {
    return '0';
  }
  if (level / M < 1) {
    return `${Math.floor(level / k)}k`;
  }

  return `${Math.floor(level / M)}M`;
};

export default getTickLabel;
