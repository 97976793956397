import { useEffect, useRef, useState } from 'react';

import useIsInViewport from 'hooks/useIsInViewport';

const options = {
  rootMargin: '-123px 0px -77px 0px', // 123px is the height of the 2 navbars, 77px is the height of the message header
  threshold: 1, // 1 means the entire element must be in the viewport
};

const useScrollMessageIntoView = (isExpanded = false) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref, options);

  const scrollIntoView = isExpanded && !isInViewport && !hasScrolled;

  useEffect(() => {
    // Only scroll into view if the message is expanded, not already in the viewport,
    // and hasn't been scrolled into view before
    let timeoutId;
    if (scrollIntoView) {
      timeoutId = setTimeout(() => {
        if (ref.current) {
          ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          setHasScrolled(true);
        }
      }, 300);
    }

    // Clear the timeout if the component unmounts before it's finished
    return () => clearTimeout(timeoutId);
  }, [scrollIntoView]);

  useEffect(() => {
    // Reset the hasScrolled state when the message is collapsed
    if (!isExpanded) {
      setHasScrolled(false);
    }
  }, [isExpanded]);

  useEffect(() => {
    // Set hasScrolled to true when the message is expanded and in the viewport
    if (isExpanded && isInViewport) {
      setHasScrolled(true);
    }
  }, [isExpanded, isInViewport]);

  return ref;
};

export default useScrollMessageIntoView;
