import { useEffect, useState } from 'react';

import { useAuth } from 'reactfire';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import addJournalistDataToOpportunity from 'helpers/addJournalistDataToOpportunity';
import useJournalistsByIds from 'hooks/useJournalistsByIds';
import usePublicationsByIds from 'hooks/usePublicationsByIds';
import getJournalistId from 'utils/getJournalistId';
import getPublicationId from 'utils/getPublicationId';

import createSearchURL from '../helpers/createSearchUrl';
import toFirestoreOpportunity from '../helpers/toFirestoreOpportunity';

import useQueryParams from './useQueryParams';
import useSearchTerms from './useSearchTerms';

const SEARCH_URL = process.env.REACT_APP_SEARCH_URL;

// retrive all the opportunites by using search query and filters from the search endpoint
const useOpportunitiesBySearch = () => {
  const auth = useAuth();
  const [journalistIds, setJournalistIds] = useState([]);
  const [publicationIds, setPublicationIds] = useState([]);
  const { journalists } = useJournalistsByIds(journalistIds);
  const { publications } = usePublicationsByIds(publicationIds);
  const { companyData } = useCompanyData();
  const searchTerms = useSearchTerms();
  const queryParams = useQueryParams();

  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const { url, urlChangedKey } = createSearchURL({
    companyId: companyData.uid,
    terms: searchTerms.terms,
    queryParams,
  });

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      setIsLoading(true);
      try {
        const token = await auth.currentUser?.getIdToken();
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: abortController.signal,
        });
        const data = await response.json();

        const opportunities = data.results.map(toFirestoreOpportunity);

        const newJournalistIds = opportunities.map((o) =>
          getJournalistId(o.journalist, o.mediaOutlet)
        );
        const newPublicationIds = opportunities.map((o) =>
          getPublicationId(o.mediaOutlet)
        );
        setJournalistIds(newJournalistIds);
        setPublicationIds(newPublicationIds);
        setResults(opportunities);
        setError(false);
      } catch (err) {
        if (err.name !== 'AbortError') {
          setError(true);
        }
      }
      setIsLoading(false);
    })();
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [auth, urlChangedKey]);

  const trackOpportunityClick = async (opportunity) => {
    try {
      const token = await auth.currentUser?.getIdToken();
      fetch(`${SEARCH_URL}/search/v2/opportunities/click`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify({
          query: opportunity?.searchTerm,
          documentId: opportunity.uid,
          requestId: opportunity?.requestId,
        }),
      });
    } catch (err) {
      // ignore for now
    }
  };

  const resultsWithJournalistData = results.map((opp) =>
    addJournalistDataToOpportunity({ opp, journalists, publications })
  );

  return {
    isLoading,
    results: resultsWithJournalistData,
    error,
    trackOpportunityClick,
  };
};

export default useOpportunitiesBySearch;
