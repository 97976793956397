import Backed from 'static/vc-backed.jpeg';
import custom from 'static/vc-custom.png';
import FoundersFactory from 'static/vc-founders-factory.png';
import HambroPerks from 'static/vc-hambro-perks.png';
import OctopusVentures from 'static/vc-octopus-ventures.png';
import PlayfairCapital from 'static/vc-playfair-capital.jpeg';
import SpeedInvest from 'static/vc-speed-invest.png';
import TargetGlobal from 'static/vc-target-global.jpeg';

// TODO: move these elsewhere, and make it possible to import them all at once
const vcImages = {
  backed: Backed,
  founders_factory: FoundersFactory,
  hambro_perks: HambroPerks,
  octopus_ventures: OctopusVentures,
  playfair_capital: PlayfairCapital,
  target_global: TargetGlobal,
  speed_invest: SpeedInvest,
  custom,
};

export default vcImages;
