const getHistogram = (fundingCounts = [], fundingLevels = []) => {
  const heights = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < fundingLevels.length; i++) {
    const lower = fundingLevels[i - 1];
    const upper = fundingLevels[i];
    const totalCount = fundingCounts.reduce((acc, { value, count }) => {
      if (value >= lower && value < upper) {
        return acc + count;
      }
      return acc;
    }, 0);
    heights.push(totalCount);
  }

  return heights;
};

export default getHistogram;
