import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';

import classNames from 'classnames';
import get from 'lodash/get';

import ErrorMessage from 'components/form/ErrorMessage';
import useCompoundComponents from 'hooks/useCompoundComponents';
import registerCompoundComponents from 'utils/components/registerCompoundComponents';

import inputFieldTextCompoundComponents from './inputFieldTextCompoundComponents';

/**
 * Styled input field for text, should be used anywhere the user inputs one line of text
 * Can be used for email and password fields as well.
 */
const InputFieldText = React.forwardRef(
  (
    {
      id = '',
      name = '',
      disabled = false,
      containerClassName = '',
      inputClassName = '',
      errors = {},
      children = null,
      testId = '',
      ...rest
    },
    ref
  ) => {
    const error = get(errors, name, false);
    const inputId = id || name;
    const errorTestId = testId ? `${testId}-error` : null;

    const { Prefix, Suffix, Label, Overlay } = useCompoundComponents(
      children,
      inputFieldTextCompoundComponents
    );

    return (
      <div
        className={classNames('w-full flex flex-col gap-2', containerClassName)}
      >
        {Label ? cloneElement(Label, { htmlFor: inputId }) : null}
        <div className="flex w-full rounded-md shadow-sm relative">
          {Prefix || null}

          <input
            id={inputId}
            ref={ref}
            type="text"
            name={name}
            disabled={disabled}
            // maximum hard limit for URL strings:
            // https://stackoverflow.com/questions/417142/what-is-the-maximum-length-of-a-url-in-different-browsers
            maxLength={255}
            className={classNames(
              'px-3 py-2 flex-1 rounded-md block w-full bg-white border-gray-300 focus:border-teal-500 focus:outline-none focus:ring-0 min-w-0 text-sm',
              {
                'rounded-l-none': Prefix,
                'rounded-r-none': Suffix,
                'opacity-50': disabled,
              },
              inputClassName
            )}
            data-test-id={testId}
            {...rest}
          />

          {Suffix || null}

          {Overlay || null}
        </div>
        {error ? (
          <ErrorMessage testId={errorTestId}>{error.message}</ErrorMessage>
        ) : null}
      </div>
    );
  }
);

InputFieldText.propTypes = {
  id: PropTypes.string,
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  errors: PropTypes.object,
  children: PropTypes.node,
};

InputFieldText.displayName = 'InputFieldText';

registerCompoundComponents(InputFieldText, inputFieldTextCompoundComponents);

export default InputFieldText;
