import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import ReactTags from 'react-tag-autocomplete';
import { v4 as uuidv4 } from 'uuid';

import reactTagsClassnames from '../InputFieldTagsAutocomplete/config/reactTagsClassnames';
import createInputFieldTagsAutocompleteTag from '../InputFieldTagsAutocomplete/createInputFieldTagsAutocompleteTag';

import InputFieldCompaniesAutocompleteInstructions from './InputFieldCompaniesAutocompleteInstructions';

const InputFieldCompaniesAutocomplete = ({
  customFieldName = '',
  onChange = noop,
  value = [],
  suggestions = [],
  tagsIndex = null,
  name = '',
  placeholder = 'Type to search companies...',
  ...rest
}) => {
  const hasTag = (tag = {}) => value?.find((t = {}) => t.name === tag.name);

  const classNames = value.length
    ? reactTagsClassnames
    : {
        ...reactTagsClassnames,
        search: `${reactTagsClassnames.search} min-w-full `,
        searchInput: `${reactTagsClassnames.searchInput} min-w-full`,
        selected: `${reactTagsClassnames.selected} hidden`,
      };

  const onDelete = (i = 0) => {
    const arr = value.slice(0);
    arr.splice(i, 1);
    onChange(arr);
  };

  const onAdd = (tag = {}) => {
    if (hasTag(tag)) {
      return;
    }
    onChange(
      value.concat({
        ...tag,
        id: tag.id || `custom-${customFieldName}-${uuidv4()}`,
      })
    );
  };

  const getPlaceholderText = () => {
    if (value.length) {
      return '';
    }

    if (!placeholder) {
      return 'Add tags';
    }

    return placeholder;
  };

  return (
    <>
      <label
        htmlFor={customFieldName}
        className="focus-within:border-teal-500 relative px-2 block bg-white border border-gray-300 min-w-0 cursor-text rounded-md shadow-sm"
      >
        <ReactTags
          name={name}
          autoresize={!!value.length}
          inputAttributes={{ id: customFieldName }}
          minQueryLength={1}
          classNames={classNames}
          tags={value}
          suggestions={suggestions}
          onDelete={onDelete}
          onAddition={onAdd}
          tagComponent={createInputFieldTagsAutocompleteTag(
            suggestions,
            tagsIndex
          )}
          allowNew
          placeholderText={getPlaceholderText()}
          {...rest}
        />
      </label>
      <div className="mt-2 w-full">
        <InputFieldCompaniesAutocompleteInstructions />
      </div>
    </>
  );
};

InputFieldCompaniesAutocomplete.propTypes = {
  customFieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  suggestions: PropTypes.array,
  tagsIndex: PropTypes.array,
  data: PropTypes.array,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  rest: PropTypes.object,
};

export default InputFieldCompaniesAutocomplete;
