import PropTypes from 'prop-types';
import React from 'react';

import Callout from 'components/Callout/Callout';
import useQueryParams from 'routes/Opportunities/hooks/useQueryParams';

const OpportunitiesListResponseModalCallout = ({ journalist = '' }) => {
  const { showDeclined } = useQueryParams();

  if (!showDeclined) {
    return null;
  }

  return (
    <div className="relative">
      <Callout wrapperClassName="text-center mb-6">
        Please reactivate the Opportunity before responding to {journalist}
      </Callout>
    </div>
  );
};

OpportunitiesListResponseModalCallout.propTypes = {
  journalist: PropTypes.string,
};

export default OpportunitiesListResponseModalCallout;
