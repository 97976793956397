import React from 'react';

import { useMatch } from 'react-router-dom';

import {
  AGENCY_DASHBOARD_COMPANY_PATH,
  AGENCY_DASHBOARD_PATH,
} from 'constants/paths';

import AgencyDashboardCompanies from './AgencyDashboardCompanies';
import AgencyDashboardCompaniesCompany from './AgencyDashboardCompaniesCompany';
import AgencyWrapper from './AgencyWrapper';

const AgencyDashboardPage = () => {
  const showCompanies = useMatch(AGENCY_DASHBOARD_PATH);
  const showCompany = useMatch(AGENCY_DASHBOARD_COMPANY_PATH);

  return (
    <AgencyWrapper currentIndex={0} title="Dashboard">
      {showCompanies && <AgencyDashboardCompanies />}
      {showCompany && <AgencyDashboardCompaniesCompany />}
    </AgencyWrapper>
  );
};

export default AgencyDashboardPage;
