import { useEffect, useRef, useState } from 'react';

import { doc, onSnapshot } from 'firebase/firestore';
import noop from 'lodash/noop';
import { useFirestore } from 'reactfire';

import {
  OPPORTUNITIES_NOTIFICATIONS,
  OPPORTUNITIES_NOTIFICATIONS_COMPANIES_NOTIFIED,
  OPPORTUNITIES_NOTIFICATIONS_COMPANIES_TO_NOTIFY,
  OPPORTUNITIES_NOTIFICATIONS_COMPANIES_VISITED,
} from 'constants/firebasePaths';
import useOnScreen from 'hooks/useOnScreen';

// This is a performance optimization to avoid subscribing to all companies
// and only subscribe to the ones that are on screen, and unsubscribe from
// the ones that are not on screen anymore

const useSubscribeToCompanyNotifiedStatusInView = ({
  companyUid = '',
  opportunityUid = '',
}) => {
  const firestore = useFirestore();
  const [isNotified, setIsNotified] = useState(false);
  const [hasVisited, setHasVisited] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  const ref = useRef(null);
  const isOnScreen = useOnScreen(ref);

  useEffect(() => {
    const subCollections = [
      OPPORTUNITIES_NOTIFICATIONS_COMPANIES_NOTIFIED,
      OPPORTUNITIES_NOTIFICATIONS_COMPANIES_TO_NOTIFY,
      OPPORTUNITIES_NOTIFICATIONS_COMPANIES_VISITED,
    ];
    const setters = [setIsNotified, setIsSelected, setHasVisited];

    const unsubscribes = [noop, noop, noop];
    const unsubscribeFromAll = () => unsubscribes.forEach((u) => u());

    if (isOnScreen && companyUid && opportunityUid) {
      const opportunityNotificationsDoc = doc(
        firestore,
        OPPORTUNITIES_NOTIFICATIONS,
        opportunityUid
      );

      subCollections.forEach((subCollection, i) => {
        const subCollectionDoc = doc(
          opportunityNotificationsDoc,
          subCollection,
          companyUid
        );
        unsubscribes[i] = onSnapshot(subCollectionDoc, (document) => {
          setters[i](document.exists());

          if (subCollection === OPPORTUNITIES_NOTIFICATIONS_COMPANIES_NOTIFIED)
            setNotificationCount(document.data()?.notificationCount || 0);
        });
      });
    }

    if (!isOnScreen) {
      unsubscribeFromAll();
    }

    return () => {
      unsubscribeFromAll();
    };
  }, [isOnScreen, companyUid, opportunityUid, firestore]);

  return { isNotified, hasVisited, isSelected, notificationCount, ref };
};

export default useSubscribeToCompanyNotifiedStatusInView;
