import { collection, doc, onSnapshot } from 'firebase/firestore';
import noop from 'lodash/noop';

/* eslint-disable max-params */
const createDataObject = (document) => ({
  data: document.data(),
  id: document.id,
  ref: document.ref,
});

const flattenSubcollectionSnapshots = (
  rules = [],
  updater = noop,
  parent = {},
  isCollection = false,
  level = 1
) => {
  const listeners = [];

  const dataGetter = onSnapshot(parent, (data) => {
    listeners
      .filter((e) => e.level > level)
      .forEach((unsubscriber) => unsubscriber.unsubscribe());

    let output = isCollection
      ? data.docs.map(createDataObject)
      : createDataObject(data);
    updater(output);

    const unsubscribers = (isCollection ? data.docs : [data])
      .map((document, i) => {
        if (isCollection) {
          output[i] = createDataObject(document);
        } else {
          output = createDataObject(document);
        }

        const nextCollectionParent = isCollection
          ? doc(parent, document.id)
          : parent;

        return rules.map((subcollection) =>
          flattenSubcollectionSnapshots(
            subcollection.subcollections || [],
            (newValue) => {
              if (isCollection) {
                output[i][subcollection.collection] = newValue;
              } else {
                output[subcollection.collection] = newValue;
              }
              updater(output);
            },
            collection(nextCollectionParent, subcollection.collection),
            true,
            level + 1
          )
        );
      })
      .flat();

    listeners.push(...unsubscribers);
  });
  listeners.push({
    level,
    unsubscribe: dataGetter,
  });

  return {
    unsubscribe: () => {
      listeners.forEach((listener) => listener.unsubscribe());
    },
    level,
  };
};

export default flattenSubcollectionSnapshots;
