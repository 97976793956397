import PropTypes from 'prop-types';
import React, { createContext } from 'react';

import { collection, query, where, documentId } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';
import useImpersonation from 'contexts/ImpersonationContext/hooks/useImpersonation';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import topicFilter from 'utils/data/topicFilter';

const defaultCompanyData = {
  companyData: null,
  isMember: false,
  isActualOwnerOfCompany: false,
  hasBasicCompanyAccess: false,
  isAgencyOfCompany: false,
};

export const CompanyContext = createContext(defaultCompanyData);
const { Provider } = CompanyContext;

const CompanyProvider = ({ uid = null, children }) => {
  const firestore = useFirestore();
  const { impersonatedCompanySlug } = useImpersonation();
  const {
    claimsAdmin,
    claimsAgencyId,
    claimsCompany,
    uid: userId,
  } = useUserData();
  let q;
  const collectionRef = collection(firestore, COMPANIES_COLLECTION);

  // If user is part of a company, use that company's data
  if (claimsCompany) {
    q = query(collectionRef, where(documentId(), '==', claimsCompany));
  }

  // if admin is impersonating a company, use slug to look for company
  else if (impersonatedCompanySlug) {
    q = query(collectionRef, where('slug', '==', impersonatedCompanySlug));
  }

  // otherwise, use the current owner's uid
  else {
    q = query(collectionRef, where('owner', '==', uid));
  }
  const { status, data } = useFirestoreCollectionData(q, defaultOptions);

  let companyData = data?.[0];

  const listOfMembers = companyData?.members;
  const isActualOwnerOfCompany = companyData?.owner === userId;
  const isMember = listOfMembers?.includes(userId) && !isActualOwnerOfCompany;

  // if company data is available, prefilter topics
  if (companyData) {
    companyData = {
      ...companyData,
      topics: data[0]?.topics?.filter(topicFilter.isNotNone) ?? [],
    };
  }

  if (status === 'loading') {
    return null;
  }

  const isAgencyOfCompany = isMember && claimsAgencyId;
  const hasBasicCompanyAccess = Boolean(
    claimsAdmin || isActualOwnerOfCompany || isAgencyOfCompany || isMember
  );

  return (
    <Provider
      value={{
        companyData,
        isActualOwnerOfCompany,
        isMember,
        hasBasicCompanyAccess,
      }}
    >
      {children}
    </Provider>
  );
};

CompanyProvider.propTypes = {
  uid: PropTypes.string,
  children: PropTypes.node,
};

export default CompanyProvider;
