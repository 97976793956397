import PropTypes from 'prop-types';
import React from 'react';

import Callout from 'components/Callout/Callout';

import InboxMessagesWrapper from './InboxMessagesWrapper';

const InboxMessagesError = ({ text = '' }) => (
  <InboxMessagesWrapper className="text-gray-600 items-center justify-center">
    <div className="p-8 flex flex-col gap-2 items-center">
      <p className="max-w-prose text-lg text-center">{text}</p>
      <Callout size="sm" testId="inbox-noThreadSelected">
        Select a thread on your left to follow the conversation.
      </Callout>
    </div>
  </InboxMessagesWrapper>
);

InboxMessagesError.propTypes = {
  text: PropTypes.string.isRequired,
};

export default InboxMessagesError;
