import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';

const ModalDescription = ({ children = null, testId = '' }) => (
  <Dialog.Description
    data-test-id={testId}
    className="text-sm text-gray-500 text-center"
  >
    {children}
  </Dialog.Description>
);

ModalDescription.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

export default ModalDescription;
