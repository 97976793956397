import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const ModalOverlay = ({ children = null, testId = '', className = '' }) => (
  <div
    className={classNames('absolute inset-0 rounded-lg', className)}
    data-test-id={testId}
  >
    {children}
  </div>
);

ModalOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
  className: PropTypes.string,
};

export default ModalOverlay;
