// add and delete operations on a Firebase reference

import { addDoc, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';

import useFirestoreWrapper from './useFirestoreWrapper';

// provides the read, create, update and delete operations for a firestore ref
const useCrud = (ref = {}) => {
  // read
  const { status, data } = useFirestoreCollectionData(ref, defaultOptions);
  const { updateDocument } = useFirestoreWrapper();

  // create, update, delete
  const ops = {
    createDoc: (docData) =>
      addDoc(ref, {
        ...docData,
        dateCreated: new Date().toISOString(),
      }),
    updateDoc: (id, docData) => updateDocument(doc(ref, id), docData),

    // look for all other docs that don't match `id`, and update with `docData`
    updateOtherDocs: (id, docData) =>
      getDocs(ref).then((docs) =>
        docs.forEach(
          (d) => d.id !== id && updateDocument(doc(ref, d.id), docData)
        )
      ),
    deleteDoc: (id) => deleteDoc(doc(ref, id)),
  };

  return [status, data, ops];
};

export default useCrud;
