import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ArchiveIcon, BanIcon, ReceiptTaxIcon } from '@heroicons/react/outline';
import noop from 'lodash/noop';
import { useFirestore } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import { legacyIntents } from 'constants/intents';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import useGetSuccessFees from 'hooks/useGetSuccessFees';
import getStatusConfig from 'utils/getStatusConfig';

import AdminMessagesThreadsActionsMarkAsUnsuccessfulModal from './AdminMessagesThreadsActionsMarkAsUnsuccessfulModal';
import onArchive from './helpers/onArchive';
import onMarkAsUnsuccessful from './helpers/onMarkAsUnsuccessful';

const disableLegacyIntents = (options) =>
  options.map((option) => ({
    ...option,
    ...(legacyIntents.includes(option.value) ? { disabled: true } : {}),
    label:
      option.label + (legacyIntents.includes(option.value) ? ' (legacy)' : ''),
  }));

const intentOptions = disableLegacyIntents([
  { label: 'Thought Leadership', value: 'opEd' },
  { label: 'Press Release', value: 'pressRelease' },
  { label: 'Cold outreach', value: 'outreach' },
  { label: 'Opportunity', value: 'opportunity' },
  { label: 'Custom opportunity', value: 'customOpportunity' },
  { label: 'Warm intro', value: 'warmIntro' },
  { label: 'Comment/Quote', value: 'commentQuote' },
  { label: 'Expert opinion', value: 'expertOpinion' },
  { label: 'Custom thread', value: 'customThread' },
]);

const AdminMessagesThreadsActions = ({
  id = '',
  threadRef = noop,
  isArchived = false,
  openSuccessFeesModal = noop,
  intent = '',
}) => {
  const { setDocument } = useFirestoreWrapper();
  const firestore = useFirestore();
  const { showSuccess, showError } = useNotifications();
  const successFeesData = useGetSuccessFees(threadRef);
  const [isMarkAsUnsuccessfulModalOpen, setIsMarkAsUnsuccessfulModalOpen] =
    useState(false);

  const status = successFeesData?.invoice?.status || null;
  const { buttonText, canBeMarkedUnsuccessful } = getStatusConfig(status);

  return (
    <td className="p-4">
      <div className="flex flex-col gap-2">
        <Button
          onClick={(e) => {
            e.stopPropagation();
            openSuccessFeesModal({ id, successFeesData });
          }}
        >
          <span className="flex items-center gap-2">
            <ReceiptTaxIcon className="h-4 w-4" />
            {buttonText}
          </span>
        </Button>
        {canBeMarkedUnsuccessful ? (
          <Button
            type="danger"
            onClick={(e) => {
              e.stopPropagation();
              setIsMarkAsUnsuccessfulModalOpen(true);
            }}
          >
            <span className="flex items-center gap-2">
              <BanIcon className="h-4 w-4" />
              Mark as unsuccessful
            </span>
          </Button>
        ) : null}
        <Button
          type="secondary"
          onClick={onArchive({
            isArchived,
            threadRef,
            showSuccess,
            showError,
            setDocument,
          })}
        >
          <span className="flex items-center gap-2">
            <ArchiveIcon className="h-4 w-4" />
            {isArchived === true ? 'Unarchive' : 'Archive'}
          </span>
        </Button>
        <div onClick={(e) => e.stopPropagation()}>
          <InputFieldDropdown
            options={intentOptions}
            value={intent}
            buttonClassName="w-full"
            onChange={(newIntent) => {
              setDocument(
                threadRef,
                {
                  intent: newIntent,
                },
                { merge: true }
              );
            }}
          />
        </div>
      </div>
      {isMarkAsUnsuccessfulModalOpen ? (
        <AdminMessagesThreadsActionsMarkAsUnsuccessfulModal
          open={isMarkAsUnsuccessfulModalOpen}
          onClose={() => setIsMarkAsUnsuccessfulModalOpen(false)}
          onMarkAsUnsuccessful={onMarkAsUnsuccessful({
            firestore,
            threadRef,
            showSuccess,
            showError,
            setDocument,
            successFeesData,
          })}
        />
      ) : null}
    </td>
  );
};

AdminMessagesThreadsActions.propTypes = {
  id: PropTypes.string,
  threadRef: PropTypes.object,
  isArchived: PropTypes.bool,
  openSuccessFeesModal: PropTypes.func,
  intent: PropTypes.string,
};

export default AdminMessagesThreadsActions;
