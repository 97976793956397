import getContentType from 'constants/contentTypes';
import getFormattedCurrencyValue from 'utils/data/currency/getFormattedCurrencyValue';
import formatTimestamp from 'utils/date/formatTimestamp';

import getSectorNames from './getSectorNames';
import getStockExchangesNames from './getStockExchangesNames';

const getContentTypeCopyText = (content = {}, companyData = {}) => {
  const { type } = content;
  const typeLabel = getContentType(content).label;

  switch (type) {
    case 'opEd': {
      const { title, bulletPoints, linkedInUrl, pastExamples } = content;
      return `${typeLabel}\nTitle: ${title}\n${
        bulletPoints.length ? `- ${bulletPoints.join('\n- ')}\n` : ''
      }Author: ${linkedInUrl}\nPast Writing Examples\n - ${pastExamples?.join(
        '\n - '
      )}`;
    }

    case 'hire': {
      const { name, companyName, position, linkedInUrl } = content;
      return `${typeLabel}\n- Name: ${name}\n- Company Name: ${companyName}\n- Position: ${position}\n- LinkedIn: ${linkedInUrl}`;
    }

    case 'm&a': {
      const {
        companyAcquiredName,
        companyAcquiredCountry,
        companyAcquiringName,
        dealSize,
        sector,
        other,
      } = content;
      return `${typeLabel}\n- Acquired Company: ${companyAcquiredName}\n- Country: ${companyAcquiredCountry}\n- Acquirer: ${companyAcquiringName}\n- Size of deal: ${getFormattedCurrencyValue(
        dealSize.amount,
        dealSize.currency
      )}\n- Sector: ${getSectorNames(sector)}\n${
        other ? `- More info: ${other}` : ''
      }
      `;
    }

    case 'fundraise': {
      const {
        fundingRound,
        leadInvestor,
        capitalRaised,
        valuation,
        embargoDate,
      } = content;
      return `${typeLabel}\n- Company: ${
        companyData?.name
      }\n- Funding Amount: ${getFormattedCurrencyValue(
        capitalRaised?.amount,
        capitalRaised?.currency
      )}\n- Round: ${fundingRound}\n- Valuation: ${getFormattedCurrencyValue(
        valuation?.amount,
        valuation?.currency
      )}\n- Lead Investor: ${leadInvestor}\n- Embargo Date: ${formatTimestamp(
        embargoDate
      )}`;
    }

    case 'brand': {
      const { headline, description, other, embargoDate } = content;
      return `${typeLabel}\n- Headline: ${headline}\n- Description: ${description}${
        other ? `\n- More info: ${other}` : ''
      }\n- Embargo Date: ${formatTimestamp(embargoDate)}`;
    }

    case 'product-service': {
      const { headline, description, other, link, name, embargoDate } = content;
      return `${typeLabel}\n- Headline: ${headline}\n- Description: ${description}${
        other ? `\n- More info: ${other}` : ''
      }\n- Product name: ${name}\n- Link: https://${link}\n- Embargo Date: ${formatTimestamp(
        embargoDate
      )}`;
    }

    case 'partnership': {
      const { headline, companies, embargoDate } = content;
      return `${typeLabel}\n- Headline: ${headline}\n- ${
        companies ? `Companies: ${companies.map((c) => c.name).join(', ')}` : ''
      }\n- Embargo Date: ${formatTimestamp(embargoDate)}`;
    }

    default: {
      const { companyName, stockExchanges, embargoDate, other } = content;
      return `${typeLabel}\n- Company Name: ${companyName}\n- Date: ${formatTimestamp(
        embargoDate,
        true
      )}\n- Stock Exchanges: ${getStockExchangesNames(stockExchanges)}\n${
        other ? `- More info: ${other}` : ''
      }`;
    }
  }
};

export default getContentTypeCopyText;
