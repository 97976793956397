import PropTypes from 'prop-types';
import React from 'react';

import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { CUSTOMERS_COLLECTION } from 'constants/firebasePaths';

import AnalyticsPageLoggerCustomerSubscription from './AnalyticsPageLoggerCustomerSubscription';

const AnalyticscPageLoggerCustomer = ({ uid = '' }) => {
  const firestore = useFirestore();
  const customersDoc = doc(firestore, CUSTOMERS_COLLECTION, uid);
  const customer = useFirestoreDocData(customersDoc, defaultOptions);

  // if no data or UID of Stripe-based document doesn't match uid, return
  if (customer?.data?.uid !== uid) {
    return null;
  }

  return <AnalyticsPageLoggerCustomerSubscription uid={uid} />;
};

AnalyticscPageLoggerCustomer.propTypes = {
  uid: PropTypes.string.isRequired,
};

export default AnalyticscPageLoggerCustomer;
