import { JOURNALIST_RESPONSE_PATH } from 'constants/paths';

import JournalistResponsePage from './JournalistResponsePage';

const JournalistResponseRoute = {
  path: JOURNALIST_RESPONSE_PATH,
  authRequired: false,
  component: JournalistResponsePage,
};

export default JournalistResponseRoute;
