import React from 'react';

import capitalize from 'lodash/capitalize';
import { Helmet } from 'react-helmet';
import { useParams, Route, Routes } from 'react-router-dom';

import PageWrapper from 'components/PageWrapper';
import {
  SETTINGS_BILLING_PATH,
  SETTINGS_COMPANY_PATH,
  SETTINGS_USER_PATH,
} from 'constants/paths';
import useRedirectSettings from 'hooks/useRedirectSettings';
import getPageTitle from 'utils/getPageTitle';

import BillingSettings from './BillingSettings/BillingSettings';
import CompanySettings from './CompanySettings/CompanySettings';
import SettingsAside from './SettingsAside';
import UserSettings from './UserSettings/UserSettings';

const SettingsPage = () => {
  const params = useParams();
  const section = params['*'];

  const showSettings = useRedirectSettings();

  if (!showSettings) {
    return null;
  }

  const title = capitalize(section);

  return (
    <PageWrapper>
      <Helmet>
        <title>{getPageTitle(`${title} settings`)}</title>
      </Helmet>
      <SettingsAside currentSection={section} />
      <Routes>
        <Route path={SETTINGS_USER_PATH} element={<UserSettings />} />
        <Route path={SETTINGS_COMPANY_PATH} element={<CompanySettings />} />
        <Route path={SETTINGS_BILLING_PATH} element={<BillingSettings />} />
      </Routes>
    </PageWrapper>
  );
};

export default SettingsPage;
