import { addDoc, collection, doc } from 'firebase/firestore';
import noop from 'lodash/noop';

import { SUCCESS_FEES_COLLECTION } from 'constants/firebasePaths';

const onMarkAsUnsuccessful =
  ({
    threadRef = {},
    firestore = {},
    successFeesData = {},
    showSuccess = noop,
    showError = noop,
    setDocument = noop,
  }) =>
  async () => {
    try {
      const companyId = threadRef.parent.parent.id;
      const threadId = threadRef.id;
      const newSuccessFees = {
        companyId,
        inboxId: companyId,
        threadId,
        invoice: {
          status: 'unsuccessful',
        },
      };
      if (successFeesData.uid) {
        const successFeesDoc = doc(
          firestore,
          SUCCESS_FEES_COLLECTION,
          successFeesData.uid
        );

        await setDocument(successFeesDoc, newSuccessFees, { merge: true });
      } else {
        const col = collection(firestore, SUCCESS_FEES_COLLECTION);
        await addDoc(col, newSuccessFees);
      }
      showSuccess({ message: 'Thread has been marked as unsuccessful' });
    } catch (error) {
      showError({
        title: 'Whoops, something went wrong',
        message: "We couldn't mark the thread as unsuccessful",
      });
    }
  };

export default onMarkAsUnsuccessful;
