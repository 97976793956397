import React from 'react';

import { StateMachineProvider, createStore } from 'little-state-machine';
import { FirebaseAppProvider, SuspenseWithPerf } from 'reactfire';

import ErrorBoundary from 'components/ErrorBoundary';
import PreloadFirebase from 'components/PreloadFirebase';
import Spinner from 'components/Spinner';
import IubendaCookieConsentModal from 'components/integrations/IubendaCookieConsentModal/IubendaCookieConsentModal';
import { defaultUser, defaultCompany } from 'constants/defaultData';
import EnvironmentProvider from 'contexts/EnvironmentContext/EnvironmentProvider';

import Router from './routes/Router';

createStore({
  user: defaultUser,
  company: defaultCompany,
  cookiesEnabled: null,
  onboardingRef: null,
});

const App = () => (
  <EnvironmentProvider>
    {({ firebaseApp }) => (
      <ErrorBoundary>
        <StateMachineProvider>
          <FirebaseAppProvider suspense firebaseApp={firebaseApp}>
            <SuspenseWithPerf fallback={<Spinner />} traceId="preload-wait">
              <PreloadFirebase>
                <SuspenseWithPerf fallback={<Spinner />} traceId="router-wait">
                  <Router />
                </SuspenseWithPerf>
                <SuspenseWithPerf fallback={<Spinner />} traceId="iubenda-wait">
                  <IubendaCookieConsentModal />
                </SuspenseWithPerf>
              </PreloadFirebase>
            </SuspenseWithPerf>
          </FirebaseAppProvider>
        </StateMachineProvider>
      </ErrorBoundary>
    )}
  </EnvironmentProvider>
);

export default App;
