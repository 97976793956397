const prepareUserTraits = (traitsData, claims) => {
  const genericTraits = {
    isAdmin: traitsData.isAdmin ?? false,
    name: traitsData.name,
    email: traitsData.email,
    createdAt: traitsData.createdAt,
  };

  if (claims.isAdmin) {
    return {
      ...genericTraits,
      ownedCompanyId: null,
      ownedAgencyId: null,
    };
  }

  if (claims.isAgency) {
    return {
      ...genericTraits,
      ownedCompanyId: null,
      ownedAgencyId: traitsData.companyData?.uid,
      company: {
        name: traitsData.agencyData?.name,
        id: traitsData.agencyData?.id,
      },
    };
  }

  if (claims.isActualOwnerOfCompany) {
    return {
      ...genericTraits,
      ownedCompanyId: traitsData.companyData.uid,
      ownedAgencyId: null,
      company: {
        name: traitsData.companyData.name,
        id: traitsData.companyData.slug,
        plan: traitsData?.currentTier?.name || null,
        owner: traitsData.companyData.owner,
      },
    };
  }

  return {
    ...genericTraits,
  };
};

export default prepareUserTraits;
