import * as React from 'react';

const JCBIcon = (props) => (
  <svg
    height={500}
    width={780}
    viewBox="0 0 780 500"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M53.41 15h672.18C746.803 15 764 31.87 764 52.68v395.64c0 20.81-17.197 37.68-38.41 37.68H53.41C32.197 486 15 469.13 15 448.32V52.68C15 31.87 32.197 15 53.41 15z"
      fill="none"
      stroke="currentColor"
      strokeWidth={30}
    />
    <path
      d="M170.22 285.9c-7.062-2.094-21.592-8.773-21.592-8.773l-.147-155.95s-.772-17.423 18.451-35.442c19.222-18.02 40.171-16.861 40.171-16.861h90.418l-.209 299.81s1.372 22.314-15.166 40.623c-18.427 21.185-44.62 20.086-44.62 20.086h-88.869v-126.41l33.07 5.812s12.737 1.271 25.375 1.531c11.078.227 21.363-.744 28.187-1.802 13.056-2.025 19.799-3.797 32.157-11.146 18.415-13.41 17.583-34.005 17.583-34.005l-.48-72.116-51.476.287v70.875s.484 13.295-10.075 21.523c-4.629 3.607-11.372 6.899-23.941 6.899-8.94.002-20.986-2.615-28.837-4.942zm146.46-75.944v-79.443s-.917-23.228 16.283-41.034c17.199-17.807 39.574-18.499 39.574-18.499h93.104v301.29s1.139 22.137-16.966 40.476c-18.037 18.271-37.954 18.379-37.954 18.379H316.68v-138.37s12.447 13.138 47.743 18.108c16.568 2.332 36.17 1.98 52.283 0 18.21-2.239 31.969-6.314 31.969-6.314l-.375-25.08s-6.533 3.984-26.501 10.044c-19.968 6.062-69.859 8.817-71.246-36.365-1.388-45.184 41.112-45.584 60.168-43.199 19.056 2.387 37.954 12.656 37.954 12.656V198.08s-24.054-5.432-34.308-6.556c-12.648-1.385-30.476-2.882-57.773 1.134-27.296 4.018-39.914 17.291-39.914 17.291v.001zm166.06 97.097v122.2h93.481s21.457-2.045 33.991-14.407c20.399-15.591 21.315-40.561 21.315-40.561V68.865h-89.149s-23.775-.738-42.098 17.738c-18.322 18.477-17.541 40.001-17.541 40.001v64.906h90.685s8.332.138 16.561 3.387c6.513 2.57 12.873 7.217 15.606 14.375 6.155 16.08-3.152 27.646-7.913 30.792-6.269 5.442-17.483 7.097-17.483 7.097s16.229 1.643 25.396 9.972c3.029 2.698 6.517 7.212 7.923 11.733 1.854 5.959 1.117 11.765 1.117 11.765s-1.117 10.222-9.672 17.141c-3.65 2.952-8.61 5.663-13.638 7.126-6.759 1.967-13.263 2.156-13.263 2.156H482.74v-.002zm29.802-96.229v29.373h32.173s15.076-.043 15.076-14.708-15.077-14.665-15.077-14.665zm34.412 46.624h-34.412v31.56h34.412c.099 0 16.966.979 16.966-15.78 0-16.758-16.966-15.78-16.966-15.78z"
      fill="currentColor"
    />
  </svg>
);

export default JCBIcon;
