export const ROOT_PATH = '/';
export const ONBOARDING_PATH = '/onboarding/:step';
export const DEFAULT_ONBOARDING_PATH = '/onboarding/one';

// Settings
export const SETTINGS_PATH = 'settings/*';
export const SETTINGS_USER_PATH = 'user';
export const SETTINGS_COMPANY_PATH = 'company';
export const SETTINGS_BILLING_PATH = 'billing';
export const DEFAULT_SETTINGS_PATH = SETTINGS_USER_PATH;

// Auth
export const LOGIN_PATH = '/login';
export const LOGIN_IMPERSONATE_PATH = '/loginImpersonate';
export const PASSWORD_RESET = '/resetPassword';
export const DELETE_ACCOUNT_PATH = '/deleteAccount';
export const SIGNUP_VC_PATH = '/signup';
export const SIGNUP_AGENCY_PATH = '/agencySignup';
export const SIGNUP_MEMBER_PATH = '/signupMember';
export const SIGNUP_JOURNALIST_PATH = '/signupJournalist';

// admins are allowed to see this (custom "admin" claim required)
export const COMPANY_PATH = '/company/:id';
export const OPPORTUNITIES_PATH = '/opportunities';
export const OPPORTUNITIES_SEARCH_PATH = '/opportunitiesSearch';
export const SWEETHEART_PATH = '/sweetheart-list';

// Inbox
export const INBOX_PATH = '/inbox/:tab/:threadId?/:messageId?';

// for VCs and journalists
export const JOURNALIST_RESPONSE_PATH = '/journalistResponse';
export const COMPANIES_JOURNALIST_PATH = '/journalist';

// for agencies
export const AGENCY_PATH = '/agencies/:agencyId?/:companyId?';
export const AGENCY_DASHBOARD_PATH = '/agencies/:agencyId';
export const AGENCY_DASHBOARD_COMPANY_PATH = '/agencies/:agencyId/:companyId';
// Admin paths
export const ADMIN_PATH = '/admin';
export const ADMIN_COMPANIES_PATH = '/admin/companies';
export const ADMIN_OPPORTUNITIES_PATH = '/admin/opportunities';
export const ADMIN_CREATE_OPPORTUNITY_PATH = '/admin/createOpportunity';
export const ADMIN_PUBLICATIONS_PATH = '/admin/publications';
export const ADMIN_JOURNALISTS_PATH = '/admin/journalists';
export const ADMIN_SUCCESS_FEES_PATH = '/admin/successFees';
export const ADMIN_CREATE_CUSTOM_THREAD_PATH = '/admin/createCustomThread';

// Admin messages
export const ADMIN_MESSAGES_PATH_BASE = '/admin/messages';
export const ADMIN_MESSAGES_PATH = `${ADMIN_MESSAGES_PATH_BASE}/:type?/:inboxId?/:threadId?`;
export const ADMIN_MESSAGES_ORPHANS_PATH = 'orphans';
export const ADMIN_MESSAGES_COMPANIES_PATH = 'companies';
export const ADMIN_MESSAGES_COMPANIES_INBOX_PATH = ':inboxId';
export const ADMIN_MESSAGES_COMPANIES_INBOX_THREAD_PATH = ':threadId';
