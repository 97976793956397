import React from 'react';

import { InboxIcon } from '@heroicons/react/solid';
import { deleteDoc, doc } from 'firebase/firestore';
import { Link, generatePath } from 'react-router-dom';
import { useFirestore } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import { INBOXES_COLLECTION } from 'constants/firebasePaths';
import { tabNames } from 'constants/inboxTabs';
import intents from 'constants/intents';
import { INBOX_PATH } from 'constants/paths';
import threadSource from 'constants/threadSource';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import useLogOpportunityInteraction from 'hooks/useLogOpportunityInteraction';
import trackIntercomEvent from 'modules/intercom/trackIntercomEvent';
import createInboxThread from 'utils/createInboxThread';

const removeJournalistDataFromOpportunity = ({ journalistData, ...rest }) =>
  rest;

const useCompaniesOpportunitiesOps = ({ collections = {}, company = {} }) => {
  const { setDocument } = useFirestoreWrapper();
  const { claimsAdmin: isAdmin } = useUserData();

  const firestore = useFirestore();
  const { showError, showSuccess } = useNotifications();
  const logOpportunityInteraction = useLogOpportunityInteraction();

  const { declinedCollection } = collections;

  const createMetadata = () => ({
    dateCreated: new Date(),
    ...(isAdmin ? { byAdmin: true } : {}),
  });

  const ops = {
    // Decline an opportunity
    decline: (opportunity) => {
      logOpportunityInteraction('decline', opportunity);
      trackIntercomEvent('opportunity-decline');
      showSuccess({ message: 'Opportunity declined' });

      return setDocument(doc(declinedCollection, opportunity.uid), {
        opportunity: removeJournalistDataFromOpportunity(opportunity),
        ...createMetadata(),
      });
    },
    undecline: (opportunity) => {
      showSuccess({ message: 'Opportunity reactivated' });
      return deleteDoc(doc(declinedCollection, opportunity.uid));
    },
    respond: async (opportunity) => {
      if (!opportunity) {
        return;
      }

      // grab company inbox
      const inboxRef = doc(firestore, INBOXES_COLLECTION, company.uid);

      const threadId = await createInboxThread({
        inboxRef,
        opportunity: removeJournalistDataFromOpportunity(opportunity),
        source: threadSource.OPPORTUNITY,
        intent: intents.opportunities.OPPORTUNITY,
        status: tabNames.DRAFT,
        setDocument,
      });

      if (!threadId) {
        showError({
          title: "We're sorry! Something went wrong",
          message: "And the message couldn't be sent.",
        });
        return;
      }

      showSuccess({
        title: 'Conversation started',
        Icon: InboxIcon,
        message: (
          <span data-test-id="opportunity-responseModal-success">
            This opportunity has now been moved to your{' '}
            <Link
              to={generatePath(INBOX_PATH, {
                tab: tabNames.DRAFT,
                threadId,
              })}
              className="underline text-teal-500"
            >
              Inbox
            </Link>{' '}
            and saved as a draft.
          </span>
        ),
      });

      trackIntercomEvent('opportunity-respond');
      logOpportunityInteraction('response_send', opportunity);
    },
  };

  return {
    ...ops,
    bulkDecline: (newOpportunities) => newOpportunities.forEach(ops.decline),
    bulkRespond: (newOpportunities) => newOpportunities.forEach(ops.respond),
  };
};

export default useCompaniesOpportunitiesOps;
