import React from 'react';

import { EmojiSadIcon } from '@heroicons/react/solid';

const CompanyPageEmptyState = () => (
  <div
    data-test-id="company-page-emptyState"
    className="bg-gray-50 h-full md:min-h-screen flex flex-col space-y-2 justify-center items-center text-gray-400 p-6 border-l border-gray-200"
  >
    <EmojiSadIcon className="w-24 h-24 text-gray-300" />
    <p className="text-xl">Oops, sorry!</p>
    <p className="text-md text-center">
      Looks like this company hasn&apos;t added any content yet
    </p>
  </div>
);

export default CompanyPageEmptyState;
