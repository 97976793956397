import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useAPIRequest from 'hooks/useAPIRequest';

const InboxMessageResponseFormSendPreviewButton = ({
  threadId = '',
  companyId = '',
  saveDraft = noop,
}) => {
  const { claimsAdmin: isAdmin } = useUserData();
  const { showError, showSuccess } = useNotifications();
  const { fetchData: sendPreview } = useAPIRequest({
    method: 'POST',
    endpoint: `/companies/${companyId}/threads/${threadId}/preview`,
    service: 'MOD',
  });

  if (!isAdmin) {
    return null;
  }

  return (
    <Button
      data-test-id="inboxMessage-responseFormSendPreviewButton"
      type="secondary"
      onClick={async () => {
        await saveDraft();
        try {
          await sendPreview();
          showSuccess({
            title: 'Preview sent',
            message: 'Please check your email.',
          });
        } catch (_) {
          showError({
            title: 'Error sending preview',
            message: 'Please try again later.',
          });
        }
      }}
      className="h-fit"
    >
      Send preview
    </Button>
  );
};

InboxMessageResponseFormSendPreviewButton.propTypes = {
  threadId: PropTypes.string,
  companyId: PropTypes.string,
  saveDraft: PropTypes.func,
};

export default InboxMessageResponseFormSendPreviewButton;
