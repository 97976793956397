import PropTypes from 'prop-types';
import React, { createContext, useMemo } from 'react';

import noop from 'lodash/noop';

import useAPIRequest from 'hooks/useAPIRequest';

const defaultContext = {
  companyId: null,
  companyThreads: [],
  journalists: [],
  isEnabled: false,
  isNotified: false,
  getJournalists: noop,
  getCompanyThreads: noop,
  insertJournalist: noop,
  deleteJournalist: noop,
  updateCompanyMetadata: noop,
  notifyCompany: noop,
  loading: false,
  error: null,
};

export const SweetheartListContext = createContext(defaultContext);

const { Provider } = SweetheartListContext;

const SweetheartListProvider = ({ uid = null, children }) => {
  const { fetchData: notifyCompany } = useAPIRequest({
    endpoint: `/company/${uid}/journalists/notify`,
    service: 'UCD',
    method: 'POST',
  });

  const { fetchData: getCompanyThreads, data: companyThreads } = useAPIRequest({
    endpoint: `/companies/${uid}/threads`,
    service: 'MOD',
    initialData: [],
  });

  const { fetchData: updateCompanyMetadata } = useAPIRequest({
    endpoint: `/company/${uid}/metadata`,
    service: 'UCD',
    method: 'PUT',
  });

  const {
    loading,
    error,
    data,
    fetchData: getJournalists,
  } = useAPIRequest({
    endpoint: `/company/${uid}/journalists`,
    service: 'UCD',
  });

  const { fetchData: insertJournalist } = useAPIRequest({
    endpoint: `/company/${uid}/journalists`,
    service: 'UCD',
    method: 'PUT',
  });

  const { fetchData: deleteJournalist } = useAPIRequest({
    endpoint: `/company/${uid}/journalists`,
    method: 'DELETE',
    service: 'UCD',
  });

  const { isEnabled, isNotified, items: journalists = [] } = data;

  const value = useMemo(
    () => ({
      companyId: uid,
      companyThreads,
      journalists,
      isEnabled,
      isNotified,
      notifyCompany: async () => {
        await notifyCompany();
        await getJournalists();
      },
      getCompanyThreads: async () => {
        getCompanyThreads();
        getJournalists();
      },
      insertJournalist: async (id) => {
        await insertJournalist({ body: { journalistId: id } });
        await getJournalists();
      },
      deleteJournalist: async (id) => {
        await deleteJournalist({ body: { journalistId: id } });
        await getJournalists();
      },
      updateCompanyMetadata: async ({
        isJournalistListEnabled,
        isJournalistListNotified,
      }) => {
        await updateCompanyMetadata({
          body: {
            isJournalistListEnabled,
            isJournalistListNotified,
          },
        });
        await getJournalists();
      },
      loading,
      error,
    }),
    [
      companyThreads,
      deleteJournalist,
      error,
      getCompanyThreads,
      getJournalists,
      insertJournalist,
      isEnabled,
      isNotified,
      journalists,
      loading,
      notifyCompany,
      uid,
      updateCompanyMetadata,
    ]
  );

  return <Provider value={value}>{children}</Provider>;
};

SweetheartListProvider.propTypes = {
  uid: PropTypes.string,
  children: PropTypes.node,
};

export default SweetheartListProvider;
