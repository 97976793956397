import React, { useCallback } from 'react';

import { CheckIcon, MailIcon } from '@heroicons/react/solid';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

const SweetheartListHeaderAdminNotifyButton = () => {
  const { showSuccess, showError } = useNotifications();
  const { notifyCompany, isEnabled, isNotified, companyId } =
    useSweetheartListContext();

  const notifySweetheartList = useCallback(async () => {
    try {
      await notifyCompany({
        companyId,
      });

      showSuccess({
        message: 'Sweetheart list notification sent',
      });
    } catch (err) {
      showError({
        title: 'Error sending the notification',
        message: err.message,
      });
    }
  }, [notifyCompany, companyId, showSuccess, showError]);

  return (
    <Button
      type={isNotified ? 'secondary' : 'primary'}
      onClick={notifySweetheartList}
      disabled={!isEnabled || isNotified}
    >
      {isNotified ? (
        <>
          <CheckIcon className="w-4 h-4 mr-2 text-teal-600" />
          <span className="text-teal-600 italic">Notified</span>
        </>
      ) : (
        <>
          <MailIcon className="w-4 h-4 mr-2" />
          <span>Notify</span>
        </>
      )}
    </Button>
  );
};

export default SweetheartListHeaderAdminNotifyButton;
