import {
  INBOXES_COLLECTION,
  INBOXES_THREADS_COLLECTION,
  INBOXES_THREADS_MESSAGES_COLLECTION,
} from 'constants/firebasePaths';
import { messageStatus } from 'constants/messageStatus';
import useNestedData from 'hooks/useNestedData';

const path = [
  {
    collection: INBOXES_THREADS_COLLECTION,
    subcollections: [
      {
        collection: INBOXES_THREADS_MESSAGES_COLLECTION,
      },
    ],
  },
];

const useUnreadMessagesCount = (uid = '') => {
  const inboxes = useNestedData(
    {
      path: `${INBOXES_COLLECTION}/${uid}`,
      isCollection: false,
      enabled: !!uid,
    },
    path
  );

  if (!inboxes?.threads?.length) {
    return 0;
  }

  const allMessages = inboxes.threads.reduce(
    (acc, thread) => acc.concat(thread.messages),
    []
  );

  const unreadMessages = allMessages.filter(
    (message) =>
      message?.data?.read === false &&
      message?.data?.status === messageStatus.DELIVERED
  );

  return unreadMessages.length;
};

export default useUnreadMessagesCount;
