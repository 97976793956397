import React, { useMemo, useState } from 'react';

import debounce from 'lodash/debounce';

import Spinner from 'components/Spinner';
import InputFieldTagsAutocompleteSuggestions from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocompleteSuggestions';

import useSearchTerms from '../hooks/useSearchTerms';

import useOpportunitiesSearchSuggestions from './hooks/useOpportunitiesSearchSuggestions';

const OpportunitiesControlsSearch = () => {
  const { terms, addTerm, removeTerm } = useSearchTerms();
  const [searchString, setSearchString] = useState('');
  const [suggestions, isLoading] =
    useOpportunitiesSearchSuggestions(searchString);

  const debouncedOnChange = useMemo(
    () => debounce(setSearchString, 250),
    [setSearchString]
  );

  return (
    <div
      className="md:pt-2 relative flex-1 xl:max-w-[50vw]"
      data-intercom-id="opportunities-search"
    >
      <InputFieldTagsAutocompleteSuggestions
        terms={terms}
        addTerm={addTerm}
        removeTerm={removeTerm}
        onChange={debouncedOnChange}
        suggestions={suggestions}
        testId="opportunities-search"
      />
      {isLoading ? (
        <div className="w-full h-full absolute inset-0 flex justify-end">
          <div className="w-6 h-6 bg-white mr-2 mt-2 md:mt-[15px] relative">
            <Spinner />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OpportunitiesControlsSearch;
