import { doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { COMPANIES_COLLECTION } from 'constants/firebasePaths';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';

const useSetCompanyInternal = (companyId = '') => {
  const { setDocument } = useFirestoreWrapper();
  const firestore = useFirestore();
  const companyRef = doc(firestore, COMPANIES_COLLECTION, companyId);

  return (internal) => setDocument(companyRef, { internal }, { merge: true });
};

export default useSetCompanyInternal;
