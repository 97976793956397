import { AnalyticsBrowser } from '@segment/analytics-next';

export default class SegmentAdapter {
  constructor(writeKey) {
    if (!writeKey) {
      // eslint-disable-next-line no-console
      console.warn(
        'Segment write key not provided. Analytics will not be enabled.'
      );
      return;
    }

    this.analytics = AnalyticsBrowser.load({
      writeKey,
    });
  }

  page(pageName, properties = {}) {
    if (!this.analytics) return;

    this.analytics.page(pageName, properties);
  }

  track(eventName, eventData) {
    if (!this.analytics) return;

    this.analytics.track(eventName, eventData);
  }

  identify(userId, params) {
    if (!this.analytics) return;

    this.analytics.identify(userId, params);
  }

  setUserProperties(userId, userProperties) {
    if (!this.analytics) return;

    this.analytics.identify(userId, userProperties);
  }
}
