import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import ExtensionIcon from 'components/icons/ExtensionIcon/ExtensionIcon';
import useGetFilesDownloadUrl from 'hooks/useGetFilesDownloadUrl';
import getIconType from 'utils/file/getIconType';

const CompanyContentElementFiles = ({ content = {} }) => {
  const { file, type } = content;
  const filesWithUrl = useGetFilesDownloadUrl([file], true);

  return (
    <div className="w-full flex space-x-2 text-teal-500">
      {filesWithUrl.map((fileWithUrl) => {
        const iconType = getIconType(fileWithUrl.type);
        return (
          <Link
            key={fileWithUrl.path}
            target="_blank"
            to={fileWithUrl.url}
            data-test-id={`company-contentElement-${type}-${iconType}`}
          >
            <ExtensionIcon type={iconType} className="w-7 h-7" />
          </Link>
        );
      })}
    </div>
  );
};

CompanyContentElementFiles.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CompanyContentElementFiles;
