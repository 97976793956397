import React from 'react';

import { useParams } from 'react-router-dom';

import {
  ADMIN_MESSAGES_COMPANIES_PATH,
  ADMIN_MESSAGES_ORPHANS_PATH,
} from 'constants/paths';

import AdminWrapper from '../AdminWrapper';

import AdminMessagesCompaniesBreadCrumbs from './AdminMessagesCompaniesTab/AdminMessagesCompanies/AdminMessagesCompaniesBreadCrumb/AdminMessagesCompaniesBreadCrumbs';
import AdminMessagesCompaniesTab from './AdminMessagesCompaniesTab/AdminMessagesCompaniesTab';
import AdminMessagesOrphansTab from './AdminMessagesOrphansTab/AdminMessagesOrphansTab';
import AdminMessagesTabs from './AdminMessagesTabs';
import AdminMessagesTimer from './AdminMessagesTimer/AdminMessagesTimer';
import AdminMessagesContext from './contexts/AdminMessagesContext';

const AdminMessagesPage = () => {
  const params = useParams();

  return (
    <AdminMessagesContext>
      <AdminWrapper currentIndex={7} title="Messages">
        <header className="flex flex-col gap-2">
          <AdminMessagesTimer />
          <div className="w-full flex gap-2 mb-2 justify-between">
            <AdminMessagesCompaniesBreadCrumbs />
            <AdminMessagesTabs />
          </div>
        </header>
        {params.type === ADMIN_MESSAGES_COMPANIES_PATH && (
          <AdminMessagesCompaniesTab />
        )}
        {params.type === ADMIN_MESSAGES_ORPHANS_PATH && (
          <AdminMessagesOrphansTab />
        )}
      </AdminWrapper>
    </AdminMessagesContext>
  );
};

export default AdminMessagesPage;
