import PropTypes from 'prop-types';
import React from 'react';

import { ChevronRightIcon } from '@heroicons/react/solid';
import { Link, useLocation, useMatch } from 'react-router-dom';

import useImpersonation from 'contexts/ImpersonationContext/hooks/useImpersonation';

const AgencyWrapperBreadcrumb = ({
  name = '',
  pattern = '',
  isFirst = false,
}) => {
  const match = useMatch({
    path: pattern,
    end: false,
  });

  const { state } = useLocation();

  const { setImpersonatedCompanySlug } = useImpersonation();

  if (!match) {
    return null;
  }

  return (
    <li key={name}>
      <div className="flex items-center">
        {!isFirst && (
          <ChevronRightIcon
            className="mr-4 flex-shrink-0 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        )}
        <Link
          onClick={
            isFirst ? () => setImpersonatedCompanySlug(null) : () => null
          }
          to={match.pathname}
          className="text-sm font-medium text-gray-500 hover:text-gray-700"
          aria-current={match.isExact ? 'page' : undefined}
          state={state}
        >
          {name}
        </Link>
      </div>
    </li>
  );
};

AgencyWrapperBreadcrumb.propTypes = {
  pattern: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isFirst: PropTypes.bool,
};
export default AgencyWrapperBreadcrumb;
