import PropTypes from 'prop-types';
import React from 'react';

import { LightBulbIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import { sizes, getSizeClassNames } from 'components/Callout/helpers/sizes';

// Displays a callout with some information about something
const Callout = ({
  children = null,
  size = 'xs',
  type = 'warning',
  wrapperClassName = '',
  Icon = LightBulbIcon,
  testId = '',
}) => {
  const { iconSizeClassName, textSizeClassName } = getSizeClassNames(size);

  return (
    <div
      className={classNames(
        'p-3 space-x-2 rounded-lg flex items-center mt-4 text-gray-500',
        {
          'bg-yellow-100': type === 'warning',
          'bg-blue-100': type === 'info',
          'bg-red-100': type === 'danger',
          'bg-gray-100': type === 'neutral',
        },
        wrapperClassName
      )}
      data-test-id={testId}
    >
      <Icon className={classNames('shrink-0', iconSizeClassName)} />

      <div className={classNames('leading-snug', textSizeClassName)}>
        {children}
      </div>
    </div>
  );
};

Callout.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(Object.keys(sizes)),
  type: PropTypes.oneOf(['warning', 'info', 'danger', 'neutral']),
  wrapperClassName: PropTypes.string,
  Icon: PropTypes.elementType,
  testId: PropTypes.string,
};

export default Callout;
