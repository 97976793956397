import PropTypes from 'prop-types';
import React from 'react';

import { LinkIcon } from '@heroicons/react/solid';
import { useFirestoreDocData } from 'reactfire';

import useAPIRequest from 'hooks/useAPIRequest';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';

import AdminMessagesMessageApproveButton from '../AdminMessages/AdminMessagesCompaniesTab/AdminMessagesMessages/AdminMessagesMessage/AdminMessagesMessageApproveButton';

const AdminCreateCustomThreadResultsResult = ({ result = {} }) => {
  const { messageRef, path } = result;
  const { data: messageData } = useFirestoreDocData(messageRef);
  const { updateDocument } = useFirestoreWrapper();
  const { data } = useAPIRequest({
    endpoint: `/journalists/${result.id}`,
    service: 'JPD',
  });
  const { name, publication, email } = data || {};

  const update = async (...args) => {
    updateDocument(messageRef, ...args);
  };

  return (
    <div
      key={name}
      className="overflow-hidden flex items-center justify-between bg-gray-50 p-2 rounded-md min-w-[300px] max-w-full relative"
    >
      <p className="min-w-0">
        <p>
          <span className="font-bold">{name}</span>
          <span className="text-gray-500"> {email || 'No email'}</span>
        </p>
        <p>{publication?.name}</p>
      </p>
      <div className="flex gap-2 h-fit items-center">
        <a href={path} target="_blank" rel="noreferrer" className="block">
          <LinkIcon className="w-5 h-5 text-teal-500" />
        </a>
        <AdminMessagesMessageApproveButton
          canBeApproved={!!email}
          status={messageData.status}
          update={update}
        />
      </div>
    </div>
  );
};

AdminCreateCustomThreadResultsResult.propTypes = {
  result: PropTypes.object,
};

export default AdminCreateCustomThreadResultsResult;
