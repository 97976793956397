import PropTypes from 'prop-types';
import React from 'react';

import ContentElementDescription from '../ContentElementDescription';
import ContentElementEmbargoDate from '../ContentElementEmbargoDate';
import ContentElementHeadline from '../ContentElementHeadline';
import ContentElementOther from '../ContentElementOther';
import ContentElementUploadFile from '../ContentElementUploadFile';

const ContentElementBrand = ({ index = 0 }) => (
  <div className="space-y-5 w-full">
    <ContentElementHeadline
      index={index}
      placeholder="Type the headline for this brand announcement..."
    />
    <ContentElementDescription
      index={index}
      placeholder="Type the description for this brand announcement..."
    />
    <ContentElementOther
      index={index}
      placeholder="Type any other relevant information about the announcement..."
    />
    <ContentElementEmbargoDate index={index} />
    <ContentElementUploadFile name="file" label="Add files" />{' '}
  </div>
);

ContentElementBrand.propTypes = {
  index: PropTypes.number,
};

export default ContentElementBrand;
