const formatDate = (date) => {
  const formattedDate = date?.toLocaleString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  });

  return formattedDate;
};

export default formatDate;
