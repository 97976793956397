import { updateProfile } from 'firebase/auth';
import { addDoc, collection, doc } from 'firebase/firestore';
import { useFirestore, useUser } from 'reactfire';

import {
  COMPANIES_COLLECTION,
  COMPANIES_SEARCH_COLLECTION,
  USERS_COLLECTION,
} from 'constants/firebasePaths';
import useAPIRequest from 'hooks/useAPIRequest';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import getCompanyRoute from 'utils/getCompanyRoute';

import useOnboardingStore from '../stores/onboardingStores';

import useIsInvitedByAgency from './useIsInvitedByAgency';

const useSubmitStepFour = () => {
  // const resetCompanyAndUser = useOnboardingStore(
  //   (state) => state.resetCompanyAndUser
  // );

  const { setDocument } = useFirestoreWrapper();
  const invitedByAgencyId = useIsInvitedByAgency();
  const { fetchData } = useAPIRequest({
    method: 'PUT',
    service: 'UCD',
  });

  const user = useOnboardingStore((state) => state.user);
  const company = useOnboardingStore((state) => state.company);

  const { data: userData } = useUser();

  const firestore = useFirestore();

  return async () => {
    if (!userData?.uid) {
      // TODO: add error logging notification that there was an error
      // sc-81 - https://app.shortcut.com/mvpr/story/81/logging-error-handling
      return null;
    }

    const companyRef = collection(firestore, COMPANIES_COLLECTION);
    const userRef = doc(firestore, USERS_COLLECTION, userData?.uid);

    // update auth user with real name
    await updateProfile(userData, {
      displayName: `${user?.firstName} ${user?.lastName}`,
    });

    // update anonymous user with values from state
    await setDocument(userRef, user);

    const { topicsOther, audiencesOther, ...companyData } = company;

    // merge owner with user's uid into company
    const dataWithOwner = {
      ...companyData,
      owner: userData?.uid,
      signupStatus: 0,
    };

    // create company
    let success;
    try {
      const companiesRef = await addDoc(companyRef, dataWithOwner);

      // Search
      const companiesSearchRef = doc(
        firestore,
        COMPANIES_SEARCH_COLLECTION,
        companiesRef.id
      );
      const searchTerms = (company?.topics || []).map((t) => t.name);
      await setDocument(companiesSearchRef, { terms: searchTerms });
      if (invitedByAgencyId) {
        await fetchData({
          endpoint: `/agencies/${invitedByAgencyId}/companies`,
          method: 'PUT',
          body: {
            companyId: companiesRef.id,
          },
        });
      }
      success = true;
    } catch (e) {
      // TODO-LOG: send this to logger
    }

    // If company has been added, reset local state machine data, as we'll pull data from Firebase from now on
    if (success) {
      // creates issues with redirect
      // resetCompanyAndUser();
      const companyRoute = getCompanyRoute(company.slug);
      return companyRoute;
    }
    return null;
  };
};

export default useSubmitStepFour;
