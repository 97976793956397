import { ROOT_PATH as path } from 'constants/paths';

import RootPage from './RootPage';

const RootRoute = {
  path,
  component: RootPage,
};

export default RootRoute;
