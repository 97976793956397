// The font is URW DIN BOLD

const extensionIcons = [
  {
    type: 'DOC',
    paths: [
      'M5.616,14.375L7.477,14.375C8.918,14.39 9.548,13.76 9.512,12.34L9.512,11.239C9.548,9.82 8.911,9.183 7.477,9.205L5.616,9.205L5.616,14.375ZM6.905,13.354L6.905,10.226L7.231,10.226C8.049,10.226 8.209,10.407 8.223,11.341L8.223,12.239C8.209,13.173 8.049,13.354 7.231,13.354L6.905,13.354Z',
      'M10.323,12.384C10.323,13.122 10.425,13.528 10.678,13.847C11.026,14.281 11.511,14.462 12.329,14.462C13.097,14.462 13.546,14.31 13.901,13.941C14.219,13.6 14.335,13.18 14.335,12.384L14.335,11.196C14.335,10.457 14.234,10.052 13.98,9.733C13.633,9.299 13.148,9.118 12.329,9.118C11.562,9.118 11.113,9.27 10.758,9.639C10.439,9.979 10.323,10.399 10.323,11.196L10.323,12.384ZM13.046,12.282C13.046,13.18 12.894,13.426 12.329,13.426C11.764,13.426 11.612,13.18 11.612,12.282L11.612,11.297C11.612,10.399 11.764,10.153 12.329,10.153C12.894,10.153 13.046,10.399 13.046,11.297L13.046,12.282Z',
      'M17.811,12.76C17.753,13.238 17.551,13.426 17.094,13.426C16.573,13.426 16.435,13.18 16.435,12.275L16.435,11.326C16.435,10.421 16.58,10.175 17.094,10.175C17.507,10.175 17.688,10.363 17.739,10.834L18.919,10.754C18.898,10.284 18.825,10.023 18.644,9.762C18.34,9.335 17.855,9.139 17.08,9.139C16.356,9.139 15.914,9.291 15.574,9.668C15.262,10.001 15.146,10.421 15.146,11.225L15.146,12.376C15.146,13.122 15.24,13.521 15.494,13.847C15.834,14.281 16.305,14.462 17.087,14.462C17.819,14.462 18.311,14.303 18.63,13.97C18.883,13.694 18.985,13.419 19.013,12.905L17.811,12.76Z',
    ],
  },
  {
    type: 'JPG',
    paths: [
      'M6.941,9.205L6.941,12.326C6.941,12.739 6.934,12.912 6.898,13.064C6.847,13.311 6.724,13.426 6.514,13.426C6.239,13.426 5.956,13.26 5.623,12.891L4.914,13.723C5.5,14.259 5.978,14.462 6.666,14.462C7.347,14.462 7.803,14.245 8.035,13.803C8.187,13.528 8.23,13.202 8.23,12.318L8.23,9.205L6.941,9.205Z',
      'M10.533,12.644L11.047,12.644C11.779,12.644 12.17,12.55 12.467,12.29C12.793,12.007 12.938,11.594 12.938,10.943C12.938,10.284 12.829,9.921 12.539,9.625C12.242,9.32 11.837,9.205 11.047,9.205L9.244,9.205L9.244,14.375L10.533,14.375L10.533,12.644ZM10.512,11.616L10.512,10.226L11.055,10.226C11.511,10.226 11.663,10.399 11.663,10.921C11.663,11.442 11.511,11.616 11.055,11.616L10.512,11.616Z',
      'M15.668,12.391L16.341,12.391L16.341,12.623C16.341,13.209 16.146,13.426 15.61,13.426C15.052,13.426 14.9,13.18 14.9,12.275L14.9,11.326C14.9,10.421 15.052,10.175 15.603,10.175C16.044,10.175 16.24,10.363 16.298,10.834L17.478,10.754C17.456,10.284 17.377,10.023 17.189,9.762C16.884,9.335 16.385,9.139 15.588,9.139C14.849,9.139 14.4,9.291 14.046,9.668C13.727,10.001 13.611,10.421 13.611,11.225L13.611,12.376C13.611,13.115 13.705,13.521 13.966,13.847C14.306,14.274 14.799,14.462 15.595,14.462C16.443,14.462 16.935,14.274 17.275,13.825C17.529,13.484 17.616,13.115 17.616,12.34L17.616,11.413L15.668,11.413L15.668,12.391Z',
    ],
  },
  {
    type: 'PNG',
    paths: [
      'M6.905,12.644L7.419,12.644C8.151,12.644 8.542,12.55 8.839,12.29C9.165,12.007 9.309,11.594 9.309,10.943C9.309,10.284 9.201,9.921 8.911,9.625C8.614,9.32 8.209,9.205 7.419,9.205L5.616,9.205L5.616,14.375L6.905,14.375L6.905,12.644ZM6.883,11.616L6.883,10.226L7.427,10.226C7.883,10.226 8.035,10.399 8.035,10.921C8.035,11.442 7.883,11.616 7.427,11.616L6.883,11.616Z',
      'M10.041,14.375L11.286,14.375L11.286,11.355L12.872,14.375L14.125,14.375L14.125,9.205L12.88,9.205L12.88,12.224L11.294,9.205L10.041,9.205L10.041,14.375Z',
      'M17.109,12.391L17.782,12.391L17.782,12.623C17.782,13.209 17.587,13.426 17.051,13.426C16.493,13.426 16.341,13.18 16.341,12.275L16.341,11.326C16.341,10.421 16.493,10.175 17.044,10.175C17.485,10.175 17.681,10.363 17.739,10.834L18.919,10.754C18.898,10.284 18.818,10.023 18.63,9.762C18.326,9.335 17.826,9.139 17.029,9.139C16.291,9.139 15.842,9.291 15.487,9.668C15.168,10.001 15.052,10.421 15.052,11.225L15.052,12.376C15.052,13.115 15.146,13.521 15.407,13.847C15.747,14.274 16.24,14.462 17.036,14.462C17.884,14.462 18.376,14.274 18.717,13.825C18.97,13.484 19.057,13.115 19.057,12.34L19.057,11.413L17.109,11.413L17.109,12.391Z',
    ],
  },
  {
    type: 'PDF',
    paths: [
      'M6.905,12.644L7.419,12.644C8.151,12.644 8.542,12.55 8.839,12.29C9.165,12.007 9.309,11.594 9.309,10.943C9.309,10.284 9.201,9.921 8.911,9.625C8.614,9.32 8.209,9.205 7.419,9.205L5.616,9.205L5.616,14.375L6.905,14.375L6.905,12.644ZM6.883,11.616L6.883,10.226L7.427,10.226C7.883,10.226 8.035,10.399 8.035,10.921C8.035,11.442 7.883,11.616 7.427,11.616L6.883,11.616Z',
      'M10.041,14.375L11.902,14.375C13.343,14.39 13.973,13.76 13.937,12.34L13.937,11.239C13.973,9.82 13.336,9.183 11.902,9.205L10.041,9.205L10.041,14.375ZM11.33,13.354L11.33,10.226L11.656,10.226C12.474,10.226 12.633,10.407 12.648,11.341L12.648,12.239C12.633,13.173 12.474,13.354 11.656,13.354L11.33,13.354Z',
      'M14.871,14.375L16.16,14.375L16.16,12.326L17.819,12.326L17.819,11.283L16.16,11.283L16.16,10.247L18.159,10.247L18.159,9.205L14.871,9.205L14.871,14.375Z',
    ],
  },
  {
    type: 'GIF',
    x: 2,
    paths: [
      'M7.557,12.391L8.23,12.391L8.23,12.623C8.23,13.209 8.035,13.426 7.499,13.426C6.941,13.426 6.789,13.18 6.789,12.275L6.789,11.326C6.789,10.421 6.941,10.175 7.492,10.175C7.933,10.175 8.129,10.363 8.187,10.834L9.367,10.754C9.346,10.284 9.266,10.023 9.078,9.762C8.774,9.335 8.274,9.139 7.477,9.139C6.739,9.139 6.29,9.291 5.935,9.668C5.616,10.001 5.5,10.421 5.5,11.225L5.5,12.376C5.5,13.115 5.594,13.521 5.855,13.847C6.195,14.274 6.688,14.462 7.484,14.462C8.332,14.462 8.824,14.274 9.165,13.825C9.418,13.484 9.505,13.115 9.505,12.34L9.505,11.413L7.557,11.413L7.557,12.391Z',
      'M10.374,14.375L11.663,14.375L11.663,9.205L10.374,9.205L10.374,14.375Z',
      'M12.713,14.375L14.002,14.375L14.002,12.326L15.661,12.326L15.661,11.283L14.002,11.283L14.002,10.247L16.001,10.247L16.001,9.205L12.713,9.205L12.713,14.375Z',
    ],
  },
  {
    type: 'WEBP',
    x: 0.4,
    y: 0.7,
    scale: 0.95,
    paths: [
      'M4.422,15.689l0.658,-0l0.605,-2.228l0.606,2.228l0.653,-0l0.866,-3.378l-0.729,0l-0.502,2.408l-0.615,-2.408l-0.563,0l-0.615,2.408l-0.496,-2.408l-0.729,0l0.861,3.378Z',
      'M8.335,15.689l2.152,-0l0,-0.591l-1.433,-0l-0,-0.819l1.197,0l-0,-0.591l-1.197,-0l-0,-0.786l1.414,0l0,-0.591l-2.133,0l-0,3.378Z',
      'M11.083,15.689l1.396,-0c0.383,-0 0.582,-0.047 0.757,-0.175c0.208,-0.161 0.312,-0.416 0.312,-0.771c0,-0.251 -0.047,-0.464 -0.137,-0.606c-0.042,-0.066 -0.071,-0.094 -0.208,-0.213c0.199,-0.156 0.284,-0.369 0.284,-0.69c-0,-0.308 -0.095,-0.559 -0.275,-0.715c-0.175,-0.151 -0.383,-0.208 -0.794,-0.208l-1.335,0l0,3.378Zm0.724,-2.006l0,-0.795l0.559,0c0.288,0 0.402,0.109 0.402,0.398c-0,0.283 -0.114,0.397 -0.402,0.397l-0.559,0Zm0,1.429l0,-0.852l0.62,0c0.284,0 0.397,0.118 0.397,0.426c0,0.303 -0.113,0.426 -0.397,0.426l-0.62,-0Z',
      'M14.816,14.511l0.426,-0c0.454,-0 0.705,-0.066 0.894,-0.232c0.213,-0.184 0.303,-0.445 0.303,-0.87c-0,-0.398 -0.076,-0.639 -0.255,-0.828c-0.19,-0.194 -0.45,-0.27 -0.942,-0.27l-1.154,0l-0,3.378l0.728,-0l0,-1.178Zm-0.014,-0.582l0,-1.041l0.445,0c0.34,0 0.468,0.142 0.468,0.521c0,0.378 -0.128,0.52 -0.468,0.52l-0.445,0Z',
    ],
  },
];

export default extensionIcons;
