import PropTypes from 'prop-types';
import { useEffect } from 'react';

import noop from 'lodash/noop';
import { useMatch } from 'react-router-dom';
import { useUser } from 'reactfire';

import { OPPORTUNITIES_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import callFnWhenConditionMet from 'utils/callFnWhenConditionMet';

const useTrackIntercomSubscribed = ({
  meetsUserCriteria = false,
  onComplete = noop,
}) => {
  const { email, uid, metadata } = useUser().data;
  const isOpportunitiesPath = useMatch(OPPORTUNITIES_PATH);
  const { isActualOwnerOfCompany } = useCompanyData();

  useEffect(() => {
    if (!meetsUserCriteria) {
      return noop;
    }

    const meetsPageCriteria = isActualOwnerOfCompany && isOpportunitiesPath;
    if (!meetsPageCriteria) {
      return noop;
    }

    callFnWhenConditionMet(
      () => window?.Intercom?.booted,
      () => window.Intercom('trackEvent', 'subscribed')
    );
    onComplete();

    return noop;
  }, [
    email,
    isOpportunitiesPath,
    metadata.creationTime,
    uid,
    isActualOwnerOfCompany,
    onComplete,
    meetsUserCriteria,
  ]);
};

useTrackIntercomSubscribed.propTypes = {
  hasPaymentSucceeded: PropTypes.bool,
  isSubscribed: PropTypes.bool,
};

export default useTrackIntercomSubscribed;
