import PropTypes from 'prop-types';
import React from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import noop from 'lodash/noop';

const Stepper = ({
  currentStep = 0,
  totalSteps = 0,
  stepLabel = 'step',
  stepsLabel = 'steps',
  onPrevious = noop,
  onNext = noop,
  className = '',
  testId = '',
}) => {
  const isFirst = currentStep === 0;
  const isLast = currentStep === totalSteps - 1;

  return (
    <div
      className={classNames(
        'z-50 w-full flex justify-center items-center text-sm text-gray-500 gap-2 p-3 px-11 border-b',
        className
      )}
    >
      <button
        type="button"
        onClick={onPrevious}
        disabled={isFirst}
        data-test-id={`${testId}-stepper-prev`}
      >
        <ChevronLeftIcon
          className={classNames('w-8 h-8 sm:w-6 sm:h-6 rounded-full', {
            'opacity-50 cursor-not-allowed': isFirst,
            'hover:bg-gray-50': !isFirst,
          })}
        />
      </button>
      <p
        data-test-id={`${testId}-stepper-count`}
        className="sr-only hidden xs:not-sr-only xs:block text-center"
      >
        <span className="inline-block">
          {currentStep + 1} of {totalSteps}
        </span>{' '}
        <span className="inline-block">
          {totalSteps === 1 ? stepLabel : stepsLabel}
        </span>
      </p>
      <button
        type="button"
        onClick={onNext}
        disabled={isLast}
        data-test-id={`${testId}-stepper-next`}
      >
        <ChevronRightIcon
          className={classNames('w-8 h-8 sm:w-6 sm:h-6 rounded-full', {
            'opacity-50 cursor-not-allowed': isLast,
            'hover:bg-gray-50': !isLast,
          })}
        />
      </button>
    </div>
  );
};

Stepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  stepLabel: PropTypes.string,
  stepsLabel: PropTypes.string,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  className: PropTypes.string,
  testId: PropTypes.string,
};

export default Stepper;
