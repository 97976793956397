import React from 'react';

import {
  COMPANIES_COLLECTION,
  COMPANIES_OPPORTUNITIES_COLLECTION,
  COMPANIES_SEARCH_COLLECTION,
  COMPANIES_SWEETHEARTS_COLLECTION,
  CUSTOMERS_COLLECTION,
  INBOXES_COLLECTION,
  INVOICES_COLLECTION,
  SUCCESS_FEES_COLLECTION,
  USERS_COLLECTION,
} from 'constants/firebasePaths';

const AdminResetModalCollections = () => (
  <ul className="pl-4 list-disc mt-2 space-y-1">
    <li>
      <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
        {COMPANIES_COLLECTION}
      </code>
    </li>
    <li>
      <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
        {COMPANIES_OPPORTUNITIES_COLLECTION}
      </code>
    </li>
    <li>
      <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
        {COMPANIES_SWEETHEARTS_COLLECTION}
      </code>
    </li>
    <li>
      <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
        {COMPANIES_SEARCH_COLLECTION}
      </code>
    </li>
    <li>
      <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
        {CUSTOMERS_COLLECTION}
      </code>
    </li>
    <li>
      <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
        {INBOXES_COLLECTION}
      </code>
    </li>
    <li>
      <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
        {SUCCESS_FEES_COLLECTION}
      </code>
    </li>
    <li>
      <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
        {INVOICES_COLLECTION}
      </code>
    </li>
    <li>
      Delete all files in the following storage folders:
      <ul className="pl-4 list-disc mt-1 space-y-1">
        <li>
          <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
            {COMPANIES_COLLECTION}/[companyUid]
          </code>
        </li>
        <li>
          <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
            {INBOXES_COLLECTION}/[companyUid]
          </code>
        </li>
        <li>
          <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
            {USERS_COLLECTION}/[userUid]
          </code>
        </li>
      </ul>
    </li>
  </ul>
);

AdminResetModalCollections.propTypes = {};

export default AdminResetModalCollections;
