import React from 'react';

import InputFieldMultiButton from 'components/form/inputFields/InputFieldMultiButton';

import { actions } from '../contexts/filtersReducer';
import useAdminMessagesContext from '../contexts/useAdminMessagesContext';

const AdminMessagesFiltersSourceTarget = () => {
  const {
    filters: { showIncoming, showOutgoing },
    dispatch,
  } = useAdminMessagesContext();
  const statusFilters = [
    {
      text: 'Incoming',
      status: showIncoming,
      setStatus: () => dispatch({ type: actions.TOGGLE_INCOMING }),
    },
    {
      text: 'Outgoing',
      status: showOutgoing,
      setStatus: () => dispatch({ type: actions.TOGGLE_OUTGOING }),
    },
  ];
  return (
    <InputFieldMultiButton
      buttons={statusFilters}
      label="Source/Target"
      reset={() => {
        dispatch({ type: actions.RESET_SOURCE_TARGET_FILTERS });
      }}
    />
  );
};

export default AdminMessagesFiltersSourceTarget;
