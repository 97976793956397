import React from 'react';

import { sendPasswordResetEmail } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import { useAuth } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import PageWrapper from 'components/PageWrapper';
import getPageTitle from 'utils/getPageTitle';

import ResetPasswordForm from './ResetPasswordForm';

const ResetPasswordPage = () => {
  const auth = useAuth();
  const { showError, showSuccess } = useNotifications();

  const resetPassword = async (formValues) => {
    try {
      await sendPasswordResetEmail(auth, formValues.email);
      showSuccess({
        title: 'A password reset link has been sent',
        message: 'Please check your inbox',
      });
    } catch (err) {
      showError({
        title: 'There was an issue resetting the password',
        message: err.message,
      });
    }
  };

  return (
    <PageWrapper className="p-0">
      <Helmet>
        <title>{getPageTitle('Reset password')}</title>
      </Helmet>
      <ResetPasswordForm onSubmit={resetPassword} />
    </PageWrapper>
  );
};

export default ResetPasswordPage;
