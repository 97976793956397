import PropTypes from 'prop-types';
import React from 'react';

const SuccessFeesModalDefinition = ({ label = '', children = null }) => (
  <div>
    <p className="text-sm font-medium text-gray-700">{label}</p>
    {typeof children === 'string' ? (
      <p className="font-normal text-base text-gray-700">{children}</p>
    ) : (
      children
    )}
  </div>
);

SuccessFeesModalDefinition.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

export default SuccessFeesModalDefinition;
