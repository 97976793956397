import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import PageWrapper from 'components/PageWrapper';
import Spinner from 'components/Spinner';
import { COMPANIES_JOURNALIST_PATH } from 'constants/paths';
import useRedirectAuth from 'hooks/useRedirectAuth';
import useSignupWithEmail from 'hooks/useSignupWithEmail';
import { useTracking } from 'modules/segment/TrackingProvider';
import getPageTitle from 'utils/getPageTitle';

import useRedirectAfterTimeout from '../hooks/useRedirectAfterTimeout';

import SignupJournalistForm from './SignupJournalistForm';

const SignupJournalistPage = () => {
  const trackingService = useTracking();

  const [loading, setLoading] = useState(false);
  const { showError } = useNotifications();

  const showAuthForm = useRedirectAuth(true);
  const signupWithEmail = useSignupWithEmail('auth-onRegisterJournalist');

  useRedirectAfterTimeout(COMPANIES_JOURNALIST_PATH, showAuthForm);

  if (loading || !showAuthForm) {
    return <Spinner />;
  }

  const onSubmit = async (formValues = {}) => {
    setLoading(true);
    try {
      await signupWithEmail({
        formValues,
        successCb: async (token = {}) => {
          if (token.claims.journalist) {
            await trackingService.track('signup', {
              auth_method: 'email',
              auth_form: 'journalist',
            });
            window.location = COMPANIES_JOURNALIST_PATH;
          }
        },
      });
    } catch (err) {
      setLoading(false);
      showError({
        title: 'There was an issue signing up',
        message: err.message,
      });
    }
    setLoading(false);
  };

  return (
    <PageWrapper className="p-0">
      <Helmet>
        <title>{getPageTitle('Signup as a journalist')}</title>
      </Helmet>
      <SignupJournalistForm onSubmit={onSubmit} />
    </PageWrapper>
  );
};

export default SignupJournalistPage;
