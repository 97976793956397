import { useEffect, useRef, useState } from 'react';

import { getDownloadURL, ref } from 'firebase/storage';
import isEqual from 'lodash/isEqual';
import { useStorage } from 'reactfire';

const useGetFilesDownloadUrl = (files = [], merge = false) => {
  const storage = useStorage();
  const oldFiles = useRef(null);
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    (async () => {
      // If the files are the same we don't need to do anything
      if (isEqual(oldFiles.current, files)) {
        return;
      }

      // If the files have changed, we need to get the download urls
      const cleanFiles = files.filter((file) => file?.path);
      let urlPromises = await Promise.all(
        cleanFiles.map((file) =>
          getDownloadURL(ref(storage, file.path)).catch(() => '#')
        )
      );
      // We set the old files to the new files
      oldFiles.current = files;

      // If merge the urls with the files
      if (merge) {
        urlPromises = urlPromises.map((url, index) => ({
          ...cleanFiles[index],
          url,
        }));
      }

      setUrls(urlPromises);
    })();
  }, [storage, merge, files]);

  return urls;
};

export default useGetFilesDownloadUrl;
