import PropTypes from 'prop-types';
import React from 'react';

import Spinner from 'components/Spinner';

import Modal from './Modal';

// Modal overlay we use when redirecting to Stripe (because this can take some seconds and should block user interactions)
const StripeRedirectModal = ({ isRedirecting = false }) => (
  <Modal open={isRedirecting} testId="stripe-redirect-modal">
    <Modal.Icon
      withBackground={false}
      Icon={() => (
        <div className="relative w-5 h-5">
          <Spinner />
        </div>
      )}
    />
    <Modal.Title>Redirecting to Stripe...</Modal.Title>
    <Modal.Description>This may take up to 10-15 seconds.</Modal.Description>
  </Modal>
);

StripeRedirectModal.propTypes = {
  isRedirecting: PropTypes.bool,
};

export default StripeRedirectModal;
