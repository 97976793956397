const eventServiceMap = {
  // DEFAULT
  defaultAdapter: 'FirebaseAnalytics',

  // SEGMENT
  Login: 'Segment',
  SignedUp: 'Segment',

  Thread_Created: 'Segment',

  Invite_Sent: 'Segment',
  Invite_Claimed: 'Segment',

  // FIREBASE ANALYTICS
};

export default eventServiceMap;
