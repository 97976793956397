import PropTypes from 'prop-types';
import React from 'react';

import { Scrollspy } from '@makotot/ghostui';
import { collection } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import OnboardingWrapper from 'components/OnboardingWrapper/OnboardingWrapper';
import PageWrapper from 'components/PageWrapper';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useQuery from 'hooks/useQuery';
import getPageTitle from 'utils/getPageTitle';

import useSaveJournalistResponse from '../../hooks/useSaveJournalistResponse';

import CompanyHeader from './CompanyHeader/CompanyHeader';
import CompanyJournalistResponseModal from './CompanyJournalistResponseModal';
import CompanyMeta from './CompanyMeta/CompanyMeta';
import CompanyNavbar from './CompanyNavbar/CompanyNavbar';
import CompanyPageEmptyState from './CompanyPageEmptyState';
import CompanyScrollHandler from './CompanyScrollHandler';
import CompanySectionRefs from './CompanySectionRefs/CompanySectionRefs';
import CompanySections from './CompanySections';
import { COMPANY_NAVBAR_OFFSET } from './companyNavbarOffset';
import useCompanyCruds from './hooks/useCompanyCruds';
import useCompanyDataByRef from './hooks/useCompanyDataByRef';

const CompanyPageContent = ({ companyRef = {} }) => {
  const location = useLocation();

  const { claimsAdmin: isAdmin } = useUserData();

  const companyData = useCompanyDataByRef(companyRef);
  const cruds = useCompanyCruds(companyRef);

  const {
    hasBasicCompanyAccess,
    isActualOwnerOfCompany,
    isMember,
    isAgencyOfCompany,
  } = useCompanyData();

  const query = useQuery();
  const isPreview = Boolean(query.get('preview')) && hasBasicCompanyAccess;
  const modalToShow = useSaveJournalistResponse(companyData.uid, query);

  if (
    !companyRef ||
    companyData === null ||
    companyData?.isOwner === null ||
    !cruds
  ) {
    return null;
  }

  const { founders: foundersCrud, ...sectionCruds } = cruds;
  const showOwnerView = Boolean(hasBasicCompanyAccess && !isPreview);

  const children = (
    <PageWrapper className="p-0">
      <Helmet>
        <title>{getPageTitle(`Press page - ${companyData.name}`)}</title>
        <meta name="robots" content="noindex" />
      </Helmet>

      <div className="bg-white flex flex-wrap">
        <CompanyHeader
          companyRef={companyRef}
          companyData={companyData}
          showOwnerView={showOwnerView}
          isPreview={isPreview}
        />
        <CompanyMeta
          {...companyData}
          companyRef={companyRef}
          showOwnerView={showOwnerView}
          foundersCrud={foundersCrud}
          videosRef={collection(companyRef, 'videos')}
        />

        <CompanySectionRefs
          showOwnerView={showOwnerView}
          isPreview={isPreview}
          cruds={sectionCruds}
        >
          {(sectionRefsArray, sectionRefs) =>
            !sectionRefsArray.length && !showOwnerView ? (
              <CompanyPageEmptyState />
            ) : (
              <CompanyScrollHandler
                hash={location.hash}
                sectionRefs={sectionRefs}
              >
                <Scrollspy
                  sectionRefs={sectionRefsArray}
                  offset={COMPANY_NAVBAR_OFFSET}
                >
                  {({ currentElementIndexInViewport }) => (
                    <>
                      <CompanyNavbar
                        currentLocation={location}
                        sectionRefs={sectionRefs}
                        showOwnerView={showOwnerView}
                        currentIndex={currentElementIndexInViewport}
                      />
                      <CompanySections
                        companyId={companyRef.id}
                        cruds={cruds}
                        founders={foundersCrud[1]}
                        sectionRefs={sectionRefs}
                        showOwnerView={showOwnerView}
                        isPreview={isPreview}
                      />
                    </>
                  )}
                </Scrollspy>
              </CompanyScrollHandler>
            )
          }
        </CompanySectionRefs>
      </div>
      <CompanyJournalistResponseModal modalToShow={modalToShow} />
    </PageWrapper>
  );

  // if user is NOT owner/member/agency or admin, don't render onboarding wrapper
  if (!(isActualOwnerOfCompany || isMember || isAgencyOfCompany || isAdmin)) {
    return children;
  }

  return <OnboardingWrapper>{children}</OnboardingWrapper>;
};

CompanyPageContent.propTypes = {
  companyRef: PropTypes.object,
};

export default CompanyPageContent;
