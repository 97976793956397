import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const AdminMessagesThreadsMessagesCountPill = ({
  count = 0,
  activeClassNames = 'bg-green-100 text-green-600',
}) => (
  <div
    className={classNames(
      'flex items-center justify-center w-8 h-8 rounded-full font-semibold',
      {
        [activeClassNames]: count > 0,
        'bg-gray-100 text-gray-600 font-normal': count === 0,
      }
    )}
  >
    {count}
  </div>
);

AdminMessagesThreadsMessagesCountPill.propTypes = {
  count: PropTypes.number,
  activeClassNames: PropTypes.string,
};

export default AdminMessagesThreadsMessagesCountPill;
