import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
} from '@heroicons/react/outline';

const getNotificationIcon = (type = 'success', Icon = null) => {
  if (Icon) {
    return Icon;
  }

  switch (type) {
    case 'success':
      return CheckCircleIcon;
    case 'error':
      return ExclamationCircleIcon;
    case 'warning':
      return ExclamationIcon;
    default:
      return CheckCircleIcon;
  }
};

export default getNotificationIcon;
