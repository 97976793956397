import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import CloseButton from 'components/buttons/CloseButton';

const ModalClose = ({ onClose = noop, testId = '', srMessage = '' }) => (
  <CloseButton
    className="absolute top-4 right-4 z-10"
    onClick={onClose}
    testId={testId}
    srMessage={srMessage}
  />
);

ModalClose.propTypes = {
  srMessage: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

export default ModalClose;
