import { ADMIN_SUCCESS_FEES_PATH as path } from 'constants/paths';

import AdminSuccessFees from './AdminSuccessFeesPage';

const AdminSuccessFeesRoute = {
  path,
  authRequired: true,
  claimsRequired: { admin: true },
  component: AdminSuccessFees,
};

export default AdminSuccessFeesRoute;
