import { useCallback } from 'react';

import { ref, uploadBytes } from 'firebase/storage';
import noop from 'lodash/noop';
import { useDropzone } from 'react-dropzone';
import { useStorage } from 'reactfire';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import createFileFromMetaData from 'utils/file/createFileFromMetadata';
import createFileName from 'utils/file/createFilename';

const useCustomDropzone = ({
  setValue = noop,
  setError = noop,
  clearErrors = noop,
  setLocalFile = noop,
  name = '',
  folder = '',
  preset = {},
}) => {
  const { companyData } = useCompanyData();
  const storage = useStorage();

  const onDropAccepted = useCallback(
    async (files) => {
      const tempFile = files[0];
      const customMetadata = {
        customMetadata: {
          companyId: companyData?.uid,
        },
      };

      // save local file to immediately show something
      setLocalFile((state) => ({
        ...state,
        ...createFileFromMetaData(tempFile),
        path: window.URL.createObjectURL(tempFile),
        isLocal: true,
        isUploading: true,
      }));

      // prepare upload
      const filename = createFileName(tempFile);
      const uploadRef = ref(storage, `${folder}/${filename}`);
      const upload = await uploadBytes(uploadRef, tempFile, customMetadata);

      if (upload.metadata) {
        const file = {
          ...createFileFromMetaData(upload.metadata),
          localName: tempFile.name,
          path: upload.metadata.fullPath,
        };

        setValue(name, file, { shouldValidate: true });

        // set upload of local file to done
        setLocalFile((localFile) => ({
          ...localFile,
          isUploading: false,
        }));
      }
      clearErrors(name);
    },
    [
      companyData?.uid,
      setLocalFile,
      storage,
      folder,
      clearErrors,
      name,
      setValue,
    ]
  );

  return useDropzone({
    onDropRejected: (fileErrors) => {
      const { code } = fileErrors[0].errors[0];

      if (code === 'file-too-large') {
        setError(name, {
          type: code,
          message: preset.maxFilesizeErrorText,
        });

        return;
      }

      if (code === 'file-invalid-type') {
        setError(name, {
          type: code,
          message: preset.acceptErrorText,
        });
      }
    },
    onDropAccepted,
    maxSize: preset.maxFilesize,
    accept: preset.accept,
  });
};

export default useCustomDropzone;
