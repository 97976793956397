import { doc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';

import { COMPANIES_SEARCH_COLLECTION } from 'constants/firebasePaths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import trackIntercomEvent from 'modules/intercom/trackIntercomEvent';

const useSearchTerms = () => {
  const { setDocument, updateDocument } = useFirestoreWrapper();
  const firestore = useFirestore();
  const { companyData } = useCompanyData();
  const companiesSearchRef = doc(
    firestore,
    COMPANIES_SEARCH_COLLECTION,
    companyData.uid
  );
  const { data } = useFirestoreDocData(companiesSearchRef, {
    initialData: undefined,
  });
  const terms = data?.terms || [];

  const addTerm = async (term) => {
    if (!term) {
      return;
    }
    const sanitizedTerm = term.trim();
    if (terms.includes(sanitizedTerm)) {
      return;
    }
    await setDocument(
      companiesSearchRef,
      {
        terms: arrayUnion(sanitizedTerm),
      },
      { merge: true }
    );

    trackIntercomEvent('opportunities-search');
  };
  const removeTerm = async (term) => {
    await updateDocument(companiesSearchRef, {
      terms: arrayRemove(term),
    });
  };
  return { terms, addTerm, removeTerm };
};

export default useSearchTerms;
