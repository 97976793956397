import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

import { useTracking } from 'modules/segment/TrackingProvider';

import getContentTypeAnalyticsEvents from '../helpers/getContentTypeAnalyticsEvents';

const CompanyContentWrapper = ({
  showOwnerView = false,
  onView = noop,
  children = null,
  content = {},
}) => {
  const trackingService = useTracking();

  const className =
    'relative z-0 rounded-lg text-sm border-gray-200 bg-white border';

  const dataTestId = `company-content-${content.type}`;

  const onClick = (e) => {
    e.preventDefault();
    trackingService.track(
      'pressPage_view',
      getContentTypeAnalyticsEvents(content)
    );
    onView(content.uid);
  };

  if (showOwnerView) {
    return (
      <li className={className} data-test-id={dataTestId}>
        {children}
      </li>
    );
  }

  return (
    <li className="flex">
      <div
        onClick={onClick}
        className={classNames(
          className,
          'hover:border-teal-500 bg-white inline hover:shadow-md w-full text-left cursor-pointer'
        )}
        data-test-id={dataTestId}
      >
        {children}
      </div>
    </li>
  );
};

CompanyContentWrapper.propTypes = {
  showOwnerView: PropTypes.bool,
  onView: PropTypes.func,
  children: PropTypes.node.isRequired,
  content: PropTypes.object,
};

export default CompanyContentWrapper;
