import PropTypes from 'prop-types';
import React from 'react';

import flow from 'lodash/flow';
import padStart from 'lodash/padStart';

const SECONDS_PER_HOUR = 3600;
const MINUTES_PER_HOUR = 60;
const SECONDS_PER_MINUTE = 60;

const padWithTwoZeros = (value) => padStart(value, 2, '0');
const formatTime = flow([Math.floor, padWithTwoZeros]);

// Displays the duration (hours:minutes:seconds) for a video
const VideoDuration = ({ title = '', duration = 0 }) => {
  if (!duration) {
    return null;
  }

  const durationInt = parseInt(duration, 10);
  const [hours, minutes, seconds] = [
    Math.floor(durationInt / SECONDS_PER_HOUR),
    Math.floor((durationInt % MINUTES_PER_HOUR) / MINUTES_PER_HOUR),
    durationInt % SECONDS_PER_MINUTE,
  ].map(formatTime);

  return (
    <div className="absolute bottom-4 right-4 bg-white bg-opacity-30 rounded-xl px-2 py-1">
      <span
        className="text-white text-xs font-medium tracking-wider"
        data-test-id={`company-video-${title}-duration`}
      >
        {hours}:{minutes}:{seconds}
      </span>
    </div>
  );
};

VideoDuration.propTypes = {
  title: PropTypes.string.isRequired,
  duration: PropTypes.number,
};

export default VideoDuration;
