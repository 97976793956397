import { useMatch } from 'react-router-dom';

import {
  COMPANY_PATH,
  ONBOARDING_PATH,
  SETTINGS_PATH,
  SWEETHEART_PATH,
  OPPORTUNITIES_PATH,
  INBOX_PATH,
} from 'constants/paths';

// checks the current route and returns if we're on an onboarding route and if we should show the top nav
const useRouteMatchNav = () => {
  const routeMatchOnboarding = useMatch(ONBOARDING_PATH);
  const routeMatchCompany = useMatch(COMPANY_PATH);
  const routeMatchOpportunities = useMatch(OPPORTUNITIES_PATH);
  const routeMatchSettings = useMatch(SETTINGS_PATH);
  const routeMatchSweetheartList = useMatch(SWEETHEART_PATH);
  const routeMatchInbox = useMatch(INBOX_PATH);

  const showTopNav =
    routeMatchCompany ||
    routeMatchOpportunities ||
    routeMatchSettings ||
    routeMatchSweetheartList ||
    routeMatchInbox;

  return [routeMatchOnboarding, showTopNav];
};

export default useRouteMatchNav;
