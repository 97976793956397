const onboardingSteps = [
  {
    number: 1,
    title: 'Step 1',
    name: 'one',
    component: 'one',
    content: { submitText: 'Next question' },
    validate: ({ user }) => user?.prGoals?.length || user?.prGoalsOther,
  },
  {
    number: 2,
    title: 'Step 2',
    name: 'two',
    component: 'two',
    content: { submitText: 'Next question' },
    validate: ({ user }) => user?.prKnowledge,
  },
  {
    number: 3,
    title: 'Step 3',
    name: 'three',
    component: 'three',
    content: {
      title: 'Now a little bit about your audiences…',
      topicsTitle: 'What topics are you keen to talk about with the media?',
      subtitle:
        'We’ll use this to match your company with journalists that will be interested in you.',
      audiencesTitle: 'Who are the people you want to reach with your views?',
      submitText: 'Next question',
    },
    validate: ({ company }) =>
      company?.topics?.length && company?.audiences?.length,
  },
  {
    number: 4,
    title: 'Step 4',
    name: 'four',
    component: 'four',
    content: {
      title: '...and finally about you and your company',
      subtitle: "We'll only show company information on your press page.",
      personalTitle: 'About you',
      personalSubtitle: "We'll use this to create your account.",
      companyTitle: 'About your company',
      companySubtitle: "We'll use this to generate your press page.",
      submitText: 'Create press page',
      placeholders: {
        userFirstName: 'Type your first name...',
        userLastName: 'Type your last name...',
        companyName: 'Type your company name here...',
        companyWebsite: 'Type your company website here...',
        companyLocation: 'Type your company location here...',
      },
      errors: {
        userFirstName: 'Please type in your first name',
        userLastName: 'Please type in your last name',
        companyName: 'Please type in the name of your company',
        companyWebsite: 'Please type in the website of your company',
      },
      labels: {
        vcs: 'Are you associated with any of these VCs?',
      },
    },
    submit: true,
    validate: ({ user, company }) =>
      user?.firstName && user?.lastName && company?.slug && company?.location,
  },
];

export default onboardingSteps;
