import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import ContentElementEmbargoDate from '../ContentElementEmbargoDate';
import ContentElementUploadFile from '../ContentElementUploadFile';

import ContentElementFundraiseCapitalRaised from './ContentElementFundraiseCapitalRaised';
import ContentFundraiseFundingRound from './ContentElementFundraiseFundingRound';
import ContentElementFundraiseLeadInvestors from './ContentElementFundraiseLeadInvestors';
import ContentElementFundraiseNewValuation from './ContentElementFundraiseNewValuation';

const ContentElementFundraise = ({ index = 0, updateCallback = noop }) => (
  <div className="space-y-5 w-full">
    <ContentFundraiseFundingRound />
    <div className="flex gap-5 sm:gap-2 flex-wrap sm:flex-nowrap z-[0]">
      <ContentElementFundraiseCapitalRaised index={index} />
      <ContentElementFundraiseNewValuation index={index} />
    </div>
    <ContentElementFundraiseLeadInvestors
      index={index}
      updateCallback={updateCallback}
    />
    <ContentElementEmbargoDate />
    <ContentElementUploadFile name="file" label="Add files" />
  </div>
);

ContentElementFundraise.propTypes = {
  index: PropTypes.number,
  updateCallback: PropTypes.func,
};

export default ContentElementFundraise;
