import PropTypes from 'prop-types';
import React from 'react';

import { useFormContext } from 'react-hook-form';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { REGEX_HANDLE_LINKEDIN } from 'constants/regex';

const ContentElementOpEdLinkedInUrl = ({ index = 0 }) => {
  const name = 'linkedInUrl';
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputFieldText
      id={`${name}${index}`}
      {...register(name, {
        pattern: {
          value: REGEX_HANDLE_LINKEDIN,
          message: 'Please type a valid LinkedIn URL',
        },
        required: {
          value: true,
          message: 'Please provide a LinkedIn URL',
        },
      })}
      testId="company-content-inputLinkedInUrl"
      errors={errors}
      placeholder="Type the author's Linkedin..."
    >
      <InputFieldText.Label>LinkedIn*</InputFieldText.Label>
      <InputFieldText.Prefix>linkedin.com/in/</InputFieldText.Prefix>
    </InputFieldText>
  );
};

ContentElementOpEdLinkedInUrl.propTypes = {
  index: PropTypes.number,
};

export default ContentElementOpEdLinkedInUrl;
