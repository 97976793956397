import PropTypes from 'prop-types';
import React from 'react';

const SuccessFeesModalTotal = ({ draft = {} }) => {
  const { price, tax, total } = draft;
  return (
    <div className="ml-auto mt-4 flex gap-2 text-gray-700 text-sm">
      <div className="flex flex-col gap-2 text-right">
        <span>Subtotal:</span>
        <span>VAT (20%)</span>
        <span>Total:</span>
      </div>
      <div className="flex flex-col gap-2 text-left font-medium">
        <span>{price?.format()}</span>
        <span>{tax?.format()}</span>
        <span>{total?.format()}</span>
      </div>
    </div>
  );
};

SuccessFeesModalTotal.propTypes = {
  draft: PropTypes.object,
};

export default SuccessFeesModalTotal;
