import { useEffect, useState } from 'react';

import getCompanySearchQuery from './helpers/getCompanySearchQuery';

const SEARCH_URL = process.env.REACT_APP_SEARCH_URL;

// retrive all the companies by using search query and filters from the search endpoint
const useCompaniesBySearch = ({
  searchQuery = '',
  fundingLevels = [],
  idToken = '',
  page = 1,
  minFunding = 0,
  maxFunding = 9999999999,
  selectedTopics = [],
  selectedStages = [],
  selectedContentTypes = [],
}) => {
  const [companyResults, setCompanyResults] = useState({});
  const [availableFilters, setAvailableFilters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const filtersChangedHash = `${selectedTopics.join()}-${selectedStages.join()}-${selectedContentTypes.join()}`;

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${SEARCH_URL}/search/?query=${searchQuery}&page=${page}&${getCompanySearchQuery(
            {
              stages: selectedStages,
              topics: selectedTopics,
              contentTypes: selectedContentTypes,
              minFunding,
              maxFunding,
              fundingLevels,
            }
          )}`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        const data = await response.json();
        setCompanyResults(data);
        setAvailableFilters(data?.facets);

        setError(false);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [searchQuery, idToken, page, minFunding, maxFunding, filtersChangedHash]);

  return {
    loading,
    companies: companyResults.results,
    meta: companyResults.meta,
    availableFilters,
    error,
  };
};

export default useCompaniesBySearch;
