import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import InputFieldMultiUpload from 'components/form/inputFields/InputFieldMultiUpload';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextEditor from 'components/form/inputFields/InputFieldTextEditor';
import { tabNames } from 'constants/inboxTabs';

import InboxMessageResponseFormEditorTemplateDropdown from './InboxMessageResponseFormEditorTemplateDropdown';
import trimMessage from './helpers/trimMessage';
import useAddSavedDraftNotification from './hooks/useAddSavedDraftNotification';

const InboxMessageResponseFormEditor = ({
  opportunity = {},
  draft = {},
  initialTemplate: providerInitialTemplate = '',
  saveDraft = noop,
  control = {},
  reset = noop,
  onResponseType = noop,
  threadSource = '',
}) => {
  const [initialTemplate, setInitialTemplate] = useState(
    providerInitialTemplate
  );

  const { tab } = useParams();
  const containerRef = useRef();
  const addSavedDraftNotification = useAddSavedDraftNotification(containerRef);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    reset({
      subject: draft?.subject || '',
      response: {
        text: draft?.text || '',
        html: draft?.html || '',
      },
      attachments: draft?.attachments || [],
    });
  }, [draft?.text, draft?.html, draft?.attachments, reset, draft?.subject]);

  return (
    <div className="relative">
      <div
        data-test-id="inboxMessage-responseEditor"
        className={classNames('relative flex flex-col gap-2', {})}
        ref={containerRef}
      >
        <div className="flex justify-between items-center flex-wrap">
          <h3 className="text-lg font-medium">
            Respond to {opportunity.journalist}
          </h3>

          {tab === tabNames.DRAFT ? (
            <InboxMessageResponseFormEditorTemplateDropdown
              template={draft.template}
              opportunity={opportunity}
              saveDraft={saveDraft}
              addSavedDraftNotification={addSavedDraftNotification}
              setInitialTemplate={setInitialTemplate}
            />
          ) : null}
        </div>
        {threadSource === 'sweetheartList' && tab === 'draft' ? (
          <Controller
            name="subject"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Please provide a subject',
              },
            }}
            render={({ field, formState: { errors } }) => (
              <InputFieldText
                {...field}
                id="inboxMessage-subjectFormTextInput"
                testId="inboxMessage-responseFormSubject"
                placeholder="Subject Line"
                value={draft?.subject ?? ''}
                errors={errors}
                onChange={(e) => {
                  field.onChange(e);
                  saveDraft(
                    { subject: e.target.value },
                    addSavedDraftNotification
                  );
                }}
              />
            )}
          />
        ) : null}

        <Controller
          name="response"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Please enter a response',
            },
            setValueAs: trimMessage,
            validate: {
              value: (value) => {
                if (value.text.length < 10) {
                  return 'Please enter a response longer than 10 characters';
                }
                return true;
              },
            },
          }}
          render={({ field: { onChange } }) => (
            <InputFieldTextEditor
              name="response"
              initialValue={initialTemplate || ''}
              onChange={(html, text) => {
                onResponseType(text);
                onChange({ html, text });
                saveDraft({ html, text }, addSavedDraftNotification);
              }}
              testId="inboxMessage-responseFormTextInput"
              id="inboxMessage-responseFormTextInput"
            />
          )}
        />
        <Controller
          name="attachments"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputFieldMultiUpload
              onChange={(attachments) => {
                onChange(attachments);
                saveDraft({ attachments });
              }}
              value={draft?.attachments || value}
            />
          )}
        />
      </div>
    </div>
  );
};

InboxMessageResponseFormEditor.propTypes = {
  threadSource: PropTypes.string,
  draft: PropTypes.object,
  opportunity: PropTypes.object,
  saveDraft: PropTypes.func,
  initialTemplate: PropTypes.string,
  control: PropTypes.object,
  onResponseType: PropTypes.func,
  reset: PropTypes.func,
};

export default InboxMessageResponseFormEditor;
