const OTHER_TIER_INDEX = 5;

// Returns the comma-separated tiers
// e.g. => Tier 1,2, Other
const getTiers = (filters = []) =>
  filters
    .map((f) => (f?.index < OTHER_TIER_INDEX ? f?.index : f?.label))
    .sort()
    .join(', ');

const getFilterByText = (filters = []) => {
  let filterText = '';
  let other = null;
  const withoutOther = [];

  filters.forEach((f) => {
    if (f?.index === OTHER_TIER_INDEX) {
      other = f;
    } else {
      withoutOther.push(f);
    }
  });

  if (withoutOther.length) {
    filterText = `Tier ${getTiers(withoutOther)}`;
  }

  if (other) {
    filterText = filterText ? `${filterText}, Other` : 'Other';
  }

  return filterText;
};

export default getFilterByText;
