import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import Modal from 'components/Modal';

const AdminMessagesThreadsQueryModal = ({
  isOpen = false,
  onClose = noop,
  title = '',
  query = '',
}) => (
  <Modal open={isOpen}>
    <Modal.Close srMessage="Close Opportunity Query Modal" onClose={onClose} />
    <Modal.Title>{title}</Modal.Title>
    <Modal.Content>
      <p className="whitespace-pre-line text-gray-500">{query}</p>
    </Modal.Content>
  </Modal>
);

AdminMessagesThreadsQueryModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  query: PropTypes.string,
};

export default AdminMessagesThreadsQueryModal;
