import PropTypes from 'prop-types';
import React from 'react';

import { ContentTypes } from 'constants/contentTypes';
import formatTimestamp from 'utils/date/formatTimestamp';

import CompanyContentElementTableRow from './CompanyContentElementTableRow';

const CompanyContentElementTableFundraise = ({ content = {} }) => {
  const { headline, description, other, embargoDate, name, link } = content;

  return (
    <>
      <CompanyContentElementTableRow
        label="Headline"
        content={headline}
        contentType={ContentTypes.PRODUCT}
      />
      <CompanyContentElementTableRow
        label="Description"
        content={description}
      />

      {other ? (
        <CompanyContentElementTableRow
          label="More info"
          content={other}
          contentType={ContentTypes.PRODUCT}
        />
      ) : null}

      <CompanyContentElementTableRow
        label="Embargo Date"
        content={formatTimestamp(embargoDate)}
        contentType={ContentTypes.PRODUCT}
      />

      <CompanyContentElementTableRow
        type="link"
        label="Product"
        contentType={ContentTypes.PRODUCT}
        content={{ url: link, text: name }}
      />
    </>
  );
};

CompanyContentElementTableFundraise.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CompanyContentElementTableFundraise;
