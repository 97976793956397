import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const OpportunitiesListOpportunityActionsAction = ({
  className = '',
  children,
  onClick,
  testId = '',
}) => (
  <a
    className={classNames(
      'inline-flex items-center border border-gray-200 rounded-md px-2 py-1 space-x-1 text-xs cursor-pointer',
      className
    )}
    onClick={onClick}
    data-test-id={testId}
  >
    {children}
  </a>
);

OpportunitiesListOpportunityActionsAction.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

export default OpportunitiesListOpportunityActionsAction;
