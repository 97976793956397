import PropTypes from 'prop-types';
import React from 'react';

import UrlDomain from 'components/company/UrlDomain';
import { useTracking } from 'modules/segment/TrackingProvider';

import CompanyContentElementFooterLinkedin from '../components/CompanyContentElementFooterLinkedin';

const CompanyContentElementOpEdPastExamples = ({
  pastExamples = [],
  linkedInUrl,
}) => {
  const trackingService = useTracking();

  const onClick = (example) => {
    trackingService.track('pressPage_view', {
      action: 'opEd_pastExample_click',
      url: example.url || null,
    });
  };

  if (!pastExamples.length) {
    return null;
  }

  return (
    <div className="w-full bg-gray-50 p-4 pl-6 pr-6 mt-4">
      <p
        className="font-medium text-lg text-left"
        data-test-id="company-content-opEd-past-examples"
      >
        Past writing examples
      </p>
      <div className="flex gap-2 items-end">
        <ul className="-ml-2 mt-2 grow min-w-0">
          {pastExamples?.map((example, i) => (
            <li key={example}>
              <UrlDomain
                url={example}
                onClick={() => onClick(example)}
                testId={`company-content-opEd-pastExample-${i}`}
                externalLinkIcon
              />
            </li>
          ))}
        </ul>

        <CompanyContentElementFooterLinkedin
          linkedInUrl={`https://www.linkedin.com/in/${linkedInUrl}`}
        />
      </div>
    </div>
  );
};

CompanyContentElementOpEdPastExamples.propTypes = {
  pastExamples: PropTypes.array,
  linkedInUrl: PropTypes.string,
};

export default CompanyContentElementOpEdPastExamples;
