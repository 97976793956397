import PropTypes from 'prop-types';
import React from 'react';

import { LightBulbIcon } from '@heroicons/react/solid';
import ReactDOMServer from 'react-dom/server';
import { Controller, useForm } from 'react-hook-form';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip/Tooltip';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import TextCounter from 'components/form/TextCounter';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import { PAGE_TITLE } from 'constants/meta';
import { REGEX_URL } from 'constants/regex';
import { todaysDateObj } from 'utils/date/dateRange';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

import CompanySnapshotEditModalDate from './CompanySnapshotEditModalDate';
import CompanySnapshotEditModalEmoji from './CompanySnapshotEditModalEmoji';
import emojis from './emojis';

const CompanySnapshotEditModal = ({
  open,
  snapshot = null,
  onClose,
  onSubmit,
}) => {
  const formName = 'company-snapshot-editModal';

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, errors },
    watch,
    setValue,
  } = useForm({ mode: 'onChange' });

  const title = watch('title');

  const defaultDate = snapshot?.date
    ? getDateObjFromTimestamp(snapshot.date)
    : todaysDateObj;

  const defaultValues = snapshot || {};

  return (
    <Modal open={open}>
      <Modal.Close srMessage="Close snapshot edit modal" onClose={onClose} />

      <Modal.Icon Icon={LightBulbIcon} />

      <Modal.Title>{snapshot ? 'Edit' : 'Add a'} milestone</Modal.Title>
      {!snapshot ? (
        <Modal.Description>
          Add a milestone to your profile to show journalists you are a credible
          company.
        </Modal.Description>
      ) : null}
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)} id={formName}>
          <div className="flex w-full justify-between items-baseline mt-5">
            <Label htmlFor="title">Choose an emoji*</Label>
          </div>

          <div className="flex flex-wrap justify-start mt-2 -mb-1">
            <Controller
              name="emoji"
              defaultValue={defaultValues?.emoji || emojis[0].char}
              control={control}
              render={({ field: { onChange, value } }) =>
                emojis.map((emoji, idx) => (
                  <CompanySnapshotEditModalEmoji
                    key={emoji.char}
                    emoji={emoji}
                    onClick={(e) => {
                      e.preventDefault();
                      onChange(emoji.char);
                    }}
                    isSelected={value === emoji.char}
                    isLast={idx === emojis.length - 1}
                  />
                ))
              }
            />
          </div>

          <div className="flex w-full justify-between items-baseline mt-3">
            <Label className="flex items-center" htmlFor="title">
              Describe the milestone*
              <Tooltip
                dataHTML
                wrapperClassName="mr-auto"
                id="tooltip-milestone-description"
                html={ReactDOMServer.renderToString(
                  <p className="pointer-events-auto">
                    Milestones should ideally be a short sentence like a
                    headline. If you need an example, you can view{' '}
                    <a
                      className="underline"
                      href="https://app.mvpr.io/company/mvpr#milestones"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {PAGE_TITLE}&apos;s Milestones here
                    </a>
                  </p>
                )}
              />
            </Label>
            <TextCounter str={title || defaultValues.title} maxLength={140} />
          </div>

          <InputfieldTextArea
            defaultValue={defaultValues.title || null}
            {...register('title', {
              required: 'Please put in a title for this milestone',
              maxLength: {
                value: 140,
                message: 'Please keep the title below 140 characters',
              },
            })}
            placeholder="Share a milestone"
            data-test-id="company-snapshots-editModal-titleInput"
          />
          {errors.title && (
            <ErrorMessage testId="company-snapshots-editModal-titleInput-error">
              {errors.title.message}
            </ErrorMessage>
          )}

          <fieldset className="w-full flex flex-col items-start mt-3">
            <Label htmlFor="date">When did it happen?</Label>
            <CompanySnapshotEditModalDate
              control={control}
              watch={watch}
              setValue={setValue}
              defaultDate={defaultDate}
            />
          </fieldset>

          <InputFieldText
            defaultValue={defaultValues.url || null}
            maxLength={2000}
            errors={errors}
            {...register('url', {
              pattern: {
                value: REGEX_URL,
                message: 'Please type in a valid URL',
              },
            })}
            placeholder="https://"
            testId="company-snapshots-editModal-urlInput"
          >
            <InputFieldText.Label className="w-full flex justify-between mt-3 text-left text-sm font-medium">
              Link to existing coverage about this milestone
              <p className="text-gray-400">Optional</p>
            </InputFieldText.Label>
          </InputFieldText>
        </form>
        <ReactTooltip
          id="tooltip-milestone-description"
          className="max-w-xs text-sm"
          delayHide={1500}
        />
      </Modal.Content>
      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-snapshots-editModal-cancel"
        >
          Cancel
        </Button>
        <Button
          submit
          form={formName}
          disabled={!isValid}
          eventType="pressPage_update"
          eventProperties={{ action: 'milestone_add' }}
          data-test-id="company-snapshots-editModal-submit"
        >
          {snapshot ? 'Update' : 'Add'} milestone
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanySnapshotEditModal.propTypes = {
  open: PropTypes.bool,
  snapshot: PropTypes.shape({
    emoji: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanySnapshotEditModal;
