import PropTypes from 'prop-types';

import { collection, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import activeSubscriptionStatus from 'constants/activeSubscriptionStatus';
import {
  CUSTOMERS_COLLECTION,
  CUSTOMERS_SUBSCRIPTIONS_COLLECTION,
} from 'constants/firebasePaths';
import { useTracking } from 'modules/segment/TrackingProvider';

const AnalyticsPageLoggerCustomerSubscription = ({ uid = '' }) => {
  const trackingService = useTracking();
  const firestore = useFirestore();
  const col = collection(
    firestore,
    CUSTOMERS_COLLECTION,
    uid,
    CUSTOMERS_SUBSCRIPTIONS_COLLECTION
  );
  const q = query(col, where('status', 'in', activeSubscriptionStatus));

  const subscription = useFirestoreCollectionData(q);

  const item = subscription?.data?.[0]?.items?.[0];

  if (!item) {
    return null;
  }

  const priceId = item?.price?.id;
  const productId = item?.plan?.product;

  if (!priceId || !productId) {
    return null;
  }

  trackingService.setUserProperties(uid, {
    productId,
    priceId,
  });

  return null;
};

AnalyticsPageLoggerCustomerSubscription.propTypes = {
  uid: PropTypes.string.isRequired,
};

export default AnalyticsPageLoggerCustomerSubscription;
