import { AGENCY_PATH as path } from 'constants/paths';

import AgencyDashboardPage from './AgencyDashboardPage';

const AgencyRoute = {
  path,
  authRequired: true,
  component: AgencyDashboardPage,
};

export default AgencyRoute;
