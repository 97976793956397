import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { InformationCircleIcon } from '@heroicons/react/outline';
import {
  EyeIcon,
  ChartSquareBarIcon,
  NewspaperIcon,
  StarIcon,
} from '@heroicons/react/solid';
import { doc, updateDoc } from 'firebase/firestore';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useFirestore } from 'reactfire';

import Modal from 'components/Modal';
import SocialLogin from 'components/SocialLogin';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import {
  COMPANIES_COLLECTION,
  USERS_COLLECTION,
} from 'constants/firebasePaths';
import { PAGE_TITLE } from 'constants/meta';
import { LOGIN_PATH } from 'constants/paths';
import { REGEX_EMAIL } from 'constants/regex';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useSignIn from 'hooks/useSignIn';
import useSignup from 'hooks/useSignup';
import { useTracking } from 'modules/segment/TrackingProvider';

// Modal to sign up. Also includes the a checkbox for confirming the T&Cs and opt in to marketing
const OnboardingWrapperSignupModal = ({
  open = false,
  onSignupComplete = noop,
}) => {
  const trackingService = useTracking();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: sessionStorage.getItem('companyCommsEmail') || '',
      password: '',
      termsAndConditions: false,
      marketingOptIn: false,
    },
  });

  const termsChecked = watch('termsAndConditions', false);
  const marketingOptInChecked = watch('marketingOptIn', false);

  const { companyData } = useCompanyData();
  const { uid } = useUserData();

  const firestore = useFirestore();
  const companyRef = doc(firestore, COMPANIES_COLLECTION, companyData.uid);
  const userRef = doc(firestore, USERS_COLLECTION, uid);

  const signUpWithEmail = useSignup();
  const { signInWithGoogle } = useSignIn(true);

  const updateCompany = () => updateDoc(companyRef, { signupStatus: 1 });
  const updateUser = async (email) => {
    await updateDoc(userRef, {
      email,
      marketingOptIn: marketingOptInChecked,
      signupConfirmationSent: 0,
    });
    sessionStorage.removeItem('companyCommsEmail');
  };

  const signupWithEmailFlow = async (formValues, e) => {
    const result = await signUpWithEmail(formValues, e);
    if (result) {
      await updateUser(formValues.email);
      await updateCompany();
      trackingService.track('signup', {
        auth_method: 'email',
        auth_form: 'modal',
      });
      onSignupComplete();
    }
  };

  const signInWithGoogleFlow = async () => {
    const result = await signInWithGoogle();
    if (result) {
      await updateUser(result.user.email);
      await updateCompany();
      trackingService.track('signup', {
        auth_method: 'google',
        auth_form: 'modal',
      });
      onSignupComplete();

      // we're returning a result here so the calling function knows whether the sign in was successful or not...
      return result;
    }
    return null;
  };

  const debouncedRef = useRef({});
  const makeInputFieldLogger = (action) => {
    if (debouncedRef.current[action]) {
      return debouncedRef.current[action];
    }

    debouncedRef.current[action] = debounce(
      () =>
        trackingService.track('onboarding_signup', {
          action,
        }),
      1000
    );

    return debouncedRef.current[action];
  };

  const makeCheckboxLogger = (action) => (event) =>
    event?.target?.checked &&
    trackingService.track('onboarding_signup', {
      action,
    });

  return (
    <Modal open={open} widthClass="sm:max-w-lg md:max-w-3xl lg:max-w-5xl">
      <Modal.Content>
        <div className="p-6 flex flex-col md:flex-row justify-between">
          <div className="flex-1 hidden md:block">
            <h3 className="text-xl font-semibold">
              Why should you join {PAGE_TITLE}?
            </h3>

            <ul className="mt-6 space-y-6">
              <li className="flex text-md">
                <div className="mr-3 mt-0.5">
                  <ChartSquareBarIcon className="text-teal-500 w-5 h-5" />
                </div>
                <p className="text-gray-500">
                  Learn what journalists want to write about and see data on how
                  they engage with your content and your company
                </p>
              </li>
              <li className="flex text-md">
                <div className="mr-3 mt-0.5">
                  <NewspaperIcon className="text-teal-500 w-5 h-5" />
                </div>
                <p className="text-gray-500">
                  Be guided on how to share visual, detailed information with
                  journalists in the right way to increase your chances of
                  getting coverage
                </p>
              </li>
              <li className="flex text-md">
                <div className="mr-3 mt-0.5">
                  <EyeIcon className="text-teal-500 w-5 h-5" />
                </div>
                <p className="text-gray-500">
                  Get in front of readers that are interested in your company
                  and your product
                </p>
              </li>
              <li className="flex text-md">
                <div className="mr-3 mt-0.5">
                  <StarIcon className="text-teal-500 w-5 h-5" />
                </div>
                <p className="text-gray-500">
                  See speaking and award opportunities that are relevant for
                  your company and its founders as they arise
                </p>
              </li>
            </ul>
          </div>
          {/* vertical divider spans whole height */}
          <div className="hidden md:block w-px bg-gray-200 mx-4" />
          <div className="flex-1 mt-4 sm:mt-0">
            <h3 className="text-xl font-semibold text-teal-500">
              Save your press page
            </h3>
            <p className="mt-4 text-gray-500">
              Create an account to get started or{' '}
              <Link to={LOGIN_PATH} className="underline hover:no-underline">
                log in
              </Link>
            </p>

            <form
              className="space-y-4 mt-4"
              onSubmit={handleSubmit(signupWithEmailFlow)}
              data-test-id="signupModal-form"
            >
              <InputFieldText
                errors={errors}
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Please provide an email address',
                  },
                  pattern: {
                    value: REGEX_EMAIL,
                    message: 'Please provide a valid email address',
                  },
                  onChange: makeInputFieldLogger('email_type'),
                })}
                type="email"
                autoComplete="email"
                placeholder="steve@apple.com"
                testId="signupModal-form-email"
              >
                <InputFieldText.Label>
                  Email address
                  <InformationCircleIcon
                    className="inline w-5 h-5 sm:w-4 sm:h-4 ml-2 sm:ml-1 text-gray-400 hover:text-gray-500"
                    data-tooltip-content="We'll need this email to create your account, handle payments and share invoices."
                    data-class="w-full max-w-[240px] sm:max-w-[320px]"
                    data-tooltip-id="tooltip-onboarding-signup"
                  />
                  <ReactTooltip
                    id="tooltip-onboarding-signup"
                    className="text-sm"
                  />
                </InputFieldText.Label>
              </InputFieldText>

              <InputFieldText
                errors={errors}
                {...register('password', {
                  required: 'Please provide a password',
                  onChange: makeInputFieldLogger('password_type'),
                })}
                type="password"
                autoComplete="current-password"
                placeholder="Your password"
                testId="signupModal-form-password"
              >
                <InputFieldText.Label>Create a password</InputFieldText.Label>
              </InputFieldText>

              <div className="flex items-center pt-1">
                <input
                  id="termsAndConditions"
                  {...register('termsAndConditions', {
                    required: true,
                    onChange: makeCheckboxLogger('terms_accept'),
                  })}
                  type="checkbox"
                  className="h-4 w-4 mr-2 text-teal-500 focus:ring-teal-500 border-gray-300 rounded cursor-pointer"
                  data-test-id="signupModal-form-terms"
                />
                <Label htmlFor="termsAndConditions">
                  I agree to the{' '}
                  <a
                    href="https://www.iubenda.com/terms-and-conditions/17443214"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    Terms &amp; Conditions
                  </a>
                </Label>
              </div>
              <div className="flex items-center cursor-pointer">
                <input
                  id="marketingOptIn"
                  {...register('marketingOptIn', {
                    onChange: makeCheckboxLogger('marketing_optIn'),
                  })}
                  type="checkbox"
                  className="h-4 w-4 mr-2 text-teal-600 focus:ring-teal-500 border-gray-300 rounded cursor-pointer"
                />
                <Label htmlFor="marketingOptIn">
                  I would like to be contacted for marketing
                </Label>
              </div>

              <div className="pt-1">
                <Button
                  size="base"
                  submit
                  full
                  disabled={isSubmitting || !isValid}
                  data-test-id="signupModal-form-submit"
                >
                  {isSubmitting && 'Creating account...'}
                  {!isSubmitting && 'Create an account'}
                </Button>
              </div>
            </form>

            <SocialLogin
              signInWithGoogle={signInWithGoogleFlow}
              disabled={!termsChecked}
            />
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

OnboardingWrapperSignupModal.propTypes = {
  open: PropTypes.bool,
  onSignupComplete: PropTypes.func,
};

export default OnboardingWrapperSignupModal;
