import React, { Suspense, useState } from 'react';

import CompanyThreadAssignModal from 'components/CompanyThreadAssignModal/CompanyThreadAssignModal';
import Button from 'components/buttons/Button';
import SuccessFeesModal from 'components/successFees/SuccessFeesModal/SuccessFeesModal';

const initialSuccessFeeData = {
  companyId: null,
  threadId: null,
};

const AdminSuccessFeesAdd = () => {
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showSuccessFeeModal, setShowSuccessFeeModal] = useState(false);
  const [successFeeData, setSuccessFeeData] = useState(initialSuccessFeeData);

  return (
    <>
      <Button onClick={() => setShowAssignModal(true)} className="w-fit">
        Add Success Fee
      </Button>
      <CompanyThreadAssignModal
        isOpen={showAssignModal}
        submitButtonText="Next"
        title="Select a company and/or a thread."
        isValid={(companyId) => companyId}
        onClose={() => {
          setSuccessFeeData(initialSuccessFeeData);
          setShowAssignModal(false);
        }}
        onSubmit={(companyId, threadId) => {
          setSuccessFeeData({
            companyId,
            threadId,
          });
          setShowAssignModal(false);
          setShowSuccessFeeModal(true);
        }}
      />
      <Suspense>
        <SuccessFeesModal
          open={showSuccessFeeModal}
          onClose={() => setShowSuccessFeeModal(false)}
          companyId={successFeeData.companyId}
          threadId={successFeeData.threadId}
        />
      </Suspense>
    </>
  );
};

export default AdminSuccessFeesAdd;
