import { useFirestoreDocData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import topicFilter from 'utils/data/topicFilter';

// @TODO Investigate if we can remove this in favor of CompanyContext
const useCompanyDataByRef = (ref = {}) => {
  const { uid, claimsAdmin, claimsAgencyId } = useUserData();
  const { data, status } = useFirestoreDocData(ref, defaultOptions);

  if (status === 'loading') {
    return null;
  }

  if (!data) {
    return null;
  }

  const matchesOwner = data.owner === uid;

  // user is owner, or company belongs to VC, or user is admin

  // this needs to be reworked as we move to the agency portal
  const hasAgencyPrivileges =
    claimsAgencyId && (data?.members || []).includes(uid);
  const isOwner = !!(matchesOwner || claimsAdmin || hasAgencyPrivileges);

  return {
    ...data,
    topics: data?.topics?.filter(topicFilter.isNotNone),
    isOwner,
    matchesOwner,
  };
};

export default useCompanyDataByRef;
