import PropTypes from 'prop-types';
import React from 'react';

import { ChevronRightIcon } from '@heroicons/react/solid';
import { Link, useMatch } from 'react-router-dom';

const AdminMessagesCompaniesBreadCrumb = ({
  name = '',
  pattern = '',
  path = '',
  isFirst = false,
}) => {
  const match = useMatch(pattern);

  if (!match) {
    return null;
  }

  return (
    <li key={name}>
      <div className="flex items-center">
        {!isFirst && (
          <ChevronRightIcon
            className="mr-4 flex-shrink-0 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        )}
        <Link
          to={path}
          className="text-sm font-medium text-gray-500 hover:text-gray-700"
          aria-current={match.isExact ? 'page' : undefined}
        >
          {name}
        </Link>
      </div>
    </li>
  );
};

AdminMessagesCompaniesBreadCrumb.propTypes = {
  name: PropTypes.string,
  pattern: PropTypes.string,
  path: PropTypes.string,
  isFirst: PropTypes.bool,
};

export default AdminMessagesCompaniesBreadCrumb;
