import { collection, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';

// retrive all the companies in the companies collection
const useCompanies = () => {
  const firestore = useFirestore();

  const col = collection(firestore, COMPANIES_COLLECTION);
  const q = query(col, where('slug', '!=', null));

  const companies = useFirestoreCollectionData(q, defaultOptions);

  if (!companies?.data) {
    return null;
  }

  return companies.data;
};

export default useCompanies;
