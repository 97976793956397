import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import PublicationImage from 'components/images/PublicationImage';
import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';
import getTimeFromTimestamp from 'utils/date/getTimeFromTimestamp';

const InboxMessageHeaderDate = ({
  isJournalist = false,
  dateCreated = '',
  publication = {},
  isRead = false,
}) => (
  <div className="ml-auto items-center flex gap-4 ">
    {isJournalist && (
      <PublicationImage
        url={publication?.image}
        name={publication?.name}
        className="w-10 h-10 hidden sm:block"
      />
    )}
    <div
      className={classNames('flex flex-col gap-1 items-end text-sm', {
        'text-gray-500 font-light': isRead,
        'text-gray-700 font-medium': !isRead,
      })}
    >
      <p>{getTimeFromTimestamp(dateCreated)}</p>
      <p data-test-id="inboxMessage-date">
        {getDateFromTimestamp(dateCreated, { month: 'long' })}
      </p>
    </div>
  </div>
);

InboxMessageHeaderDate.propTypes = {
  isJournalist: PropTypes.bool,
  dateCreated: PropTypes.string,
  publication: PropTypes.object,
  isRead: PropTypes.bool,
};

export default InboxMessageHeaderDate;
