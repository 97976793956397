import React from 'react';

import InputFieldMultiButton from 'components/form/inputFields/InputFieldMultiButton';

import { actions } from '../contexts/filtersReducer';
import useAdminMessagesContext from '../contexts/useAdminMessagesContext';

const AdminThreadsFiltersJournalist = () => {
  const {
    filters: { showJournalistWithEmail, showJournalistWithoutEmail },
    dispatch,
  } = useAdminMessagesContext();

  const journalistFilters = [
    {
      text: 'With email',
      status: showJournalistWithEmail,
      setStatus: () => dispatch({ type: actions.TOGGLE_JOURNALIST_WITH_EMAIL }),
    },
    {
      text: 'Without email',
      status: showJournalistWithoutEmail,
      setStatus: () =>
        dispatch({ type: actions.TOGGLE_JOURNALIST_WITHOUT_EMAIL }),
    },
  ];

  return (
    <InputFieldMultiButton
      buttons={journalistFilters}
      label="Journalist"
      reset={() => {
        dispatch({ type: actions.RESET_JOURNALIST_FILTERS });
      }}
    />
  );
};

export default AdminThreadsFiltersJournalist;
