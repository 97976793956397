import React, { useEffect } from 'react';

import 'firebase/auth';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import PageWrapper from 'components/PageWrapper';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useQuery from 'hooks/useQuery';
import useRedirectAuth from 'hooks/useRedirectAuth';
import useSignIn from 'hooks/useSignIn';
import { useTracking } from 'modules/segment/TrackingProvider';
import getPageTitle from 'utils/getPageTitle';

import OnboardingLink from '../OnboardingLink';

import LoginForm from './LoginForm';

const LoginPage = () => {
  const { isAnonymous } = useUserData();
  const showAuth = useRedirectAuth();
  const { signInWithEmail, signInWithGoogle } = useSignIn();
  const { showSuccess } = useNotifications();
  const query = useQuery();
  const navigate = useNavigate();
  const trackingService = useTracking();

  useEffect(() => {
    trackingService.page('Login');
  }, [trackingService]);

  useEffect(() => {
    // Show a success modal if the redirect comes from account deletion
    if (query.get('accountDeleted') === 'true') {
      showSuccess({ message: 'Your account has been deleted' });
      navigate(
        {
          search: null,
        },
        { replace: true }
      );
    }
  }, [navigate, query, showSuccess]);

  // Don't render anything because the user should be redirected
  if (!showAuth) {
    return null;
  }

  return (
    <PageWrapper className="p-0">
      <Helmet>
        <title>{getPageTitle('Login')}</title>
      </Helmet>
      <LoginForm
        signInWithEmail={signInWithEmail}
        signInWithGoogle={signInWithGoogle}
      />
      <OnboardingLink isAnonymous={isAnonymous} />
    </PageWrapper>
  );
};

export default LoginPage;
