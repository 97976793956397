import PropTypes from 'prop-types';
import React from 'react';

import { OfficeBuildingIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import UniversalImage from 'components/UniversalImage/UniversalImage';

const CompanyImage = ({
  image = {},
  className = 'w-8 h-8',
  alt = 'Logo of company',
  ...props
}) => (
  <div
    className={classNames(
      'bg-white outline outline-1 outline-black/10 rounded-sm overflow-hidden relative',
      className
    )}
  >
    <UniversalImage
      alt={alt}
      contain
      image={image}
      placeholderImage={<OfficeBuildingIcon className="text-gray-200 p-1 " />}
      {...props}
    />
  </div>
);

CompanyImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  alt: PropTypes.string,
};
export default CompanyImage;
