import { addDoc, collection, doc, getDoc } from 'firebase/firestore';
import noop from 'lodash/noop';

import {
  COMPANIES_COLLECTION,
  INVOICES_COLLECTION,
  SUCCESS_FEES_COLLECTION,
} from 'constants/firebasePaths';
import successFeeStatuses from 'constants/successFeeStatuses';

const createOnSendReceipt =
  ({
    isValid = false,
    title = '',
    link = '',
    draft = {},
    companyId = '',
    successFeesUid = '',
    firestore = {},
    onSaveInvoiceDraft = noop,
    setDocument = noop,
  }) =>
  async () => {
    if (!isValid) {
      return;
    }

    await onSaveInvoiceDraft();
    const { product } = draft;
    const description = title ? `${title} - ${product.name}` : product.name;

    const companyDoc = await getDoc(
      doc(firestore, COMPANIES_COLLECTION, companyId)
    );
    const ownerId = companyDoc.data().owner;

    const invoicesCol = collection(firestore, INVOICES_COLLECTION);
    const invoiceDoc = await addDoc(invoicesCol, {
      uid: ownerId,
      successFeesUid,
      companyId,
      articleLink: link,
      items: [
        {
          productId: product.uid,
          description,
        },
      ],
    });

    const successFeesDoc = doc(
      firestore,
      SUCCESS_FEES_COLLECTION,
      successFeesUid
    );
    await setDocument(
      successFeesDoc,
      {
        invoice: {
          uid: invoiceDoc.id,
          status: successFeeStatuses.INVOICED,
        },
        pendingEmailNotification: true,
      },
      { merge: true }
    );
  };

export default createOnSendReceipt;
