import React from 'react';

const StepDivider = () => (
  <svg
    className="shrink:1 sm:shrink-0 w-6 h-full ml-2 text-gray-200"
    viewBox="0 0 24 44"
    preserveAspectRatio="none"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
  </svg>
);

export default StepDivider;
