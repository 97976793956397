const emojis = [
  { char: '🚀', label: 'Launched Product' },
  { char: '💸', label: 'Raised Funding' },
  { char: '🤝', label: 'Partnership Agreed' },
  { char: '🔥', label: 'Growth statistic' },
  { char: '🌍', label: 'New market launch' },
  { char: '🧠', label: 'New hire' },
  { char: '🥇', label: 'Award' },
  { char: '🏢', label: 'Founded' },
  { char: '🥳', label: 'Company anniversary' },
  { char: '💫', label: 'Other kind of milestone' },
];

export default emojis;
