import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import ContentElementUploadFile from '../ContentElementUploadFile';

import ContentElementOpEdArticleIdea from './ContentElementOpEdArticleIdea';
import ContentElementOpEdLinkedInUrl from './ContentElementOpEdLinkedInUrl';
import ContentElementOpEdWritingExamples from './ContentElementOpEdWritingExamples';

const ContentElementOpEd = ({ index = 0, updateCallback = noop }) => (
  <div className="relative flex flex-col items-center space-y-5">
    <ContentElementOpEdLinkedInUrl index={index} />
    <ContentElementOpEdWritingExamples
      index={index}
      updateCallback={updateCallback}
    />
    <ContentElementOpEdArticleIdea index={index} />
    <ContentElementUploadFile name="file" label="Add files" />
  </div>
);

ContentElementOpEd.propTypes = {
  index: PropTypes.number,
  updateCallback: PropTypes.func,
};

export default ContentElementOpEd;
