import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

import noop from 'lodash/noop';

import localStorageKeys from 'constants/localStorageKeys';
import useUserData from 'contexts/UserContext/hooks/useUserData';

const defaultImpersonationData = {
  impersonatedCompanySlug: null,
  setImpersonatedCompanySlug: noop,
};
export const ImpersonationContext = createContext(defaultImpersonationData);

const { Provider } = ImpersonationContext;

const ImpersonationProvider = ({ children = null }) => {
  const [impersonatedCompanySlug, setImpersonatedCompanySlug] = useState(() => {
    if (typeof window === 'undefined') {
      return null;
    }

    return (
      window.localStorage.getItem(
        localStorageKeys.IMPERSONATION_LOCAL_STORAGE_KEY
      ) || null
    );
  });

  const user = useUserData();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return noop;
    }

    if (impersonatedCompanySlug) {
      window.localStorage.setItem(
        localStorageKeys.IMPERSONATION_LOCAL_STORAGE_KEY,
        impersonatedCompanySlug
      );
    }

    return () => {
      window.localStorage.removeItem(
        localStorageKeys.IMPERSONATION_LOCAL_STORAGE_KEY
      );
    };
  }, [impersonatedCompanySlug]);

  if (!user.claimsAdmin && !user.claimsAgencyId) {
    return children;
  }

  return (
    <Provider value={{ impersonatedCompanySlug, setImpersonatedCompanySlug }}>
      {children}
    </Provider>
  );
};

ImpersonationProvider.propTypes = {
  children: PropTypes.node,
};

export default ImpersonationProvider;
