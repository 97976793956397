import PropTypes from 'prop-types';
import React, { createContext, useMemo, useReducer } from 'react';

import noop from 'lodash/noop';

import { initialState, reducer } from './filtersReducer';

export const Context = createContext({
  filters: initialState,
  dispatch: noop,
});

const AdminMessagesContext = ({ children = null }) => {
  const [filters, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(
    () => ({
      filters,
      dispatch,
    }),
    [filters]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

AdminMessagesContext.propTypes = {
  children: PropTypes.node,
};

export default AdminMessagesContext;
