import PropTypes from 'prop-types';
import React from 'react';

import { useNavigate } from 'react-router-dom';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useQuery from 'hooks/useQuery';
import useTrackIntercomSubscribed from 'hooks/useTrackIntercomSubscribed';
import getCompanyRoute from 'utils/getCompanyRoute';

import OnboardingWrapperPaymentStatusFailure from './OnboardingWrapperPaymentStatusFailure';
import OnboardingWrapperPaymentStatusSuccess from './OnboardingWrapperPaymentStatusSuccess/OnboardingWrapperPaymentStatusSuccess';
import OnboardingWrapperPlansModal from './OnboardingWrapperPlansModal';
import OnboardingWrapperSignupModal from './OnboardingWrapperSignupModal';

/**
 * Displays the signup and plans modal depending on a company's `signupStatus`
 * Handles the payment success/failure callback from Stripe
 * Tracks the `subscribed` event in Intercom (to potentially trigger a workflow)
 */
const OnboardingWrapper = ({ children = null }) => {
  // get query params
  const query = useQuery();
  const hasPaymentSucceeded = !!query.get('success');
  const hasError = !!query.get('error');

  // get history for push and replace actions
  const navigate = useNavigate();

  // get company data for signup status and referrer
  const { companyData } = useCompanyData();
  const signupStatus = companyData?.signupStatus;

  // removes the query parameter after the work it triggered is done
  const removeSearchQuery = () =>
    navigate(
      {
        search: null,
      },
      { replace: true }
    );

  const showSignupModal = signupStatus === 0;
  const showPlansModal = signupStatus === 1;

  const showModals = !hasPaymentSucceeded && !hasError;

  const isSubscribed = signupStatus === 2;
  useTrackIntercomSubscribed({
    meetsUserCriteria: hasPaymentSucceeded && isSubscribed,
    onComplete: removeSearchQuery,
  });

  // if sign up status doesn't exist (legacy onboarding), don't show onboarding
  if (signupStatus === undefined) {
    return children;
  }

  return (
    <>
      {children}
      {showModals && (
        <>
          <OnboardingWrapperSignupModal open={showSignupModal} />
          {showPlansModal && (
            <OnboardingWrapperPlansModal
              open={showPlansModal}
              onClose={() => {
                navigate(getCompanyRoute(companyData.slug));
              }}
              showCloseButton={false}
            />
          )}
        </>
      )}
      {hasPaymentSucceeded && (
        <OnboardingWrapperPaymentStatusSuccess
          onPaymentCheckComplete={removeSearchQuery}
        />
      )}
      {hasError && (
        <OnboardingWrapperPaymentStatusFailure
          open={hasError}
          onClose={() => navigate('/')}
        />
      )}
    </>
  );
};

OnboardingWrapper.propTypes = {
  children: PropTypes.node,
};

export default OnboardingWrapper;
