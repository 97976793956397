import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

// Shows the amount of characters in a string in gray or red (depending on whether maxLength has been exceeded)
const TextCounter = ({ str = '', maxLength = 140 }) => {
  const length = str?.length;

  return (
    <p
      className={classNames('text-xs', {
        'text-red-400': length > maxLength,
        'text-gray-400': length <= maxLength,
      })}
    >
      {length}/{maxLength}
    </p>
  );
};

TextCounter.propTypes = {
  str: PropTypes.string,
  maxLength: PropTypes.number,
};

export default TextCounter;
