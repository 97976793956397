import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import classNames from 'classnames';

import { ContentTypes } from 'constants/contentTypes';
import getFormattedCurrencyValue from 'utils/data/currency/getFormattedCurrencyValue';

import { ClampContext } from '../contexts/ClampContext';

const CompanyContentElementTableRow = ({
  label = '',
  contentType = '',
  content = '',
  type = 'string',
  currency = '',
}) => {
  const { clampValues } = useContext(ClampContext);

  return (
    <tr
      className="odd:bg-gray-50 even:bg-white"
      data-test-id={`company-content-${label}`}
    >
      <td
        className={classNames({
          'w-full align-top pt-2 pl-6 block': true,
          'lg:w-1/2 lg:table-cell lg:p-2 lg:pl-6': clampValues,
        })}
      >
        <p className="font-medium text-sm">{label}</p>
      </td>
      <td
        className={classNames({
          'w-full align-top pb-2 pl-6 pr-6 block': true,
          'lg:w-1/2 lg:table-cell lg:py-2 lg:pl-0': clampValues,
        })}
      >
        <p
          className={classNames({
            'text-gray-600 flex items-center break-words': true,
            'line-clamp-1': clampValues,
          })}
        >
          {currency !== '' &&
            type === 'currency' &&
            getFormattedCurrencyValue(content, currency)}

          {type === 'string' && content}
          {type === 'link' && (
            <a
              className="text-teal-500 hover:underline"
              target="_blank"
              rel="noreferrer"
              href={content.url}
              data-test-id={`company-content-${contentType}-url`}
            >
              {content.text}
            </a>
          )}
          {(type === 'list' || type === 'links') && (
            <span
              className={classNames('pr-4', { 'line-clamp-3': clampValues })}
            >
              {type === 'list' && content.map((tag) => tag.name).join(', ')}

              {type === 'links' &&
                content.map((tag, index) => (
                  <span key={tag?.id}>
                    {tag?.id?.startsWith('custom-') ? (
                      tag.name
                    ) : (
                      <a
                        className="text-teal-500 hover:underline"
                        href={`https://www.landscape.vc/investors/${tag?.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {tag.name}
                      </a>
                    )}
                    {index < content.length - 1 && ', '}
                  </span>
                ))}
            </span>
          )}
        </p>
      </td>
    </tr>
  );
};

CompanyContentElementTableRow.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  contentType: PropTypes.oneOf(Object.values(ContentTypes)),
  type: PropTypes.oneOf(['currency', 'string', 'list', 'links', 'link']),
  currency: PropTypes.string,
};

export default CompanyContentElementTableRow;
