import PropTypes from 'prop-types';
import React from 'react';

import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { Controller, useFormContext } from 'react-hook-form';

import ErrorMessage from 'components/form/ErrorMessage';
import { contentTypeTags } from 'constants/contentTypes';

const ContentTypeSelect = ({ onSelect = noop, suppressOnChange = false }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const typeError = errors?.type ?? null;

  return (
    <Controller
      name="type"
      control={control}
      rules={{
        required: {
          value: true,
          message:
            'Please select what type of content you would like to share.',
        },
      }}
      render={({ field: { onChange, value } }) => (
        <RadioGroup
          value={value || null}
          onChange={(v) => {
            onSelect(v);

            if (!suppressOnChange) {
              onChange(v);
            }
          }}
        >
          <RadioGroup.Label className="sr-only">Content type</RadioGroup.Label>
          <div className="grid sm:grid-cols-2 gap-4">
            {contentTypeTags.map((contentType) => (
              <RadioGroup.Option
                key={contentType.id}
                value={contentType.name}
                className={({ checked, active }) =>
                  classNames(
                    {
                      'bg-gray-100 z-10 border-teal-500 hover:bg-gray-100':
                        checked,
                      'ring-teal-500 ring-2 z-10': active,
                    },
                    'flex flex-col gap-2 border border-gray-300 hover:bg-gray-50 items-start justify-start bg-white rounded-lg p-4 cursor-pointer focus:outline-none'
                  )
                }
                data-test-id={`company-content-type-${contentType.name}`}
              >
                <RadioGroup.Label
                  as="span"
                  className="flex gap-2 text-sm items-center w-full font-semibold text-gray-700"
                >
                  <contentType.Icon className="w-5 fill-current" />
                  {contentType.label}
                </RadioGroup.Label>
                <RadioGroup.Description
                  as="p"
                  className="text-sm text-gray-500"
                >
                  {contentType.description}
                </RadioGroup.Description>
              </RadioGroup.Option>
            ))}
          </div>
          {typeError && (
            <ErrorMessage testId="company-content-type-error">
              {typeError.message}
            </ErrorMessage>
          )}
        </RadioGroup>
      )}
    />
  );
};

ContentTypeSelect.propTypes = {
  onSelect: PropTypes.func,
  suppressOnChange: PropTypes.bool,
};

export default ContentTypeSelect;
