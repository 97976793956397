import { useRemoteConfigString } from 'reactfire';

import useActiveSubscription from './useActiveSubscription';

const useActiveSubscriptionWithTier = (ownerId = '') => {
  const subscriptionData = useActiveSubscription(ownerId);
  const price = subscriptionData?.items[0]?.price;
  const name = price?.product?.name || null;
  const priceId = price?.id || null;

  const { data } = useRemoteConfigString('tiers');
  const { tiers } = JSON.parse(data);
  const currentTier = tiers.find(
    (tier) =>
      tier?.name?.toLowerCase() === name?.toLowerCase() ||
      tier?.priceId === priceId
  );

  return [currentTier, subscriptionData];
};

export default useActiveSubscriptionWithTier;
