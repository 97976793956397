import PropTypes from 'prop-types';
import React from 'react';

import getContentType from 'constants/contentTypes';

import CompanyContentFooter from '../components/CompanyContentFooter';

import CompanyContentElementFiles from './CompanyContentElementFiles';
import CompanyContentElementTableBrand from './CompanyContentElementTableBrand';
import CompanyContentElementTableFundraise from './CompanyContentElementTableFundraise';
import CompanyContentElementTableHire from './CompanyContentElementTableHire';
import CompanyContentElementTableIPO from './CompanyContentElementTableIPO';
import CompanyContentElementTableMAndA from './CompanyContentElementTableMAndA';
import CompanyContentElementTablePartnership from './CompanyContentElementTablePartnership';
import CompanyContentElementTableProductService from './CompanyContentElementTableProductService';

const componentTypeMap = {
  fundraise: CompanyContentElementTableFundraise,
  ipo: CompanyContentElementTableIPO,
  'm&a': CompanyContentElementTableMAndA,
  hire: CompanyContentElementTableHire,
  brand: CompanyContentElementTableBrand,
  'product-service': CompanyContentElementTableProductService,
  partnership: CompanyContentElementTablePartnership,
};

const CompanyContentElementTable = ({ content = {} }) => {
  const { name, label } = getContentType(content);

  // return the component
  const ComponentType = componentTypeMap[name];

  return (
    <div className="w-full flex flex-col flex-1 pt-2">
      <table
        className="mt-2 w-full table-fixed"
        data-test-id={`company-content-${name}`}
      >
        <tbody>
          <ComponentType content={content} name={name} label={label} />
        </tbody>
      </table>
      <CompanyContentFooter content={content}>
        <CompanyContentElementFiles content={content} />
      </CompanyContentFooter>
    </div>
  );
};

CompanyContentElementTable.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CompanyContentElementTable;
