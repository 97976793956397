import PropTypes from 'prop-types';
import React from 'react';

import { CheckIcon } from '@heroicons/react/solid';

const BillingSettingsSubscriptionFeatures = ({ includedFeatures = [] }) => (
  <div className="col-span-2 sm:col-span-3">
    <dt className="text-sm font-medium text-gray-500">What&apos;s included</dt>
    <dd className="mt-1 text-sm text-gray-900">
      <ul className="mt-2 space-y-2">
        {includedFeatures?.map((feature) => (
          <li key={feature} className="flex space-x-2">
            <CheckIcon
              className="shrink-0 h-5 w-5 text-teal-500"
              aria-hidden="true"
            />
            <span className="text-sm text-gray-900">{feature}</span>
          </li>
        ))}
      </ul>
    </dd>
  </div>
);

BillingSettingsSubscriptionFeatures.propTypes = {
  includedFeatures: PropTypes.arrayOf(PropTypes.string),
};

export default BillingSettingsSubscriptionFeatures;
