import PropTypes from 'prop-types';
import React from 'react';

import ContentElementEmbargoDate from '../ContentElementEmbargoDate';
import ContentElementHeadline from '../ContentElementHeadline';
import ContentElementUploadFile from '../ContentElementUploadFile';

import ContentElementPartnershipCompanies from './ContentElementPartnershipCompanies/ContentElementPartnershipCompanies';

const ContentElementPartnership = ({ index = 0 }) => (
  <div className="space-y-5 w-full">
    <ContentElementHeadline
      index={index}
      placeholder="Type the headline for this partnership announcement..."
    />
    <ContentElementPartnershipCompanies index={index} />
    <ContentElementEmbargoDate index={index} />
    <ContentElementUploadFile name="file" label="Add files" />
  </div>
);

ContentElementPartnership.propTypes = {
  index: PropTypes.number,
};

export default ContentElementPartnership;
