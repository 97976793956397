import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';

import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';

const ContentElementDescription = ({ index = 0, placeholder = '' }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const name = 'description';
  const error = get(errors, name, null);

  return (
    <div className="w-full">
      <Label htmlFor={`${name}${index}`}>Description</Label>
      <InputfieldTextArea
        id={`${name}${index}`}
        {...register(name)}
        placeholder={placeholder}
        data-test-id="company-content-inputDescription"
      />
      {error && (
        <ErrorMessage testId="company-content-inputDescription-error">
          {error.message}
        </ErrorMessage>
      )}
    </div>
  );
};

ContentElementDescription.propTypes = {
  index: PropTypes.number,
  placeholder: PropTypes.string,
};

export default ContentElementDescription;
