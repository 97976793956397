import { useEffect } from 'react';

import { doc } from 'firebase/firestore';

import { INBOXES_THREADS_MESSAGES_COLLECTION } from 'constants/firebasePaths';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import { useTracking } from 'modules/segment/TrackingProvider';

const useTrackIsExpanded = ({
  message = {},
  isExpanded = false,
  threadRef = null,
}) => {
  const trackingService = useTracking();

  const { updateDocument } = useFirestoreWrapper();

  useEffect(() => {
    const { read, uid, isJournalist } = message;
    if (isExpanded) {
      trackingService.track('inbox_interaction', {
        action: 'message_open',
        inbox_id: uid,
        inbox_hasUnreadMessages: !read,
        inbox_isJournalist: !!isJournalist,
      });
    }
    if (isExpanded && !read) {
      updateDocument(doc(threadRef, INBOXES_THREADS_MESSAGES_COLLECTION, uid), {
        read: true,
      });
    }
  }, [isExpanded, message, threadRef, updateDocument, trackingService]);
};

export default useTrackIsExpanded;
