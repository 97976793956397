import { LOGIN_PATH as path } from 'constants/paths';

import LoginPage from './LoginPage';

const LoginRoute = {
  path,
  component: LoginPage,
};

export default LoginRoute;
