import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const SweetheartListJournalistDetailsModalStatTitle = ({
  title = '',
  subtitle = '',
  wrapperClassName = '',
}) => (
  <div className={classNames('mb-4', wrapperClassName)}>
    <p className="font-bold text-xl text-gray-700 text-left">{title}</p>
    <p className="text-xs text-gray-500">{subtitle}</p>
  </div>
);

SweetheartListJournalistDetailsModalStatTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default SweetheartListJournalistDetailsModalStatTitle;
