import useActiveSubscriptionWithTier from 'hooks/useActiveSubscriptionWithTier';
import getCurrencySymbol from 'utils/data/currency/getCurrencySymbol';
import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';

const useActiveSubscriptionWithTierFormatted = (ownerId = '') => {
  const [currentTier, subscriptionData] =
    useActiveSubscriptionWithTier(ownerId);
  const subscriptionPrice = subscriptionData?.items[0]?.price;
  const name = subscriptionPrice?.product?.name || null;
  const priceId = subscriptionPrice?.id || null;
  const currency = subscriptionPrice?.currency?.toUpperCase() || null;

  if (!currentTier || !subscriptionData) {
    return null;
  }

  const subscription = {
    name,
    priceId,
    price: (subscriptionPrice?.unit_amount || 0) / 100 || null,
    createdDate: getDateFromTimestamp(
      subscriptionData?.created?.toDate() || null
    ),
    nextBillingDate:
      getDateFromTimestamp(subscriptionData?.current_period_end?.toDate()) ||
      null,
    currency,
    currencySymbol: getCurrencySymbol(currency),
    includedFeatures: currentTier?.includedFeatures || [],
    description: currentTier?.description || null,
    willCancel: subscriptionData?.cancel_at_period_end || false,
    willCancelAtDate: getDateFromTimestamp(
      subscriptionData?.cancel_at?.toDate() || null
    ),
  };

  return subscription;
};

export default useActiveSubscriptionWithTierFormatted;
