import React from 'react';

import {
  OfficeBuildingIcon,
  AdjustmentsIcon,
  StarIcon,
  NewspaperIcon,
  UsersIcon,
  MailIcon,
  CurrencyPoundIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { generatePath } from 'react-router-dom';

import {
  ADMIN_COMPANIES_PATH,
  ADMIN_OPPORTUNITIES_PATH,
  ADMIN_CREATE_OPPORTUNITY_PATH,
  ADMIN_PUBLICATIONS_PATH,
  ADMIN_JOURNALISTS_PATH,
  ADMIN_SUCCESS_FEES_PATH,
  ADMIN_MESSAGES_PATH,
  ADMIN_MESSAGES_COMPANIES_PATH,
  ADMIN_CREATE_CUSTOM_THREAD_PATH,
} from 'constants/paths';
import getPageTitle from 'utils/getPageTitle';

import AdminWrapper from './AdminWrapper';

const actions = [
  {
    title: 'Companies',
    description: 'See all companies at a glance',
    href: ADMIN_COMPANIES_PATH,
    icon: OfficeBuildingIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'Opportunities',
    description: 'Assign/Unassign companies to and from media opportunities',
    href: ADMIN_OPPORTUNITIES_PATH,
    icon: AdjustmentsIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'Custom opportunities',
    description: 'Create custom opportunities for specific companies',
    href: ADMIN_CREATE_OPPORTUNITY_PATH,
    icon: StarIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'Publications',
    description:
      'A list of publications, to manage images and domain authority',
    href: ADMIN_PUBLICATIONS_PATH,
    icon: NewspaperIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'Journalists',
    description:
      'A list of journalists, to manage images, email and phone number',
    href: ADMIN_JOURNALISTS_PATH,
    icon: UsersIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'Messages',
    description:
      'A queue of pending messages from/to Jounalists that need approval',
    href: generatePath(ADMIN_MESSAGES_PATH, {
      type: ADMIN_MESSAGES_COMPANIES_PATH,
    }),
    icon: MailIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'Success fees',
    description:
      'A list of what we charged for successful placements in the media',
    href: `${ADMIN_SUCCESS_FEES_PATH}`,
    icon: CurrencyPoundIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'Create custom thread',
    description: 'A tool to create custom threads',
    href: `${ADMIN_CREATE_CUSTOM_THREAD_PATH}`,
    icon: NewspaperIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
];

const AdminPage = () => (
  <AdminWrapper currentIndex={0} title="Dashboard">
    <Helmet>
      <title>{getPageTitle('Admin - Dashboard')}</title>
    </Helmet>
    <div className="mt-4 rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
      {actions.map((action, actionIdx) => (
        <div
          key={action.title}
          className={classNames(
            'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
            {
              'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none': actionIdx === 0,
              'sm:rounded-tr-lg': actionIdx === 1,
              'sm:rounded-bl-lg': actionIdx === actions.length - 2,
              'rounded-bl-lg rounded-br-lg sm:rounded-bl-none':
                actionIdx === actions.length - 1,
            }
          )}
        >
          <div>
            <span
              className={classNames(
                action.iconBackground,
                action.iconForeground,
                'rounded-lg inline-flex p-3 ring-4 ring-white'
              )}
            >
              <action.icon className="h-6 w-6" aria-hidden="true" />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-lg font-medium">
              <a href={action.href} className="focus:outline-none">
                {/* Extend touch target to entire panel */}
                <span className="absolute inset-0" aria-hidden="true" />
                {action.title}
              </a>
            </h3>
            <p className="mt-2 text-sm text-gray-500">{action.description}</p>
          </div>
          <span
            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
            aria-hidden="true"
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
      ))}
    </div>
  </AdminWrapper>
);

export default AdminPage;
