import PropTypes from 'prop-types';
import React, { memo } from 'react';

import classNames from 'classnames';
import { isEqual } from 'lodash';
import noop from 'lodash/noop';
import { generatePath, Link, useNavigate } from 'react-router-dom';

import OpportunityBody from 'components/opportunities/opportunity/OpportunityBody';
import OpportunityQuery from 'components/opportunities/opportunity/OpportunityQuery';
import OpportunityTags from 'components/opportunities/opportunity/OpportunityTags';
import OpportunityTimeLeft from 'components/opportunities/opportunity/OpportunityTimeLeft';
import OpportunityType from 'components/opportunities/opportunity/OpportunityType';
import { INBOX_PATH } from 'constants/paths';

import OpportunitiesListOpportunityActions from './OpportunitiesListOpportunityActions/OpportunitiesListOpportunityActions';

const OpportunitiesListOpportunity = ({
  opportunity = {},
  onExpand = noop,
  onSelect = noop,
  isSelected = false,
}) => {
  const { type, deadlineUTC, query, uid, thread, searchTerms } = opportunity;
  const navigate = useNavigate();
  const expired = deadlineUTC?.toDate?.() < new Date() ?? false;

  return (
    <article
      className={classNames(
        'relative flex flex-col-reverse items-center lg:items-start lg:flex-row lg:justify-center group lg:mt-2',
        {
          'opacity-50': expired || thread?.exists,
        }
      )}
      data-test-id={`opportunity-${uid}`}
      data-intercom-id="opportunity"
    >
      <OpportunitiesListOpportunityActions
        opportunity={opportunity}
        expired={expired}
        onSelect={() => onSelect(uid)}
        isSelected={isSelected}
      />

      <Link
        className="flex flex-1 flex-col justify-between relative p-4 pt-2 mb-4 rounded-md md:border border-white max-w-prose w-full shadow cursor-pointer hov border bg-white hover:border-teal-500 hover:shadow-md"
        onClick={() => {
          if (thread?.exists) {
            navigate(
              generatePath(INBOX_PATH, {
                threadId: thread?.uid,
                tab: thread?.status,
              })
            );
          } else {
            onExpand();
          }
        }}
        data-intercom-id="opportunity-container"
      >
        <div className="flex flex-row flex-wrap items-center">
          <OpportunityType type={type} isResponded={thread?.exists} />

          <OpportunityTags searchTerms={searchTerms} />
        </div>

        <OpportunityBody {...opportunity} />

        <OpportunityQuery opportunityId={uid} query={query} collapsed />
      </Link>
      <div className="flex flex-col max-w-prose items-end lg:items-start w-full lg:w-40">
        <OpportunityTimeLeft deadlineUTC={deadlineUTC} expired={expired} />
      </div>
    </article>
  );
};

OpportunitiesListOpportunity.propTypes = {
  opportunity: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    mediaOutlet: PropTypes.string.isRequired,
    mediaOutletWebsite: PropTypes.string,
    journalist: PropTypes.string.isRequired,
    deadlineUTC: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    enquirySummary: PropTypes.string,
    query: PropTypes.array.isRequired,
    custom: PropTypes.bool,
    type: PropTypes.string,
    domainAuthority: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  onExpand: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default memo(
  OpportunitiesListOpportunity,
  (prevProps, nextProps) =>
    prevProps.isSelected === nextProps.isSelected &&
    isEqual(prevProps.opportunity, nextProps.opportunity)
);
