export const messageStatus = {
  UNAPPROVED: 'unapproved',
  APPROVED: 'approved',
  DELIVERED: 'delivered',
  DELIVERED_INTERNALLY: 'delivered_internally',
  ERROR: 'error',
};
export const orphanMessageStatus = {
  ASSIGNED: 'assigned',
  UNASSIGNED: 'unassigned',
};
