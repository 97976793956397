import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

import { doc } from 'firebase/firestore';
import sortBy from 'lodash/sortBy';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import OnboardingWrapper from 'components/OnboardingWrapper/OnboardingWrapper';
import PageWrapper from 'components/PageWrapper';
import getPageTitle from 'utils/getPageTitle';

import InboxMessages from './InboxMessages/InboxMessages';
import InboxMessagesError from './InboxMessages/InboxMessagesError';
import InboxNavbar from './InboxNavbar';
import InboxPageNoMessages from './InboxPageNoMessages';
import InboxThreads from './InboxThreads/InboxThreads';
import useEnrichedThreads from './hooks/useEnrichedThreads';

const InboxPage = ({ companyUid = '' }) => {
  const { threadId, tab } = useParams();

  const {
    threadsRef,
    threads: enrichedThreads,
    inbox,
  } = useEnrichedThreads(companyUid, tab);

  const currentThreadId = enrichedThreads?.find(
    (thread) => thread?.uid === threadId
  )?.uid;

  const threadsByDate = sortBy(enrichedThreads, 'dateCreated').reverse();

  const hasNoInbox = !inbox.exists();
  const hasNoThreads = enrichedThreads.length === 0;

  return (
    <PageWrapper>
      <OnboardingWrapper>
        <Helmet>
          <title>{getPageTitle('Inbox')}</title>
        </Helmet>
        <InboxNavbar />
        {hasNoInbox || hasNoThreads ? (
          <InboxPageNoMessages />
        ) : (
          <div>
            {enrichedThreads.length > 0 ? (
              <div className="flex flex-col lg:flex-row gap-6">
                <aside className="lg:w-80 xl:w-96">
                  <h2 className="mb-2 pl-2 sm:pl-0 text-lg font-semibold text-gray-700">
                    Threads
                  </h2>
                  <Suspense>
                    <InboxThreads
                      threads={threadsByDate}
                      threadsRef={threadsRef}
                      currentThreadId={currentThreadId}
                    />
                  </Suspense>
                </aside>
                <section className="flex-auto min-w-0">
                  <h2 className="mb-2 pl-2 sm:pl-0 text-lg font-semibold text-gray-700">
                    Messages
                  </h2>

                  {/*  If we don't have a threadId, we show the no thread message */}
                  <Suspense>
                    {currentThreadId ? (
                      <InboxMessages
                        journalist={
                          enrichedThreads.find((t) => t.uid === currentThreadId)
                            ?.journalist
                        }
                        threadRef={doc(threadsRef, currentThreadId)}
                      />
                    ) : (
                      <InboxMessagesError text="No thread selected." />
                    )}
                  </Suspense>
                </section>
              </div>
            ) : (
              <InboxPageNoMessages />
            )}
          </div>
        )}
      </OnboardingWrapper>
    </PageWrapper>
  );
};

InboxPage.propTypes = {
  companyUid: PropTypes.string.isRequired,
};

export default InboxPage;
