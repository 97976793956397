import { ADMIN_MESSAGES_PATH as path } from 'constants/paths';

import AdminMessagesPage from './AdminMessagesPage';

const AdminMessagesRoute = {
  path,
  authRequired: true,
  claimsRequired: { admin: true },
  component: AdminMessagesPage,
};

export default AdminMessagesRoute;
