import React, { useEffect } from 'react';

import noop from 'lodash/noop';
import { useParams } from 'react-router-dom';

import useUserData from 'contexts/UserContext/hooks/useUserData';
import useCompanyDocBySlug from 'hooks/useCompanyDocBySlug';
import { useTracking } from 'modules/segment/TrackingProvider';

import CompanyNotFound from './CompanyNotFound';
import CompanyPageContent from './CompanyPageContent';

const CompanyPage = () => {
  const { id } = useParams();
  const { ref } = useCompanyDocBySlug(id) || {};

  const trackingService = useTracking();

  const { isAnonymous, claimsJournalist } = useUserData();

  useEffect(() => {
    // don't log this if the user is the owner of the company
    if (isAnonymous || claimsJournalist) {
      trackingService.track('pressPage_visit', {
        company_title: id,
      });
    }

    return noop;
  }, [id, isAnonymous, claimsJournalist, trackingService]);

  return ref ? <CompanyPageContent companyRef={ref} /> : <CompanyNotFound />;
};

export default CompanyPage;
