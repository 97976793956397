import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import { getDateRange } from 'utils/date/dateRange';

import statsMeta from '../constants/statsMeta';

const AdminCompaniesCompanyStats = ({ stats = [] }) => {
  if (!stats.length) {
    return (
      <td colSpan={statsMeta.length + 1} className="text-center">
        No data
      </td>
    );
  }

  return (
    <>
      <th className="px-2 text-right" scope="row">
        {stats.map((stat, index) => {
          const month = getDateRange('months')[stat.dateCreated.getMonth()];
          return (
            <p
              key={month}
              className={classNames({
                'pt-2': index === 0,
                'pb-2': index === stats.length - 1,
              })}
            >
              {month}
            </p>
          );
        })}
      </th>
      {statsMeta.map(({ name }) => (
        <td key={name} className="text-center">
          {stats.map((stat) => (
            <p
              key={name + stat.dateCreated.getMonth()}
              className="text-sm font-medium"
            >
              {stat?.[name] || 0}
            </p>
          ))}
        </td>
      ))}
    </>
  );
};

AdminCompaniesCompanyStats.propTypes = {
  stats: PropTypes.array,
};

export default AdminCompaniesCompanyStats;
