import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { MailIcon } from '@heroicons/react/solid';
import { doc, getDoc } from 'firebase/firestore';
import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';
import { useFirestore } from 'reactfire';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { USERS_COLLECTION } from 'constants/firebasePaths';

import useUpdateEmail from '../hooks/useUpdateEmail';

const AdminCompaniesCompanyUpdateEmailModal = ({
  open = true,
  company = {},
  onClose = noop,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isValid, errors },
  } = useForm({ mode: 'onChange' });
  const firestore = useFirestore();
  const { isUpdatingEmail, onSubmit } = useUpdateEmail({
    handleSubmit,
    companyOwner: company.owner,
    onClose,
  });

  useEffect(() => {
    if (!company.owner || !open) {
      reset();
      return;
    }

    (async () => {
      const userDoc = await getDoc(
        doc(firestore, USERS_COLLECTION, company.owner)
      );
      const { email } = userDoc.data();

      setValue('oldEmail', email);
    })();
  }, [company.owner, firestore, open, reset, setValue]);

  return (
    <Modal open={open}>
      <Modal.Icon Icon={MailIcon} />
      <Modal.Title>Update {company.name}&apos;s email address</Modal.Title>
      <Modal.Content>
        <form
          onSubmit={onSubmit}
          id="updateUserEmailForm"
          className="space-y-3"
        >
          <InputFieldText {...register('oldEmail')} errors={errors} disabled>
            <InputFieldText.Label>Old email</InputFieldText.Label>
          </InputFieldText>

          <InputFieldText
            {...register('newEmail', {
              required: 'Please type in the new email',
            })}
            placeholder="Type the new email here..."
            errors={errors}
          >
            <InputFieldText.Label>New email</InputFieldText.Label>
          </InputFieldText>

          <p className="text-sm text-gray-400">
            After updating the email, we&apos;ll send {company.name} an email
            with a link to reset their password.
          </p>
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          submit
          disabled={!isValid || isUpdatingEmail}
          form="updateUserEmailForm"
        >
          {isUpdatingEmail ? 'Updating' : 'Update'} email
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminCompaniesCompanyUpdateEmailModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
};

export default AdminCompaniesCompanyUpdateEmailModal;
