import PropTypes from 'prop-types';
import React from 'react';

import Card from 'components/Card';
import StripeRedirectModal from 'components/StripeRedirectModal';
import Button from 'components/buttons/Button';
import stripePortalConfigNames from 'constants/stripePortalConfigNames';
import useRedirectToStripePortal from 'hooks/useRedirectToStripePortal';
import { useTracking } from 'modules/segment/TrackingProvider';

const BillingSettingsInvoices = ({ subscriptionData = {} }) => {
  const {
    price,
    name,
    currency,
    currencySymbol,
    nextBillingDate,
    willCancel,
    willCancelAtDate,
  } = subscriptionData || {};

  const isBillingToday = nextBillingDate === 'Today';
  const { redirectToStripePortal, isRedirecting } = useRedirectToStripePortal(
    stripePortalConfigNames.INVOICE_HISTORY
  );
  const trackingService = useTracking();

  const showInvoices = async (event) => {
    await trackingService.track('subscription_update', {
      action: 'invoice_view',
      subscriptionName: name,
    });
    redirectToStripePortal(event);
  };

  return (
    <Card title="Invoices">
      <div className="w-full items-center flex flex-col gap-5">
        <p className="text-center text-sm text-gray-900 mx-auto max-w-prose leading-relaxed">
          {willCancel ? (
            <span>
              Your subscription has been cancelled. You will not be charged
              again. You can still use the features you have access to until{' '}
              {willCancelAtDate}.
            </span>
          ) : (
            <span>
              The next invoice for your subscription plan ({currencySymbol}
              {price?.toFixed(2)} {currency?.toUpperCase()}) will be billed and
              paid {isBillingToday ? ' ' : 'on'}{' '}
              {isBillingToday
                ? nextBillingDate?.toLocaleLowerCase()
                : nextBillingDate}
              .
            </span>
          )}
        </p>
        <Button
          type="secondary"
          className="w-fit flex gap-2 items-center"
          onClick={showInvoices}
        >
          View all invoices
        </Button>
      </div>
      <StripeRedirectModal isRedirecting={isRedirecting} />
    </Card>
  );
};

BillingSettingsInvoices.propTypes = {
  subscriptionData: PropTypes.object,
};

export default BillingSettingsInvoices;
