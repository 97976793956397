import { collection } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import {
  INBOXES_COLLECTION,
  INBOXES_THREADS_COLLECTION,
} from 'constants/firebasePaths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

const useInboxThreads = () => {
  const firestore = useFirestore();
  const {
    companyData: { uid },
  } = useCompanyData();
  const { data: threads } = useFirestoreCollectionData(
    collection(firestore, INBOXES_COLLECTION, uid, INBOXES_THREADS_COLLECTION),
    {
      initialData: [],
    }
  );

  return threads;
};

export default useInboxThreads;
