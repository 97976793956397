import PropTypes from 'prop-types';
import React from 'react';

import { useFormContext } from 'react-hook-form';

import Tooltip from 'components/Tooltip/Tooltip';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import useUserData from 'contexts/UserContext/hooks/useUserData';

const ContentElementUploadFile = ({ name = '', label = '' }) => {
  const form = useFormContext();
  const { uid } = useUserData();
  const uploadFolder = `users/${uid}/assets`;

  if (!uploadFolder || !name) {
    return null;
  }

  return (
    <InputFieldUpload
      folder={uploadFolder}
      name={name}
      form={form}
      presetName="fileWithZip"
      testId="company-content-inputFileUpload"
    >
      <InputFieldUpload.Label className="flex items-center">
        {label}
        <Tooltip
          wrapperClassName="!inline-block"
          id="tooltip-contentEdit-general"
          copy="Add files for Journalists to use like prewritten content, an image or a zip file containing both."
        />
      </InputFieldUpload.Label>
    </InputFieldUpload>
  );
};

ContentElementUploadFile.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};
export default ContentElementUploadFile;
