import PropTypes from 'prop-types';
import React from 'react';

import { MailOpenIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const UpdateEmailModal = ({ open = true, onClose = noop, onSubmit = noop }) => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({ mode: 'onChange' });

  return (
    <Modal open={open}>
      <Modal.Icon Icon={MailOpenIcon} />
      <Modal.Title>Update your email address</Modal.Title>
      <Modal.Content>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="updateUserEmailForm"
          className="space-y-3"
        >
          <InputFieldText
            {...register('password', {
              required: 'Please type in your current password',
            })}
            type="password"
            placeholder="Your current password"
            errors={errors}
          >
            <InputFieldText.Label>Password</InputFieldText.Label>
          </InputFieldText>

          <InputFieldText
            {...register('newEmail', {
              required: 'Please type in the new email',
            })}
            placeholder="Type your new email here..."
            errors={errors}
          >
            <InputFieldText.Label>New email</InputFieldText.Label>
          </InputFieldText>

          <p className="text-sm text-gray-400">
            After updating your email, we&apos;ll send you a confirmation email
            with a link to confirm this new email.
          </p>
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button submit disabled={!isValid} form="updateUserEmailForm">
          Update email
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

UpdateEmailModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UpdateEmailModal;
