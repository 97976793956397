import React, { useEffect } from 'react';

import { useUser } from 'reactfire';

import IntercomWithUser from './IntercomWithUser';
import bootIntercom from './helpers/bootIntercom';
import loadIntercom from './helpers/loadIntercom';

const Intercom = () => {
  const { data: userDataAuth } = useUser();
  const hasUserId = userDataAuth?.uid || null;

  useEffect(() => {
    loadIntercom();
    if (!hasUserId) {
      bootIntercom();
    }
    if (window?.Intercom) {
      window.Intercom('update');
    }
  }, [hasUserId]);

  if (!hasUserId || userDataAuth.isAnonymous) {
    return null;
  }

  return <IntercomWithUser userDataAuth={userDataAuth} />;
};

export default Intercom;
