import PropTypes from 'prop-types';
import React from 'react';

import { CheckCircleIcon, XIcon } from '@heroicons/react/solid';
import { noop } from 'lodash';

import Tag from 'components/tags/Tag';

import useSubscribeToCompanyNotifiedStatusInView from './helpers/useSubscribeToCompanyNotifiedStatusInView';
import useToggleCompany from './helpers/useToggleCompany';

const AdminOpportunityCompaniesCompany = ({
  company = {},
  opportunityUid = '',
  type = '',
  deleteTargetedCompany = noop,
}) => {
  const { ref, isNotified, hasVisited, isSelected, notificationCount } =
    useSubscribeToCompanyNotifiedStatusInView({
      companyUid: company?.uid,
      opportunityUid,
    });

  const toggleCompany = useToggleCompany({
    companyUid: company?.uid,
    opportunityUid,
    isSelected,
  });

  const handleClick = (e) => {
    e.stopPropagation();
    deleteTargetedCompany(company?.uid);
  };

  let bgColor = 'gray-100';
  let textColor = '';
  let className = 'hover:bg-gray-50';
  if (isSelected && !hasVisited) {
    bgColor = 'amber-300';
    textColor = 'amber-700';
    className = 'hover:bg-amber-200';
  }
  if (isNotified && !isSelected && !hasVisited) {
    bgColor = 'green-300';
    textColor = 'green-700';
    className = 'hover:bg-green-300 cursor-default';
  }

  if (hasVisited) {
    bgColor = 'gray-700';
    textColor = 'white';
    className = '';
  }

  return (
    <a
      ref={ref}
      href="#"
      onClick={(e) => {
        e.preventDefault();

        if (hasVisited) {
          return;
        }

        toggleCompany();
      }}
      key={company?.slug}
    >
      <Tag bgColor={bgColor} textColor={textColor} className={className}>
        {hasVisited && <CheckCircleIcon className="w-4 h-4 text-white-500" />}
        <span className={hasVisited ? 'ml-1' : ''}>
          {notificationCount > 1
            ? `${company?.name} (+${notificationCount - 1})`
            : company?.name}
          {type === 'targeted' && (
            <button
              type="button"
              onClick={handleClick}
              className="ml-2 hover:bg-gray-300 rounded-md px-1 py-1"
            >
              <XIcon className="h-2 w-2" />
            </button>
          )}
        </span>
      </Tag>
    </a>
  );
};

AdminOpportunityCompaniesCompany.propTypes = {
  company: PropTypes.object.isRequired,
  opportunityUid: PropTypes.string,
  deleteTargetedCompany: PropTypes.func,
  type: PropTypes.string,
};

export default AdminOpportunityCompaniesCompany;
