import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import CompanyAutocompleteField from 'components/company/CompanyAutocompleteField';
import useAPIRequest from 'hooks/useAPIRequest';

import AdminOpportunityCompaniesCompany from './AdminOpportunityCompaniesCompany';

const AdminOpportunityCompanies = ({
  companies = [],
  opportunityUid = '',
  targetedCompanies = [],
}) => {
  const { showError } = useNotifications();
  const { data: companiesData, loading: loadingCompanies } = useAPIRequest({
    endpoint: '/company/list',
    service: 'UCD',
  });

  const [showForm, setShowForm] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      targetedCompanies: [],
    },
  });

  const { fetchData: upsertOpportunity } = useAPIRequest({
    endpoint: '/opportunities',
    method: 'PUT',
    service: 'MOD',
  });

  const handleFormSubmit = async (data) => {
    const newTargetedComapanies = data.targetedCompanies
      .map((c) => c.id)
      .concat(targetedCompanies.map((c) => c.uid));

    try {
      await upsertOpportunity({
        body: {
          uid: opportunityUid,
          targetedCompanies: [...newTargetedComapanies],
        },
      });
    } catch (error) {
      showError({ message: 'There was an error adding the company' });
    }

    reset({ targetedCompanies: [] });
    setShowForm(false);
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(handleFormSubmit)();
    }
  };

  const handleDelete = useCallback(
    async (companyId) => {
      try {
        if (!companyId) {
          return;
        }

        const updatedTargetedCompanies = targetedCompanies
          .filter((c) => c.uid !== companyId)
          .map((c) => c.uid);

        await upsertOpportunity({
          body: {
            uid: opportunityUid,
            targetedCompanies: [...updatedTargetedCompanies],
          },
        });
      } catch (error) {
        showError({ message: 'There was an error deleting the company' });
      }
    },
    [targetedCompanies, opportunityUid, upsertOpportunity, showError]
  );

  return (
    <div className="flex flex-row flex-wrap items-stretch">
      {companies.map((company) => (
        <AdminOpportunityCompaniesCompany
          key={company?.uid}
          company={company}
          opportunityUid={opportunityUid}
        />
      ))}

      {targetedCompanies.map((company) => (
        <AdminOpportunityCompaniesCompany
          key={company?.uid}
          type="targeted"
          company={company}
          deleteTargetedCompany={handleDelete}
          opportunityUid={opportunityUid}
        />
      ))}
      {showForm ? (
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex items-center"
        >
          <div className="mr-2">
            <Controller
              name="targetedCompanies"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CompanyAutocompleteField
                  name="targetedCompanies"
                  error={errors.targetedCompanies}
                  targetedCompanies={value}
                  companiesData={companiesData}
                  loading={loadingCompanies}
                  onKeyPress={handleOnKeyPress}
                  updateCallback={(newValue) => onChange(newValue)}
                />
              )}
            />
          </div>
        </form>
      ) : (
        <button
          type="button"
          onClick={() => setShowForm(true)}
          className="inline-flex items-center mr-2 my-1 p-2 py-1 text-xs border border-transparent font-bold rounded-md shadow-sm text-black bg-gray-100 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          + Add
        </button>
      )}
    </div>
  );
};

AdminOpportunityCompanies.propTypes = {
  companies: PropTypes.array.isRequired,
  targetedCompanies: PropTypes.array.isRequired,
  opportunityUid: PropTypes.string,
};

export default AdminOpportunityCompanies;
