import { useState } from 'react';

import { MailIcon } from '@heroicons/react/solid';
import { sendPasswordResetEmail } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import noop from 'lodash/noop';
import { useAuth, useFunctions } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';

const useUpdateEmail = ({
  handleSubmit = noop,
  companyOwner = '',
  onClose = noop,
}) => {
  const auth = useAuth();
  const { showSuccess, showError } = useNotifications();

  const [isUpdatingEmail, setIsUpdatingEmail] = useState(false);
  const functions = useFunctions();
  const updateUserEmail = httpsCallable(functions, 'auth-updateUserEmail');

  const onSubmit = handleSubmit(async ({ newEmail, oldEmail }) => {
    if (newEmail === oldEmail) {
      showError({
        title: 'Emails are the same',
        message: 'Please type in a different email.',
      });
      return;
    }

    try {
      setIsUpdatingEmail(true);
      const { data } = await updateUserEmail({
        uid: companyOwner,
        newEmail,
      });

      if (data?.success) {
        await sendPasswordResetEmail(auth, newEmail);

        showSuccess({
          title: 'Email updated successfully',
          Icon: MailIcon,
        });
      } else {
        showError({
          title: 'Something went wrong.',
          message: data.error,
        });
      }
    } catch (error) {
      showError({
        title: 'Something went wrong.',
        message: 'Please contact dev team, so that they can check the error.',
      });
    } finally {
      setIsUpdatingEmail(false);
      onClose();
    }
  });

  return {
    onSubmit,
    isUpdatingEmail,
  };
};

export default useUpdateEmail;
