import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

import getLocalisedCurrencyValue from 'utils/data/currency/getLocalisedCurrencyValue';

const InputFieldCurrencyFormattedInput = ({
  onChange = noop,
  value = 0,
  inputClassName = '',
  currency = 'GBP',
  testId = '',
  id = '',
}) => {
  const ref = useRef(null);
  const formated = useMemo(
    () => getLocalisedCurrencyValue(value, currency),
    [value, currency]
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.value = formated;
    }
  }, [formated]);

  return (
    <input
      id={id}
      defaultValue={formated}
      ref={ref}
      maxLength={15}
      data-test-id={testId}
      onChange={(e) => {
        const inputValue =
          e.target.value.replace(/[^0-9]/g, '').replace(/^0+/, '') || 0;

        onChange(inputValue);
      }}
      onKeyDown={(e) => {
        // If user presses Tab, let him go to the next input
        if (e.key === 'Tab') {
          return;
        }

        // If user tries to delete when the input is 0, prevent the delete
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (e.target.value === '0') {
            e.preventDefault();
          }
          return;
        }

        // If user tries to type a non-number, prevent the input
        if (e.key < '0' || e.key > '9') {
          e.preventDefault();
        }
      }}
      className={classNames(
        '-mr-[1px] focus:border-teal-500 border block w-full pl-7 pr-1 sm:text-sm border-gray-300 rounded-md rounded-r-none',
        'focus:z-[1] border-r-transparent focus:border-r-teal-500 focus:outline-none focus:ring-0',
        inputClassName
      )}
    />
  );
};

InputFieldCurrencyFormattedInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  inputClassName: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  id: PropTypes.string,
  testId: PropTypes.string,
};

export default InputFieldCurrencyFormattedInput;
