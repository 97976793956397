import { useMemo } from 'react';

import { collection } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import {
  COMPANIES_COLLECTION,
  INBOXES_COLLECTION,
  INBOXES_THREADS_COLLECTION,
  INBOXES_THREADS_MESSAGES_COLLECTION,
} from 'constants/firebasePaths';
import useNestedData from 'hooks/useNestedData';

const path = [
  {
    collection: INBOXES_THREADS_COLLECTION,
    subcollections: [
      {
        collection: INBOXES_THREADS_MESSAGES_COLLECTION,
      },
    ],
  },
];

const useNestedInboxesWithCompanies = () => {
  const inboxes = useNestedData(
    { path: INBOXES_COLLECTION, isCollection: true },
    path
  );
  const firestore = useFirestore();
  const { data: companies } = useFirestoreCollectionData(
    collection(firestore, COMPANIES_COLLECTION),
    defaultOptions
  );
  const inboxesWithCompanies = useMemo(
    () =>
      inboxes.map((inbox) => {
        const company = companies.find((c) => c.uid === inbox.id);
        return {
          ...inbox,
          data: company,
        };
      }),
    [inboxes, companies]
  );
  return inboxesWithCompanies;
};

export default useNestedInboxesWithCompanies;
