/* eslint-disable max-params */
import { useEffect, useState } from 'react';

import { collection, doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import flattenSubcollectionSnapshots from '../routes/admin/AdminMessages/hooks/flattenSubcollectionSnapshots';

const useNestedData = (parent = {}, path = [], timeout = 300) => {
  const firestore = useFirestore();
  const [data, setData] = useState(parent.isCollection ? [] : {});

  useEffect(() => {
    let timeoutid;
    let snapshots;

    if (typeof parent.enabled === 'undefined' || parent.enabled) {
      snapshots = flattenSubcollectionSnapshots(
        path,
        (newData) => {
          if (timeoutid) {
            clearTimeout(timeoutid);
          }
          timeoutid = setTimeout(() => {
            if (parent.isCollection) {
              setData([...newData]);
            } else {
              setData({ ...newData });
            }
          }, timeout);
        },
        parent.isCollection
          ? collection(firestore, parent.path)
          : doc(firestore, parent.path),
        parent.isCollection
      );
    }

    return () => {
      snapshots?.unsubscribe();
    };
  }, [
    firestore,
    parent.enabled,
    parent.isCollection,
    parent.path,
    path,
    timeout,
  ]);

  return data;
};

export default useNestedData;
