import PropTypes from 'prop-types';
import React from 'react';

import debounce from 'lodash/debounce';
import { useParams } from 'react-router-dom';

import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import { tabNames } from 'constants/inboxTabs';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import { useTracking } from 'modules/segment/TrackingProvider';

import InboxMessageAvatar from '../InboxMessageAvatar';
import useSendInboxMessage from '../hooks/useSendInboxMessage';

import InboxMessageResponseFormDraftProvider from './InboxMessageResponseFormDraftProvider';
import InboxMessageResponseFormEditor from './InboxMessageResponseFormEditor';
import InboxMessageResponseFormScheduleButton from './InboxMessageResponseFormScheduleButton';
import InboxMessageResponseFormSendPreviewButton from './InboxMessageResponseFormSendPreviewButton';

const InboxMessageResponseForm = ({ threadRef = {}, thread = {} }) => {
  const { setDocument } = useFirestoreWrapper();
  const trackingService = useTracking();

  const { tab } = useParams();
  const { claimsAdmin: isAdmin } = useUserData();

  const { handleSubmit, createSendInboxMessage, control, reset, messageError } =
    useSendInboxMessage({
      thread,
      threadRef,
      isAdmin,
    });
  const responseFormError = messageError?.response?.message;

  const onResponseType = debounce(
    (event) =>
      trackingService.track('inbox_interaction', {
        action: 'response_type',
        inbox_threadId: thread.uid,
        inbox_numberOfCharacters: event?.target?.value?.length,
      }),
    1000
  );

  return (
    <div className="p-4 w-full" data-intercom-id="inbox-messages-form">
      <div className="flex w-full gap-4 ">
        {tab !== tabNames.DRAFT ? (
          <InboxMessageAvatar showCompanyImage={isAdmin} />
        ) : null}
        <InboxMessageResponseFormDraftProvider
          thread={thread}
          threadRef={threadRef}
          key={thread.uid}
          setDocument={setDocument}
        >
          {({ draft, initialTemplate, saveDraft }) => (
            <form
              data-test-id="inboxMessage-responseForm"
              onSubmit={handleSubmit(createSendInboxMessage())}
              className="flex flex-col w-full gap-4"
            >
              <InboxMessageResponseFormEditor
                draft={draft}
                control={control}
                onResponseType={onResponseType}
                initialTemplate={initialTemplate}
                saveDraft={saveDraft}
                opportunity={thread.opportunity}
                threadSource={thread.source}
                reset={reset}
              />

              {responseFormError && (
                <ErrorMessage className="mt-0 w-full">
                  {responseFormError || null}
                </ErrorMessage>
              )}
              <div className="flex gap-2 items-center justify-between">
                <InboxMessageResponseFormScheduleButton
                  onSchedule={(sendAfter) =>
                    createSendInboxMessage(sendAfter)()
                  }
                />
                <InboxMessageResponseFormSendPreviewButton
                  threadId={threadRef.id}
                  companyId={threadRef.parent.parent.id}
                  saveDraft={saveDraft}
                />
                <Button
                  data-test-id="inboxMessage-responseFormSendButton"
                  type="primary"
                  submit
                  className="h-fit"
                >
                  Send message
                </Button>
              </div>
            </form>
          )}
        </InboxMessageResponseFormDraftProvider>
      </div>
    </div>
  );
};

InboxMessageResponseForm.propTypes = {
  threadRef: PropTypes.object,
  thread: PropTypes.object,
};

export default InboxMessageResponseForm;
