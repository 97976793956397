import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { TrashIcon } from '@heroicons/react/solid';

import Avatar from 'components/Avatar';
import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import { ReactComponent as IconLinkedIn } from 'static/icon-linkedin.svg';
import { ReactComponent as IconTwitter } from 'static/icon-twitter.svg';

import CompanyMetaFoundersDeleteModal from './CompanyMetaFoundersDeleteModal';
import CompanyMetaFoundersEditModal from './CompanyMetaFoundersEditModal';

const CompanyMetaFounder = ({
  founder = {},
  showOwnerView = false,
  ops = {},
  companyId = '',
}) => {
  const { image, name, position, linkedinHandle, twitterHandle } = founder;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onSubmit = (data) => {
    ops.updateDoc(founder.uid, data);
    setIsEditModalOpen(false);
  };

  const onDelete = () => {
    ops.deleteDoc(founder.uid);
    setIsEditModalOpen(false);
  };

  return (
    <>
      <div
        key={founder.uid}
        className="relative flex rounded-lg border border-gray-200"
        data-test-id={`company-meta-founders-${founder.name}`}
      >
        <div className="relative flex-none rounded-l-lg overflow-hidden">
          <Avatar
            className="w-28 h-28 outline-none rounded-none"
            image={image}
            alt={`Image of founder ${name}`}
          />
        </div>
        <div className="p-3 pb-1 flex flex-col justify-between">
          <div className="space-y-1 text-sm">
            <h4
              className="text-gray-700 font-medium line-clamp-1"
              data-test-id={`company-meta-founders-${founder.name}-name`}
            >
              {name}
            </h4>
            <p
              className="text-gray-400 line-clamp-1"
              data-test-id={`company-meta-founders-${founder.name}-position`}
            >
              {position}
            </p>
          </div>

          <div className="flex justify-start items-center pb-2 space-x-2">
            {linkedinHandle && (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://linkedin.com/in/${linkedinHandle}`}
                className="text-sm line-clamp-1 text-blue-500"
              >
                <IconLinkedIn className="w-5" />
              </a>
            )}
            {twitterHandle && (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://twitter.com/${twitterHandle}`}
                className="text-sm line-clamp-1 text-blue-500"
              >
                <IconTwitter className="w-5" />
              </a>
            )}
          </div>
        </div>

        {showOwnerView && (
          <div className="absolute bottom-2 right-2">
            <DotsMenu
              options={[
                {
                  label: 'Edit founder',
                  onClick: () => setIsEditModalOpen(true),
                  testId: `company-meta-founders-${founder.name}-menu-edit`,
                },
                {
                  label: 'Delete founder',
                  icon: <TrashIcon className="w-4 h-4" />,
                  onClick: () => setIsDeleteModalOpen(true),
                  testId: `company-meta-founders-${founder.name}-menu-delete`,
                },
              ]}
              theme="medium"
              testId={`company-meta-founders-${founder.name}-menu`}
            />
          </div>
        )}
      </div>
      {showOwnerView && isEditModalOpen ? (
        <CompanyMetaFoundersEditModal
          open={isEditModalOpen}
          uploadFolder={`companies/${companyId}/founders`}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onSubmit}
          founder={founder}
        />
      ) : null}

      <CompanyMetaFoundersDeleteModal
        open={isDeleteModalOpen}
        name={founder.name}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={onDelete}
      />
    </>
  );
};

CompanyMetaFounder.propTypes = {
  founder: PropTypes.shape({
    uid: PropTypes.string,
    image: PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
    }),
    name: PropTypes.string,
    position: PropTypes.string,
    linkedinHandle: PropTypes.string,
    twitterHandle: PropTypes.string,
  }).isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  ops: PropTypes.shape({
    deleteDoc: PropTypes.func,
    updateDoc: PropTypes.func,
  }).isRequired,
  companyId: PropTypes.string.isRequired,
};

export default CompanyMetaFounder;
