import PropTypes from 'prop-types';
import React from 'react';

import { ContentTypes } from 'constants/contentTypes';

import CompanyContentElementTableRow from './CompanyContentElementTableRow';

const CompanyContentElementTableMAndA = ({ content = {} }) => {
  const {
    companyAcquiredCountry,
    companyAcquiredName,
    companyAcquiringName,
    sector,
    dealSize,
    other,
  } = content;
  return (
    <>
      <CompanyContentElementTableRow
        label="Acquired Company"
        contentType={ContentTypes.MA}
        content={companyAcquiredName}
      />
      <CompanyContentElementTableRow
        label="Country"
        contentType={ContentTypes.MA}
        content={companyAcquiredCountry}
      />
      <CompanyContentElementTableRow
        label="Acquirer"
        contentType={ContentTypes.MA}
        content={companyAcquiringName}
      />
      <CompanyContentElementTableRow
        type="currency"
        contentType={ContentTypes.MA}
        label="Size of deal"
        content={dealSize.amount}
        currency={dealSize.currency}
      />
      <CompanyContentElementTableRow
        label="Sector"
        type="list"
        contentType={ContentTypes.MA}
        content={sector}
      />
      {other ? (
        <CompanyContentElementTableRow
          label="More info"
          content={other}
          contentType={ContentTypes.MA}
        />
      ) : null}
    </>
  );
};

CompanyContentElementTableMAndA.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CompanyContentElementTableMAndA;
