import PropTypes from 'prop-types';
import React from 'react';

import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

const IconCheckmark = ({ active = false }) => (
  <span
    className={classNames(
      'absolute inset-y-0 left-0 items-center pl-1.5 hidden sm:flex',
      {
        'text-white': active,
        'text-teal-500': !active,
      }
    )}
  >
    <CheckIcon className="w-5 h-5" />
  </span>
);

IconCheckmark.propTypes = {
  active: PropTypes.bool,
};

export default IconCheckmark;
