import { addDoc, collection, getDoc } from 'firebase/firestore';
import noop from 'lodash/noop';

import {
  INBOXES_THREADS_MESSAGES_COLLECTION,
  INBOXES_THREADS_COLLECTION,
} from 'constants/firebasePaths';
import { tabNames } from 'constants/inboxTabs';
import { messageStatus } from 'constants/messageStatus';

const createInboxThread = async ({
  inboxRef = {},
  opportunity = {},
  response = null,
  approved = false,
  source = '',
  intent = '',
  draft = {},
  status = tabNames.ACTIVE,
  setDocument = noop,
  returnRefs = false,
}) => {
  const dateCreated = new Date().toISOString();

  // If inbox doesn't exist, create it first
  const inboxData = await getDoc(inboxRef);
  if (!inboxData.exists()) {
    await setDocument(inboxRef, { dateCreated });
  }

  // create new thread including opportunity
  const threadRef = await addDoc(
    collection(inboxRef, INBOXES_THREADS_COLLECTION),
    {
      dateCreated,
      opportunity,
      source,
      intent,
      status,
      draft,
    }
  );

  await setDocument(threadRef, { uid: threadRef.id }, { merge: true });

  let messageRef = null;
  if (response) {
    // add response as message to thread
    messageRef = await addDoc(
      collection(threadRef, INBOXES_THREADS_MESSAGES_COLLECTION),
      {
        dateCreated,
        isFirstMessage: true,
        text: response.text,
        html: response.html,
        isJournalist: false,
        attachments: response.attachments,
        status: approved ? messageStatus.APPROVED : messageStatus.UNAPPROVED,
      }
    );
  }

  return returnRefs ? { threadRef, messageRef } : threadRef?.id || null;
};

export default createInboxThread;
