import PropTypes from 'prop-types';
import React from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { LOGIN_PATH } from 'constants/paths';
import { REGEX_EMAIL } from 'constants/regex';

const SignupAgencyForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    nativeValidation: false,
  });

  return (
    <div className="mt-16 sm:mx-auto sm:w-full sm:max-w-md">
      <h2 className="text-lg font-bold text-gray-700 mb-2 text-center">
        Create your agency
      </h2>
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form className="space-y-3" onSubmit={handleSubmit(onSubmit)}>
          <InputFieldText
            {...register('agencyName', {
              required: true,
            })}
            type="text"
            required
          >
            <InputFieldText.Label>Agency name</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText
            {...register('email', {
              pattern: REGEX_EMAIL,
            })}
            type="email"
            autoComplete="email"
            required
          >
            <InputFieldText.Label>Email address</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText
            {...register('password', {
              required: true,
            })}
            type="password"
            required
          >
            <InputFieldText.Label>Password</InputFieldText.Label>
          </InputFieldText>
          <div className="pt-4">
            <Button size="base" submit full disabled={isSubmitting || !isValid}>
              {isSubmitting ? 'Loading' : 'Sign up'}
            </Button>
          </div>
        </form>
      </div>

      <div className="py-4 flex justify-center">
        <Link
          to={LOGIN_PATH}
          className="font-medium text-teal-500 hover:text-teal-500"
        >
          Or sign in if you have an account
        </Link>
      </div>
    </div>
  );
};

SignupAgencyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignupAgencyForm;
