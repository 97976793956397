import { useEffect, useMemo, useState } from 'react';

import noop from 'lodash/noop';

const defaultState = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};
const isBrowser = typeof window !== 'undefined';
const supportsResizeObserver = typeof window.ResizeObserver !== 'undefined';

const useMeasure = () => {
  const [element, ref] = useState(null);
  const [rect, setRect] = useState(defaultState);
  const observer = useMemo(
    () =>
      new window.ResizeObserver((entries) => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } =
            entries[0].contentRect;
          setRect({ x, y, width, height, top, left, bottom, right });
        }
      }),
    []
  );
  useEffect(() => {
    if (!element) {
      return noop;
    }

    observer.observe(element);

    return () => {
      observer.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  return [ref, rect];
};

export default isBrowser && supportsResizeObserver
  ? useMeasure
  : () => [noop, defaultState];
