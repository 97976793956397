import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';

import Callout from 'components/Callout/Callout';
import OnboardingInputsContainer from 'components/OnboardingInputsContainer';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import SeparatorTextLine from 'components/form/SeparatorTextLine';
import InputFieldTagsDropdown from 'components/form/inputFields/InputFieldTagsDropdown/InputFieldTagsDropdown';
import InputFieldTextOther from 'components/form/inputFields/InputFieldTextOther/InputFieldTextOther';
import { tagsAudiences, coreTopicsForUser } from 'constants/tags';
import filterCustomOptions from 'utils/data/filterCustomOptions';

import TitleSubtitle from '../TitleSubtitle';
import useOnboardingStore from '../stores/onboardingStores';

const StepThree = ({ onSubmit = noop, content = {} }) => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const { topics, audiences, topicsOther, audiencesOther } = useOnboardingStore(
    (state) => state.company
  );
  const updateCompany = useOnboardingStore((state) => state.updateCompany);

  const customTopics = filterCustomOptions(topics);
  const customAudiences = filterCustomOptions(audiences);

  const onFormChange = () => {
    updateCompany(getValues());
    // make sure not to trigger audiences when seleting a topic
    trigger('topics');
  };

  return (
    <form
      className="relative z-10 flex flex-col mx-4 items-center"
      onSubmit={handleSubmit(onSubmit)}
      onChange={onFormChange}
      data-test-id="onboarding-user-form"
    >
      <OnboardingInputsContainer>
        <TitleSubtitle title={content.title} subtitle={content.subtitle} />
        <Callout wrapperClassName="w-full md:w-fit !-mt-4 mb-4" type="info">
          You can change these later on in your Company Settings.
        </Callout>

        {/* Topics */}
        <fieldset className="w-full mt-5">
          <Label htmlFor="topics">{content.topicsTitle}</Label>

          <div className="mt-2">
            <Controller
              name="topics"
              control={control}
              defaultValue={topics}
              rules={{
                validate: {
                  value: (value) =>
                    value.length > 0 ||
                    'Please add at least one topic to the list',
                },
              }}
              render={({ field: { onChange, value } }) => (
                <InputFieldTagsDropdown
                  tags={coreTopicsForUser.concat(customTopics)}
                  tagsIndex={coreTopicsForUser}
                  value={value}
                  buttonClassName="w-full"
                  placeholder="Select as many as you would like"
                  onChange={(val) => {
                    onChange(val);
                    onFormChange();
                  }}
                  testId="onboarding-company-topics"
                />
              )}
            />
          </div>

          {errors.topics && (
            <ErrorMessage>{errors.topics.message}</ErrorMessage>
          )}

          <SeparatorTextLine />

          <InputFieldTextOther
            setValue={setValue}
            onFormChange={onFormChange}
            defaultFieldName="topics"
            defaultValue={topicsOther}
            watchedValue={topics}
            options={coreTopicsForUser}
            placeholder="Add custom industry topics here..."
            data-test-id="onboarding-company-topics-other"
          />
        </fieldset>

        {/* Audiences */}
        <fieldset className="w-full mt-10">
          <Label htmlFor="audiences">
            Who are the people you want to reach with your views?
          </Label>
          <div className="mt-2">
            <Controller
              name="audiences"
              control={control}
              defaultValue={audiences}
              rules={{
                validate: {
                  value: (value) =>
                    value.length > 0 ||
                    'Please add at least one audience to the list',
                },
              }}
              render={({ field: { onChange, value } }) => (
                <InputFieldTagsDropdown
                  tags={tagsAudiences.concat(customAudiences)}
                  tagsIndex={tagsAudiences}
                  value={value}
                  buttonClassName="w-full"
                  placeholder="Select as many as you would like"
                  onChange={(val) => {
                    onChange(val);
                    onFormChange();
                  }}
                  testId="onboarding-company-audiences"
                />
              )}
            />
          </div>

          {errors.audiences && (
            <ErrorMessage>{errors.audiences.message}</ErrorMessage>
          )}

          <SeparatorTextLine />

          <InputFieldTextOther
            setValue={setValue}
            onFormChange={onFormChange}
            defaultFieldName="audiences"
            defaultValue={audiencesOther}
            watchedValue={audiences}
            options={tagsAudiences}
            placeholder="Add custom audiences here..."
            data-test-id="onboarding-company-audiences-other"
          />
        </fieldset>
      </OnboardingInputsContainer>

      <div className="mb-8 flex items-center">
        <Button size="base" submit data-test-id="onboarding-user-submit">
          {content.submitText}
        </Button>
      </div>
    </form>
  );
};

StepThree.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
};

export default StepThree;
