import { useEffect } from 'react';

import noop from 'lodash/noop';
import { useNavigate } from 'react-router-dom';

const TIMEOUT_BEFORE_REDIRECT = 10000;

const useRedirectAfterTimeout = (targetPath = '', showAuthForm = true) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (showAuthForm) {
      return noop;
    }
    const timeout = setTimeout(() => {
      // need to refresh here for the logged-in status to take effect
      window.location.href = targetPath;
    }, TIMEOUT_BEFORE_REDIRECT);
    return () => {
      clearTimeout(timeout);
    };
  }, [showAuthForm, navigate, targetPath]);
};

export default useRedirectAfterTimeout;
