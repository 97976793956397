import noop from 'lodash/noop';

const callFnWhenConditionMet = (condition = noop, fn = noop) => {
  let timeout;
  const isConditionMet = setInterval(() => {
    if (condition()) {
      fn();
      clearInterval(isConditionMet);
      clearTimeout(timeout);
    }
  }, 1000);

  timeout = setTimeout(() => {
    clearInterval(isConditionMet);
  }, 5000);
};

export default callFnWhenConditionMet;
