/* eslint-disable max-params */
import { setDoc, updateDoc, Timestamp } from 'firebase/firestore';

import useUserData from 'contexts/UserContext/hooks/useUserData';

const useFirestoreWrapper = () => {
  const { claimsAdmin: isAdmin } = useUserData();

  const role = isAdmin ? 'admin' : 'user';

  const setDocument = async (documentRef, data, options) => {
    const metaData = {
      created: Timestamp.now(),
      createdBy: role,
      modified: Timestamp.now(),
      modifiedBy: role,
    };

    const mergedData = { ...data, meta: metaData };

    return setDoc(documentRef, mergedData, options);
  };

  const updateDocument = async (documentRef, data) => {
    const updateMetaData = {
      'meta.modified': Timestamp.now(),
      'meta.modifiedBy': role,
    };

    const mergedUpdateData = { ...data, ...updateMetaData };

    return updateDoc(documentRef, mergedUpdateData);
  };

  return {
    setDocument,
    updateDocument,
  };
};

export default useFirestoreWrapper;
