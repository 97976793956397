import PropTypes from 'prop-types';
import React from 'react';

import { TrashIcon } from '@heroicons/react/solid';
import { deleteDoc, doc } from 'firebase/firestore';
import noop from 'lodash/noop';
import { useFirestore } from 'reactfire';

import Callout from 'components/Callout/Callout';
import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import { SUCCESS_FEES_COLLECTION } from 'constants/firebasePaths';

const AdminSuccessFeesTableRowDeleteModal = ({
  onClose = noop,
  open = false,
  successFeeId = '',
}) => {
  const firestore = useFirestore();
  const deleteContent = async () => {
    const successFeesRef = doc(
      firestore,
      SUCCESS_FEES_COLLECTION,
      successFeeId
    );
    await deleteDoc(successFeesRef);
    onClose();
  };

  return (
    <Modal open={open}>
      <Modal.Close
        srMessage="Close Delete Success Fees modal"
        onClose={onClose}
      />
      <Modal.Icon Icon={TrashIcon} />
      <Modal.Title>Delete Success Fee</Modal.Title>
      <Modal.Description>
        Are you sure you want to delete this <b>success fee</b>?
      </Modal.Description>
      <Modal.Content>
        <Callout>
          If this success fee has already been invoiced (status: PENDING or
          PAID), this action won&apos;t delete the corresponding invoice in
          Stripe.
        </Callout>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button onClick={deleteContent}>Delete success fee</Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminSuccessFeesTableRowDeleteModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  successFeeId: PropTypes.string,
};

export default AdminSuccessFeesTableRowDeleteModal;
