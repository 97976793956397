import PropTypes from 'prop-types';
import React from 'react';

import { ClockIcon } from '@heroicons/react/outline';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';

const AdminCompaniesCompanyTier = ({
  tier = '',
  today = {},
  willCancel = false,
  cancelDate = '',
  status = '',
}) => {
  const cancelDateInFuture = willCancel ? new Date(cancelDate) > today : null;
  return (
    <td className="px-4 py-2">
      {tier}
      {willCancel && (
        <div className="mt-1">
          <span
            data-tooltip-id="tooltip-admin-companyTier"
            data-tooltip-content={
              (cancelDateInFuture ? 'Will cancel on ' : 'Was cancelled on ') +
              getDateFromTimestamp(cancelDate)
            }
            className="align-middle inline-block p-2 py-1 text-xs rounded-md whitespace-nowrap bg-red-100 text-red-500 cursor-pointer"
          >
            {cancelDateInFuture ? 'Cancelling' : 'Cancelled'}
          </span>
          <ReactTooltip id="tooltip-admin-companyTier" className="text-sm" />
        </div>
      )}
      {status === 'past_due' && (
        <div className="mt-1">
          <span
            data-tooltip-id="tooltip-admin-companyTier"
            data-tooltip-content="Payment past due"
            className="flex gap-1 w-fit p-2 py-1 text-xs rounded-md whitespace-nowrap bg-amber-100 text-amber-600 cursor-pointer"
          >
            <ClockIcon className="w-4 h-4" />
            Payment past due
          </span>
          <ReactTooltip id="tooltip-admin-companyTier" className="text-sm" />
        </div>
      )}
    </td>
  );
};

AdminCompaniesCompanyTier.propTypes = {
  tier: PropTypes.string,
  today: PropTypes.object,
  willCancel: PropTypes.bool,
  cancelDate: PropTypes.string,
  status: PropTypes.string,
};

export default AdminCompaniesCompanyTier;
