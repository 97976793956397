import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import Spinner from 'components/Spinner';
import useEnvironment from 'contexts/EnvironmentContext/hooks/useEnvironment';
import isUrl from 'utils/validate/isUrl';

import FirebaseImage from './FirebaseImage';
import HtmlImage from './HtmlImage';
import ResponsiveFirebaseImage from './ResponsiveFirebaseImage';
import isDataUrl from './helpers/isDataUrl';
import isStatic from './helpers/isStatic';

/**
 * Versatile image component that can display local and remote images.
 * Needs an image prop that contains a path to display an image. Alt attribute is also mandatory.
 * You can provide a placeholderImage that gets shown if the image is not available.
 */
const UniversalImage = ({
  image = null,
  alt = '',
  placeholderImage = null,
  className = '',
  contain = false,
  testId = '',
  isResponsive = false,
}) => {
  let ImageComponent = FirebaseImage;

  // Extra props that don't apply to both FirebaseImage and HtmlImage (e.g. placeHolder)
  const extraImageProps = {};

  const { isEmulated } = useEnvironment();
  // if no image or image path present...
  if (!image || !image.path || typeof image.path !== 'string') {
    // ...check for placeholderImage
    if (placeholderImage && typeof placeholderImage === 'string') {
      return (
        <HtmlImage className={className} path={placeholderImage} alt={alt} />
      );
    }

    // check if placeholderImage is an react element
    if (placeholderImage && typeof placeholderImage === 'object') {
      return placeholderImage;
    }
    // ...otherwise return null
    return null;
  }

  const { isLocal, path } = image;

  // remove query string and hashes for matching
  const pathClean = path.split(/[?#]/)[0];

  // if image is flagged as local, is a URL, data URL or in the static folder, use the HTML image
  if (
    isLocal ||
    isUrl(pathClean, isEmulated) ||
    isDataUrl(path) ||
    isStatic(path)
  ) {
    ImageComponent = HtmlImage;
  } else if (placeholderImage && typeof placeholderImage === 'string') {
    // only use `placeHolder` prop if ImageComponent is not a HtmlImage
    extraImageProps.placeHolder = (
      <HtmlImage className={className} path={placeholderImage} alt={alt} />
    );
  }

  // if isResponsive is true, and not emulating, as the resizing is not emulating use the responsive image
  if (isResponsive && !isEmulated) {
    ImageComponent = ResponsiveFirebaseImage;
  }

  return (
    <>
      <ImageComponent
        path={path}
        alt={alt}
        className={classNames(
          'w-full h-full object-center',
          {
            'object-cover': !contain,
            'object-contain': contain,
          },
          className
        )}
        data-test-id={testId}
        {...extraImageProps}
      />
      {image.isUploading && <Spinner />}
    </>
  );
};

UniversalImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholderImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alt: PropTypes.string.isRequired,
  contain: PropTypes.bool,
  testId: PropTypes.string,
  isResponsive: PropTypes.bool,
  className: PropTypes.string,
};

export default UniversalImage;
