/* eslint-disable class-methods-use-this */
import {
  logEvent,
  setAnalyticsCollectionEnabled,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';

class FirebaseAnalyticsAdapter {
  constructor(analytics) {
    this.analytics = analytics;
  }

  track(eventName, eventData) {
    logEvent(this.analytics, eventName, eventData);
  }

  identify(userId) {
    setUserId(this.analytics, userId);
  }

  setUserProperties(_, userProperties) {
    setUserProperties(this.analytics, userProperties);
  }

  setAnalyticsCollectionEnabled(isEnabled) {
    setAnalyticsCollectionEnabled(this.analytics, isEnabled);
  }
}

export default FirebaseAnalyticsAdapter;
