import PropTypes from 'prop-types';
import React from 'react';

import { useFormContext } from 'react-hook-form';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const ContentElementHireName = ({ index = 0 }) => {
  const name = 'name';
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="w-full">
      <InputFieldText
        id={`${name}${index}`}
        {...register(name, {
          required: {
            value: true,
            message: 'Please provide the name of the hire',
          },
        })}
        testId="company-content-hire-inputName"
        errors={errors}
        placeholder="Type the name of the hire..."
      >
        <InputFieldText.Label>Name of the hire*</InputFieldText.Label>
      </InputFieldText>
    </div>
  );
};

ContentElementHireName.propTypes = {
  index: PropTypes.number,
};

export default ContentElementHireName;
