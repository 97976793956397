import PropTypes from 'prop-types';
import React from 'react';

import { InboxIcon } from '@heroicons/react/solid';
import { doc } from 'firebase/firestore';
import { Link, generatePath } from 'react-router-dom';
import { useFirestore } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import { INBOXES_COLLECTION } from 'constants/firebasePaths';
import intents from 'constants/intents';
import { INBOX_PATH } from 'constants/paths';
import threadSource from 'constants/threadSource';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import { useTracking } from 'modules/segment/TrackingProvider';
import createInboxThread from 'utils/createInboxThread';

const reachOutOptions = [
  { value: intents.sweetheartList.OP_ED, label: 'Pitch Thought Leadership' },
  { value: intents.sweetheartList.OUTREACH, label: 'Send a cold outreach' },
  {
    value: intents.sweetheartList.PRESS_RELEASE,
    label: 'Pitch a press release',
  },
];

const createSuccessMessage = (threadId) => (
  <span data-test-id="sweetheartListJournalist-conversationStartedSuccess">
    <span>Your conversation with the journalist has been started </span>
    <span>. It&apos;s now in your </span>
    <Link
      type="button"
      className="inline-flex gap-1 items-center p-0.5 px-2 border-teal-600 border text-teal-600 rounded-md"
      to={generatePath(INBOX_PATH, { tab: 'draft', threadId })}
    >
      Inbox
    </Link>
    <span>
      {' '}
      as a draft. Go finalize and send it. The journalist is marked as
      &quot;Contacted.&quot;
    </span>
  </span>
);

const SweetheartListJournalistReachOut = ({ journalist = {} }) => {
  const { setDocument } = useFirestoreWrapper();
  const trackingService = useTracking();
  const firestore = useFirestore();
  const { companyData } = useCompanyData();

  const companyId = companyData.uid;

  const { showSuccess, showError } = useNotifications();
  const { getCompanyThreads } = useSweetheartListContext();

  const inboxRef = doc(firestore, INBOXES_COLLECTION, companyId);

  const createNewThread = async (intent) => {
    const thread = {
      source: threadSource.SWEETHEART_LIST,
      intent,
      status: 'draft',
      opportunity: {
        id: journalist.id,
        journalist: journalist.name,
        mediaOutlet: journalist.publication.name,
        domainAuthority: journalist.publication.domainAuthority,
      },
    };

    try {
      const threadId = await createInboxThread({
        inboxRef,
        source: thread.source,
        intent: thread.intent,
        status: thread.status,
        draft: thread.draft,
        opportunity: thread.opportunity,
        setDocument,
      });
      if (!threadId) {
        showError({
          title: "We're sorry! Something went wrong",
          message: "The thread couldn't be created.",
        });
        return;
      }
      showSuccess({
        title: 'Conversation started',
        Icon: InboxIcon,
        message: createSuccessMessage(threadId),
      });

      trackingService.track('Thread_Created', {
        source: 'sweetheartList',
        action: 'Reach out to Journalist',
        outreachType: intent,
        companyId,
      });

      setTimeout(async () => {
        // Refresh the data after 3 seconds to get the new thread
        getCompanyThreads();
      }, 3000);
    } catch (error) {
      showError({ message: 'Error starting conversation' });
    }
  };

  return (
    <InputFieldDropdown
      sameWidthOption={false}
      optionWithCheckbox={false}
      value="Start conversation"
      buttonClassName="w-fit"
      onChange={createNewThread}
      options={reachOutOptions}
      testId={`sweetheartListJournalist-startConversation-${journalist.name}`}
    />
  );
};

SweetheartListJournalistReachOut.propTypes = {
  journalist: PropTypes.object.isRequired,
};

export default SweetheartListJournalistReachOut;
