import md5 from 'crypto-js/md5';

const getJournalistId = (name = '', publicationName = '') => {
  const sanitizedName = (name || '').trim().replace(/\s+/g, ' ').toLowerCase();
  const sanitizedPublicationName = (publicationName || '')
    .trim()
    .replace(/\s+/g, ' ')
    .toLowerCase();
  return md5(`${sanitizedName}-${sanitizedPublicationName}`).toString();
};

export default getJournalistId;
