import {
  createUserWithEmailAndPassword,
  deleteUser,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import noop from 'lodash/noop';
import { useFirebaseApp, useAuth } from 'reactfire';

import functionsConfig from 'constants/functionsConfig';

const useSignupWithEmail = (cloudFnName = '') => {
  const auth = useAuth();

  // We need to set the region here explicitly:
  // https://github.com/FirebaseExtended/reactfire/issues/330#issuecomment-875708438
  const app = useFirebaseApp();
  const functions = getFunctions(app, functionsConfig.DEFAULT_REGION);
  const onRegister = httpsCallable(functions, cloudFnName);

  return async ({ formValues = {}, successCb = noop }) => {
    // create user with form values
    const authResponse = await createUserWithEmailAndPassword(
      auth,
      formValues.email,
      formValues.password
    );

    // user couldn't be created
    if (!authResponse.user) {
      throw new Error(
        "Couldn't create new user. Please contact tom@mvpr.io to resolve the issue."
      );
    }

    // call cloud function to assign custom claim to user
    const cloudFnResponse = await onRegister({
      uid: authResponse.user.uid,
      token: formValues.token,
    });

    // token could not be obtained
    if (!cloudFnResponse?.data?.valid) {
      await deleteUser(authResponse.user);
      throw new Error(
        'Looks like there was a token error. Please contact tom@mvpr.io to resolve the issue.'
      );
    }

    // get credentials to programmatically reauthenticate user
    const credentials = EmailAuthProvider.credential(
      formValues.email,
      formValues.password
    );

    const reauthenticateResponse = await reauthenticateWithCredential(
      authResponse.user,
      credentials
    );

    // obtain token and call success callback
    const token = await reauthenticateResponse.user.getIdTokenResult();
    successCb(token);
  };
};

export default useSignupWithEmail;
