import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

const CompanyQuoteWrapper = ({
  showOwnerView = false,
  onClick,
  children,
  testId = '',
}) => {
  const className =
    'relative z-0 flex flex-1 flex-col justify-between items-start p-6 rounded-lg text-sm border-gray-200 bg-white border';

  if (showOwnerView) {
    return <li className={className}>{children}</li>;
  }

  return (
    <Link to="#" onClick={onClick} className="flex" data-test-id={testId}>
      <li
        className={`${className} hover:border-teal-500 hover:shadow-md max-w-full`}
      >
        {children}
      </li>
    </Link>
  );
};

CompanyQuoteWrapper.propTypes = {
  showOwnerView: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

export default CompanyQuoteWrapper;
