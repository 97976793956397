// This is a bad solution to track the page title.
// The main reason for doing it like this is that the title update by React Helmet lags:

import capitalize from 'lodash/capitalize';
import { matchPath } from 'react-router-dom';

import {
  COMPANY_PATH,
  LOGIN_PATH,
  ONBOARDING_PATH,
  OPPORTUNITIES_PATH,
  SETTINGS_PATH,
  SIGNUP_JOURNALIST_PATH,
  SIGNUP_VC_PATH,
  SIGNUP_AGENCY_PATH,
  SWEETHEART_PATH,
  COMPANIES_JOURNALIST_PATH,
  INBOX_PATH,
  PASSWORD_RESET,
  JOURNALIST_RESPONSE_PATH,
} from 'constants/paths';
import getPageTitle from 'utils/getPageTitle';

const pageTitlesForPath = {
  [ONBOARDING_PATH]: (match) =>
    `Create a press page - step ${match?.params?.step}`,
  [SETTINGS_PATH]: (match) => `${capitalize(match?.params?.['*'])} settings`,
  [LOGIN_PATH]: () => 'Login',
  [PASSWORD_RESET]: () => 'Reset password',
  [SIGNUP_VC_PATH]: () => 'Signup as VC',
  [SIGNUP_AGENCY_PATH]: () => 'Signup as agency',
  [SIGNUP_JOURNALIST_PATH]: () => 'Signup as Journalist',
  [COMPANY_PATH]: (match) => `Press page - ${match?.params?.id}`,
  [OPPORTUNITIES_PATH]: () => 'Media opportunities',
  [SWEETHEART_PATH]: () => 'Sweetheart list',
  [INBOX_PATH]: () => 'Inbox',
  [JOURNALIST_RESPONSE_PATH]: () => 'Journalist response',
  [COMPANIES_JOURNALIST_PATH]: () => 'Journalist dashboard',
};

// We do this because of https://github.com/nfl/react-helmet/issues/189
const getAnalyticsPageTitle = (pathname = '') => {
  let pageTitle = '';

  // go through page titles and find the first match,
  // then run the function to generate the title and in case of a match,
  // short-circuit the execution by returning true
  Object.keys(pageTitlesForPath).some((path) => {
    const match = matchPath({ path }, pathname);
    if (match) {
      pageTitle = pageTitlesForPath[path](match);
      return true;
    }
    return false;
  });

  if (!pageTitle) {
    return null;
  }

  return getPageTitle(pageTitle);
};

export default getAnalyticsPageTitle;
