import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { PlusIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import useFormArray from 'hooks/useFormArray';

import ContentElementPartnershipCompaniesCompany from './ContentElementPartnershipCompaniesCompany';
import ContentElementPartnershipCompaniesEditModal from './ContentElementPartnershipCompaniesEditModal';

const ContentElementPartnershipCompanies = ({
  index = 0,
  updateCallback = noop,
}) => {
  const name = 'companies';
  const { add, remove, fields } = useFormArray({
    name,
    updateCallback,
  });

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  return (
    <>
      <div className="w-full flex flex-col">
        <div className="flex justify-between items-center">
          <Label htmlFor={`${name}0${index}`}>Companies*</Label>
        </div>

        <div className="flex flex-col mt-2">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50 text-xs font-medium text-gray-500 uppercase">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left tracking-wider"
                      >
                        Sectors
                      </th>
                      <th
                        scope="col"
                        colSpan={2}
                        className="px-4 py-3 text-left tracking-wider"
                      >
                        Logo
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!fields?.length && (
                      <tr>
                        <td colSpan="3">
                          <div className="flex flex-col space-y-4 justify-center items-center p-4">
                            <p className="text-sm text-gray-500">
                              Here you can add companies to your partnership
                              announcement.
                            </p>
                            <Button
                              type="secondary"
                              onClick={() => setIsEditModalOpen(true)}
                              data-test-id="company-content-partnership-addCompany"
                            >
                              Add first company
                            </Button>
                          </div>
                        </td>
                      </tr>
                    )}
                    {fields?.map((field, fieldIndex) => (
                      <ContentElementPartnershipCompaniesCompany
                        // eslint-disable-next-line react/no-array-index-key
                        key={`company${fieldIndex}`}
                        company={field}
                        onRemove={() => remove(fieldIndex)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {!!fields?.length && (
          <Button
            type="secondary"
            className="mt-2 ml-auto"
            disabled={fields?.length === 5}
            onClick={() => setIsEditModalOpen(true)}
          >
            <PlusIcon className="w-4 h-4 mr-1" />
            Add company
          </Button>
        )}
      </div>
      {isEditModalOpen ? (
        <ContentElementPartnershipCompaniesEditModal
          open={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={(company) => {
            add(company);
            setIsEditModalOpen(false);
          }}
        />
      ) : null}
    </>
  );
};

ContentElementPartnershipCompanies.propTypes = {
  index: PropTypes.number,
  updateCallback: PropTypes.func,
};

export default ContentElementPartnershipCompanies;
