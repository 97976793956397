import { getDateRange } from './dateRange';

// turn a timestamp into a date obj (reverses what `createTimestampFromDateObj` does)
const getDateObjFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return {
    day: date.getUTCDate(),
    month: getDateRange('months')[date.getUTCMonth()],
    year: date.getUTCFullYear(),
    hour: getDateRange('hours')[date.getUTCHours()],
  };
};

export const getDateObjFromTimestampCurrentUserTimezone = (timestamp) => {
  const date = new Date(timestamp);
  return {
    day: date.getDate(),
    month: getDateRange('months')[date.getMonth()],
    year: date.getFullYear(),
    hour: getDateRange('hours')[date.getHours()],
  };
};

export default getDateObjFromTimestamp;
