const downloadFile = (url = '', title = '') => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = () => {
    const blob = xhr.response;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = title;
    link.click();
    URL.revokeObjectURL(link.href);
  };
  xhr.open('GET', url);
  xhr.send();
};

export default downloadFile;
