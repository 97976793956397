import PropTypes from 'prop-types';
import React from 'react';

import StripeRedirectModal from 'components/StripeRedirectModal';
import Button from 'components/buttons/Button';
import useRedirectToStripePortal from 'hooks/useRedirectToStripePortal';
import { useTracking } from 'modules/segment/TrackingProvider';

const BillingSettingsSubscriptionUpdateButton = ({
  portalConfigName = '',
  action = 'subscription_update',
  subscriptionName = '',
}) => {
  const trackingService = useTracking();
  const { redirectToStripePortal, isRedirecting } =
    useRedirectToStripePortal(portalConfigName);

  const onClick = async (event) => {
    await trackingService.track('subscription_update', {
      action,
      stripe_subscriptionName: subscriptionName,
    });
    redirectToStripePortal(event);
  };

  return (
    <>
      <Button className="col-span-2 w-fit" type="secondary" onClick={onClick}>
        {action === 'reactivate'
          ? 'Reactivate your subscription'
          : 'Update your subscription'}
      </Button>
      <StripeRedirectModal isRedirecting={isRedirecting} />
    </>
  );
};

BillingSettingsSubscriptionUpdateButton.propTypes = {
  portalConfigName: PropTypes.string,
  action: PropTypes.oneOf(['update', 'reactivate']),
  subscriptionName: PropTypes.string,
};

export default BillingSettingsSubscriptionUpdateButton;
