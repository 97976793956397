import Proptypes from 'prop-types';
import React from 'react';

import { DownloadIcon, TrashIcon, PlusIcon } from '@heroicons/react/solid';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import noop from 'lodash/noop';
import { useStorage } from 'reactfire';
import { v4 as uuidv4 } from 'uuid';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import downloadFile from 'utils/file/downloadFile';

const InputFieldMultiUpload = ({ value = [], onChange = noop }) => {
  const storage = useStorage();
  const { companyData } = useCompanyData();
  const uploadFolder = `inboxes/${companyData?.uid}`;

  const uploadFile = async (file) => {
    const path = `${uploadFolder}/${uuidv4()}-${file.name}`;
    const uploadRef = ref(storage, path);
    await uploadBytes(uploadRef, file, {
      contentDisposition: `attachment; filename="${file.name}"`,
      customMetadata: {
        companyId: companyData?.uid,
      },
    });
    const url = await getDownloadURL(uploadRef);
    return {
      path,
      contentType: file.type,
      downloadUrl: url,
      fileName: file.name,
      contentDisposition: 'attachment',
    };
  };

  const onInputChange = async (e) => {
    const files = Array.from(e.target.files);
    const uploadedFiles = await Promise.all(
      files.map((file) => uploadFile(file))
    );
    onChange([...value, ...uploadedFiles]);
    // empty the file input
    e.target.value = '';
  };

  const onDelete = (idx) => {
    const newValue = value.filter((_, i) => i !== idx);
    onChange(newValue);
  };

  return (
    <ul className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
      {value.map((v, i) => (
        <li key={v.path} className="col-span-1 rounded-md shadow-sm group">
          <div className="p-3 flex w-full items-center justify-between rounded-md border border-gray-200 bg-white">
            <div className="flex-1 truncate text-sm">
              <span className="font-medium text-gray-900 group-hover:text-gray-600">
                {v.fileName}
              </span>
            </div>
            <div className="flex relative w-7">
              <DotsMenu
                theme="dark"
                options={[
                  {
                    label: `Download`,
                    icon: <DownloadIcon className="w-4 h-4" />,
                    onClick: () => downloadFile(v.downloadUrl, v.fileName),
                  },
                  {
                    label: `Delete`,
                    icon: <TrashIcon className="w-4 h-4" />,
                    onClick: () => onDelete(i),
                  },
                ]}
              />
            </div>
          </div>
        </li>
      ))}
      <li className="col-span-1 flex rounded-md shadow-sm group cursor-pointer">
        <div className="relative p-3 flex flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white">
          <input
            type="file"
            multiple="multiple"
            onChange={onInputChange}
            className="opacity-0 absolute inset-0 z-10 cursor-pointer"
          />
          <div className="mr-1 text-teal-500 cursor-pointer">
            <PlusIcon className="w-6 h-6 -my-1" />
          </div>
          <div className="flex-1 truncate text-sm cursor-pointer">
            <span className="font-medium text-gray-500">Add attachment...</span>
          </div>
        </div>
      </li>
    </ul>
  );
};

InputFieldMultiUpload.propTypes = {
  value: Proptypes.array,
  onChange: Proptypes.func,
};

export default InputFieldMultiUpload;
