import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import CompanyAutocompleteField from 'components/company/CompanyAutocompleteField';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextEditor from 'components/form/inputFields/InputFieldTextEditor';
import InputFieldToggle from 'components/form/inputFields/InputFieldToggle';
import { REGEX_DOMAIN_AUTHORITY, REGEX_URL } from 'constants/regex';
import { todaysDateObj } from 'utils/date/dateRange';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

import AdminAddOpportunityModalDeadline from './AdminAddOpportunityModalDeadline';

const defaultValues = {
  enquirySummary: '',
  subject: '',
  type: '✨ Perfect Match',
  query: '',
  mediaOutlet: '',
  mediaOutletWebsite: '',
  domainAuthority: '',
  journalist: '',
  deadline: todaysDateObj,
  evergreen: false,
  tags: [],
  exclusive: false,
  targetedCompanies: [],
};

const getDefaultValues = (opportunity = {}) => ({
  ...defaultValues,
  ...opportunity,
  description: {
    text: opportunity?.description,
    html: opportunity?.opportunityHtml,
  },
  query: opportunity?.query
    ? opportunity.query.join('\n')
    : defaultValues.query,
  evergreen: !opportunity?.deadlineUTC || defaultValues?.evergreen,
  exclusive: opportunity?.exclusive || defaultValues?.exclusive,
  targetedCompanies: opportunity?.targetedCompanies || [],
  deadline: opportunity?.deadlineUTC
    ? getDateObjFromTimestamp(
        opportunity?.deadlineUTC?.toDate().toLocaleString('en-GB')
      )
    : defaultValues.deadline,
});

const AdminAddOpportunityModal = ({
  open,
  onClose,
  onSubmit,
  companiesData = {},
  opportunity = null,
}) => {
  const [loading, setLoading] = useState(false);
  const formName = 'addOpportunityForm';
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, errors },
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(opportunity),
  });

  const isEvergreen = watch('evergreen');
  const exclusive = watch('exclusive');

  const onOpportunityAddRequested = async (data) => {
    setLoading(true);
    await onSubmit(data);
    setLoading(false);
  };

  return (
    <Modal open={open} testId="opportunityResponseModal">
      <Modal.Close
        srMessage="Close Add Opportunity Modal"
        onClose={onClose}
        testId="opportunityResponseModal-close"
      />
      <Modal.Title testId="dialog-1-title">
        {opportunity ? 'Edit' : 'Add'} opportunity
      </Modal.Title>
      <Modal.Content>
        <form
          onSubmit={handleSubmit(onOpportunityAddRequested)}
          id={formName}
          className="space-y-3"
        >
          <InputFieldText
            {...register('enquirySummary', {
              required: 'Please put in an enquiry summary',
              maxLength: {
                value: 140,
                message: 'Please limit this to 140 characters',
              },
            })}
            placeholder="Type in the enquiry summary..."
            errors={errors}
          >
            <InputFieldText.Label>Enquiry summary*</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText
            {...register('subject', {
              maxLength: {
                value: 140,
                message: 'Please limit this to 140 characters',
              },
            })}
            placeholder="Type in the email subject..."
            errors={errors}
          >
            <InputFieldText.Label>Subject</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText
            {...register('type', {
              maxLength: {
                value: 140,
                message: 'Please limit this to 60 characters',
              },
            })}
            placeholder="Type in the opportunity type..."
            errors={errors}
          >
            <InputFieldText.Label>Type</InputFieldText.Label>
          </InputFieldText>

          <div className="flex flex-col">
            <Label className="mb-2" htmlFor="query">
              Query*
            </Label>
            <Controller
              name="description"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Please put in a query',
                },
              }}
              render={({ field: { onChange } }) => (
                <InputFieldTextEditor
                  name="description"
                  height={300}
                  initialValue={
                    opportunity?.descriptionHtml ||
                    (opportunity?.query || []).join('\n')
                  }
                  onChange={(html, text) => {
                    onChange({ html, text });
                  }}
                />
              )}
            />

            {errors.query && (
              <ErrorMessage>{errors.query.message}</ErrorMessage>
            )}
          </div>

          <InputFieldText
            {...register('mediaOutlet', {
              required: 'Please put in a media outlet',
              maxLength: {
                value: 140,
                message: 'Please limit this to 140 characters',
              },
            })}
            placeholder="Type in the media outlet..."
            errors={errors}
          >
            <InputFieldText.Label>Media outlet*</InputFieldText.Label>
          </InputFieldText>

          <InputFieldText
            {...register('mediaOutletWebsite', {
              required: 'Please put in a media outlet website',
              pattern: {
                value: REGEX_URL,
                message: 'Please enter a valid URL (https://...)',
              },
            })}
            maxLength={2000}
            errors={errors}
            placeholder="Type in the media outlet website..."
          >
            <InputFieldText.Label>Media outlet website*</InputFieldText.Label>
          </InputFieldText>

          <InputFieldText
            {...register('domainAuthority', {
              required: {
                value: true,
                message: 'Please put in a domain authority',
              },
              pattern: {
                value: REGEX_DOMAIN_AUTHORITY,
                message: 'Please type in a number between 0 and 100',
              },
            })}
            errors={errors}
            placeholder="Type in the domain authority..."
          >
            <InputFieldText.Label>Domain authority*</InputFieldText.Label>
          </InputFieldText>

          <InputFieldText
            {...register('journalist', {
              required: 'Please put in a journalist',
              maxLength: {
                value: 140,
                message: 'Please limit this to 140 characters',
              },
            })}
            placeholder="Type in the journalist..."
            errors={errors}
          >
            <InputFieldText.Label>Journalist*</InputFieldText.Label>
          </InputFieldText>

          <div className="input-field-wrapper">
            <div className="row-wrapper flex items-center justify-between mb-4">
              <div className="label-wrapper" style={{ flex: '1' }}>
                <InputFieldText.Label htmlFor="targetedCompanies">
                  Target Companies
                </InputFieldText.Label>
              </div>
              <div className="toggle-wrapper" style={{ flex: 'none' }}>
                <Controller
                  name="exclusive"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputFieldToggle
                      value={value}
                      onChange={onChange}
                      text="Exclusive"
                    />
                  )}
                />
              </div>
            </div>
            {exclusive && (
              <p className="text-sm text-gray-600 mb-4">
                When exclusive is enabled, this opportunity will only be visible
                to the companies added in here.
              </p>
            )}

            <Controller
              name="targetedCompanies"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CompanyAutocompleteField
                  name="targetedCompanies"
                  error={errors.targetedCompanies}
                  targetedCompanies={value}
                  companiesData={companiesData}
                  updateCallback={(newValue) => onChange(newValue)}
                />
              )}
            />
          </div>

          <div className="flex justify-between items-center">
            <Label htmlFor="deadline">Deadline (UTC)</Label>
            <Controller
              name="evergreen"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputFieldToggle
                  value={value}
                  onChange={onChange}
                  text="Ongoing"
                />
              )}
            />
          </div>

          <AdminAddOpportunityModalDeadline
            control={control}
            isEvergreen={isEvergreen}
            setValue={setValue}
            watch={watch}
          />
        </form>
      </Modal.Content>

      <Modal.RightButtons>
        <Button type="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button submit disabled={!isValid || loading} form={formName}>
          {opportunity ? 'Update' : 'Add'} opportunity
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminAddOpportunityModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opportunity: PropTypes.object,
  companiesData: PropTypes.object,
  disabledAddButton: PropTypes.bool,
};

export default AdminAddOpportunityModal;
