import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const CompanySnapshotEditModalEmoji = ({
  emoji = {},
  onClick,
  isSelected = false,
  isLast = false,
}) => (
  <a
    key={emoji.char}
    href="#"
    className={classNames(
      'w-12 h-12 mb-1 flex justify-center items-center border border-gray-200 rounded-full',
      {
        'mr-0': isLast,
        'mr-1': !isLast,
        'bg-gray-100': isSelected,
        'bg-white': !isSelected,
      }
    )}
    onClick={onClick}
    data-tooltip-content={emoji.label}
    data-tooltip-id="tooltip-milestone-emoji"
    data-test-id={`company-snapshot-editModal-emoji-${emoji.char}`}
  >
    <span className="text-xl">{emoji.char}</span>
    <ReactTooltip id="tooltip-milestone-emoji" className="text-sm" />
  </a>
);

CompanySnapshotEditModalEmoji.propTypes = {
  emoji: PropTypes.shape({
    char: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default CompanySnapshotEditModalEmoji;
