import React from 'react';

import { collection, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import Card from 'components/Card';
import defaultOptions from 'constants/defaultOptions';
import { SUCCESS_FEES_COLLECTION } from 'constants/firebasePaths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import BillingSettingsSuccessFeesTable from './BillingSettingsSuccessFeesTable';

const BillingSettingsSuccessFees = () => {
  const firestore = useFirestore();

  const { companyData } = useCompanyData();
  const companyId = companyData?.uid;

  const successFeesRef = query(
    collection(firestore, SUCCESS_FEES_COLLECTION),
    where('companyId', '==', companyId)
  );

  const { data: successFees } = useFirestoreCollectionData(
    successFeesRef,
    defaultOptions
  );

  return (
    <Card title="Success Fees Billing Summary & Invoices">
      <div className="w-full items-center gap-5">
        {successFees && successFees.length > 0 ? (
          <BillingSettingsSuccessFeesTable successFees={successFees} />
        ) : (
          <p className="text-center text-sm text-gray-900 mx-auto max-w-prose leading-relaxed">
            There are no success fees yet.
          </p>
        )}
      </div>
    </Card>
  );
};

export default BillingSettingsSuccessFees;
