import PropTypes from 'prop-types';
import React from 'react';

import { Controller } from 'react-hook-form';

import { currencies } from 'constants/currencies';

import InputFieldDropdown from '../InputFieldDropdown/InputFieldDropdown';

import InputFieldCurrencyFormattedInput from './InputFieldCurrencyFormattedInput';

const InputFieldCurrency = ({
  control = {},
  currencyDefaultName = 'GBP',
  nameCurrency = '',
  nameAmount = '',
  currencyWrapperClassName = '',
  inputClassName = '',
  inputRules = {},
  testId = '',
  id = '',
}) => {
  const currencySymbol = currencies.find(
    (currency) => currency.name === currencyDefaultName
  )?.symbol;
  const currencyNames = currencies.map((currency) => currency.name);

  return (
    <div>
      <div className="flex relative rounded-md shadow-sm ">
        <Controller
          control={control}
          name={nameAmount}
          rules={inputRules}
          render={({ field: { onChange, value } }) => (
            <InputFieldCurrencyFormattedInput
              onChange={onChange}
              value={value}
              inputClassName={inputClassName}
              currency={currencyDefaultName}
              id={id}
              testId={testId}
            />
          )}
        />
        <div className="flex items-center">
          <label htmlFor="currency" className="sr-only">
            Currency
          </label>
          <Controller
            name={nameCurrency}
            control={control}
            defaultValue={currencyDefaultName}
            render={({ field: { onChange, value } }) => (
              <InputFieldDropdown
                name={nameCurrency}
                onChange={onChange}
                value={value}
                wrapperClassName={currencyWrapperClassName}
                buttonClassName="cursor-pointer rounded-l-none hover:bg-gray-100 w-[calc(3ch+3rem)]"
                options={currencyNames}
              />
            )}
            rules={{
              required: {
                value: true,
                message: 'Please provide a currency',
              },
            }}
          />
        </div>
        <span className="absolute z-[2] inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-500 sm:text-sm">
          {currencySymbol}
        </span>
      </div>
    </div>
  );
};

InputFieldCurrency.propTypes = {
  control: PropTypes.object.isRequired,
  currencyDefaultName: PropTypes.string,
  nameCurrency: PropTypes.string.isRequired,
  currencyWrapperClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  nameAmount: PropTypes.string.isRequired,
  inputRules: PropTypes.object,
  testId: PropTypes.string,
  id: PropTypes.string,
};

export default InputFieldCurrency;
