import React from 'react';

import { matchPath, Outlet, ScrollRestoration } from 'react-router-dom';

import Intercom from 'components/Intercom/Intercom';
import NotificationsProvider from 'components/Notifications/NotificationsProvider';
import AnalyticsPageLogger from 'components/integrations/AnalyticsPageLogger/AnalyticsPageLogger';
import CompanyLastSeenTracker from 'components/integrations/CompanyLastSeenTracker/CompanyLastSeenTracker';
import CoreLayout from 'components/layout/CoreLayout';
import { COMPANY_PATH, INBOX_PATH, OPPORTUNITIES_PATH } from 'constants/paths';
import CompanyProvider from 'contexts/CompanyContext/CompanyContext';
import ImpersonationProvider from 'contexts/ImpersonationContext/ImpersonationContext';
import UserProvider from 'contexts/UserContext/UserContext';
import useQuery from 'hooks/useQuery';

const RootPage = () => {
  const query = useQuery();
  const skipRoot = query.get('skipRoot');

  if (skipRoot) {
    return <UserProvider>{() => <Outlet />}</UserProvider>;
  }

  return (
    <UserProvider>
      {(uid) => (
        <ImpersonationProvider>
          <CompanyProvider uid={uid}>
            <NotificationsProvider>
              <>
                <CoreLayout>
                  <Outlet />
                  <AnalyticsPageLogger />
                  <CompanyLastSeenTracker userId={uid} />
                </CoreLayout>
                <ScrollRestoration
                  getKey={(location) => {
                    const isInbox = matchPath(INBOX_PATH, location.pathname);

                    const isPressPage = matchPath(
                      COMPANY_PATH,
                      location.pathname
                    );

                    const isOpportunitiesPage = matchPath(
                      OPPORTUNITIES_PATH,
                      location.pathname
                    );

                    if (isInbox) {
                      return 'inbox';
                    }

                    if (isPressPage) {
                      return 'press';
                    }

                    if (isOpportunitiesPage) {
                      return 'opportunities';
                    }

                    return location.key;
                  }}
                />
              </>
            </NotificationsProvider>
            <Intercom />
          </CompanyProvider>
        </ImpersonationProvider>
      )}
    </UserProvider>
  );
};

export default RootPage;
