import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';
import slugify from 'slugify';

import OnboardingInputsContainer from 'components/OnboardingInputsContainer';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import SeparatorTextLine from 'components/form/SeparatorTextLine';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldTagsDropdown from 'components/form/inputFields/InputFieldTagsDropdown/InputFieldTagsDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextOther from 'components/form/inputFields/InputFieldTextOther/InputFieldTextOther';
import numberOfEmployeesOptions from 'constants/numberOfEmployees';
import { REGEX_URL_NO_PROTOCOL } from 'constants/regex';
import { tagsVcs } from 'constants/tags';
import { useTracking } from 'modules/segment/TrackingProvider';
import filterCustomOptions from 'utils/data/filterCustomOptions';

import TitleSubtitle from '../TitleSubtitle';
import useVerifySlug from '../hooks/useVerifySlug';
import useOnboardingStore from '../stores/onboardingStores';

import StepFourFoundedDate from './StepFourFoundedDate';
import vcImages from './constants/vcImages';

const StepFour = ({ onSubmit = noop, content = {} }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setError,
    setValue,
    formState,
  } = useForm({ mode: 'onChange' });

  const { errors } = formState;

  const trackingService = useTracking();

  const company = useOnboardingStore((state) => state.company);
  const {
    foundedDate,
    location,
    name,
    slug,
    vcs,
    vcsOther,
    website,
    numberOfEmployees,
  } = company;
  const user = useOnboardingStore((state) => state.user);
  const updateCompany = useOnboardingStore((state) => state.updateCompany);
  const updateUser = useOnboardingStore((state) => state.updateUser);

  const customVcs = filterCustomOptions(vcs);
  const verifySlug = useVerifySlug(setError);

  const onFormChange = () => {
    const newValues = getValues();
    const newSlug = slugify(newValues.name, { lower: true, strict: true });
    setValue('slug', newSlug);
    const isValidSlug = verifySlug(newSlug, newValues.name);
    const { firstName, lastName, ...rest } = getValues();
    updateUser({ firstName, lastName });
    updateCompany({ ...rest, slug: isValidSlug ? newSlug : null });
  };

  // verify name/slug once on page mount
  useEffect(() => {
    const values = getValues();
    verifySlug(values.slug, values.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      className="relative z-10 flex flex-col mx-4 items-center pb-24"
      onSubmit={handleSubmit(onSubmit)}
      onChange={onFormChange}
      action="#"
      data-test-id="onboarding-company-form"
    >
      <OnboardingInputsContainer>
        <TitleSubtitle title={content.title} subtitle={content.subtitle} />

        <div className="w-full mt-3">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {content.personalTitle}
          </h3>
          {content.personalSubtitle && (
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {content.personalSubtitle}
            </p>
          )}
        </div>

        {/* First Name  */}
        <div className="w-full mt-5">
          <InputFieldText
            {...register('firstName', {
              required: content.errors.userFirstName,
            })}
            defaultValue={user.firstName}
            placeholder={content.placeholders.userFirstName}
            errors={errors}
            testId="onboarding-user-firstName"
          >
            <InputFieldText.Label>First name*</InputFieldText.Label>
          </InputFieldText>
        </div>

        {/* Last Name  */}
        <div className="w-full mt-5">
          <InputFieldText
            errors={errors}
            {...register('lastName', { required: content.errors.userLastName })}
            defaultValue={user.lastName}
            placeholder={content.placeholders.userLastName}
            testId="onboarding-user-lastName"
          >
            <InputFieldText.Label>Last name*</InputFieldText.Label>
          </InputFieldText>
        </div>

        <div className="w-full mt-10">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {content.companyTitle}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {content.companySubtitle}
          </p>
        </div>

        {/* Company Name */}
        <div className="w-full mt-5">
          <InputFieldText
            {...register('name', {
              required: content.errors.companyName,
            })}
            defaultValue={name}
            placeholder={content.placeholders.companyName}
            testId="onboarding-company-name"
            errors={errors}
          >
            <InputFieldText.Label>Company name*</InputFieldText.Label>
          </InputFieldText>
          <p className="text-xs text-gray-500 mt-1">
            The company profile URL will be:{' '}
            <span className="font-semibold">
              mvpr.io/company/
              {slug || <i className="text-gray-400">your-company-name</i>}
            </span>
          </p>
          <input type="hidden" {...register('slug')} defaultValue={slug} />
        </div>

        {/* Company Website */}
        <div className="w-full mt-5">
          <InputFieldText
            {...register('website', {
              pattern: {
                value: REGEX_URL_NO_PROTOCOL,
                message: content.errors.companyWebsite,
              },
            })}
            maxLength={2000}
            defaultValue={website}
            placeholder={content.placeholders.companyWebsite}
            testId="onboarding-company-url"
            errors={errors}
          >
            <InputFieldText.Prefix>https://</InputFieldText.Prefix>
            <InputFieldText.Label>Company website</InputFieldText.Label>
          </InputFieldText>
        </div>

        {/* Founded Date */}
        <fieldset className="w-full mt-5">
          <Label htmlFor="foundedDate">Founded date</Label>
          <StepFourFoundedDate
            control={control}
            watch={watch}
            setValue={setValue}
            foundedDate={foundedDate}
            onFormChange={onFormChange}
          />
        </fieldset>

        {/* Location */}
        <div className="w-full mt-5">
          <InputFieldText
            {...register('location', {
              required: 'Please type in the location',
            })}
            defaultValue={location}
            placeholder={content.placeholders.companyLocation}
            testId="onboarding-company-location"
            errors={errors}
          >
            <InputFieldText.Label>Location*</InputFieldText.Label>
          </InputFieldText>
        </div>

        {/* Number of employees */}
        <fieldset className="w-full mt-5">
          <Label htmlFor="numberOfEmployees">Number of employees</Label>
          <div className="flex mt-2">
            <Controller
              name="numberOfEmployees"
              control={control}
              defaultValue={numberOfEmployees || numberOfEmployeesOptions[0]}
              render={({ field: { onChange, value } }) => (
                <InputFieldDropdown
                  options={numberOfEmployeesOptions}
                  onChange={(val) => {
                    onChange(val);
                    onFormChange();
                  }}
                  value={value}
                  buttonClassName="w-44"
                  wrapperClassName="mr-2"
                />
              )}
            />
          </div>
          {errors.numberOfEmployees && (
            <ErrorMessage>{errors.numberOfEmployees.message}</ErrorMessage>
          )}
        </fieldset>

        {/* VCs */}
        <fieldset className="w-full mt-5">
          <Label htmlFor="vcs">{content.labels.vcs}</Label>
          <div className="mt-2">
            <Controller
              name="vcs"
              control={control}
              defaultValue={vcs}
              render={({ field: { onChange, value } }) => (
                <InputFieldTagsDropdown
                  tags={tagsVcs.concat(customVcs)}
                  value={value}
                  buttonClassName="w-full"
                  multiplePlaceholder="Select as many as you would like"
                  // eslint-disable-next-line react/no-unstable-nested-components
                  prependButtonFn={(tag) => (
                    <img
                      src={vcImages[tag.slug]}
                      alt="VC logo"
                      className="w-4 h-4 rounded-full mr-2"
                    />
                  )}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  prependTagFn={(tag) => (
                    <img
                      src={vcImages[tag.slug]}
                      alt="VC logo"
                      className="h-8 w-8 rounded-full mr-3"
                    />
                  )}
                  onChange={(val) => {
                    onChange(val);
                    onFormChange();
                  }}
                  testId="onboarding-company-vcs"
                />
              )}
            />
          </div>

          {errors.vcs && <ErrorMessage>{errors.vcs.message}</ErrorMessage>}

          <SeparatorTextLine text="OR" />

          <InputFieldTextOther
            setValue={setValue}
            onFormChange={onFormChange}
            defaultFieldName="vcs"
            defaultValue={vcsOther}
            watchedValue={vcs}
            options={tagsVcs}
            placeholder="Add other VCs here..."
            extraParams={{ slug: 'custom' }}
            data-test-id="onboarding-company-vcs-other"
          />
        </fieldset>
      </OnboardingInputsContainer>
      <div className="mb-8 flex items-center">
        <Button
          size="base"
          submit
          data-test-id="onboarding-company-submit"
          onClick={() => {
            // only fire the event if the form is valid
            if (formState.isValid) {
              trackingService.track('pressPage_create', { company_slug: slug });
            }
          }}
        >
          {content.submitText}
        </Button>
      </div>
    </form>
  );
};

StepFour.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
};

export default StepFour;
