import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { DocumentIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import noop from 'lodash/noop';

import Spinner from 'components/Spinner';
import UniversalImage from 'components/UniversalImage/UniversalImage';
import CloseButton from 'components/buttons/CloseButton';
import isImageFile from 'utils/file/isImageFile';

const InputFieldUploadFilePreview = ({
  preset = {},
  file = {},
  onDelete = noop,
  alt = '',
  testId = '',
  contain = false,
}) => {
  const isImage = useMemo(() => isImageFile(file), [file]);
  const isImagePreset = preset.name === 'image';

  return (
    <div className="rounded-lg overflow-hidden flex flex-col h-full w-full">
      <div
        className={classNames(
          'relative flex min-h-24 grow bg-gray-100 justify-center items-center text-teal-500',
          {
            'min-h-40': isImagePreset,
          }
        )}
      >
        {file.isUploading ? (
          <>
            <div className="opacity-75 bg-white w-full h-full absolute" />
            <Spinner />
          </>
        ) : null}

        {isImage ? (
          <UniversalImage
            image={file}
            alt={alt}
            contain={contain}
            testId={`${testId}-previewImage`}
            className="h-40"
          />
        ) : (
          <DocumentIcon
            data-test-id={`${testId}-previewIcon`}
            className="h-1/2 max-h-16"
          />
        )}
      </div>
      {!isImagePreset ? (
        <div className="bg-gray-50 p-3 relative">
          <p className="text-sm font-medium text-gray-700 truncate">
            {file.localName || file.name}
          </p>
          <p className="text-sm text-gray-400">
            {Math.floor(file.size / 1024)}kB
          </p>
        </div>
      ) : null}
      <CloseButton
        className="absolute -top-2 -right-2 shadow-sm shadow-black/75"
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      />
    </div>
  );
};

InputFieldUploadFilePreview.propTypes = {
  preset: PropTypes.object,
  file: PropTypes.object,
  onDelete: PropTypes.func,
  alt: PropTypes.string,
  testId: PropTypes.string,
  contain: PropTypes.bool,
};

export default InputFieldUploadFilePreview;
