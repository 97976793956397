import PropTypes from 'prop-types';
import React from 'react';

import { LightningBoltIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import getDomainAuthorityClassNames from 'utils/color/getDomainAuthorityClassNames';

// Displays the domain authority number next to a lightning bolt icon, or "n/a" if none exists
const DomainAuthorityPill = ({ domainAuthority = null }) => (
  <span
    className={classNames(
      'inline-flex items-center px-2 py-1 rounded-md text-xs min-w-max',
      getDomainAuthorityClassNames(domainAuthority)
    )}
  >
    <LightningBoltIcon className="w-3 h-3 mr-1" />
    {domainAuthority ? `${domainAuthority} / 100` : 'n/a'}
  </span>
);

DomainAuthorityPill.propTypes = {
  domainAuthority: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default DomainAuthorityPill;
