import { addDoc, collection } from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useUser } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import { INBOXES_THREADS_MESSAGES_COLLECTION } from 'constants/firebasePaths';
import { tabNames } from 'constants/inboxTabs';
import { messageStatus } from 'constants/messageStatus';
import { INBOX_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useEnvironment from 'contexts/EnvironmentContext/hooks/useEnvironment';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import { useTracking } from 'modules/segment/TrackingProvider';
import sendNotification from 'modules/slack/sendNotification';
import formatDate from 'utils/date/formatDate';

const useSendInboxMessage = ({
  thread = {},
  threadRef = {},
  isAdmin = false,
}) => {
  const { tab } = useParams();
  const trackingService = useTracking();

  const navigate = useNavigate();
  const user = useUser();
  const { isLocal } = useEnvironment();
  const { companyData } = useCompanyData();
  const { showSuccess, showError } = useNotifications();
  const { updateDocument } = useFirestoreWrapper();
  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      subject: '',
      response: {
        text: '',
        html: '',
      },
      attachments: [],
    },
  });
  const messageError = errors;

  const createSendInboxMessage =
    (sendAfter = null) =>
    async () => {
      let success = false;
      const { subject, response, attachments } = getValues();
      const dateCreated = new Date().toISOString();
      const messageDoc = {
        isJournalist: false,
        text: response.text,
        html: response.html,
        attachments: attachments || [],
        dateCreated,
        sendAfter,
      };
      const opportunityUpdate =
        thread.source === 'sweetheartList' && tab === 'draft'
          ? {
              opportunity: {
                ...thread.opportunity,
                subject: subject || thread.draft.subject,
              },
            }
          : {};

      let message;

      try {
        message = await addDoc(
          collection(threadRef, INBOXES_THREADS_MESSAGES_COLLECTION),
          {
            ...messageDoc,
            status: isAdmin ? messageStatus.APPROVED : messageStatus.UNAPPROVED,
            isFirstMessage: tab === tabNames.DRAFT,
          }
        );

        await updateDocument(threadRef, {
          draft: null,
          ...opportunityUpdate,
          ...(tab === tabNames.DRAFT ? { status: tabNames.ACTIVE } : {}),
        });

        showSuccess({
          message: `Your response will be sent to the journalist${
            sendAfter ? ` on ${formatDate(sendAfter)}` : ''
          }`,
        });
        success = true;
      } catch {
        showError({
          title: 'Whoops! Something went wrong',
          message: "We couldn't send your response, please try again",
        });
      }

      trackingService.track('inbox_interaction', {
        action: 'response_send',
        inbox_threadId: thread.uid,
        inbox_numberOfCharacters: response?.length,
      });

      navigate(
        generatePath(INBOX_PATH, {
          tab: tabNames.ACTIVE,
          threadId: thread.uid,
          messageId: message.id,
        })
      );

      if (isLocal) {
        return;
      }

      if (success) {
        const { opportunity } = thread;
        sendNotification({
          opportunity,
          user,
          response: response.text,
          dateCreated,
          companyName: companyData.name,
          companySlug: companyData.slug,
        });
      }
    };

  return {
    handleSubmit,
    createSendInboxMessage,
    register,
    messageError,
    reset,
    control,
  };
};

export default useSendInboxMessage;
