import PropTypes from 'prop-types';
import React from 'react';

import { XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import noop from 'lodash/noop';

import { useTracking } from 'modules/segment/TrackingProvider';

// Small rounded close button to show in corners for closing modals and deleting items
// TODO-ANALYTICS: Extract the `onClickWithAnalytics` function and move to a separate hook
const CloseButton = ({
  onClick = noop,
  eventType = '',
  eventProperties = {},
  className = '',
  srMessage = 'Close',
  testId = '',
}) => {
  // analytics: track button click
  const trackingService = useTracking();

  const onClickWithAnalytics = (e) => {
    if (eventType) {
      trackingService.track(eventType, eventProperties);
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      type="button"
      className={classNames(
        'bg-white rounded-2xl text-gray-400 hover:text-gray-500 focus:outline-none',
        className
      )}
      onClick={onClickWithAnalytics}
      data-test-id={testId}
    >
      <span className="sr-only">{srMessage}</span>
      <XIcon className="h-6 w-6 sm:h-5 sm:w-5" />
    </button>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  eventType: PropTypes.string,
  eventProperties: PropTypes.object,
  testId: PropTypes.string,
  className: PropTypes.string,
  srMessage: PropTypes.string,
};

export default CloseButton;
