import PropTypes from 'prop-types';
import React, { cloneElement, useState, useEffect } from 'react';

import classNames from 'classnames';
import get from 'lodash/get';
import { Controller } from 'react-hook-form';

import ErrorMessage from 'components/form/ErrorMessage';
import uploadPresets from 'constants/uploadPresets';
import useCompoundComponents from 'hooks/useCompoundComponents';
import registerCompoundComponents from 'utils/components/registerCompoundComponents';

import InputFieldUploadDropzone from './InputFieldUploadDropzone';
import InputFieldUploadFilePreview from './InputFieldUploadFilePreview';
import useCustomDropzone from './hooks/useCustomDropzone';
import inputFieldUploadCompoundComponents from './inputFieldUploadCompoundComponents';

/**
 * @typedef InputFieldUploadProps
 * @property {keyof uploadPresets} presetName
 *
 * @param {InputFieldUploadProps} props
 *
 * @returns
 */
const InputFieldUpload = ({
  id = '',
  name = '',
  folder = '',
  form = {},
  children = null,
  testId = '',
  presetName = 'image',
  alt = 'Preview of uploaded file',
  overridePreview = false,
  containPreview = false,
  showError = true,
  rules = {},
}) => {
  const preset = uploadPresets[presetName];
  const { Label, Hint } = useCompoundComponents(
    children,
    inputFieldUploadCompoundComponents
  );
  const {
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    control,
  } = form;
  const formFile = watch(name);
  const error = get(errors, name, false);
  const inputId = id || name;

  const [localFile, setLocalFile] = useState(formFile);
  const file = localFile || formFile;

  const { getInputProps, getRootProps, isDragActive } = useCustomDropzone({
    setLocalFile,
    preset,
    folder,
    name,
    setValue,
    setError,
    clearErrors,
  });

  useEffect(() => {
    if (overridePreview) {
      setValue(name, overridePreview, { shouldValidate: true });
      setLocalFile(overridePreview);
    }
  }, [overridePreview, name, setValue, setLocalFile]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={formFile || null}
      rules={rules}
      render={() => (
        <div className="w-full flex flex-col gap-2">
          {Label ? cloneElement(Label, { htmlFor: inputId }) : null}
          <div
            {...getRootProps()}
            className={classNames(
              'rounded-lg border border-gray-300 h-40 flex items-center',
              {
                'border-2 border-dashed': !file || isDragActive,
                'w-full flex flex-col relative': file,
              }
            )}
            data-test-id={`${testId}-dropzone`}
          >
            {file && !isDragActive ? (
              <InputFieldUploadFilePreview
                onDelete={() => {
                  if (file.isLocal) {
                    URL.revokeObjectURL(file.path);
                  }
                  setValue(name, null, { shouldValidate: true });
                  setLocalFile(null);
                }}
                contain={containPreview}
                file={file}
                preset={preset}
                alt={alt}
                testId={testId}
              />
            ) : (
              <InputFieldUploadDropzone
                name={name}
                acceptText={preset.acceptText}
                getInputProps={getInputProps}
                isDragActive={isDragActive}
              />
            )}
          </div>
          {Hint || null}

          {error && showError ? (
            <ErrorMessage testId={`${testId}-error`}>
              {error.message}
            </ErrorMessage>
          ) : null}
        </div>
      )}
    />
  );
};

registerCompoundComponents(
  InputFieldUpload,
  inputFieldUploadCompoundComponents
);

InputFieldUpload.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  folder: PropTypes.string.isRequired,
  form: PropTypes.object,
  children: PropTypes.node,
  testId: PropTypes.string,
  presetName: PropTypes.oneOf(Object.keys(uploadPresets)),
  overridePreview: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  alt: PropTypes.string,
  rules: PropTypes.object,
  showError: PropTypes.bool,
  containPreview: PropTypes.bool,
};

export default InputFieldUpload;
