import PropTypes from 'prop-types';
import React from 'react';

import { Helmet } from 'react-helmet';

import OnboardingWrapper from 'components/OnboardingWrapper/OnboardingWrapper';
import PageWrapper from 'components/PageWrapper';
import Spinner from 'components/Spinner';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import getPageTitle from 'utils/getPageTitle';

import SweetheartListHeader from './SweetheartListHeader/SweetheartListHeader';
import SweetheartListJournalists from './SweetheartListJournalists';
import SweetheartListPageBeingGenerated from './SweetheartListViewStates/SweetheartListPageBeingGenerated';
import SweetheartListPageJournalistsError from './SweetheartListViewStates/SweetheartListPageJournalistsError';

const SweetheartList = ({ company = {} }) => {
  const { isEnabled, journalists, loading, error } = useSweetheartListContext();
  const { claimsAdmin: isAdmin, claimsAgencyId: isAgency } = useUserData();

  const allowSweetheartListWhenNotGenerated = isAdmin || isAgency;
  const isBeingGenerated =
    !isEnabled && !error && !loading && !allowSweetheartListWhenNotGenerated;
  const isLoadingWithNoError = loading && !error;
  const isEnabledAndLoaded = isEnabled && !loading;

  return (
    <PageWrapper>
      <OnboardingWrapper>
        <Helmet>
          <title>{getPageTitle('Sweetheart List')}</title>
        </Helmet>

        {/* --- IF LIST DOES NOT EXIST IN AIRTABLE --- */}
        {error && (
          <SweetheartListPageJournalistsError companyName={company.name} />
        )}

        {/* --- LOADING STATE --- */}
        {isLoadingWithNoError && <Spinner />}

        {/* --- GENERATING LIST --- */}
        {isBeingGenerated && <SweetheartListPageBeingGenerated />}

        {/* --- JOURNALISTS --- */}
        {!error &&
          (isEnabledAndLoaded || allowSweetheartListWhenNotGenerated) && (
            <div className="w-full">
              <SweetheartListHeader />
              {journalists?.length > 0 && <SweetheartListJournalists />}
            </div>
          )}
      </OnboardingWrapper>
    </PageWrapper>
  );
};

SweetheartList.propTypes = {
  company: PropTypes.object.isRequired,
};

export default SweetheartList;
