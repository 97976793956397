import PropTypes from 'prop-types';
import React from 'react';

import journalistResponseOptions from '../../constants/journalistResponseOptions';

const InboxMessageJournalistResponse = ({
  journalistResponse = '',
  journalistName = '',
}) => {
  const { Icon, responseText, bgColor, textColor } =
    journalistResponseOptions[journalistResponse];
  return (
    <div
      className={`${bgColor} w-full border-b`}
      data-test-id="inboxMessage-journalistFeedback"
    >
      <div className="mx-auto max-w-7xl py-3 px-3">
        <div className="flex flex-wrap justify-between">
          <div className="flex">
            <span className="flex p-2">
              <Icon className={`h-6 w-6 ${textColor}`} aria-hidden="true" />
            </span>
            <p className={`ml-3 text-sm  ${textColor}`}>
              {journalistName} has shared some feedback:
              <br />
              <span className="font-medium">&ldquo;{responseText}&rdquo;</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

InboxMessageJournalistResponse.propTypes = {
  journalistResponse: PropTypes.oneOf(['positive', 'neutral', 'negative']),
  journalistName: PropTypes.string,
};

export default InboxMessageJournalistResponse;
