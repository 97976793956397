/* eslint-disable no-underscore-dangle */

// generates the iubenda configuration object, among other things to style the modal
const createIubendaConfiguration = (onConsentGiven) => {
  window._iub = window._iub || [];
  window._iub.csConfiguration = {
    countryDetection: true,
    consentOnContinuedBrowsing: false,
    perPurposeConsent: true,
    enableCMP: true,
    enableCcpa: true,
    googleAdditionalConsentMode: true,
    lang: 'en',
    siteId: process.env.REACT_APP_IUBENDA_SITE_ID,
    cookiePolicyId: process.env.REACT_APP_IUBENDA_COOKIE_POLICY_ID,
    floatingPreferencesButtonDisplay: 'bottom-left',
    banner: {
      acceptButtonDisplay: true,
      customizeButtonDisplay: true,
      acceptButtonColor: '#14b8a6',
      acceptButtonCaptionColor: '#ffffff',
      customizeButtonColor: '#5eead4',
      customizeButtonCaptionColor: 'white',
      rejectButtonDisplay: true,
      rejectButtonColor: '#cacaca',
      position: 'top',
      textColor: '#010101',
      backgroundColor: '#ffffff',
    },
    callback: {
      onConsentGiven,
    },
  };
};

export default createIubendaConfiguration;
