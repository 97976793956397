import PropTypes from 'prop-types';
import React from 'react';

import { CalendarIcon } from '@heroicons/react/solid';

const AdminOpportunityDeadline = ({ deadlineUTC = null }) => (
  <p className="inline-flex items-center text-xs py-1 px-2 space-x-1 bg-gray-100 text-gray-500 font-semibold rounded-md">
    <CalendarIcon className="w-4" />
    <span>
      {deadlineUTC?.toDate().toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })}
    </span>
    {!deadlineUTC && <span>Ongoing</span>}
  </p>
);

AdminOpportunityDeadline.propTypes = {
  deadlineUTC: PropTypes.object,
};

export default AdminOpportunityDeadline;
