import React from 'react';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import OpportunitiesProvider from 'contexts/OpportunitiesContext/OpportunitiesContext';
import useRedirectOpportunities from 'hooks/useRedirectOpportunities';

import Opportunities from './Opportunities';

const OpportunitiesPage = () => {
  const { companyData } = useCompanyData();
  const showOpportunities = useRedirectOpportunities();

  if (!showOpportunities) {
    return null;
  }

  // if no company data is available, throw error
  if (!companyData) {
    return "Can't find any media opportunities";
  }

  return (
    <OpportunitiesProvider>
      <Opportunities />
    </OpportunitiesProvider>
  );
};

export default OpportunitiesPage;
