import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { useNavigate, generatePath } from 'react-router-dom';

import Button from 'components/buttons/Button';
import {
  ADMIN_MESSAGES_COMPANIES_PATH,
  ADMIN_MESSAGES_PATH,
} from 'constants/paths';

import AdminMessagesCompaniesCompany from './AdminMessagesCompaniesCompany';
import getUnapprovedMessagesCount from './helpers/getUnapprovedMessagesCount';

const AdminMessagesCompanies = ({ inboxes = [] }) => {
  const navigate = useNavigate();
  const inboxesSorted = [...inboxes]?.sort(
    (a, b) => getUnapprovedMessagesCount(b) - getUnapprovedMessagesCount(a)
  );

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200 table-auto">
        <thead className="bg-gray-50 text-xs font-medium text-gray-500 uppercase">
          <tr>
            <th scope="col" className="px-6 py-3 text-left tracking-wider">
              Company
            </th>
            <th scope="col" className="px-6 py-3 text-center tracking-wider">
              # of Unapproved Messages
            </th>
            <th scope="col" className="px-6 py-3 text-center tracking-wider">
              # of Active Threads
            </th>
            <th scope="col" className="px-6 py-3 text-center tracking-wider">
              # of Total Threads
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {inboxesSorted.map((inbox) => {
            const activeThreadsCount = inbox?.threads?.filter(
              (t) => t?.data?.isArchived !== true
            ).length;
            const totalThreadsCount = inbox?.threads?.length;
            const unapprovedMessagesCount = getUnapprovedMessagesCount(inbox);

            return (
              <tr key={inbox.id} className="text-center">
                {inbox.data && (
                  <td className="p-4">
                    <AdminMessagesCompaniesCompany
                      name={inbox.data.name}
                      logo={inbox.data.logo}
                    />
                  </td>
                )}
                <td>
                  <span
                    className={classNames({
                      'text-sm bg-red-100 font-semibold text-red-600 py-1 px-2 rounded-full min-w-[28px] w-fit inline-block ':
                        unapprovedMessagesCount > 0,
                    })}
                  >
                    {unapprovedMessagesCount}
                  </span>
                </td>
                <td>{activeThreadsCount}</td>
                <td>{totalThreadsCount}</td>
                <td className="p-4">
                  <div className="flex justify-end">
                    <Button
                      onClick={() =>
                        navigate(
                          generatePath(ADMIN_MESSAGES_PATH, {
                            type: ADMIN_MESSAGES_COMPANIES_PATH,
                            inboxId: inbox.id,
                          })
                        )
                      }
                    >
                      View Threads
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

AdminMessagesCompanies.propTypes = {
  inboxes: PropTypes.array,
};

export default AdminMessagesCompanies;
