// Returns the hour and minute from a timestamp
const getTimeFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  return date.toLocaleTimeString('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
  });
};

export default getTimeFromTimestamp;
