import React from 'react';

import AdminThreadsFiltersArchivedToggle from './AdminThreadsFiltersArchivedToggle';
import AdminThreadsFiltersJournalist from './AdminThreadsFiltersJournalist';

const AdminThreadsFilters = () => (
  <div className="flex gap-2 self-start flex-wrap justify-between w-full items-end">
    <AdminThreadsFiltersJournalist />
    <div className="flex items-center h-9">
      <AdminThreadsFiltersArchivedToggle />
    </div>
  </div>
);

export default AdminThreadsFilters;
