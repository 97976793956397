import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import classNames from 'classnames';
import currency from 'currency.js';
import { collection, getDocs, query, where } from 'firebase/firestore';
import noop from 'lodash/noop';
import { Controller } from 'react-hook-form';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import defaultOptions from 'constants/defaultOptions';
import {
  PRODUCTS_COLLECTION,
  PRODUCTS_PRICES_COLLECTION,
} from 'constants/firebasePaths';
import { REGEX_URL } from 'constants/regex';
import useDateTimeSelector from 'hooks/useDateTimeSelector';
import { todaysDateObj } from 'utils/date/dateRange';

const mapSuccessFees = (successFee) => ({
  label: successFee?.name || '',
  value: successFee?.uid || '',
});

const SuccessFeesModalForm = ({
  disabled = false,
  setDraft = noop,
  form = {},
}) => {
  const firestore = useFirestore();

  const productsCol = collection(firestore, PRODUCTS_COLLECTION);
  const productsQuery = query(productsCol, where('role', '==', 'success_fees'));

  const { data: successFees = [] } = useFirestoreCollectionData(
    productsQuery,
    defaultOptions
  );
  const successFeesOptions = successFees.map(mapSuccessFees);

  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = form;
  const allValues = watch();

  const validOptionRanges = useDateTimeSelector({
    config: {
      type: 'past',
    },
    selection: {
      selectedDay: allValues?.date?.day,
      selectedMonth: allValues?.date?.month,
      selectedYear: allValues?.date?.year,
    },
    updateSelections: ({ day, month, year }) => {
      setValue('date.day', day);
      setValue('date.month', month);
      setValue('date.year', year);
    },
  });

  const onTierChange = useCallback(
    async (product) => {
      if (!product?.uid) {
        return;
      }

      const pricesCol = collection(
        firestore,
        PRODUCTS_COLLECTION,
        product?.uid,
        PRODUCTS_PRICES_COLLECTION
      );
      const pricesQuery = query(pricesCol, where('active', '==', true));
      const pricesDocs = await getDocs(pricesQuery);

      const priceData = pricesDocs.docs[0].data();

      const price = currency(priceData?.unit_amount || 0, {
        fromCents: true,
        symbol: '£',
      });
      const tax = price.multiply(0.2);
      const total = price.add(tax);

      setDraft({
        product,
        price,
        priceData,
        tax,
        total,
      });
    },
    [firestore, setDraft]
  );

  useEffect(() => {
    const selectedProductId = allValues?.productId;
    const selectedProduct = selectedProductId
      ? successFees.find((successFee) => successFee?.uid === selectedProductId)
      : successFees[0];

    onTierChange(selectedProduct);
  }, [allValues?.productId, successFees, onTierChange]);

  return (
    <div
      className={classNames('flex gap-2 w-full', {
        'opacity-50 pointer-events-none': disabled,
      })}
    >
      <div className="flex flex-col gap-2 w-full">
        <InputFieldText
          defaultValue=""
          maxLength={2000}
          {...register('title', {
            required: {
              value: true,
              message: 'Please provide a title',
            },
          })}
          errors={errors}
          placeholder="A free-form text prepended to the invoice item"
        >
          <InputFieldText.Label>Title</InputFieldText.Label>
        </InputFieldText>

        <InputFieldText
          defaultValue=""
          maxLength={2000}
          {...register('link', {
            required: {
              value: true,
              message: 'Please provide an URL',
            },
            pattern: {
              value: REGEX_URL,
              message: 'Please type in a valid URL',
            },
          })}
          errors={errors}
          placeholder="The URL of the article..."
        >
          <InputFieldText.Label>Article link*</InputFieldText.Label>
        </InputFieldText>

        <div className="flex flex-col gap-2">
          <Label htmlFor="name">Publication date</Label>
          <div className="flex w-full gap-2">
            <Controller
              name="date.day"
              control={control}
              defaultValue={todaysDateObj.day}
              render={({ field: { onChange, value } }) => (
                <InputFieldDropdown
                  options={validOptionRanges.days}
                  onChange={onChange}
                  value={value}
                  buttonClassName="w-24"
                />
              )}
            />
            <Controller
              name="date.month"
              control={control}
              defaultValue={todaysDateObj.month}
              render={({ field: { onChange, value } }) => (
                <InputFieldDropdown
                  options={validOptionRanges.months}
                  onChange={onChange}
                  value={value}
                  buttonClassName="w-36"
                />
              )}
            />
            <Controller
              name="date.year"
              control={control}
              defaultValue={todaysDateObj.year}
              render={({ field: { onChange, value } }) => (
                <InputFieldDropdown
                  options={validOptionRanges.years}
                  onChange={onChange}
                  value={value}
                  buttonClassName="w-28"
                />
              )}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="tier">Success fee</Label>
          <Controller
            name="productId"
            control={control}
            defaultValue={successFeesOptions[0]?.value || ''}
            render={({ field: { onChange, value } }) => (
              <InputFieldDropdown
                options={successFeesOptions}
                onChange={onChange}
                value={value}
                buttonClassName="w-full"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

SuccessFeesModalForm.propTypes = {
  form: PropTypes.object,
  setDraft: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SuccessFeesModalForm;
