import { doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { COMPANIES_COLLECTION } from 'constants/firebasePaths';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';

const useSetCompanyArchived = (companyId = '') => {
  const { setDocument } = useFirestoreWrapper();
  const firestore = useFirestore();
  const companyRef = doc(firestore, COMPANIES_COLLECTION, companyId);

  return (archived = false) =>
    setDocument(companyRef, { archived }, { merge: true });
};

export default useSetCompanyArchived;
