import { collection } from 'firebase/firestore';
import sortBy from 'lodash/sortBy';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import {
  CUSTOMERS_COLLECTION,
  CUSTOMERS_PAYMENTS_COLLECTION,
} from 'constants/firebasePaths';
import useUserData from 'contexts/UserContext/hooks/useUserData';

const useBillingSettingsPaymentData = () => {
  const firestore = useFirestore();
  const { uid } = useUserData();

  const paymentsRef = collection(
    firestore,
    CUSTOMERS_COLLECTION,
    uid,
    CUSTOMERS_PAYMENTS_COLLECTION
  );

  const { data: payments } = useFirestoreCollectionData(paymentsRef, {
    initialData: [],
  });

  const sortedPayments = sortBy(payments, 'created');
  const lastPaymentData =
    sortedPayments[sortedPayments.length - 1]?.charges?.data?.[0];
  const {
    last4,
    brand,
    exp_month: expiryMonth,
    exp_year: expiryYear,
  } = lastPaymentData?.payment_method_details?.card ?? {};
  return {
    card: {
      lastDigits: last4 || null,
      brand: brand || null,
      expiryMonth: expiryMonth || null,
      expiryYear: expiryYear || null,
    },
    name: lastPaymentData?.billing_details?.name || null,
    email: lastPaymentData?.billing_details?.email || null,
    address: lastPaymentData?.billing_details?.address || null,
  };
};

export default useBillingSettingsPaymentData;
