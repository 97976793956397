import InputFieldTextLabel from './InputFieldTextLabel';
import InputFieldTextOverlay from './InputFieldTextOverlay';
import InputFieldTextPrefix from './InputFieldTextPrefix';
import InputFieldTextSuffix from './InputFieldTextSuffix';

const inputFieldTextCompoundComponents = {
  Prefix: InputFieldTextPrefix,
  Suffix: InputFieldTextSuffix,
  Label: InputFieldTextLabel,
  Overlay: InputFieldTextOverlay,
};

export default inputFieldTextCompoundComponents;
