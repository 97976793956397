import PropTypes from 'prop-types';
import React from 'react';

// Good ol' HTML image
const HtmlImage = ({ path = '', alt = '', ...rest }) => (
  // Adding referrer-policy attribute, as suggested here, to avoid 403 error
  // https://stackoverflow.com/questions/40570117/http403-forbidden-error-when-trying-to-load-img-src-with-google-profile-pic
  <img referrerPolicy="no-referrer" src={path} alt={alt} {...rest} />
);

HtmlImage.propTypes = {
  path: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default HtmlImage;
