import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import AddButton from 'components/buttons/AddButton';

// The empty state for press page components, renders a title, description and add button
const CompanySectionPlaceholder = ({
  title = '',
  description = '',
  onClick,
  paddingClassName = 'p-8',
  className = '',
  testId = '',
}) => (
  <li
    className={classNames(
      'border border-dashed rounded-lg border-gray-200 bg-white flex flex-col justify-center items-center text-center space-y-2',
      paddingClassName,
      className
    )}
  >
    <p className="text-gray-300 text-xl font-medium">{title}</p>
    <p className="text-gray-300 text-sm font-normal">{description}</p>
    <AddButton onClick={onClick} testId={testId} />
  </li>
);

CompanySectionPlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  paddingClassName: PropTypes.string,
  className: PropTypes.string,
  testId: PropTypes.string,
};

export default CompanySectionPlaceholder;
