import PropTypes from 'prop-types';
import React, { createContext } from 'react';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import useCompaniesOpportunitiesCollections from './hooks/useCompaniesOpportunitiesCollections';
import useCompaniesOpportunitiesOps from './hooks/useCompaniesOpportunitiesOps';

const defaultOpportunities = {
  companiesOpportunitiesCollections: {},
  companiesOpportunitiesOps: {},
};

export const OpportunitiesContext = createContext(defaultOpportunities);
const { Provider } = OpportunitiesContext;

// This provider obtains collection references
// Since those shouldn't change, it will only render once on initial page load
const OpportunitiesProvider = ({ children }) => {
  const { companyData } = useCompanyData();

  // The opportunities collections only return references, so they won't change or cause re-renders
  const companiesOpportunitiesCollections =
    useCompaniesOpportunitiesCollections(companyData.uid);

  // Returns only functions (potentially memo'able) and shouldn't change references
  const companiesOpportunitiesOps = useCompaniesOpportunitiesOps({
    collections: companiesOpportunitiesCollections,
    company: companyData,
  });

  return (
    <Provider
      value={{
        companiesOpportunitiesCollections,
        companiesOpportunitiesOps,
      }}
    >
      {children}
    </Provider>
  );
};

OpportunitiesProvider.propTypes = {
  children: PropTypes.node,
};

export default OpportunitiesProvider;
