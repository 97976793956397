import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/lodash';
import { NavLink } from 'react-router-dom';

// Navigation Link in the mobile nav overlay
const MobileNavLink = ({
  path = '',
  label = '',
  Icon = null,
  onClick = noop,
  Badge = null,
}) => (
  <li className="px-3">
    <NavLink
      onClick={onClick}
      to={path}
      className={({ isActive }) =>
        classNames(
          'flex justify-between items-center py-2 px-3 rounded-md text-white hover:bg-white hover:bg-opacity-25 focus:outline-none',
          { 'bg-white bg-opacity-25': isActive }
        )
      }
      role="menuitem"
    >
      <div className="flex items-center">
        <Icon className="w-9 h-9 pr-4" />
        {label}
      </div>
      {Badge && Badge}
    </NavLink>
  </li>
);

MobileNavLink.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string,
  Icon: PropTypes.elementType,
  onClick: PropTypes.func.isRequired,
  Badge: PropTypes.node,
};

export default MobileNavLink;
