import PropTypes from 'prop-types';
import React from 'react';

import { EyeIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';

const UpdateLogoModal = ({
  open = false,
  uploadFolder = '',
  onClose = noop,
  onSubmit = noop,
}) => {
  const form = useForm({ mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isValid },
  } = form;

  return (
    <Modal open={open} testId="updateLogoModal">
      <Modal.Icon Icon={EyeIcon} />
      <Modal.Title>Update your company logo</Modal.Title>
      <Modal.Description>
        Make your press page more unique with your own logo
      </Modal.Description>
      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="updateLogoModal-cancel"
        >
          Cancel
        </Button>
        <Button
          submit
          form="updateLogoForm"
          disabled={!isValid}
          data-test-id="updateLogoModal-submit"
        >
          Update logo
        </Button>
      </Modal.RightButtons>
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)} id="updateLogoForm">
          <InputFieldUpload
            name="logo"
            folder={uploadFolder}
            testId="updateLogoModal-upload"
            alt="Company logo preview"
            rules={{
              required: 'Please upload a logo',
            }}
            form={form}
            containPreview
          >
            <InputFieldUpload.Hint>
              Square images work best (e.g. 500x500 or bigger)
            </InputFieldUpload.Hint>
          </InputFieldUpload>
        </form>
      </Modal.Content>
    </Modal>
  );
};

UpdateLogoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadFolder: PropTypes.string.isRequired,
};

export default UpdateLogoModal;
