import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as LinkedInIcon } from 'static/icon-linkedin.svg';

const CompanyContentElementFooterLinkedin = ({ linkedInUrl = '' }) => (
  <a
    href={linkedInUrl}
    onClick={(e) => e.stopPropagation()}
    target="_blank"
    rel="noreferrer"
    className="text-blue-500 flex items-center space-x-1 text-sm hover:text-blue-700"
    data-test-id="company-content-linkedIn"
  >
    <LinkedInIcon className="w-4 h-4" />
    <span>LinkedIn</span>
    <span className="hidden sm:block">profile</span>
  </a>
);

CompanyContentElementFooterLinkedin.propTypes = {
  linkedInUrl: PropTypes.string.isRequired,
};

export default CompanyContentElementFooterLinkedin;
