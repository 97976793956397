const stringArrayToFilter = (arr) =>
  arr.map((c) => encodeURIComponent(c)).join(',');

const getCompanySearchQuery = ({
  stages = [],
  topics = [],
  contentTypes = [],
  minFunding = 0,
  maxFunding = 0,
  fundingLevels = [],
}) => {
  const filterParts = [];

  if (stages.length > 0) {
    filterParts.push(`fundraise_funding_rounds=${stringArrayToFilter(stages)}`);
  }

  if (contentTypes.length > 0) {
    filterParts.push(`content_types=${stringArrayToFilter(contentTypes)}`);
  }

  if (minFunding > 0 || maxFunding < fundingLevels[fundingLevels.length - 1]) {
    filterParts.push(
      `fundraise_capital_raised_amounts=${minFunding},${maxFunding}`
    );
  }

  if (topics.length > 0) {
    filterParts.push(
      `topics=${topics.map((t) => encodeURIComponent(t.name)).join(',')}`
    );
  }

  return filterParts.join('&');
};

export default getCompanySearchQuery;
