import PropTypes from 'prop-types';
import React, { Suspense, useState } from 'react';

import { EmojiSadIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import noop from 'lodash/noop';
import Helmet from 'react-helmet';
import { useParams, useNavigate, generatePath } from 'react-router-dom';

import EmptyState from 'components/EmptyState';
import SuccessFeesModal from 'components/successFees/SuccessFeesModal/SuccessFeesModal';
import { legacyIntents } from 'constants/intents';
import {
  ADMIN_MESSAGES_COMPANIES_PATH,
  ADMIN_MESSAGES_PATH,
} from 'constants/paths';
import getPageTitle from 'utils/getPageTitle';

import AdminThreadsFilters from '../../AdminThreadsFilters/AdminThreadsFilters';

import AdminMessagesThreadsActions from './AdminMessagesThreadsActions';
import AdminMessagesThreadsMessages from './AdminMessagesThreadsMessagesCount/AdminMessagesThreadsMessagesCount';
import AdminMessagesThreadsQueryModal from './AdminMessagesThreadsQueryModal';
import AdminMessagesThreadsThread from './AdminMessagesThreadsThread';
import useFilteredThreads from './hooks/useFilteredThreads';

const AdminMessagesThreads = ({ threads = [], reload = noop }) => {
  const { inboxId } = useParams();
  const navigate = useNavigate();
  const [isQueryModalOpen, setIsQueryModalOpen] = useState(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [openSuccessFeesModal, setOpenSuccessFeesModal] = useState(false);
  const filteredThreads = useFilteredThreads(threads);

  return (
    <div className="flex flex-col gap-2">
      <AdminThreadsFilters />
      <Helmet>
        <title>{getPageTitle('Admin - Messages: Threads')}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      {filteredThreads.length ? (
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 table-auto">
            <thead className="bg-gray-50 text-xs font-medium text-gray-500 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 text-left tracking-wider">
                  Approved / Unapproved
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left tracking-wider w-full"
                >
                  Thread
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredThreads.map(({ id, data, messages, ref }) => (
                <Suspense key={id}>
                  <tr
                    // TODO: remove this highlight when client team migrates all legacy intents
                    className={classNames('p-4 cursor-pointer ', {
                      'bg-yellow-50 hover:bg-yellow-100':
                        legacyIntents.includes(data.intent),
                      'hover:bg-gray-50': !legacyIntents.includes(data.intent),
                    })}
                    onClick={() =>
                      navigate(
                        generatePath(ADMIN_MESSAGES_PATH, {
                          type: ADMIN_MESSAGES_COMPANIES_PATH,
                          inboxId,
                          threadId: id,
                        })
                      )
                    }
                  >
                    <AdminMessagesThreadsMessages messages={messages} />
                    <AdminMessagesThreadsThread
                      opportunity={data.opportunity}
                      journalist={data.journalist}
                      isArchived={data.isArchived}
                      reload={reload}
                      handleShowQuery={(e) => {
                        e.stopPropagation();
                        setSelectedOpportunity(data.opportunity);
                        setIsQueryModalOpen(true);
                      }}
                      threadRef={ref}
                    />
                    <AdminMessagesThreadsActions
                      id={id}
                      intent={data.intent}
                      isArchived={data.isArchived}
                      threadRef={ref}
                      openSuccessFeesModal={({ successFeesData }) =>
                        setOpenSuccessFeesModal({
                          id,
                          companyId: ref?.parent?.parent?.id,
                          threadId: ref?.id,
                          successFeesData,
                        })
                      }
                    />
                  </tr>
                </Suspense>
              ))}
            </tbody>
          </table>
          <AdminMessagesThreadsQueryModal
            isOpen={isQueryModalOpen}
            title={selectedOpportunity?.enquirySummary}
            query={selectedOpportunity?.query}
            onClose={() => setIsQueryModalOpen(false)}
          />
          {openSuccessFeesModal ? (
            <SuccessFeesModal
              open
              onClose={() => setOpenSuccessFeesModal(false)}
              companyId={openSuccessFeesModal?.companyId}
              threadId={openSuccessFeesModal?.threadId}
              successFeesData={openSuccessFeesModal?.successFeesData}
            />
          ) : null}
        </div>
      ) : (
        <div className="py-8">
          <EmptyState
            Icon={EmojiSadIcon}
            helmetTitle="Threads not found"
            title="No threads found"
            subtitle="Sorry, there are no threads with the current filters or view"
          />
        </div>
      )}
    </div>
  );
};

AdminMessagesThreads.propTypes = {
  threads: PropTypes.array,
  reload: PropTypes.func,
};

export default AdminMessagesThreads;
