/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';
import { generatePath, Link } from 'react-router-dom';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';
import { INBOX_PATH } from 'constants/paths';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import SweetheartListJournalistContacted from './SweetheartListJournalistContacted';
import SweetheartListJournalistDelete from './SweetheartListJournalistDelete';
import SweetheartListJournalistProfile from './SweetheartListJournalistProfile';
import SweetheartListJournalistReachOut from './SweetheartListJournalistReachOut';
import SweetheartListJournalistTier from './SweetheartListJournalistTier';

const SweetheartListJournalist = ({ journalist = {}, onClick = noop }) => {
  const { claimsAdmin: isAdmin, claimsAgencyId: isAgency } = useUserData();
  const { companyThreads } = useSweetheartListContext();
  const currentJournalistThreads = useMemo(
    () =>
      companyThreads
        .filter((d) => d.journalistId === journalist.id)
        .sort((a, b) => {
          const dateA = new Date(a.dateCreated);
          const dateB = new Date(b.dateCreated);

          return dateB - dateA;
        }),
    [companyThreads, journalist.id]
  );

  const handleClick = (event) => {
    event.stopPropagation();
  };

  return (
    <tr
      className={classNames('odd:bg-white even:bg-gray-50 ', {
        'odd:hover:bg-gray-50 even:hover:bg-gray-100 cursor-pointer': isAdmin,
      })}
      data-test-id="sweetheartList-table-row"
      onClick={onClick}
    >
      <td className="px-4 py-2">
        <SweetheartListJournalistProfile
          name={journalist?.name}
          url={journalist?.image}
          hasStats={journalist?.hasStats}
          ImageComponent={JournalistImage}
          isAdmin={isAdmin}
          testId="sweetheartList-table-row-journalist"
        />
      </td>
      <td className="px-4 py-2">{journalist?.jobTitle || 'n/a'}</td>
      <td className="px-4 py-2">
        <SweetheartListJournalistProfile
          name={journalist?.publication?.name}
          url={journalist?.publication?.image}
          ImageComponent={PublicationImage}
          testId="sweetheartList-table-row-publication"
        />
      </td>
      <td className="px-4 py-2 text-center">
        <SweetheartListJournalistTier tier={journalist?.publication?.tier} />
      </td>
      <td className="px-4 py-2 text-center">
        <DomainAuthorityPill
          domainAuthority={journalist?.publication?.domainAuthority}
        />
      </td>

      <td className="px-4 py-2 text-left">
        {currentJournalistThreads.map((thread) => (
          <Link
            key={thread.id}
            onClick={handleClick}
            to={generatePath(INBOX_PATH, {
              tab: thread.status || 'draft',
              threadId: thread.id,
            })}
            className="cursor-pointer block"
          >
            <SweetheartListJournalistContacted
              journalist={journalist}
              status={thread.status}
              date={new Date(thread.dateCreated).toISOString().split('T')[0]}
            />
          </Link>
        ))}
      </td>

      <td className="px-4 py-2 text-center">
        <SweetheartListJournalistReachOut journalist={journalist} />
      </td>
      {(isAdmin || isAgency) && (
        <td className="px-4 py-2 text-center">
          <SweetheartListJournalistDelete journalistId={journalist.id} />
        </td>
      )}
    </tr>
  );
};

SweetheartListJournalist.propTypes = {
  journalist: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default SweetheartListJournalist;
