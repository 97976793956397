import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Tooltip } from 'react-tooltip';

import AdminCompaniesArchivedToggle from './AdminCompaniesArchivedToggle';
import AdminCompaniesCompany from './AdminCompaniesCompany/AdminCompaniesCompany';
import AdminCompaniesSearchField from './AdminCompaniesSearchField';
import AdminCompaniesSortBy from './AdminCompaniesSortBy';
import AdminCompaniesTiers from './AdminCompaniesTiers';
import AdminCompaniesTopicFilter from './AdminCompaniesTopicFilter';
import statsMeta from './constants/statsMeta';
import sortArrayBy from './helpers/sortArrayBy';
import useCompaniesStore from './hooks/useCompaniesStore';

const formatDate = (field) => field?.toDate()?.toISOString() || '1999-12-31';

const sortBySignupDate = {
  name: 'signupDate',
  format: formatDate,
  direction: 'asc',
};

const sortByLastSeenDate = {
  name: 'lastSeenDate',
  format: formatDate,
  direction: 'asc',
};

const AdminCompanies = ({ companies = [] }) => {
  const [sortBy, setSortBy] = useState(sortBySignupDate);
  const createSortByFn = (sortByPreset) => () =>
    setSortBy({
      ...sortByPreset,
      direction:
        sortBy.name === sortByPreset.name && sortBy.direction === 'desc'
          ? 'asc'
          : 'desc',
    });

  const sortedCompanies = sortArrayBy(companies, sortBy);

  useEffect(() => {
    useCompaniesStore.setState({ companies });
  }, [companies]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2 w-full items-end">
        <AdminCompaniesSearchField />
        <AdminCompaniesTiers />
        <AdminCompaniesTopicFilter />
        <AdminCompaniesArchivedToggle />
      </div>

      <div className="w-full">
        <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 text-gray-500">
            <thead className="bg-gray-50 text-xs font-medium uppercase">
              <tr className="text-left tracking-wider">
                <th scope="col" className="px-4 py-2">
                  Company
                </th>
                <td />
                {statsMeta.map(({ name, Icon, description }) => (
                  <th className="py-2" scope="row" key={name}>
                    <Icon
                      className="h-5 w-5 text-teal-500 mx-auto"
                      data-tooltip-content={description}
                      data-tooltip-id="tooltip-admin-companies"
                    />
                  </th>
                ))}
                <th scope="col" className="px-4 py-2 max-w-sm">
                  Topics
                </th>
                <th scope="col" className="px-4 py-2">
                  Subscription Status
                </th>
                <th scope="col" className="px-4 py-2">
                  Subscription Date
                </th>
                <th scope="col" className="px-4 py-2">
                  <AdminCompaniesSortBy
                    name="signupDate"
                    label="Signed up"
                    onClick={createSortByFn(sortBySignupDate)}
                    sortBy={sortBy}
                  />
                </th>
                <th scope="col" className="px-4 py-2">
                  <AdminCompaniesSortBy
                    name="lastSeenDate"
                    label="Last seen"
                    onClick={createSortByFn(sortByLastSeenDate)}
                    sortBy={sortBy}
                  />
                </th>
                <th scope="col" className="px-4 py-2">
                  VC Info
                </th>
                <th scope="col" className="px-4 py-2">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 text-sm">
              {sortedCompanies.map((company) => (
                <AdminCompaniesCompany key={company.uid} company={company} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Tooltip id="tooltip-admin-companies" className="max-w-xs text-sm" />
    </div>
  );
};

AdminCompanies.propTypes = {
  companies: PropTypes.array,
};

export default AdminCompanies;
