import { useEffect, useRef } from 'react';

import { sanitizeUrl } from '@braintree/sanitize-url';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';

import isUrl from 'utils/validate/isUrl';

const OPENGRAPH_API_ENDPOINT = 'https://opengraph.io/api/1.1/site';
const OPENGRAPH_APP_ID = process.env.REACT_APP_OPENGRAPH_app_id;
const DEBOUNCE_DELAY = 1500;

const createFetchUrl = (url = '') =>
  `${OPENGRAPH_API_ENDPOINT}/${encodeURIComponent(
    url
  )}?app_id=${OPENGRAPH_APP_ID}`;

const fetchOpenGraphData = async (url = '') => {
  const sanitizedUrl = sanitizeUrl(url);

  if (!isUrl(sanitizedUrl)) {
    return { error: true };
  }

  const res = await fetch(createFetchUrl(sanitizedUrl));

  return res.json();
};

const createFetchDebounceFn = (url = '') =>
  debounce(async ({ handleSuccess, handleError, setIsLoading }) => {
    const { hybridGraph, error } = await fetchOpenGraphData(url);
    setIsLoading(false);

    if (error) {
      handleError(error);
      return;
    }

    handleSuccess(hybridGraph);
  }, DEBOUNCE_DELAY);

const useOpenGraphApi = ({
  url = '',
  open = true,
  validate = () => Promise.resolve(true, null),
  setIsLoading = noop,
  handleError = noop,
  handleSuccess = noop,
}) => {
  const previousUrl = useRef(url);
  useEffect(() => {
    if (!open || !url || url === previousUrl.current) {
      previousUrl.current = url;
      return noop;
    }

    let debounceFn;
    (async () => {
      const canFetch = await validate();
      if (!canFetch) {
        return;
      }
      setIsLoading(true);
      debounceFn = createFetchDebounceFn(url);
      await debounceFn({ handleSuccess, handleError, setIsLoading });

      previousUrl.current = url;
    })();

    return () => {
      setIsLoading(false);
      debounceFn?.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
};

export default useOpenGraphApi;
