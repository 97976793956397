import callWhenConditionMet from 'utils/callFnWhenConditionMet';

import intercomBaseAttributes from '../constants/intercomBaseAttributes';

const bootIntercom = (attributes = {}) => {
  callWhenConditionMet(
    () => window?.Intercom,
    () => window.Intercom('boot', { ...intercomBaseAttributes, ...attributes })
  );
};

export default bootIntercom;
