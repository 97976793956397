import React, { Suspense } from 'react';

import { Helmet } from 'react-helmet';

import OnboardingWrapper from 'components/OnboardingWrapper/OnboardingWrapper';
import PageWrapper from 'components/PageWrapper';
import Spinner from 'components/Spinner';
import getPageTitle from 'utils/getPageTitle';

import OpportunitiesControls from './OpportunitiesControls/OpportunitiesControls';
import OpportunitiesList from './OpportunitiesList/OpportunitiesList';
import OpportunitiesListShowDeclined from './OpportunitiesList/OpportunitiesListShowDeclined';
import mergeOpportunities from './helpers/mergeOpportunities';
import useCompaniesOpportunities from './hooks/useCompaniesOpportunities';
import useInboxThreads from './hooks/useInboxThreads';
import useOpportunitiesBySearch from './hooks/useOpportunitiesBySearch';

const Opportunities = () => {
  const firebaseOpportunities = useCompaniesOpportunities();
  const inboxThreads = useInboxThreads();

  const { results, trackOpportunityClick, error, isLoading } =
    useOpportunitiesBySearch();

  const opportunities = mergeOpportunities({
    results,
    inboxThreads,
    ...(firebaseOpportunities || {}),
  });

  return (
    <PageWrapper className="px-6 pb-6 flex grow flex-col">
      <OnboardingWrapper>
        <Helmet>
          <title>{getPageTitle('Media opportunities')}</title>
        </Helmet>
        <div id="opportunities" className="flex flex-col">
          <OpportunitiesControls />
          <OpportunitiesListShowDeclined />
          <Suspense fallback={<Spinner />}>
            {isLoading || !firebaseOpportunities ? (
              <Spinner />
            ) : (
              <OpportunitiesList
                opportunities={opportunities}
                onOpportunityExpand={trackOpportunityClick}
                error={error}
              />
            )}
          </Suspense>
        </div>
      </OnboardingWrapper>
    </PageWrapper>
  );
};

export default Opportunities;
