import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import classNames from 'classnames';

import CompanyImage from 'components/company/CompanyImage';
import TagList from 'components/tags/TagList';
import { ContentTypes } from 'constants/contentTypes';
import { coreTopics } from 'constants/tags';
import formatTimestamp from 'utils/date/formatTimestamp';

import { ClampContext } from '../contexts/ClampContext';

import CompanyContentElementTableRow from './CompanyContentElementTableRow';

const CompanyContentElementTablePartnership = ({ content = {} }) => {
  const { headline, companies, embargoDate } = content;
  const { clampValues } = useContext(ClampContext);
  const index = (topic) => coreTopics.findIndex((t) => topic.name === t.name);
  return (
    <>
      <CompanyContentElementTableRow
        label="Headline"
        content={headline}
        contentType={ContentTypes.PARTNERSHIP}
      />

      {!!companies.length && (
        <tr className="odd:bg-gray-50 even:bg-white">
          <td
            className={classNames({
              'w-full align-top pt-2 pl-6 block': true,
              'lg:w-1/2 lg:table-cell lg:p-2 lg:pl-6': clampValues,
            })}
          >
            <p className="font-medium text-sm">Companies</p>
          </td>
          <td
            className={classNames({
              'w-full align-top pb-2 pl-6 pr-6 block': true,
              'lg:w-1/2 lg:table-cell lg:py-2 lg:pl-0': clampValues,
            })}
          >
            {companies.length && (
              <div
                className={classNames('w-full flex flex-col space-y-2', {
                  'mt-2': !clampValues,
                })}
              >
                {companies.map((company) => (
                  <div
                    key={company.name}
                    className={classNames('block space-y-1', {
                      'mt-1 lg:mt-0': clampValues,
                    })}
                  >
                    <div className="flex justify-start items-center space-x-2 flex-nowrap">
                      <CompanyImage image={company.logo} className="w-4 h-4" />
                      <span className="block text-gray-600 line-clamp-1">
                        {company.name}
                      </span>
                    </div>
                    {!clampValues && (
                      <TagList
                        tags={(company.sectors || []).map((sector) => ({
                          text: sector.name,
                          id: sector.name,
                          colorIndex: index(sector.name),
                        }))}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </td>
        </tr>
      )}

      <CompanyContentElementTableRow
        label="Embargo Date"
        contentType={ContentTypes.PARTNERSHIP}
        content={formatTimestamp(embargoDate)}
      />
    </>
  );
};

CompanyContentElementTablePartnership.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CompanyContentElementTablePartnership;
