import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import { messageStatus } from 'constants/messageStatus';

import getApproveButtonAction from './helpers/getApproveButtonAction';

const AdminMessagesMessageApproveButton = ({
  status = messageStatus.UNAPPROVED,
  isJournalist = false,
  update = {},
  canBeApproved = false,
}) => {
  const notifications = useNotifications();

  const { onClick, type, text, notification, disabled } = useMemo(() => {
    const action = getApproveButtonAction(isJournalist, status);
    return {
      ...action,
      onClick: () => update({ status: action.nextStatus }),
      disabled: action.disabled === null ? !canBeApproved : action.disabled,
    };
  }, [canBeApproved, isJournalist, status, update]);

  return (
    <Button
      disabled={disabled}
      onClick={async () => {
        try {
          await onClick();
          notifications.showSuccess({
            message: notification.text,
            title: notification.title,
          });
        } catch (error) {
          notifications.showError({ title: 'Error updating message status' });
        }
      }}
      className="ml-auto"
      type={type}
    >
      {text}
    </Button>
  );
};

AdminMessagesMessageApproveButton.propTypes = {
  status: PropTypes.oneOf([
    messageStatus.UNAPPROVED,
    messageStatus.APPROVED,
    messageStatus.DELIVERED,
  ]),
  isJournalist: PropTypes.bool,
  update: PropTypes.func,
  canBeApproved: PropTypes.bool,
};

export default AdminMessagesMessageApproveButton;
