import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import ReactTags from 'react-tag-autocomplete';
import { v4 as uuidv4 } from 'uuid';

import InputFieldTagsAutocompleteInstructions from './InputFieldTagsAutocompleteInstructions';
import reactTagsClassnames from './config/reactTagsClassnames';
import createInputFieldTagsAutocompleteTag from './createInputFieldTagsAutocompleteTag';

/**
 * Input field for tags.
 * Accepts a list of predefined suggestions that will show up in a dropdown,
 * but also allows inputting custom fields, for which we need to provide a `customFieldName`.
 */
const InputFieldTagsAutocomplete = ({
  customFieldName = '',
  onChange = noop,
  value = [],
  suggestions = [],
  tagsIndex = null,
  placeholder = '',
  testId = '',
  ...rest
}) => {
  const hasTag = (tag = {}) => value?.find((t = {}) => t.name === tag.name);
  const classNames = value.length
    ? reactTagsClassnames
    : {
        ...reactTagsClassnames,
        search: `${reactTagsClassnames.search} min-w-full `,
        searchInput: `${reactTagsClassnames.searchInput} min-w-full`,
        selected: `${reactTagsClassnames.selected} hidden`,
      };
  const onDelete = (i = 0) => {
    const arr = value.slice(0);
    arr.splice(i, 1);
    onChange(arr);
  };

  const onAdd = (tag = {}) => {
    if (hasTag(tag)) {
      return;
    }
    onChange(
      value.concat({
        ...tag,
        id: tag.id || `custom-${customFieldName}-${uuidv4()}`,
      })
    );
  };

  const getPlaceholderText = () => {
    if (value.length) {
      return '';
    }

    if (!placeholder) {
      return 'Add tags';
    }

    return placeholder;
  };

  return (
    <>
      <label
        htmlFor={customFieldName}
        className="focus-within:border-teal-500 relative px-2 block bg-white border border-gray-300 min-w-0 cursor-text rounded-md shadow-sm"
        data-test-id={testId}
      >
        <ReactTags
          autoresize={!!value.length}
          inputAttributes={{ id: customFieldName }}
          minQueryLength={1}
          classNames={classNames}
          tags={value}
          suggestions={suggestions.filter((suggestion) => !hasTag(suggestion))}
          onDelete={onDelete}
          onAddition={onAdd}
          tagComponent={createInputFieldTagsAutocompleteTag(
            suggestions,
            tagsIndex
          )}
          allowNew
          placeholderText={getPlaceholderText()}
          data-test-id="tags"
          {...rest}
        />
      </label>
      <div className="mt-2 w-full">
        <InputFieldTagsAutocompleteInstructions />
      </div>
    </>
  );
};

InputFieldTagsAutocomplete.propTypes = {
  customFieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  suggestions: PropTypes.array,
  tagsIndex: PropTypes.array,
  placeholder: PropTypes.string,
  testId: PropTypes.string,
  rest: PropTypes.object,
};

export default InputFieldTagsAutocomplete;
