import React, { useState } from 'react';

import { useMatch } from 'react-router-dom';

import Button from 'components/buttons/Button';
import { ADMIN_PATH } from 'constants/paths';
import useEnvironment from 'contexts/EnvironmentContext/hooks/useEnvironment';

import AdminResetDevEnvironmentModal from './AdminResetDevEnvironmentModal';

const AdminResetDevEnvironment = () => {
  const { isProduction } = useEnvironment();
  const isAdminPath = useMatch(ADMIN_PATH);
  const [isOpen, setIsOpen] = useState(false);

  if (isProduction || !isAdminPath) {
    return null;
  }

  return (
    <>
      <Button type="danger" onClick={() => setIsOpen(true)}>
        Reset Dev Environment
      </Button>
      <AdminResetDevEnvironmentModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default AdminResetDevEnvironment;
