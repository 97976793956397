import { useEffect, useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

const useRedirectToLastMessageInThread = (messages = []) => {
  const { messageId } = useParams();
  const lastMessageInThreadId = messages[messages.length - 1]?.uid;
  const lastMessageInThreadIdRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // If no messageId is provided, navigate to the last message in the thread
    if (
      !messageId &&
      lastMessageInThreadId &&
      lastMessageInThreadIdRef.current !== lastMessageInThreadId
    ) {
      navigate(`./${lastMessageInThreadId}`, {
        replace: true,
      });
    }
    return () => {
      lastMessageInThreadIdRef.current = lastMessageInThreadId;
    };
  }, [messageId, navigate, lastMessageInThreadId]);
};

export default useRedirectToLastMessageInThread;
