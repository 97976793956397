import PropTypes from 'prop-types';
import React from 'react';

import findIndex from 'lodash/findIndex';
import { generatePath, useNavigate } from 'react-router-dom';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import SectionHeadline from 'components/company/SectionHeadline';
import TagList from 'components/tags/TagList';
import { SETTINGS_COMPANY_PATH, SETTINGS_PATH } from 'constants/paths';
import { coreTopicsForUser } from 'constants/tags';

const CompanyMetaTopics = ({ topics, showOwnerView }) => {
  const navigate = useNavigate();

  return (
    <div className="pt-5 pb-5 mx-6 border-t border-b border-gray-100">
      <div className="flex justify-between items-center">
        <SectionHeadline>Topics</SectionHeadline>
        {showOwnerView && (
          <DotsMenu
            options={[
              {
                label: 'Edit company targeting',
                onClick: () =>
                  navigate({
                    pathname: `/${generatePath(SETTINGS_PATH, {
                      '*': SETTINGS_COMPANY_PATH,
                    })}`,
                    hash: 'targeting',
                  }),
                testId: 'company-meta-topics-menu-edit',
              },
            ]}
            theme="medium"
            testId="company-meta-topics-menu"
          />
        )}
      </div>
      <TagList
        className="mt-5"
        tags={(topics || []).map((topic) => ({
          text: topic.name,
          colorIndex: findIndex(coreTopicsForUser, (t) => t.id === topic.id),
          id: topic.id,
        }))}
      />
    </div>
  );
};

CompanyMetaTopics.propTypes = {
  topics: PropTypes.array,
  showOwnerView: PropTypes.bool,
};

export default CompanyMetaTopics;
