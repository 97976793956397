import PropTypes from 'prop-types';
import React from 'react';

import { useFormContext } from 'react-hook-form';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { REGEX_URL } from 'constants/regex';

const ContentElementProductServiceLink = ({ index = 0 }) => {
  const name = 'link';
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputFieldText
      id={`${name}${index}`}
      {...register(name, {
        pattern: {
          value: REGEX_URL,
          message: 'Please type a valid URL',
        },
      })}
      errors={errors}
      testId="company-content-product-service-inputLink"
      placeholder="Please enter the URL to the product/service..."
    >
      <InputFieldText.Label>Link</InputFieldText.Label>
    </InputFieldText>
  );
};

ContentElementProductServiceLink.propTypes = {
  index: PropTypes.number,
  contentType: PropTypes.string,
};

export default ContentElementProductServiceLink;
