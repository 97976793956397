import { DELETE_ACCOUNT_PATH as path } from 'constants/paths';

import DeleteAccountPage from './DeleteAccountPage';

const DeleteAccountRoute = {
  path,
  component: DeleteAccountPage,
};

export default DeleteAccountRoute;
