import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Tag from 'components/tags/Tag';
import getTimeLeftUntilInterval from 'utils/date/getTimeLeftUntilInterval';

const AdminMessagesTimerClock = ({
  startedAt = '',
  isRunning = false,
  interval = 15,
}) => {
  const [nextFunctionRunTimer, setNextFunctionRunTimer] = useState('00:00');
  const [isDanger, setIsDanger] = useState(false);

  useEffect(() => {
    const id = setInterval(() => {
      if (!isRunning) {
        const startedAtDate = new Date(startedAt);
        const { timeLeft, diff } = getTimeLeftUntilInterval(
          startedAtDate,
          interval
        );

        if (diff.getMinutes() < 1) {
          setIsDanger(true);
        } else if (isDanger) {
          setIsDanger(false);
        }

        setNextFunctionRunTimer(timeLeft);

        if (!timeLeft) {
          clearInterval(id);
        }
      }
    }, 1000);
    return () => clearInterval(id);
  }, [isDanger, isRunning, startedAt, interval]);

  return (
    <Tag
      bold
      uppercase
      className="self-start"
      bgColor={isDanger || !nextFunctionRunTimer ? 'red-100' : 'gray-200'}
      textColor={isDanger || !nextFunctionRunTimer ? 'red-700' : 'gray-700'}
    >
      {nextFunctionRunTimer
        ? `Next run in: ${nextFunctionRunTimer}`
        : 'Cloud function was skipped.'}
    </Tag>
  );
};

AdminMessagesTimerClock.propTypes = {
  startedAt: PropTypes.string,
  isRunning: PropTypes.bool,
  interval: PropTypes.number,
};

export default AdminMessagesTimerClock;
