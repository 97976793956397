import PropTypes from 'prop-types';
import React from 'react';

import sortBy from 'lodash/sortBy';
import Helmet from 'react-helmet';

import getPageTitle from 'utils/getPageTitle';

import AdminMessagesFilters from '../../AdminMessagesFilters/AdminMessagesFilters';

import AdminMessagesMessage from './AdminMessagesMessage/AdminMessagesMessage';
import useFilteredMessages from './hooks/useFilteredMessages';

const AdminMessagesMessages = ({
  companyName = '',
  journalist = {},
  messages = [],
  isArchived = false,
}) => {
  const filteredMessages = useFilteredMessages(messages);
  const sortedFilteredMessages = sortBy(filteredMessages, 'data.dateCreated');

  return (
    <div className="flex flex-col gap-2">
      <Helmet>
        <title>{getPageTitle('Admin - Messages: Messages')}</title>
      </Helmet>
      <AdminMessagesFilters />
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 table-auto">
          <thead className="bg-gray-50 text-xs font-medium text-gray-500 uppercase">
            <tr>
              <th scope="col" className="px-6 py-3 text-left tracking-wider">
                Messages
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedFilteredMessages.map((message) => (
              <AdminMessagesMessage
                key={message.id}
                message={message}
                journalist={journalist}
                companyName={companyName}
                isArchived={isArchived}
              />
            ))}
          </tbody>
        </table>
        {filteredMessages.length === 0 && messages.length > 0 && (
          <p className="p-4 text-center text-gray-500">
            No messages found with the current filters. ({messages.length}{' '}
            total)
          </p>
        )}
      </div>
    </div>
  );
};

AdminMessagesMessages.propTypes = {
  companyName: PropTypes.string,
  journalist: PropTypes.object,
  messages: PropTypes.array,
  isArchived: PropTypes.bool,
};

export default AdminMessagesMessages;
