import PropTypes from 'prop-types';
import React from 'react';

import { XIcon } from '@heroicons/react/outline';
import { MenuIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import noop from 'lodash/noop';

// Hamburger icon to toggle the mobile nav overlay
const MobileNavToggle = ({
  isMobileMenuOpen = false,
  toggleMobileMenu = noop,
}) => (
  <div className="relative flex items-center transition-all ease-in-out duration-700">
    <button
      type="button"
      className={classNames({
        'z-20': isMobileMenuOpen,
      })}
      aria-expanded={isMobileMenuOpen}
      onClick={() => toggleMobileMenu(!isMobileMenuOpen)}
    >
      <span className="sr-only">
        {isMobileMenuOpen ? 'Close' : 'Open'} main menu
      </span>
      {isMobileMenuOpen ? (
        <XIcon className="w-6 h-6" />
      ) : (
        <MenuIcon className="w-6 h-6" />
      )}
    </button>
  </div>
);

MobileNavToggle.propTypes = {
  isMobileMenuOpen: PropTypes.bool.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
};

export default MobileNavToggle;
