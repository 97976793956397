import PropTypes from 'prop-types';
import React from 'react';

const TitleSubtitle = ({ title, subtitle }) => (
  <div className="mb-8 text-center">
    <h2 className="text-xl text-teal-500">{title}</h2>
    <p className="pt-2 text-gray-500">{subtitle}</p>
  </div>
);

TitleSubtitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default TitleSubtitle;
