import {
  BellIcon,
  DownloadIcon,
  LoginIcon,
  ReceiptTaxIcon,
  TrashIcon,
  UploadIcon,
} from '@heroicons/react/outline';

const statsMeta = [
  { name: 'sessionStarts', Icon: LoginIcon, description: 'Client logins' },
  {
    name: 'notifications',
    Icon: BellIcon,
    description: 'Media Opportunities notifications sent',
  },
  {
    name: 'outgoing',
    Icon: UploadIcon,
    description: 'Outgoing messages from client',
  },
  {
    name: 'incoming',
    Icon: DownloadIcon,
    description: 'Incoming messages from journalists',
  },
  {
    name: 'declined',
    Icon: TrashIcon,
    description: 'Declined Media Opportunities',
  },
  {
    name: 'successFees',
    Icon: ReceiptTaxIcon,
    description: 'Success Fees charged',
  },
];

export default statsMeta;
