import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DotsVerticalIcon } from '@heroicons/react/outline';

import OpportunityQuery from 'components/opportunities/opportunity/OpportunityQuery';

const AdminExpandableQueryView = ({ opportunityUid, query = [] }) => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const handleToggleContent = () => {
    setIsContentVisible((prevState) => !prevState);

    if (isContentVisible) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="flex flex-col items-start overflow-wrap break-word">
      <OpportunityQuery
        opportunityId={opportunityUid}
        query={isContentVisible ? query : query.slice(0, 3)}
      />

      {query.length > 3 && (
        <div className="flex justify-center items-center mt-2 w-full">
          <button
            type="button"
            className="mx-auto my-auto rounded-md text-gray-400 hover:text-gray-500 transform rotate-90 bg-gray-100"
            onClick={handleToggleContent}
          >
            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      )}
    </div>
  );
};

AdminExpandableQueryView.propTypes = {
  opportunityUid: PropTypes.string,
  query: PropTypes.array,
};

export default AdminExpandableQueryView;
