import { useCallback, useState, useEffect } from 'react';

import { useAuth } from 'reactfire';

const BASE_URL = process.env.REACT_APP_JPD_URL;

const useJournalistsByIds = (ids = []) => {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [journalists, setJournalists] = useState([]);

  const body = JSON.stringify({ ids });

  const fetchData = useCallback(async () => {
    setLoading(true);
    const jwt = await auth.currentUser?.getIdToken();

    const response = await fetch(`${BASE_URL}/journalists/resolve`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body,
    });
    const data = await response.json();

    setJournalists(data.items);
    setLoading(false);
  }, [auth.currentUser, body]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { loading, journalists, reload: fetchData };
};

export default useJournalistsByIds;
