// sorts an array by a given sortBy (name, format, direction) object
const sortArrayBy = (array = [], sortBy = {}) =>
  [...array].sort((a, b) => {
    const { format, name, direction } = sortBy;

    const formatedA = format ? format(a[name]) : a[name];
    const formatedB = format ? format(b[name]) : b[name];

    if (formatedA < formatedB) {
      return direction === 'asc' ? -1 : 1;
    }

    if (formatedA > formatedB) {
      return direction === 'asc' ? 1 : -1;
    }

    return 0;
  });

export default sortArrayBy;
