import React from 'react';

import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';

import Button from 'components/buttons/Button';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

const SweetheartListHeaderIsEnabledButton = () => {
  const { isEnabled, updateCompanyMetadata } = useSweetheartListContext();

  return (
    <Button
      title={`Click to ${isEnabled ? 'disable' : 'enable'} the sweetheart list`}
      type={isEnabled ? 'secondary' : 'primary'}
      value={isEnabled}
      onClick={() =>
        updateCompanyMetadata({ isJournalistListEnabled: !isEnabled })
      }
    >
      {isEnabled ? (
        <>
          <EyeOffIcon className="w-4 h-4 mr-2" />
          <span>Disable</span>
        </>
      ) : (
        <>
          <EyeIcon className="w-4 h-4 mr-2 text-white" />
          <span className="text-white">Enable</span>
        </>
      )}
    </Button>
  );
};

export default SweetheartListHeaderIsEnabledButton;
