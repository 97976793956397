import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { sanitizeUrl } from '@braintree/sanitize-url';
import sortBy from 'lodash/sortBy';

import CompanySectionPlaceholder from 'components/company/CompanySectionPlaceholder';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';

import CompanyArticle from './CompanyArticle';
import CompanyArticlesEditModal from './CompanyArticleEditModal';

const NO_ARTICLES_DESCRIPTION =
  'Add your first article, recent news gives your company credibility';
const ARTICLES_DESCRIPTION =
  'Add an article, recent news gives your company credibility';

const CompanyArticles = ({ crud, showOwnerView, companyId, sectionRef }) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const closeModal = () => setIsAddModalOpen(false);

  const [, articles, ops] = crud;
  const articlesByDate = sortBy(articles, 'date').reverse();

  const isEmpty = articlesByDate.length < 1;

  const onSubmit = (data = {}) => {
    const { date, url, image, title, author, publication } = data;
    const sanitizedUrl = sanitizeUrl(url);
    ops.createDoc({
      date: createTimestampFromDateObj(date),
      url: sanitizedUrl,
      image,
      title,
      author,
      publication,
    });
    closeModal();
  };

  // If there are no videos yet, hide this section for journalists
  if (!showOwnerView && isEmpty) {
    return null;
  }

  return (
    <div data-test-id="company-recentNews" id="recent-news" ref={sectionRef}>
      <SectionHeaderWrapper>
        <SectionHeadline light testId="company-recent-news-headline">
          Recent news
        </SectionHeadline>
      </SectionHeaderWrapper>
      <div className="w-full mt-4">
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-6">
          {articlesByDate.map((article) => (
            <CompanyArticle
              key={article.uid}
              article={article}
              ops={ops}
              showOwnerView={showOwnerView}
              companyId={companyId}
            />
          ))}
          {showOwnerView && (
            <CompanySectionPlaceholder
              title="Add an article"
              description={
                isEmpty ? NO_ARTICLES_DESCRIPTION : ARTICLES_DESCRIPTION
              }
              onClick={() => setIsAddModalOpen(true)}
              testId="company-articles-add"
            />
          )}
        </ul>
      </div>

      {showOwnerView && isAddModalOpen ? (
        <CompanyArticlesEditModal
          open={isAddModalOpen}
          onClose={() => closeModal()}
          onSubmit={onSubmit}
          uploadFolder={`companies/${companyId}/articles`}
        />
      ) : null}
    </div>
  );
};

CompanyArticles.propTypes = {
  crud: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanyArticles;
