import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { PlusIcon } from '@heroicons/react/solid';
import cloneDeep from 'lodash/cloneDeep';
import noop from 'lodash/noop';
import { FormProvider, useForm } from 'react-hook-form';

import ContentTypeSelect from 'components/ContentTypeSelect';
import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import contentOnboardingDefaults from 'constants/contentOnboardingDefaults';

const CompanyContentAddModal = ({
  open = false,
  onSubmit = noop,
  onClose = noop,
}) => {
  const formName = 'companyContentAddModalForm';
  const form = useForm({
    mode: 'all',
    defaultValues: {
      type: '',
    },
  });
  const type = form.watch('type');
  const content = useMemo(
    () => cloneDeep(contentOnboardingDefaults[type]?.defaultValues),
    [type]
  );

  return (
    <Modal open={open} widthClass="max-w-2xl">
      <Modal.Close srMessage="Close content add modal" onClose={onClose} />
      <Modal.Icon Icon={PlusIcon} />
      <Modal.Title>
        Select what type of content you&apos;d like to share
      </Modal.Title>
      <Modal.Content>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(() => onSubmit(content))}
            id={formName}
          >
            <ContentTypeSelect />
          </form>
        </FormProvider>
      </Modal.Content>
      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-content-addModal-cancel"
        >
          Cancel
        </Button>
        <Button
          form={formName}
          submit
          data-test-id="company-content-addModal-submit"
        >
          Continue
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyContentAddModal.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default CompanyContentAddModal;
