import { useCallback, useEffect, useState } from 'react';

import { getDownloadURL } from 'firebase/storage';

const isStorageObject = (storageRef) => storageRef?.fullPath;

const useGetDownloadUrls = (storageRefs = []) => {
  const [urls, setUrls] = useState(null);

  if (!storageRefs.length) {
    throw new Error('storageRefs array cannot be empty');
  }

  const getUrls = useCallback(async () => {
    try {
      const downloadUrls = await Promise.all(
        storageRefs.map((storageRef) => {
          if (!isStorageObject(storageRef)) {
            throw new Error(
              'storageRefs must be an array of Firebase storage refs'
            );
          }
          return getDownloadURL(storageRef);
        })
      );
      setUrls(downloadUrls);
    } catch (error) {
      setUrls(null);
    }
  }, [storageRefs]);

  useEffect(() => {
    getUrls();
  }, [getUrls]);

  return urls;
};

export default useGetDownloadUrls;
