import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import noop from 'lodash/noop';

import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import exchanges from 'constants/exchanges';
import useFormArray from 'hooks/useFormArray';

const validate = (value = []) => {
  if (value.length === 0) {
    return 'Please add at least one stock exchange';
  }
  return null;
};

const ContentElementIPOStockExchanges = ({
  index = 0,
  updateCallback = noop,
}) => {
  const name = 'stockExchanges';
  const {
    formApi: { formState, trigger, getValues },
    update,
  } = useFormArray({
    name,
    validate,
    updateCallback,
  });
  const error = get(formState.errors, name, null);

  return (
    <div className="w-full">
      <div className="space-y-2">
        <Label htmlFor={`${name}${index}`}>Stock exchanges*</Label>
        <InputFieldTagsAutocomplete
          onChange={(value) => {
            update(value);
            trigger(name);
          }}
          customFieldName={`${name}${index}`}
          buttonClassName="w-full"
          placeholder="i.e. London Stock Exchange, New York Stock Exchange..."
          suggestions={exchanges}
          value={getValues(name) || []}
          testId="company-content-ipo-inputStockExchanges"
        />
      </div>
      {error && (
        <ErrorMessage testId="company-content-ipo-inputStockExchanges-error">
          {error.message}
        </ErrorMessage>
      )}
    </div>
  );
};

ContentElementIPOStockExchanges.propTypes = {
  index: PropTypes.number,
  updateCallback: PropTypes.func,
};
export default ContentElementIPOStockExchanges;
