import ModalBottomBar from './ModalBottomBar';
import ModalClose from './ModalClose';
import ModalContent from './ModalContent';
import ModalDescription from './ModalDescription';
import ModalIcon from './ModalIcon';
import ModalLeftButtons from './ModalLeftButtons';
import ModalOverlay from './ModalOverlay';
import ModalRightButtons from './ModalRightButtons';
import ModalTitle from './ModalTitle';
import ModalTopBar from './ModalTopBar';

const modalCompoundComponents = {
  Title: ModalTitle,
  Description: ModalDescription,
  Icon: ModalIcon,
  Close: ModalClose,
  Content: ModalContent,
  LeftButtons: ModalLeftButtons,
  RightButtons: ModalRightButtons,
  TopBar: ModalTopBar,
  BottomBar: ModalBottomBar,
  Overlay: ModalOverlay,
};

export default modalCompoundComponents;
