import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Controller } from 'react-hook-form';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import { getNumPastYearsSince } from 'hooks/helpers/dateUtils';
import useDateTimeSelector from 'hooks/useDateTimeSelector';

const CompanyFormFoundedDate = ({
  control = {},
  watch = noop,
  setValue = noop,
  companyData = {},
}) => {
  const monthName = 'foundedDate.month';
  const yearName = 'foundedDate.year';

  const optionRanges = useDateTimeSelector({
    config: {
      type: 'past',
      numPastYears: getNumPastYearsSince(2000),
    },
    selection: {
      selectedMonth: watch(monthName),
      selectedYear: watch(yearName),
    },
    updateSelections: ({ month, year }) => {
      setValue(monthName, month);
      setValue(yearName, year);
    },
  });

  return (
    <div className="flex w-full mt-2 space-x-2">
      <Controller
        name={monthName}
        control={control}
        defaultValue={companyData.foundedDate.month}
        render={({ field: { onChange, value } }) => (
          <InputFieldDropdown
            options={optionRanges.months}
            onChange={onChange}
            value={value}
            buttonClassName="w-36"
          />
        )}
      />
      <Controller
        name={yearName}
        control={control}
        defaultValue={companyData.foundedDate.year}
        render={({ field: { onChange, value } }) => (
          <InputFieldDropdown
            options={optionRanges.years}
            onChange={onChange}
            value={value}
            buttonClassName="w-28"
          />
        )}
      />
    </div>
  );
};

CompanyFormFoundedDate.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  companyData: PropTypes.object,
};

export default CompanyFormFoundedDate;
