import PropTypes from 'prop-types';
import React from 'react';

const TargetHint = ({ text }) => (
  <div className="col-span-12 -mt-4 md:mt-6 md:col-span-6 leading-snug">
    <small className="text-xs text-gray-500">{text}</small>
  </div>
);

TargetHint.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TargetHint;
