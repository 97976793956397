import { SIGNUP_MEMBER_PATH as path } from 'constants/paths';

import SignupMemberPage from './SignupMemberPage';

const SignupMemberRoute = {
  path,
  component: SignupMemberPage,
};

export default SignupMemberRoute;
