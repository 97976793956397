import React, { useEffect } from 'react';

import { Navigate } from 'react-router-dom';
import { useAuth } from 'reactfire';

import useQuery from 'hooks/useQuery';
import useRedirectAuth from 'hooks/useRedirectAuth';

const LoginImpersonatePage = () => {
  const query = useQuery();
  const token = query.get('token');
  const showAuth = useRedirectAuth();

  const auth = useAuth();
  useEffect(() => {
    (async () => {
      await auth.signInWithCustomToken(token);
    })();
  }, [auth, token]);

  // Don't render anything because the user should be redirected
  if (!showAuth) {
    return null;
  }

  return <Navigate to="/" />;
};

export default LoginImpersonatePage;
