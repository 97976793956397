import PropTypes from 'prop-types';
import React, { useState } from 'react';

import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Tooltip from 'components/Tooltip/Tooltip';
import CompanySectionPlaceholder from 'components/company/CompanySectionPlaceholder';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';
import getContentType from 'constants/contentTypes';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import filterContentForPublic from 'utils/data/filterContentForPublic';

import CompanyContentElement from './CompanyContentElement/CompanyContentElement';
import CompanyContentElementOpEd from './CompanyContentElementOpEd/CompanyContentElementOpEd';
import CompanyContentAddModal from './components/CompanyContentAddModal';
import CompanyContentDeleteModal from './components/CompanyContentDeleteModal';
import CompanyContentEditModal from './components/CompanyContentEditModal';
import CompanyContentViewModal from './components/CompanyContentViewModal';

const NO_CONTENT_DESCRIPTION =
  'Add your first piece of content like opinion articles and announcements about new hires, fundraises, and more';
const CONTENT_DESCRIPTION =
  'Add a piece of content like opinion articles and announcements about new hires, fundraises, and more';

const CompanyContent = ({
  crud = [],
  showOwnerView = false,
  sectionRef = {},
}) => {
  const { claimsAdmin: isAdmin, claimsJournalist: isJournalist } =
    useUserData();
  const showAllContent = isAdmin || isJournalist || showOwnerView;

  const [, contentRaw, ops] = crud;

  const contentFiltered = contentRaw.filter((content) =>
    getContentType(content)
  );

  // order content by embargo date
  const contentSorted = orderBy(contentFiltered, (a) => a.embargoDate, 'desc');

  // split up to isolate items without embargo date
  const contentSortedSplit = partition(contentSorted, (a) => !!a.embargoDate);

  // bring them back together so items without embargo date are at the end
  const contentSortedSplitFlattened = contentSortedSplit.flat();

  // For pieces of content that have an embargo date before the current date or no embargo date - show this info to the general public
  const contentFilteredForPublic = contentSortedSplitFlattened.filter(
    filterContentForPublic
  );

  const filteredContent = showAllContent
    ? contentSortedSplitFlattened
    : contentFilteredForPublic;

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [currentViewContentId, setCurrentViewContentId] = useState(null);
  const [modalType, setModalType] = useState('edit');
  const [content, setContent] = useState(null);

  const isEmpty = filteredContent.length < 1;

  if (isEmpty && !showOwnerView) {
    return null;
  }

  const onView = (uid) => {
    setCurrentViewContentId(uid);
    setIsViewModalOpen(true);
  };
  const onEdit = (uid) => {
    setContent(contentSorted.find((c) => c.uid === uid));
    setModalType('edit');
    setIsEditModalOpen(true);
  };
  const onAdd = (defaultContent) => {
    setContent(defaultContent);
    setIsAddModalOpen(false);
    setIsEditModalOpen(true);
  };
  const onDelete = (uid) => {
    setContent(contentSorted.find((c) => c.uid === uid));
    setIsDeleteModalOpen(true);
  };

  return (
    <>
      <div data-test-id="company-content" id="content" ref={sectionRef}>
        <SectionHeaderWrapper>
          <SectionHeadline light testId="company-content-headline">
            Content
          </SectionHeadline>
          {showOwnerView && (
            <Tooltip
              wrapperClassName="mr-auto"
              id="tooltip-contentEdit-embargo"
              copy="Content without an embargo date or with an embargo date before today's date will be viewable by the public and journalists. Any content with an embargo date set in the future will only be viewable by journalists."
            />
          )}
        </SectionHeaderWrapper>

        <div className="w-full mt-4">
          <ul
            className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 gap-6 items-stretch"
            data-test-id="company-content-list"
          >
            {filteredContent.map((c) => {
              const CompanyContentElementType =
                c.type === 'opEd'
                  ? CompanyContentElementOpEd
                  : CompanyContentElement;
              return (
                <CompanyContentElementType
                  key={c.uid}
                  content={c}
                  showOwnerView={showOwnerView}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onView={onView}
                />
              );
            })}
            {showOwnerView && (
              <CompanySectionPlaceholder
                title="Add a content element"
                description={
                  isEmpty ? NO_CONTENT_DESCRIPTION : CONTENT_DESCRIPTION
                }
                onClick={() => {
                  setModalType('add');
                  setIsAddModalOpen(true);
                }}
                testId="company-content-add"
              />
            )}
          </ul>
        </div>
      </div>

      {showOwnerView && (
        <>
          {content && isEditModalOpen ? (
            <CompanyContentEditModal
              open={isEditModalOpen}
              content={content}
              modalType={modalType}
              ops={ops}
              onClose={() => {
                setIsEditModalOpen(false);
              }}
            />
          ) : null}

          {isAddModalOpen ? (
            <CompanyContentAddModal
              open={isAddModalOpen}
              onSubmit={onAdd}
              onClose={() => {
                setIsAddModalOpen(false);
              }}
            />
          ) : null}

          {content ? (
            <CompanyContentDeleteModal
              ops={ops}
              open={isDeleteModalOpen}
              content={content}
              onClose={() => setIsDeleteModalOpen(false)}
            />
          ) : null}
        </>
      )}
      <CompanyContentViewModal
        open={isViewModalOpen}
        content={filteredContent}
        currentViewContentId={currentViewContentId}
        setCurrentViewContentId={setCurrentViewContentId}
        onClose={() => setIsViewModalOpen(false)}
      />

      <ReactTooltip
        id="tooltip-contentEdit-embargo"
        className="max-w-xs text-sm"
      />
    </>
  );
};

CompanyContent.propTypes = {
  crud: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanyContent;
