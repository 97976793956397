import { useCallback, useEffect, useState } from 'react';

import { useAuth } from 'reactfire';

const BASE_URL = process.env.REACT_APP_JPD_URL;

const useJournalistsBySearch = (query = '') => {
  const [journalists, setJournalists] = useState([]);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const auth = useAuth();

  const fetchData = useCallback(async () => {
    setLoading(true);
    const jwt = await auth.currentUser?.getIdToken();

    const response = await fetch(
      `${BASE_URL}/journalists?page=${page}&q=${query}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    const data = await response.json();

    setJournalists(data.items);
    setNumPages(data.meta.totalPages);
    if (data.meta.totalPages < page) {
      setPage(Math.max(data.meta.totalPages, 1));
    }
    setLoading(false);
  }, [query, page, auth.currentUser]);

  useEffect(() => {
    fetchData();
  }, [query, page, fetchData]);

  return { loading, journalists, page, numPages, reload: fetchData, setPage };
};

export default useJournalistsBySearch;
