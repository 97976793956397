import React from 'react';

import { Link } from 'react-router-dom';

import { LOGIN_PATH } from 'constants/paths';

import useRouteMatchAccountLink from './hooks/useRouteMatchAccountLink';

// Shows a link for logged-out users on the right side of the top nav bar
const AccountLink = () => {
  const showSigninLink = useRouteMatchAccountLink();

  // we're not showing the signup link anymore because all the signups should go via SignupModal
  if (!showSigninLink) {
    return null;
  }

  return (
    <Link
      to={LOGIN_PATH}
      className="border-transparent text-white hover:border-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
      data-test-id="accountLink-login"
    >
      Sign in
    </Link>
  );
};

export default AccountLink;
