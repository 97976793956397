import PropTypes from 'prop-types';
import React from 'react';

import { useFormContext } from 'react-hook-form';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const ContentElementHireCompanyName = ({ index = 0 }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const name = 'companyName';

  return (
    <div className="w-full">
      <InputFieldText
        id={`${name}${index}`}
        {...register(name, {
          required: {
            value: true,
            message: "Please provide the company they're joining",
          },
        })}
        testId="company-content-hire-inputCompanyName"
        errors={errors}
        placeholder="Type the name of company they're joining..."
      >
        <InputFieldText.Label>
          Company they&apos;re joining*
        </InputFieldText.Label>
      </InputFieldText>
    </div>
  );
};

ContentElementHireCompanyName.propTypes = {
  index: PropTypes.number,
};

export default ContentElementHireCompanyName;
