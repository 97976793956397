import { collection, doc, query, where } from 'firebase/firestore';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDoc,
} from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import {
  INBOXES_COLLECTION,
  INBOXES_THREADS_COLLECTION,
} from 'constants/firebasePaths';
import useJournalistsByIds from 'hooks/useJournalistsByIds';
import usePublicationsByIds from 'hooks/usePublicationsByIds';
import getJournalistId from 'utils/getJournalistId';
import getPublicationId from 'utils/getPublicationId';

const useEnrichedThreads = (companyUid = '', tab = '') => {
  const firestore = useFirestore();

  // grab the inbox data for specific company
  const inboxDoc = doc(firestore, INBOXES_COLLECTION, companyUid);
  const { data: inbox } = useFirestoreDoc(inboxDoc, defaultOptions);

  // grab the sub-collection `threads` and its data
  const threadsRef = collection(inbox.ref, INBOXES_THREADS_COLLECTION);
  const q = query(threadsRef, where('status', '==', tab));
  const { data: threads } = useFirestoreCollectionData(q, defaultOptions);

  const journalistIds = threads.map((t) =>
    getJournalistId(t?.opportunity?.journalist, t?.opportunity?.mediaOutlet)
  );
  const publicationIds = threads.map((t) =>
    getPublicationId(t?.opportunity?.mediaOutlet)
  );

  const { publications } = usePublicationsByIds(publicationIds);
  const { journalists } = useJournalistsByIds(journalistIds);

  const enrichedThreads = threads.map((t) => {
    const journalistId = getJournalistId(
      t?.opportunity?.journalist,
      t?.opportunity?.mediaOutlet
    );
    const publicationId = getPublicationId(t?.opportunity?.mediaOutlet);

    let journalist = journalists?.find((j) => j.id === journalistId);

    if (!journalist) {
      journalist = {
        name: t?.opportunity?.journalist,
        publication: publications?.find((p) => p.id === publicationId) || {
          name: t?.opportunity?.mediaOutlet,
          domainAuthority: t?.opportunity?.domainAuthority,
        },
      };
    }

    return {
      ...t,
      journalist,
    };
  });

  return {
    threadsRef,
    threads: enrichedThreads,
    inbox,
  };
};

export default useEnrichedThreads;
