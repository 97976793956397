import React from 'react';

import { useUser } from 'reactfire';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useUserByUID from 'hooks/useUserByUID';

import AccountForm from './AccountForm/AccountForm';
import ProfileForm from './ProfileForm/ProfileForm';
import UserSettingsDangerZone from './UserSettingsDangerZone/UserSettingsDangerZone';

const UserForm = () => {
  const { data: user } = useUser();
  const { isActualOwnerOfCompany } = useCompanyData();
  const { claimsAdmin } = useUserData();
  const { userDoc, status, userRef } = useUserByUID();

  const isUserSettingsDangerZoneVisible = claimsAdmin || isActualOwnerOfCompany;

  return (
    <div className="flex flex-col gap-6">
      <AccountForm user={user} />

      {status === 'success' && (
        <ProfileForm user={user} userRef={userRef} userData={userDoc} />
      )}

      {isUserSettingsDangerZoneVisible && <UserSettingsDangerZone />}
    </div>
  );
};

export default UserForm;
