import isString from 'lodash/isString';

import { REGEX_URL, REGEX_URL_LOCALHOST } from 'constants/regex';

// check if a string is a URL
const isUrl = (str = '', isEmulated = false) =>
  isString(str) &&
  (str.match(REGEX_URL) || (isEmulated && str.match(REGEX_URL_LOCALHOST)));

export default isUrl;
