import { useTracking } from 'modules/segment/TrackingProvider';

const useLogOpportunityInteraction = () => {
  const trackingService = useTracking();

  return (action = '', opportunity = {}) =>
    trackingService.track('opportunity_interaction', {
      action,
      opportunity_id: opportunity.uid,
      opportunity_title: opportunity.enquirySummary,
      opportunity_journalist: opportunity.journalist,
      opportunity_publication: opportunity.mediaOutlet,
    });
};

export default useLogOpportunityInteraction;
