import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import {
  createId,
  getFieldName,
  getSingular,
} from './helpers/customFieldHelpers';

const ENTER_KEY = 'Enter';

/**
 * Must be combined with InputFieldSelectMultiple
 * Allows users to add additional options (on top of those included in default field)
 * `defaultFieldName` has to be the `name` of corresponding `InputFieldSelectMultiple`
 * `watchedValue` has to be the watched default value
 * `options` has to be the default options array
 */
const InputFieldTextOther = React.forwardRef(
  (
    {
      setValue = noop,
      onFormChange = null,
      defaultFieldName = '',
      defaultValue = '',
      watchedValue = [],
      options = [],
      extraParams = {},
      placeholder = '',
      ...rest
    },
    ref
  ) => {
    const fieldName = getFieldName(defaultFieldName);
    const singular = getSingular(defaultFieldName);

    const onKeyDown = (event) => {
      if (event.key === ENTER_KEY) {
        event.preventDefault();

        // if watched valued contains option already, skip...
        if (watchedValue.find(({ name }) => name === event.target.value)) {
          setValue(fieldName, '');
          return;
        }

        // get option from existing options array, if available
        let newOption = options.find(({ name }) => name === event.target.value);

        // create new option object if it doesn't exist
        if (!newOption) {
          newOption = {
            id: createId(singular),
            name: event.target.value,
            ...extraParams,
          };
        }

        // add new option and reset field
        setValue(defaultFieldName, [...watchedValue, newOption]);
        setValue(fieldName, '');
        if (onFormChange) {
          onFormChange();
        }
      }
    };

    return (
      <div className="space-y-2">
        <input
          ref={ref}
          type="text"
          maxLength={255}
          name={fieldName}
          defaultValue={defaultValue}
          className="block mt-2 w-full px-3 py-2 rounded-md bg-white border-gray-300 focus:border-teal-500 focus:outline-none focus:ring-0 min-w-0 text-sm"
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          {...rest}
        />
        <p className="text-xs text-gray-400">
          Hit{' '}
          <b>
            <code>enter</code>
          </b>{' '}
          to add a tag to the list.
        </p>
      </div>
    );
  }
);

InputFieldTextOther.propTypes = {
  setValue: PropTypes.func.isRequired,
  onFormChange: PropTypes.func,
  defaultFieldName: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  watchedValue: PropTypes.array,
  options: PropTypes.array.isRequired,
  extraParams: PropTypes.object,
  placeholder: PropTypes.string.isRequired,
};

InputFieldTextOther.displayName = 'InputFieldTextOther';

export default InputFieldTextOther;
