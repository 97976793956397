import PropTypes from 'prop-types';
import React from 'react';

import { SearchIcon } from '@heroicons/react/solid';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const AgencySearch = ({ query = '', setQuery }) => (
  <div className="flex flex-col gap-2 w-full">
    <div className="flex justify-between">
      <InputFieldText
        type="text"
        autoComplete="company"
        containerClassName="w-full mr-2 bg-white"
        name="search"
        placeholder="Search by company name"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      >
        <InputFieldText.Prefix>
          <SearchIcon className="w-4 h-4" />
        </InputFieldText.Prefix>
      </InputFieldText>
    </div>
  </div>
);

AgencySearch.propTypes = {
  query: PropTypes.string,
  setQuery: PropTypes.func.isRequired,
};

export default AgencySearch;
