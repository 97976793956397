/* eslint-disable no-unused-vars */
import React from 'react';

import { InboxIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';

import EmptyState from 'components/EmptyState';
import PageWrapper from 'components/PageWrapper';

const InboxPageNoMessages = () => (
  <EmptyState
    helmetTitle="Inbox"
    testId="inbox-NoMessages"
    title="No messages!"
    subtitle="There are no messages for the current view."
    Icon={InboxIcon}
  />
);

export default InboxPageNoMessages;
