import PropTypes from 'prop-types';
import React from 'react';

import { useFormContext } from 'react-hook-form';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const ContentElementIPOCompanyName = ({ index = 0 }) => {
  const name = 'companyName';
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputFieldText
      id={`${name}${index}`}
      {...register(name)}
      errors={errors}
      testId="company-content-ipo-inputCompanyName"
      placeholder="Type the name of the company planning to IPO..."
    >
      <InputFieldText.Label>Company planning to IPO</InputFieldText.Label>
    </InputFieldText>
  );
};

ContentElementIPOCompanyName.propTypes = {
  index: PropTypes.number,
};
export default ContentElementIPOCompanyName;
