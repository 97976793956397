import { getDateRange } from './dateRange';

// turns a date object with year/month/day/hour into a JS date object
const createTimestampFromDateObj = (
  dateObj = {},
  skipTimestampConversion = false
) => {
  const date = new Date();

  date.setUTCFullYear(parseInt(dateObj.year, 10));
  date.setUTCMonth(getDateRange('months').indexOf(dateObj.month));

  if (dateObj.day) {
    date.setUTCDate(dateObj.day);
  }

  if (dateObj.hour) {
    const hourComponents = dateObj.hour.split(':');
    date.setUTCHours(parseInt(hourComponents[0], 10));
    date.setUTCMinutes(hourComponents[1] ? parseInt(hourComponents[1], 10) : 0);
    date.setUTCSeconds(0);
    date.setUTCMilliseconds(0);
  }

  // TODO: check if date is still the same, to make sure day exists in month

  if (skipTimestampConversion) {
    return date;
  }

  return date.toISOString();
};

export default createTimestampFromDateObj;
