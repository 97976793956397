const loadIntercom = () => {
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    const i = function initArguments() {
      // eslint-disable-next-line prefer-rest-params
      i.c(arguments);
    };
    i.q = [];
    i.c = function pushArgs(args) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function createScript() {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_ID}`;
      const x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
};

export default loadIntercom;
