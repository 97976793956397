import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import useOnboardingStore from '../stores/onboardingStores';

const useIsInvitedByAgency = () => {
  const { search } = useLocation();
  const updateCompany = useOnboardingStore((state) => state.updateCompany);
  const invitedByAgencyState = useOnboardingStore(
    (state) => state.company.invitedByAgency
  );
  const agency = new URLSearchParams(search).get('agency');

  useEffect(() => {
    const isInvitedByAgency = Boolean(agency);
    if (isInvitedByAgency) {
      updateCompany({ invitedByAgency: agency });
    }
  }, [agency, updateCompany]);

  return invitedByAgencyState;
};

export default useIsInvitedByAgency;
