import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DotsVerticalIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';

import OpportunityDetails from 'components/opportunities/OpportunityDetails';
import ThreadTag from 'components/tags/ThreadTag';

const InboxMessagesThreadDetail = ({ thread = {}, journalist = {} }) => {
  const { tab } = useParams();
  const type = thread.source;
  const enquirySummary =
    tab === 'draft' ? thread.draft.subject : thread.opportunity.subject;

  const data = {
    enquirySummary,
    ...thread?.opportunity,
    journalistData: journalist,
  };

  const [isContentVisible, setIsContentVisible] = useState(true);

  const toggleContentVisibility = () => setIsContentVisible(!isContentVisible);
  return (
    <div
      className="rounded-t-md border max-w-4xl p-4 bg-white border-b-0 m-0"
      data-test-id={`inboxMessage-${type}Context`}
      data-intercom-id="inbox-messages-context"
    >
      {isContentVisible && (
        <OpportunityDetails
          opportunity={data}
          threadSource={type}
          extraTags={
            <ThreadTag
              threadIntent={thread?.intent}
              testId={`inboxMessage-${type}Context-tag`}
              show={(intent) => !data?.type?.includes(intent.name)}
            />
          }
          testId={`inboxMessage-${type}Context-details`}
        />
      )}
      {data?.query?.length > 0 && (
        <div className="flex justify-center items-center">
          <button
            type="button"
            className="mx-auto my-auto rounded-md text-gray-400 hover:text-gray-500 transform rotate-90 bg-gray-100"
            data-test-id={`inboxMessage-${type}Context-toggle`}
            onClick={toggleContentVisibility}
          >
            <span className="sr-only">Close panel</span>
            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      )}
    </div>
  );
};

InboxMessagesThreadDetail.propTypes = {
  thread: PropTypes.object,
  journalist: PropTypes.object,
};

export default InboxMessagesThreadDetail;
