export const initialState = {
  showUnapproved: false,
  showApproved: false,
  showDelivered: false,
  showIncoming: false,
  showOutgoing: false,
  showJournalistWithEmail: false,
  showJournalistWithoutEmail: false,
  showArchivedThreads: false,
};

export const actions = {
  TOGGLE_SHOW_ARCHIVED_THREADS: 'TOGGLE_SHOW_ARCHIVED_THREADS',
  TOGGLE_UNAPPROVED: 'TOGGLE_UNAPPROVED',
  TOGGLE_APPROVED: 'TOGGLE_APPROVED',
  TOGGLE_DELIVERED: 'TOGGLE_DELIVERED',
  TOGGLE_INCOMING: 'TOGGLE_INCOMING',
  TOGGLE_OUTGOING: 'TOGGLE_OUTGOING',
  TOGGLE_JOURNALIST_WITH_EMAIL: 'TOGGLE_JOURNALIST_WITH_EMAIL',
  TOGGLE_JOURNALIST_WITHOUT_EMAIL: 'TOGGLE_JOURNALIST_WITHOUT_EMAIL',
  RESET_FILTERS: 'RESET_FILTERS',
  RESET_SOURCE_TARGET_FILTERS: 'RESET_SOURCE_TARGET_FILTERS',
  RESET_JOURNALIST_FILTERS: 'RESET_JOURNALIST_FILTERS',
  RESET_STATUS_FILTERS: 'RESET_STATUS_FILTERS',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.TOGGLE_SHOW_ARCHIVED_THREADS:
      return {
        ...state,
        showArchivedThreads: !state.showArchivedThreads,
      };
    case actions.RESET_FILTERS:
      return initialState;
    case actions.RESET_SOURCE_TARGET_FILTERS:
      return {
        ...state,
        showIncoming: false,
        showOutgoing: false,
      };
    case actions.RESET_JOURNALIST_FILTERS:
      return {
        ...state,
        showJournalistWithEmail: false,
        showJournalistWithoutEmail: false,
      };
    case actions.RESET_STATUS_FILTERS:
      return {
        ...state,
        showUnapproved: false,
        showApproved: false,
        showDelivered: false,
      };
    case actions.TOGGLE_UNAPPROVED:
      return {
        ...state,
        showApproved: false,
        showDelivered: false,
        showUnapproved: true,
      };
    case actions.TOGGLE_APPROVED:
      return {
        ...state,
        showUnapproved: false,
        showDelivered: false,
        showApproved: true,
      };
    case actions.TOGGLE_DELIVERED:
      return {
        ...state,
        showUnapproved: false,
        showApproved: false,
        showDelivered: true,
      };
    case actions.TOGGLE_INCOMING:
      return {
        ...state,
        showApproved: false,
        showOutgoing: false,
        showIncoming: true,
      };
    case actions.TOGGLE_OUTGOING:
      return {
        ...state,
        showIncoming: false,
        showOutgoing: true,
      };
    case actions.TOGGLE_JOURNALIST_WITH_EMAIL:
      return {
        ...state,
        showJournalistWithoutEmail: false,
        showJournalistWithEmail: true,
      };
    case actions.TOGGLE_JOURNALIST_WITHOUT_EMAIL:
      return {
        ...state,
        showJournalistWithEmail: false,
        showJournalistWithoutEmail: true,
      };
    default:
      return state;
  }
};
