import sortBy from 'lodash/sortBy';

const getLastJournalistResponse = (journalistResponse = null) => {
  if (!journalistResponse) {
    return null;
  }

  let lastJournalistResponse = null;

  const journalistResponseByDate = sortBy(journalistResponse, 'createdAt');
  lastJournalistResponse = journalistResponseByDate?.at(-1)?.value;

  if (lastJournalistResponse === 'unsubscribe') {
    return null;
  }

  return lastJournalistResponse;
};

export default getLastJournalistResponse;
