import { SETTINGS_PATH as path } from 'constants/paths';

import SettingsPage from './SettingsPage';

const SettingsRoute = {
  path,
  authRequired: true,
  component: SettingsPage,
};

export default SettingsRoute;
