import { getAllQueries } from '../hooks/useQueryParams';

import isUndefined from './isUndefined';

const removeDefaultQueries = (search = '') => {
  const newSearchParams = new URLSearchParams(search);
  const { id, sort, sortDir, filter } = getAllQueries(newSearchParams);

  if (!id || isUndefined(id)) {
    newSearchParams.delete('id', id);
  }
  if (sort === 'null' || isUndefined(sort)) {
    newSearchParams.delete('sort', sort);
  }
  if (sortDir === '1' || isUndefined(sortDir)) {
    newSearchParams.delete('sortDir', sortDir);
  }
  if (!filter || isUndefined(filter)) {
    newSearchParams.delete('filter', filter);
  }

  return newSearchParams;
};

export default removeDefaultQueries;
