import PropTypes from 'prop-types';
import React from 'react';

import { EyeIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';

const UpdatePhotoModal = ({
  open = false,
  uploadFolder = '',
  onClose = noop,
  onSubmit = noop,
}) => {
  const form = useForm({ mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isValid },
  } = form;

  return (
    <Modal open={open}>
      <Modal.Icon Icon={EyeIcon} />
      <Modal.Title>Update your profile photo</Modal.Title>
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)} id="updatePhotoForm">
          <InputFieldUpload
            name="photo"
            form={form}
            folder={uploadFolder}
            alt="Profile photo preview"
          />
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button form="updatePhotoForm" submit disabled={!isValid}>
          Update photo
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

UpdatePhotoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadFolder: PropTypes.string.isRequired,
};

export default UpdatePhotoModal;
