import PropTypes from 'prop-types';
import React from 'react';

import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Stepper from 'components/Stepper';
import CopyToClipboardButton from 'components/buttons/CopyToClipboardButton';
import Founder from 'components/company/Founder';
import useWithAnalytics from 'hooks/useWithAnalytics';
import getCurrentObjectAndIndex from 'utils/getCurrentObjectAndIndex';

import CompanyInsightAnswer from './CompanyInsight/CompanyInsightAnswer';
import CompanyInsightQuestion from './CompanyInsight/CompanyInsightQuestion';
import CompanyInsightTag from './CompanyInsight/CompanyInsightTag';

const CompanyInsightViewModal = ({
  open = false,
  insights = [],
  founders = [],
  currentInsightId = null,
  setCurrentInsightId = noop,
  onClose = noop,
}) => {
  const [currentInsight, currentInsightIndex] = getCurrentObjectAndIndex(
    insights,
    currentInsightId
  );
  const onPrevious = useWithAnalytics({
    eventType: 'pressPage_view',
    eventProperties: {
      action: 'insight_view',
      insight_title: insights[currentInsightIndex - 1]?.question,
      insight_body: insights[currentInsightIndex - 1]?.answer,
    },
  })(() => setCurrentInsightId(insights[currentInsightIndex - 1]?.uid));

  const onNext = useWithAnalytics({
    eventType: 'pressPage_view',
    eventProperties: {
      action: 'insight_view',
      insight_title: insights[currentInsightIndex + 1]?.question,
      insight_body: insights[currentInsightIndex + 1]?.answer,
    },
  })(() => setCurrentInsightId(insights[currentInsightIndex + 1]?.uid));

  if (!currentInsight) {
    return null;
  }

  return (
    <Modal open={open} widthClass="w-auto">
      <Modal.Close
        testId="company-insight-viewModal-close"
        srMessage="Close Insight view modal"
        onClose={onClose}
      />
      <Modal.Icon Icon={QuestionMarkCircleIcon} />
      <Modal.Content>
        <CompanyInsightTag tag={currentInsight.tag} />
        <CompanyInsightQuestion
          testId={`company-insight-viewModal-${currentInsight.question}`}
          question={currentInsight.question}
          hasAnswer
        />

        <div className="mt-2">
          <CompanyInsightAnswer answer={currentInsight.answer} clamp={false} />
        </div>

        <div className="w-full flex flex-row justify-between items-center mt-4">
          <Founder
            founder={founders.find(
              (f) => f.name === currentInsight.founder?.name
            )}
            founders={founders}
            widthClassName="w-3/4 flex-shrink"
            testId={`company-insight-${currentInsight.title}-founder`}
          />
          <CopyToClipboardButton
            copyText={currentInsight.answer}
            moduleName="insight"
            eventType="pressPage_view"
            eventProperties={{
              action: 'insight_view_copyToClipboard',
              insightCopied_title: currentInsight.question,
              insightCopied_body: currentInsight.answer,
            }}
          />
        </div>
      </Modal.Content>

      <Modal.TopBar>
        <Stepper
          currentStep={currentInsightIndex}
          totalSteps={insights.length}
          stepLabel="Insight"
          stepsLabel="Insights"
          testId="company-insight-viewModal"
          onPrevious={onPrevious}
          onNext={onNext}
        />
      </Modal.TopBar>
    </Modal>
  );
};

CompanyInsightViewModal.propTypes = {
  open: PropTypes.bool,
  insights: PropTypes.array,
  founders: PropTypes.array,
  currentInsightId: PropTypes.string,
  setCurrentInsightId: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompanyInsightViewModal;
