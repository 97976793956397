import PropTypes from 'prop-types';
import React from 'react';

import { BanIcon } from '@heroicons/react/outline';
import noop from 'lodash/noop';

import Callout from 'components/Callout/Callout';
import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

const AdminMessagesThreadsActionsMarkAsUnsuccessfulModal = ({
  onClose = noop,
  onMarkAsUnsuccessful = noop,
  open = false,
}) => (
  <Modal open={open}>
    <Modal.Close
      srMessage="Close Mark as Unsuccessful modal"
      onClose={onClose}
    />
    <Modal.Icon Icon={BanIcon} warning />
    <Modal.Title>Mark Success Fee as Unsuccessful</Modal.Title>
    <Modal.Description>
      Are you sure you want to <b>mark this success fee as unsuccessful</b>?
    </Modal.Description>

    <Modal.Content>
      <Callout>
        You can reenable this success fee by clicking on the &quot;View Success
        Fees&quot; button, and saving the invoice draft again.
      </Callout>
    </Modal.Content>

    <Modal.RightButtons>
      <Button type="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button
        onClick={() => {
          onMarkAsUnsuccessful();
          onClose();
        }}
      >
        Mark as unsuccessful
      </Button>
    </Modal.RightButtons>
  </Modal>
);

AdminMessagesThreadsActionsMarkAsUnsuccessfulModal.propTypes = {
  onClose: PropTypes.func,
  onMarkAsUnsuccessful: PropTypes.func,
  open: PropTypes.bool,
};

export default AdminMessagesThreadsActionsMarkAsUnsuccessfulModal;
