import PropTypes from 'prop-types';
import React from 'react';

import { ContentTypes } from 'constants/contentTypes';
import { ReactComponent as LinkedInIcon } from 'static/icon-linkedin.svg';

import CompanyContentElementTableRow from './CompanyContentElementTableRow';

const LinkedinProfile = ({ name = '' }) => (
  <span className="text-blue-500 flex items-center space-x-1 text-sm hover:text-blue-700">
    <LinkedInIcon className="w-4 h-4 mr-1" />
    {name}
  </span>
);

LinkedinProfile.propTypes = {
  name: PropTypes.string.isRequired,
};

const CompanyContentElementTableHire = ({ content = {} }) => {
  const { companyName, position, linkedInUrl, name } = content;
  return (
    <>
      <CompanyContentElementTableRow
        label="Name"
        contentType={ContentTypes.HIRE}
        content={{
          text: <LinkedinProfile name={name} />,
          url: `https://www.linkedin.com/in/${linkedInUrl}`,
        }}
        type="link"
      />
      <CompanyContentElementTableRow
        label="Company Name"
        contentType={ContentTypes.HIRE}
        content={companyName}
      />
      <CompanyContentElementTableRow
        label="Position"
        content={position}
        contentType={ContentTypes.HIRE}
      />
    </>
  );
};

CompanyContentElementTableHire.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CompanyContentElementTableHire;
