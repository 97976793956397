import React, { useState } from 'react';

import { CalendarIcon } from '@heroicons/react/solid';
import { collection, query, where } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import Spinner from 'components/Spinner';
import InputFieldToggle from 'components/form/inputFields/InputFieldToggle';
import OpportunitiesFilter from 'components/opportunities/OpportunitiesFilter/OpportunitiesFilter';
import OpportunitiesSort from 'components/opportunities/OpportunitiesSort/OpportunitiesSort';
import filterOpportunities from 'components/opportunities/helpers/filterOpportunities';
import sortOpportunities from 'components/opportunities/helpers/sortOpportunities';
import defaultOptions from 'constants/defaultOptions';
import {
  CLASSIFIED_OPPORTUNITIES_COLLECTION,
  CUSTOM_OPPORTUNITIES_COLLECTION,
} from 'constants/firebasePaths';
import { filterOptions, sortOptions } from 'constants/opportunitiesOptions';
import addJournalistDataToOpportunity from 'helpers/addJournalistDataToOpportunity';
import useJournalistsByIds from 'hooks/useJournalistsByIds';
import usePublicationsByIds from 'hooks/usePublicationsByIds';
import getJournalistId from 'utils/getJournalistId';
import getPageTitle from 'utils/getPageTitle';
import getPublicationId from 'utils/getPublicationId';

import AdminWrapper from '../AdminWrapper';

import AdminOpportunity from './AdminOpportunity/AdminOpportunity';
import AdminOpportunityCustom from './AdminOpportunity/AdminOpportunityCustom';
import useMergeCompaniesIntoOpportunities from './hooks/useMergeCompaniesIntoOpportunities';

const date = new Date();

const sortOptionsNone = sortOptions.slice(2, 3);
const sortOptionsMostRecent = {
  name: 'mostRecent',
  label: 'Most recent',
  Icon: CalendarIcon,
};
const sortOptionsAdmin = sortOptions
  .slice(0, 2)
  .concat(sortOptionsMostRecent, sortOptionsNone);

const AdminOpportunitiesPage = () => {
  const firestore = useFirestore();

  const [showDeleted, setShowDeleted] = useState(false);

  // filter, sort
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState(sortOptionsMostRecent);
  const [sortDir, setSortDir] = useState(-1);

  // get classified opportunities
  const opportunitiesCol = collection(
    firestore,
    CLASSIFIED_OPPORTUNITIES_COLLECTION
  );
  const opportunitiesQ = query(
    opportunitiesCol,
    where('deadlineUTC', '>', date)
  );

  const { data: opportunitiesData } = useFirestoreCollectionData(
    opportunitiesQ,
    defaultOptions
  );

  // get custom opportunities
  const customOppoortunitiesCol = collection(
    firestore,
    CUSTOM_OPPORTUNITIES_COLLECTION
  );
  const customOpportunitiesQ = query(
    customOppoortunitiesCol,
    where('deadlineUTC', '>', date)
  );

  const { data: customOpportunitiesData } = useFirestoreCollectionData(
    customOpportunitiesQ,
    defaultOptions
  );

  // get custom evergreen opportunities

  const customEvergreenOpportunitiesCol = collection(
    firestore,
    CUSTOM_OPPORTUNITIES_COLLECTION
  );
  const customEvergreenOpportunitiesQ = query(
    customEvergreenOpportunitiesCol,
    where('deadlineUTC', '==', null)
  );

  const { data: customEvergreenOpportunitiesData } = useFirestoreCollectionData(
    customEvergreenOpportunitiesQ,
    defaultOptions
  );

  const firestoreOpportunities = [
    ...opportunitiesData,
    ...customOpportunitiesData,
    ...customEvergreenOpportunitiesData,
  ];

  const journalistIds = firestoreOpportunities.map((opp) =>
    getJournalistId(opp.journalist, opp.mediaOutlet)
  );
  const publicationIds = firestoreOpportunities.map((opp) =>
    getPublicationId(opp.mediaOutlet)
  );

  const { journalists } = useJournalistsByIds(journalistIds);
  const { publications } = usePublicationsByIds(publicationIds);

  const opportunitiesWithJournalistData = firestoreOpportunities.map((opp) =>
    addJournalistDataToOpportunity({ opp, journalists, publications })
  );

  // merged opportunities
  const opportunitiesResult = useMergeCompaniesIntoOpportunities(
    opportunitiesWithJournalistData
  );

  const { error, loading } = opportunitiesResult;
  let { opportunities } = opportunitiesResult;

  if (!showDeleted) {
    opportunities = opportunities.filter((o) => !o.deleted);
  }

  // filter & sort opportunities
  if (filter.length) {
    opportunities = filterOpportunities(opportunities, filter);
  }

  if (sort !== null) {
    opportunities = sortOpportunities(opportunities, { sort, sortDir });
  }

  const opportunitiesCounts = filterOptions.map(
    (f) =>
      opportunities.filter(
        (o) => o?.domainAuthority >= f.min && o?.domainAuthority <= f.max
      ).length
  );

  return (
    <AdminWrapper title="Opportunities" currentIndex={2}>
      <Helmet>
        <title>{getPageTitle('Admin - Opportunities')}</title>
      </Helmet>
      {loading && <Spinner />}
      {error && <p>Something went wrong! Please try again later</p>}
      {!error && !loading && (
        <>
          <div className="flex justify-between items-center">
            <div className="flex space-x-2">
              <OpportunitiesFilter
                filterOptions={filterOptions}
                setFilter={setFilter}
                filter={filter}
              />
              <OpportunitiesSort
                sortOptions={sortOptionsAdmin}
                setSort={setSort}
                sort={sort}
                sortDir={sortDir}
                setSortDir={setSortDir}
              />
              <div className="px-4 mb-1 hidden lg:flex">
                <InputFieldToggle
                  text="Show Deleted"
                  value={showDeleted}
                  onChange={() => setShowDeleted((prevVal) => !prevVal)}
                />
              </div>
            </div>

            <div className="flex space-x-2">
              {opportunitiesCounts.map((count, index) => (
                <p
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className="flex items-center space-x-2 p-2 bg-gray-100 rounded-md text-sm"
                >
                  <span className="text-gray-400">Tier {index + 1}</span>
                  <span className="px-2 py-0.5 bg-teal-500 text-white rounded-lg">
                    {count}
                  </span>
                </p>
              ))}
            </div>
          </div>

          <div className="flex flex-col mt-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 text-xs font-medium text-gray-500 uppercase">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left tracking-wider"
                        >
                          Enquiry summary / Query
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left tracking-wider"
                        >
                          Companies
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {opportunities.map((opportunity) => {
                        const OpportunityComponent = opportunity.custom
                          ? AdminOpportunityCustom
                          : AdminOpportunity;
                        return (
                          <OpportunityComponent
                            key={opportunity.uid}
                            opportunity={opportunity}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </AdminWrapper>
  );
};

export default AdminOpportunitiesPage;
