import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import IconCheckmark from 'components/icons/IconCheckmark';

const selectedFont = 'font-semibold';
const unSelectedFont = 'font-normal';

/**
 * Child element for InputFieldSelect dropdown list with multiple tags
 * Can be prepended with an additional element, such as an image
 */
const InputFieldTagsDropdownTag = ({
  tag = {},
  isSelected = false,
  isActive = false,
  prependTagFn = null,
}) => (
  <div
    className={classNames(
      'cursor-default select-none relative py-2 px-4 sm:pl-8 sm:pr-4',
      {
        'flex items-center': prependTagFn,
        'text-white bg-teal-500': isActive,
        'text-gray-900': !isActive,
      }
    )}
  >
    {prependTagFn && prependTagFn(tag)}
    <span
      className={`${
        isSelected ? selectedFont : unSelectedFont
      } block truncate text-left`}
    >
      {tag.name}
    </span>
    {isSelected && <IconCheckmark active={isActive} />}
  </div>
);

InputFieldTagsDropdownTag.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool,
  isActive: PropTypes.bool,
  prependTagFn: PropTypes.func,
};

export default InputFieldTagsDropdownTag;
