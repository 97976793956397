import PropTypes from 'prop-types';
import React from 'react';

import journalistResponseOptions from '../../constants/journalistResponseOptions';

const InboxThreadsThreadJournalistResponse = ({ journalistResponse = '' }) => {
  const { dotColor } = journalistResponseOptions[journalistResponse] || {};
  return (
    <span
      className={`flex rounded-full ${dotColor} p-1 ml-1`}
      data-test-id={`inboxThread-qualifyingIcon_${journalistResponse}`}
    />
  );
};

InboxThreadsThreadJournalistResponse.propTypes = {
  journalistResponse: PropTypes.oneOf(['positive', 'neutral', 'negative']),
};

export default InboxThreadsThreadJournalistResponse;
