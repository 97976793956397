import React from 'react';

import EmptyState from 'components/EmptyState';
import PageWrapper from 'components/PageWrapper';

const NotFoundPage = () => (
  <PageWrapper>
    <EmptyState
      helmetTitle="Page not found"
      testId="page-notFound"
      title="Oops, sorry!"
      subtitle="Looks like this page doesn't exist."
    />
  </PageWrapper>
);

export default NotFoundPage;
