import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CompanySectionPlaceholder from 'components/company/CompanySectionPlaceholder';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';

import CompanyQuote from './CompanyQuote/CompanyQuote';
import CompanyQuoteEditModal from './CompanyQuoteEditModal';
import CompanyQuoteViewModal from './CompanyQuoteViewModal';

const NO_QUOTES_DESCRIPTION =
  'Add your first quote, help journalists understand how your founders and/or team think';
const QUOTES_DESCRIPTION =
  'Add a quote, help journalists understand how your founders and/or team think';

const CompanyQuotes = ({
  crud,
  founders,
  showOwnerView = false,
  sectionRef,
}) => {
  const [, quotesRaw, ops] = crud;
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [currentViewQuoteId, setCurrentViewQuoteId] = useState(null);

  // migrate from press page v1 -> v2
  const quotes = quotesRaw
    .map((quote) => ({
      ...quote,
      founder: quote?.users?.[0]
        ? { name: quote?.users?.[0].name }
        : quote.founder,
    }))
    .filter((quote) => showOwnerView || !quote.private);

  const isEmpty = quotes.length < 1;

  // If there are no insights yet, hide this section for journalists
  if (!showOwnerView && isEmpty) {
    return null;
  }

  const onSubmit = (comment) => {
    const date = createTimestampFromDateObj(comment.date);
    ops.createDoc({
      title: comment.title,
      body: comment.body,
      tags: comment.tags,
      founder: comment.founder,
      private: comment.private,
      date,
    });
    setIsAddModalOpen(false);
  };

  const onView = (uid) => {
    setCurrentViewQuoteId(uid);
    setIsViewModalOpen(true);
  };

  return (
    <>
      <div data-test-id="company-quote-bank" id="quote-bank" ref={sectionRef}>
        <SectionHeaderWrapper>
          <SectionHeadline light testId="company-quote-bank-headline">
            Quote bank
          </SectionHeadline>
        </SectionHeaderWrapper>
        <div className="w-full mt-4">
          <ul
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4 gap-6 items-stretch"
            data-test-id="company-quotes-list"
          >
            {quotes.map((quote, index) => (
              <CompanyQuote
                key={quote.uid}
                quote={quote}
                showOwnerView={showOwnerView}
                ops={ops}
                founders={founders}
                onView={onView}
                index={index}
              />
            ))}
            {showOwnerView && (
              <CompanySectionPlaceholder
                title="Add a quote"
                description={
                  isEmpty ? NO_QUOTES_DESCRIPTION : QUOTES_DESCRIPTION
                }
                onClick={() => setIsAddModalOpen(true)}
                testId="company-quotes-add"
              />
            )}
          </ul>
        </div>
      </div>

      {showOwnerView && isAddModalOpen ? (
        <CompanyQuoteEditModal
          open={isAddModalOpen}
          founders={founders}
          onClose={() => setIsAddModalOpen(false)}
          onSubmit={onSubmit}
        />
      ) : null}

      <CompanyQuoteViewModal
        open={isViewModalOpen}
        quotes={quotes}
        founders={founders}
        currentQuoteId={currentViewQuoteId}
        setCurrentQuoteId={setCurrentViewQuoteId}
        onClose={() => setIsViewModalOpen(false)}
      />
    </>
  );
};

CompanyQuotes.propTypes = {
  crud: PropTypes.array.isRequired,
  founders: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanyQuotes;
