import { LOGIN_IMPERSONATE_PATH as path } from 'constants/paths';

import LoginImpersonatePage from './LoginImpersonatePage';

const LoginRoute = {
  path,
  component: LoginImpersonatePage,
};

export default LoginRoute;
