import React from 'react';

// Renders an error if the search API fails
const JournalistSearchError = () => (
  <div className="min-h-screen pt-24 bg-gray-50 flex flex-col items-center py-12 sm:px-6 lg:px-8">
    <div className="max-w-prose">
      <p className="font-semibold">Oops.</p>
      <p className="mt-4 text-md">
        There seems to be an unexpected problem. Please try again later.
      </p>
    </div>
  </div>
);

export default JournalistSearchError;
