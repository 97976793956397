import React from 'react';

import { UserAddIcon } from '@heroicons/react/solid';

import EmptyState from 'components/EmptyState';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

import SweetheartListHeaderAdminAddJournalist from './SweetheartListHeaderAdminAddJournalist';
import SweetheartListHeaderAdminNotifyButton from './SweetheartListHeaderAdminNotifyButton';
import SweetheartListHeaderIsEnabledButton from './SweetheartListHeaderIsEnabledButton';

const SweetheartListHeaderAdmin = () => {
  const { journalists = [], loading } = useSweetheartListContext();

  if (journalists.length === 0 && !loading) {
    return (
      <div className="w-full">
        <EmptyState
          helmetTitle="Sweetheart List - No journalists"
          Icon={UserAddIcon}
          instructions={<SweetheartListHeaderAdminAddJournalist />}
          title="No journalists in this client's Sweetheart List"
          subtitle={`Click "Find Journalist" to add journalists to this client's Sweetheart List.`}
        />
      </div>
    );
  }

  return (
    <div className="flex gap-2">
      <SweetheartListHeaderIsEnabledButton />
      <SweetheartListHeaderAdminNotifyButton />
      <SweetheartListHeaderAdminAddJournalist />
    </div>
  );
};

export default SweetheartListHeaderAdmin;
