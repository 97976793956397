import PropTypes from 'prop-types';
import React from 'react';

import { PlusIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { REGEX_URL } from 'constants/regex';
import useFormArray from 'hooks/useFormArray';

const ContentElementOpEdPastWritingExamples = ({
  index = 0,
  updateCallback = noop,
}) => {
  const name = 'pastExamples';
  const {
    formApi: {
      formState: { errors },
      register,
    },
    add,
    remove,
    fields,
  } = useFormArray({
    name,
    updateCallback,
  });

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <Label htmlFor={`${name}0${index}`}>Past writing example*</Label>
        <span className="text-sm text-gray-500">
          {' '}
          Add at least two and a maximum of five...
        </span>
      </div>
      {fields?.map((_, fieldIndex) => (
        <InputFieldText
          // eslint-disable-next-line react/no-array-index-key
          key={fieldIndex}
          id={`${name}${fieldIndex}${index}`}
          defaultValue={fields[fieldIndex]}
          containerClassName="gap-0"
          maxLength={2000}
          errors={errors}
          placeholder="Past writing example..."
          testId={`company-content-opEd-inputPastExamples-${fieldIndex}`}
          {...register(`${name}.${fieldIndex}`, {
            pattern: {
              value: REGEX_URL,
              message: 'Please provide a correct url',
            },
            validate: {
              value: (value) =>
                fieldIndex > 1
                  ? true
                  : value.length ||
                    'Please provide at least two past writing examples',
            },
          })}
        >
          {fieldIndex > 1 ? (
            <InputFieldText.Suffix>
              <CloseButton
                srMessage="Remove past writing example"
                className="h-auto w-11 bg-white border border-gray-300 flex justify-center items-center shadow-sm !rounded border-l-0 !rounded-l-none text-red-500/25 hover:text-red-500 focus:border-teal-500"
                onClick={() => remove(fieldIndex)}
              />
            </InputFieldText.Suffix>
          ) : null}
        </InputFieldText>
      ))}
      <Button
        type="secondary"
        className="ml-auto"
        disabled={fields?.length === 5}
        onClick={() => fields?.length < 5 && add('')}
      >
        <PlusIcon className="w-4 h-4 mr-1" />
        Add example
      </Button>
    </div>
  );
};

ContentElementOpEdPastWritingExamples.propTypes = {
  index: PropTypes.number,
  updateCallback: PropTypes.func,
};

export default ContentElementOpEdPastWritingExamples;
