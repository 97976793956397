/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';

import TrashIcon from '@heroicons/react/solid/TrashIcon';
import noop from 'lodash/noop';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import getContentType from 'constants/contentTypes';

import CompanyContentElementFiles from '../CompanyContentElement/CompanyContentElementFiles';
import CompanyContentFooter from '../components/CompanyContentFooter';
import CompanyContentTitle from '../components/CompanyContentTitle';
import CompanyContentWrapper from '../components/CompanyContentWrapper';

import CompanyContentElementOpEdArticleIdea from './CompanyContentElementOpEdArticleIdea';
import CompanyContentElementOpEdPastExamples from './CompanyContentElementOpEdPastExamples';

const CompanyContentElementOpEd = ({
  content = {},
  showOwnerView = false,
  onEdit = noop,
  onDelete = noop,
  onView = noop,
}) => {
  const { type, title, pastExamples, linkedInUrl } = content;
  const { label } = getContentType(content);

  return (
    <CompanyContentWrapper
      onView={onView}
      showOwnerView={showOwnerView}
      content={content}
    >
      {showOwnerView && (
        <div className="absolute top-4 right-4 z-10">
          <DotsMenu
            theme="medium"
            options={[
              {
                label: `Edit ${label.toLowerCase()}`,
                onClick: () => onEdit(content.uid),
                testId: `company-content-${type}-${title}-menu-edit`,
              },
              {
                label: `Delete ${label.toLowerCase()}`,
                icon: <TrashIcon className="w-4 h-4" />,
                onClick: () => onDelete(content.uid),
                testId: `company-quote-${type}-${title}-menu-delete`,
              },
            ]}
            testId={`company-quote-${type}-${title}-menu`}
          />
        </div>
      )}
      <div className="w-full h-full flex flex-col justify-between">
        <div className="flex flex-col items-start relative pt-1">
          <CompanyContentTitle content={content} />
          <CompanyContentElementOpEdArticleIdea
            content={content}
            clampContent
          />
        </div>
        <CompanyContentElementOpEdPastExamples
          pastExamples={pastExamples}
          linkedInUrl={linkedInUrl}
        />
        <CompanyContentFooter content={content}>
          <CompanyContentElementFiles content={content} />
        </CompanyContentFooter>
      </div>
    </CompanyContentWrapper>
  );
};

CompanyContentElementOpEd.propTypes = {
  content: PropTypes.object.isRequired,
  showOwnerView: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CompanyContentElementOpEd;
