import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import AttachmentList from 'components/AttachmentList';
import HtmlContainer from 'components/HtmlContainer';
import {
  DURATION_MEDIUM,
  DELAY_SHORT,
  DURATION_SHORT,
} from 'constants/transitions';

const motionProps = {
  initial: {
    height: 0,
    opacity: 0,
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      height: {
        duration: DURATION_MEDIUM,
      },
      opacity: {
        duration: DURATION_SHORT,
        delay: DELAY_SHORT,
      },
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: DURATION_MEDIUM,
      },
      opacity: {
        duration: DURATION_SHORT,
      },
    },
  },
};

const removeStyleTags = (htmlString) =>
  htmlString.replace(/<style([\s\S]*?)<\/style>/gi, '');

const InboxMessageContent = ({
  text = '',
  html = '',
  attachments = [],
  isExpanded = false,
}) => (
  <AnimatePresence initial={false} mode="wait">
    {isExpanded ? (
      <motion.div {...motionProps}>
        <div
          className={classNames('self-start overflow-hidden', {
            'ml-20 w-[calc(100%-5rem)] p-4 pl-0': isExpanded,
          })}
        >
          <HtmlContainer
            testId="inboxMessage-fullResponse"
            htmlString={removeStyleTags(html) || text}
          />

          <AttachmentList attachments={attachments} />
        </div>
      </motion.div>
    ) : null}
  </AnimatePresence>
);

InboxMessageContent.propTypes = {
  text: PropTypes.string,
  html: PropTypes.string,
  attachments: PropTypes.array,
  isExpanded: PropTypes.bool,
};

export default InboxMessageContent;
