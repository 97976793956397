import React, { useEffect, useRef } from 'react';

import { doc } from 'firebase/firestore';
import isEqual from 'lodash/isEqual';
import { useForm, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useFirestore } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import SeparatorTextLine from 'components/form/SeparatorTextLine';
import InputFieldTagsDropdown from 'components/form/inputFields/InputFieldTagsDropdown/InputFieldTagsDropdown';
import InputFieldTextOther from 'components/form/inputFields/InputFieldTextOther/InputFieldTextOther';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';
import { tagsAudiences, coreTopicsForUser } from 'constants/tags';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import { useTracking } from 'modules/segment/TrackingProvider';
import filterCustomOptions from 'utils/data/filterCustomOptions';

import TargetHint from './TargetHint';

const CompanyTargetForm = () => {
  const trackingService = useTracking();
  const firestore = useFirestore();
  const { showSuccess, showError } = useNotifications();
  const { companyData } = useCompanyData();
  const { updateDocument } = useFirestoreWrapper();
  const companyId = companyData?.uid;
  const companyRef = doc(firestore, COMPANIES_COLLECTION, companyId);

  // scroll #targeting into view if hash exists
  const pageRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    if (location.hash === '#targeting') {
      pageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.hash]);

  const {
    control,
    handleSubmit,

    formState: { isSubmitting, isValid, errors },

    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    nativeValidation: false,
    defaultValues: companyData,
  });

  // custom topics and audiences
  const topicsWatch = watch('topics');
  const audiencesWatch = watch('audiences');
  const customTopics = filterCustomOptions(topicsWatch);
  const customAudiences = filterCustomOptions(audiencesWatch);

  const onSubmit = async (newCompanyData = {}) => {
    try {
      // Log changed events
      if (!isEqual(companyData.audiences, newCompanyData.audiences)) {
        trackingService.track('companyData_update', {
          action: 'audiences_change',
          company_audiences_old: (companyData.audiences || [])
            .map((a) => a.name)
            .join(','),
          company_audiences_new: (newCompanyData.audiences || [])
            .map((a) => a.name)
            .join(','),
        });
      }
      if (!isEqual(companyData.topics, newCompanyData.topics)) {
        trackingService.track('companyData_update', {
          action: 'topics_change',
          company_topics_old: (companyData.topics || [])
            .map((a) => a.name)
            .join(','),
          company_topics_new: (newCompanyData.topics || [])
            .map((a) => a.name)
            .join(','),
        });
      }
      // Update properties

      await updateDocument(companyRef, newCompanyData);
      showSuccess({ message: 'Company settings updated successfully' });
    } catch (err) {
      console.error('Error updating profile', err); // eslint-disable-line no-console
      showError({ title: 'Error updating profile', message: err.message });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="targeting" ref={pageRef}>
      <div className="grid grid-cols-12 gap-6 mt-6">
        {/* Topics */}
        <fieldset className="col-span-12 md:col-span-6">
          <Label htmlFor="topics">
            What topics are you keen to talk about with the media?
          </Label>
          <div className="mt-2">
            <Controller
              name="topics"
              control={control}
              defaultValue={companyData.topics}
              render={({ field: { onChange, value } }) => (
                <InputFieldTagsDropdown
                  tags={coreTopicsForUser.concat(customTopics)}
                  tagsIndex={coreTopicsForUser}
                  value={value}
                  buttonClassName="w-full"
                  placeholder="Select as many as you would like"
                  onChange={onChange}
                />
              )}
            />
          </div>

          {errors.topics && (
            <ErrorMessage>{errors.topics.message}</ErrorMessage>
          )}

          <SeparatorTextLine />

          <InputFieldTextOther
            setValue={setValue}
            defaultFieldName="topics"
            watchedValue={topicsWatch}
            options={coreTopicsForUser}
            placeholder="Add custom topics here..."
          />
        </fieldset>

        <TargetHint text="We’ll use the topics you choose here to inform which opportunities we share with you from journalists so that they are most relevant for what you want to communicate about. Enter as many as you want specific to your company." />

        <div className="col-span-12 md:col-span-6">
          <Label htmlFor="audiences">
            Who are the people you want to reach with your views?
          </Label>
          <div className="mt-2">
            <Controller
              name="audiences"
              control={control}
              defaultValue={companyData.audiences}
              render={({ field: { onChange, value } }) => (
                <InputFieldTagsDropdown
                  tags={tagsAudiences.concat(customAudiences)}
                  tagsIndex={tagsAudiences}
                  value={value}
                  buttonClassName="w-full"
                  placeholder="Select as many as you would like"
                  onChange={onChange}
                />
              )}
            />
          </div>

          {errors.audiences && (
            <ErrorMessage>{errors.audiences.message}</ErrorMessage>
          )}

          <SeparatorTextLine />

          <InputFieldTextOther
            setValue={setValue}
            defaultFieldName="audiences"
            watchedValue={audiencesWatch}
            options={tagsAudiences}
            placeholder="Add custom audiences here..."
          />
        </div>

        <TargetHint text="Audiences help us choose which publications and then journalists are most relevant for your company. This helps us target opportunities that can have the biggest impact to your business. The more precise you are with this, the higher your success rate will be when you pitch journalists." />
      </div>

      <div className="mt-4 flex flex-row justify-end">
        <Button type="secondary" submit disabled={isSubmitting || !isValid}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default CompanyTargetForm;
