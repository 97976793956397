import PropTypes from 'prop-types';
import React from 'react';

import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import noop from 'lodash/noop';
import sortBy from 'lodash/sortBy';

import IconDropdownArrow from 'components/icons/IconDropdownArrow';

import InputFieldTagsDropdownButton from './InputFieldTagsDropdownButton';
import InputFieldTagsDropdownTag from './InputFieldTagsDropdownTag';

// Dropdown select field to choose multiple tags from a list, contains a button and tag component
const InputFieldTagsDropdown = ({
  tags = [],
  tagsIndex = null,
  onChange = noop,
  value = [],
  buttonClassName = 'w-24',
  wrapperClassName = '',
  disabled = false,
  prependButtonFn = null,
  prependTagFn = null,
  placeholder = 'No tags selected',
  testId = '',
}) => {
  // tags that come from Firestore have a UID instead of an ID
  // tags that come from src/constants have an ID instead of a UID
  // we need to unify this so the subsequent code doesn't need to look for both
  const tagsWithId = sortBy(tags, 'name').map((o) =>
    typeof o !== 'object'
      ? o
      : {
          ...o,
          id: o?.id || o?.uid || null,
        }
  );

  const isSelected = (tag) => !!value.find((el) => el.id === tag.id);

  const removeTag = (tag) =>
    onChange(value.filter((selected) => selected.id !== tag.id));

  const handleSelection = (tag) => {
    // remove tag
    if (value.filter((selected) => selected.id === tag.id).length) {
      removeTag(tag);
    }
    // add tag
    else {
      onChange([...value, tag]);
    }
  };

  return (
    <Listbox
      as="div"
      value={value}
      onChange={handleSelection}
      className={wrapperClassName}
    >
      {({ open }) => {
        let buttonChildren = null;

        if (value.length) {
          // render children based on array "value"
          buttonChildren = sortBy(value, 'name').map((tag) => (
            <InputFieldTagsDropdownButton
              key={tag.id}
              tag={tag}
              tagsIndex={tagsIndex || tags}
              prependButtonFn={prependButtonFn}
              testId={testId}
            />
          ));
        }

        // render placeholder
        if (!value.length) {
          buttonChildren = (
            <span className="inline-block text-gray-500">{placeholder}</span>
          );
        }
        return (
          <div className="relative flex flex-col">
            <Listbox.Button
              disabled={disabled}
              className={classNames(
                'cursor-default relative rounded-md border border-gray-300 bg-white text-left focus:outline-none transition ease-in-out duration-150 text-sm sm:leading-5',
                'p-1 px-2',
                buttonClassName,
                { 'opacity-50': disabled }
              )}
              data-test-id={testId}
            >
              <div className="flex flex-wrap items-center min-h-[1.75rem]">
                {buttonChildren}
              </div>
              <IconDropdownArrow />
            </Listbox.Button>
            {!disabled && (
              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute z-40 top-full mt-1 w-full rounded-md bg-white shadow-lg"
              >
                <Listbox.Options
                  static
                  className={classNames(
                    'max-h-60 rounded-md py-1 text-sm leading-6 shadow-xs overflow-auto focus:outline-none sm:leading-5',
                    buttonClassName
                  )}
                >
                  {tagsWithId.map((tag) => {
                    const key = tag.id ? tag.id : tag;
                    return (
                      <Listbox.Option
                        key={key}
                        value={tag}
                        data-test-id={`${testId}-${key}`}
                      >
                        {({ active }) => (
                          <InputFieldTagsDropdownTag
                            tag={tag}
                            isSelected={isSelected(tag)}
                            isActive={active}
                            prependTagFn={prependTagFn}
                          />
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            )}
          </div>
        );
      }}
    </Listbox>
  );
};

InputFieldTagsDropdown.propTypes = {
  tags: PropTypes.array.isRequired,
  tagsIndex: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ).isRequired,
  buttonClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  disabled: PropTypes.bool,
  prependButtonFn: PropTypes.func,
  prependTagFn: PropTypes.func,
  placeholder: PropTypes.string,
  testId: PropTypes.string,
};

export default InputFieldTagsDropdown;
