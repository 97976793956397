import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import CompanyImage from 'components/company/CompanyImage';
import UserAvatar from 'components/layout/Navbar/UserAvatar';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

const InboxMessageAvatar = ({ showCompanyImage = false, className = '' }) => {
  const company = useCompanyData();

  return (
    <div
      className={classNames(
        'shrink-0 w-12 h-12 flex items-center justify-center relative',
        className
      )}
    >
      {showCompanyImage && (
        <CompanyImage
          image={company.companyData.logo}
          className="w-full h-full !rounded-full"
        />
      )}

      {!showCompanyImage && <UserAvatar className="w-full h-full" />}
    </div>
  );
};

InboxMessageAvatar.propTypes = {
  showCompanyImage: PropTypes.bool,
  className: PropTypes.string,
};

export default InboxMessageAvatar;
