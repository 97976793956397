import { collection, limit, orderBy, query, where } from 'firebase/firestore';
import { uniqBy } from 'lodash';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import {
  COMPANIES_COLLECTION,
  COMPANIES_STATS_COLLECTION,
} from 'constants/firebasePaths';

const useCompanyStats = (companyId = '') => {
  const firestore = useFirestore();
  const col = collection(
    firestore,
    COMPANIES_COLLECTION,
    companyId,
    COMPANIES_STATS_COLLECTION
  );

  const lastThreeMonthsQuery = query(
    col,
    where('isLastDayOfMonth', '==', true),
    limit(3),
    orderBy('dateCreated', 'desc')
  );
  const mostRecentCurrentMonthQuery = query(
    col,
    limit(1),
    orderBy('dateCreated', 'desc')
  );

  const { data: lastThreeMonthsStats } = useFirestoreCollectionData(
    lastThreeMonthsQuery,
    defaultOptions
  );

  const { data: mostRecentStatsCurrentMonth } = useFirestoreCollectionData(
    mostRecentCurrentMonthQuery,
    defaultOptions
  );

  const uniqueStatsFromLastThreeMonths = uniqBy(
    [...mostRecentStatsCurrentMonth, ...lastThreeMonthsStats],
    'uid'
  );

  if (uniqueStatsFromLastThreeMonths.length > 3) {
    uniqueStatsFromLastThreeMonths.pop();
  }

  return uniqueStatsFromLastThreeMonths.map(({ dateCreated, ...rest }) => ({
    ...rest,
    dateCreated: dateCreated?.toDate(),
  }));
};

export default useCompanyStats;
