import React from 'react';

const OpportunitiesListErrorState = () => (
  <div className="flex w-full min-h-screen justify-center items-center p-8 -mt-32 text-center text-gray-500">
    <p className="max-w-prose">
      There seems to be an error with your search.{' '}
      <br className="hidden lg:block" />
      Please try again. ⏳{' '}
    </p>
  </div>
);

export default OpportunitiesListErrorState;
