import PropTypes from 'prop-types';
import React from 'react';

import {
  ArcElement,
  Chart,
  Tooltip,
  Legend,
  Title,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';
import useAPIRequest from 'hooks/useAPIRequest';

import SweetheartListJournalistReachOut from '../SweetheartListJournalist/SweetheartListJournalistReachOut';

import SweetheartListJournalistDetailsModalIndustries from './SweetheartListJournalistDetailsModalIndustries';
import SweetheartListJournalistDetailsModalKeywords from './SweetheartListJournalistDetailsModalKeywords';
import SweetheartListJournalistDetailsModalLastStories from './SweetheartListJournalistDetailsModalLastStories';
import SweetheartListJournalistDetailsModalPublishingDays from './SweetheartListJournalistDetailsModalPublishingDays';
import SweetheartListJournalistDetailsModalStatTitle from './SweetheartListJournalistDetailsModalStatTitle';
import SweetheartListJournalistDetailsModalTopics from './SweetheartListJournalistDetailsModalTopics';

Chart.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  Tooltip,
  Legend,
  Title
);

const SweetheartListJournalistDetailsModal = ({
  journalist = {},
  onClose = noop,
}) => {
  const { data: journalistStats, loading } = useAPIRequest({
    endpoint: `/journalists/${journalist.id}/stats`,
    service: 'JPD',
  });
  const hasJournalistStats = Boolean(Object.keys(journalistStats).length);

  return (
    <Modal
      open={Boolean(journalist)}
      widthClass="!max-w-4xl max-h-[calc(100vh-4rem)] flex flex-col"
      buttonsContainerClass="sticky bottom-0"
      contentContainerClass="overflow-y-auto"
    >
      <Modal.Close srMessage="Close edit Modal" onClose={onClose} />
      <Modal.TopBar>
        <div className="bg-gray-100 rounded-t-md flex flex-col items-center p-8 sticky top-0">
          <div className="flex flex-col gap-4 items-center">
            <JournalistImage url={journalist?.image} className="w-20 h-20" />

            <div className="text-center">
              <p className="font-bold">{journalist.name}</p>
              <div className="text-gray-700 flex gap-2 items-center">
                <div className="text-sm items-start flex gap-1">
                  <p className="">{journalist.jobTitle || 'Journalist'}</p>
                  <div className="flex items-center gap-1">
                    <PublicationImage
                      url={journalist?.publication?.image}
                      className="w-4 h-4"
                    />
                    <div className="flex gap-2 items-center text-teal-500">
                      {journalist?.publication?.name}
                      {/* <div className="text-xs px-2 py-0.5 rounded-md text-teal-500 border border-teal-500">
                        Tier {journalist?.publication?.tier}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-2 w-full items-center justify-center" />
        </div>
      </Modal.TopBar>

      <Modal.Content className="h-full">
        <div className="text-gray-700 mt-2 w-full">
          {hasJournalistStats && (
            <div className="grid grid-cols-2 w-full gap-6">
              <div className="col-span-2 md:col-span-1">
                <SweetheartListJournalistDetailsModalStatTitle
                  title="Topics"
                  subtitle={`Number of times ${journalist.name} has written about specific topics in the last 3 months`}
                  wrapperClassName="md:-mb-4"
                />
                <div className="h-72">
                  <SweetheartListJournalistDetailsModalTopics
                    topics={journalistStats.topicCounts}
                  />
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <SweetheartListJournalistDetailsModalStatTitle
                  title="Industries"
                  subtitle={`Number of times ${journalist.name} has written about specific industries in the last 3 months`}
                  wrapperClassName="md:-mb-4"
                />
                <div className="h-72">
                  <SweetheartListJournalistDetailsModalIndustries
                    industries={journalistStats.industryCounts}
                  />
                </div>
              </div>
              <div className="col-span-2 md:col-span-2 space-y-6 md:-mt-8">
                <div>
                  <SweetheartListJournalistDetailsModalStatTitle
                    title="Keywords"
                    subtitle={`Number of times ${journalist.name} has used specific keywords in the last 3 months`}
                  />
                  <div className="h-56">
                    <SweetheartListJournalistDetailsModalKeywords
                      keywords={journalistStats.keywordCounts}
                    />
                  </div>
                </div>
                <div>
                  <SweetheartListJournalistDetailsModalStatTitle
                    title="Publishing days"
                    subtitle={`Number of times ${journalist.name} has published on a specific day in the last 3 months`}
                  />
                  <div className="h-56">
                    <SweetheartListJournalistDetailsModalPublishingDays
                      publishingDays={journalistStats.publishingWeekdayCounts}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-2 md:col-span-2">
                <SweetheartListJournalistDetailsModalStatTitle
                  title="Last Stories"
                  subtitle={`Stories that ${journalist.name} has published in the last 3 months`}
                />
                <SweetheartListJournalistDetailsModalLastStories
                  lastStories={journalistStats.lastFiveStories}
                />
              </div>
            </div>
          )}

          {loading && !hasJournalistStats && <Spinner />}

          {!loading && !hasJournalistStats && (
            <p className="text-center italic">
              This journalist doesn&apos;t have any stats yet.
            </p>
          )}
        </div>
      </Modal.Content>
      <Modal.RightButtons>
        <SweetheartListJournalistReachOut journalist={journalist} />
      </Modal.RightButtons>
    </Modal>
  );
};

SweetheartListJournalistDetailsModal.propTypes = {
  journalist: PropTypes.object,
  onClose: PropTypes.func,
};

export default SweetheartListJournalistDetailsModal;
