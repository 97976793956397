const createUserName = (userDataAuth = {}, userDataFirestore = {}) => {
  const { displayName } = userDataAuth;

  if (displayName) {
    return userDataAuth.displayName;
  }

  const { firstName, lastName } = userDataFirestore;

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return null;
};

const createUserAttributes = ({
  userDataAuth = {},
  userDataFirestore = {},
  userClaims = {},
  userHash = '',
}) => {
  if (!userDataFirestore || !userClaims || !userHash) {
    return null;
  }

  let userAttributes = {
    created_at: userDataAuth.metadata.createdAt / 1000,
    isAnonymous: userClaims.isAnonymous || false,
    isAdmin: userClaims.claimsAdmin || false,
    agencyId: userClaims.claimsAgencyId || null,
    user_id: userDataAuth.uid || null,
    user_hash: userHash || null,
  };

  if (!userClaims.isAnonymous && userDataFirestore) {
    userAttributes = {
      ...userAttributes,
      name: createUserName(userDataAuth, userDataFirestore),
      email: userDataAuth.email || null,
      marketingOptIn: userDataFirestore.marketingOptIn || false,
    };
  }

  return userAttributes;
};

export default createUserAttributes;
