import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

const InputFieldMultiButton = ({ buttons = [], label = '', reset = noop }) => (
  <div className="flex flex-col gap-1 w-fit shrink-0 group">
    {(label || reset !== noop) && (
      <div className="flex gap-2 justify-between text-sm text-gray-500">
        {label && <label>{label} </label>}
        {buttons.some((b) => b.status) && reset !== noop && (
          <button
            type="button"
            className="hidden group-hover:block text-xs"
            onClick={reset}
          >
            Reset
          </button>
        )}
      </div>
    )}
    <div className="relative z-0 inline-flex shadow-sm rounded-md">
      {buttons.map(({ text, status, setStatus }, index) => (
        <div key={text} className="flex">
          <button
            className={classNames(
              'relative inline-flex items-center px-4 py-2 border text-sm font-medium focus:z-20 focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500',
              {
                'bg-teal-100 text-teal-700 border-teal-500 z-10': status,
                'bg-white text-gray-700 border-gray-300 hover:bg-gray-50':
                  !status,
                'rounded-l-md ': index === 0,
                '-ml-px': index !== 0,
                'rounded-r-md ': index === buttons.length - 1,
              }
            )}
            onClick={() => setStatus(!status)}
            type="button"
          >
            {text}
          </button>
        </div>
      ))}
    </div>
  </div>
);

InputFieldMultiButton.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      status: PropTypes.bool.isRequired,
      setStatus: PropTypes.func.isRequired,
    })
  ).isRequired,
  label: PropTypes.string,
  reset: PropTypes.func,
};
export default InputFieldMultiButton;
