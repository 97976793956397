import PropTypes from 'prop-types';
import React from 'react';

import { PhotographIcon } from '@heroicons/react/solid';
import { useForm } from 'react-hook-form';
import { generatePath, Link } from 'react-router-dom';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import { SETTINGS_COMPANY_PATH, SETTINGS_PATH } from 'constants/paths';
import useUserData from 'contexts/UserContext/hooks/useUserData';

const CompanyHeaderModal = ({ open, uploadFolder, onClose, onSubmit }) => {
  const formName = 'company-header-modal';
  const form = useForm({ mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isValid },
  } = form;

  const { isAnonymous } = useUserData();

  return (
    <Modal open={open}>
      <Modal.Close
        srMessage="Close company header edit modal"
        onClose={onClose}
      />
      <Modal.Icon Icon={PhotographIcon} />
      <Modal.Title>Upload a cover photo</Modal.Title>

      <Modal.Description>
        A nice header image makes a good first impression to visitors.
      </Modal.Description>

      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)} id={formName}>
          <InputFieldUpload
            name="image"
            label="Upload a cover photo"
            form={form}
            folder={uploadFolder}
            rules={{
              required: 'Please upload a header image',
            }}
            testId="company-header-modal"
          >
            <InputFieldUpload.Hint>
              Upload an image with an aspect ratio of 16x9 or wider
              (approximately 1500x400 or similar).
            </InputFieldUpload.Hint>
          </InputFieldUpload>
          {!isAnonymous && (
            <p className="mt-4 text-sm text-gray-500">
              To update your company&apos;s name, website, location and logo,
              please go to the{' '}
              <Link
                className="underline"
                to={`/${generatePath(SETTINGS_PATH, {
                  '*': SETTINGS_COMPANY_PATH,
                })}`}
              >
                company settings page
              </Link>
              .
            </p>
          )}
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-header-modal-cancel"
        >
          Cancel
        </Button>
        <Button
          submit
          form={formName}
          disabled={!isValid}
          eventType="pressPage_update"
          eventProperties={{ action: 'header_add' }}
          data-test-id="company-header-modal-update"
        >
          Update image
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyHeaderModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadFolder: PropTypes.string.isRequired,
};

export default CompanyHeaderModal;
