import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import { REGEX_EMAIL } from 'constants/regex';

const UPLOAD_FOLDER = 'journalistImages';

const AdminJournalistsEditJournalistModal = ({
  open = false,
  onClose = noop,
  onSubmit = noop,
  currentJournalist = null,
}) => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: currentJournalist
      ? {
          ...currentJournalist,
          publicationName: currentJournalist.publication?.name,
          jobTitle: currentJournalist.jobTitle,
          image: currentJournalist.image
            ? {
                path: currentJournalist.image,
              }
            : null,
        }
      : {
          name: '',
          image: null,
        },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  return (
    <Modal open={open}>
      <Modal.Title>Edit journalist</Modal.Title>
      <Modal.Content>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="journalistForm"
          className="flex flex-col gap-2"
        >
          <InputFieldText
            {...register('name', {
              validate: (value) =>
                (value || '').trim().split(' ').length >= 2 ||
                'First and Last name are required',
            })}
            errors={errors}
            disabled={!!currentJournalist}
          >
            <InputFieldText.Label>Name</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText {...register('jobTitle')} errors={errors}>
            <InputFieldText.Label>Job Title</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText
            {...register('publicationName', {
              required: 'Publication is required',
            })}
            errors={errors}
            disabled={!!currentJournalist}
          >
            <InputFieldText.Label>Publication</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText
            {...register('email', {
              pattern: {
                value: REGEX_EMAIL,
                message: 'Please enter a valid email address',
              },
            })}
            errors={errors}
          >
            <InputFieldText.Label>Email</InputFieldText.Label>
          </InputFieldText>
          <InputFieldUpload name="image" folder={UPLOAD_FOLDER} form={form}>
            <InputFieldUpload.Label>Image</InputFieldUpload.Label>
          </InputFieldUpload>
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button submit form="journalistForm">
          Update Journalist
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminJournalistsEditJournalistModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentJournalist: PropTypes.object,
};

export default AdminJournalistsEditJournalistModal;
