import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextEditor from 'components/form/inputFields/InputFieldTextEditor';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';

const InboxMessageResponseFormEditorNewTemplateModal = ({
  onCancel = noop,
}) => {
  const { companyData } = useCompanyData();
  const { showError, showSuccess } = useNotifications();

  const { fetchData: createTemplate } = useAPIRequest({
    endpoint: `/companies/${companyData.uid}/templates`,
    method: 'PUT',
    service: 'MOD',
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      text: '',
    },
    mode: 'onSubmit',
  });

  return (
    <Modal open widthClass="max-w-2xl">
      <Modal.Close srMessage="Close edit Modal" onClose={onCancel} />
      <Modal.Title>Create new Template</Modal.Title>
      <Modal.Description>
        Create a new template to use in your response.
      </Modal.Description>
      <Modal.Content>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Label htmlFor="press-release-edit-rte">Template name*</Label>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Please provide a template name',
                },
              }}
              render={({ field }) => (
                <InputFieldText initialValue="" onChange={field.onChange} />
              )}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="press-release-edit-rte">Template content*</Label>
            <Controller
              name="text"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Please provide the template content',
                },
              }}
              render={({ field }) => (
                <InputFieldTextEditor
                  initialValue=""
                  onChange={field.onChange}
                  height="320px"
                />
              )}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" className="w-fit" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          className="w-fit"
          onClick={handleSubmit(async (data) => {
            try {
              await createTemplate({
                body: data,
              });
              onCancel();
              showSuccess({
                title: 'Template created',
                message: 'New template has been created',
              });
            } catch (_) {
              showError({
                title: 'Error creating template',
                message: 'Please try again later.',
              });
            }
          })}
        >
          Create Template
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

InboxMessageResponseFormEditorNewTemplateModal.propTypes = {
  onCancel: PropTypes.func,
};

export default InboxMessageResponseFormEditorNewTemplateModal;
