import PropTypes from 'prop-types';
import React from 'react';

import Avatar from 'components/Avatar';

// Displays a founder image alongside his/her name and position
const Founder = ({
  founder = {},
  widthClassName = 'max-w-full',
  testId = '',
}) =>
  founder?.name ? (
    <div className={`flex space-x-2 ${widthClassName}`}>
      <Avatar
        image={founder.image}
        className="w-8 h-8"
        alt={`${founder.name} profile photo`}
      />
      <div className="flex flex-col justify-center items-start text-xs max-w-full truncate text-left">
        <h4
          className="font-medium text-gray-700 truncate w-full"
          data-test-id={`${testId}-name`}
        >
          {founder.name}
        </h4>
        <p className="text-gray-400" data-test-id={`${testId}-position`}>
          {founder.position}
        </p>
      </div>
    </div>
  ) : (
    <div className="flex-1" />
  );

Founder.propTypes = {
  founder: PropTypes.shape({
    uid: PropTypes.string,
    image: PropTypes.object,
    name: PropTypes.string,
    position: PropTypes.string,
  }),
  widthClassName: PropTypes.string,
  testId: PropTypes.string,
};

export default Founder;
