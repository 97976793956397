import PropTypes from 'prop-types';
import React from 'react';

import { MailIcon, SparklesIcon } from '@heroicons/react/solid';

import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';
import getTimeFromTimestamp from 'utils/date/getTimeFromTimestamp';

const AdminMessagesMessageDate = ({
  sent = '',
  created = '',
  isJournalist = false,
}) => {
  const dateCreated = getDateFromTimestamp(created);
  const dateSent = sent
    ? `${getDateFromTimestamp(sent)}, ${getTimeFromTimestamp(sent)}`
    : null;

  return (
    <div className="flex gap-2">
      <div
        className="flex gap-1 items-center text-xs text-gray-400"
        title={
          isJournalist
            ? 'Date the message was received in mailbox'
            : 'Date the message was created by user'
        }
      >
        <SparklesIcon className="w-4 h-4" />
        <span>Created on: {dateCreated}</span>
      </div>
      {dateSent && !isJournalist && (
        <div
          className="flex gap-1 items-center text-xs text-gray-400"
          title="Date the message was sent to journalist"
        >
          <MailIcon className="w-4 h-4" />
          <span>Sent at: {dateSent}</span>
        </div>
      )}
    </div>
  );
};

AdminMessagesMessageDate.propTypes = {
  sent: PropTypes.string,
  created: PropTypes.string,
  isJournalist: PropTypes.bool,
};

export default AdminMessagesMessageDate;
