const trackIntercomEvent = (eventName = '') => {
  if (!window.Intercom) {
    return;
  }

  window.Intercom('trackEvent', eventName);
  window.Intercom('update', {
    last_request_at: parseInt(new Date().getTime() / 1000, 10),
  });
};

export default trackIntercomEvent;
