import PropTypes from 'prop-types';
import React from 'react';

import { useIdTokenResult } from 'reactfire';

const Claims = ({ user = {}, children = null }) => {
  const token = useIdTokenResult(user);
  const claims = token?.data?.claims || null;
  const claimsAgencyId = claims?.agencyId || null;
  const claimsAdmin = claims?.admin || null;
  const claimsJournalist = claims?.journalist || null;
  const claimsCompany = claims?.companyId || null;

  return children({
    claimsAgencyId,
    claimsAdmin,
    claimsJournalist,
    claimsCompany,
    token: token?.data?.token,
  });
};

const ClaimsWrapper = ({ user = {}, hasAuth = false, children = null }) => {
  if (hasAuth) {
    return <Claims user={user}>{children}</Claims>;
  }
  return children({});
};

ClaimsWrapper.propTypes = {
  user: PropTypes.object,
  hasAuth: PropTypes.bool,
  children: PropTypes.elementType,
};

export default ClaimsWrapper;
