import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import ContentElementOther from '../ContentElementOther';
import ContentElementUploadFile from '../ContentElementUploadFile';

import ContentElementMAAcquiredCountry from './ContentElementMAAcquiredCountry';
import ContentElementMAAcquiredName from './ContentElementMAAcquiredName';
import ContentElementMAAcquirer from './ContentElementMAAcquirer';
import ContentElementMADealSize from './ContentElementMADealSize';
import ContentElementMASector from './ContentElementMASector';

const ContentElementMA = ({ index = 0, updateCallback = noop }) => (
  <div className="relative flex flex-col items-center space-y-5">
    <ContentElementMAAcquirer index={index} />
    <div className="w-full grid grid-cols-1 gap-x-2 gap-y-5 sm:grid-cols-2">
      <ContentElementMAAcquiredName index={index} />
      <ContentElementMAAcquiredCountry />
    </div>
    <ContentElementMADealSize index={index} />
    <ContentElementMASector index={index} updateCallback={updateCallback} />
    <ContentElementOther
      index={index}
      placeholder="Type other relevant information..."
    />
    <ContentElementUploadFile name="file" label="Add files" />
  </div>
);

ContentElementMA.propTypes = {
  index: PropTypes.number,
  updateCallback: PropTypes.func,
};

export default ContentElementMA;
