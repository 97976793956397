import PropTypes from 'prop-types';
import React from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

const AdminCompaniesSortBy = ({
  onClick = noop,
  sortBy = {},
  name = '',
  label = '',
}) => (
  <div
    role="button"
    tabIndex={0}
    onClick={onClick}
    className="flex gap-1 items-center"
  >
    <span className="min-w-max">{label}</span>
    {name === sortBy.name && sortBy.direction === 'desc' ? (
      <ChevronDownIcon className="w-4 h-4" />
    ) : (
      <ChevronUpIcon className="w-4 h-4" />
    )}
  </div>
);

AdminCompaniesSortBy.propTypes = {
  onClick: PropTypes.func.isRequired,
  sortBy: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default AdminCompaniesSortBy;
