import PropTypes from 'prop-types';
import React from 'react';

import { ExternalLinkIcon } from '@heroicons/react/outline';
import currency from 'currency.js';

import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';

const BillingSettingsSuccessFeesTable = ({ successFees = [] }) => (
  <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
    <table className="min-w-full divide-y divide-gray-200 text-gray-500">
      <thead className="bg-gray-50 text-xs font-medium uppercase">
        <tr className="text-left tracking-wider">
          <th scope="col" className="px-4 py-2">
            Media Opportunity
          </th>
          <th scope="col" className="px-4 py-2">
            Article Link
          </th>
          <th scope="col" className="px-4 py-2 max-w-sm">
            Date Published
          </th>
          <th scope="col" colSpan="2" className="px-4 py-2">
            Success Fee
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 text-sm">
        {successFees.map(({ uid, date, title, link, price, invoice }) => (
          <tr key={uid}>
            <td className="px-4 py-2 whitespace-nowrap">{title || 'n/a'}</td>
            <td className="px-4 py-2 whitespace-nowrap">
              {link ? (
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex gap-1 items-center text-teal-500 hover:text-teal-600"
                >
                  {link}
                  <ExternalLinkIcon className="w-4 h-4" />
                </a>
              ) : (
                'n/a'
              )}
            </td>
            <td className="px-4 py-2 whitespace-nowrap">
              {date ? getDateFromTimestamp(date?.toDate()) : 'n/a'}
            </td>
            <td className="px-4 py-2 whitespace-nowrap">
              {currency(price?.unit_amount || 0, {
                fromCents: true,
                symbol: '£',
              }).format()}{' '}
              + VAT
            </td>
            <td className="px-4 py-2 whitespace-nowrap">
              <a
                href={invoice?.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex gap-1 items-center text-teal-500 hover:text-teal-600"
              >
                Invoice
                <ExternalLinkIcon className="w-4 h-4" />
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

BillingSettingsSuccessFeesTable.propTypes = {
  successFees: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.shape({
        unit_amount: PropTypes.number,
      }),
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

export default BillingSettingsSuccessFeesTable;
