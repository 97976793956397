import { collection } from 'firebase/firestore';
import orderBy from 'lodash/orderBy';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';

import useReverseOpportunitySearch from './useReverseOpportunitySearch';

const useMergeCompaniesIntoOpportunities = (opportunities = []) => {
  const firestore = useFirestore();

  const {
    result: opportunitiesToCompanies,
    loading,
    error,
  } = useReverseOpportunitySearch();

  // collect all the companies
  const companiesCollection = collection(firestore, COMPANIES_COLLECTION);

  const { data: companiesData } = useFirestoreCollectionData(
    companiesCollection,
    defaultOptions
  );

  const activeCompanies = companiesData.filter(
    (c) => !c.archived && !c.internal
  );

  const mappedOpportunities = opportunities.map((o) => {
    const companyIds = opportunitiesToCompanies[o.uid] || [];
    const companies = activeCompanies.filter((c) => companyIds.includes(c.uid));
    const targetedCompanies = activeCompanies.filter((activeCompany) =>
      o.targetedCompanies?.includes(activeCompany.uid)
    );
    const companiesAlphabetical = orderBy(companies, 'slug', 'asc');
    const targetedCompaniesAlphabetical = orderBy(
      targetedCompanies,
      'slug',
      'asc'
    );

    return {
      ...o,
      targetedCompanies: targetedCompaniesAlphabetical,
      companies: companiesAlphabetical,
    };
  });

  return {
    opportunities: mappedOpportunities,
    loading,
    error,
  };
};

export default useMergeCompaniesIntoOpportunities;
