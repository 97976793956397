import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/lodash';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

// Opportunities filter/sort modal: allows filtering and sorting opportunities
const OpportunitiesFilterSortModal = ({
  open = false,
  onClose = noop,
  children = null,
}) => (
  <Modal open={open} widthClass="max-w-fit">
    <Modal.Close srMessage="Close Filter sort modal" onClose={onClose} />
    <Modal.Content>{children}</Modal.Content>

    <Modal.RightButtons>
      <Button onClick={onClose}>Done</Button>
    </Modal.RightButtons>
  </Modal>
);

OpportunitiesFilterSortModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default OpportunitiesFilterSortModal;
