import { v4 as uuidv4 } from 'uuid';

// topics -> topicsOther
const getFieldName = (defaultFieldName) => `${defaultFieldName}Other`;

// topics -> topic
const getSingular = (defaultFieldName) =>
  defaultFieldName.slice(0, defaultFieldName.length - 1);

// generate an ID of type: `custom-topic-{uuid}` to avoid duplicates
const createId = (singular) => `custom-${singular}-${uuidv4()}`;

export { getFieldName, getSingular, createId };
