import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { PlusIcon, SparklesIcon, ThumbDownIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import useEnvironment from 'contexts/EnvironmentContext/hooks/useEnvironment';
import { OpportunitiesContext } from 'contexts/OpportunitiesContext/OpportunitiesContext';
import useQueryParams from 'routes/Opportunities/hooks/useQueryParams';

import OpportunitiesListOpportunityActionsAction from './OpportunitiesListOpportunityActionsAction';

const OpportunitiesListOpportunityActions = ({
  opportunity = {},
  expired = false,
  onSelect = noop,
  isSelected = false,
}) => {
  const { companiesOpportunitiesOps } = useContext(OpportunitiesContext);
  const { showDeclined } = useQueryParams();
  const { isLocal, isEmulated } = useEnvironment();

  const revealHoverable = isLocal && isEmulated;
  const baseClasses = 'bg-white hover:bg-gray-50 text-gray-500';
  const hoverClasses = `${baseClasses} ${
    revealHoverable ? '' : 'opacity-0 group-hover:opacity-100'
  }`;

  if (expired) {
    return (
      <div className="hidden lg:flex flex-none flex-col items-end whitespace-nowrap space-y-2 pr-4 w-40" />
    );
  }

  return (
    <div className="hidden lg:flex flex-none flex-col items-end whitespace-nowrap space-y-2 pr-4 w-40">
      {showDeclined ? (
        <OpportunitiesListOpportunityActionsAction
          className={hoverClasses}
          onClick={() => companiesOpportunitiesOps.undecline(opportunity)}
          testId="opportunity-actions-reactivate"
        >
          <span className="font-semibold">Reactivate</span>
        </OpportunitiesListOpportunityActionsAction>
      ) : null}

      {!showDeclined && !opportunity?.thread?.exists ? (
        <>
          <OpportunitiesListOpportunityActionsAction
            className={
              isSelected ? 'bg-teal-500 hover:bg-teal-500 text-white' : ''
            }
            onClick={onSelect}
            testId="actionButtons-select"
          >
            {isSelected ? (
              <span>Selected</span>
            ) : (
              <>
                <PlusIcon className="w-4" /> <span>Select</span>
              </>
            )}
          </OpportunitiesListOpportunityActionsAction>
          <OpportunitiesListOpportunityActionsAction
            className={hoverClasses}
            onClick={() => {
              companiesOpportunitiesOps.respond(opportunity);
            }}
            testId="opportunity-actions-respond"
          >
            <SparklesIcon className="w-4 text-gray-400" />{' '}
            <span className="font-semibold">Respond</span>
          </OpportunitiesListOpportunityActionsAction>
          <OpportunitiesListOpportunityActionsAction
            className={hoverClasses}
            onClick={() => {
              companiesOpportunitiesOps.decline(opportunity);
            }}
            testId="opportunity-actions-decline"
          >
            <ThumbDownIcon className="w-4 text-gray-400" />{' '}
            <span className="font-semibold">Decline</span>
          </OpportunitiesListOpportunityActionsAction>
        </>
      ) : null}
    </div>
  );
};

OpportunitiesListOpportunityActions.propTypes = {
  opportunity: PropTypes.object.isRequired,
  expired: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default OpportunitiesListOpportunityActions;
