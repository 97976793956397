import { filterOptions } from 'constants/opportunitiesOptions';

const SEARCH_URL = process.env.REACT_APP_SEARCH_URL;
const findFilter = (key) => (i) =>
  filterOptions.find((f) => f.index === i)[key];

const createSearchURL = ({ companyId = '', terms = [], queryParams = {} }) => {
  const url = new URL(`${SEARCH_URL}/search/v2/opportunities`);
  url.searchParams.append('page', 1);
  url.searchParams.append('companyId', companyId);
  url.searchParams.append('searchTerms', terms || []);

  const { id, sort, sortDir, filter } = queryParams;

  // We only want to add the opportunityId to the search query on the initial load
  // This is to prevent the search query from being reset when the user clicks on an opportunity
  if (id) {
    url.searchParams.append('opportunityId', id);
  }

  if (filter.length > 0) {
    const min = Math.min(...filter.map(findFilter('min')));
    const max = Math.max(...filter.map(findFilter('max')));

    url.searchParams.append('minDomainAuthority', min);
    url.searchParams.append('maxDomainAuthority', max);
  }

  if (sort) {
    const sortDirection = sortDir === 1 ? 'desc' : 'asc';
    url.searchParams.append('sortDirection', sortDirection);

    if (sort === 'domainAuthority') {
      url.searchParams.append('sortBy', 'domain_authority');
    }

    if (sort === 'timeLeft') {
      url.searchParams.append('sortBy', 'deadline_date_utc');
    }
  }

  const urlString = url.toString();
  // Adding, removing or changing the `opportunityId` param should not trigger a reload
  url.searchParams.delete('opportunityId');
  const urlChangedKey = url.toString();

  return {
    url: urlString,
    urlChangedKey,
  };
};

export default createSearchURL;
