import PropTypes from 'prop-types';
import React from 'react';

import { useFormContext } from 'react-hook-form';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const ContentElementProductServiceName = ({ index = 0 }) => {
  const name = 'name';
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputFieldText
      id={`${name}${index}`}
      {...register(name, {
        required: {
          value: true,
          message: 'Please provide the name of the product or service',
        },
      })}
      errors={errors}
      testId="company-content-product-service-inputName"
      placeholder="Type the name of the product/service here..."
    >
      <InputFieldText.Label>Name of Product / Service*</InputFieldText.Label>
    </InputFieldText>
  );
};

ContentElementProductServiceName.propTypes = {
  index: PropTypes.number,
  contentType: PropTypes.string,
};

export default ContentElementProductServiceName;
