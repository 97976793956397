import { getDownloadURL, ref } from 'firebase/storage';
import { useAuth, useStorage } from 'reactfire';

const BASE_URL = process.env.REACT_APP_JPD_URL;

const useUpdateJournalist = () => {
  const auth = useAuth();
  const storage = useStorage();

  const updateJournalist = async (journalist = {}) => {
    const jwt = await auth.currentUser?.getIdToken();

    const imageUrl = journalist?.image?.localName
      ? await getDownloadURL(ref(storage, journalist?.image?.path))
      : journalist?.image?.path;

    const response = await fetch(`${BASE_URL}/journalists`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        source: 'adminUi',
        name: journalist.name,
        publicationName: journalist.publicationName,
        fields: {
          jobTitle: journalist.jobTitle || '',
          email: journalist.email || '',
          isDeactivated: journalist.isDeactivated,
          ...(imageUrl ? { imageUrl } : {}),
        },
      }),
    });
    return response.json();
  };

  return updateJournalist;
};

export default useUpdateJournalist;
