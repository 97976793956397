import PropTypes from 'prop-types';
import React from 'react';

import { useFormContext } from 'react-hook-form';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const ContentElementOther = ({ index = 0, placeholder = '' }) => {
  const name = 'other';
  const { register, getValues } = useFormContext();

  return (
    <InputFieldText
      id={`${name}${index}`}
      {...register(name)}
      defaultValue={getValues(name)}
      placeholder={placeholder}
      testId="company-content-inputOther"
    >
      <InputFieldText.Label>Anything else</InputFieldText.Label>
    </InputFieldText>
  );
};

ContentElementOther.propTypes = {
  index: PropTypes.number,
  placeholder: PropTypes.string,
};

export default ContentElementOther;
