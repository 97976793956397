import React from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import find from 'lodash/find';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { tabs } from 'constants/inboxTabs';
import { INBOX_PATH } from 'constants/paths';

import InboxNavbarTabsItem from './InboxNavbarTabsItem';

const InboxNavbarTabsMobile = () => {
  const navigate = useNavigate();
  const { tab } = useParams();

  return (
    <div className="xl:hidden py-2 ml-2 xl:ml-4 mt-1 mb-3 text-teal-500">
      <Listbox
        as="div"
        value={tab}
        onChange={(value) => {
          navigate(generatePath(INBOX_PATH, { tab: value }));
        }}
      >
        {({ open }) => (
          <div className="relative flex flex-col">
            <span className="block w-full rounded-md">
              <Listbox.Button
                className="flex items-center w-56 sm:w-64 space-x-2 cursor-default relative focus:outline-none font-medium"
                data-test-id="inboxMessage-dropdownButton"
              >
                <InboxNavbarTabsItem tab={find(tabs, { field: tab })} />
                <ChevronDownIcon className="w-4" />
              </Listbox.Button>
            </span>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute z-10 mt-12 w-full rounded-md bg-white shadow-lg"
            >
              <Listbox.Options
                static
                className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 w-56 sm:w-64"
              >
                {tabs.map((tabOption) => (
                  <Listbox.Option
                    key={tabOption.field}
                    value={tabOption.field}
                    data-test-id={`inboxMessage-dropdownButton-${tabOption.field}`}
                  >
                    {({ active }) => (
                      <div
                        className={`text-gray-900 ${
                          active ? 'bg-gray-50' : ''
                        } cursor-default select-none relative py-2 pl-2`}
                      >
                        <InboxNavbarTabsItem tab={tabOption} />
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
};

export default InboxNavbarTabsMobile;
