import PropTypes from 'prop-types';
import React from 'react';

import AddButton from 'components/buttons/AddButton';

const CompanyInsightPlaceholder = ({ onClick, testId = '' }) => (
  <div className="relative w-full flex flex-1 justify-center items-center p-12 py-9">
    <div className="z-0 w-full absolute inset-0 flex flex-col flex-1 justify-center mt-2 pointer-events-none space-y-1 opacity-50">
      <p className="w-1/2 rounded bg-gray-50">&nbsp;</p>
      <p className="w-3/4 rounded bg-gray-50">&nbsp;</p>
      <p className="w-2/3 rounded bg-gray-50">&nbsp;</p>
      <p className="w-5/6 rounded bg-gray-50">&nbsp;</p>
      <p className="w-3/5 rounded bg-gray-50">&nbsp;</p>
    </div>
    <div className="relative z-10">
      <AddButton onClick={onClick} testId={`${testId}-add`} />
    </div>
  </div>
);

CompanyInsightPlaceholder.propTypes = {
  onClick: PropTypes.func,
  testId: PropTypes.string,
};

export default CompanyInsightPlaceholder;
