import React, { useState } from 'react';

import Button from 'components/buttons/Button';

import AdminMessagesResetThreadModal from './AdminMessagesResetThreadModal';

const AdminMessagesResetThread = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        type="danger"
        onClick={() => setIsModalOpen(true)}
        className="h-fit mt-auto ml-auto"
      >
        Reset thread
      </Button>
      {isModalOpen ? (
        <AdminMessagesResetThreadModal onClose={() => setIsModalOpen(false)} />
      ) : null}
    </>
  );
};

export default AdminMessagesResetThread;
