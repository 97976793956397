import React from 'react';

import { XIcon } from '@heroicons/react/solid';

import Button from 'components/buttons/Button';

import { actions } from '../contexts/filtersReducer';
import useAdminMessagesContext from '../contexts/useAdminMessagesContext';

const AdminMessagesFiltersReset = () => {
  const { filters, dispatch } = useAdminMessagesContext();
  const showReset = Object.values(filters).some((value) => value);

  if (!showReset) {
    return null;
  }

  return (
    <Button
      type="secondary"
      className="w-[38px] h-[38px] self-end"
      onClick={() => {
        dispatch({ type: actions.RESET_FILTERS });
      }}
    >
      <XIcon className="w-6 h-6" />
    </Button>
  );
};

export default AdminMessagesFiltersReset;
