import React from 'react';

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import PrivateRoute from 'components/router/PrivateRoute';

import AgencyRoute from './Agency';
import CompanyRoute from './Company';
import HomePage from './Home/HomePage';
import InboxRoute from './Inbox';
import JournalistRoute from './Journalist';
import JournalistResponseRoute from './JournalistResponse';
import NotFoundPage from './NotFound/NotFoundPage';
import OnboardingRoute from './Onboarding';
import OpportunitiesRoute from './Opportunities';
import RootRoute from './Root';
import SettingsRoute from './Settings';
import SweetheartListRoute from './SweetheartList';
import AdminRoute from './admin';
import AdminCompaniesRoute from './admin/AdminCompanies';
import AdminCreateCustomThreadRoute from './admin/AdminCreateCustomThread';
import AdminCreateOpportunityRoute from './admin/AdminCreateOpportunity';
import AdminJournalistsRoute from './admin/AdminJournalists';
import AdminMessagesRoute from './admin/AdminMessages';
import AdminOpportunitiesRoute from './admin/AdminOpportunities';
import AdminPublicationsRoute from './admin/AdminPublications';
import AdminSuccessFeesRoute from './admin/AdminSuccessFees';
import DeleteAccountRoute from './auth/DeleteAccount';
import LoginRoute from './auth/Login';
import LoginImpersonateRoute from './auth/LoginImpersonate';
import ResetPasswordRoute from './auth/ResetPassword';
import SignupAgencyRoute from './auth/SignupAgency';
import SignupJournalistRoute from './auth/SignupJournalist';
import SignupMemberRoute from './auth/SignupMember';

const RootComponent = RootRoute.component;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={RootRoute.path} element={<RootComponent />}>
      <Route index element={<HomePage />} />
      <Route path="*" element={<NotFoundPage />} />
      {[
        // auth routes
        SignupAgencyRoute,
        SignupMemberRoute,
        SignupJournalistRoute,
        LoginRoute,
        LoginImpersonateRoute,
        ResetPasswordRoute,
        DeleteAccountRoute,

        // onboarding route
        OnboardingRoute,

        // user area routes
        CompanyRoute,
        OpportunitiesRoute,
        SettingsRoute,
        SweetheartListRoute,
        InboxRoute,

        // agency routes
        AgencyRoute,

        // journalist routes
        JournalistRoute,
        JournalistResponseRoute,

        // admin routes
        AdminRoute,
        AdminPublicationsRoute,
        AdminJournalistsRoute,
        AdminOpportunitiesRoute,
        AdminCreateOpportunityRoute,
        AdminCompaniesRoute,
        AdminSuccessFeesRoute,
        AdminMessagesRoute,
        AdminCreateCustomThreadRoute,
      ].map((settings) => {
        const Component = settings.component;

        return (
          <Route
            key={`Route-${settings.path}`}
            path={settings.path}
            element={
              settings.authRequired ? (
                <PrivateRoute
                  key={`Route-${settings.path}`}
                  claimsRequired={settings.claimsRequired}
                >
                  <Component key={`Route-${settings.path}`} />
                </PrivateRoute>
              ) : (
                <Component key={`Route-${settings.path}`} />
              )
            }
          />
        );
      })}
    </Route>
  )
);
const Router = () => <RouterProvider router={router} />;

export default Router;
