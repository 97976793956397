import { useEffect, useMemo } from 'react';

import debounce from 'lodash/debounce';

import { useTracking } from 'modules/segment/TrackingProvider';

import useQueryParams from './useQueryParams';

const useLogScroll = () => {
  const trackingService = useTracking();
  const { showDeclined } = useQueryParams();
  const tab = showDeclined ? 'declined' : 'upcoming';

  const onScroll = useMemo(
    () =>
      debounce(
        () =>
          trackingService.track('opportunity_interaction', {
            action: 'scroll',
            opportunities_tab: tab,
          }),
        1000
      ),
    [tab, trackingService]
  );

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);
};

export default useLogScroll;
