import getJournalistId from 'utils/getJournalistId';
import getPublicationId from 'utils/getPublicationId';

const addJournalistDataToOpportunity = ({
  opp = {},
  journalists = [],
  publications = [],
}) => {
  const journalistId = getJournalistId(opp.journalist, opp.mediaOutlet);
  const publicationId = getPublicationId(opp.mediaOutlet);

  let journalistData = journalists.find((j) => j.id === journalistId);
  if (!journalistData) {
    journalistData = {
      name: opp.journalist,
      publication: publications.find((p) => p.id === publicationId) || {
        name: opp.mediaOutlet,
      },
    };
  }

  return {
    ...opp,
    journalistData,
  };
};

export default addJournalistDataToOpportunity;
