import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import noop from 'lodash/noop';

import useCreateSetState from '../hooks/useCreateSetState';
import useCurrentOpportunity from '../hooks/useCurrentOpportunity';
import useLogScroll from '../hooks/useLogScroll';
import useQueryParams from '../hooks/useQueryParams';

import OpportunitiesListBulkActions from './OpportunitiesListBulkActions';
import OpportunitiesListEmptyState from './OpportunitiesListEmptyState';
import OpportunitiesListErrorState from './OpportunitiesListErrorState';
import OpportunitiesListOpportunity from './OpportunitiesListOpportunity/OpportunitiesListOpportunity';
import OpportunitiesListResponseModal from './OpportunitiesListResponseModal/OpportunitiesListResponseModal';

const OpportunitiesList = ({
  opportunities = [],
  error = false,
  onOpportunityExpand = noop,
}) => {
  const { showDeclined } = useQueryParams();
  const setId = useCreateSetState('id');
  const [
    currentOpportunityIndex,
    showOpportunityModal,
    isRedirecting,
    currentOpportunityTab,
  ] = useCurrentOpportunity(opportunities);
  const currentOpportunities =
    opportunities[showDeclined ? 'declined' : currentOpportunityTab];

  const [selectedOpportunitiesIds, setSelectedOpportunitiesIds] = useState([]);
  const selectedOpportunities = useMemo(
    () =>
      currentOpportunities.filter((o) =>
        selectedOpportunitiesIds.includes(o.uid)
      ),
    [currentOpportunities, selectedOpportunitiesIds]
  );
  useLogScroll();

  if (isRedirecting) {
    return null;
  }

  return (
    <>
      <div className="mt-6 relative" data-test-id="opportunitiesList">
        {!error && currentOpportunities.length === 0 && (
          <OpportunitiesListEmptyState showDeclined={showDeclined} />
        )}

        {error && <OpportunitiesListErrorState />}

        {currentOpportunities.map((opportunity) => (
          <OpportunitiesListOpportunity
            key={opportunity.uid}
            opportunity={opportunity}
            onExpand={() => {
              onOpportunityExpand(opportunity);
              // logOpportunityInteraction('expand', opportunity);
              setId(opportunity.uid);
            }}
            onSelect={(uid) =>
              setSelectedOpportunitiesIds((arr) =>
                selectedOpportunitiesIds.includes(uid)
                  ? arr.filter((o) => o !== uid)
                  : [...arr, uid]
              )
            }
            isSelected={selectedOpportunitiesIds.includes(opportunity.uid)}
          />
        ))}
      </div>
      {selectedOpportunitiesIds.length ? (
        <OpportunitiesListBulkActions
          selectedOpportunities={selectedOpportunities}
          setSelectedOpportunities={setSelectedOpportunitiesIds}
        />
      ) : null}

      {showOpportunityModal ? (
        <OpportunitiesListResponseModal
          open={showOpportunityModal}
          opportunity={currentOpportunities[currentOpportunityIndex]}
          nextId={currentOpportunities[currentOpportunityIndex + 1]?.uid}
          prevId={currentOpportunities[currentOpportunityIndex - 1]?.uid}
          currentCount={currentOpportunityIndex}
          totalCount={currentOpportunities.length}
        />
      ) : null}
    </>
  );
};

OpportunitiesList.propTypes = {
  opportunities: PropTypes.object.isRequired,
  error: PropTypes.bool,
  onOpportunityExpand: PropTypes.func,
};

export default OpportunitiesList;
