import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

import Button from 'components/buttons/Button';
import CompanyImage from 'components/company/CompanyImage';

const CompanyThreadAssignModalCompanySearchResult = ({
  company = {},
  selectedCompany = {},
  onCompanyClick = noop,
}) => {
  const isSelectedCompany = selectedCompany.uid === company.uid;

  return (
    <div
      key={company.uid}
      className={classNames(
        'flex items-center gap-2 text-gray-700 p-2 border border-gray-300 rounded-md',
        {
          'border-teal-500': isSelectedCompany,
        }
      )}
    >
      <CompanyImage image={company.logo} alt={`${company.name} logo`} />
      <div className="flex flex-col w-fit">
        <span className="font-semibold">{company.name}</span>
        <span className="w-fit lowercase bg-gray-200 px-2 py-0.5 rounded-md text-xs">
          {company?.website || 'no website'}
        </span>
      </div>
      <Button
        type={isSelectedCompany ? 'primary' : 'secondary'}
        className="ml-auto"
        onClick={() => onCompanyClick(company)}
      >
        {isSelectedCompany ? 'Selected' : 'Select'}
      </Button>
    </div>
  );
};

CompanyThreadAssignModalCompanySearchResult.propTypes = {
  company: PropTypes.object,
  selectedCompany: PropTypes.object,
  onCompanyClick: PropTypes.func,
};

export default CompanyThreadAssignModalCompanySearchResult;
