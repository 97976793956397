import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

// Renders a pagination nav bar
const PaginationNav = ({
  currentPage = 1,
  setPage = noop,
  maxPages = 10,
  loading = true,
  className = 'm-8 ml-10',
}) => {
  if (loading) {
    return null;
  }
  const start = Math.max(currentPage - 5, 0);
  const pageIndices = [...Array(maxPages).keys()].slice(start, start + 10);

  const activeItemClasses =
    'py-2 px-3 text-blue-600 bg-blue-50 border border-gray-300 hover:bg-blue-100 hover:text-blue-700 cursor-pointer';
  const inactiveItemClasses =
    'py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 cursor-pointer';

  return (
    <div className={`flex align content-center justify-center ${className}`}>
      <nav aria-label="Page navigation example">
        <ul className="inline-flex -space-x-px">
          {currentPage > 1 && (
            <li>
              <a
                className={classNames(inactiveItemClasses, 'rounded-l-lg')}
                onClick={() => setPage(currentPage - 1)}
              >
                Previous
              </a>
            </li>
          )}
          {pageIndices.map((i) => (
            <li key={`nav-page-${i}`}>
              <a
                onClick={() => setPage(i + 1)}
                className={
                  i + 1 === currentPage
                    ? classNames(activeItemClasses, {
                        'rounded-l-lg': currentPage === 1,
                        'rounded-r-lg': currentPage === maxPages,
                      })
                    : inactiveItemClasses
                }
              >
                {i + 1}
              </a>
            </li>
          ))}
          {currentPage < maxPages && (
            <li>
              <a
                className={classNames(inactiveItemClasses, 'rounded-r-lg')}
                onClick={() => setPage(currentPage + 1)}
              >
                Next
              </a>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

PaginationNav.propTypes = {
  currentPage: PropTypes.number,
  setPage: PropTypes.func,
  maxPages: PropTypes.number,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default PaginationNav;
