import { useMemo, useState } from 'react';

import sortBy from 'lodash/sortBy';

const sortByDateCreatedOrMostRecentMessage = (
  threads = [],
  order = new Map()
) =>
  sortBy(threads, (thread) => {
    const { threadDateCreated, mostRecentMessageDate } = order.get(thread.uid);

    return mostRecentMessageDate || threadDateCreated;
  }).reverse();

const useOrderedThreads = (threads = [], isDraftTab = false) => {
  const [order, setOrder] = useState(new Map());

  const orderedThreads = useMemo(() => {
    if (isDraftTab) {
      return threads;
    }

    const threadsWithUnreadMessages = threads.filter(
      (thread) =>
        order.get(thread.uid) && order.get(thread.uid)?.threadHasUnreadMessages
    );
    const threadsWithoutUnreadMessages = threads.filter(
      (thread) =>
        order.get(thread.uid) && !order.get(thread.uid)?.threadHasUnreadMessages
    );

    return [
      ...sortByDateCreatedOrMostRecentMessage(threadsWithUnreadMessages, order),
      ...sortByDateCreatedOrMostRecentMessage(
        threadsWithoutUnreadMessages,
        order
      ),
    ];
  }, [isDraftTab, order, threads]);

  return { orderedThreads, order, setOrder };
};

export default useOrderedThreads;
