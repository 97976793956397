import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const ModalIcon = ({
  Icon = null,
  testId = '',
  error = false,
  warning = false,
  withBackground = true,
}) => (
  <div
    className={classNames('mx-auto mb-2 flex items-center justify-center ', {
      'bg-teal-500': !error && !warning && withBackground,
      'bg-red-500': error && withBackground,
      'bg-yellow-500': warning && withBackground,
      'h-12 w-12 rounded-full text-white': withBackground,
    })}
  >
    <Icon data-test-id={testId} className="w-6" />
  </div>
);

ModalIcon.propTypes = {
  Icon: PropTypes.object,
  testId: PropTypes.string,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  withBackground: PropTypes.bool,
};

export default ModalIcon;
