import PropTypes from 'prop-types';
import React from 'react';

import Tag from 'components/tags/Tag';
import getContentType from 'constants/contentTypes';
import getTagColors from 'utils/color/getTagColors';

const CompanyContentTitle = ({ content = {} }) => {
  const { label, Icon, id } = getContentType(content);
  const [bgColor, textColor] = getTagColors(id, label?.length);

  return (
    <div className="pt-6 px-6">
      <Tag
        bgColor={label ? bgColor : 'gray-100'}
        textColor={label ? textColor : 'gray-500'}
        data-test-id={`company-content-label-${label}`}
      >
        <Icon className="w-4 h-4 mr-1" />
        {label}
      </Tag>
    </div>
  );
};

CompanyContentTitle.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CompanyContentTitle;
