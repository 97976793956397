import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import useCompoundComponents from 'hooks/useCompoundComponents';
import registerCompoundComponents from 'utils/components/registerCompoundComponents';

import modalCompoundComponents from './modalCompoundComponents';

// Renders content in a modal overlay
// See Notion for documentation of this component: https://www.notion.so/mvpr/Modal-bd76472b25fc48eaa5fc7ab35952e80e
const Modal = ({
  children = null,
  open = false,
  onClose = noop,
  widthClass = 'sm:max-w-xl',
  buttonsContainerClass = '',
  contentContainerClass = '',
  testId = '',
}) => {
  const {
    Icon,
    Title,
    Description,
    Content,
    Close,
    LeftButtons,
    RightButtons,
    TopBar,
    BottomBar,
    Overlay,
  } = useCompoundComponents(children, modalCompoundComponents);

  return (
    <Dialog
      open={open}
      as="div"
      onClose={onClose}
      className="fixed inset-0 z-[100] overflow-y-auto"
    >
      <div className="modal-overlay" />
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Panel
          data-test-id={testId}
          className={classNames(
            'relative overflow-visible bg-white rounded-lg shadow-xl m-6 max-w-xl w-full',
            widthClass
          )}
        >
          {Overlay || null}

          {TopBar || null}

          {Close || null}

          <div
            className={classNames(
              'p-6 flex flex-col gap-2',
              contentContainerClass
            )}
          >
            {Icon || null}

            {Title || null}

            {Description || null}

            {Content || null}
          </div>

          {LeftButtons || RightButtons ? (
            <div
              className={classNames(
                'p-6 flex w-full justify-between bg-gray-50 rounded-b-md',
                buttonsContainerClass
              )}
            >
              {LeftButtons || null}
              {RightButtons || null}
            </div>
          ) : null}

          {BottomBar || null}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  widthClass: PropTypes.string,
  buttonsContainerClass: PropTypes.string,
  contentContainerClass: PropTypes.string,
  testId: PropTypes.string,
  open: PropTypes.bool,
};

registerCompoundComponents(Modal, modalCompoundComponents);

export default Modal;
