import React from 'react';

import AdminMessagesResetThread from '../AdminMessagesCompaniesTab/AdminMessagesMessages/AdminMessagesResetThread';

import AdminMessagesFiltersReset from './AdminMessagesFiltersReset';
import AdminMessagesFiltersSourceTarget from './AdminMessagesFiltersSourceTarget';
import AdminMessagesFiltersStatus from './AdminMessagesFiltersStatus';

const AdminMessagesFilters = () => (
  <div className="flex gap-2 self-start flex-wrap w-full">
    <AdminMessagesFiltersSourceTarget />
    <AdminMessagesFiltersStatus />
    <AdminMessagesFiltersReset />
    <AdminMessagesResetThread />
  </div>
);

export default AdminMessagesFilters;
