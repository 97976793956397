import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Switch } from '@headlessui/react';
import {
  HomeIcon,
  InformationCircleIcon,
  LogoutIcon,
} from '@heroicons/react/outline';
import { CheckIcon, LockClosedIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useRemoteConfigString } from 'reactfire';

import Callout from 'components/Callout/Callout';
import Modal from 'components/Modal';
import StripeRedirectModal from 'components/StripeRedirectModal';
import Button from 'components/buttons/Button';
import { ADMIN_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useHandleLogout from 'hooks/useHandleLogout';
import useRedirectToStripe from 'hooks/useRedirectToStripe';
import { useTracking } from 'modules/segment/TrackingProvider';

const DEFAULT_CALLOUT_MESSAGE = [
  "If you don't know which plan to choose or don't have the payment details available, ",
  'you can come back here later by logging in with your account.',
];

// Modal to select one of the available payment plans
const OnboardingWrapperPlansModal = ({
  open = false,
  onClose = noop,
  showCloseButton = false,
  calloutMessage = DEFAULT_CALLOUT_MESSAGE,
}) => {
  const handleLogout = useHandleLogout();
  const navigate = useNavigate();
  const { uid, claimsAdmin: isAdmin } = useUserData();
  const { companyData } = useCompanyData();

  const { data } = useRemoteConfigString('tiers');
  const { tiers } = JSON.parse(data);

  const { redirectToStripe, isRedirecting } = useRedirectToStripe();

  const trackingService = useTracking();

  const onClick = async ({ priceId, tierName, productId }) => {
    trackingService.track('onboarding_plans', {
      action: 'plan_select',
      stripe_productId: productId,
      stripe_tierName: tierName,
      stripe_priceId: priceId,
    });

    const successUrl = `${window.location.origin}/opportunities?success=true`;

    await redirectToStripe({
      uid,
      slug: companyData?.slug,
      priceId,
      successUrl,
      cancelUrl: `${window.location.origin}${window.location.pathname}?error=true`,
    });
  };

  const [successFeesToggled, setSuccessFeesToggled] = useState(false);

  const { Icon, text, onClickTopButton } = {
    Icon: isAdmin ? HomeIcon : LogoutIcon,
    text: isAdmin ? 'Go back to Dashboard' : 'Log out',
    onClickTopButton: isAdmin ? () => navigate(ADMIN_PATH) : handleLogout,
  };

  return (
    <>
      {isRedirecting && <StripeRedirectModal isRedirecting={isRedirecting} />}
      <Modal
        open={open}
        widthClass="sm:max-w-full md:max-w-7xl"
        testId="onboardingWrapper-plansModal"
      >
        {showCloseButton ? (
          <Modal.Close srMessage="Close Plans Modal" onClose={onClose} />
        ) : null}
        <Modal.TopBar>
          <div className="flex w-full absolute p-4 px-6 justify-end">
            <Button
              type="secondary"
              className="flex gap-1"
              onClick={onClickTopButton}
            >
              <Icon className="h-5 w-5" />
              <span className="sr-only sm:not-sr-only">{text}</span>
            </Button>
          </div>
        </Modal.TopBar>
        <Modal.Title>Please choose a plan</Modal.Title>
        <Modal.Content>
          <Callout size="sm">
            {calloutMessage[0]}
            <b className="font-semibold">{calloutMessage[1]}</b>
          </Callout>

          <Switch.Group as="div" className="flex mt-6 items-center">
            <Switch
              data-test-id="onboardingWrapper-plansModal-successFeesToggle"
              checked={successFeesToggled}
              onChange={(newValue) => {
                setSuccessFeesToggled(newValue);
                if (newValue) {
                  trackingService.track('onboarding_plans', {
                    action: 'successFees_accept',
                  });
                }
              }}
              className={classNames(
                successFeesToggled ? 'bg-teal-500' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 border-gray-300'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  successFeesToggled ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
              />
            </Switch>

            <Switch.Label
              as="span"
              className="ml-3 text-sm font-medium text-gray-900 flex flex-wrap"
              passive
            >
              I agree to paying the
              <a
                className="underline hover:no-underline text-teal-500 mx-1"
                href="https://www.mvpr.io/success-fees"
                target="_blank"
                rel="noreferrer"
              >
                success fees
              </a>
              associated with media coverage
              <InformationCircleIcon
                data-tooltip-content="In the event that MVPR shares a press release with journalists on behalf of a client, MVPR will be entitled to success fees associated with coverage for the release up to a cap of £1,500 + VAT per release."
                className="tooltip-onboarding-successFees cursor-pointer w-4 h-4 mb-1 mr-1 text-gray-500 shrink-0"
              />{' '}
              and media opportunities
              <InformationCircleIcon
                data-tooltip-content="In the event that the company or its employees are named in the media as a result of responding to an opportunity sourced by MVPR (marked 'Sourced by MVPR'). For full details click the link to Success Fees"
                className="tooltip-onboarding-successFees cursor-pointer w-4 h-4 mb-1 mr-1 text-gray-500 shrink-0"
              />
              <ReactTooltip
                className="max-w-sm text-sm"
                anchorSelect=".tooltip-onboarding-successFees"
                // eslint-disable-next-line max-params
                overridePosition={({ left, top }, _e, _t, node) => ({
                  top,
                  left: typeof node === 'string' ? left : Math.max(left, 0),
                })}
              />
              <p className="text-sm text-gray-500 md:w-full">
                Accepting success fees will unlock all plans below. We&apos;ll
                only ever charge you in case of a successful placement.
              </p>
            </Switch.Label>
          </Switch.Group>

          <div className="mt-4 sm:mt-6 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-6 lg:mx-auto lg:grid-cols-2 xl:max-w-none xl:mx-0 xl:grid-cols-4">
            {tiers.map((tier, index) => (
              <div
                key={tier.name}
                className="border p-6 border-gray-200 rounded-lg shadow-sm "
              >
                <div>
                  <h2 className="text-lg leading-6 font-medium text-gray-900">
                    {tier.name}
                  </h2>
                  <p className="mt-4 text-sm text-gray-500">
                    {tier.description}
                  </p>
                  <p className="mt-8">
                    <span className="text-4xl font-extrabold text-gray-900">
                      £{tier.priceMonthly}
                    </span>{' '}
                    <span className="text-base font-medium text-gray-500">
                      /mo
                    </span>
                  </p>
                  <small className="text-xs text-gray-500">
                    + fees associated with media coverage and media
                    opportunities
                  </small>

                  <Button
                    disabled={!successFeesToggled}
                    className="mt-6 block w-full"
                    onClick={(e) => {
                      e.preventDefault();
                      onClick({
                        priceId: tier.priceId,
                        tierName: tier.name,
                        productId: tier.product,
                      });
                    }}
                    data-test-id={`onboardingWrapper-plansModal-button-${tier.product}`}
                  >
                    Buy {tier.name}
                  </Button>
                </div>
                <div className="">
                  <ul className="mt-6 space-y-4 text-sm text-gray-500">
                    {index > 0 ? (
                      <b>Features included in the Basic subscription +</b>
                    ) : null}
                    {tier.includedFeatures.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon
                          className="shrink-0 h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <div className="text-gray-500 flex text-sm mt-4 items-center md:justify-center space-x-2">
            <LockClosedIcon className="h-6 w-6 shrink-0" />
            <p>
              MVPR does not process any card details on its own servers. MVPR
              uses third party payment processor{' '}
              <a
                className="underline hover:no-underline text-teal-500"
                href="https://stripe.com/gb"
                target="_blank"
                alt="link to Stripe"
                rel="noreferrer"
              >
                Stripe
              </a>{' '}
              to process card payments.
            </p>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

OnboardingWrapperPlansModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  calloutMessage: PropTypes.arrayOf(PropTypes.string),
};

export default OnboardingWrapperPlansModal;
