import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

// This just displays a set of instructions underneath the autocomplete tag field
const InputFieldTagsAutocompleteInstructions = ({
  className = '',
  tagWord = 'tag',
}) => (
  <p className={classNames('text-xs text-gray-400', className)}>
    Hit{' '}
    <b>
      <code>enter</code>
    </b>{' '}
    to add a {tagWord}, use{' '}
    <b>
      <code>backspace</code>
    </b>{' '}
    to delete the last one
  </p>
);

InputFieldTagsAutocompleteInstructions.propTypes = {
  className: PropTypes.string,
  tagWord: PropTypes.string,
};

export default InputFieldTagsAutocompleteInstructions;
