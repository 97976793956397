import { useMemo } from 'react';

import { messageStatus } from 'constants/messageStatus';
import useAdminMessagesContext from 'routes/admin/AdminMessages/contexts/useAdminMessagesContext';

const filters = {
  showUnapproved: (m) =>
    !m.data.status || m.data.status === messageStatus.UNAPPROVED,
  showApproved: (m) => m.data.status === messageStatus.APPROVED,
  showDelivered: (m) => m.data.status === messageStatus.DELIVERED,
  showIncoming: (m) => m.data.isJournalist,
  showOutgoing: (m) => !m.data.isJournalist,
};

const useFilteredMessages = (messages = []) => {
  const { filters: globalFilters } = useAdminMessagesContext();

  const filteredMessages = useMemo(() => {
    const activeFilters = Object.entries(globalFilters).filter(
      ([, value]) => value
    );

    let newMessages = messages;
    activeFilters.every(([filterName]) => {
      if (filters[filterName]) {
        newMessages = newMessages.filter(filters[filterName]);
      }

      return newMessages.length;
    });

    return newMessages;
  }, [globalFilters, messages]);

  return filteredMessages;
};

export default useFilteredMessages;
