import PropTypes from 'prop-types';
import React from 'react';

// Displays the headline for a section on the company's press page
const SectionHeaderWrapper = ({ children = null }) => (
  <div className="pt-6 w-full flex justify-between items-center">
    {children}
  </div>
);

SectionHeaderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionHeaderWrapper;
