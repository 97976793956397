import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

const UserSettingsDangerZoneErrorModal = ({
  open = false,
  onClose = noop,
  message = '',
}) => (
  <Modal open={open}>
    <Modal.Title>Account Deletion Error</Modal.Title>
    <Modal.Description>{message}</Modal.Description>
    <Modal.RightButtons>
      <Button type="primary" className="w-fit" onClick={onClose}>
        Close
      </Button>
    </Modal.RightButtons>
  </Modal>
);

UserSettingsDangerZoneErrorModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  message: PropTypes.string,
};

export default UserSettingsDangerZoneErrorModal;
