import PropTypes from 'prop-types';
import React from 'react';

import { EmojiSadIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Helmet from 'react-helmet';

import getPageTitle from 'utils/getPageTitle';

const EmptyState = ({
  helmetTitle = '',
  testId = '',
  title = '',
  subtitle = '',
  Icon = EmojiSadIcon,
  instructions = null,
}) => (
  <div
    data-test-id={testId}
    className={classNames(
      'bg-gray-50 flex flex-1 flex-col space-y-2 justify-center items-center text-gray-400 mt-24'
    )}
  >
    <Helmet>
      <title>{getPageTitle(helmetTitle)}</title>
      <meta name="robots" content="noindex" />
    </Helmet>
    <div className="text-gray-400 flex">
      <Icon className="w-12 h-12 md:w-20 md:h-20" />
    </div>
    <p className="font-bold">{title}</p>
    <p>{subtitle}</p>
    {instructions}
  </div>
);

EmptyState.propTypes = {
  helmetTitle: PropTypes.string.isRequired,
  testId: PropTypes.string,
  Icon: PropTypes.elementType,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  instructions: PropTypes.node,
};

export default EmptyState;
