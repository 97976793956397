const tealShadesClasses = [
  'bg-teal-700',
  'bg-teal-600',
  'bg-teal-500',
  'bg-teal-400',
  'bg-teal-300',
  'bg-teal-200',
  'bg-teal-100',
];

const detailedTealShadesClasses = [
  'bg-teal-700',
  'bg-teal-650',
  'bg-teal-600',
  'bg-teal-550',
  'bg-teal-500',
  'bg-teal-450',
  'bg-teal-400',
  'bg-teal-350',
  'bg-teal-300',
  'bg-[#8eefdb]',
  'bg-teal-200',
  'bg-[#b1fbec]',
  'bg-teal-100',
  'bg-[#dbfff7]',
];
// get teal shade className for an index
const getTealBgClassName = (
  idx = 0,
  config = { reverse: false, detailed: false }
) => {
  const { reverse, detailed } = config;
  const colors = detailed ? detailedTealShadesClasses : tealShadesClasses;
  const key = reverse ? colors.length - 1 - idx : idx;

  return colors[key];
};
export default getTealBgClassName;
