import { collection, limit, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { SUCCESS_FEES_COLLECTION } from 'constants/firebasePaths';

const useGetSuccessFees = (threadRef = {}) => {
  const firestore = useFirestore();
  const threadId = threadRef?.id;
  const companyId = threadRef?.parent?.parent?.id;

  const col = collection(firestore, SUCCESS_FEES_COLLECTION);
  const q = query(
    col,
    where('companyId', '==', companyId),
    where('threadId', '==', threadId),
    limit(1)
  );

  const { data } = useFirestoreCollectionData(q, defaultOptions);

  return data[0] || {};
};

export default useGetSuccessFees;
