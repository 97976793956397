import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { collection, getDocs, query, setDoc, where } from 'firebase/firestore';
import noop from 'lodash/noop';
import { useFirestore } from 'reactfire';

import { COMPANIES_COLLECTION } from 'constants/firebasePaths';
import useIsAnalyticsDisabled from 'hooks/useIsAnalyticsDisabled';

// When a user is online, we want to update the latest
const CompanyLastSeenTracker = ({ userId = '' }) => {
  const firestore = useFirestore();
  const disableTracking = useIsAnalyticsDisabled();

  useEffect(() => {
    if (disableTracking || !userId) {
      return noop;
    }

    const setLastSeenDate = async () => {
      const col = collection(firestore, COMPANIES_COLLECTION);
      const q = query(col, where('owner', '==', userId));

      const company = (await getDocs(q))?.docs?.[0];
      if (company?.ref) {
        setDoc(company.ref, { lastSeenDate: new Date() }, { merge: true });
      }
    };
    setLastSeenDate();

    return noop;
  }, [disableTracking, firestore, userId]);

  return null;
};

CompanyLastSeenTracker.propTypes = {
  userId: PropTypes.string,
};

export default CompanyLastSeenTracker;
