import { INBOX_PATH } from 'constants/paths';

import InboxPage from './InboxPage';

const InboxRoute = {
  path: INBOX_PATH,
  authRequired: true,
  component: InboxPage,
};

export default InboxRoute;
