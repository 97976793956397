import PropTypes from 'prop-types';
import React from 'react';

import { Navigate } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';

import { DEFAULT_ONBOARDING_PATH, LOGIN_PATH } from 'constants/paths';

// A wrapper for <Route> that redirects to the login
const PrivateRoute = ({ children, claimsRequired = null }) => {
  const { status, data } = useSigninCheck(
    claimsRequired ? { requiredClaims: claimsRequired } : {}
  );

  if (status === 'loading') {
    return null;
  }

  if (data?.user?.isAnonymous) {
    return <Navigate to={DEFAULT_ONBOARDING_PATH} />;
  }

  // redirect logged-out users to login path
  if (!data?.signedIn) {
    return <Navigate to={LOGIN_PATH} />;
  }

  // redirect users with insufficient claims to login
  if (claimsRequired && !data.hasRequiredClaims) {
    return <Navigate to={LOGIN_PATH} />;
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.element,
  claimsRequired: PropTypes.object,
};

export default PrivateRoute;
