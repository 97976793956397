import React from 'react';

import capitalize from 'lodash/capitalize';

import InputFieldMultiButton from 'components/form/inputFields/InputFieldMultiButton';
import { messageStatus } from 'constants/messageStatus';

import { actions } from '../contexts/filtersReducer';
import useAdminMessagesContext from '../contexts/useAdminMessagesContext';

const AdminMessagesFiltersStatus = () => {
  const {
    filters: { showUnapproved, showDelivered, showApproved },
    dispatch,
  } = useAdminMessagesContext();
  const statusFilters = [
    {
      text: capitalize(messageStatus.UNAPPROVED),
      status: showUnapproved,
      setStatus: () => dispatch({ type: actions.TOGGLE_UNAPPROVED }),
    },
    {
      text: capitalize(messageStatus.APPROVED),
      status: showApproved,
      setStatus: () => dispatch({ type: actions.TOGGLE_APPROVED }),
    },
    {
      text: capitalize(messageStatus.DELIVERED),
      status: showDelivered,
      setStatus: () => dispatch({ type: actions.TOGGLE_DELIVERED }),
    },
  ];
  return (
    <InputFieldMultiButton
      buttons={statusFilters}
      label="Status"
      reset={() => {
        dispatch({ type: actions.RESET_STATUS_FILTERS });
      }}
    />
  );
};

export default AdminMessagesFiltersStatus;
