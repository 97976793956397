import PropTypes from 'prop-types';
import React from 'react';

import { LinkIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';
import OpportunityMeta from 'components/opportunities/opportunity/OpportunityMeta';
import getCompanyRoute from 'utils/getCompanyRoute';

import AdminExpandableQueryView from './AdminExpandableQueryView';
import AdminMessagesCompaniesCompany from './AdminMessagesCompanies/AdminMessagesCompaniesCompany';

const AdminMessagesCompaniesTabHeader = ({ thread = {}, inbox = {} }) => {
  const { name, logo, slug } = inbox?.data || {};

  const { journalist, opportunity } = thread?.data || {};
  const { uid, mediaOutlet, enquirySummary, custom, subject, query } =
    opportunity || {};

  return (
    <div className="p-4 bg-gray-100 shadow-inner rounded-md">
      <Link
        to={getCompanyRoute(slug || '')}
        className="group flex w-fit gap-2 items-center text-teal-700 hover:bg-teal-500/10 px-2 py-1 rounded-md uppercase font-bold "
      >
        <AdminMessagesCompaniesCompany name={name} logo={logo} />
        <LinkIcon className="w-4 h-4 hidden group-hover:block" />
      </Link>
      {thread?.data && (
        <>
          <hr className="my-2 mb-4" />
          <div className="flex flex-col gap-2">
            <p className="text-sm uppercase font-bold text-gray-600">
              {enquirySummary || subject}
            </p>
            {subject && enquirySummary ? (
              <p className="text-xs uppercase text-gray-600">
                (Subject: {subject})
              </p>
            ) : null}
            <div className="flex items-center gap-2 flex-wrap">
              <div className="flex gap-2 items-center">
                <PublicationImage
                  custom={custom}
                  url={journalist?.publication?.image}
                  name={journalist?.publication?.name}
                />
                <OpportunityMeta text={mediaOutlet} />
              </div>
              <div className="flex items-center">
                <DomainAuthorityPill
                  domainAuthority={journalist?.publication?.domainAuthority}
                />
              </div>
              <div className="flex gap-2 items-center">
                <JournalistImage
                  url={journalist?.image}
                  name={journalist?.name}
                />
                <OpportunityMeta text={journalist?.name || ''} />
              </div>
            </div>

            <AdminExpandableQueryView opportunityUid={uid} query={query} />
          </div>
        </>
      )}
    </div>
  );
};

AdminMessagesCompaniesTabHeader.propTypes = {
  thread: PropTypes.object,
  inbox: PropTypes.object,
};
export default AdminMessagesCompaniesTabHeader;
