import PropTypes from 'prop-types';
import React from 'react';

import ContentElementDescription from '../ContentElementDescription';
import ContentElementEmbargoDate from '../ContentElementEmbargoDate';
import ContentElementHeadline from '../ContentElementHeadline';
import ContentElementOther from '../ContentElementOther';
import ContentElementUploadFile from '../ContentElementUploadFile';

import ContentElementProductServiceLink from './ContentElementProductServiceLink';
import ContentElementProductServiceName from './ContentElementProductServiceName';

const ContentElementProductService = ({ index = 0 }) => (
  <div className="space-y-5 w-full">
    <ContentElementHeadline
      index={index}
      placeholder="Type the headline for this product or service announcement..."
    />
    <ContentElementDescription
      index={index}
      placeholder="Type the description for this product or service announcement..."
    />
    <ContentElementOther
      index={index}
      placeholder="Type any other relevant information about the announcement..."
    />
    <ContentElementEmbargoDate index={index} />
    <ContentElementProductServiceName index={index} />
    <ContentElementProductServiceLink index={index} />
    <ContentElementUploadFile name="file" label="Add files" />
  </div>
);

ContentElementProductService.propTypes = {
  index: PropTypes.number,
};

export default ContentElementProductService;
