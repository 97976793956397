import { collection } from 'firebase/firestore';
import sortBy from 'lodash/sortBy';
import { useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { INBOXES_THREADS_MESSAGES_COLLECTION } from 'constants/firebasePaths';
import { messageStatus } from 'constants/messageStatus';

const useInboxMessagesByDate = (threadRef = {}) => {
  const { data: messages } = useFirestoreCollectionData(
    collection(threadRef, INBOXES_THREADS_MESSAGES_COLLECTION),
    defaultOptions
  );
  const approvedMessages = messages.filter(
    (message) =>
      !message.isJournalist || message.status === messageStatus.DELIVERED
  );

  const messagesByDate = sortBy(approvedMessages, 'dateCreated');

  return messagesByDate;
};

export default useInboxMessagesByDate;
