import PropTypes from 'prop-types';
import React from 'react';

import { CheckIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';

import Modal from 'components/Modal';

const CompanyJournalistResponseModal = ({ modalToShow = null }) => {
  const navigate = useNavigate();
  const onClose = () => {
    navigate({
      search: '',
    });
  };

  return (
    <Modal
      open={modalToShow !== null}
      testId="inboxThread-journalistResponseModal"
    >
      <Modal.Close
        srMessage="Close journalist feedback modal"
        onClose={onClose}
        testId="inboxThread-journalistResponseModal-close"
      />
      <Modal.Icon Icon={CheckIcon} />
      <Modal.Title>Thank you for your feedback!</Modal.Title>
      <Modal.Description>
        Your response has been saved, we’ll use your feedback to curate what we
        share in the future.
      </Modal.Description>
    </Modal>
  );
};

CompanyJournalistResponseModal.propTypes = {
  modalToShow: PropTypes.string,
};

export default CompanyJournalistResponseModal;
