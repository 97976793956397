import { useEffect, useMemo, useRef } from 'react';

import debounce from 'lodash/debounce';
import noop from 'lodash/noop';

// This hacks into the TinyMCE editor and returns a
// function that adds some text in the status bar

const useAddSavedDraftNotification = (containerRef = {}) => {
  const statusBarRef = useRef();
  const addSavedDraftNotification = useMemo(
    () =>
      debounce(() => {
        if (statusBarRef.current) {
          // check if there is already a notification
          if (statusBarRef.current.querySelector('span')) {
            return;
          }

          const span = document.createElement('span');
          span.className = 'text-green-500';
          span.innerText = 'Draft saved';

          statusBarRef.current.style.justifyContent = 'space-between';
          statusBarRef.current.prepend(span);

          setTimeout(() => {
            if (statusBarRef.current) {
              statusBarRef.current.removeChild(span);
              statusBarRef.current.style.justifyContent = 'flex-end';
            }
          }, 2000);
        }
      }, 1000),
    []
  );

  useEffect(() => {
    if (containerRef.current) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach(() => {
          if (
            containerRef.current?.querySelector(
              '.tox-statusbar__text-container'
            )
          ) {
            statusBarRef.current = containerRef.current.querySelector(
              '.tox-statusbar__text-container'
            );
            observer.disconnect();
          }
        });
      });

      observer.observe(containerRef.current, {
        childList: true,
        subtree: true,
      });

      return () => {
        observer.disconnect();
      };
    }

    return noop;
  }, [containerRef]);

  return addSavedDraftNotification;
};

export default useAddSavedDraftNotification;
