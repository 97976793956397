import PropTypes from 'prop-types';
import React from 'react';

import sortBy from 'lodash/sortBy';

import TagList from 'components/tags/TagList';
import { coreTopics } from 'constants/tags';
import topicFilter from 'utils/data/topicFilter';

const AdminCompaniesCompanyTopics = ({ topics = [] }) => {
  const index = (topic) => coreTopics.findIndex((t) => topic.name === t.name);

  return (
    <TagList
      tags={sortBy(topics, 'name').map((topic) => ({
        text: topic.name,
        id: topic.name,
        colorIndex: topicFilter.isNotNone(topic) ? index(topic) : -2,
      }))}
    />
  );
};

AdminCompaniesCompanyTopics.propTypes = {
  topics: PropTypes.array,
};

export default AdminCompaniesCompanyTopics;
