import { useEffect, useState } from 'react';

import { useNotifications } from 'components/Notifications/NotificationsProvider';

import useNavigateWithQuery from './useNavigateWithQuery';
import useQueryParams from './useQueryParams';

const useCurrentOpportunity = (opportunities = {}) => {
  const { id, showDeclined } = useQueryParams();
  const tab = showDeclined ? 'declined' : 'upcoming';
  const navigate = useNavigateWithQuery();
  const notifications = useNotifications();
  const [isRedirecting, setIsRedirecting] = useState(false);

  let currentOpportunityTab = tab;

  if (!['upcoming', 'declined'].includes(tab)) {
    const foundEntry = Object.entries(opportunities).find(([, value]) =>
      value.some((opportunity) => opportunity.uid === id)
    );
    currentOpportunityTab = foundEntry ? foundEntry[0] : 'upcoming';
  }

  const currentOpportunities = opportunities[currentOpportunityTab];
  let currentOpportunityIndex = currentOpportunities?.findIndex(
    (o) => o.uid === id
  );

  if (currentOpportunityIndex === -1) {
    // If opportunity is not found, we search in other tabs
    Object.keys(opportunities)
      .filter((opportunityKey) => opportunityKey !== tab)
      .some((opportunityTab) => {
        currentOpportunityIndex = opportunities[opportunityTab]?.findIndex(
          (o) => o.uid === id
        );
        if (currentOpportunityIndex !== -1) {
          currentOpportunityTab = opportunityTab;
          return true;
        }
        return false;
      });
  }

  const showOpportunityModal =
    currentOpportunityIndex !== -1 && tab === currentOpportunityTab;

  useEffect(
    () => {
      setIsRedirecting(true);
      if (id && currentOpportunityIndex === -1) {
        // If opportunities are not loaded yet, hold off
        if (opportunities?.upcoming?.length === 0) {
          return;
        }
        notifications.showError({
          title: 'Sorry! Opportunity not found',
          message: 'This opportunity may have already expired',
        });

        navigate({ id: null });
      } else {
        navigate({ showDeclined: currentOpportunityTab === 'declined' });
      }

      setIsRedirecting(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentOpportunityIndex,
      currentOpportunityTab,
      id,
      navigate,
      notifications,
      tab,
    ]
  );

  return [
    currentOpportunityIndex,
    showOpportunityModal,
    isRedirecting,
    currentOpportunityTab,
  ];
};

export default useCurrentOpportunity;
