import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

// This just displays a set of instructions underneath the autocomplete company field
const InputFieldCompaniesAutocompleteInstructions = ({
  className = '',
  compWord = 'company',
}) => (
  <p className={classNames('text-xs text-gray-400', className)}>
    Hit{' '}
    <b>
      <code>enter</code>
    </b>{' '}
    to add a {compWord}, use{' '}
    <b>
      <code>backspace</code>
    </b>{' '}
    to delete the last one
  </p>
);

InputFieldCompaniesAutocompleteInstructions.propTypes = {
  className: PropTypes.string,
  compWord: PropTypes.string,
};

export default InputFieldCompaniesAutocompleteInstructions;
