import React from 'react';

import { Menu } from '@headlessui/react';
import { CogIcon, ArrowRightIcon, HomeIcon } from '@heroicons/react/outline';
import {
  CreditCardIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/solid';
import { generatePath, Link } from 'react-router-dom';

import {
  ADMIN_PATH,
  AGENCY_DASHBOARD_PATH,
  SETTINGS_BILLING_PATH,
  SETTINGS_COMPANY_PATH,
  SETTINGS_PATH,
  SETTINGS_USER_PATH,
} from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useActiveSubscription from 'hooks/useActiveSubscription';
import useHandleLogout from 'hooks/useHandleLogout';

import ImpersonateDropdown from './ImpersonateDropdown';
import UserAvatar from './UserAvatar';
import useUserName from './hooks/useUserName';

// User avatar which reveals the user's account menu on click
const AccountMenu = () => {
  const handleLogout = useHandleLogout();
  const userName = useUserName();

  const { hasBasicCompanyAccess } = useCompanyData();
  const { claimsAgencyId, claimsAdmin } = useUserData();

  const showBilling = useActiveSubscription();

  return (
    <>
      {claimsAdmin && <ImpersonateDropdown />}

      <div className="hidden sm:block items-center my-2 px-2 relative z-10">
        <Menu>
          <Menu.Button
            className="bg-white rounded-full flex text-sm focus:outline-none sm:focus:ring-2 focus:ring-teal-400 pointer-events-none sm:pointer-events-auto"
            data-test-id="navbar-toggle"
          >
            <span className="sr-only">Open user menu</span>
            <UserAvatar />
          </Menu.Button>

          <Menu.Items
            className="origin-top-right absolute right-0 z-20 mt-2 rounded-md shadow-lg py-1 bg-white focus:outline-none ring-1 ring-gray-200"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            <Menu.Item>
              <div className="flex items-center space-x-2 mb-2 px-4 py-2 pb-3 text-sm text-gray-900 focus:outline-none border-b border-gray-300">
                <UserAvatar />
                <div className="w-48">
                  <p className="font-medium truncate w-40">{userName}</p>
                </div>
              </div>
            </Menu.Item>

            {claimsAdmin && (
              <Menu.Item>
                <Link
                  to={ADMIN_PATH}
                  role="menuitem"
                  className="flex items-center space-x-2 px-4 py-2 text-sm bg-red-50 hover:bg-red-100 focus:outline-none"
                  data-test-id="navbar-menu-userSettings"
                >
                  <ShieldCheckIcon className="w-4 h-4 text-red-500" />
                  <span className="text-gray-900">Admin panel</span>
                </Link>
              </Menu.Item>
            )}

            {claimsAgencyId && (
              <Menu.Item>
                <Link
                  to={generatePath(AGENCY_DASHBOARD_PATH, {
                    agencyId: claimsAgencyId,
                  })}
                  role="menuitem"
                  className="flex items-center space-x-2 px-4 py-2 text-sm bg-red-50 hover:bg-red-100 focus:outline-none"
                  data-test-id="navbar-menu-agencyDashboard"
                >
                  <HomeIcon className="w-4 h-4 text-red-500" />
                  <span className="text-gray-900">Agency Dashboard</span>
                </Link>
              </Menu.Item>
            )}

            <Menu.Item>
              <Link
                to={generatePath(SETTINGS_PATH, {
                  '*': SETTINGS_USER_PATH,
                })}
                role="menuitem"
                className="flex items-center space-x-2 px-4 py-2 text-sm hover:bg-gray-100 focus:outline-none"
                data-test-id="navbar-menu-userSettings"
              >
                <UserIcon className="w-4 h-4 text-teal-500" />
                <span className="text-gray-900">User profile</span>
              </Link>
            </Menu.Item>

            {hasBasicCompanyAccess && (
              <Menu.Item>
                <Link
                  to={generatePath(SETTINGS_PATH, {
                    '*': SETTINGS_COMPANY_PATH,
                  })}
                  role="menuitem"
                  className="flex items-center space-x-2 px-4 py-2 text-sm hover:bg-gray-100 focus:outline-none"
                  data-test-id="navbar-menu-companySettings"
                >
                  <CogIcon className="w-4 h-4 text-teal-500" />
                  <span className="text-gray-900">Company settings</span>
                </Link>
              </Menu.Item>
            )}

            {showBilling && (
              <Menu.Item>
                <Link
                  to={generatePath(SETTINGS_PATH, {
                    '*': SETTINGS_BILLING_PATH,
                  })}
                  role="menuitem"
                  className="flex items-center space-x-2 px-4 py-2 text-sm hover:bg-gray-100 focus:outline-none"
                  data-test-id="navbar-menu-paymentSettings"
                >
                  <CreditCardIcon className="w-4 h-4 text-teal-500" />
                  <span className="text-gray-900">Subscription & Billing</span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item>
              <div className="mt-2 mb-1 border-t border-gray-300">
                <a
                  href="#"
                  role="menuitem"
                  onClick={handleLogout}
                  className="flex mt-2 items-center space-x-2 px-4 py-2 text-sm hover:bg-gray-100 focus:outline-none"
                  data-test-id="navbar-menu-signOut"
                >
                  <ArrowRightIcon className="w-4 h-4 text-teal-500" />
                  <span className="text-gray-900">Sign out</span>
                </a>
              </div>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </>
  );
};

export default AccountMenu;
