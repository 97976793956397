import PropTypes from 'prop-types';
import React from 'react';

const OpportunitiesListEmptyState = ({ showDeclined = false }) => (
  <div className="flex w-full min-h-screen justify-center items-center p-8 -mt-32 text-center text-gray-500">
    <p className="max-w-prose">
      {showDeclined ? (
        <span>
          You haven&apos;t declined any opportunities yet! If you think
          something isn&apos;t right for you, hit decline and we&apos;ll learn
          from your actions to improve what opportunities we share with you. ✨
        </span>
      ) : (
        <span>
          There aren&apos;t any live opportunities for your search at the
          moment,
          <br className="hidden lg:block" />
          but check back in a few hours to see if any more come in. We receive
          requests throughout the day. ⏳{' '}
        </span>
      )}
    </p>
  </div>
);

OpportunitiesListEmptyState.propTypes = {
  showDeclined: PropTypes.bool,
};

export default OpportunitiesListEmptyState;
