import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { RefreshIcon } from '@heroicons/react/solid';
import { getFunctions, httpsCallable } from 'firebase/functions';
import noop from 'lodash/noop';
import { useFirebaseApp } from 'reactfire';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import {
  CLASSIFIED_OPPORTUNITIES_COLLECTION,
  COMPANIES_OPPORTUNITIES_COLLECTION,
  COMPANIES_SWEETHEARTS_COLLECTION,
  CUSTOM_OPPORTUNITIES_COLLECTION,
  INBOXES_COLLECTION,
  INVOICES_COLLECTION,
  OPPORTUNITIES_NOTIFICATIONS,
  PARSED_OPPORTUNITIES_COLLECTION,
  RESPONSE_SOURCE_EMAILS_COLLECTION,
  SUCCESS_FEES_COLLECTION,
} from 'constants/firebasePaths';
import functionsConfig from 'constants/functionsConfig';

import AdminResetModalCollections from '../components/AdminResetModalCollections';

const AdminResetDevEnvironmentModal = ({ open = false, onClose = noop }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccess } = useNotifications();
  const app = useFirebaseApp();
  const functions = getFunctions(app, functionsConfig.DEFAULT_REGION);
  const resetDevEnvironment = useCallback(async () => {
    setIsLoading(true);

    await httpsCallable(functions, 'firestore-onResetDevEnvironment-default', {
      timeout: 300000,
    })();
    setIsLoading(false);
    onClose();
    showSuccess({
      message: 'Dev environment has been reset successfully',
    });
  }, [functions, onClose, showSuccess]);

  return (
    <Modal open={open} onClose={isLoading ? noop : onClose}>
      {isLoading ? (
        <Modal.Overlay className="bg-white/70 z-20">
          <Spinner />
        </Modal.Overlay>
      ) : null}
      <Modal.Close
        srMessage="Close Reset Development modal"
        onClose={onClose}
      />
      <Modal.Icon Icon={RefreshIcon} warning />
      <Modal.Title>Reset Dev Environment</Modal.Title>
      <Modal.Description>
        Are you sure you want to reset the dev environment?
        <div className="text-left mt-4">
          <p>
            <b>This will trigger the following actions:</b>
          </p>
          <ul className="list-disc pl-4 mt-4 space-y-2">
            <li className="leading-normal">
              Delete all users <i>except</i> the ones in the{' '}
              <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                userEmailAllowList
              </code>{' '}
              defined in{' '}
              <a
                href="https://console.firebase.google.com/project/mvpr-io/config"
                target="_blank"
                rel="noreferrer"
                className="text-teal-500"
              >
                Remote Config
              </a>
              . This will also remove any entries associated with this user from
              the following collections (similar to when a user deletes an
              account):
              <AdminResetModalCollections />
            </li>
            <li className="leading-normal">
              Delete any remaining users in the{' '}
              <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                users
              </code>{' '}
              database which don&apos;t have a corresponding entry with the same
              user ID in the{' '}
              <a
                href="https://console.firebase.google.com/project/mvpr-io/authentication/users"
                target="_blank"
                rel="noreferrer"
                className="text-teal-500"
              >
                Auth
              </a>{' '}
              module.
            </li>
            <li className="leading-normal">
              Delete any items from the{' '}
              <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                products
              </code>{' '}
              collection that don&apos;t have a matching entry in{' '}
              <a
                href="https://dashboard.stripe.com/products?active=true"
                target="_blank"
                rel="noreferrer"
                className="text-teal-500"
              >
                Stripe
              </a>
            </li>
            <li className="leading-normal">
              Delete any{' '}
              <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                companies
              </code>{' '}
              which don&apos;t have an owner, OR the owner doesn&apos;t exist
              anymore, OR that don&apos;t have a slug
            </li>
            <li className="leading-normal">
              Delete any orphan documents (where the company has been deleted)
              from the following collections:
              <ul className="pl-4 list-disc mt-2 space-y-1">
                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    {COMPANIES_OPPORTUNITIES_COLLECTION}
                  </code>
                </li>

                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    {COMPANIES_SWEETHEARTS_COLLECTION}
                  </code>
                </li>

                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    {INBOXES_COLLECTION}
                  </code>
                </li>

                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    {INVOICES_COLLECTION}
                  </code>
                </li>

                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    {SUCCESS_FEES_COLLECTION}
                  </code>
                </li>
              </ul>
            </li>
            <li>
              Delete all documents from the following collections:
              <ul className="pl-4 list-disc mt-2 space-y-1">
                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    {CUSTOM_OPPORTUNITIES_COLLECTION}
                  </code>
                </li>

                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    {CLASSIFIED_OPPORTUNITIES_COLLECTION}
                  </code>
                </li>

                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    {OPPORTUNITIES_NOTIFICATIONS}
                  </code>
                </li>

                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    {PARSED_OPPORTUNITIES_COLLECTION}
                  </code>
                </li>

                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    {RESPONSE_SOURCE_EMAILS_COLLECTION}
                  </code>
                </li>

                <li>
                  <code className="bg-gray-200 text-gray-700 px-1 py-0.5 rounded-lg">
                    publications_journalists_images
                  </code>{' '}
                  (deprecated)
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="text-left mt-4">
          <p>
            After cleaning up the data, we add testing data to the dev
            environment, so the app can be tested by using the test accounts
            <a
              target="_blank"
              rel="noreferrer"
              className="text-teal-500"
              href="https://www.notion.so/mvpr/Test-accounts-f424994bd373414fa6682f79c699f44e?pvs=4"
            >
              {' '}
              documented in Notion
            </a>
            .
          </p>
        </div>
      </Modal.Description>
      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-content-addModal-cancel"
        >
          Cancel
        </Button>
        <Button onClick={resetDevEnvironment}>Reset Dev Environment</Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminResetDevEnvironmentModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AdminResetDevEnvironmentModal;
