import React from 'react';

import { tabs } from 'constants/inboxTabs';

import InboxNavbarTabsItem from './InboxNavbarTabsItem';
import InboxNavbarTabsMobile from './InboxNavbarTabsMobile';

const InboxNavbarTabs = () => (
  <>
    <div className="hidden xl:flex pt-2" data-intercom-id="inbox-tabs">
      {tabs.map((tab) => (
        <InboxNavbarTabsItem key={tab.field} tab={tab} />
      ))}
    </div>
    <InboxNavbarTabsMobile />
  </>
);

export default InboxNavbarTabs;
