import PropTypes from 'prop-types';
import React from 'react';

import { ChartSquareBarIcon } from '@heroicons/react/outline';

const SweetheartListJournalistProfile = ({
  name = '',
  url = '',
  ImageComponent = null,
  hasStats = false,
  isAdmin = false,
  testId = '',
}) => (
  <div className="flex gap-2 items-center">
    <div className="flex-shrink-0 w-8 h-8 relative">
      <ImageComponent url={url} name={name} />
    </div>
    <div className="text-sm font-medium" data-test-id={testId}>
      {name}
    </div>
    {hasStats && isAdmin ? (
      <ChartSquareBarIcon className="h-5 w-5 text-teal-500" />
    ) : null}
  </div>
);

SweetheartListJournalistProfile.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  ImageComponent: PropTypes.elementType,
  hasStats: PropTypes.bool,
  isAdmin: PropTypes.bool,
  testId: PropTypes.string,
};

export default SweetheartListJournalistProfile;

// TODO Refactor as UniversalImageAndText and pass params for text, url, layout, alignment
