import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const InputFieldTextOverlay = ({ children = null, className = '' }) => (
  <div
    className={classNames(
      'absolute inset-0 w-full h-full pointer-events-none',
      className
    )}
  >
    {children}
  </div>
);

InputFieldTextOverlay.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default InputFieldTextOverlay;
