import PropTypes from 'prop-types';
import React from 'react';

import Card from 'components/Card';
import StripeRedirectModal from 'components/StripeRedirectModal';
import Button from 'components/buttons/Button';
import stripePortalConfigNames from 'constants/stripePortalConfigNames';
import useRedirectToStripePortal from 'hooks/useRedirectToStripePortal';
import { useTracking } from 'modules/segment/TrackingProvider';

const BillingSettingsDangerZone = ({ subscriptionData }) => {
  const { name, nextBillingDate } = subscriptionData;
  const { isRedirecting, redirectToStripePortal } = useRedirectToStripePortal(
    stripePortalConfigNames.SUBSCRIPTION_CANCEL
  );
  const trackingService = useTracking();

  const cancelSubscription = async (event) => {
    await trackingService.track('subscription_update', {
      action: 'subscription_cancel',
      stripe_subscriptionName: name,
    });
    redirectToStripePortal(event);
  };

  return (
    <Card type="danger" title="Danger Zone">
      <div className="w-full flex flex-col gap-5">
        <div className="flex flex-col text-sm text-gray-500 gap-2">
          <p className="text-gray-900">
            If you would like to cancel your current subscription please be
            aware of the following:
          </p>
          <ul className="ml-4 space-y-2 list-disc	">
            <li>
              You&apos;ll be able to access all the benefits from the{' '}
              <span className="text-gray-900">{name}</span> until the
              subscription end date:{' '}
              <span className="text-gray-900">{nextBillingDate}.</span>
            </li>
            <li>
              You will not be billed again for this subscription{' '}
              <span className="text-gray-900">
                but may receive media coverage & success fees
              </span>{' '}
              for opportunities prior to the end of the subscription end date.
            </li>
            <li>
              After the subscription end date, you should not receive any
              further billing for media coverage & success fees.
            </li>
          </ul>
        </div>
        <Button type="secondary" className="w-fit" onClick={cancelSubscription}>
          Cancel Subscription
        </Button>
      </div>
      <StripeRedirectModal isRedirecting={isRedirecting} />
    </Card>
  );
};

BillingSettingsDangerZone.propTypes = {
  subscriptionData: PropTypes.object,
};

export default BillingSettingsDangerZone;
