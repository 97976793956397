import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import { createDate } from 'hooks/helpers/dateUtils';
import useDateTimeSelector from 'hooks/useDateTimeSelector';
import { getDateObjFromTimestampCurrentUserTimezone } from 'utils/date/getDateObjFromTimestamp';

const defaultValues = (date) => ({
  ...getDateObjFromTimestampCurrentUserTimezone(date),
});

const InboxMessageResponseFormScheduleModal = ({
  onSchedule = noop,
  onCancel = noop,
  date = new Date(),
}) => {
  const { control, setValue, watch, getValues } = useForm({
    defaultValues: defaultValues(date),
    mode: 'onSubmit',
  });

  const validOptionRanges = useDateTimeSelector({
    config: {
      type: 'future',
    },
    selection: {
      selectedHour: watch('hour'),
      selectedDay: watch('day'),
      selectedMonth: watch('month'),
      selectedYear: watch('year'),
    },
    updateSelections: ({ day, month, year, hour }) => {
      setValue('day', day);
      setValue('month', month);
      setValue('year', year);
      setValue('hour', hour);
    },
  });

  return (
    <Modal open widthClass="max-w-xl lg:max-w-2xl xl:max-w-3xl">
      <Modal.Close
        srMessage="Close Opportunity Response modal"
        testId="opportunity-responseModal-close"
        onClose={onCancel}
      />
      <Modal.Title>Schedule message</Modal.Title>
      <Modal.Description>
        <p>
          Select a date and time to schedule your message to be sent to the
          journalist.
        </p>
      </Modal.Description>
      <Modal.Content>
        <div className="flex flex-col gap-2">
          <Label>Date and time</Label>
          <div className="flex gap-2">
            <Controller
              name="day"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputFieldDropdown
                  options={validOptionRanges.days}
                  onChange={onChange}
                  value={value || ''}
                  wrapperClassName="w-[calc(2ch+4rem)] grow"
                  buttonClassName="w-full"
                />
              )}
            />
            <Controller
              name="month"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputFieldDropdown
                  options={validOptionRanges.months}
                  onChange={onChange}
                  value={value || ''}
                  wrapperClassName="w-[calc(9ch+4rem)] grow"
                  buttonClassName="w-full"
                />
              )}
            />
            <Controller
              name="year"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputFieldDropdown
                  options={validOptionRanges.years}
                  onChange={onChange}
                  value={value || ''}
                  wrapperClassName="w-[calc(4ch+4rem)] grow"
                  buttonClassName="w-full"
                />
              )}
            />
            <Controller
              name="hour"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputFieldDropdown
                  options={validOptionRanges.hours}
                  onChange={onChange}
                  value={value || ''}
                  wrapperClassName="w-[calc(4ch+4rem)] grow"
                  buttonClassName="w-full"
                  testId="company-content-inputEmbargo-hour"
                />
              )}
            />
          </div>
        </div>
      </Modal.Content>

      <Modal.RightButtons>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          onClick={() => onSchedule(createDate(getValues()))}
          type="primary"
        >
          Schedule
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

InboxMessageResponseFormScheduleModal.propTypes = {
  onSchedule: PropTypes.func,
  onCancel: PropTypes.func,
  date: PropTypes.instanceOf(Date),
};

export default InboxMessageResponseFormScheduleModal;
