import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { generatePath, Link, useParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import IconReadStatus from 'components/icons/IconReadStatus';
import JournalistImage from 'components/images/JournalistImage';
import { messageStatus } from 'constants/messageStatus';
import { INBOX_PATH } from 'constants/paths';

import InboxMessageAvatar from '../InboxMessageAvatar';
import useScrollMessageIntoView from '../hooks/useScrollMessageIntoView';

import InboxMessageHeaderDate from './InboxMessageHeaderDate';
import InboxMessageHeaderName from './InboxMessageHeaderName';
import InboxMessageHeaderSchedule from './InboxMessageHeaderSchedule';

const InboxMessageHeader = ({
  message = {},
  thread = {},
  isLast = false,
  isFirst = false,
  isExpanded = false,
  isAdmin = false,
  journalist = {},
  threadRef = {},
}) => {
  const { tab } = useParams();
  const { text, isJournalist, dateCreated, read, opened, status, sendAfter } =
    message;
  const { opportunity } = thread;
  const { mediaOutlet } = opportunity;
  const isRead = read === undefined || read === true;
  const ref = useScrollMessageIntoView(isExpanded);

  return (
    <Link
      ref={ref}
      data-test-id="inboxMessage"
      className={classNames(
        'w-full flex gap-4 items-center hover:bg-gray-50 scroll-mt-16',
        {
          'rounded-t-md': isFirst,
        }
      )}
      preventScrollReset
      to={generatePath(INBOX_PATH, {
        tab,
        threadId: thread.uid,
        messageId: message.uid,
      })}
    >
      {!isJournalist && (
        <InboxMessageAvatar
          isAdmin={isAdmin}
          journalist={journalist?.name}
          className="ml-4"
        />
      )}
      {isJournalist && (
        <JournalistImage
          url={journalist?.image}
          text={journalist?.name}
          className="ml-4 w-12 h-12"
        />
      )}

      <div
        className={classNames(
          'flex w-full gap-2 items-center truncate p-4 pl-0 border-b-[1px] border-transparent',
          {
            'border-gray-200': isExpanded || !isLast,
          }
        )}
      >
        <div className="flex flex-col lg:flex-row truncate lg:gap-2 items-start lg:items-center">
          <InboxMessageHeaderName
            isJournalist={isJournalist}
            journalist={journalist?.name}
            publication={mediaOutlet}
            isExpanded={isExpanded}
            isRead={isRead}
          />
          {!isExpanded && (
            <p
              className={classNames(
                'truncate w-full items-center text-gray-400',
                {
                  'font-bold text-gray-700': !isRead,
                  'font-light': isRead,
                }
              )}
            >
              {text}
            </p>
          )}
        </div>
        <div className="ml-auto flex items-center gap-2">
          {!isJournalist &&
          isAdmin &&
          sendAfter &&
          status !== messageStatus.DELIVERED ? (
            <InboxMessageHeaderSchedule
              status={status}
              sendAfter={sendAfter}
              messagePath={`${threadRef.path}/messages/${message.uid}`}
            />
          ) : (
            !isJournalist && <IconReadStatus opened={opened} status={status} />
          )}
          <ReactTooltip id="tooltip-inbox-readicon" className="text-sm" />
          {sendAfter && status !== messageStatus.DELIVERED ? null : (
            <InboxMessageHeaderDate
              isJournalist={isJournalist}
              dateCreated={dateCreated}
              publication={journalist?.publication}
              isRead={isRead}
            />
          )}
        </div>
      </div>
    </Link>
  );
};

InboxMessageHeader.propTypes = {
  message: PropTypes.object,
  thread: PropTypes.object,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isAdmin: PropTypes.bool,
  journalist: PropTypes.object,
  threadRef: PropTypes.object,
};

export default InboxMessageHeader;
